import React from 'react';
import { OrderStatusEnum } from '@zonofi/zono-money-helpers';
import './order-status-tag.scss';

interface OrderStatusTagProps {
  status: OrderStatusEnum | any;
}

const getOrderStatusText = (orderStatus: OrderStatusEnum) => {
  switch (orderStatus) {
    case OrderStatusEnum.SUBMITTED_BY_CUSTOMER:
    case OrderStatusEnum.SUBMITTED:
    case OrderStatusEnum.SUBMITTED_BY_SALES:
    case OrderStatusEnum.ORDER_SUBMITTED:
      return 'Submitted';

    case OrderStatusEnum.APPROVED:
      return 'Approved';
    case OrderStatusEnum.APPROVED_BY_CFA:
      return 'Approved';
    case OrderStatusEnum.CONFIRMED:
      return 'CONFIRMED';
    case OrderStatusEnum.WAITING_FOR_CONFIRMATION_:
    case OrderStatusEnum.WAITING_FOR_CONFIRMATION:
      return 'WAITING FOR CNF';
    case OrderStatusEnum.BILLED:
      return 'BILLED';
    case OrderStatusEnum.DISPATCHED:
      return 'DISPATCHED';
    case OrderStatusEnum.ADDING_ITEMS:
    case OrderStatusEnum.ORDER_CREATED:
    case OrderStatusEnum.DRAFT:
      return 'DRAFT';

    default:
      return orderStatus;
  }
};

const getColors = (orderStatus: OrderStatusEnum) => {
  switch (orderStatus) {
    case OrderStatusEnum.ADDING_ITEMS:
    case OrderStatusEnum.ORDER_CREATED:
    case OrderStatusEnum.DRAFT:
      return {
        bgColor: '#EAEAEA',
        color: '#555',
      };
    case OrderStatusEnum.CANCELLED:
      return {
        bgColor: '#f089431a',
        color: '#f08943',
      };
    default:
      return {
        bgColor: '#D6EAED',
        color: '#0067FF',
      };
  }
};

export const OrderStatusTag: React.FC<OrderStatusTagProps> = ({ status }) => {
  const { bgColor, color } = getColors(status);
  return (
    <div
      className="order-status-tag"
      style={{
        backgroundColor: bgColor,
      }}
    >
      <span
        className="order-status-tag-text font-primary-semibold"
        style={{
          color: color,
        }}
      >
        {getOrderStatusText(status)}
      </span>
    </div>
  );
};
