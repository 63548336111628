import { ColumnConfig, PrincipalTeamInvitesColumnKey } from '../../model';

export const principalTeamInvitesConfig: Record<
  PrincipalTeamInvitesColumnKey,
  ColumnConfig
> = {
  user: {
    name: 'User',
    field: 'component',
  },
  role: {
    name: 'Role',
  },
  divisionsCFALocation: {
    name: 'Divisions / CFA Location',
    field: 'component',
  },
  status: {
    name: 'Invite Status',
  },
  customer: {
    name: 'Customers',
    field: 'addAction',
  },
  actions: { name: '', alignment: 'flex-end' },
};
