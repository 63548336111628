import { ColumnConfig, PrincipalOrderDetailsViewColumnKey } from '../../model';

export const principalOrderDetailsViewConfig: Record<
  PrincipalOrderDetailsViewColumnKey,
  ColumnConfig
> = {
  skuCode: {
    name: 'SKU Code',
    field: 'checkboxNavigate',
    isCheckboxField: true,
  },
  productTitle: {
    name: 'Product Title',
    isComponent: true,
  },
  pts: { name: 'PTS', sortField: 'pts', alignment: 'flex-end' },
  tax: { name: 'Tax (GST)', alignment: 'flex-end', isComponent: true },
  orderedQty: {
    name: 'Ordered Qty',
    alignment: 'flex-end',
    sortField: 'orderedQty',
  },
  orderedAmt: {
    name: 'Ordered Amt',
    alignment: 'flex-end',
    sortField: 'proratedLinePriceWithTax',
  },
  invoicedQty: {
    name: 'Invoiced Qty',
    alignment: 'flex-end',
    sortField: 'InvoicedQty',
  },
  invoicedAmt: {
    name: 'Invoiced Amt',
    alignment: 'flex-end',
    sortField: 'InvoicedAmt',
  },
  CO: {
    name: 'CO',
    isAdditional: true,
    isVisible: false,
  },
  orderSaving: {
    name: 'Order Saving',
    alignment: 'flex-end',
    isAdditional: true,
    isVisible: false,
  },
  // invoicedValue: {
  //   name: 'Invoiced Value',
  //   isAdditional: true,
  //   isVisible: false,
  // },
  // fulfilledOrderQuantity: {
  //   name: 'Fullfilled Order Quantity',
  //   isAdditional: true,
  //   isVisible: false,
  // },
  // fulFilledOrderValue: {
  //   name: 'Fullfilled Order Value',
  //   isAdditional: true,
  //   isVisible: false,
  // },
  bouncedOrderQuantity: {
    name: 'Bounced Order Quantity',
    alignment: 'flex-end',
    isAdditional: true,
    isVisible: false,
  },
  bouncedOrderValue: {
    name: 'Bounced Order Value',
    alignment: 'flex-end',
    isAdditional: true,
    isVisible: false,
  },
  invoiceNumber: {
    name: 'Invoice Number',
    isAdditional: true,
    isVisible: false,
    isComponent: true,
  },
  // recommendedOrderValue: {
  //   name: 'Recommended Order Value',
  //   isAdditional: true,
  //   isVisible: false,
  // },
  headerDivision: {
    name: 'Header Division',
    isAdditional: true,
    isVisible: false,
  },
  scheme: {
    name: 'Scheme',
    isAdditional: true,
    isVisible: false,
  },
  division: {
    name: 'Division',
    isAdditional: true,
    isVisible: false,
  },
  // cfaName: {
  //   name: 'CFA Name',
  //   isAdditional: true,
  //   isVisible: false,
  // },
  actualNumberOfPlacedItems: {
    name: 'Actual Number Of Placed Items',
    alignment: 'flex-end',
    isAdditional: true,
    isVisible: false,
  },
  actualValueOfPlacedItems: {
    name: 'Actual Value Of Placed Items',
    alignment: 'flex-end',
    isAdditional: true,
    isVisible: false,
  },
  // numberOfFulfilledItems: {
  //   name: 'Number Of Fulfilled Items',
  //   isAdditional: true,
  //   isVisible: false,
  // },
  // valueOfFulfilledItems: {
  //   name: 'Value Of Fulfilled Items',
  //   isAdditional: true,
  //   isVisible: false,
  // },
  numberOfBouncedItems: {
    name: 'Number Of Bounced Items',
    alignment: 'flex-end',
    isAdditional: true,
    isVisible: false,
  },
  valueOfBouncedItems: {
    name: 'Value Of Bounced Items',
    alignment: 'flex-end',
    isAdditional: true,
    isVisible: false,
  },
};
