export interface SelectedDateFilter {
  segment: SelectedDateFilterType;
  startDate: string;
  endDate: string;
  type: FilterType;
}

export enum SelectedDateFilterType {
  INVOICE = 'invoiceFilters',
  PAYMENT = 'paymentFilters',
  DAYBOOK = 'daybookFilters',
  DUE_BY_CUSTOMER = 'dueByCustomerFilters',
  DUE_BY_ROUTE = 'dueByRouteFilters',
  TEAM_MEMBER_COLLECTION = 'teamMemberCollectionFilters',
  TEAM_MEMBER_COLLECTION_SUMMARY = 'teamMemberCollectionSummaryFilters',
  HOME = 'homeFilters',
  SETTLEMENT = 'settlementFilters',
  CUSTOMER_LEDGER_SUMMARY = 'customerLedgerSummaryFilters',
  CUSTOMER_LEDGER_SUMMARYY = 'customerLedgerSummaryDateFilters',
  OUTSTANDING_TEAM_MEMBER_SUMMARY = 'outstandingTeamMemberSummaryFilters',
  OUTSTANDING_ROUTE_SUMMARY = 'outstandingRouteSummaryFilters',
  TEAM_MEMBER_OUTSTANDING = 'teamMemberOutstandingFilters',
  RECEIVE_PAYMENTS = 'receivePaymentsFilters',
  SALES_INVOICE = 'salesInvoiceFilters',
}

export enum FilterType {
  TODAY = 'Today',
  YESTERDAY = 'Yesterday',
  THIS_WEEK = 'This Week',
  THIS_MONTH = 'This Month',
  THIS_QUARTER = 'This Quarter',
  THIS_FINANCIAL_YEAR = 'This Year',
  SELECT_DATE_RANGE = 'Date Range',
  SELECT_DATE = 'Select Date',
}

export enum DocumentSubType {
  GST = 'gst_doc',
  DRUG_LICENSE = 'drug_license',
  SAMPLE_ORDER = 'sample_order',
  SAMPLE_RETURN_ORDER = 'sample_return_order',
  SAMPLE_PRODUCT_MAPPING = 'sample_product_mapping',
  SAMPLE_STOCK_INVENTORY = 'sample_stock_inventory',
}

export enum DocumentMimeTypes {
  PDF = 'application/pdf',
  JPEG = 'image/jpeg',
  PNG = 'image/png',
  XLS = 'application/vnd.ms-excel',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  CSV = 'text/csv',
  CSV2 = 'text/comma-separated-values',
  TXT = 'text/plain',
}

export interface SelectedPreferences {
  activeTab: string;
}

export interface PaymentPreferences {
  sellerWorkspaceId?: string;
  expiry?: number;
  upi: boolean;
  netBanking: boolean;
  wallet: boolean;
  creditCard: boolean;
  debitCard: boolean;
}

export interface CustomerBankDetails {
  accountNumber: string;
  accountHolderName: string;
  IFSCCode: string;
  bankName: string;
}

export interface SelectedPrincipal {
  customerName: string;
  id: string;
  inviteId?: string;
  principalWorkspaceId?: string;
  spaceName?: string;
  workspaceId: string;
  referenceNumber?: number;
  profilePic?: string | null;
  customerCode?: string;
  address?: string;
  phone?: string;
}
