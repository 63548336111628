import React, { useEffect, useState } from 'react';
import './product-details-footer.scss';
import { LoadingSpinner, SVGS } from '@zonofi/zono-money-design-system';
import { useAppSelector } from '@zonofi/zono-money-helpers';
import {
  getCustomerInvite,
  ProductVariantCategory,
} from '@zonofi/zono-money-store';
import { useSearchParams } from 'react-router-dom';
import { getConfigValue } from '@zonofi/common';

interface ProductFooterProps {
  onDecrement: (
    selectedLine: any,
    sellerWorkspaceId: string,
    customerId: string
  ) => any;
  onIncrement: (
    selectedLine: any,
    sellerWorkspaceId: string,
    customerId: string
  ) => any;
  onChangeQuantity: (
    selectedLine: any,
    qty: number,
    sellerWorkspaceId: string,
    customerId: string
  ) => any;
  onAddToCart: (val: { productVariantId: number; minOrderQty: number }) => any;
  productDetails: {
    title: string;
    price: number;
    tags: ProductVariantCategory[];
    description: string;
    details: {
      ptr: string;
      margin: string;
      moq: number;
    };
    images: string[];
    itemInCart?: {
      quantity: number;
      orderId: number;
    };
    productVariantId: number;
  };
  isLoading: boolean;
  isMoqError?: boolean;
  onDelete: (
    selectedLine: any,
    sellerWorkspaceId: string,
    customerId: string
  ) => void;
}

export const ProductFooter: React.FC<ProductFooterProps> = ({
  onAddToCart,
  onChangeQuantity,
  onDecrement,
  onIncrement,
  productDetails,
  isLoading,
  isMoqError,
  onDelete,
}) => {
  const [searchParams] = useSearchParams();
  const hideAddToCart = searchParams?.get('hideAddToCart') === 'true';
  const inviteData = useAppSelector(getCustomerInvite);
  const workspaceId = inviteData?.seller?.workspaceId;
  const customerId = inviteData?.inviteId;
  const { waConfig } = inviteData;
  const [qty, setQty] = useState<string>('0');

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQty(e?.target?.value);
  };

  useEffect(() => {
    setQty(productDetails?.itemInCart?.quantity?.toString() || '0');
  }, [productDetails]);

  useEffect(() => {
    if (isMoqError) {
      setQty(productDetails?.details?.moq.toString());
    }
  }, [isMoqError, productDetails]);

  const onClickRaiseRequest = () => {
    const encodedMessage = encodeURIComponent(
      `I have a query related to ${productDetails?.title}`
    );
    if (waConfig?.whatsappPhoneNo && waConfig?.whatsappPhoneNo?.trim()) {
      window.location.href = `https://wa.me/${waConfig.whatsappPhoneNo}?text=${encodedMessage}`;
    } else if (workspaceId === 'c9bedd72-85d7-41c4-8c62-e33ffc926966') {
      window.location.href =
        getConfigValue('WAPP_REDIRECT_BJ_GROUP') + '?text=' + encodedMessage;
    } else {
      window.location.href =
        getConfigValue('WAPP_REDIRECT') + '?text=' + encodedMessage;
    }
  };
  return (
    <footer className="product-footer">
      <button className="secondary-button" onClick={onClickRaiseRequest}>
        <img src={SVGS.ChantIconBlack} alt="" />
        <div className="btn-text">
          <div className="btn-text-small">Any query?</div>
          <div className="btn-text-big">Chat With Us</div>
        </div>
      </button>
      {!hideAddToCart &&
        (productDetails?.itemInCart?.quantity ? (
          <button className="quantity-input">
            <img
              src={SVGS.MinusPrimaryCyan}
              onClick={() =>
                onDecrement(
                  {
                    productVariantId: productDetails?.productVariantId,
                    quantity: productDetails?.itemInCart?.quantity,
                    orderId: productDetails?.itemInCart?.orderId,
                  },
                  workspaceId,
                  customerId
                )
              }
            />
            {isLoading ? (
              <div className="quantity-input-loader">
                <LoadingSpinner size={15} />
              </div>
            ) : (
              <input
                type="number"
                value={qty}
                onChange={(e) => onChange(e)}
                onBlur={() => {
                  onChangeQuantity(
                    {
                      productVariantId: productDetails?.productVariantId,
                      quantity: productDetails?.itemInCart?.quantity,
                      orderId: productDetails?.itemInCart?.orderId,
                    },
                    Number(qty),
                    workspaceId,
                    customerId
                  );
                }}
              />
            )}
            <img
              src={SVGS.PlusPrimaryCyan}
              onClick={() =>
                onIncrement(
                  {
                    productVariantId: productDetails?.productVariantId,
                    quantity: productDetails?.itemInCart?.quantity,
                    orderId: productDetails?.itemInCart?.orderId,
                  },
                  workspaceId,
                  customerId
                )
              }
            />
          </button>
        ) : (
          <button
            className="primary-button"
            onClick={() =>
              onAddToCart({
                productVariantId: productDetails?.productVariantId,
                minOrderQty: productDetails?.details?.moq,
              })
            }
          >
            <img src={SVGS.CartIconWhite} alt="" />
            {isLoading ? (
              <div className="quantity-input-loader">
                <LoadingSpinner size={15} />
              </div>
            ) : (
              'Add To Cart'
            )}
          </button>
        ))}
      {!hideAddToCart && productDetails?.itemInCart?.quantity && (
        <div
          className="delete-product"
          onClick={() =>
            onDelete(
              {
                productVariantId: productDetails?.productVariantId,
                quantity: productDetails?.itemInCart?.quantity,
                orderId: productDetails?.itemInCart?.orderId,
              },
              workspaceId,
              customerId
            )
          }
        >
          <img src={SVGS.DeleteRed} alt="" />
        </div>
      )}
    </footer>
  );
};
