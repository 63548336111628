import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../root';
import { ReturnOrder } from './model';

export const RETURNS_FEATURE_KEY = 'returnsData';

interface SelectedValue {
  id: string;
  name: string;
}
interface ReturnsState {
  selectedCfa: SelectedValue;
  selectedHeadDivision: SelectedValue;
  selectedCategory: SelectedValue;
  createReturnFromHome: boolean;
  latestReturn?: ReturnOrder;
}

const initialReturnState: ReturnsState = {
  selectedCfa: { id: '', name: '' },
  selectedHeadDivision: { id: '', name: '' },
  selectedCategory: { id: '', name: '' },
  createReturnFromHome: false,
};

export const returnsSlice = createSlice({
  name: RETURNS_FEATURE_KEY,
  initialState: initialReturnState,
  reducers: {
    updateSelectedCfa(state, action: PayloadAction<SelectedValue>) {
      state.selectedCfa = action.payload;
    },
    updateSelectedHeadDivision(state, action: PayloadAction<SelectedValue>) {
      state.selectedHeadDivision = action.payload;
    },
    updateSelectedCategory(state, action: PayloadAction<SelectedValue>) {
      state.selectedCategory = action.payload;
    },
    updateCreateReturnFromHome(state, action: PayloadAction<boolean>) {
      state.createReturnFromHome = action.payload;
    },
    updateLatestReturn(state, action: PayloadAction<ReturnOrder>) {
      state.latestReturn = action.payload;
    },
  },
});

export const returnsReducer = returnsSlice.reducer;

export const {
  updateSelectedCfa,
  updateSelectedHeadDivision,
  updateSelectedCategory,
  updateCreateReturnFromHome,
  updateLatestReturn,
} = returnsSlice.actions;

export const getReturnsSliceState = (rootState: RootState): ReturnsState =>
  rootState[RETURNS_FEATURE_KEY];

export const getSelectedCfa = createSelector(
  getReturnsSliceState,
  (state) => state.selectedCfa
);

export const getSelectedHeadDivision = createSelector(
  getReturnsSliceState,
  (state) => state.selectedHeadDivision
);

export const getSelectedCategory = createSelector(
  getReturnsSliceState,
  (state) => state.selectedCategory
);

export const getCreateReturnFromHome = createSelector(
  getReturnsSliceState,
  (state) => state.createReturnFromHome
);

export const getLatestReturn = createSelector(
  getReturnsSliceState,
  (state) => state?.latestReturn
);
