import { ColumnConfig, PrincipalInvoiceColumnKey } from '../../model';

export const principalInvoiceConfig: Record<
  PrincipalInvoiceColumnKey,
  ColumnConfig
> = {
  invoiceNumber: {
    name: 'Invoice Number',
    isCheckboxField: true,
    isComponent: true,
  },
  invoiceDate: {
    name: 'Invoice Date',
    sortField: 'invoiceDate',
    alignment: 'flex-end',
  },
  coNumber: {
    name: 'CO Number',
  },
  status: {
    name: 'Status',
    isComponent: true,
  },
  customerName: {
    name: 'Customer Name',
    field: 'avatarNameStatus',
  },
  dueDate: {
    name: 'Due Date',
    sortField: 'dueDate',
    alignment: 'flex-end',
  },
  invoiceAmount: {
    name: 'Invoice Amount',
    sortField: 'invoiceAmount',
    alignment: 'flex-end',
  },
  amountPaid: {
    name: 'Amount Paid',
    sortField: 'paidAmount',
    alignment: 'flex-end',
  },
  paymentId: {
    name: 'Payment ID',
    isComponent: true,
  },
  orderNumber: {
    name: 'Order Number',
    isComponent: true,
    isAdditional: true,
    isVisible: false,
  },

  action: {
    name: '',
    alignment: 'flex-end',
  },
};
