import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import { paymentApi } from '../payment';
import { RootState } from '../root';
import {
  FilterType,
  SelectedDateFilter,
  SelectedDateFilterType,
  SelectedPrincipal,
  SelectedPreferences,
  PaymentPreferences,
  CustomerBankDetails,
} from './model';

interface Toast {
  canDisplay: boolean;
  title: string;
  message: string;
  colorCode: string;
}

const USER_PREFERENCE_FEATURE_KEY = 'userPreference';
const DATE_FORMAT = 'YYYY-MM-DD';

type SelectedDateFilters = { [id: string]: SelectedDateFilter };

interface userPreferenceState {
  selectedDateFilters: SelectedDateFilters;
  dayBookLastRefreshedDateTime: number | null;
  homePageLastRefreshedDateTime: number | null;
  threadsLastRefreshedDateTime: number | null;
  channelsLastRefreshedDateTime: number | null;
  selectedPrincipalAccount: SelectedPrincipal;
  isPrincipalDetailsModalOpen: boolean;
  selectedPreferences: SelectedPreferences;
  paymentPreferences: PaymentPreferences;
  toast: Toast;
  customerBankDetails: CustomerBankDetails[];
  version: string;
}

export const initialUserPreferenceState: userPreferenceState = {
  selectedDateFilters: {
    [SelectedDateFilterType.INVOICE]: {
      endDate: moment().endOf('week').format('DD/MM/YYYY'),
      startDate: moment().startOf('week').format('DD/MM/YYYY'),
      type: FilterType.THIS_WEEK,
      segment: SelectedDateFilterType.INVOICE,
    },
    [SelectedDateFilterType.PAYMENT]: {
      endDate: moment().endOf('week').format('DD/MM/YYYY'),
      startDate: moment().startOf('week').format('DD/MM/YYYY'),
      type: FilterType.THIS_WEEK,
      segment: SelectedDateFilterType.PAYMENT,
    },
    [SelectedDateFilterType.DAYBOOK]: {
      endDate: moment().format('DD/MM/YYYY'),
      startDate: moment().format('DD/MM/YYYY'),
      type: FilterType.TODAY,
      segment: SelectedDateFilterType.DAYBOOK,
    },
    [SelectedDateFilterType.DUE_BY_CUSTOMER]: {
      endDate: moment().format('DD/MM/YYYY'),
      startDate: moment().format('DD/MM/YYYY'),
      type: FilterType.TODAY,
      segment: SelectedDateFilterType.DUE_BY_CUSTOMER,
    },
    [SelectedDateFilterType.DUE_BY_ROUTE]: {
      endDate: moment().format('DD/MM/YYYY'),
      startDate: moment().format('DD/MM/YYYY'),
      type: FilterType.TODAY,
      segment: SelectedDateFilterType.DUE_BY_ROUTE,
    },
    [SelectedDateFilterType.TEAM_MEMBER_COLLECTION]: {
      endDate: moment().format('DD/MM/YYYY'),
      startDate: moment().format('DD/MM/YYYY'),
      type: FilterType.TODAY,
      segment: SelectedDateFilterType.TEAM_MEMBER_COLLECTION,
    },
    [SelectedDateFilterType.TEAM_MEMBER_COLLECTION_SUMMARY]: {
      endDate: moment().format('DD/MM/YYYY'),
      startDate: moment().format('DD/MM/YYYY'),
      type: FilterType.TODAY,
      segment: SelectedDateFilterType.TEAM_MEMBER_COLLECTION_SUMMARY,
    },
    [SelectedDateFilterType.HOME]: {
      endDate: moment().endOf('week').format('DD/MM/YYYY'),
      startDate: moment().startOf('week').format('DD/MM/YYYY'),
      type: FilterType.THIS_WEEK,
      segment: SelectedDateFilterType.HOME,
    },
    [SelectedDateFilterType.SETTLEMENT]: {
      endDate: moment().endOf('month').format('DD/MM/YYYY'),
      startDate: moment().startOf('month').format('DD/MM/YYYY'),
      type: FilterType.THIS_MONTH,
      segment: SelectedDateFilterType.SETTLEMENT,
    },
    [SelectedDateFilterType.CUSTOMER_LEDGER_SUMMARY]: {
      endDate: moment().format('DD/MM/YYYY'),
      startDate: moment().format('DD/MM/YYYY'),
      type: FilterType.TODAY,
      segment: SelectedDateFilterType.CUSTOMER_LEDGER_SUMMARY,
    },
    [SelectedDateFilterType.OUTSTANDING_TEAM_MEMBER_SUMMARY]: {
      endDate: moment().format('DD/MM/YYYY'),
      startDate: moment().format('DD/MM/YYYY'),
      type: FilterType.TODAY,
      segment: SelectedDateFilterType.OUTSTANDING_TEAM_MEMBER_SUMMARY,
    },
    [SelectedDateFilterType.OUTSTANDING_ROUTE_SUMMARY]: {
      endDate: moment().format('DD/MM/YYYY'),
      startDate: moment().format('DD/MM/YYYY'),
      type: FilterType.TODAY,
      segment: SelectedDateFilterType.OUTSTANDING_ROUTE_SUMMARY,
    },
    [SelectedDateFilterType.TEAM_MEMBER_OUTSTANDING]: {
      endDate: moment().format('DD/MM/YYYY'),
      startDate: moment().format('DD/MM/YYYY'),
      type: FilterType.TODAY,
      segment: SelectedDateFilterType.TEAM_MEMBER_OUTSTANDING,
    },
    [SelectedDateFilterType.RECEIVE_PAYMENTS]: {
      endDate: moment().endOf('month').format('DD/MM/YYYY'),
      startDate: moment().startOf('month').format('DD/MM/YYYY'),
      type: FilterType.THIS_MONTH,
      segment: SelectedDateFilterType.RECEIVE_PAYMENTS,
    },
    [SelectedDateFilterType.SALES_INVOICE]: {
      endDate: moment().endOf('week').format('DD/MM/YYYY'),
      startDate: moment().startOf('week').format('DD/MM/YYYY'),
      type: FilterType.THIS_WEEK,
      segment: SelectedDateFilterType.SALES_INVOICE,
    },
    [SelectedDateFilterType.CUSTOMER_LEDGER_SUMMARYY]: {
      segment: SelectedDateFilterType.CUSTOMER_LEDGER_SUMMARYY,
      endDate: moment().format('DD/MM/YYYY'),
      startDate: moment().format('DD/MM/YYYY'),
      type: FilterType.TODAY,
    },
  },
  dayBookLastRefreshedDateTime: null,
  homePageLastRefreshedDateTime: null,
  channelsLastRefreshedDateTime: null,
  threadsLastRefreshedDateTime: null,
  isPrincipalDetailsModalOpen: false,
  selectedPrincipalAccount: {
    customerName: '',
    id: '',
    inviteId: '',
    principalWorkspaceId: '',
    spaceName: '',
    workspaceId: '',
    referenceNumber: 0,
    address: '',
    phone: '',
  },
  selectedPreferences: {
    activeTab: 'COMMUNICATION',
  },
  paymentPreferences: {
    upi: false,
    netBanking: false,
    wallet: false,
    creditCard: false,
    debitCard: false,
  },
  toast: { canDisplay: false, title: '', message: '', colorCode: '' },
  customerBankDetails: [
    {
      bankName: '',
      IFSCCode: '',
      accountHolderName: '',
      accountNumber: '',
    },
  ],
  version: '',
};

export const userPreferenceSlice = createSlice({
  name: USER_PREFERENCE_FEATURE_KEY,
  initialState: initialUserPreferenceState,
  reducers: {
    updateDateFilter(state, action: PayloadAction<SelectedDateFilter>) {
      state.selectedDateFilters[action.payload.segment] = action.payload;
    },
    updateDayBookLastRefreshedDateTime(state, action: PayloadAction<number>) {
      state.dayBookLastRefreshedDateTime = action.payload;
    },
    updateHomePageLastRefreshedDateTime(state, action: PayloadAction<number>) {
      state.homePageLastRefreshedDateTime = action.payload;
    },
    updateChannelsLastRefreshedDateTime(state, action: PayloadAction<number>) {
      state.channelsLastRefreshedDateTime = action.payload;
    },
    updateThreadsLastRefreshedDateTime(state, action: PayloadAction<number>) {
      state.threadsLastRefreshedDateTime = action.payload;
    },
    updateSelectedPrincipal(state, action: PayloadAction<SelectedPrincipal>) {
      state.selectedPrincipalAccount = action.payload;
    },
    updatePrincipalDetailsModalOpen(state, action: PayloadAction<boolean>) {
      state.isPrincipalDetailsModalOpen = action.payload;
    },
    updateSelectedPreferences(
      state,
      action: PayloadAction<SelectedPreferences>
    ) {
      state.selectedPreferences = action.payload;
    },
    updatePaymentPreferences(state, action: PayloadAction<PaymentPreferences>) {
      console.log('updatePaymentPreferences', action);
      state.paymentPreferences = action.payload;
    },
    updateBankDetails(state, action: PayloadAction<CustomerBankDetails[]>) {
      state.customerBankDetails = action.payload;
    },
    updateAppVersion(state, action: PayloadAction<string>) {
      state.version = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      paymentApi.endpoints.getPaymentPreferences.matchFulfilled,
      (state, actions) => {
        const obj = {
          upi: actions.payload?.linkSetting?.upi ? true : false,
          netBanking: actions.payload?.linkSetting?.netBanking ? true : false,
          wallet: actions.payload?.linkSetting?.wallet ? true : false,
          creditCard: actions.payload?.linkSetting?.creditCard ? true : false,
          debitCard: actions.payload?.linkSetting?.debitCard ? true : false,
        };
        state.paymentPreferences = obj;
      }
    );
    builder.addMatcher(
      paymentApi.endpoints.updatePaymentPreferences.matchFulfilled,
      (state, actions) => {
        const obj = {
          upi: actions.payload?.linkSetting?.upi ? true : false,
          netBanking: actions.payload?.linkSetting?.netBanking ? true : false,
          wallet: actions.payload?.linkSetting?.wallet ? true : false,
          creditCard: actions.payload?.linkSetting?.creditCard ? true : false,
          debitCard: actions.payload?.linkSetting?.debitCard ? true : false,
        };
        state.paymentPreferences = obj;
      }
    );
  },
});

export const userPreferenceReducer = userPreferenceSlice.reducer;

export const {
  updateDateFilter,
  updateSelectedPrincipal,
  updateDayBookLastRefreshedDateTime,
  updateHomePageLastRefreshedDateTime,
  updateChannelsLastRefreshedDateTime,
  updateThreadsLastRefreshedDateTime,
  updatePrincipalDetailsModalOpen,
  updateSelectedPreferences,
  updatePaymentPreferences,
  updateBankDetails,
  updateAppVersion,
} = userPreferenceSlice.actions;

const getUserPreferenceState = (rootState: RootState): userPreferenceState =>
  rootState[USER_PREFERENCE_FEATURE_KEY];

export const getDateFilters = createSelector(
  getUserPreferenceState,
  (state) => state?.selectedDateFilters
);

export const getSelectedPrincipalAccount = createSelector(
  getUserPreferenceState,
  (state) => state?.selectedPrincipalAccount
);

export const getDayBookLastRefreshedDateTime = createSelector(
  getUserPreferenceState,
  (state) => state?.dayBookLastRefreshedDateTime
);
export const getHomePageLastRefreshedDateTime = createSelector(
  getUserPreferenceState,
  (state) => state?.homePageLastRefreshedDateTime
);
export const getChannelsLastRefreshedDateTime = createSelector(
  getUserPreferenceState,
  (state) => state?.channelsLastRefreshedDateTime
);
export const getThreadsLastRefreshedDateTime = createSelector(
  getUserPreferenceState,
  (state) => state?.threadsLastRefreshedDateTime
);

export const getUserPreferencesState = createSelector(
  getUserPreferenceState,
  (state) => state
);
export const getAppVersion = createSelector(
  getUserPreferenceState,
  (state) => state.version
);

export const getIsPrincipalModalState = createSelector(
  getUserPreferenceState,
  (state) => state?.isPrincipalDetailsModalOpen
);
