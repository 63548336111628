export interface Toast {
  message: string;
  type: ErrorVariants;
  canDisplay: boolean;
  responseStatus: string;
  position?: 'left' | 'right';
}
export enum ErrorVariants {
  DANGER = 'DANGER',
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
  NEUTRAL = 'NEUTRAL',
}
