import { ColumnConfig, CustomerSchemesColumnKey } from '../../model';

export const customerSchemeConfig: Record<
  CustomerSchemesColumnKey,
  ColumnConfig
> = {
  customerSchemeCode: {
    name: 'Scheme Code',
    field: 'checkboxNavigate',
    isCheckboxField: true,
  },
  schemeName: {
    name: 'Scheme Name',
  },
  status: { name: 'Status', field: 'toolTip' },
  skuCode: { name: 'SKU Code' },
  schemeTitle: { name: 'Title' },
  schemeType: { name: 'Scheme Type', field: 'textWithImage' },
  pts: {
    name: 'PTS',
    sortField: 'ProductPrice',
    alignment: 'flex-end',
  },
  minQty: {
    name: 'Min Qty',
    sortField: 'minimumQty',
    alignment: 'flex-end',
  },
  freeQty: {
    name: 'Free Qty',
    sortField: 'freeQty',
    alignment: 'flex-end',
  },
  discount: {
    name: 'Discount',
    sortField: 'discount',
    alignment: 'flex-end',
  },
  addToCart: {
    name: '',
    field: 'addToCart',
    permissions: ['order_write'],
  },
};
