import { CFADrawerKey, DrawerConfigValues } from '../model';

export const CFADrawerConfig: Record<CFADrawerKey, DrawerConfigValues> = {
  details: {
    tabName: 'Details',
    sections: [
      {
        sectionName: 'CFA Details',
        blocks: [
          {
            inputs: [
              {
                inputType: 'textInput',
                label: 'CFA Name',
                name: 'name',
                required: true,
                placeholder: 'Enter CFA Name',
              },
            ],
          },
          {
            inputs: [
              {
                inputType: 'textInput',
                label: 'CFA Code',
                name: 'code',
                required: true,
                placeholder: 'Ex: ED',
              },
              {
                inputType: 'textInput',
                label: 'Mobile Number',
                name: 'phone',
                required: true,
                placeholder: '9999999999',
                prefixType: 'mobile',
                inputValueType: 'mobileNumber',
              },
            ],
          },
          {
            inputs: [
              {
                inputType: 'textInput',
                label: 'Email',
                name: 'email',
                required: true,
                placeholder: 'Enter Email Id',
                inputValueType: 'email',
              },
            ],
          },
          {
            inputs: [
              {
                inputType: 'textInput',
                label: 'Pin Code',
                name: 'pin',
                required: true,
                placeholder: 'Enter Pin Code',
                inputValueType: 'postalCode',
              },
              {
                inputType: 'textInput',
                label: 'City',
                name: 'city',
                required: true,
                placeholder: 'Enter City Name',
              },
            ],
          },
          {
            inputs: [
              {
                inputType: 'dropdownInput',
                label: 'State',
                name: 'state',
                required: true,
                placeholder: 'Select',
              },
              {
                inputType: 'textInput',
                label: 'Country',
                name: 'country',
                required: true,
                placeholder: 'Select',
              },
            ],
          },
        ],
      },
    ],
  },
  // customers: {
  //   tabName: 'Customers',
  //   sections: [
  //     {
  //       sectionName: '',
  //       blocks: [],
  //     },
  //   ],
  // },
};
