export const PRINCIPAL_ROUTES = {};

export const PRINCIPAL_COMMERCE_APP_ROUTES = {
  // COMMERCE APP
  CUSTOMERS_LIST: '/admin/sales/customers',
  LEDGERS_LIST: '/admin/sales/ledgers/customers/:id',
  LEDGERS_CUSTOMER_LIST: '/admin/sales/lnfigedgers/customers',
  LEDGERS_PAYMENT_DETAILS:
    '/admin/sales/ledgers/customers/:id/paymentDetails/:paymentId',
  PRODUCTS_LIST: '/admin/sales/products',
  SCHEMES_LIST: '/admin/sales/schemes',
  ORDERS_LIST: '/admin/sales/orders',
  REPORTS_LIST: '/admin/reports',
  CFA_REPORTS: '/admin/cfa-reports',
  ORDER_LINE_REPORTS: '/admin/order-line-report',
  ORDER_REPORTS: '/admin/order-reports',
  SALES_LEADERBOARD_REPORTS: '/admin/sales-leaderboard-reports',
  // SALES_TEAM_ACTIVITY_MEMBER_DETAILS:
  //   '/admin/sales-team-activity/member-details',
  CUSTOMER_PERFORMACE_REPORTS: '/admin/customer-performance-reports',
  PRODUCT_PERFORMACE_REPORTS: '/admin/product-performance-reports',
  INVOICE_REPORTS: '/admin/invoice-reports',
  SALES_TEAM_CHECKIN_REPORTS: '/admin/sales-team-checkin-reports',

  PAYMENT_REPORTS: '/admin/payment-reports',
  // INVOICES_LIST: '/admin/sales/invoices',
  TRACK_PO_LIST: '/admin/sales/track-po',

  ORDERS_VIEW_DETAILS: '/admin/sales/orders/:id',
  RETURNS_LIST: '/admin/sales/returns',
  RETURNS_VIEW_DETAILS: '/admin/sales/returns/:id',

  CFA_SETTINGS: '/admin/sales/setting/cfa',
  DIVISION_SETTINGS: '/admin/sales/settings/divisions',
  CUSTOMER_SETTINGS: '/admin/customer-config',
  WORKSPACE_SETTINGS: '/admin/workspace-config',
  DIVISION_CONFIGURATION_SETTINGS: '/admin/division-config',
  CUSTOMER_GROUP_SETTINGS: '/admin/customer-group-config',
};

export const PRINCIPAL_COMMERCE_ROUTES_VARIABLES = {
  ORDERS_VIEW_DETAILS: (id: any) => `/admin/sales/orders/${id}`,
  TRACK_PO_VIEW_DETAILS: (id: any) => `/admin/sales/track-po/${id}`,
  RETURNS_VIEW_DETAILS: (id: any) => `/admin/sales/returns/${id}`,
  LEDGERS_PAYMENT_DETAILS: (paymentId: any) =>
    `/admin/sales/ledgers/${paymentId}`,
};
