import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/query/react';
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  getIsInvite,
  store,
  getRefreshToken,
  getToken,
  logOut,
  onRefreshToken,
  getIsLoggedInOnBehalf,
  getLoggedInOnBehalfWorkspaceId,
} from '@zonofi/seller-rtk-store';
import { getConfigValue } from '@zonofi/common';

interface RefreshTokenResponse {
  refreshToken: string;
  token: string;
}

export const REDUCER_PATH_ZONO_SELLER_API = 'zonoSellerApi';

const baseQuery = fetchBaseQuery({
  baseUrl: getConfigValue('API_ENDPOINT'),
  prepareHeaders: (headers) => {
    const accessToken = getToken(store.getState());
    if (!headers.has('authorization') && accessToken) {
      headers.set('authorization', `Bearer ${accessToken}`);
    }
    return headers;
  },
});

export const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError,
  any,
  FetchBaseQueryMeta
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  const refreshToken = getRefreshToken(store.getState());
  const accessToken = getToken(store.getState());
  const isInvite = getIsInvite(store.getState());
  // const refreshToken = store.getState().userAuth.refreshToken;
  // const accessToken = store.getState().userAuth.token;
  const isLoggedInOnBehalf = getIsLoggedInOnBehalf(store.getState());
  const loggedInOnBehalfWorkspaceId = getLoggedInOnBehalfWorkspaceId(
    store.getState()
  );

  if (
    !isInvite &&
    result.error &&
    result.error.status === 401 &&
    api.endpoint !== 'verifyOTP'
  ) {
    const refreshResult = await baseQuery(
      {
        url: isLoggedInOnBehalf
          ? 'regenerate/supportToken'
          : 'regenerate/token',
        method: 'POST',
        body: {
          refreshToken: refreshToken,
          token: accessToken,
          workspaceId: loggedInOnBehalfWorkspaceId,
        },
      },
      api,
      extraOptions
    );
    if (refreshResult.data) {
      //@ts-ignore
      const tokens: RefreshTokenResponse = refreshResult.data;
      api.dispatch(onRefreshToken(tokens));
      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(logOut());
    }
  }
  return result;
};

export const Api = createApi({
  reducerPath: REDUCER_PATH_ZONO_SELLER_API,
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
  tagTypes: [
    'User',
    'Payment',
    'ActiveOrder',
    'TeamInvites',
    'Notifications',
    'CartSummary',
    'workspace',
    'userData',
    'UpdatePoFile',
    'cfaList',
    'divsionList',
    'teamMemberList',
    'sellerAction',
  ],
});
