import React from 'react';
import './catalog-recent-search.scss';

export const CatalogRecentSearch: React.FC = () => {
  return (
    <div className="catalog-recent-search font-primary">
      <span className="catalog-recent-search-title">Recent Search</span>
    </div>
  );
};
