import { Api } from '../api/root-api';
import {
  workspaceDetails,
  AccountDetailsRequest,
  AccountDetailsResponse,
  CustomerInvoicesRequest,
  CustomerInvoicesResponse,
  InvoiceBysellerRequest,
  ListInvoices,
  InvoiceList,
  InvoiceRequest,
  CommunicationResponse,
  CommunicationRequest,
  InvoiceCommunicationRequest,
  Invoice,
  InvoiceUpdateRequest,
  InvoiceIds,
  InvoiceIdsRequest,
  InvoiceFilter,
  FilteredInvoices,
  InvoiceAgingGraphDataResponse,
  GraphDataRequest,
  InvoiceReportsGraphDataResponse,
  InvoiceDetailsRequest,
  CreateInvoiceRequest,
  InvoiceParsedDataRequest,
  InvoiceParsedDataResponse,
  createDispatchNoteReq,
  DispatchListResponse,
  DispatchListRequest,
  createDispatchNoteResponse,
  DispatchListDetailsRes,
} from './model';
import {
  addAccountDetails,
  getOutstandingCustomerList,
} from '../customers/slice';
import {
  ListInputItemOptions,
  addListInputItems,
  mapDataToInputItems,
} from '../filter';
import { getQueryParams } from '../helpers';
import { getConfigValue } from '@zonofi/common';

let INVITE_ID: string;

export const invoiceApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    getInvoiceDetailsBySeller: builder.mutation<
      ListInvoices,
      InvoiceBysellerRequest
    >({
      query(InvoiceBysellerRequest: InvoiceBysellerRequest) {
        return {
          url: `invoiceservice/invoiceBySeller`,
          method: 'GET',
          params: InvoiceBysellerRequest,
        };
      },
    }),
    getWorkSpaceDetailsById: builder.query<workspaceDetails, string>({
      query: (workSpaceId) => `guest/workspace/${workSpaceId}`,
    }),
    getAccountDetails: builder.mutation<
      AccountDetailsResponse,
      AccountDetailsRequest
    >({
      query(AccountDetailsRequest: AccountDetailsRequest) {
        const { inviteId } = AccountDetailsRequest;
        INVITE_ID = inviteId;
        return {
          url: `invoiceservice/party/vaccount`,
          method: 'GET',
          params: AccountDetailsRequest,
        };
      },
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(addAccountDetails({ accountDetails: data, id: INVITE_ID }));
        } catch (err) {
          console.log(err, 'dispatchErr');
        }
      },
    }),
    getCustomerInvoices: builder.mutation<
      CustomerInvoicesResponse,
      CustomerInvoicesRequest
    >({
      query(CustomerInvoicesRequest: CustomerInvoicesRequest) {
        return {
          url: `invoiceservice/partyAccountBook/balanceBySeller`,
          method: 'GET',
          params: CustomerInvoicesRequest,
        };
      },
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(getOutstandingCustomerList(data));
        } catch (err) {
          console.log(err, 'dispatchErr');
        }
      },
    }),
    getAllInvoice: builder.mutation<InvoiceList, InvoiceRequest>({
      query(arg) {
        const { workspaceId, body, ...params } = arg;
        return {
          url: `invoiceservice/invoices/all/${workspaceId}`,
          method: 'POST',
          body: body || {},
          params: params,
        };
      },
    }),
    getInvoiceList: builder.query<InvoiceList, InvoiceRequest>({
      query(arg) {
        const { workspaceId, ...params } = arg;
        const queryParams = getQueryParams(params);
        return {
          url: `invoiceservice/invoices/${workspaceId}?${queryParams}`,
          method: 'GET',
        };
      },
      async onQueryStarted(request, { dispatch, queryFulfilled }) {
        queryFulfilled.then((response) => {
          if (request?.dispatchFilter) {
            const customerInputItems = response?.data?.customers?.map((x) => ({
              id: x?.customerId,
              value: x?.name,
              selected: false,
              eitherOr: true,
            }));
            dispatch(
              addListInputItems({
                inputItems: customerInputItems,
                key: ListInputItemOptions.cfaPrincipalCustomers,
              })
            );
          }
        });
      },
    }),
    getCommunicationTemplate: builder.query<
      CommunicationResponse,
      CommunicationRequest
    >({
      query(arg) {
        return {
          url: `invoiceservice/communication/invoices/template`,
          method: 'GET',
          params: arg,
        };
      },
    }),
    triggerShareEvent: builder.mutation<
      {
        success: boolean;
        statusCode: number;
        responseStatus: string;
      },
      InvoiceCommunicationRequest
    >({
      query(arg) {
        return {
          url: `invoiceservice/communication/invoices`,
          method: 'POST',
          body: arg,
        };
      },
    }),
    getInvoiceDetails: builder.query<Invoice, InvoiceDetailsRequest>({
      query(arg) {
        const { inviteId, ...params } = arg;
        return {
          url: `invoiceservice/invoice/${inviteId}?includePayment=true`,
          method: 'GET',
          params: params,
        };
      },
    }),
    createInvoice: builder.mutation<Invoice, any>({
      query(arg) {
        const { workspaceId, ...body } = arg;
        return {
          url: `invoiceservice/invoice/${workspaceId}`,
          method: 'POST',
          body: body,
        };
      },
    }),
    updateInvoice: builder.mutation<Invoice, InvoiceUpdateRequest>({
      query(arg) {
        const { workspaceId, ...body } = arg;
        return {
          url: `invoiceservice/invoice/${workspaceId}`,
          method: 'POST',
          body: body,
        };
      },
    }),
    getAllIds: builder.mutation<InvoiceIds, InvoiceIdsRequest>({
      query(arg) {
        const { workspaceId, ...body } = arg;
        return {
          url: `invoiceservice/reports/invoices/allIds/${workspaceId}`,
          body: body,
          method: 'POST',
        };
      },
    }),
    filterInvoices: builder.mutation<FilteredInvoices, InvoiceFilter>({
      query(arg) {
        const { workspaceId, ...body } = arg;
        return {
          url: `invoiceservice/reports/invoices/${workspaceId}`,
          body: body,
          method: 'POST',
        };
      },
    }),
    downloadInvoiceDetails: builder.query<any, string>({
      query(arg) {
        return {
          url: `invoiceservice/invoice/download-invoice-details?invoiceId=${arg}`,
          method: 'GET',
          responseHandler(response) {
            return response.arrayBuffer();
          },
        };
      },
    }),
    getInvoiceAgingGraphData: builder.query<
      InvoiceAgingGraphDataResponse,
      GraphDataRequest
    >({
      query(params) {
        return {
          url: `${getConfigValue(
            'API_ENDPOINT'
          )}api/reports/get-invoice-aging-report`,
          method: 'GET',
          params: params,
        };
      },
    }),
    getInvoiceDailyReportsGraphData: builder.query<
      InvoiceReportsGraphDataResponse,
      GraphDataRequest
    >({
      query(params) {
        return {
          url: `${getConfigValue(
            'API_ENDPOINT'
          )}api/reports/get-daily-invoice-reports`,
          method: 'GET',
          params: params,
        };
      },
    }),
    getInvoiceParsedData: builder.query<
      InvoiceParsedDataResponse,
      InvoiceParsedDataRequest
    >({
      query(params) {
        const { body, ...otherParams } = params;
        return {
          url: `hub/bot/api/v1/messages`,
          method: 'POST',
          params: otherParams,
          body: body,
        };
      },
    }),
    createDispatchNote: builder.mutation<
      createDispatchNoteResponse,
      createDispatchNoteReq
    >({
      query(args) {
        return {
          url: `/invoiceservice/dispatch/${args.workspaceId}`,
          method: 'POST',
          body: args,
        };
      },
    }),
    getDispatchNoteList: builder.query<
      DispatchListResponse,
      DispatchListRequest
    >({
      query(args) {
        return {
          url: `/invoiceservice/dispatch/${args.workspaceId}?${getQueryParams(
            args.body
          )}`,
          method: 'get',
        };
      },
      async onQueryStarted(request, { dispatch, queryFulfilled }) {
        queryFulfilled.then((response) => {
          const customerList = mapDataToInputItems(
            response.data.customers,
            'customerId',
            'name'
          )?.filter((x: any) => x.value);
          dispatch(
            addListInputItems({
              inputItems: customerList,
              key: ListInputItemOptions.customers,
            })
          );
        });
        const statsArray = [
          { id: '0', name: 'Delivered' },
          { id: '1', name: 'Dispatched' },
        ];
        const statusList = mapDataToInputItems(statsArray, 'id', 'name').filter(
          (x: any) => x.value
        );

        dispatch(
          addListInputItems({
            inputItems: statusList,
            key: ListInputItemOptions.dispatchStatus,
          })
        );
      },
    }),
    getDispatchNoteDetails: builder.query<
      DispatchListDetailsRes,
      { dispatchId: string; workspaceId: string }
    >({
      query(args) {
        return {
          url: `/invoiceservice/dispatch/details/${args.dispatchId}/${args.workspaceId}`,
          method: 'get',
        };
      },
    }),
  }),
});

export const {
  useGetInvoiceDetailsBySellerMutation,
  useLazyGetWorkSpaceDetailsByIdQuery,
  useGetAccountDetailsMutation,
  useGetCustomerInvoicesMutation,
  useLazyGetInvoiceListQuery,
  useLazyGetCommunicationTemplateQuery,
  useLazyGetInvoiceDetailsQuery,
  useCreateInvoiceMutation,
  useUpdateInvoiceMutation,
  useTriggerShareEventMutation,
  useGetAllIdsMutation,
  useFilterInvoicesMutation,
  useLazyDownloadInvoiceDetailsQuery,
  useGetInvoiceAgingGraphDataQuery,
  useLazyGetInvoiceAgingGraphDataQuery,
  useLazyGetInvoiceDailyReportsGraphDataQuery,
  useGetInvoiceDailyReportsGraphDataQuery,
  useGetAllInvoiceMutation,
  useLazyGetInvoiceParsedDataQuery,
  useCreateDispatchNoteMutation,
  useLazyGetDispatchNoteListQuery,
  useLazyGetDispatchNoteDetailsQuery,
} = invoiceApi;
