import { StoredFolder } from '../model';
import { CommsState } from '../slice';

interface UseUpdateChannelListForUnreadProps {
  commsState: CommsState;
  folderData: { folders: Record<string, StoredFolder> };
  searchQuery: string;

  // Add any additional props needed by the hook
}

export const updateChannelListForSearchQueryHelper = ({
  commsState,
  folderData,
  searchQuery,
}: UseUpdateChannelListForUnreadProps): Record<string, StoredFolder> => {
  function searchByName(
    data: { folders: Record<string, StoredFolder> },
    query: string
  ): Record<string, StoredFolder> {
    const results: Record<string, StoredFolder> = {};

    function searchItems(
      rootFolders: StoredFolder[],
      parentId?: string | null
    ) {
      for (const rootFolder of rootFolders) {
        // Check if the item name matches the query
        const itemNameMatches = rootFolder.name
          .toLowerCase()
          .includes(query.toLowerCase());

        // If root folder name matches, return entire folder , dont perform any additional query
        if (itemNameMatches) {
          results[rootFolder.id] = rootFolder;
        } else {
          const updatedSubFolders: StoredFolder[] = [];
          // Subfolder case
          if (rootFolder.subFolders) {
            for (const subfolder of rootFolder.subFolders) {
              const subFolderNameMatches = subfolder.name
                .toLowerCase()
                .includes(query.toLowerCase());

              if (subFolderNameMatches) {
                updatedSubFolders.push(subfolder);
              } else {
                //If subfolder name doesnt match. find if any of the channel inside matches only then push filtered channels
                const subChannelMatches = subfolder.channels?.filter((chnl) =>
                  commsState.channels[chnl].channelName
                    .toLowerCase()
                    .includes(query.toLowerCase())
                );
                if (subChannelMatches && subChannelMatches?.length > 0) {
                  updatedSubFolders.push({
                    ...subfolder,
                    channels: subChannelMatches,
                  });
                }
              }
            }
          }

          const updatedSubChannels: string[] = [];

          if (rootFolder.channels) {
            for (const channel of rootFolder.channels) {
              const channelNameMatches = commsState.channels[
                channel
              ].channelName
                .toLowerCase()
                .includes(query.toLowerCase());

              if (channelNameMatches) {
                updatedSubChannels.push(channel);
              }
            }
          }
          if (updatedSubChannels.length > 0 || updatedSubFolders.length > 0) {
            results[rootFolder.id] = {
              ...rootFolder,
              channels: updatedSubChannels,
              subFolders: updatedSubFolders,
            };
          }
        }

        // Include parent folders in the results with parentId preserved
        if (parentId && results[parentId]) {
          results[rootFolder.id] = { ...rootFolder, parentId };
        }
      }
    }

    const foldersArray = Object.values(data.folders);
    searchItems(foldersArray);

    return results;
  }

  return searchByName(folderData, searchQuery);
};
