import React from 'react';
import { LoadingSpinner, SVGS } from '@zonofi/zono-money-design-system';
import './catalog-image-search-upload.scss';

interface CatalogImageSearchUploadProps {
  uploadedFile: File;
  uploadedImage: any;
  onOpenUpload: () => any;
  isParsing: boolean;
  error: {
    show: boolean;
    msg: string;
  };
  onTryAgain: () => any;
}

export const CatalogImageSearchUpload: React.FC<
  CatalogImageSearchUploadProps
> = ({
  uploadedFile,
  uploadedImage,
  onOpenUpload,
  isParsing,
  error,
  onTryAgain,
}) => {
  return uploadedFile && uploadedImage ? (
    <>
      <div className="catalog-image-search-body-preview-container">
        <img
          src={uploadedImage}
          className="catalog-image-search-body-preview"
        />
      </div>
      {isParsing && (
        <div className="catalog-image-search-body-preview-loader">
          <LoadingSpinner size={'small'} />
          <span>Fetching products for you...</span>
        </div>
      )}
      {error?.show && (
        <div className="catalog-image-search-body-preview-error">
          <img src={SVGS.WarningRed} width={25} />
          <span>Couldn't find similar products, Try again</span>
          <span>{error?.msg}</span>
          <div
            className="catalog-image-search-body-preview-error-try-again"
            onClick={onTryAgain}
          >
            Try Again
          </div>
        </div>
      )}
    </>
  ) : (
    <>
      <img src={SVGS.ImageUploadCharacter} />
      <span className="font-primary-semibold">Search with your photos</span>
      <span>Find similar products</span>
      <div className="catalog-image-search-body-upload-btn">
        <span className=" font-primary-semibold" onClick={onOpenUpload}>
          Upload Photo
        </span>
      </div>
    </>
  );
};
