import { Api } from '../api';
import { addListInputItems, ListInputItemOptions } from '../filter';
import {
  CreateBuyXGetYRequest,
  Scheme,
  Schemes,
  SchemesRequest,
  CreateSchemeDiscountRequest,
} from './model';

export const schemesApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    getAllSchemes: builder.mutation<
      Schemes,
      {
        principalWorkspaceId: string;
        params?: SchemesRequest;
      }
    >({
      query(arg) {
        const { params } = arg;
        const { body, ...restParams } = params || {};
        return {
          url: `hub/commerce-v2/scheme/${arg.principalWorkspaceId}?sellerWorkspaceId=${arg.principalWorkspaceId}`,
          method: 'POST',
          params: restParams,
          body: body,
        };
      },
      async onQueryStarted(request, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then((response) => {
            if (request?.params?.dispatchFilters) {
              const cfaInputItems = response?.data?.cfa?.map((x) => {
                return {
                  id: x.id,
                  value: x.supplierName,
                  selected: false,
                };
              });
              dispatch(
                addListInputItems({
                  inputItems: cfaInputItems,
                  key: ListInputItemOptions.cfa,
                })
              );

              const divisionInputItems = response?.data?.division?.map((x) => {
                return {
                  id: x?.divisionId || x?.id,
                  value: x.name,
                  selected: false,
                };
              });
              dispatch(
                addListInputItems({
                  inputItems: divisionInputItems,
                  key: ListInputItemOptions.divisions,
                })
              );
            }
          })
          .catch((error) => {
            console.log(error);
          });
      },
    }),

    getScheme: builder.query<
      Scheme,
      { principalWorkspaceId: string; id: number }
    >({
      query(arg) {
        return {
          url: `hub/commerce-v2/scheme/${arg.principalWorkspaceId}/${arg.id}?sellerWorkspaceId=${arg.principalWorkspaceId}`,
          method: 'GET',
        };
      },
    }),
    createBuyXGetY: builder.mutation<Scheme, CreateBuyXGetYRequest>({
      query(arg) {
        return {
          url: `hub/commerce-v2/scheme/v2/buyXgetYfree/${arg.workspaceId}?sellerWorkspaceId=${arg.workspaceId}`,
          method: 'POST',
          body: arg,
        };
      },
    }),
    createProductDiscount: builder.mutation<
      Scheme,
      CreateSchemeDiscountRequest
    >({
      query(arg) {
        return {
          url: `hub/commerce-v2/scheme/v2/productdiscount/${arg?.workspaceId}?sellerWorkspaceId=${arg.workspaceId}`,
          method: 'POST',
          body: arg,
        };
      },
    }),
  }),
});

export const {
  useCreateBuyXGetYMutation,
  useCreateProductDiscountMutation,
  useLazyGetSchemeQuery,
  useGetAllSchemesMutation,
} = schemesApi;
