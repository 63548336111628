import { DrawerConfigValues, HeadDivisionDrawerKey } from '../model';

export const HeadDivisionDrawerConfig: Record<
  HeadDivisionDrawerKey,
  DrawerConfigValues
> = {
  details: {
    tabName: '',
    sections: [
      {
        sectionName: '',
        blocks: [
          {
            inputs: [
              {
                inputType: 'textInput',
                name: 'name',
                label: 'Head Division Name',
                required: true,
              },
            ],
          },
          {
            inputs: [
              {
                inputType: 'textInput',
                name: 'code',
                label: 'Code',
                required: true,
              },
            ],
          },
        ],
      },
    ],
  },
};
