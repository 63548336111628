import _ from 'lodash';
import { Api } from '../api';
import {
  CreateIssueThread,
  SearchThreadsResponse,
  Thread,
  UpdateThreadStateResponse,
  CreateCustomerThreadResonse,
  CreateCustomerThreadRequest,
  CreateThread,
  filterCustomerThreadResponse,
  filterCustomerThreadRequest,
  IssueStatus,
  GetThreadFiltersRequest,
  GetThreadFiltersResponse,
  ApplyThreadsFilterRequest,
  ApplyThreadsFilterResponse,
  CustomerThreadStatusFilter,
  CustomerThreadTreeTypeFilter,
} from './model';
import {
  updateCurrentChannel,
  updateThreadTabNavigationValue,
  upsertChannel,
  ChannelTypeEnum,
} from '../channel';
import { isWeb } from '@zonofi/common';
import {
  TreeInput,
  TreeInputItemOptions,
  addListInputItems,
  addTreeInputItems,
  mapDataToInputItemsFromAppliedFilter,
} from '../filter';
import {
  getCommonThreadFilterItems,
  handleConvertToTreeInput,
  handleCustomerStatusFilters,
  handleIssueStatusFilters,
} from './helper';

export const threadApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    createIssueThread: builder.mutation<Thread | Thread[], CreateIssueThread>({
      query(arg) {
        return {
          url: `hub/communication-v2/api/threads/issue`,
          method: 'POST',
          body: arg,
        };
      },
      async onQueryStarted(request, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then((response) => {
            const { data } = response;
            let thread: Thread;
            if (_.isArray(data)) {
              thread = data[0];
            } else {
              thread = data;
            }
            const { id, channelName } = thread;
            dispatch(
              upsertChannel({
                [id]: {
                  ...thread,
                  isActive: true,
                  type: ChannelTypeEnum.ISSUETHREAD,
                  latestMessage: channelName,
                  latestMessageTimeToken: Date.now() + '0000',
                },
              })
            );
            if (isWeb()) {
              dispatch(
                updateThreadTabNavigationValue(ChannelTypeEnum.ISSUETHREAD)
              );
              dispatch(updateCurrentChannel(id));
            }
          })
          .catch((error) => console.log(error));
      },
    }),
    searchThreads: builder.query<
      SearchThreadsResponse,
      {
        searchKey: string;
        sellerWorkspaceId: string;
      }
    >({
      query(arg) {
        return {
          url: `hub/communication-v2/api/threads`,
          method: 'GET',
          params: arg,
        };
      },
    }),
    updateThreadState: builder.mutation<
      UpdateThreadStateResponse,
      {
        sellerWorkspaceId: string;
        isActive?: boolean;
        issueStatus?: IssueStatus;
        channelId: string;
        noChannelUpdate?: boolean;
      }
    >({
      query(arg) {
        return {
          url: `hub/communication-v2/api/threads`,
          method: 'PUT',
          body: arg,
        };
      },
      async onQueryStarted(request, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then((response) => {
            const { data } = response;
            const { id, issueStatus } = data;
            dispatch(
              upsertChannel({
                [id]: {
                  issueStatus,
                },
              })
            );
            if (request?.noChannelUpdate) {
              return;
            }
            dispatch(updateCurrentChannel(id));
          })
          .catch((error) => console.log(error));
      },
    }),
    createCustomerThread: builder.mutation<
      CreateCustomerThreadResonse[],
      CreateCustomerThreadRequest
    >({
      query(arg) {
        return {
          url: `customers/${arg.workspaceId}?doCreateCustomerThread=true`,
          method: 'POST',
          body: arg.body,
        };
      },
    }),
    filterCustomerThread: builder.mutation<
      filterCustomerThreadResponse[],
      filterCustomerThreadRequest
    >({
      query(arg) {
        const { sellerWorkspaceId, inviteId, ...body } = arg;
        return {
          url: `hub/communication-v2/api/threads/list/${inviteId}`,
          method: 'POST',
          params: { sellerWorkspaceId },
          body: body,
        };
      },
    }),
    createThread: builder.mutation<Thread, CreateThread>({
      query(arg) {
        return {
          url: `hub/communication-v2/api/threads/groupThread`,
          method: 'POST',
          body: arg,
        };
      },
      async onQueryStarted(request, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then((response) => {
            const { data: thread } = response;
            const { id, channelName, channelType, customerId } = thread;
            const type =
              channelType === 'C'
                ? ChannelTypeEnum.CUSTOMERTHREAD
                : ChannelTypeEnum.GROUPTHREAD;
            dispatch(
              upsertChannel({
                [id]: {
                  ...thread,
                  isActive: true,
                  type,
                  latestMessage: channelName,
                  latestMessageTimeToken: Date.now() + '0000',
                  customerId: customerId || undefined,
                },
              })
            );
            dispatch(updateThreadTabNavigationValue(type));
            dispatch(updateCurrentChannel(id));
          })
          .catch((error) => console.log(error));
      },
    }),
    getThreadFilters: builder.mutation<
      GetThreadFiltersResponse,
      GetThreadFiltersRequest
    >({
      query(arg) {
        return {
          url: `hub/communication-v2/api/threads/filters/${arg?.workspaceId}`,
          method: 'POST',
          body: arg?.body,
          params: arg?.params,
        };
      },
      async onQueryStarted(request, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then((response) => {
            const { data } = response;
            const inputMappings = getCommonThreadFilterItems(data);
            inputMappings.forEach(({ data, idKey, valueKey, optionKey }) => {
              const inputItems = mapDataToInputItemsFromAppliedFilter(
                data,
                idKey,
                valueKey,
                optionKey
              )?.filter((x: any) => x.value);
              dispatch(addListInputItems({ inputItems, key: optionKey }));
            });

            if (request?.body?.channelType === 'C') {
              handleCustomerStatusFilters(
                dispatch,
                data?.status as CustomerThreadStatusFilter
              );

              const routesData = handleConvertToTreeInput(
                data?.routes as CustomerThreadTreeTypeFilter
              );
              dispatch(
                addTreeInputItems({
                  inputItems: routesData?.TreeData as TreeInput[],
                  key: TreeInputItemOptions.threadRoutes,
                })
              );

              const segmentData = handleConvertToTreeInput(
                data?.customerSegment as CustomerThreadTreeTypeFilter
              );
              dispatch(
                addTreeInputItems({
                  inputItems: segmentData?.TreeData as TreeInput[],
                  key: TreeInputItemOptions.threadSegments,
                })
              );
            } else if (
              request?.body?.channelType === ChannelTypeEnum.ISSUETHREAD
            ) {
              handleIssueStatusFilters(dispatch, data);
            }
          })
          .catch((error) => console.log('thread filter error', error));
      },
    }),

    applyThreadsFilter: builder.mutation<
      ApplyThreadsFilterResponse[],
      ApplyThreadsFilterRequest
    >({
      query(arg) {
        return {
          url: `hub/communication-v2/api/threads/list/${arg?.workspaceId}`,
          method: 'POST',
          body: arg?.body,
          params: arg?.params,
        };
      },
    }),
  }),
});

export const {
  useCreateIssueThreadMutation,
  useLazySearchThreadsQuery,
  useUpdateThreadStateMutation,
  useCreateCustomerThreadMutation,
  useCreateThreadMutation,
  useFilterCustomerThreadMutation,
  useGetThreadFiltersMutation,
  useApplyThreadsFilterMutation,
} = threadApi;
