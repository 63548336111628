import { ColumnConfig, PrincipalReportsColumnKey } from '../../model';

export const cfaOrderReportConfig: Record<
  PrincipalReportsColumnKey,
  ColumnConfig
> = {
  sNo: {
    name: 'S.No',
  },
  orderDate: {
    name: 'Date',
    sortField: 'orderDate',
  },
  tags: {
    name: 'Tags',
  },
  cfa: {
    name: 'CFAs',
    sortField: 'cfaname',
  },
  skuCode: {
    name: 'SKU Code',
  },
  productTitle: {
    name: 'Product Title',
    field: 'component',
  },
  orders: {
    name: 'Orders',
    sortField: 'totalorders',
    alignment: 'flex-end',
  },
  orderQty: {
    name: ' Ord. Qty',
    sortField: 'totalqty',
    alignment: 'flex-end',
  },
  orderAmt: {
    name: 'Ord Amt',
    sortField: 'totalvalue',
    alignment: 'flex-end',
  },
};
