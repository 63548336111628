import { ColumnConfig, PrincipalSchemesColumnKey } from '../../model';

export const principalSchemeConfig: Record<
  PrincipalSchemesColumnKey,
  ColumnConfig
> = {
  schemeCode: { name: 'Scheme Code', isHyperlink: true },
  schemeName: {
    name: 'Scheme Name',
  },
  status: { name: 'Status', field: 'toolTip' },
  skuCode: { name: 'SKU Code' },
  schemeTitle: { name: 'Title' },
  schemeType: { name: 'Scheme Type', field: 'textWithImage' },
  pts: {
    name: 'PTS',
    sortField: 'ProductPrice',
    alignment: 'flex-end',
  },
  minQty: {
    name: 'Min Qty',
    sortField: 'minimumQty',
    alignment: 'flex-end',
  },
  freeQty: {
    name: 'Free Qty',
    sortField: 'freeQty',
    alignment: 'flex-end',
  },
  discount: {
    name: 'Discount',
    sortField: 'discount',
    alignment: 'flex-end',
  },
  action: {
    name: '',
    field: 'component',
  },
};
