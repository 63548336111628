import { SVGS } from '@zonofi/zono-money-design-system';
import React from 'react';
import './product-details-header.scss';
import { CartIcon } from '../cart-icon/cart-icon';
import { useNavigate, useSearchParams } from 'react-router-dom';
interface ProductDetailsHeaderProps {
  productName?: string;
  totalCartItems: number;
}
export const ProductDetailsHeader: React.FC<ProductDetailsHeaderProps> = ({
  productName,
  totalCartItems,
}) => {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  
  return (
    <div className="product-details-header">
      <div className="left-content">
        <div
          className="back-button"
          onClick={() => {
            navigate(-1);
          }}
        >
          <img
            src={SVGS.LeftArrowBlack}
            alt="back"
            width={'24px'}
            height={'24px'}
          />
        </div>
        <div className="product-name font-primary-semibold" title={productName}>
          {productName}
        </div>
      </div>
      <div className="right-content">
        {params?.get('hideAddToCart') !== 'true' && (
          <CartIcon
            itemsCount={totalCartItems}
            onCartClick={() => {
              navigate('/cart');
            }}
          />
        )}
      </div>
    </div>
  );
};
