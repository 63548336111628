import React from 'react';
import { SVGS } from '@zonofi/zono-money-design-system';
import { WEBROUTES } from '@zonofi/zono-money-helpers';
import { useNavigate, useSearchParams } from 'react-router-dom';
import './order-details-empty-cart.scss';
import { FallBackScreen } from '../fallback-screen/fallback-screen';

export const OrderDetailsEmptyCart: React.FC = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const fromScreen = params?.get('from');

  const onClick = () => {
    if (fromScreen === 'campaign' || fromScreen === 'price-list') {
      navigate(-1);
    } else {
      navigate(WEBROUTES.CATALOGUE);
    }
  };

  return (
    <div className="order-details-empty-cart">
      <div className="order-details-empty-cart-img">
        <img src={SVGS.ZotokLogoGreen} alt="zotok-logo" />
      </div>
      <FallBackScreen
        image={SVGS.EmptyCart}
        message="Empty Cart Alert!"
        description="Don’t wait - shop and fill it with your favourites."
        buttonName="Add Items"
        onButtonClick={onClick}
      />
    </div>
  );
};
