import React from 'react';
import './catalog-list.scss';
import { getFormattedAmount } from '@zonofi/common';
import { SVGS } from '@zonofi/zono-money-design-system';
import {
  CatalogListData,
  CatalogueView,
  LineInOrder,
  SchemeTypes,
  getCustomerInvite,
} from '@zonofi/zono-money-store';
import { CategoryTag } from './category-tag';
import { useCatalogList } from './useCatalogList';
import { useAppSelector } from '@zonofi/zono-money-helpers';
import { isNumber } from 'lodash';
import { MoqErrorSnackBar } from '../moq-error-snackbar/moq-error-snackbar';
import { RenderAddButton } from '../catalog-list-add-to-cart-button/catalog-list-add-to-cart-button';

interface CatalogListProps {
  products: CatalogListData[];
  onDecrement: (
    item: LineInOrder,
    sellerWorkspaceId: string,
    customerId: string
  ) => any;
  onIncrement: (
    item: LineInOrder,
    sellerWorkspaceId: string,
    customerId: string
  ) => any;
  onAddToCart: (item: CatalogListData) => any;
  onDelete: (
    item: LineInOrder,
    sellerWorkspaceId: string,
    customerId: string
  ) => any;
  onChangeQuantity: (
    item: LineInOrder,
    newQty: number,
    sellerWorkspaceId: string,
    customerId: string
  ) => any;
  showImage: boolean;
  showPrice: boolean;
  listView: boolean;
  catalogueView: CatalogueView;
}

export const CatalogList: React.FC<CatalogListProps> = ({
  products,
  onAddToCart,
  onIncrement,
  onDecrement,
  onDelete,
  onChangeQuantity,
  showImage,
  showPrice,
  listView,
  catalogueView,
}) => {
  const inviteDetails = useAppSelector(getCustomerInvite);
  const { seller, inviteId } = inviteDetails;
  const { quantityMap, setQuantityMap, openProductDetails } = useCatalogList(
    products,
    catalogueView,
    showPrice
  );

  return products?.map((item, index) => {
    const disc = Number(item?.promotions?.[0]?.discount);
    const discType = item?.promotions?.[0]?.promotionType;
    const sortedCategories = [...(item?.categories ?? [])].sort(
      (a, b) => a.categoryName.length - b.categoryName.length
    );
    let showStrikeThroughPrice = false;
    let margin = 0;
    if (item?.ptr && item?.pts) {
      margin = item.ptr / 100 - item.pts / 100;
    }
    showStrikeThroughPrice =
      !isNaN(disc) &&
      discType !== 'buy_x_get_y_free' &&
      item?.isAddedToCart &&
      item?.addedQty >= item?.minOrderQty;

    return (
      <>
        <div
          className={`catalog-list-item${listView ? '-grid-' : '-'}container`}
          key={index}
        >
          <div
            className={`catalog-list-item${listView ? '-grid' : ''}`}
            onClick={() => openProductDetails(item?.productVariantId)}
          >
            {showImage && (
              <div
                className={`catalog-list-item${
                  listView ? '-grid-' : '-'
                }img-container`}
              >
                <img
                  src={
                    item?.productImage
                      ? item?.productImage
                      : SVGS.DefaultProduct
                  }
                  alt=""
                  className={`catalog-list-item${listView ? '-grid-' : '-'}img`}
                />
                {listView && (
                  <RenderAddButton
                    item={item}
                    seller={seller}
                    inviteId={inviteId}
                    onDelete={onDelete}
                    onIncrement={onIncrement}
                    onDecrement={onDecrement}
                    onChangeQuantity={onChangeQuantity}
                    setQuantityMap={setQuantityMap}
                    quantityMap={quantityMap}
                    onAddToCart={onAddToCart}
                    listView={listView}
                  />
                )}
              </div>
            )}
            <div
              className={`catalog-list-item${
                listView ? '-grid-' : '-'
              }info-container`}
            >
              <div
                className={`catalog-list-item${listView ? '-grid-' : '-'}info`}
              >
                <span
                  className={`catalog-list-item${
                    listView ? '-grid-' : '-'
                  }info-name font-primary`}
                >
                  {item?.name}
                </span>
                {showPrice && (
                  <span className="catalog-list-item-info-price-container font-primary-semibold">
                    {showStrikeThroughPrice ? (
                      <div className="catalog-list-item-info-price-container">
                        <span className="catalog-list-item-info-price-container-striked">
                          {getFormattedAmount((item?.pts ?? 0) / 100) ?? 0}
                        </span>
                        {getFormattedAmount(item?.discountedPrice ?? 0) ?? 0}
                      </div>
                    ) : (
                      <span className="catalog-list-item-info-price-container font-primary-semibold">
                        {getFormattedAmount((item?.pts ?? 0) / 100) ?? 0}
                      </span>
                    )}
                    {isNumber(item?.ptr) && item?.ptr > 1 && <span> • </span>}
                    {isNumber(item?.ptr) && item?.ptr > 1 && (
                      <span className="catalog-list-item-info-price-container-margin font-primary">
                        {`Margin: ₹${margin.toFixed(2)}`}
                      </span>
                    )}
                  </span>
                )}
                {sortedCategories && sortedCategories?.length !== 0 && (
                  <div className="catalog-list-item-info-tags">
                    {sortedCategories?.length !== 0 &&
                      sortedCategories?.slice(0, 2)?.map((category, jindex) => {
                        return <CategoryTag category={category} key={jindex} />;
                      })}
                    {sortedCategories.slice(2)?.length > 0 && (
                      <CategoryTag
                        category={{
                          categoryCode: '',
                          categoryId: 0,
                          categoryName:
                            '+' + sortedCategories?.slice(2)?.length,
                        }}
                      />
                    )}
                  </div>
                )}
                {item?.promotions && item?.promotions?.length !== 0 && (
                  <div className="catalog-list-item-promotion">
                    <img src={SVGS.DiscountIcon} alt="discount" />
                    <span className="catalog-list-item-promotion-text font-primary-semibold">
                      {item?.promotions?.[0]?.promotionType ===
                      SchemeTypes.BUYXGETY
                        ? `Buy ${item?.promotions?.[0]?.minQtyASC} Qty, get ${item?.promotions?.[0]?.freeQtyASC} free`
                        : `Buy ${item?.promotions?.[0]?.minQtyASC} Qty, get ${item?.promotions?.[0]?.discount} % off`}
                    </span>
                  </div>
                )}
              </div>

              {!listView && (
                <div className="catalog-list-item-button-container">
                  <RenderAddButton
                    item={item}
                    seller={seller}
                    inviteId={inviteId}
                    onDelete={onDelete}
                    onIncrement={onIncrement}
                    onDecrement={onDecrement}
                    onChangeQuantity={onChangeQuantity}
                    setQuantityMap={setQuantityMap}
                    quantityMap={quantityMap}
                    onAddToCart={onAddToCart}
                    listView={listView}
                  />
                </div>
              )}
            </div>
          </div>
          {/* {item?.moqError && (
              <span className="catalog-list-item-container-moq-error font-primary">
                Qty cannot be less than MOQ
              </span>
            )} */}
        </div>
        {item?.moqError && (
          <div className="catalog-list-moq-error-container">
            <MoqErrorSnackBar number={item.minOrderQty} />
          </div>
        )}
      </>
    );
  });
};
