import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { isEmpty, isArray, defaultTo } from 'lodash';
import { RootState } from '../root';
import { ProductVariants } from '../products';
import { WhatsappTemplateData } from '../channel';

export interface campaignState {
  selectedIds: number[];
  campaignProducts: ProductVariants[];
  headerSteps: { id: number; label: string }[];
  bodyPartKeysData: { key: string; label: string; value: string }[];
  ctwaInfo: { [key: string]: string };
  campaignType: string;
  mediafile: {
    path: string;
    type: string;
  } | null;
  templateData: WhatsappTemplateData | null;
  campaignName: string;
  templateCampaignType: string;
  screenRoute: string;
}

export const initialcampaignState: campaignState = {
  selectedIds: [],
  campaignProducts: [],
  headerSteps: [],
  bodyPartKeysData: [],
  ctwaInfo: {},
  campaignType: '',
  mediafile: null,
  templateData: null,
  campaignName: '',
  templateCampaignType: '',
  screenRoute: '',
};

const CAMPAIGN_FEATURE_KEY = 'campaign';

export const campaignSlice = createSlice({
  name: CAMPAIGN_FEATURE_KEY,
  initialState: initialcampaignState,
  reducers: {
    updateSelectedIds(state, action: PayloadAction<number | number[]>) {
      if (isArray(action.payload)) {
        state.selectedIds = action.payload;
      } else {
        const idIndex = state.selectedIds.indexOf(action.payload);
        if (idIndex > -1) {
          state.selectedIds.splice(idIndex, 1);
        } else {
          state.selectedIds.push(action.payload);
        }
      }
    },
    updateCampaignsProductData(
      state,
      action: PayloadAction<ProductVariants[]>
    ) {
      if (!isEmpty(action.payload)) {
        state.campaignProducts = action.payload;
      } else {
        state.campaignProducts = [];
      }
    },
    removeSelectedIds(state) {
      state.selectedIds = [];
    },
    updateHeaderSteps(
      state,
      action: PayloadAction<{ id: number; label: string }[]>
    ) {
      if (!isEmpty(action.payload)) {
        state.headerSteps = action.payload;
      } else {
        state.headerSteps = [];
      }
    },
    updateTemplateCampaignData(
      state,
      action: PayloadAction<{
        bodyPartKeysData?: { key: string; label: string; value: string }[];
        ctwaInfo?: { [key: string]: string };
        mediafile?: {
          path: string;
          type: string;
        } | null;
        campaignType?: string;
        campaignName?: string;
      }>
    ) {
      const {
        bodyPartKeysData,
        ctwaInfo,
        mediafile,
        campaignType,
        campaignName,
      } = action.payload;

      state.bodyPartKeysData = defaultTo(bodyPartKeysData, []);
      state.ctwaInfo = defaultTo(ctwaInfo, {});
      state.mediafile = defaultTo(mediafile, null);
      state.campaignType = defaultTo(campaignType, '');
      state.campaignName = defaultTo(campaignName, '');
    },
    updateTemplateData(state, action: PayloadAction<WhatsappTemplateData>) {
      state.templateData = action.payload;
    },
    updateTemplateCampaignType(state, action: PayloadAction<string>) {
      state.templateCampaignType = action.payload;
    },
    updateScreenRoute(state, action: PayloadAction<string>) {
      state.screenRoute = action.payload;
    },
  },
});

export const campaignReducer = campaignSlice.reducer;

export const {
  updateSelectedIds,
  removeSelectedIds,
  updateCampaignsProductData,
  updateHeaderSteps,
  updateTemplateCampaignData,
  updateTemplateData,
  updateTemplateCampaignType,
  updateScreenRoute
} = campaignSlice.actions;

const getcampaignState = (rootState: RootState): campaignState =>
  rootState[CAMPAIGN_FEATURE_KEY];

export const getSelectedIds = createSelector(
  getcampaignState,
  (state) => state.selectedIds
);

export const getCampaignsProduct = createSelector(
  getcampaignState,
  (state) => state.campaignProducts
);

export const getHeaderSteps = createSelector(
  getcampaignState,
  (state) => state.headerSteps
);

export const getBodyPartKeysData = createSelector(
  getcampaignState,
  (state) => state.bodyPartKeysData
);

export const getCtwaInfo = createSelector(
  getcampaignState,
  (state) => state.ctwaInfo
);

export const getMediafile = createSelector(
  getcampaignState,
  (state) => state.mediafile
);

export const getCampaignType = createSelector(
  getcampaignState,
  (state) => state.campaignType
);

export const getCampaignName = createSelector(
  getcampaignState,
  (state) => state.campaignName
);

export const getTemplateData = createSelector(
  getcampaignState,
  (state) => state.templateData
);

export const getTemplateCampaignType = createSelector(
  getcampaignState,
  (state) => state.templateCampaignType
);

export const getScreenRoute = createSelector(
  getcampaignState,
  (state) => state.screenRoute
);