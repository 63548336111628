import { getQueryParams } from '../helpers';
import { Api } from '../api';
import {
  CfaCustomer,
  CfaCustomerRequest,
  CfaUpdateRequest,
  CfaResponse,
  CfaRequest,
  LocationDetails,
  State,
  CfaTeamMember,
  AssignCustomerResponse,
  AssignRequest,
  Country,
  RemoveRequest,
} from './model';
import { ListInputItemOptions, addListInputItems } from '../filter';
import _ from 'lodash';

export const cfaApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    updateCfa: builder.mutation<void, CfaUpdateRequest>({
      query(arg) {
        const { fulfillmentLocationId, ...params } = arg;
        return {
          url: `fulfillment-locations/${fulfillmentLocationId}`,
          method: 'PATCH',
          body: params,
        };
      },
    }),
    getCfaAndDivisionCustomers: builder.query<
      { customers: CfaCustomer[] },
      CfaCustomerRequest
    >({
      query(arg) {
        return {
          url: `cfa/customers`,
          method: 'GET',
          params: arg,
        };
      },
    }),
    getCfaTeamMembers: builder.query<CfaTeamMember[], CfaCustomerRequest>({
      query(arg) {
        return {
          url: `fulfillment-locations/team-members`,
          method: 'GET',
          params: arg,
        };
      },
    }),
    getAllCfaLocations: builder.query<any, any>({
      query(arg) {
        const { workspaceId, ...params } = arg;
        const queryParams = getQueryParams(params);
        return {
          url: `admin/cfa-locations/all/${workspaceId}?${queryParams}`,
          method: 'GET',
        };
      },
      // async onQueryStarted(request, { dispatch, queryFulfilled }) {
      //   queryFulfilled.then((response) => {
      //     const inputItems = response?.data?.cfas?.map((x) => {
      //       return {
      //         id: x.id,
      //         value: x.supplierName,
      //         selected: false,
      //         eitherOr: true,
      //       };
      //     });
      //     dispatch(
      //       addListInputItems({
      //         inputItems: inputItems,
      //         key: ListInputItemOptions.cfa,
      //       })
      //     );
      //   });
      // },
    }),
    getAllLocations: builder.query<CfaResponse, CfaRequest>({
      query(arg) {
        const { workspaceId, isNoCache, ...params } = arg;
        const queryParams = getQueryParams(params);
        return {
          url: `hub/orgs/api/cfa?sellerWorkspaceId=${workspaceId}&${queryParams}`,
          method: 'GET',
          ...(arg.isNoCache
            ? {
                headers: {
                  'Cache-control': 'no-cache',
                },
              }
            : {}),
        };
      },
      providesTags: ['cfaList'],
      async onQueryStarted(request, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then((response) => {
            const inputItems = response?.data?.cfas?.map((x) => {
              return {
                id: x.id,
                value: x.supplierName,
                selected: false,
                eitherOr: !_.isUndefined(request?.eitherOr)
                  ? request?.eitherOr
                  : true,
              };
            });
            dispatch(
              addListInputItems({
                inputItems: inputItems,
                key: ListInputItemOptions.cfa,
              })
            );
          })
          .catch((error) => console.log(error));
      },
    }),
    getLocationDetailsById: builder.query<
      // { cfa: LocationDetails; status: string; statusCode: number },
      Record<'cfa', LocationDetails>,
      { cfaId: string; workspaceId: string }
    >({
      query(arg) {
        return {
          url: `hub/orgs/api/cfa/${arg.cfaId}?sellerWorkspaceId=${arg.workspaceId}`,
          method: 'GET',
        };
      },
      transformResponse: (cfaDetails: LocationDetails) => {
        return { cfa: cfaDetails };
      },
    }),
    getAllStates: builder.query<State[], void>({
      query() {
        return { url: 'states', method: 'GET' };
      },
    }),
    getAllCountries: builder.query<Country[], void>({
      query() {
        return {
          url: `countries`,
          method: 'GET',
        };
      },
    }),
    createCfa: builder.mutation<
      LocationDetails,
      { workspaceId: string; body: CfaUpdateRequest }
    >({
      query(args) {
        return {
          url: `hub/orgs/api/cfa?sellerWorkspaceId=${args.workspaceId}`,
          method: 'POST',
          body: args.body,
        };
      },
      invalidatesTags: ['cfaList'],
    }),
    assignCustomerToCfaAndDivision: builder.mutation<
      AssignCustomerResponse,
      AssignRequest
    >({
      query(arg) {
        return {
          url: `cfa/customer`,
          method: 'POST',
          body: arg,
        };
      },
    }),
    assignTeamMemberToCfa: builder.mutation<CfaResponse, AssignRequest>({
      query(arg) {
        return {
          url: `fulfillment-locations/team-member/add`,
          method: 'POST',
          body: arg,
        };
      },
    }),
    removeCustomerFromCfaAndDivision: builder.mutation<string, RemoveRequest>({
      query(arg) {
        return {
          url: `cfa/customer`,
          method: 'DELETE',
          params: arg,
        };
      },
    }),
  }),
});

export const {
  useLazyGetAllCfaLocationsQuery,
  useGetAllCfaLocationsQuery,
  useLazyGetAllLocationsQuery,
  useLazyGetCfaAndDivisionCustomersQuery,
  useUpdateCfaMutation,
  useLazyGetLocationDetailsByIdQuery,
  useGetAllStatesQuery,
  useLazyGetAllStatesQuery,
  useCreateCfaMutation,
  useLazyGetCfaTeamMembersQuery,
  useAssignCustomerToCfaAndDivisionMutation,
  useAssignTeamMemberToCfaMutation,
  useGetAllCountriesQuery,
  useRemoveCustomerFromCfaAndDivisionMutation,
} = cfaApi;
