import { ColumnConfig, PriceListColumnKey } from '../model';

export const PriceListConfig: Record<PriceListColumnKey, ColumnConfig> = {
  code: {
    name: 'Code',
    field: 'NavigateLink',
  },
  name: {
    name: 'Name',
    field: 'NavigateLink',
  },
  createdOn: {
    name: 'Created On',
  },
  criteria: {
    name: 'Criteria',
  },
  numProducts: {
    name: 'No.Of Products',
  },
  status: {
    name: 'Status',
  },
  filterby: {
    name: 'Filter By',
    isVisible: false,
    field: 'component',
  },
  cfa: {
    name: 'CFA',
    isVisible: false,
  },
  customer: {
    name: 'Customer',
    isVisible: false,
  },
  options: {
    name: '',
    field: 'component',
  },
};
