import React from 'react';
import { useOrderDetailsScreen } from './hooks/useOrderDetailsScreen';
import { LoadingSpinner, SVGS } from '@zonofi/zono-money-design-system';
import {
  MoqErrorSnackBar,
  OrderDetailsEmptyCart,
  OrderDetailsList,
  OrderDetailsSummary,
  OrderStatusTag,
} from '@zonofi/zono-money-whatsapp-stack';
import './order-details-screen.scss';
import { useLocation, useNavigate } from 'react-router-dom';

export const OrderDetailsScreenV2: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isCart = location.pathname?.includes('cart');
  const {
    orderDetails,
    onChangeQuantity,
    onIncrement,
    onDecrement,
    onDelete,
    onConfirm,
    onAddMore,
    isLoading,
    isSubmitting,
    fetchOrderResponse,
    fetchPoFileResponse,
    getPoDetailsWithIdAPIResponse,
    showImage,
    showPrice,
    showOrderEdit,
    cartAPIResponse,
    error,
    onBackBtnClick,
    moqError,
  } = useOrderDetailsScreen();
  if (error?.show) {
    return (
      <div className="order-details-screen-v2-error-screen font-primary">
        <span>{error?.msg}</span>
      </div>
    );
  }

  if (
    orderDetails?.lines?.length === 0 &&
    (fetchOrderResponse?.isSuccess ||
      fetchPoFileResponse?.isSuccess ||
      getPoDetailsWithIdAPIResponse?.isSuccess ||
      cartAPIResponse?.isSuccess)
  ) {
    return <OrderDetailsEmptyCart />;
  }

  return (
    <div className="order-details-screen-v2 font-primary">
      <div className="order-details-header">
        {!isLoading && (
          <>
            <div className="order-details-header-title">
              {isCart && (
                <img
                  src={SVGS.LeftArrowBlack}
                  alt=""
                  onClick={onBackBtnClick}
                />
              )}

              <span className=" font-primary-semibold">
                {isCart ? 'Cart' : 'Order Details'}
              </span>
              <div>
                <OrderStatusTag status={orderDetails?.status} />
              </div>
            </div>
            {showOrderEdit && (
              <div
                className="order-details-header-add-more font-primary-semibold"
                onClick={onAddMore}
              >
                Add More <img src={SVGS.PlusIconBlue} />
              </div>
            )}
          </>
        )}
      </div>

      {isLoading && (
        <div className="order-details-loading">
          <LoadingSpinner />
        </div>
      )}

      {!isLoading && (
        <div className="order-details-body">
          <div className="order-details-line-items-container">
            <div className="order-details-line-items-header font-primary-semibold">
              <img src={SVGS.CartBox} />
              <span>
                {orderDetails?.lines?.length < 10
                  ? orderDetails?.lines?.length.toString().padStart(2, '0')
                  : orderDetails?.lines?.length}{' '}
                items
              </span>
            </div>
            <div className="order-details-line-items">
              <OrderDetailsList
                orderDetails={orderDetails}
                onDecrement={onDecrement}
                onDelete={onDelete}
                onIncrement={onIncrement}
                onChangeQuantity={onChangeQuantity}
                showImage={showImage}
                showPrice={showPrice}
                showOrderEdit={showOrderEdit}
                isCart={isCart}
              />
            </div>
          </div>
          <OrderDetailsSummary
            orderDetails={orderDetails}
            showOrderEdit={showOrderEdit}
          />
          {moqError.isError && (
            <div className="order-details-moq-error-container">
              <MoqErrorSnackBar number={moqError.quantity} />
            </div>
          )}
        </div>
      )}

      {!isLoading && showOrderEdit && (
        <div className="order-details-footer">
          <span className="order-details-footer-warning">
            Estimate for your requirement will be available after the
            confirmation!
          </span>
          <div
            className="order-details-footer-btn  font-primary-semibold"
            onClick={isSubmitting ? () => {} : onConfirm}
          >
            <span>{isSubmitting ? 'Please wait...' : 'Checkout'}</span>
          </div>
        </div>
      )}
    </div>
  );
};
