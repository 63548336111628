import React, { useState } from 'react';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';
import Video from 'yet-another-react-lightbox/plugins/video';
import 'yet-another-react-lightbox/plugins/thumbnails.css';
import type {
  RenderThumbnailProps,
  Slide,
  SlideImage,
} from 'yet-another-react-lightbox';
import './product-details-gallery-view.scss';
import { SVGS } from '@zonofi/zono-money-design-system';

// Define types for videos
interface VideoSource {
  src: string;
  type: string;
}

interface VideoSlide extends Omit<SlideImage, 'type'> {
  type: 'video';
  poster?: string;
  sources?: VideoSource[];
}

export type MediaSlide = SlideImage | VideoSlide;

interface ProductDetailsGalleryViewProps {
  open: boolean;
  onClose: () => void;
  images: MediaSlide[];
  initialIndex?: number;
}

export const ProductDetailsGalleryView: React.FC<
  ProductDetailsGalleryViewProps
> = ({ open, onClose, images, initialIndex = 0 }) => {
  const [index, setIndex] = useState(initialIndex);
  const slides: Slide[] = images.map((item) => {
    if (item.type === 'video') {
      return {
        ...item,
        poster: item.poster || item.src,
        sources: item.sources || [
          {
            src: item.src,
            type: 'video/mp4',
          },
        ],
      };
    }
    return {
      ...item,
      type: 'image',
    };
  });

  const EmptyNode = () => {
    return null;
  };

  const CloseIcon = () => {
    return (
      <div
        className="product-image-gallery-close-container"
        onClick={() => onClose()}
      >
        <img src={SVGS.CloseIconBlack} alt="" />
      </div>
    );
  };
  const renderThumbnail = ({ slide }: RenderThumbnailProps) => {
    if (slide.type === 'video') {
      return (
        <div className="video-thumbnail-container">
          <img
            src={slide.poster}
            alt={''}
            className="video-thumbnail-container-img"
          />
          <div className="video-thumbnail-container-play-icon">
            <img src={SVGS.VideoPlayIcon} alt="" />
          </div>
        </div>
      );
    }
    return undefined;
  };

  return (
    <Lightbox
      index={index}
      slides={slides}
      open={open}
      close={onClose}
      plugins={[Zoom, Thumbnails, Video]}
      on={{
        view: ({ index: currentIndex }) => setIndex(currentIndex),
      }}
      zoom={{
        maxZoomPixelRatio: 4,
        zoomInMultiplier: 2,
        doubleTapDelay: 300,
        doubleClickDelay: 300,
        doubleClickMaxStops: 2,
        keyboardMoveDistance: 50,
        wheelZoomDistanceFactor: 100,
        pinchZoomDistanceFactor: 100,
        scrollToZoom: true,
      }}
      video={{
        autoPlay: false,
        muted: false,
        playsInline: true,
        controls: true,
        disablePictureInPicture: false,
        disableRemotePlayback: false,
        controlsList: 'nodownload',
      }}
      render={{
        buttonPrev: EmptyNode,
        buttonNext: EmptyNode,
        iconZoomIn: EmptyNode,
        iconZoomOut: EmptyNode,
        buttonClose: CloseIcon,
        thumbnail: renderThumbnail,
      }}
      thumbnails={{
        position: 'bottom',
        border: 0,
        borderRadius: 0,
        padding: 0,
        gap: 8,
        vignette: false,
      }}
      carousel={{
        finite: true,
        preload: 3,
        spacing: 0,
      }}
      styles={{
        slide: {
          padding: 0,
        },
        container: { backgroundColor: 'rgba(240, 240, 240, 1)' },
        thumbnailsContainer: {
          borderTopRightRadius: '12px',
          borderTopLeftRadius: '12px',
          backgroundColor: '#ffffff',
          padding: '12px 0',
          boxShadow: '0px -3px 4px 0px rgba(0, 0, 0, 0.25)',
        },
        thumbnail: {
          borderRadius: '8px',
          width: '52px',
          height: '52px',
          background: 'transparent',
        },
        thumbnailsTrack: { gap: 8 },
      }}
    />
  );
};
