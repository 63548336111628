import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../root';
import { DivisionResponse, ISelectedCfaWithDivisions } from './model';
import { DivisionDaum, Location } from '../cfa';
import { CustomerSearchCFA } from '../customers';

export const DIVISION_FEATURE_KEY = 'divisionData';

export interface DivisionDataState {
  divisionListData: DivisionResponse;
  selectedCfaWithDivisions: ISelectedCfaWithDivisions[];
  showDivisionDataIds: string[];
}

export const initialDivisionState: DivisionDataState = {
  divisionListData: {
    divisions: [],
    status: '',
    statusCode: 0,
  },
  selectedCfaWithDivisions: [],
  showDivisionDataIds: [],
};

export const divisionSlice = createSlice({
  name: DIVISION_FEATURE_KEY,
  initialState: initialDivisionState,
  reducers: {
    updateDivisionData(state, action: PayloadAction<DivisionResponse>) {
      state.divisionListData = {
        ...action.payload,
        divisions: [
          {
            id: '0',
            name: 'All Division',
            code: '',
            workspaceId: '',
            isDefault: false,
            createdDate: null,
            updatedDate: null,
            headDivisionId: undefined,
            stateId: undefined,
            numberOfCustomer: 0,
          },
          ...action.payload.divisions,
        ],
      };
    },
    updateSelectedCfaWithDivisions(
      state,
      action: PayloadAction<{
        cfa?: Location;
        division?: DivisionDaum;
        cfaList?: CustomerSearchCFA[];
        type: 'single' | 'batch' | 'empty';
      }>
    ) {
      const { cfa, division, cfaList, type } = action.payload || {};

      if (type === 'batch' && cfaList) {
        cfaList.forEach((batchCfa) => {
          const divisions = (batchCfa?.divisions || [])?.map(
            (divisionItem) => ({
              id: divisionItem.id,
              name: divisionItem.name,
              code: divisionItem.code,
            })
          );

          const existingCfa = state.selectedCfaWithDivisions.find(
            (item) => item.id === batchCfa.id
          );

          if (existingCfa) {
            existingCfa.division = divisions;
          } else {
            state.selectedCfaWithDivisions.push({
              id: batchCfa.id,
              supplierName: batchCfa.name,
              fulfillmentLocationCode: batchCfa.cfaCode as string,
              division: divisions,
            });
          }
        });
      } else if (type === 'single' && cfa && division) {
        const allDivisions = state.divisionListData.divisions;

        if (division.id === '0' && division.code === '') {
          const existingCfa = state.selectedCfaWithDivisions.find(
            (item) => item.id === cfa.id
          );

          if (
            existingCfa &&
            existingCfa.division.length === allDivisions.length
          ) {
            state.selectedCfaWithDivisions =
              state.selectedCfaWithDivisions.filter(
                (item) => item.id !== cfa.id
              );
          } else {
            if (existingCfa) {
              existingCfa.division = allDivisions;
            } else {
              state.selectedCfaWithDivisions.push({
                id: cfa.id,
                supplierName: cfa.supplierName,
                fulfillmentLocationCode: cfa.fulfillmentLocationCode as string,
                division: allDivisions,
              });
            }
          }
        } else {
          const existingCfa = state.selectedCfaWithDivisions.find(
            (item) => item.id === cfa.id
          );

          if (existingCfa) {
            const existingDivisionIndex = existingCfa.division.findIndex(
              (div) => div.id === division.id
            );

            if (existingDivisionIndex !== -1) {
              existingCfa.division.splice(existingDivisionIndex, 1);
            } else {
              existingCfa.division.push(division);
            }

            if (existingCfa.division.length === 0) {
              state.selectedCfaWithDivisions =
                state.selectedCfaWithDivisions.filter(
                  (item) => item.id !== cfa.id
                );
            }
          } else {
            state.selectedCfaWithDivisions.push({
              id: cfa.id,
              supplierName: cfa.supplierName,
              fulfillmentLocationCode: cfa.fulfillmentLocationCode as string,
              division: [division],
            });
          }
        }
      } else if (type === 'empty') {
        state.selectedCfaWithDivisions = [];
      }
    },
    removeCfaItem(
      state,
      action: PayloadAction<{ id: string } | { id: string }[]>
    ) {
      const payload = action.payload;
      const idsToRemove = Array.isArray(payload)
        ? payload.map((item) => item.id)
        : [payload.id];

      state.selectedCfaWithDivisions = state.selectedCfaWithDivisions.filter(
        (cfa) => !idsToRemove.includes(cfa.id)
      );
    },
    updateShowDivisionIdsData(
      state,
      action: PayloadAction<{ items?: Location[]; idToCheck?: string }>
    ) {
      if (action.payload.items) {
        const newIds = action.payload.items.map((item) => item.id);
        const uniqueIds = new Set([...state.showDivisionDataIds, ...newIds]);
        state.showDivisionDataIds = Array.from(uniqueIds);
      }

      if (action.payload.idToCheck) {
        const id = action.payload.idToCheck;
        if (state.showDivisionDataIds.includes(id)) {
          state.showDivisionDataIds = state.showDivisionDataIds.filter(
            (cfaId) => cfaId !== id
          );
        } else {
          state.showDivisionDataIds.push(id);
        }
      }
    },
    clearSelectedCfaWithDivisions(state) {
      state.selectedCfaWithDivisions = [];
    },
    clearShowDivisionIds(state) {
      state.showDivisionDataIds = [];
    },
  },
});

export const divisionReducer = divisionSlice.reducer;

export const {
  updateDivisionData,
  updateSelectedCfaWithDivisions,
  removeCfaItem,
  clearSelectedCfaWithDivisions,
  updateShowDivisionIdsData,
  clearShowDivisionIds,
} = divisionSlice.actions;

export const getDivisionSliceState = (
  rootState: RootState
): DivisionDataState => rootState[DIVISION_FEATURE_KEY];

export const getDivisionListData = createSelector(
  getDivisionSliceState,
  (state) => state.divisionListData
);

export const getSelectedCfaWithDivisions = createSelector(
  getDivisionSliceState,
  (state) => state.selectedCfaWithDivisions
);

export const getShowDivisionIds = createSelector(
  getDivisionSliceState,
  (state) => state.showDivisionDataIds
);
