import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../lib';
import { Event } from './model';
import { eventsApi } from './api';
import _ from 'lodash';
import moment from 'moment';

const EVENTS_FEATURE_KEY = 'events';

interface eventsState {
  events: Event[];
}
export const initialEventsState: eventsState = {
  events: [],
};

export const eventsSlice = createSlice({
  name: EVENTS_FEATURE_KEY,
  initialState: initialEventsState,
  reducers: {
    addEvents(state, action: PayloadAction<Event[]>) {
      action.payload.forEach((x) => {
        if (state.events.findIndex((y) => y.id === x.id) === -1) {
          state.events.push(x);
        }
      });
    },
    addEvent(state, action: PayloadAction<Event>) {
      if (state.events.findIndex((y) => y.id === action.payload.id) === -1) {
        state.events.push(action.payload);
      }
    },
    removeEvents(state) {
      state.events = [];
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      eventsApi.endpoints.getEvents.matchFulfilled,
      (state, action) => {
        eventsSlice.caseReducers.addEvents(state, action);
      }
    );
  },
});

export const { addEvents, addEvent, removeEvents } = eventsSlice.actions;

const getEventsState = (rootState: RootState): eventsState =>
  rootState[EVENTS_FEATURE_KEY];

export const getEvents = createSelector(getEventsState, (state) =>
  _.orderBy(state.events, ['date'], ['desc'])
);
