import { ChatActionViewOrderColumnKey, ColumnConfig } from '../../model';

export const chatActionViewOrderConfig: Record<
  ChatActionViewOrderColumnKey,
  ColumnConfig
> = {
  sNo: { name: 'S.No' },
  title: {
    name: 'Product Title',
    field: 'component',
  },

  price: {
    name: 'Price',
    alignment: 'flex-end',
    sortField: 'price',
  },
  quantity: {
    name: 'Qty',
    alignment: 'flex-end',
    sortField: 'quantity',
  },
  total: {
    name: 'Total',
    alignment: 'flex-end',
    sortField: 'total',
  },
  action: { name: '', field: 'component' },
};
