import React from 'react';
import { LoadingSpinner, SVGS } from '@zonofi/zono-money-design-system';
import './catalog-image-search-camera.scss';

interface CatalogImageSearchCameraProps {
  videoRef: React.MutableRefObject<HTMLVideoElement>;
  canvasRef: React.MutableRefObject<HTMLCanvasElement>;
  onTakePhoto: () => any;
  uploadedFile: File;
  uploadedImage: any;
  isParsing: boolean;
  error: {
    show: boolean;
    msg: string;
  };
  onTryAgain: () => any;
}

export const CatalogImageSearchCamera: React.FC<
  CatalogImageSearchCameraProps
> = ({
  videoRef,
  canvasRef,
  onTakePhoto,
  uploadedFile,
  uploadedImage,
  isParsing,
  error,
  onTryAgain,
}) => {
  return uploadedFile && uploadedImage ? (
    <>
      <div className="catalog-image-search-body-preview-container">
        <img
          src={uploadedImage}
          className="catalog-image-search-body-preview"
        />
      </div>
      {isParsing && (
        <div className="catalog-image-search-body-preview-loader">
          <LoadingSpinner size={'small'} />
          <span>Fetching products for you...</span>
        </div>
      )}
      {error?.show && (
        <div className="catalog-image-search-body-preview-error">
          <img src={SVGS.WarningRed} width={25} />
          <span>Couldn't find similar products, Try again</span>
          <span>{error?.msg}</span>
          <div
            className="catalog-image-search-body-preview-error-try-again"
            onClick={onTryAgain}
          >
            Try Again
          </div>
        </div>
      )}
    </>
  ) : (
    <div className="catalog-image-search-camera">
      <video ref={videoRef} style={{ width: '100%', maxWidth: '600px' }} />

      <div className="catalog-image-search-camera-button" onClick={onTakePhoto}>
        <img
          src={SVGS.SearchBlack}
          className="catalog-image-search-camera-img"
        />
      </div>

      <canvas ref={canvasRef} style={{ display: 'none' }} />
    </div>
  );
};
