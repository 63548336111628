import React from 'react';
import './catalog-recent-purchase.scss';

export const CatalogRecentPurchase: React.FC = () => {
  return (
    <div className="catalog-recent-purchase font-primary">
      <span className="catalog-recent-purchase-title">Recent Purchases</span>
    </div>
  );
};
