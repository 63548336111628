import moment from 'moment';
import { Api } from '../api';
import {
  RecordCheckInResponse,
  RecordCheckInRequest,
  VisitsRes,
  CustomerLocationUpdateReq,
  CustomerLocationUpdateRes,
  VisitsRequest,
} from './visit-model';
import { updateTodayVisitedCustomerIds } from './visit-slice';

export const visitApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    getVisits: builder.query<VisitsRes, VisitsRequest>({
      query: (args) => {
        const newArgs = { ...args, random: Math.random() };
        return {
          url: `hub/reports/api/v1/salesmen-detail-report/${args.sellerWorkspaceId}`,
          method: 'GET',
          params: newArgs,
        };
      },
      onQueryStarted(request, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then((response) => {
            if (
              !request.customerId &&
              moment(moment().format('YYYY-MM-DD')).isBetween(
                moment(request.startDate),
                moment(request.endDate),
                undefined,
                '[]'
              )
            ) {
              const visitedCustomerIds = response.data.visits
                .filter(
                  (visit) =>
                    moment(visit.visitedDateTime).format('YYYY-MM-DD') ===
                    moment().format('YYYY-MM-DD')
                )
                .map(
                  (visit) => visit?.customerId || visit?.customerDetails?.id
                );
              dispatch(
                updateTodayVisitedCustomerIds({
                  append: false,
                  payload: visitedCustomerIds,
                })
              );
            }
          })
          .catch((error) => console.log('visit error', error));
      },
    }),
    recordVisit: builder.mutation<RecordCheckInResponse, RecordCheckInRequest>({
      query: (args) => {
        return {
          url: `hub/orgs/api/visit/${args.params.sellerWorkspaceId}`,
          method: 'POST',
          params: args.params,
          body: args.body,
        };
      },
    }),
    customerLocationUpdate: builder.mutation<
      CustomerLocationUpdateRes,
      CustomerLocationUpdateReq
    >({
      query: (args) => {
        return {
          url: `hub/orgs/api/visit/${args.visitId}`,
          method: 'PUT',
          body: args.body,
          params: { sellerWorkspaceId: args.sellerWorkspaceId },
        };
      },
    }),
  }),
  overrideExisting: true,
});

export const {
  useLazyGetVisitsQuery,
  useGetVisitsQuery,
  useRecordVisitMutation,
  useCustomerLocationUpdateMutation,
} = visitApi;
