import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { PriceListSearchResponse } from './model';
import { RootState } from '../root';
import { isEmpty } from 'lodash';
import { PriceProductListItem } from '../products';

export const PRICE_LIST_FEATURE_KEY = 'priceList';

export interface PriceListDataState {
  priceListSearchData: PriceListSearchResponse;
  savedProductIds: number[];
  newPricesListData: PriceProductListItem[];
  expiryDate: string;
}

export const initialPriceListState: PriceListDataState = {
  priceListSearchData: { products: [] },
  savedProductIds: [],
  newPricesListData: [],
  expiryDate: '',
};

export const priceListSlice = createSlice({
  name: PRICE_LIST_FEATURE_KEY,
  initialState: initialPriceListState,
  reducers: {
    updatePriceListSearchData(
      state,
      action: PayloadAction<PriceListSearchResponse>
    ) {
      state.priceListSearchData = action.payload;
    },
    clearPriceListSearchData(state) {
      state.priceListSearchData = {
        products: [],
      };
    },
    updateProductId(state, action: PayloadAction<number>) {
      const productId = action.payload;
      const index = state.savedProductIds.indexOf(productId);

      if (index > -1) {
        state.savedProductIds.splice(index, 1);
      } else {
        state.savedProductIds.push(productId);
      }
    },
    clearProductId(state) {
      state.savedProductIds = [];
    },
    updateNewPriceData(state, action: PayloadAction<PriceProductListItem>) {
      if (!isEmpty(action.payload)) {
        const { productVariantId } = action.payload;

        const existingIndex = state.newPricesListData.findIndex(
          (item) => item.productVariantId === productVariantId
        );

        if (existingIndex !== -1) {
          state.newPricesListData[existingIndex] = action.payload;
        } else {
          state.newPricesListData.push(action.payload);
        }
      }
    },
    removeNewPriceData(state, action: PayloadAction<number | undefined>) {
      const productIdToRemove = action.payload;

      if (productIdToRemove === undefined) {
        state.newPricesListData = [];
      } else {
        state.newPricesListData = state.newPricesListData.filter(
          (item) => item.productVariantId !== productIdToRemove
        );
      }
    },
    updateExpiryDate(state, action: PayloadAction<string>) {
      state.expiryDate = action.payload;
    },
  },
});

export const priceListReducer = priceListSlice.reducer;

export const {
  updatePriceListSearchData,
  updateProductId,
  clearProductId,
  updateNewPriceData,
  removeNewPriceData,
  updateExpiryDate,
  clearPriceListSearchData,
} = priceListSlice.actions;

export const getPriceListSliceState = (
  rootState: RootState
): PriceListDataState => rootState[PRICE_LIST_FEATURE_KEY];

export const getPriceListSearchData = createSelector(
  getPriceListSliceState,
  (state) => state.priceListSearchData
);

export const getSavedProductIds = createSelector(
  getPriceListSliceState,
  (state) => state.savedProductIds
);

export const getNewPricesListData = createSelector(
  getPriceListSliceState,
  (state) => state.newPricesListData
);

export const getExpiryDate = createSelector(
  getPriceListSliceState,
  (state) => state.expiryDate
);
