import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SchemeActionButtons, SchemeTypes } from '../helpers';
import { RootState } from '../root';
import { CreateBuyXGetYRequest } from './model';

export const SCHEMES_FEATURE_KEY = 'schemesData';

export interface SchemesState {
  selectedScheme: SchemeTypes;
  selectedTab: SchemeActionButtons;
  selectedFilterChip: string;
}

export const initialSchemesState: SchemesState = {
  selectedScheme: SchemeTypes.BUYXGETY,
  selectedTab: SchemeActionButtons.CONDITIONS,
  selectedFilterChip: 'All',
};

export const schemesSlice = createSlice({
  name: SCHEMES_FEATURE_KEY,
  initialState: initialSchemesState,
  reducers: {
    updateSelectedScheme(state, action: PayloadAction<SchemeTypes>) {
      state.selectedScheme = action.payload;
    },
    updateSelectedTab(state, action: PayloadAction<SchemeActionButtons>) {
      state.selectedTab = action.payload;
    },
    updateSelectedFilterChip(state, action: PayloadAction<string>) {
      state.selectedFilterChip = action.payload;
    },
  },
});

export const schemeReducer = schemesSlice.reducer;

export const {
  updateSelectedScheme,
  updateSelectedTab,
  updateSelectedFilterChip,
} = schemesSlice.actions;

export const getSchemesSliceState = (rootState: RootState): SchemesState =>
  rootState[SCHEMES_FEATURE_KEY];

export const getSelectedScheme = createSelector(
  getSchemesSliceState,
  (state) => state.selectedScheme
);

export const getSelectedTab = createSelector(
  getSchemesSliceState,
  (state) => state.selectedTab
);

export const getSelectedFilterChip = createSelector(
  getSchemesSliceState,
  (state) => state.selectedFilterChip
);
