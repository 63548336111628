import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import { RootState } from '../root';

export const LEDGER_FEATURE_KEY = 'ledgerData';

export interface keyValue {
  id: string;
  value: string;
}

export interface LedgerState {
  searchView: boolean;
  searchKey: string;
  filter: Map<string, string>;
  customerLedgerSearchKey: string;
  dateRange: {
    startDate: Date;
    endDate: Date;
  };
  listLength: number;
  selectedTransactionType: keyValue;
}

export const initialLedgerState: LedgerState = {
  searchView: false,
  searchKey: '',
  filter: new Map(),
  customerLedgerSearchKey: '',
  dateRange: {
    startDate: moment().subtract(29, 'days').toDate(),
    endDate: moment().toDate(),
  },
  listLength: 0,
  selectedTransactionType: { id: '', value: 'All' },
};

export const ledgerSlice = createSlice({
  name: LEDGER_FEATURE_KEY,
  initialState: initialLedgerState,
  reducers: {
    updateLedgerSearchView(state, action: PayloadAction<boolean>) {
      state.searchView = action.payload;
    },
    updateLedgerSearchKey(state, action: PayloadAction<string>) {
      state.searchKey = action.payload;
    },
    updateLedgerFilter(state, action: PayloadAction<Map<string, string>>) {
      state.filter = action.payload;
    },
    updateCustomerLedgerSearchKey(state, action: PayloadAction<string>) {
      state.customerLedgerSearchKey = action.payload;
    },
    updateSelectedTransactionType(state, action: PayloadAction<keyValue>) {
      state.selectedTransactionType = action.payload;
    },
    updateLedgerDateRange(
      state,
      action: PayloadAction<{
        startDate: Date;
        endDate: Date;
      }>
    ) {
      state.dateRange = action.payload;
    },
    updateListLength(state, action: PayloadAction<number>) {
      state.listLength = action.payload;
    },
  },
});

export const ledgerReducer = ledgerSlice.reducer;

export const {
  updateLedgerSearchView,
  updateLedgerSearchKey,
  updateLedgerFilter,
  updateCustomerLedgerSearchKey,
  updateSelectedTransactionType,
  updateLedgerDateRange,
  updateListLength,
} = ledgerSlice.actions;

export const getLedgerSliceState = (rootState: RootState): LedgerState =>
  rootState[LEDGER_FEATURE_KEY];

export const getLedgerSearchView = createSelector(
  getLedgerSliceState,
  (state) => state.searchView
);

export const getLedgerSearchKey = createSelector(
  getLedgerSliceState,
  (state) => state.searchKey
);

export const getLedgerFilters = createSelector(
  getLedgerSliceState,
  (state) => state.filter
);

export const getCustomerLedgerSearchKey = createSelector(
  getLedgerSliceState,
  (state) => state.customerLedgerSearchKey
);

export const getLedgerDateRange = createSelector(
  getLedgerSliceState,
  (state) => state.dateRange
);

export const getListLength = createSelector(
  getLedgerSliceState,
  (state) => state.listLength
);

export const getSelectedTransactionType = createSelector(
  getLedgerSliceState,
  (state) => state.selectedTransactionType
);
