import React from 'react';
import './unauthorized-screen.scss';
import { SVGS } from '@zonofi/zono-money-design-system';
import { ZoButton } from '@zonofi/zo-components';
import { useAppSelector } from '@zonofi/zono-money-helpers';
import { getCustomerInvite } from '@zonofi/zono-money-store';
import { getConfigValue } from '@zonofi/common';
export const UnauthorizedScreen: React.FC = () => {
  const inviteDetails = useAppSelector(getCustomerInvite);
  const { seller, waConfig } = inviteDetails;

  const onClickBackToWhatsapp = () => {
    if (waConfig?.whatsappPhoneNo && waConfig?.whatsappPhoneNo?.trim()) {
      window.location.href = `https://wa.me/${waConfig.whatsappPhoneNo}`;
    } else if (seller?.workspaceId === 'c9bedd72-85d7-41c4-8c62-e33ffc926966') {
      window.location.href = getConfigValue('WAPP_REDIRECT_BJ_GROUP');
    } else {
      window.location.href = getConfigValue('WAPP_REDIRECT');
    }
  };
  return (
    <div className="error-screen-container">
      <div className="error-content">
        <img
          className="error-content-img"
          src={SVGS.Restricted}
          alt=""
          width={'172px'}
          height={'172px'}
        />

        <div className="error-content-body">
          <div className="error-content-body-message">
            <div className="font-primary-semibold error-content-body-message-header">
              User Restricted!
            </div>
            <div className="font-primary error-content-body-message-description">
              You don't have access to this link.
            </div>
          </div>
          <ZoButton
            label={'Back to Whatsapp'}
            onClick={onClickBackToWhatsapp}
            className="error-content-body-button font-primary-regular"
          />
        </div>
      </div>
    </div>
  );
};
