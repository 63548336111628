import { store } from '../root';
import { hierarchyAdapter } from './slice';
import { EntityId } from '@reduxjs/toolkit';

export const hierarchySelectors = hierarchyAdapter.getSelectors(
  //@ts-ignore
  (state: RootState) => state.hierarchyItem
);

export const eventsNativeSelectors = hierarchyAdapter.getSelectors();

const getHierarchyState = () => store.getState().hierarchyItem;

export const getHierarchyItems = (hierarchy = getHierarchyState()) =>
  eventsNativeSelectors.selectAll(hierarchy);

export const getHierarchyItemIds = (hierarchy = getHierarchyState()) =>
  eventsNativeSelectors.selectIds(hierarchy);

export const getHierarchyItemById = (
  id: EntityId,
  hierarchy = getHierarchyState()
) => eventsNativeSelectors.selectById(hierarchy, id);

export const getHierarchyItemsCount = (hierarchy = getHierarchyState()) =>
  eventsNativeSelectors.selectTotal(hierarchy);
