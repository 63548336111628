import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../root';
import {
  ArithmeticOperatorInputOptions,
  BooleanInputItem,
  BooleanInputItemOptions,
  Epoch,
  EpochScreenType,
  FilterInputs,
  ImageSearchKey,
  InputItem,
  ListInputItem,
  ListInputItemOptions,
  Operator,
  RecentSearchTypes,
  Sift,
  SiftScreenEnum,
  SortDirection,
  SortField,
  StringInputItem,
  StringInputItemOptions,
  TreeInput,
  TreeInputItemOptions,
} from './model';
import _, { isEmpty, isUndefined } from 'lodash';
import moment from 'moment';
import { ThreadsFitlers } from '../thread';

export const FILTER_FEATURE_KEY = 'filtersData';

interface ClearFilter {
  defaultSortfield?: SortField;
  resetAllFilters?: boolean;
}
export interface FiltersState {
  filters: FilterInputs;
  epoch: Epoch;
  sift: Sift;
  appliedFilters: FilterInputs;
  threadsFilters: ThreadsFitlers;
  error: string | null;
  searchKey?: string;
  imageSearchKey?: ImageSearchKey;
  searchKeyList?: string[];
  searchActive?: boolean;
  searchActiveScreen?: string;
  pagination: number;
  filtersActive?: boolean;
  recentSearches: {
    products: string[];
    schemes: string[];
    orders: string[];
    trackPos: string[];
    cart: string[];
    returns: string[];
    ledger: string[];
    invoice: string[];
    threads: string[];
    customers: string[];
    tags: string[];
    payment: string[];
    members: string[];
    campaigns: string[];
  };
}

const initialState: FiltersState = {
  filters: {
    sortField: { field: '', direction: SortDirection.ASCENDING },
  },
  epoch: {
    [EpochScreenType.CAMPAIGN_DETAIL]: {
      startDate: new Date(moment().subtract(30, 'days').format('YYYY-MM-DD')),
      endDate: new Date(moment().format('YYYY-MM-DD')),
    },
    [EpochScreenType.CAMPAIGN_LIST]: {
      startDate: new Date(moment().subtract(30, 'days').format('YYYY-MM-DD')),
      endDate: new Date(moment().format('YYYY-MM-DD')),
    },
    [EpochScreenType.SEND_CAMPAIGN]: {
      startDate: new Date(moment().subtract(30, 'days').format('YYYY-MM-DD')),
      endDate: new Date(moment().format('YYYY-MM-DD')),
    },
    [EpochScreenType.SALES_TEAM_ACTIVITY]: {
      startDate: new Date(moment().subtract(30, 'days').format('YYYY-MM-DD')),
      endDate: new Date(moment().format('YYYY-MM-DD')),
    },
    [EpochScreenType.REPORT]: {
      startDate: new Date(moment().startOf('week').toDate()),
      endDate: new Date(moment().format('YYYY-MM-DD')),
    },
    [EpochScreenType.ORDERLIST]: {
      startDate: new Date(moment().subtract(7, 'days').format()),
      endDate: new Date(moment().format()),
    },
    [EpochScreenType.INVOICELIST]: {
      startDate: new Date(moment().subtract(7, 'days').format()),
      endDate: new Date(moment().format()),
    },
  },
  sift: {
    [SiftScreenEnum.APPLIED_FILTERS]: {},
    [SiftScreenEnum.PRODUCT_PERFORMANCE_REPORTS]: undefined,
    [SiftScreenEnum.CUSTOMER_PERFORMANCE_REPORTS]: undefined,
    [SiftScreenEnum.INVOICE_REPORTS]: undefined,
    [SiftScreenEnum.SALES_TEAM_CHECKINS_REPORT]: undefined,
    [SiftScreenEnum.PAYMENTS_REPORT]: undefined,
    [SiftScreenEnum.SUPPLY_TRACKER]: undefined,
    [SiftScreenEnum.ORDER_DETAILS_REPORT]: undefined,
    [SiftScreenEnum.SALES_LEADERBOARD_REPORT]: undefined,
    [SiftScreenEnum.CAMPAIGN_LIST_SCREEN]: undefined,
    [SiftScreenEnum.SEND_CAMPAIGN_SCREEN]: undefined,
    [SiftScreenEnum.CUSTOMER_SCREEN]: undefined,
    [SiftScreenEnum.ORDER_LINE_SCREEN]: undefined,
    [SiftScreenEnum.CUSTOMERS_ERP_SYNC_DETAILS]: {
      taskStatus: [
        {
          id: 'Successful',
          value: 'Successful',
          selected: false,
          eitherOr: true,
        },
        { id: 'Failed', value: 'Failed', selected: false, eitherOr: true },
      ],
    },
  },
  threadsFilters: {
    transactionStatus: {
      campaign: [],
      dispatch: [],
      invoice: [],
      order: [],
      payment: [],
      priceList: [],
    },
    others: {
      Type: [],
      Status: [],
      Dispatch: [],
      Whatsapp: [],
    },
    routes: [],
    customerSegment: [],
    teamMember: [],
    customer: [],
    cfaList: [],
    divisionList: [],
    issueThreadTransaction: [],
    filteredChannels: [],
  },
  recentSearches: {
    products: [],
    schemes: [],
    orders: [],
    trackPos: [],
    cart: [],
    returns: [],
    ledger: [],
    invoice: [],
    threads: [],
    customers: [],
    tags: [],
    payment: [],
    members: [],
    campaigns: [],
  },
  appliedFilters: {
    sortField: { field: '', direction: SortDirection.ASCENDING },
  },
  error: null,
  searchKey: '',
  imageSearchKey: { path: '', type: '' },
  searchActive: false,
  searchActiveScreen: '',
  pagination: 20,
  filtersActive: false,
};

export const filtersSlice = createSlice({
  name: FILTER_FEATURE_KEY,
  initialState,
  reducers: {
    addListInputItems(
      state,
      action: PayloadAction<{
        inputItems: InputItem[];
        key: string;
        parentIdRequired?: boolean;
      }>
    ) {
      const key = action.payload.key as ListInputItemOptions;
      if (state.filters) {
        if (state.filters.listInputItem) {
          state.filters.listInputItem[key] = action.payload.inputItems;
        } else {
          // @ts-ignore
          state.filters.listInputItem = { [key]: action.payload.inputItems };
        }
      }
    },
    addTreeInputItems(
      state,
      action: PayloadAction<{
        inputItems: TreeInput[];
        key: string;
        parentIdRequired?: boolean;
      }>
    ) {
      const key = action.payload.key as TreeInputItemOptions;
      if (state.filters) {
        if (state.filters.treeInputItem) {
          state.filters.treeInputItem[key] = action.payload.inputItems;
        } else {
          // @ts-ignore
          state.filters.treeInputItem = { [key]: action.payload.inputItems };
        }
      }
    },
    addSearchListInputItems(
      state,
      action: PayloadAction<{
        value: {
          searchTerm: string;
          listInputItem: InputItem[];
        };
        key: string;
      }>
    ) {
      const key = action.payload.key as ListInputItemOptions;
      if (state.filters) {
        if (state.filters.searchListInputItem) {
          state.filters.searchListInputItem[key] = action.payload.value;
        } else {
          // @ts-ignore
          state.filters.searchListInputItem = {
            [key]: action.payload.value,
          };
        }
      }
    },
    addStringInputItems(
      state,
      action: PayloadAction<{
        value: string;
        key: string;
      }>
    ) {
      const key = action.payload.key as StringInputItemOptions;
      const data = action.payload.value;
      if (state.filters) {
        if (state.filters.stringInputItem) {
          state.filters.stringInputItem[key] = data;
        } else {
          // @ts-ignore
          state.filters.stringInputItem = { [key]: data };
        }
      }
    },
    updateListInputItem(
      state,
      action: PayloadAction<{ value: InputItem[]; key: string }>
    ) {
      if (state.filters.listInputItem) {
        state.filters.listInputItem[
          action.payload.key as ListInputItemOptions
        ] = action.payload.value;
      }
    },
    updateTreeInputItem(
      state,
      action: PayloadAction<{ value: TreeInput[]; key: string }>
    ) {
      if (state.filters.treeInputItem) {
        state.filters.treeInputItem[
          action.payload.key as TreeInputItemOptions
        ] = action.payload.value;
      }
    },
    deleteListInputItem(state, action: PayloadAction<{ key: string }>) {
      const newState: any = _.omit(
        state.filters.listInputItem,
        action.payload.key
      );
      if (state.filters.listInputItem) {
        state.filters.listInputItem[
          action.payload.key as ListInputItemOptions
        ] = newState;
      }
    },
    updateSearchListInputItem(
      state,
      action: PayloadAction<{
        value: {
          searchTerm: string;
          listInputItem: InputItem[];
        };
        key: string;
      }>
    ) {
      if (state.filters.searchListInputItem) {
        state.filters.searchListInputItem[
          action.payload.key as ListInputItemOptions
        ] = action.payload.value;
      }
    },
    updateStringInputItems(
      state,
      action: PayloadAction<{
        value: string | number;
        key: string;
      }>
    ) {
      if (state.filters.stringInputItem) {
        state.filters.stringInputItem[
          action.payload.key as StringInputItemOptions
        ] = action.payload.value.toString();
      }
    },
    updateCalendarInputItem(
      state,
      action: PayloadAction<{
        value: string;
        key: string;
      }>
    ) {
      if (state.filters.calendarInputItem) {
        state.filters.calendarInputItem[action.payload.key] =
          action.payload.value;
      }
      state.filters = {
        ...state.filters,
        calendarInputItem: {
          [action.payload.key]: action.payload.value,
        },
      };
    },
    updateAppliedFilters(state) {
      state.appliedFilters = state.filters;
    },

    updateAppliedFiltersByScreenAndValue(
      state,
      action: PayloadAction<{ screen: SiftScreenEnum; value: any }>
    ) {
      state.sift[SiftScreenEnum.APPLIED_FILTERS][action?.payload?.screen] =
        action?.payload?.value;
    },
    clearAppliedFiltersByScreen(
      state,
      action: PayloadAction<{ screen: SiftScreenEnum }>
    ) {
      state.sift[SiftScreenEnum.APPLIED_FILTERS][action?.payload?.screen] =
        undefined;

      const setSelectedToFalse = (obj: any) => {
        for (let key in obj) {
          if (typeof obj[key] === 'object' && obj[key] !== null) {
            setSelectedToFalse(obj[key]);
          } else if (key === 'selected' && obj[key] === true) {
            obj[key] = false;
          }
        }
        return obj;
      };

      // Call the function on the data object
      state.sift[action?.payload?.screen] = setSelectedToFalse(
        state.sift[action?.payload?.screen]
      );
    },
    updateAppliedFiltersByScreen(
      state,
      action: PayloadAction<{ screen: SiftScreenEnum }>
    ) {
      const screenFilters = state.sift?.[action?.payload?.screen];
      if (screenFilters) {
        const appliedFilters = Object.entries(screenFilters).reduce(
          (acc, [key, value]) => {
            if (Array.isArray(value)) {
              const selectedItems = value
                .filter((item) => item.selected)
                .map((item) => item.id);
              if (selectedItems.length > 0) {
                acc[key] = selectedItems;
              }
            }
            return acc;
          },
          {} as Record<string, string[]>
        );

        state.sift[SiftScreenEnum.APPLIED_FILTERS][action?.payload?.screen] =
          appliedFilters;
      }
    },
    clearAppliedFilter(state, action: PayloadAction<undefined | SortField>) {
      state.appliedFilters = {
        sortField: action.payload || {
          field: '',
          direction: SortDirection.ASCENDING,
        },
      };
      const filters = { ...state.filters };
      filters.sortField = action.payload || {
        field: '',
        direction: SortDirection.ASCENDING,
      };
      const {
        listInputItem,
        stringInputItem,
        booleanInputItem,
        treeInputItem,
      } = filters;
      if (!isUndefined(listInputItem) && !isEmpty(listInputItem)) {
        const listInput = { ...listInputItem };
        filters.listInputItem = Object.fromEntries(
          Object.entries(listInput)?.map(([k, v = []]) => [
            k,
            [...v]?.map((item) => ({
              ...item,
              // selected: resetAllFilters && item?.id === 'all',
              selected: false,
            })) || [],
          ]) || []
        ) as ListInputItem;
      }
      if (!isUndefined(stringInputItem) && !isEmpty(stringInputItem)) {
        const stringInput = { ...stringInputItem };
        filters.stringInputItem = Object.fromEntries(
          Object.entries(stringInput)?.map(([k, _v]) => [k, ''])
        ) as StringInputItem;
      }
      if (!isUndefined(booleanInputItem) && !isEmpty(booleanInputItem)) {
        const booleanInput = { ...booleanInputItem };
        filters.booleanInputItem = Object.fromEntries(
          Object.entries(booleanInput)?.map(([k, _v]) => [k, false])
        ) as BooleanInputItem;
      }

      if (!isUndefined(treeInputItem) && !isEmpty(treeInputItem)) {
        const clearSelectedTreeFilters = (treeData: any[]) => {
          return treeData.map((node) => {
            if ('selected' in node) {
              node.selected = false;
            }
            if (node?.TreeData && Array.isArray(node?.TreeData)) {
              node.TreeData = clearSelectedTreeFilters(node?.TreeData);
            }
            return node;
          });
        };

        const treeInput = { ...treeInputItem };
        if (treeInput.Routes) {
          treeInput.Routes = clearSelectedTreeFilters(treeInput?.Routes);
        }
        if (treeInput.Segments) {
          treeInput.Segments = clearSelectedTreeFilters(treeInput?.Segments);
        }
        filters.treeInputItem = treeInput;
      }
      state.filters = filters;
    },
    addBooleanInputItem(
      state,
      action: PayloadAction<{ key: string; value: boolean }>
    ) {
      const key = action.payload.key as BooleanInputItemOptions;
      const data = action.payload.value;
      if (state.filters) {
        if (state.filters.booleanInputItem) {
          state.filters.booleanInputItem[key] = data;
        } else {
          // @ts-ignore
          state.filters.booleanInputItem = { [key]: data };
        }
      }
    },
    updateBooleanInputItem(
      state,
      action: PayloadAction<{ key: string; value: boolean }>
    ) {
      if (state.filters.booleanInputItem) {
        state.filters.booleanInputItem[
          action.payload.key as BooleanInputItemOptions
        ] = action.payload.value;
      }
    },
    updateSearchKey(state, action: PayloadAction<string>) {
      state.searchKey = action.payload;
    },
    updateImageSearchKey(state, action: PayloadAction<ImageSearchKey>) {
      state.imageSearchKey = action.payload;
    },
    updateSearchKeyList(state, action: PayloadAction<string[]>) {
      state.searchKeyList = action.payload;
    },
    addSearchKeyToList(state, action: PayloadAction<string>) {
      if (state.searchKeyList) {
        if (!state.searchKeyList.includes(action.payload)) {
          state.searchKeyList?.push(action.payload);
        }
      } else {
        state.searchKeyList = [action.payload];
      }
    },
    updateSearchActive(state, action: PayloadAction<boolean>) {
      state.searchActive = action.payload;
    },
    updateSearchActiveScreen(state, action: PayloadAction<string>) {
      state.searchActiveScreen = action.payload;
    },
    updateFiltersActive(state, action: PayloadAction<boolean>) {
      state.filtersActive = action.payload;
    },
    updatePaginationKey(state, action: PayloadAction<number>) {
      state.pagination = action.payload;
    },
    updateRecentSearches(
      state,
      action: PayloadAction<{ typed: RecentSearchTypes; searchTerm: string }>
    ) {
      const { typed, searchTerm } = action.payload;
      if (!state.recentSearches[typed]?.includes(searchTerm)) {
        state.recentSearches[typed] = [
          ...state.recentSearches[typed],
          searchTerm,
        ];
      }
    },
    clearRecentSearches(state, action: PayloadAction<RecentSearchTypes>) {
      const typed = action.payload;
      state.recentSearches[typed] = [];
    },
    addOperationInputItem(
      state,
      action: PayloadAction<{ key: string; operator: Operator; value: number }>
    ) {
      // @ts-ignore
      state.filters.arithmeticOperatorInputItem[action.payload.key] = {
        operator: action.payload.operator,
        value: action.payload.value,
      };
    },
    updateOperationInputItem(
      state,
      action: PayloadAction<{ key: string; operator: Operator; value: number }>
    ) {
      const { key, operator, value } = action.payload;
      if (state.filters.arithmeticOperatorInputItem) {
        state.filters.arithmeticOperatorInputItem[
          key as ArithmeticOperatorInputOptions
        ] = { operator, value };
      }
    },

    updateSortField(state, action: PayloadAction<SortField>) {
      state.filters.sortField = action.payload;
    },
    updateInitialSortField(state, action: PayloadAction<SortField>) {
      const s = { ...state.appliedFilters } as FilterInputs;
      s.sortField = action.payload;
      state.appliedFilters = s;
      state.filters.sortField = action.payload;
    },

    updateEpochTimeWithScreen(
      state,
      action: PayloadAction<{
        screen: EpochScreenType;
        startDate: Date;
        endDate: Date;
      }>
    ) {
      state.epoch[action.payload.screen] = {
        startDate: action?.payload?.startDate,
        endDate: action?.payload?.endDate,
      };
    },

    updateSiftByScreen(
      state,
      action: PayloadAction<{ screen: keyof Sift; filters: any }>
    ) {
      state.sift = {
        ...state.sift,
        [action.payload.screen]: action.payload.filters,
      };
    },
    updateIndividualSiftByKeyAndScreen(
      state,
      action: PayloadAction<{
        value: InputItem[];
        key: string;
        screen: SiftScreenEnum;
      }>
    ) {
      const currentSiftValue = state.sift[action.payload.screen];
      if (currentSiftValue) {
        currentSiftValue[action.payload.key] = action.payload.value;
      }
      state.sift = {
        ...state.sift,
        [action.payload.screen]: currentSiftValue,
      };
    },
    updateAppliedThreadsFitler(
      state,
      action: PayloadAction<{ threadsFilters: ThreadsFitlers }>
    ) {
      state.threadsFilters = action?.payload?.threadsFilters;
    },
  },
});

export const {
  updateTreeInputItem,
  updateListInputItem,
  addListInputItems,
  addTreeInputItems,
  deleteListInputItem,
  updateCalendarInputItem,
  addSearchListInputItems,
  updateSearchListInputItem,
  updateAppliedFilters,
  addStringInputItems,
  updateStringInputItems,
  addBooleanInputItem,
  updateBooleanInputItem,
  updateSearchKey,
  updateImageSearchKey,
  updateFiltersActive,
  updateSearchActive,
  clearAppliedFilter,
  addOperationInputItem,
  updateOperationInputItem,
  updatePaginationKey,
  updateSearchKeyList,
  addSearchKeyToList,
  updateSearchActiveScreen,
  updateRecentSearches,
  updateSortField,
  updateInitialSortField,
  clearRecentSearches,
  updateEpochTimeWithScreen,
  updateSiftByScreen,
  updateAppliedFiltersByScreen,
  updateIndividualSiftByKeyAndScreen,
  updateAppliedFiltersByScreenAndValue,
  clearAppliedFiltersByScreen,
  updateAppliedThreadsFitler,
} = filtersSlice.actions;

export const getFiltersSliceState = (rootState: RootState): FiltersState =>
  rootState[FILTER_FEATURE_KEY];

export const getFilters = createSelector(
  getFiltersSliceState,
  (state) => state.filters
);
export const getAppliedFilters = createSelector(
  getFiltersSliceState,
  (state) => state.appliedFilters as FilterInputs
);

export const getSearchKey = createSelector(
  getFiltersSliceState,
  (state) => state?.searchKey
);
export const getImageSearchKey = createSelector(
  getFiltersSliceState,
  (state) => state.imageSearchKey
);

export const getSearchActive = createSelector(
  getFiltersSliceState,
  (state) => state?.searchActive
);

export const getSearchActiveScreen = createSelector(
  getFiltersSliceState,
  (state) => state?.searchActiveScreen
);

export const getFiltersActive = createSelector(
  getFiltersSliceState,
  (state) => state?.filtersActive
);

export const getPaginationKey = createSelector(
  getFiltersSliceState,
  (state) => state?.pagination
);

export const getSearchKeyList = createSelector(
  getFiltersSliceState,
  (state) => state?.searchKeyList
);

export const getRecentSearches = createSelector(
  getFiltersSliceState,
  (state) => state?.recentSearches
);

export const getSortField = createSelector(
  getFiltersSliceState,
  (state) => state?.filters?.sortField
);

export const getEpochRangeWithScreen = (screen: EpochScreenType) => {
  return createSelector(
    getFiltersSliceState,
    (state) => state?.epoch?.[screen]
  );
};

export const getSiftByScreen = (screen: SiftScreenEnum) => {
  return createSelector(getFiltersSliceState, (state) => state?.sift?.[screen]);
};

export const getAppliedFiltersByScreen = (
  screen: SiftScreenEnum | undefined
) => {
  return createSelector(getFiltersSliceState, (state) =>
    screen ? state?.sift[SiftScreenEnum.APPLIED_FILTERS][screen] : {}
  );
};

export const getAppliedThreadsFilter = createSelector(
  getFiltersSliceState,
  (state) => state?.threadsFilters
);
