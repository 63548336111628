import React, { useContext, useState, useEffect, useRef } from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Icon from 'react-native-easy-icon';
import { Chip, ListItemText, OutlinedInput } from '@mui/material';
import './zo-select.scss';
import { Avatar, SHARED_SVGS, ThemeContext } from '@zonofi/shared';
import { convertCamelCaseToRegularCase } from '@zonofi/common';
import _ from 'lodash';

interface option {
  label: string;
  value: string;
}
interface SingleSelectProps {
  /**
   * Provide options which is shwon in the drop donw list.
   */
  options: option[];
  /**
   * Provide the tite of the drop down list.
   */
  title?: string;

  /**
   * Value for dorp down
   */
  value: any;
  /**
   * Callback function to be called on selcet of item from drop down list.
   */
  onSelectValue: (val: any) => void;
  /**
   * Placeholder text for the drop down
   */
  placeholder: string;
  /**
   * Custom style populated over the multi-select.
   */
  customMultiSelectStyle?: React.CSSProperties;
  /**
   * Custom style populated on the input feild
   */
  customInputStyle?: React.CSSProperties;
  /**
   * Provide this value for the required drop down (default: false)
   */
  required?: boolean;
  /**
   * Avatar to be populated on the left of the option text. (By default Text Avatar to be shown)
   */
  setAvatar?: React.JSX.Element;
  /**
   * Property to hide the avatar. by defalue false value is given.
   */
  showAvatar?: boolean;
  /**
   * Property to be allowed for single select. By default false value is given (By default Multiselect is applied)
   */
  singleSelect?: boolean;
  /**
   * An element to display at the end of the input field.
   * Example: An icon or text.
   */
  endAdornment?: React.JSX.Element;
  /**
   * Disabled multi-select or select. By defaut false is given
   */
  disabled?: boolean;
  /**
   * Callback function to be called on click of multiselect
   */
  onclick?: () => void;
  /**
   * Provide the Limit tags. by defaut 4 is given
   */
  limitTag?: number;

  onFocus?: (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
  ) => any;
  onBlur?: (val: string) => void;
  focusInput?: boolean;
}

const uncheckedIcon = (
  <img
    alt="uncheckedBox"
    src={SHARED_SVGS.UncheckedBox}
    width="16px"
    height="16px"
    style={{ marginTop: '2px' }}
  />
);
const checkedIcon = (
  <Icon type={'antdesign'} name={'checksquare'} size={16} color="#2C9AD9" />
);
const closeIcon = (
  <Icon
    type={'evilicon'}
    name={'close'}
    size={16}
    color="#626F86"
    //@ts-ignore
    style={{ cursor: 'pointer' }}
  />
);

// const ArrowIcon = ({ isOpen }: { isOpen: boolean }) => (
//     <img
//         src={isOpen ? SHARED_SVGS.SelectArrowUp : SHARED_SVGS.SelectArrowDown}
//         alt="Arrow Icon"
//         style={{
//             width: '16px',
//             height: '16px',
//             marginRight: '10px',
//             transition: 'transform 0.3s ease',
//             filter: isOpen
//                 ? 'invert(29%) sepia(10%) saturate(178%) hue-rotate(199deg) brightness(87%) contrast(88%)' // Example dark color
//                 : 'invert(29%) sepia(10%) saturate(178%) hue-rotate(199deg) brightness(87%) contrast(88%)' // Example gray color
//         }}
//     />
// );
const ArrowIcon = ({ isOpen }: { isOpen: boolean }) => (
  <div
    onClick={(e) => e.stopPropagation()}
    style={{
      display: 'flex',
      alignItems: 'center',
      position: 'absolute',
      right: 0,
    }}
  >
    <img
      src={isOpen ? SHARED_SVGS.SelectArrowUp : SHARED_SVGS.SelectArrowDown}
      alt="Arrow Icon"
      style={{
        width: '16px',
        height: '16px',
        marginRight: '10px',
        transition: 'transform 0.3s ease',
        cursor: 'pointer',
        filter: isOpen
          ? 'invert(29%) sepia(10%) saturate(178%) hue-rotate(199deg) brightness(87%) contrast(88%)'
          : 'invert(29%) sepia(10%) saturate(178%) hue-rotate(199deg) brightness(87%) contrast(88%)',
      }}
    />
  </div>
);

export function ZoSelect(props: SingleSelectProps) {
  const {
    title,
    options,
    placeholder,
    required,
    value,
    onSelectValue,
    customInputStyle,
    customMultiSelectStyle,
    setAvatar,
    showAvatar = false,
    singleSelect = false,
    endAdornment = null,
    disabled = false,
    limitTag = 4,
    onclick,
    onFocus,
    onBlur,
    focusInput,
  } = props;
  const { theme } = useContext(ThemeContext);
  const [borderColor, setBorderColor] = useState('#626F86');
  const [val, setVal] = useState('');
  const [open, setOpen] = useState(false);
  console.log(value, options, 'return the values res');

  const titleColor = val ? '#2C9AD9' : '#000';

  useEffect(() => {
    if (value) {
      setVal(value);
    }
  }, [value]);

  const [error, setError] = useState(false);

  const handleChange = (e: any) => {
    const selectedValue = e.target.value;
    if (required && !selectedValue) {
      setError(true);
    } else {
      setError(false);
      onSelectValue(selectedValue);
    }
    setVal(selectedValue);
  };
  const handleOpen = () => setOpen(true); // Dropdown opened
  const handleClose = () => setOpen(false); // Dropdown closed

  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (focusInput && ref && !_.isNull(ref)) {
      ref?.current?.focus();
    } else if (ref && ref?.current?.blur) {
      ref?.current?.blur();
    }
  }, [focusInput]);

  return (
    <div className="zo-select-container font-regular">
      {title && (
        <span
          className={`select_title ${val ? 'active' : ''}`}
          style={{ color: open ? '#32a7e8' : '#000000' }}
        >
          {title}
          {required && <span className="select_asterisk">*</span>}
        </span>
      )}
      <Select
        labelId="select-label"
        name={'State'}
        id={'hello'}
        required={required}
        value={val?.includes('_') ? convertCamelCaseToRegularCase(val) : val}
        inputRef={ref}
        disabled={disabled}
        onChange={handleChange}
        placeholder={placeholder}
        onFocus={(e) => {
          onFocus && onFocus(e);
        }}
        onBlur={(e) => {
          onBlur && onBlur(e?.target?.value);
        }}
        // renderValue={(selected) => {
        //     return selected || placeholder;
        // }}
        renderValue={(selected) => {
          if (!selected) {
            return (
              <span style={{ color: '#bfbfbf', fontSize: '16px' }}>
                {placeholder}
              </span>
            );
          }
          return (
            <span style={{ color: '#8C8C8C', fontSize: '16px' }}>
              {selected}
            </span>
          );
        }}
        displayEmpty
        onOpen={handleOpen} // Trigger open state
        onClose={handleClose} // Trigger close state
        IconComponent={() => <ArrowIcon isOpen={open} />}
        className="custom-select font-regular"
        sx={{
          '& .MuiSelect-select': {
            // Additional specific styles for the select text
            color: val ? '#8C8C8C' : '#bfbfbf', // Black if value is selected, gray for placeholder
            fontSize: '16px',
            padding: '10px',
            paddingRight: '0px',
            zIndex: 99,
          },
          borderRadius: '6px',
          // Apply customInputStyle if provided, otherwise use default styles
          ...customInputStyle,
        }}
      >
        {options.map((option) => {
          return (
            <MenuItem key={option.value} value={option.value}>
              <div className="option-container">
                <span>
                  {showAvatar &&
                    (setAvatar || (
                      <Avatar cfa={true} title={option.label} size={24} />
                    ))}
                </span>
                <ListItemText
                  disableTypography
                  primary={
                    option.label?.includes('_')
                      ? convertCamelCaseToRegularCase(option.label)
                      : option.label
                  }
                  className="list-item-text"
                />
              </div>
            </MenuItem>
          );
        })}
      </Select>
    </div>
  );
}
