import { Api } from '../api';
import {
  ListInputItemOptions,
  addListInputItems,
  mapDataToInputItems,
} from '../filter';
import {
  BulkImportDataRequest,
  BulkImportDataResponse,
  BulkImportListRequest,
  BulkImportListResponse,
  BulkRecordMappingStatusTextEnum,
  BulkUploadRequest,
  DownloadErpFileRequest,
  DownloadErpFileResponse,
  ERPUploadBatchUpdateRequest,
  ERPUploadBatchUpdateResponse,
  ErpFileUploadRequest,
  ErpFileUploadResponse,
  ErpUploadBatchDetailsRequest,
  ErpUploadBatchDetailsResponse,
  GetErpColumnMappingRequest,
  GetErpColumnMappingResponse,
  ListErpUploadsRequest,
  ListErpUploadsResponse,
  UpdateColumnMappingRequest,
  UpdateErpSyncSettingsResponse,
  UpdateErpSyncSettingsRequest,
  GetErpSyncSettingResponse,
} from './model';

export const bulkImportApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    bulkUpload: builder.mutation<any, BulkUploadRequest>({
      query(args) {
        return {
          url: `mdm-integration/uploadbatch`,
          method: 'POST',
          body: args,
        };
      },
    }),
    getBulkImportsList: builder.query<
      BulkImportListResponse,
      BulkImportListRequest
    >({
      query(arg) {
        return {
          url: `mdm-integration/uploadbatch/history`,
          method: 'POST',
          params: arg,
        };
      },
    }),
    getBulkImportData: builder.query<
      BulkImportDataResponse,
      BulkImportDataRequest
    >({
      query(arg) {
        return {
          url: `mdm-integration/uploadbatch/details/${arg?.uploadBatchId}`,
          method: 'POST',
          params: arg?.params,
          body: arg?.body,
        };
      },
      async onQueryStarted(request, { dispatch, queryFulfilled }) {
        queryFulfilled.then((response) => {
          const customerFirmName = response?.data?.customerFirmName?.map(
            (item) => ({
              id: item,
              name: item,
            })
          );

          const erpCustomerName = mapDataToInputItems(
            customerFirmName,
            'id',
            'name'
          )?.filter((x: any) => x.value);

          dispatch(
            addListInputItems({
              inputItems: erpCustomerName,
              key: ListInputItemOptions.erpCustomerName,
            })
          );

          const mappingStatus = response?.data?.mapping_status?.map((item) => ({
            id: item,
            name: BulkRecordMappingStatusTextEnum[item],
          }));

          const erpMappingStatus = mapDataToInputItems(
            mappingStatus,
            'id',
            'name'
          )?.filter((x: any) => x.value);

          dispatch(
            addListInputItems({
              inputItems: erpMappingStatus,
              key: ListInputItemOptions.erpMappingStatus,
            })
          );

          const transactionType = response?.data?.transactionType?.map(
            (item) => ({
              id: item,
              name: item,
            })
          );

          const erpTransactionType = mapDataToInputItems(
            transactionType,
            'id',
            'name'
          )?.filter((x: any) => x.value);

          dispatch(
            addListInputItems({
              inputItems: erpTransactionType,
              key: ListInputItemOptions.erpTransactionType,
            })
          );
        });
      },
    }),
    erpFileUpload: builder.mutation<
      ErpFileUploadResponse,
      ErpFileUploadRequest
    >({
      query(args) {
        return {
          url: `mdm-integration/erp-sync/file-upload/${args?.workspaceId}`,
          method: 'POST',
          params: args?.params,
          body: args?.body,
        };
      },
    }),
    listErpUploads: builder.mutation<
      ListErpUploadsResponse,
      ListErpUploadsRequest
    >({
      query(args) {
        return {
          url: `mdm-integration/erp-sync/history/${args?.workspaceId}`,
          method: 'POST',
          params: args?.params,
        };
      },
    }),
    downloadErpUploadedFile: builder.mutation<any, DownloadErpFileRequest>({
      query(arg) {
        return {
          url: `mdm-integration/erp-sync/details/download/${arg?.id}`,
          params: arg?.params,
          method: 'POST',
          // responseHandler: (response) => response.,
        };
      },
    }),
    erpUploadBatchDetails: builder.mutation<
      ErpUploadBatchDetailsResponse,
      ErpUploadBatchDetailsRequest
    >({
      query(args) {
        return {
          url: `mdm-integration/erp-sync/details/${args?.erpBatchId}`,
          method: 'POST',
          params: args?.params,
        };
      },
    }),
    erpUploadBatchUpdate: builder.mutation<
      ERPUploadBatchUpdateResponse,
      ERPUploadBatchUpdateRequest
    >({
      query(arg) {
        return {
          url: `mdm-integration/uploadbatch/update/${arg?.uploadBatchId}`,
          method: 'POST',
          params: arg?.params,
          body: arg?.body,
        };
      },
    }),
    updateColumnMapping: builder.mutation<any, UpdateColumnMappingRequest>({
      query(arg) {
        return {
          url: `mdm-integration/erp-sync/column-mapping`,
          method: 'POST',
          body: arg?.body,
          params: arg?.params,
        };
      },
    }),
    getErpColumnMapping: builder.mutation<
      GetErpColumnMappingResponse,
      GetErpColumnMappingRequest
    >({
      query(arg) {
        return {
          url: `mdm-integration/erp-sync/column-mapping`,
          method: 'GET',
          params: arg?.params,
        };
      },
    }),
    updateErpSyncSettings: builder.mutation<
      UpdateErpSyncSettingsResponse,
      UpdateErpSyncSettingsRequest
    >({
      query(arg) {
        return {
          url: `/mdm-integration/erp-sync/settings`,
          method: 'POST',
          body: arg?.body,
          params: arg?.params,
        };
      },
    }),

    getErpSyncSettings: builder.query<
      GetErpSyncSettingResponse,
      {
        params: {
          sellerWorkspaceId: string;
        };
      }
    >({
      query(args) {
        return {
          url: 'mdm-integration/erp-sync/settings',
          method: 'GET',
          params: args?.params,
        };
      },
    }),
  }),
});

export const {
  useBulkUploadMutation,
  useLazyGetBulkImportsListQuery,
  useLazyGetBulkImportDataQuery,
  useErpFileUploadMutation,
  useListErpUploadsMutation,
  useErpUploadBatchDetailsMutation,
  useErpUploadBatchUpdateMutation,
  useDownloadErpUploadedFileMutation,
  useUpdateColumnMappingMutation,
  useGetErpColumnMappingMutation,
  useUpdateErpSyncSettingsMutation,
  useLazyGetErpSyncSettingsQuery,
} = bulkImportApi;
