import { Invoice, InvoicesEntity } from '../invoice';


export enum PaymentModeEnum {
  CHEQUE = 'CHQ',
  DEMAND_DRAFT = 'DD',
  BANK_TRANSFER = 'BNTR',
  ONLINE = 'ONLINE',
  CASH = 'CASH',
  UPI = 'UPI',
  WALLET = 'WALLET',
  NET_BANKING = 'NET_BANKING',
  CREDIT_CARD = 'CREDIT_CARD',
  TRANSFER = 'TF',
  CREDIT_NOTE = 'CN',
}
export interface CreatePaymentResponse {
  paymentLink: string;
  orderToken: string;
  paymentRequestId: string;
  currencyCode: string;
  statusCode: number;
  responseStatus: string;
  qrImage?: string;
}

export interface CreatePaymentRequest {
  sellerWorkspaceId?: string;
  buyerWorkspaceId?: string;
  inviteId?: string;
  amountPaid: number;
  currencyCode: string;
  paymentProvider?: string;
  generateQr?: boolean;
  env?: string;
  virtualVpaId?: string;
  docNumberToClear?: string[];
  paymentDate?: string;
  teamMemberId?: string;
}

export interface GetPaymentResponse {
  id: string;
  createdDate: Date;
  updatedDate: string;
  transactionDate?: any;
  amountPaid: number;
  status: string;
  currencyCode: string;
  paymentProvider: string;
  paymentMode: string;
  paymentLink?: any;
  gatewayCode: string;
  gatewayRefCode: string;
  requestPayload: RequestPayload;
  responsePayload: ResponsePayload;
  paymentResponse: PaymentResponse;
  responseCode: string;
  paymentResponseMsg: string;
  paymentTransaction: PaymentTransaction;
  statusCode: number;
  responseStatus: string;
}
export interface Order1 {
  order_id: string;
  order_tags?: any;
  order_amount: number;
  order_currency: string;
}
export interface Netbanking {
  channel?: any;
  netbanking_bank_code: string;
  netbanking_bank_name: string;
}

export interface PaymentMethod {
  netbanking: Netbanking;
  card: Card;
  upi: Upi;
  wallet: wallet;
}
export interface wallet {
  wallet_name: string;
}
export interface Upi {
  upi_id: string;
  channel?: any;
}
export interface Card {
  channel?: any;
  card_type: string;
  card_number: string;
  card_country: string;
  card_network: string;
  card_bank_name: string;
}

export interface Payment {
  auth_id?: any;
  payment_time: Date;
  cf_payment_id: number;
  payment_group: string;
  bank_reference: string;
  payment_amount: number;
  payment_method: PaymentMethod;
  payment_status: string;
  payment_message: string;
  payment_currency: string;
}

export interface CustomerDetails3 {
  customer_id: string;
  customer_name?: any;
  customer_email: string;
  customer_phone: string;
}
export interface Data {
  order: Order1;
  payment: Payment;
  customer_details: CustomerDetails3;
}
export interface PaymentResponse {
  data: Data;
  type: string;
  event_time: Date;
  remitterVpa?: string;
}
export interface PaymentTransaction {
  id: string;
  createdDate: Date;
  updatedDate: Date;
  sellerWorkspaceId: string;
  buyerWorkspaceId: string;
  inviteId: string;
  paymentDate: string;
  amountPaid: number;
  allocatedAmount: number;
  currencyCode: string;
  status: string;
  paymentType: string;
  paymentMode: string;
  paymentProvider?: any;
  proofOfPayment?: any;
  approvedBy?: any;
  createdBy: string;
  paymentRefCode: string;
  instrumentNumber?: any;
  instrumentDate?: any;
  bankName: string;
  bankAccountNumber?: any;
  bankReference: string;
  channel?: any;
  cardNumber?: any;
  cardNetwork?: any;
  cardType?: any;
  bankCode: string;
  upiId?: any;
  narration?: any;
  source: string;
  teamMemberId?: any;
}
export interface RequestPayload {
  orderId: string;
  orderAmount: number;
  orderCurrency: string;
}

export interface ResponsePayload {
  status: string;
  cftoken: string;
  message: string;
}
export interface PaymentLinkResponse {
  id: string;
  inviteId: string;
  sellerWorkspaceId: string;
  linkURL: string;
  statusCode: number;
  responseStatus: string;
}

export interface PaymentsRequest {
  status?: string;
  endDate?: string;
  startDate?: string;
  pageNo?: number;
  pageSize?: number;
  sellerWorkspaceId: string;
  includeSettlement?: boolean;
  inviteIds?: string;
  searchKey?: string;
  isCsv?: boolean;
}
export interface PaymentsResponse {
  payments: PaymentList[];
  total: number;
  startRecord: number;
  endRecord: number;
  statusCode: number;
  responseStatus: string;
}

export interface PaymentList {
  id: string;
  paymentRequestId?: string;
  customerName: string;
  customerCode: string;
  paymentDate: string;
  paymentRefCode: string;
  amountPaid: number;
  paymentType: string;
  paymentMode: string;
  upiId: any;
  inviteId: string;
  sellerWorkspaceId: string;
  buyerWorkspaceId?: string;
  status: string;
  createdDate: string;
  updatedDate: string;
  currencyCode: string;
  instrumentNumber?: string;
  instrumentDate?: string;
  bankName?: string;
  bankAccountNumber?: string;
  narration?: string;
  teamMemberId?: string;
  teamMemberName: string;
  source: string;
  invoices: PaymentInvoices[];
}

export interface OfflinePaymentDetails {
  id: string;
  createdDate: string;
  updatedDate: string;
  sellerWorkspaceId: string;
  buyerWorkspaceId: string;
  inviteId: string;
  paymentDate: string;
  amountPaid: number;
  allocatedAmount: number;
  currencyCode: string;
  status: string;
  subStatus: string;
  paymentType: string;
  paymentMode: string;
  paymentProvider: string;
  proofOfPayment: string;
  approvedBy: string;
  createdBy: string;
  paymentRefCode: string;
  instrumentNumber: string;
  instrumentDate: string;
  bankName: string;
  bankAccountNumber: string;
  bankReference: string;
  channel: string;
  cardNumber: string;
  cardNetwork: string;
  cardType: string;
  bankCode: string;
  upiId: string;
  narration: string;
  source: string;
  isAllocated: boolean;
  teamMemberId: string;
  paymentInvoiceMap: PaymentInvoiceMap[];
  paymentSettleMent: any;
  teamMemberName: string;
  statusCode: number;
  responseStatus: string;
  clearingDate: string;
}

export interface PaymentInvoiceMap {
  id: string;
  createdDate: string;
  updatedDate: string;
  amountCleared: number;
  invoiceId: string;
  paymentTransactionId: string;
  invoice: InvoiceMap;
}

export interface InvoiceMap {
  id: string;
  createdDate: string;
  updatedDate: string;
  referenceInvoiceNumber: string;
  supTyp: string;
  invoiceDate: string;
  dueDate: string;
  invoiceAmount: number;
  paidAmount: number;
  irn: string;
  customerName: string;
  docType: string;
  gstin: any;
  docNumber: string;
  status: string;
  invoiceStatus: string;
  narration: any;
  workspaceId: string;
  inviteId: string;
  linkedWorkspacesId: string;
}

export interface PaymentInvoices {
  id: string;
  createdDate: string;
  updatedDate: string;
  referenceInvoiceNumber: string;
  supTyp: string;
  invoiceDate: string;
  dueDate: string;
  invoiceAmount: number;
  paidAmount: number;
  irn: string;
  customerName: string;
  docType: string;
  gstin: any;
  docNumber: string;
  status: string;
  invoiceStatus: string;
  narration: any;
  workspaceId: string;
  inviteId: string;
  linkedWorkspacesId: string;
  amountCleared: number;
}

export interface RecordPaymentRequest {
  sellerWorkspaceId: string;
  inviteId: string;
  amountPaid: number;
  currencyCode: string;
  automatedInvoiceClearance?: boolean;
  // invoice: Invoices[];
  paymentMode: string;
  paymentRefCode?: string;
  paymentDate?: string;
  instrumentNumber?: string;
  instrumentDate?: string;
  bankName?: string;
  bankAccountNumber?: string;
  bankReference?: string;
  narration?: string;
  source?: string;
  teamMemberId?: string;
}
export interface RecordPaymentResponse {
  id: string;
  createdDate: string;
  updatedDate: string;
  sellerWorkspaceId: string;
  buyerWorkspaceId: string;
  inviteId: string;
  paymentDate: string;
  amountPaid: number;
  allocatedAmount: number;
  currencyCode: string;
  status: string;
  paymentType: string;
  paymentMode: string;
  paymentProvider: string;
  proofOfPayment: string;
  approvedBy: string;
  createdBy: string;
  paymentRefCode: string;
  instrumentNumber: string;
  instrumentDate: string;
  bankName: string;
  bankAccountNumber: string;
  bankReference: string;
  channel: string;
  cardNumber: string;
  cardNetwork: string;
  cardType: string;
  bankCode: string;
  upiId: string;
  narration: string;
  source: string;
  teamMemberId: string;
  invoices: Invoice[];
  statusCode: number;
  responseStatus: string;
}

export enum PaymentMode {
  OFFLINE = 'offline',
  ONLINE = 'online',
}
export interface PaymentDetailsResponse {
  successfulPaymentAmount: number;
  pendingPaymentAmount: number;
  failedPaymentAmount: number;
  statusCode: number;
  responseStatus: string;
}

export interface PaymentDetailsRequest {
  endDate?: string;
  startDate?: string;
  pageNo?: number;
  pageSize?: number;
  sellerWorkspaceId: string;
  includeSettlement: boolean;
  inviteIds?: string;
}

export interface PaymentIdRequest {
  paymentId: string;
  includeSettlement: boolean;
  workspaceId: string;
  sellerWorkspaceId?: string;
}

export interface PaymentSummaryDetailsRequest {
  sellerWorkspaceId: string;
  startDate?: string;
  endDate?: string;
  teamMemberId?: string;
}
export interface PaymentSummaryDetailsResponse {
  payments: PaymentSummaryDetailsList[];
  totalAmount: number;
  statusCode: number;
  responseStatus: string;
}

export interface PaymentSummaryDetailsList {
  paymentMode: string;
  amount: number;
}

export interface CustomerSearchRecordsResponse {
  customers?: CustomerRecordsEntity[] | null;
  totalCount: number;
  startRecord: number;
  endRecord: number;
}
export interface CustomerRecordsEntity {
  id: string;
  phone: string;
  senderUserId: string;
  senderWorkspaceId: string;
  status: string;
  createdDate: string;
  updatedDate: string;
  gstin?: string | null;
  businessPAN?: string | null;
  distributorCode: string;
  postalCode?: string | null;
  physicalAddress?: string | null;
  companyName: string;
  stateId?: string | null;
  stateName?: string | null;
  countryId?: string | null;
  mobile?: string | null;
  cityName?: string | null;
  name?: string | null;
  email?: string | null;
  spaceName?: string | null;
  address?: null;
  profilePic?: null;
  wgstin?: string | null;
  workspaceMembersCount: number;
  customerGroups?: CustomerGroupsEntity[] | null;
}
export interface CustomerGroupsEntity {
  id: string;
  name: string;
  groupRefCode?: null;
  createdDate: string;
  updatedDate: string;
  workspaceId: string;
  updatedBy?: null;
  parentGroupId?: string | null;
  lineage?: null;
  heritage?: null;
  groupsHierarchyDimensionsId: string;
}

export interface CustomerSearchRecordsRequest {
  includeWorkspaceMembers?: number;
  includeCustomerGroupAssignments?: number;
  pageNo?: number;
  pageSize?: number;
  searchKey?: string;
  workspaceId: string;
}

export interface CustomerPaymentFilter {
  customerName?: string[];
  customerCode?: string;
  pendingAmount?: {
    value: string;
    key: string;
  };
  paymentId?: string;
  status?: string[];
  includePendingPayments?: boolean;
  routeName?: string[];
  outstandingAmount?: {
    value: string;
    key: string;
  };
  InvoiceNumber?: string;
}

export interface Settlements {
  settlements: Settlement[];
  total: number;
  startRecord: number;
  endRecord: number;
  statusCode: number;
  responseStatus: string;
}

export interface Settlement {
  id: string;
  settlementDate: string | null;
  createdDate: string;
  updatedDate: string;
  sellerWorkspaceId: string;
  buyerWorkspaceId: null | string;
  inviteId: string;
  settlementStatus: string;
  settlementUtr: string;
  settlementAmount: number;
  transferUtr: string;
  transferId: string;
  paymentsSettledCount: number;
  payments: SettlementPayment[];
}

export interface SettlementPayment {
  id: string;
  paymentRequestId: string;
  customerName: string;
  customerCode: string;
  paymentDate: string;
  paymentRefCode: string;
  amountPaid: number;
  paymentType: string;
  paymentMode: string;
  upiId: string;
  inviteId: string;
  sellerWorkspaceId: string;
  buyerWorkspaceId: null | string;
  status: string;
  subStatus: string;
  createdDate: string;
  updatedDate: string;
  currencyCode: string;
  instrumentNumber?: string;
  instrumentDate?: string;
  bankName?: string;
  bankAccountNumber?: string;
  narration?: string;
  teamMemberId?: string;
  teamMemberName?: string;
  source: string;
}

export interface SettlementRequest {
  startDate: string;
  endDate: string;
  pageSize: number;
  pageNo: number;
  sellerWorkspaceId: string;
  includePayments: boolean;
}

export interface ReceivedPaymentsRequest {
  sellerWorkspaceId: string;
  pageNo: number;
  pageSize: number;
  startDate: string;
  endDate: string;
  sortBy: string;
  sortDirection: string;
}

export interface ReceivedPaymentsReponse {
  records: ReceivedPaymentsRecord[];
  summary: ReceivedPaymentsSummary;
  startRecord: number;
  endRecord: number;
  total: number;
  statusCode: number;
  responseStatus: string;
}

export interface ReceivedPaymentsRecord {
  id: string;
  createdDate: string;
  updatedDate: string;
  receivedDate: string;
  sellerWorkspaceId: string;
  cashierTeamMemberId: string;
  collectionAgentTeamMemberId: string;
  totalAmountReceived: number;
  totalAmountByCash: number;
  totalAmountByCheque: number;
  totalAmountByOthers: number;
  narration: string;
  cashierTeamMemberName: string;
  collectionAgentTeamMemberName: string;
  noOfPayments: number;
  isSelected?: boolean;
}

export interface ReceivedPaymentsSummary {
  narration?: string;
  totalAmountReceived: number;
  totalNoOfPayments: number;
  totalAmountByCash: number;
  totalAmountByCheque: number;
  totalAmountByOthers: number;
}

export interface RecivedPayment {
  id: string;
  createdDate: Date;
  updatedDate: Date;
  receivedDate: Date;
  sellerWorkspaceId: string;
  cashierTeamMemberId: string;
  collectionAgentTeamMemberId: string;
  totalAmountReceived: number;
  totalAmountByCash: number;
  totalAmountByCheque: number;
  totalAmountByOthers: number;
  narration: string;
  cashierTeamMemberName: string;
  collectionAgentTeamMemberName: string;
  payments: RecivedPaymentList[];
  statusCode: number;
  responseStatus: string;
}

export interface RecivedPaymentList {
  id: string;
  paymentRefCode: string;
  amount: number;
  paymentMode: string;
  paymentDate: Date;
  inviteId: string;
  invoiceNumber: string[];
  noOfInvoices: number;
  customerName: string;
  narration: string;
}
interface PaymentErrorDetails {
  orderAmount?: string;
  orderId?: string;
  paymentMode?: string;
  referenceId?: string;
  signature?: string;
  txMsg?: string;
  txStatus?: string;
  txTime?: string;
}

export interface PaymentsTransactionsEntity {
  id: string;
  transactionId?: number;
  customerName: string;
  transactionDate?: string;
  amountPaid: number;
  paymentType: string;
  paymentMode: string;
  paymentRefCode: string;
  instrumentNumber?: string;
  instrumentDate?: string;
  bankName?: string;
  narration?: string;
  inviteId: string;
  workspaceId?: string;
  status: string;
  createdDate: string;
  updatedDate: string;
  invoices?: string[] | PaymentInvoices[];
  paymentInvoiceMap?: { invoice: InvoicesEntity; amountCleared: number }[];
  teamMemberId?: string;
  channel?: string;
  cardNumber?: string;
  cardNetwork?: string;
  cardType?: string;
  upiId?: string;
  bankAccountNumber?: string;
  bankCode?: string;
  paymentId?: string;
  cardCountry?: string;
  collectedBy?: string;
  paymentDate?: string;
  errorDetails?: {
    responseCode: string;
    paymentResponseMsg: string;
    paymentResponse: PaymentErrorDetails;
  };
  bankReference?: string;
  paymentSettleMent?: {
    id: string;
    settlementAmount: number;
    settlementStatus: string;
    settlementUtr: string;
  };
}

export interface RecordDetails {
  id?: string;
  customerName?: string;
  sellerWorkspaceId: string;
  inviteId: string;
  date: string;
  paymentMode: string;
  paymentType?: string;
  amountPaid: number;
  currencyCode: string;
  invoice: { invoiceId: string }[];
  invoicesWithDetail?: InvoicesEntity[];
  instrumentNumber?: string;
  paymentRefCode?: string;
  instrumentDate?: string;
  bankName?: string;
  bankAccountNumber?: string;
  narration?: string;
  status?: string;
  paymentId?: string;
  teamMemberId?: string;
  automatedInvoiceClearance?: boolean;
  channel?: string;
  cardNumber?: string;
  cardNetwork?: string;
  cardType?: string;
  upiId?: string;
  bankCode?: string;
  collectedBy?: string;
  errorDetails?: {
    responseCode: string;
    paymentResponseMsg: string;
    paymentResponse: PaymentErrorDetails;
  };
  bankReference?: string;
  paymentDate?: string;
  paymentTransactionId?: string;
  paymentSettleMent?: {
    id: string;
    settlementAmount: number;
    settlementStatus: string;
    settlementUtr: string;
    transferUtr?: string;
  };
}
export interface UpdatePaymentRequest {
  id?: string;
  customerName?: string;
  sellerWorkspaceId: string;
  inviteId: string;
  date: string;
  paymentDate?: string;
  paymentMode: string;
  paymentType?: string;
  amountPaid: number;
  instrumentNumber?: string;
  instrumentDate?: string;
  paymentRefCode?: string;
  bankName?: string;
  currencyCode?: string;
  narration?: string;
  invoice?: UpdatedInvoice[];
  invoicesWithDetail?: InvoicesWithDetail[] | InvoicesEntity[];
  status?: string;
  paymentId?: string;
  teamMemberId?: string;
  automatedInvoiceClearance?: boolean;
  paymentTransactionId?: string;
}

export interface UpdatedInvoice {
  invoiceId: string;
}

export interface InvoicesWithDetail {
  id: string;
  createdDate: string;
  updatedDate: string;
  referenceInvoiceNumber: string;
  supTyp: string;
  invoiceDate: string;
  dueDate: string;
  invoiceAmount: number;
  paidAmount?: number;
  irn: string;
  customerName: string;
  docType: string;
  gstin?: null;
  docNumber: string;
  status: string;
  invoiceStatus: string;
  narration?: null;
  workspaceId: string;
  inviteId: string;
  docs?: null;
  info?: null;
  url?: null;
  linkedWorkspacesId: string;
  locationId?: null;
  amountCleared: number;
  pendingAmount: string;
  isSelected: boolean;
}

export interface UpdatePaymentResponse {
  id: string;
  createdDate: string;
  updatedDate: string;
  sellerWorkspaceId: string;
  buyerWorkspaceId: null;
  inviteId: string;
  paymentDate: Date;
  amountPaid: number;
  allocatedAmount: number;
  currencyCode: string;
  status: string;
  subStatus: string;
  paymentType: string;
  paymentMode: string;
  paymentProvider: null;
  proofOfPayment: null;
  approvedBy: null;
  createdBy: string;
  paymentRefCode: string;
  instrumentNumber: string;
  instrumentDate: Date;
  bankName: string;
  bankAccountNumber: string;
  bankReference: null;
  channel: null;
  cardNumber: null;
  cardNetwork: null;
  cardType: null;
  bankCode: null;
  upiId: null;
  narration: string;
  source: string;
  isAllocated: boolean;
  teamMemberId: string;
  paymentRequest: any[];
  invoices: PaidInvoice[];
  statusCode: number;
  responseStatus: string;
}

export interface PaidInvoice {
  id: string;
  createdDate: Date;
  updatedDate: Date;
  referenceInvoiceNumber: string;
  supTyp: string;
  invoiceDate: Date;
  dueDate: Date;
  invoiceAmount: number;
  paidAmount: number;
  irn: string;
  customerName: string;
  docType: string;
  gstin: null;
  docNumber: string;
  status: string;
  invoiceStatus: string;
  narration: null;
  workspaceId: string;
  inviteId: string;
  docs: null;
  info: null;
  url: null;
  linkedWorkspacesId: string;
  locationId: null;
}

export interface PaymentsInvoicesEntity {
  id: string;
  dueDate: string;
  invoiceNumber: string;
  amount: number;
  amountPaid: number;
  amountSettled: number;
  amountPending: number;
  isSelected: boolean;
}

export interface SelectedTeamMemberForReceivePayments {
  teamMemberId: string;
  teamMemberName: string;
  selectedReceiveDate: string;
  cashierId: string;
  cashierName: string;
}

export interface EditedDetails {
  sellerWorkspaceId: string;
  cashierTeamMemberId: string;
  paymentTransactionsNarration: PaymentTransactionsNarration[];
  totalReceivingAmount: number;
  totalAmountByCash: number;
  totalAmountByCheque: number;
  totalAmountByUPI: number;
  narration: string;
  paymentReceivedId?: string;
  collectionAgentTeamMemberId?: string;
}

export interface PaymentTransactionsNarration {
  paymentTransactionId: string;
  narration: string;
}

export interface TeamMemberPaymentsTransactionsEntity {
  id: string;
  amountPaid?: number;
  narration: string;
  invoices?: InvoicesRecords[];
  paymentDate: string;
  amount?: number;
  paymentMode: string;
  paymentRefCode: string;
  customerName: string;
  invoiceNumber?: string[];
  noOfInvoices?: number;
  isSelected?: boolean;
}

export interface InvoicesRecords {
  id: string;
  docNumber?: string;
}

export interface Settlements {
  settlements: Settlement[];
  total: number;
  startRecord: number;
  endRecord: number;
  statusCode: number;
  responseStatus: string;
}

export interface Settlement {
  id: string;
  settlementDate: string | null;
  createdDate: string;
  updatedDate: string;
  sellerWorkspaceId: string;
  buyerWorkspaceId: null | string;
  inviteId: string;
  settlementStatus: string;
  settlementUtr: string;
  settlementAmount: number;
  transferUtr: string;
  transferId: string;
  paymentsSettledCount: number;
  payments: SettlementPayment[];
}

export interface SettlementPayment {
  id: string;
  paymentRequestId: string;
  customerName: string;
  customerCode: string;
  paymentDate: string;
  paymentRefCode: string;
  amountPaid: number;
  paymentType: string;
  paymentMode: string;
  upiId: string;
  inviteId: string;
  sellerWorkspaceId: string;
  buyerWorkspaceId: null | string;
  status: string;
  subStatus: string;
  createdDate: string;
  updatedDate: string;
  currencyCode: string;
  instrumentNumber?: string;
  instrumentDate?: string;
  bankName?: string;
  bankAccountNumber?: string;
  narration?: string;
  teamMemberId?: string;
  teamMemberName?: string;
  source: string;
}

export interface SettlementRequest {
  startDate: string;
  endDate: string;
  pageSize: number;
  pageNo: number;
  sellerWorkspaceId: string;
  includePayments: boolean;
}

export interface ReceivedPaymentsRequest {
  sellerWorkspaceId: string;
  pageNo: number;
  pageSize: number;
  startDate: string;
  endDate: string;
  sortBy: string;
  sortDirection: string;
}

export interface ReceivedPaymentsReponse {
  records: ReceivedPaymentsRecord[];
  summary: ReceivedPaymentsSummary;
  startRecord: number;
  endRecord: number;
  total: number;
  statusCode: number;
  responseStatus: string;
}

export interface ReceivedPaymentsRecord {
  id: string;
  createdDate: string;
  updatedDate: string;
  receivedDate: string;
  sellerWorkspaceId: string;
  cashierTeamMemberId: string;
  collectionAgentTeamMemberId: string;
  totalAmountReceived: number;
  totalAmountByCash: number;
  totalAmountByCheque: number;
  totalAmountByOthers: number;
  narration: string;
  cashierTeamMemberName: string;
  collectionAgentTeamMemberName: string;
  noOfPayments: number;
  isSelected?: boolean;
}

export interface ReceivedPaymentsSummary {
  narration?: string;
  totalAmountReceived: number;
  totalNoOfPayments: number;
  totalAmountByCash: number;
  totalAmountByCheque: number;
  totalAmountByOthers: number;
}

export interface RecivedPayment {
  id: string;
  createdDate: Date;
  updatedDate: Date;
  receivedDate: Date;
  sellerWorkspaceId: string;
  cashierTeamMemberId: string;
  collectionAgentTeamMemberId: string;
  totalAmountReceived: number;
  totalAmountByCash: number;
  totalAmountByCheque: number;
  totalAmountByOthers: number;
  narration: string;
  cashierTeamMemberName: string;
  collectionAgentTeamMemberName: string;
  payments: RecivedPaymentList[];
  statusCode: number;
  responseStatus: string;
}

export interface RecivedPaymentList {
  id: string;
  paymentRefCode: string;
  amount: number;
  paymentMode: string;
  paymentDate: Date;
  inviteId: string;
  invoiceNumber: string[];
  noOfInvoices: number;
  customerName: string;
  narration: string;
}
interface PaymentErrorDetails {
  orderAmount?: string;
  orderId?: string;
  paymentMode?: string;
  referenceId?: string;
  signature?: string;
  txMsg?: string;
  txStatus?: string;
  txTime?: string;
}

export interface PaymentsTransactionsEntity {
  id: string;
  transactionId?: number;
  customerName: string;
  transactionDate?: string;
  amountPaid: number;
  paymentType: string;
  paymentMode: string;
  paymentRefCode: string;
  instrumentNumber?: string;
  instrumentDate?: string;
  bankName?: string;
  narration?: string;
  inviteId: string;
  workspaceId?: string;
  status: string;
  createdDate: string;
  updatedDate: string;
  invoices?: string[] | PaymentInvoices[];
  paymentInvoiceMap?: { invoice: InvoicesEntity; amountCleared: number }[];
  teamMemberId?: string;
  channel?: string;
  cardNumber?: string;
  cardNetwork?: string;
  cardType?: string;
  upiId?: string;
  bankAccountNumber?: string;
  bankCode?: string;
  paymentId?: string;
  cardCountry?: string;
  collectedBy?: string;
  paymentDate?: string;
  errorDetails?: {
    responseCode: string;
    paymentResponseMsg: string;
    paymentResponse: PaymentErrorDetails;
  };
  bankReference?: string;
  paymentSettleMent?: {
    id: string;
    settlementAmount: number;
    settlementStatus: string;
    settlementUtr: string;
  };
}

export interface RecordDetails {
  id?: string;
  customerName?: string;
  sellerWorkspaceId: string;
  inviteId: string;
  date: string;
  paymentMode: string;
  paymentType?: string;
  amountPaid: number;
  currencyCode: string;
  invoice: { invoiceId: string }[];
  invoicesWithDetail?: InvoicesEntity[];
  instrumentNumber?: string;
  paymentRefCode?: string;
  instrumentDate?: string;
  bankName?: string;
  bankAccountNumber?: string;
  narration?: string;
  status?: string;
  paymentId?: string;
  teamMemberId?: string;
  automatedInvoiceClearance?: boolean;
  channel?: string;
  cardNumber?: string;
  cardNetwork?: string;
  cardType?: string;
  upiId?: string;
  bankCode?: string;
  collectedBy?: string;
  errorDetails?: {
    responseCode: string;
    paymentResponseMsg: string;
    paymentResponse: PaymentErrorDetails;
  };
  bankReference?: string;
  paymentDate?: string;
  paymentTransactionId?: string;
  paymentSettleMent?: {
    id: string;
    settlementAmount: number;
    settlementStatus: string;
    settlementUtr: string;
    transferUtr?: string;
  };
}
export interface UpdatePaymentRequest {
  id?: string;
  customerName?: string;
  sellerWorkspaceId: string;
  inviteId: string;
  date: string;
  paymentDate?: string;
  paymentMode: string;
  paymentType?: string;
  amountPaid: number;
  instrumentNumber?: string;
  instrumentDate?: string;
  paymentRefCode?: string;
  bankName?: string;
  currencyCode?: string;
  narration?: string;
  invoice?: UpdatedInvoice[];
  invoicesWithDetail?: InvoicesWithDetail[] | InvoicesEntity[];
  status?: string;
  paymentId?: string;
  teamMemberId?: string;
  automatedInvoiceClearance?: boolean;
  paymentTransactionId?: string;
}

export interface UpdatedInvoice {
  invoiceId: string;
}

export interface InvoicesWithDetail {
  id: string;
  createdDate: string;
  updatedDate: string;
  referenceInvoiceNumber: string;
  supTyp: string;
  invoiceDate: string;
  dueDate: string;
  invoiceAmount: number;
  paidAmount?: number;
  irn: string;
  customerName: string;
  docType: string;
  gstin?: null;
  docNumber: string;
  status: string;
  invoiceStatus: string;
  narration?: null;
  workspaceId: string;
  inviteId: string;
  docs?: null;
  info?: null;
  url?: null;
  linkedWorkspacesId: string;
  locationId?: null;
  amountCleared: number;
  pendingAmount: string;
  isSelected: boolean;
}

export interface UpdatePaymentResponse {
  id: string;
  createdDate: string;
  updatedDate: string;
  sellerWorkspaceId: string;
  buyerWorkspaceId: null;
  inviteId: string;
  paymentDate: Date;
  amountPaid: number;
  allocatedAmount: number;
  currencyCode: string;
  status: string;
  subStatus: string;
  paymentType: string;
  paymentMode: string;
  paymentProvider: null;
  proofOfPayment: null;
  approvedBy: null;
  createdBy: string;
  paymentRefCode: string;
  instrumentNumber: string;
  instrumentDate: Date;
  bankName: string;
  bankAccountNumber: string;
  bankReference: null;
  channel: null;
  cardNumber: null;
  cardNetwork: null;
  cardType: null;
  bankCode: null;
  upiId: null;
  narration: string;
  source: string;
  isAllocated: boolean;
  teamMemberId: string;
  paymentRequest: any[];
  invoices: PaidInvoice[];
  statusCode: number;
  responseStatus: string;
}

export interface PaidInvoice {
  id: string;
  createdDate: Date;
  updatedDate: Date;
  referenceInvoiceNumber: string;
  supTyp: string;
  invoiceDate: Date;
  dueDate: Date;
  invoiceAmount: number;
  paidAmount: number;
  irn: string;
  customerName: string;
  docType: string;
  gstin: null;
  docNumber: string;
  status: string;
  invoiceStatus: string;
  narration: null;
  workspaceId: string;
  inviteId: string;
  docs: null;
  info: null;
  url: null;
  linkedWorkspacesId: string;
  locationId: null;
}

export interface PaymentsInvoicesEntity {
  id: string;
  dueDate: string;
  invoiceNumber: string;
  amount: number;
  amountPaid: number;
  amountSettled: number;
  amountPending: number;
  isSelected: boolean;
}

export interface SelectedTeamMemberForReceivePayments {
  teamMemberId: string;
  teamMemberName: string;
  selectedReceiveDate: string;
  cashierId: string;
  cashierName: string;
}

export interface EditedDetails {
  sellerWorkspaceId: string;
  cashierTeamMemberId: string;
  paymentTransactionsNarration: PaymentTransactionsNarration[];
  totalReceivingAmount: number;
  totalAmountByCash: number;
  totalAmountByCheque: number;
  totalAmountByUPI: number;
  narration: string;
  paymentReceivedId?: string;
  collectionAgentTeamMemberId?: string;
}

export interface PaymentTransactionsNarration {
  paymentTransactionId: string;
  narration: string;
}

export interface TeamMemberPaymentsTransactionsEntity {
  id: string;
  amountPaid?: number;
  narration: string;
  invoices?: InvoicesRecords[];
  paymentDate: string;
  amount?: number;
  paymentMode: string;
  paymentRefCode: string;
  customerName: string;
  invoiceNumber?: string[];
  noOfInvoices?: number;
  isSelected?: boolean;
}

export interface InvoicesRecords {
  id: string;
  docNumber?: string;
}

export interface GraphRequest {
  startDate: string;
  endDate: string;
  workspaceId: string;
}

interface PaymentModeGraphData {
  countTransactions: number;
  valueTransactions: number;
  paymentMode: string;
}
export type PaymentModeGraphResponse = Array<PaymentModeGraphData>;

export interface CustomerTransactionsGraphData {
  xAxis: string;
  fromDate: string;
  toDate: string;
  countTransactions: number;
  valueTransactions: number;
}

export type CustomerTransactionsGraphResponse =
  Array<CustomerTransactionsGraphData>;

export interface PaymentPreferencesResponse {
  linkSetting: PaymentPreferences;
  statusCode: number;
  responseStatus: string;
  message?: string;
}

interface PaymentPreferences {
  sellerWorkspaceId?: string;
  expiry?: number;
  upi: boolean;
  netBanking: boolean;
  wallet: boolean;
  creditCard: boolean;
  debitCard: boolean;
}

export interface AmountCollectedBySalesManRes {
  AmountCollected: number;
  statusCode: number;
  responseStatus: string;
}

export interface AmountCollectedBySalesManReq {
  startDate: string;
  endDate: string;
  customerId: string;
}

export interface CreatePaymentTransactionRequest {
  sellerWorkspaceId: string;
  buyerWorkspaceId?: string;
  inviteId: string;
  paymentDate?: string;
  amountPaid?: number;
  allocatedAmount?: number;
  currencyCode?: string;
  paymentType?: string;
  paymentMode?: string;
  paymentProvider?: string;
  proofOfPayment?: string;
  approvedBy?: string;
  createdBy?: string;
  paymentRefCode: string;
  bankName?: string;
  bankAccountNumber?: string;
  bankReference?: string;
  bankCode?: string;
  upiId?: string;
  paymentTransactionId?: string;
  isConfirmed?: boolean;
  instrumentNumber?: string;
  docType: string;
}

export interface CreatePaymentTransactionResponse {
  id: string;
  createdDate: string;
  updatedDate: string;
  sellerWorkspaceId: string;
  buyerWorkspaceId: string;
  inviteId: string;
  paymentDate: string;
  amountPaid: number;
  allocatedAmount: number;
  currencyCode: string;
  status: string;
  subStatus: string | null;
  paymentType: string;
  paymentMode: string;
  paymentProvider: string | null;
  proofOfPayment: string | null;
  approvedBy: string | null;
  createdBy: string | null;
  paymentRefCode: string;
  instrumentNumber: string | null;
  instrumentDate: string | null;
  bankName: string | null;
  bankAccountNumber: string;
  bankReference: string;
  channel: string | null;
  cardNumber: string | null;
  cardNetwork: string | null;
  cardType: string | null;
  bankCode: string | null;
  upiId: string | null;
  referenceTransactionNumber: string | null;
  narration: string | null;
  erpOrderNumber: string | null;
  source: string;
  isAllocated: boolean;
  teamMemberId: string | null;
  cfaId: string | null;
  divisionId: string | null;
  clearingDate: string | null;
  statusCode: number;
  responseStatus: string;
}
