import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CheckinLocation, ClockInHistory } from './modal';
import { salesApi } from './api';
import { RootState } from '../root';
import moment from 'moment';
import { cloneDeep } from 'lodash';

export const SALES_SLICE_KEY = 'Sales';
export interface SalesState {
  todayClockInHistory: ClockInHistory;
  checkinLocation: CheckinLocation | null;
}

export const initialSalesState: SalesState = {
  todayClockInHistory: {
    lastClockIn: null,
    clockIn: null,
    clockOut: undefined,
    totalWorkingHours: {
      hours: 0,
      minutes: 0,
    },
  },
  checkinLocation: null,
};

export const salesSlice = createSlice({
  name: SALES_SLICE_KEY,
  initialState: initialSalesState,
  reducers: {
    updateTodayClockInHistory(state, action: PayloadAction<ClockInHistory>) {
      state.todayClockInHistory = action.payload;
    },
    updateCheckinLocation(state, action: PayloadAction<CheckinLocation>) {
      state.checkinLocation = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      salesApi.endpoints.clockInHistory.matchFulfilled,
      (state, action) => {
        const foundTodayHistory = action.payload.records.find(
          (history) => history?.date === moment().format('YYYY-MM-DD')
        );

        if (foundTodayHistory) {
          const clonedTodayHistory = cloneDeep(foundTodayHistory);
          const clockInSorted = clonedTodayHistory?.dayActivity?.sort((a, b) =>
            moment(b.clockIn, 'hh:mm:ss').diff(moment(a.clockIn, 'hh:mm:ss'))
          );
          // agar h to take first one;
          const clockIn = clockInSorted?.[0]?.clockIn;
          const lastClockIn = clockInSorted?.at(-1)?.clockIn || null;
          // agar h to take last one,
          const clockOut = clonedTodayHistory.dayActivity.some(
            (a) => !a.clockOut
          )
            ? null
            : clockInSorted?.[0]?.clockOut;

          const totalWorkingHours = clonedTodayHistory?.totalWorkingHours || {
            hours: 0,
            minutes: 0,
          };

          const payload: ClockInHistory = {
            lastClockIn,
            clockIn,
            clockOut,
            totalWorkingHours,
          };

          const updatedAction = {
            ...action,
            payload,
          };
          salesSlice.caseReducers.updateTodayClockInHistory(
            state,
            updatedAction
          );
        }
      }
    );
  },
});

export const salesReducer = salesSlice.reducer;

export const { updateTodayClockInHistory, updateCheckinLocation } =
  salesSlice.actions;

export const getSalesSliceState = (rootState: RootState): SalesState =>
  rootState[SALES_SLICE_KEY];

export const getTodayClockInHistory = createSelector(
  getSalesSliceState,
  (state) => state.todayClockInHistory
);

export const getCheckinLocation = createSelector(
  getSalesSliceState,
  (state) => state.checkinLocation
);
