import React, { useContext, useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { SHARED_SVGS, ThemeContext } from '@zonofi/shared';
import dayjs from 'dayjs';
import './zo-date-picker.scss';

interface  DateTimePickerProps {
  title?: string;
  label: string;
  isRequired?: boolean;
  placeholder?: string;
  onChange: (val: any) => void;
  value: string | dayjs.Dayjs | null;
  disabled?: boolean;
  disablePast?: boolean;
  error?: boolean;
  minDate?: any;
  startAdornment?: React.JSX.Element;
  endAdornment?: React.JSX.Element;
  dateFeildStyleProps?: React.CSSProperties;
  inputFieldProps?: React.CSSProperties;
}

export const ZoDateTimePicker = (props: DateTimePickerProps) => {
  const {
    label,
    title,
    isRequired = false,
    onChange,
    placeholder,
    value,
    disabled = false,
    disablePast = false,
    error = false,
    minDate,
    startAdornment,
    endAdornment,
    dateFeildStyleProps,
    inputFieldProps,
  } = props;
  const [open, setOpen] = useState(false);

  const currentTheme = useContext(ThemeContext);
  const { theme } = currentTheme;
  const [isFocused, setIsFocused] = useState(false);

  const inputFeildStyleContainer = {
    ...theme.fontConfig.regular,
    fontSize: '14px',
    color: `${value ? '#091E42' : '#8f8f8f'}`,
    caretColor: 'transparent',
    cursor: 'pointer',
    ...inputFieldProps,
  };

  const handleClearDate = () => {
    onChange(null);
    setOpen(false);
  };

  const CustomActionBar = ({ onAccept, onCancel }: any) => (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        padding: '8px 16px',
        borderTop: '1px solid #e0e0e0',
      }}
    >
      <div
        onClick={handleClearDate}
        style={{
          color: theme.secondary,
          fontSize: 14,
          textAlign: 'center',
          textDecorationLine: 'underline',
          ...theme.fontConfig.semiBold,
          cursor: 'pointer',
          lineHeight: '30px',
        }}
      >
        Clear
      </div>

      <div>
        <button
          onClick={onCancel}
          style={{
            marginRight: 12,
            width: 62,
            height: 32,
            borderRadius: 6,
            fontSize: 14,
            background: 'none',
            border: 'none',
            ...theme.fontConfig.semiBold,
            cursor: 'pointer',
            color: '#82807f',
          }}
        >
          Cancel
        </button>
        <button
          onClick={onAccept}
          style={{
            background: '#32A7E8',
            border: 'none',
            color: '#fff',
            cursor: 'pointer',
            fontSize: '14px',
            width: 62,
            height: 32,
            borderRadius: 3,
            ...theme.fontConfig.semiBold,
          }}
        >
          Done
        </button>
      </div>
    </div>
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className="zoDatePicker font-regular" style={{ width: '100%' }}>
        {title && (
          <span
          className={`input_text__title ${
            error ? 'input_text__error' : isFocused ? 'input_text__focused' : ''
          }`}
        >
          {title}
          {isRequired  && <span className="input_text__asterisk">*</span>}
        </span>
        )}

        <DateTimePicker
          label={label}
          format="DD-MM-YYYY HH:mm"
          disabled={disabled}
          disablePast={disablePast}
          minDate={minDate}
          open={!disabled && open}
          onClose={() => setOpen(false)}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: isFocused ? '#32a7e8' : '#dcdcdc',
                borderWidth: '1px',
                borderRadius: '6px',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#32a7e8',
                borderWidth: '1px',
                borderRadius: '6px',
              },
            },
          }}
          onChange={(value) => onChange(value)}
          value={value ? dayjs(value) : null}
          slotProps={{
            textField: {
              onClick: () => setOpen(true),
              placeholder,
              disabled,
              required: isRequired,
              InputLabelProps: {
                style: {
                  ...theme.fontConfig.regular,
                  color: `${value || open ? '#091E42' : '#BFC0C2'}`,
                  ...(value || open ? {} : { marginTop: -10 }),
                },
              },
              inputProps: {
                sx: {
                  '&::placeholder': {
                    color: `${open ? '#091E42' : '#BFC0C2'}`,
                    opacity: 1,
                    fontSize: '14px',
                    fontFamily: 'Segoe-UI',
                  },
                },
                style: inputFeildStyleContainer,
              },
              InputProps: {
                startAdornment,
                endAdornment,
                error,
                sx: {
                  height: '38px',
                  ...dateFeildStyleProps,
                },
              },
            },
          }}
        />
      </div>
    </LocalizationProvider>
  );
};
