import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  GetWorkspaceRolesResponse,
  MappedWorskpaceForSupportResponse,
  WorkspaceRoleEntry,
} from '../auth';
import { RootState } from '../root';
import { superAdminApi } from './api';

export const SUPER_ADMIN_FEATURE_KEY = 'supeAdmin';

export interface SuperAdminState {
  rolesList: GetWorkspaceRolesResponse;
  selectedSupportUser: MappedWorskpaceForSupportResponse | null;
}

export const initialSuperAdminState: SuperAdminState = {
  rolesList: { roles: [] },
  selectedSupportUser: null,
};

export const superAdminSlice = createSlice({
  name: SUPER_ADMIN_FEATURE_KEY,
  initialState: initialSuperAdminState,
  reducers: {
    onGetWorkspaceRolesSuccess(
      state,
      action: PayloadAction<GetWorkspaceRolesResponse>
    ) {
      state.rolesList = action?.payload;
    },
    updateWorkspaceRoles(
      state,
      action: PayloadAction<GetWorkspaceRolesResponse>
    ) {
      state.rolesList = action?.payload;
    },
    updateSelectedSupportUser(
      state,
      action: PayloadAction<MappedWorskpaceForSupportResponse>
    ) {
      state.selectedSupportUser = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      superAdminApi.endpoints.getWorkspaceRolesList.matchFulfilled,
      (state, action) => {
        superAdminSlice.caseReducers.onGetWorkspaceRolesSuccess(state, action);
      }
    );
  },
});

export const superAdminReducer = superAdminSlice.reducer;

export const { updateWorkspaceRoles, updateSelectedSupportUser } =
  superAdminSlice.actions;

export const getSuperAdminState = (rootState: RootState): SuperAdminState =>
  rootState[SUPER_ADMIN_FEATURE_KEY];

export const getRolesList = createSelector(
  getSuperAdminState,
  (state) => state?.rolesList
);

export const getSelectedSupportUser = createSelector(
  getSuperAdminState,
  (state) => state.selectedSupportUser
);
