import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { RootState } from '../root';
import { inviteApi } from './api';
import {
  PrincipalInvite,
  TeamInvite,
  PrincipalInviteObject,
  CustomerInviteResponse,
  ChannelMemberInvite,
} from './model';

export const INVITE_FEATURE_KEY = 'inviteData';

export interface InviteState {
  teamInvites: TeamInvite[];
  principalInvite: PrincipalInvite[];
  sentPrincipalInvites: PrincipalInviteObject[];
  customerInviteData?: CustomerInviteResponse;
  channelMemberInvite?: ChannelMemberInvite;
}

export const initialInviteState: InviteState = {
  teamInvites: [],
  principalInvite: [],
  sentPrincipalInvites: [],
  channelMemberInvite: {
    channelId: '',
    principalWorkspaceId: '',
  },
};

export const inviteSlice = createSlice({
  name: INVITE_FEATURE_KEY,
  initialState: initialInviteState,
  reducers: {
    updateTeamInvites(state, action: PayloadAction<TeamInvite[]>) {
      state.teamInvites = action.payload;
    },
    updatePrincipalInvite(state, action: PayloadAction<PrincipalInvite[]>) {
      state.principalInvite = action.payload;
    },
    addPrincipalInvite(state, action: PayloadAction<PrincipalInviteObject>) {
      state.sentPrincipalInvites.push(action.payload);
    },
    updateCustomerInviteDetails(
      state,
      action: PayloadAction<CustomerInviteResponse>
    ) {
      state.customerInviteData = action.payload;
    },
    updateChannelMemberInvite(
      state,
      action: PayloadAction<ChannelMemberInvite>
    ) {
      state.channelMemberInvite = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      inviteApi.endpoints.getTeamInvite.matchFulfilled,
      (state, action) =>
        inviteSlice.caseReducers.updateTeamInvites(state, action)
    );
    // builder.addMatcher(
    //   inviteApi.endpoints.getPrincipalInvite.matchFulfilled,
    //   (state, action) =>
    //     inviteSlice.caseReducers.updatePrincipalInvite(state, action)
    // );
    builder.addMatcher(
      inviteApi.endpoints.sendPrincipalInvite.matchFulfilled,
      (state, action) =>
        inviteSlice.caseReducers.addPrincipalInvite(state, action)
    );
    builder.addMatcher(
      inviteApi.endpoints.getInviteByToken.matchFulfilled,
      (state, action) =>
        inviteSlice.caseReducers.updateCustomerInviteDetails(state, action)
    );
    builder.addMatcher(
      inviteApi.endpoints.getInviteById.matchFulfilled,
      (state, action) =>
        inviteSlice.caseReducers.updateCustomerInviteDetails(state, action)
    );
  },
});

export const inviteReducer = inviteSlice.reducer;

export const {
  updateTeamInvites,
  updateCustomerInviteDetails,
  updateChannelMemberInvite,
  updatePrincipalInvite,
} = inviteSlice.actions;

export const getInviteSliceState = (rootState: RootState): InviteState =>
  rootState[INVITE_FEATURE_KEY];

export const getHasTeamInvite = createSelector(
  getInviteSliceState,
  (state) => !_.isEmpty(state.teamInvites)
);

export const getTeamInvites = createSelector(
  getInviteSliceState,
  (state) => state.teamInvites
);

export const getPrincipalInvite = createSelector(
  getInviteSliceState,
  (state) => state.principalInvite
);

export const getPendingTeamInvites = createSelector(
  getInviteSliceState,
  (state) =>
    state.teamInvites.filter((i) => ['P', 'N'].includes(i.inviteStatus))
);

export const getSentPrincipalInvites = createSelector(
  getInviteSliceState,
  (state) => state.sentPrincipalInvites
);

export const getCustomerInvite = createSelector(
  getInviteSliceState,
  (state) => state.customerInviteData
);

export const getChannelMemberInvite = createSelector(
  getInviteSliceState,
  (state) => state.channelMemberInvite
);
