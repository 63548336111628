export enum MessageType {
  TEXT = 'text',
  MEDIA = 'media',
  FORWARD = 'forward',
  ISSUE = 'issue',
  ORDER = 'order',
  PRICE_LIST = 'priceList',
  INVOICE_LIST = 'invoiceList',
  SCHEME_LIST = 'schemeList',
  PAYMENT = 'payment',
  INVOICE = 'invoice',
  REQUIREMENT = 'requirement',
  CUSTOMER_NOT_FOUND = 'customerNotFound',
  WHATSAPP_TEMPLATE = 'waCustomTemplate',
  ZOEY_ORDER_REPORT = 'orderReport',
  ZOEY_INVOICE_REPORT = 'invoiceReport',
  LEDGER = 'ledger',
  CAMPAIGN = 'campaign',
  CHANNEL_UPDATE = 'CU',
  INTERACTIVE = 'interactive',
  DISPATCH = 'dispatch',
  TYPEBOT = 'typeBot',
}

export const ImageFileExtentions = [
  'svg',
  'gif',
  'jpeg',
  'jpg',
  'png',
  'webp',
  'bmp',
  'whatsapp-image',
  'sendPaymentRequestQR',
];

export enum PnGcmMessageType {
  TIMELINE = 'TMLN',
  TEXT_MESSAGE = '',
  CHANNEL_MEMBER_CREATED = 'CLMC',
  CHANNEL_MEMBER_ADDED = 'CLMA',
  CHANNEL_MEMBER_REMOVED = 'CLMR',
  CHANNEL_MEMBER_UPDATED = 'CLMU',
  WORK_SPACE_MEMBER_UPDATED = 'WSMU',
  WORK_SPACE_INVITE_SENT = 'WSIS',
  WORK_SPACE_MEMBER_REMOVED = 'WSMR',
  TEXT = 'TEXT',
}

export enum ChatActionType {
  // SEND_PRICES = 'Send Prices',
  ACCEPT_PAYMENT = 'ACCEPT PAYMENT',
  CREATE_DISPATCH_NOTES = 'Create Dispatch Note',
  SEND_PRICE_LIST = 'Send Price List',
  SEND_SCHEMES = 'Send Schemes',
  SEND_ORDERS = 'Send Orders',
  SEND_INVOICES = 'Send Invoices',
  SEND_LEDGER = 'Send Ledger',
  SEND_PAYMENT_REQUEST = 'Send Payment Request',
  REVIEW_PAYMENT_INVOICE = 'Review Invoices',
  CREATE_INVOICE = 'Upload Invoice',
  CREATE_INVOICE_ACTION = 'Upload Invoice Action',
  VIEW_ORDER = 'View Order',
  VIEW_PAYMENT = 'View Payment',
  VIEW_ACTIVITIES = 'View Activities',
  VIEW_CART = 'View Cart',
  DETAILS = 'Details',
  SUMMARY = 'Summary',
  DOWNLOAD_INVOICE = 'Download Invoice',
  VIEW_INVOICE = 'View Invoice',
  PAYMENT_RECEIVED = 'Payment Received',
  PAYMENT_REJECTED = 'Payment Rejected',
  PRODUCT_DETAILS = 'Product Details',
  ACCEPT_CUSTOMER = 'Accept and create Customer',
  SEND_TEMPLATE = 'Send Template',
  SEND_CAMPAIGNS = 'Send Campaigns',
  VIEW_CAMPAIGN = 'View Campaign',
  CREATE_ORDER = 'Create Order',
  UPLOAD_PAYMENT = 'Upload Payment',
  CREATE_PAYMENT = 'Create Payment',
  VIEW_ISSUE_THREAD = 'View Issue Thread',
  VIEW_DISPATCH_NOTE = 'View Dispatch Note',
  COLLECT_PAYMENT = 'Collect Payment',
  MESSAGE_INFO = 'Message Info',
  NONE = 'None',
}

export type MessageActionType =
  | 'reply'
  | 'delete'
  | 'forward'
  | 'issue'
  | 'download_invoice'
  | 'accept_payment'
  | 'reject_payment'
  | 'create_customer';
