import { ColumnConfig, CustomerProductColumnKey } from '../../model';

export const customerProductConfig: Record<
  CustomerProductColumnKey,
  ColumnConfig
> = {
  skuCodeSelection: {
    name: 'SKU Code',
    field: 'checkboxNavigate',
    isCheckboxField: true,
  },
  productTitle: { name: 'Product Title', field: 'multiLine' },
  categories: { name: 'Categories', field: 'component' },
  pts: { name: 'PTS', sortField: 'price', alignment: 'flex-end' },
  ptr: { name: 'PTR', alignment: 'flex-end', isVisible: true },
  currentStock: { name: 'Current Stock', alignment: 'flex-end' },
  cfaInventory: {
    name: 'CFA Inventory',
    alignment: 'flex-end',
    isVisible: false,
  },
  margin: { name: 'Margin', alignment: 'flex-end' },
  packSize: { name: 'Pack Size', alignment: 'flex-end' },
  scheme: { name: 'Scheme', isVisible: false },
  addToCart: {
    name: '',
    field: 'addToCart',
    permissions: ['order_write'],
  },
};
