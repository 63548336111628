import { FilterInputs } from '../model';

export const getSelectedFilterIds = (
  appliedFilters: FilterInputs,
  key: string
): string[] => {
  const listInputItem = appliedFilters?.listInputItem;
  const selectedFilters = listInputItem && Object.entries(listInputItem);
  const found = selectedFilters?.find(([k]) => k === key);
  return found
    ? found[1]?.filter((x) => x.selected)?.map((x) => x.id?.toString())
    : [];
};

export const getSelectedFilterValues = (
  appliedFilters: FilterInputs,
  key: string
) => {
  const listInputItem = appliedFilters?.listInputItem;
  const selectedFilters = listInputItem && Object.entries(listInputItem);
  const found = selectedFilters?.find(([k]) => k === key);
  return found ? found[1]?.filter((x) => x.selected)?.map((x) => x.value) : [];
};

export const getSelectedTreeFilterValues = (
  appliedFilters: FilterInputs,
  key: string
) => {
  const treeInputItem = appliedFilters?.treeInputItem;
  const selectedFilters = treeInputItem && Object.entries(treeInputItem);
  const found = selectedFilters?.find(([k]) => k === key);

  // Helper function for recursive traversal
  const getSelectedIds = (data: any[]) => {
    let selectedIds: string[] = [];
    data?.forEach((item) => {
      if (item.selected) {
        selectedIds.push(item.id);
      }
      if (item.TreeData && item.TreeData.length > 0) {
        selectedIds = selectedIds.concat(getSelectedIds(item.TreeData));
      }
    });
    return selectedIds;
  };

  return found ? getSelectedIds(found[1]) : [];
};
