import { ColumnConfig, ZoeyOrderListColumnKey } from '../../model';

export const zoeyOrderListConfig: Record<ZoeyOrderListColumnKey, ColumnConfig> =
  {
    orderNo: {
      name: 'Order No. & Date',
      field: 'checkboxText',
      isCheckboxField: true,
    },
    status: {
      name: 'Status',
    },
    skuCount: {
      name: 'SKU Count',
    },
    customerName: {
      name: 'Order By',
      field: 'avatarNameStatus',
    },
    netOrderValue: {
      name: 'Order Amount',
    },
    action: {
      name: '',
    },
  };
