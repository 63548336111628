import { Api } from '../api';

export const sessionApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    sendFcmToServer: builder.mutation<any, any>({
      query(arg) {
        return {
          url: 'pntoken',
          method: 'POST',
          body: arg,
        };
      },
    }),
    sendLoginInfoToServer: builder.mutation<any, any>({
      query(arg) {
        return {
          url: 'addlog',
          method: 'POST',
          body: arg,
        };
      },
    }),
  }),
});

export const { useSendFcmToServerMutation, useSendLoginInfoToServerMutation } =
  sessionApi;
