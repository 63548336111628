import { Api } from '../api/root-api';
import {
  CreateOrderRequest,
  CreateOrderResponse,
  addItemsToOrderResponse,
  addItemsToOrderRequest,
  ListOrderResponse,
  ListOrderRequest,
  FulfillmentLocationResponse,
  CustomerMetadataResponse,
  CustomerMetadataRequest,
  getProductsDetailsResponse,
  getProductsDetailsRequest,
  LatestOrderDetailsResponse,
  LatestOrderDetailsRequest,
  AddItemToActiveOrderRequest,
  ActiveOrder,
  AddItemToActiveOrderResponse,
  UploadOrderFileRequest,
  UploadOrderFileResponse,
  PoListOrderRequest,
  PoListOrderResponse,
  OrderCheckoutRequest,
  CheckoutOrder,
  ManualProductMappingResponse,
  ManualProductMappingRequest,
  PoOrderDetailsResponse,
  DeletePoFileRequest,
  DeletePoFileResponse,
  DownloadOrderInvoiceRequest,
  TrackPODetailsRequest,
  DownloadOrderRequest,
  CreateTicketRequest,
  CreateTicketResponse,
  DeleteLinesRequest,
  cartSummary,
  OrderSchemeSummaryRepsonse,
  OrderSchemeSummaryRequest,
  UpdateOrderRequest,
  UpdateOrderResponse,
  SuggestedProducts,
  OrderLogsRequest,
  OrderLogsResponse,
  OrderLogsTypes,
  UpdatePoFileRequest,
  UpdatePoFileResponse,
  Audit,
  UpdatePOStatusRequest,
  NotifyToBuyerRequest,
  OrderFilterResponse,
  OrderFilterRequest,
  TagListRes,
  CancelOrderResponse,
  CancelOrderRequest,
  GetCartRequest,
} from './model';
import {
  addListInputItems,
  ListInputItemOptions,
  mapDataToInputItems,
  mapDataToInputItemsFromAppliedFilter,
} from '../filter';
import { updateCurrentPoFile, updatePOFile, updatePOFileLine } from './slice';
import moment from 'moment';
import _ from 'lodash';
import { store } from '../root/store';
import { Platform } from 'react-native';

export const ordersApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    createOrUpdateOrder: builder.mutation<
      CreateOrderResponse,
      { body: CreateOrderRequest; workspaceId: string }
    >({
      query: (args) => ({
        url: `hub/commerce-v2/orders/${args.workspaceId}?sellerWorkspaceId=${args.workspaceId}`,
        method: 'POST',
        body: args.body,
      }),
    }),
    addItemsToOrder: builder.mutation<
      addItemsToOrderResponse,
      { body: addItemsToOrderRequest; workspaceId: string }
    >({
      query: (args) => ({
        url: `hub/commerce-v2/orders/additemtoorder/${args.workspaceId}?sellerWorkspaceId=${args.workspaceId}`,
        method: 'POST',
        body: args.body,
      }),
    }),
    getFulfillmentLocations: builder.query<
      FulfillmentLocationResponse,
      { principalWorkspaceId: string; inviteId?: string }
    >({
      query: (args) => ({
        url: `fulfillment-locations/${args.principalWorkspaceId}?inviteId=${args?.inviteId}`,
        method: 'GET',
      }),
    }),
    listOrders: builder.mutation<ListOrderResponse, ListOrderRequest>({
      query: (args) => ({
        url: `hub/commerce-v2/orders?sellerWorkspaceId=${args.params.workspaceId}`,
        method: 'POST',
        body: args.body,
        params: args.params,
      }),
    }),
    ordersFilters: builder.mutation<OrderFilterResponse, OrderFilterRequest>({
      query: (args) => ({
        url: `hub/commerce-v2/order-filter?sellerWorkspaceId=${args.workspaceId}`,
        method: 'POST',
        body: args,
      }),
      async onQueryStarted(request, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then((response) => {
            const appliedFilters =
              store.getState()?.filtersData?.appliedFilters;

            const cfaInputItems = mapDataToInputItemsFromAppliedFilter(
              response?.data?.fulfillments,
              'cfaIds',
              'cfaName',
              ListInputItemOptions.cfa
            )?.filter((x: any) => x.value);
            dispatch(
              addListInputItems({
                inputItems: cfaInputItems,
                key: ListInputItemOptions.cfa,
              })
            );

            const tagInputItems = mapDataToInputItems(
              response?.data?.tags?.map((tag) => {
                return { ...tag, tagId: tag?.tagId?.toString() };
              }),
              'tagId',
              'tagName',
              ListInputItemOptions.Ztag
            )?.filter((x: any) => x.value);
            dispatch(
              addListInputItems({
                inputItems: tagInputItems,
                key: ListInputItemOptions.Ztag,
              })
            );

            const divisionInputItems = mapDataToInputItemsFromAppliedFilter(
              response?.data?.divisions,
              'divisionId',
              'divisionName',
              ListInputItemOptions.divisions
            )?.filter((x: any) => x.value);
            dispatch(
              addListInputItems({
                inputItems: divisionInputItems,
                key: ListInputItemOptions.divisions,
              })
            );

            const headDivisionInputItems = mapDataToInputItemsFromAppliedFilter(
              response?.data?.headDivisions,
              'headDivisionId',
              'headDivisionName',
              ListInputItemOptions.headDivision
            )?.filter((x: any) => x.value);
            dispatch(
              addListInputItems({
                inputItems: headDivisionInputItems,
                key: ListInputItemOptions.headDivision,
              })
            );

            const customerInputItems = mapDataToInputItemsFromAppliedFilter(
              response?.data?.customers,
              'customerId',
              'customerName',
              ListInputItemOptions.customers
            )?.filter((x: any) => x.value);
            dispatch(
              addListInputItems({
                inputItems: customerInputItems,
                key: ListInputItemOptions.customers,
              })
            );

            const getStatus = (status: string) => {
              switch (status) {
                case 'SubmittedByCustomer':
                  return 'Submitted';
                case 'Approved':
                  return 'Accepted';
                case 'WaitingForCNF':
                  return 'Waiting For CNF';
                case 'AddingItems':
                  return 'Draft';
                default:
                  return status;
              }
            };

            const statuses = response.data.status;
            const statusInputItems = () => {
              return statuses
                .map((value) => {
                  let selected = false;

                  if (
                    appliedFilters?.listInputItem &&
                    appliedFilters?.listInputItem[
                      ListInputItemOptions.orderStatus
                    ] &&
                    appliedFilters?.listInputItem[
                      ListInputItemOptions.orderStatus
                    ]?.length
                  ) {
                    const optionsArray = appliedFilters?.listInputItem
                      ? appliedFilters?.listInputItem[
                          ListInputItemOptions.orderStatus
                        ]
                      : [];
                    optionsArray?.forEach((option) => {
                      if (option?.id === value?.status && option?.selected) {
                        selected = true;
                      }
                    });
                  }

                  return {
                    id: value?.status,
                    value: getStatus(value?.status),
                    selected: selected,
                  };
                })
                .filter(
                  (x) => !(x.id === 'Submitted' || x.id === 'ORDER_SUBMITTED')
                );
            };
            dispatch(
              addListInputItems({
                inputItems: statusInputItems(),
                key: ListInputItemOptions.orderStatus,
              })
            );
          })
          .catch((error) => console.log(error));
      },
    }),
    listPoOrders: builder.mutation<
      PoListOrderResponse,
      {
        body: PoListOrderRequest;
        workspaceId: string;
        dispatchFilters?: boolean;
      }
    >({
      query: (args) => ({
        url: `hub/commerce-v2/trackPoFiles/${args.workspaceId}?sellerWorkspaceId=${args.workspaceId}`,
        method: 'POST',
        body: args.body,
      }),
      async onQueryStarted(request, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then((response) => {
            if (request?.dispatchFilters) {
              const divisionInputItems = mapDataToInputItems(
                response?.data?.summary?.divisions,
                'id',
                'name'
              )?.filter((i: any) => i?.id && i?.value);
              dispatch(
                addListInputItems({
                  inputItems: divisionInputItems,
                  key: ListInputItemOptions.divisions,
                })
              );

              const cfaInputItems = mapDataToInputItems(
                response?.data?.summary?.cfas,
                'fullFillmentLocationId',
                'cfaName'
              );
              dispatch(
                addListInputItems({
                  inputItems: cfaInputItems,
                  key: ListInputItemOptions.cfa,
                })
              );

              const getStatus = (status: string) => {
                switch (status) {
                  case 'SubmittedByCustomer':
                    return 'Submitted';
                  case 'Approved':
                    return 'Acknowledged by CFA';
                  case 'WaitingForCNF':
                    return 'Waiting For CNF';
                  case 'AddingItems':
                    return 'In-Cart';
                  case 'Mapped':
                  case 'Mapping':
                    return 'Draft';
                  default:
                    return status;
                }
              };

              const statusInputItems =
                response?.data?.summary?.orderStates
                  ?.split(',')
                  .map((status) => ({
                    id: status,
                    value: getStatus(status),
                    selected: false,
                  })) || [];
              dispatch(
                addListInputItems({
                  inputItems: statusInputItems,
                  key: ListInputItemOptions.orderStatus,
                })
              );
            }
          })
          .catch((error) => console.log(error));
      },
    }),
    listCart: builder.query<
      PoListOrderResponse,
      { body: PoListOrderRequest; workspaceId: string }
    >({
      query: (args) => ({
        url: `hub/commerce-v2/poFiles/${args.workspaceId}?sellerWorkspaceId=${args.workspaceId}`,
        method: 'GET',
        params: { ...args.body, random: Math.random() },
      }),
      providesTags: ['ActiveOrder'],

      async onQueryStarted(request, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then(() => {
            dispatch(
              ordersApi.endpoints.cartSummary.initiate({
                sellerWorkspaceId: request.workspaceId,
                customerId: request.body.customerId || '',
              })
            );
          })
          .catch((x) => {
            console.error(x);
          });
      },
    }),
    getOrderDetail: builder.query<
      CreateOrderResponse,
      { workspaceId: string; orderId: number }
    >({
      query: (args) => ({
        url: `hub/commerce-v2/orders/${args.workspaceId}/${args.orderId}?includeInvoice=true&includePromotionData=true&sellerWorkspaceId=${args.workspaceId}`,
        method: 'GET',
      }),
    }),
    getOrderDetails: builder.mutation<
      CreateOrderResponse,
      {
        workspaceId: string;
        orderId: number;
        dispatchFilters: boolean;
        body: any;
      }
    >({
      query: (args) => ({
        url: `hub/commerce-v2/orders/details/${args.workspaceId}/${args.orderId}?includeInvoice=true&sellerWorkspaceId=${args.workspaceId}`,
        method: 'POST',
        body: { ...args.body, includePromotionData: true },
      }),
      async onQueryStarted(request, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then((response) => {
            if (request?.dispatchFilters) {
              const divisionInputItems = mapDataToInputItems(
                response?.data?.divisions,
                'divisionId',
                'divisionName'
              )?.filter((x: any) => x.value);
              dispatch(
                addListInputItems({
                  inputItems: divisionInputItems,
                  key: ListInputItemOptions.divisions,
                })
              );
            }
          })
          .catch((error) => console.log(error));
      },
    }),
    getCustomerMetadata: builder.query<
      CustomerMetadataResponse,
      { workspaceId: string; params: CustomerMetadataRequest }
    >({
      query(args) {
        return {
          url: `commerce/customer/metadata/${args.workspaceId}?sellerWorkspaceId=${args.workspaceId}`,
          method: 'GET',
          params: args.params,
        };
      },
    }),
    getProductsDetails: builder.mutation<
      getProductsDetailsResponse[],
      { body: getProductsDetailsRequest; workspaceId: string }
    >({
      query: (args) => ({
        url: `hub/commerce-v2/productVariant/${args.workspaceId}?sellerWorkspaceId=${args.workspaceId}`,
        method: 'POST',
        body: args.body,
      }),
    }),
    getActiveOrder: builder.query<
      ActiveOrder,
      { sellerWorkspaceId: string; customerInviteId: string }
    >({
      query: (args) => ({
        url: `hub/commerce-v2/orders/active/order/${args.sellerWorkspaceId}?customerId=${args.customerInviteId}&sellerWorkspaceId=${args.sellerWorkspaceId}`,
        method: 'GET',
      }),
      providesTags: ['ActiveOrder'],
    }),
    updatePoFile: builder.mutation<
      UpdatePoFileResponse,
      {
        inviteId: string;
        body: UpdatePoFileRequest;
        fetchPoDetails: boolean;
      }
    >({
      query: (args) => {
        return {
          url: `hub/commerce-v2/poFile/${args.inviteId}?sellerWorkspaceId=${args.body.workpsaceId}`,
          method: 'PUT',
          body: args.body,
        };
      },
      async onQueryStarted(request, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then(() => {
            if (request?.fetchPoDetails) {
              dispatch(
                ordersApi.endpoints.getTrackPODetails.initiate({
                  workspaceId: request.body.workpsaceId ?? '',
                  body: {
                    pofileId: request.body.poFileId ?? '',
                    includeInCartItemsOnly: true,
                    includePromotion: true,
                  },
                })
              )
                .unwrap()
                .then((response) => {
                  if (response) {
                    dispatch(updatePOFile({ response }));
                  }
                });
              dispatch(
                ordersApi.endpoints.cartSummary.initiate({
                  sellerWorkspaceId: request.body.workpsaceId,
                  customerId: request.body.customerId,
                })
              );
            }
          })
          .catch((x) => {
            console.error(x);
          });
      },
    }),
    deletePoFile: builder.mutation<DeletePoFileResponse, DeletePoFileRequest>({
      query: (args) => {
        const { workspaceId, poFileImportSource, ...otherParams } = args;
        return {
          url: `hub/commerce-v2/poFile/${workspaceId}?sellerWorkspaceId=${args.workspaceId}`,
          method: 'DELETE',
          body: otherParams,
        };
      },
      invalidatesTags: ['ActiveOrder'],
      async onQueryStarted(request, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then(() => {
            dispatch(
              ordersApi.endpoints.listCart.initiate({
                body: {
                  customerId: request.customerId,
                  includeActiveOrders: true,
                  includeSummary: true,
                },
                workspaceId: request?.workspaceId,
              })
            )
              .unwrap()
              .then(() => {
                //  for orders other than manual
                if (
                  request?.poFileImportSource &&
                  request.poFileImportSource !== 'manual'
                ) {
                  dispatch(
                    ordersApi.endpoints.getTrackPODetails.initiate({
                      workspaceId: request.workspaceId ?? '',
                      body: {
                        pofileId: request.poFileId ?? '',
                        includeInCartItemsOnly: true,
                        includePromotion: true,
                      },
                    })
                  )
                    .unwrap()
                    .then((response) => {
                      if (response) {
                        dispatch(updatePOFile({ response }));
                      }
                    });
                }
              });
            //dispatch(closeCartModal());
          })
          .catch((x) => {
            console.error(x);
          });
      },
    }),
    UpdateOrder: builder.mutation<
      CreateOrderResponse,
      { body: CreateOrderRequest[]; workspaceId: string }
    >({
      query: (args) => ({
        url: `hub/commerce-v2/orders/${args.workspaceId}?sellerWorkspaceId=${args.workspaceId}`,
        method: 'POST',
        body: args.body,
      }),
      invalidatesTags: ['ActiveOrder'],
    }),
    UpdateActiveOrderItem: builder.mutation<
      AddItemToActiveOrderResponse,
      {
        body: AddItemToActiveOrderRequest;
        sellerWorkspaceId: string;
        isDispatchPOFile?: boolean;
        isDispatchPODetail?: boolean;
      }
    >({
      query: (args) => ({
        url: `hub/commerce-v2/orders/additemtoactiveorder/${args.sellerWorkspaceId}?sellerWorkspaceId=${args.sellerWorkspaceId}`,
        method: 'POST',
        body: args.body,
      }),
      invalidatesTags: ['CartSummary'],
      async onQueryStarted(request, { dispatch, queryFulfilled }) {
        queryFulfilled.then((response) => {
          if (response?.data) {
            // dispatch(
            //   ordersApi.endpoints.listCart.initiate({
            //     body: {
            //       customerId: request?.body?.customerId,
            //       includeActiveOrders: true,
            //       includeSummary: true,
            //     },
            //     workspaceId: request.sellerWorkspaceId,
            //   })
            // )

            //  for orders other than manual
            if (
              Platform.OS === 'web' &&
              request.body.source &&
              request.body.source !== 'manual' &&
              request.body.poFileId
            ) {
              dispatch(
                ordersApi.endpoints.getTrackPODetails.initiate({
                  workspaceId: request.sellerWorkspaceId ?? '',
                  body: {
                    pofileId: request.body.poFileId ?? '',
                    includeInCartItemsOnly: true,
                    includePromotion: true,
                  },
                })
              )
                .unwrap()
                .then((response) => {
                  if (response) {
                    dispatch(updatePOFile({ response }));
                    dispatch(updateCurrentPoFile(response));
                  }
                });
              dispatch(
                ordersApi.endpoints.cartSummary.initiate({
                  sellerWorkspaceId: request.sellerWorkspaceId,
                  customerId: request.body.customerId,
                })
              );
            } else if (request?.isDispatchPOFile) {
              if (Platform.OS !== 'web') {
                dispatch(
                  ordersApi.endpoints.getCart.initiate(
                    {
                      workspaceId: request.sellerWorkspaceId ?? '',
                      params: {
                        sellerWorkspaceId: request.sellerWorkspaceId ?? '',
                        customerId: request?.body.customerId ?? '',
                        includeActiveOrders: true,
                        includeSummary: true,
                      },
                    },
                    { forceRefetch: true }
                  )
                );
              } else
                dispatch(
                  ordersApi.endpoints.listCart.initiate(
                    {
                      workspaceId: request.sellerWorkspaceId ?? '',
                      body: {
                        customerId: request?.body.customerId ?? '',
                        includeActiveOrders: true,
                        includeSummary: true,
                      },
                    },
                    { forceRefetch: true }
                  )
                );
            } else {
              dispatch(
                updatePOFileLine({
                  response: response.data,
                  request: request.body,
                  variantId: request.body.lines.map((x) => x.productVariantId),
                })
              );
              dispatch(
                ordersApi.endpoints.cartSummary.initiate({
                  sellerWorkspaceId: request.sellerWorkspaceId,
                  customerId: request.body.customerId,
                })
              );
            }
          }
        });
      },
    }),

    deleteFromActiveOrder: builder.mutation<
      { error: string[]; msg: string },
      DeleteLinesRequest
    >({
      query(arg) {
        const { sellerWorkspaceId } = arg;
        return {
          url: `hub/commerce-v2/orders/deleteLines/${sellerWorkspaceId}?sellerWorkspaceId=${sellerWorkspaceId}`,
          method: 'POST',
          body: arg,
        };
      },
      invalidatesTags: ['ActiveOrder'],
      async onQueryStarted(request, { dispatch, queryFulfilled }) {
        queryFulfilled.then((response) => {
          if (response.data) {
            if (Platform.OS === 'web') {
              dispatch(
                ordersApi.endpoints.listCart.initiate({
                  body: {
                    customerId: request.customerId,
                    includeActiveOrders: true,
                    includeSummary: true,
                  },
                  workspaceId: request?.sellerWorkspaceId,
                })
              );
              if (request.importSource && request.importSource !== 'manual') {
                dispatch(
                  ordersApi.endpoints.getTrackPODetails.initiate({
                    workspaceId: request.sellerWorkspaceId ?? '',
                    body: {
                      pofileId: request.poFileId ?? '',
                      includeInCartItemsOnly: true,
                      includePromotion: true,
                    },
                  })
                )
                  .unwrap()
                  .then((response) => {
                    if (response) {
                      dispatch(
                        updatePOFile({
                          response: response,
                        })
                      );
                    }
                  });
              }
            } else {
              dispatch(
                ordersApi.endpoints.getCart.initiate(
                  {
                    params: {
                      sellerWorkspaceId: request?.sellerWorkspaceId,
                      customerId: request.customerId,
                      includeActiveOrders: true,
                      includeSummary: true,
                    },
                    workspaceId: request?.sellerWorkspaceId,
                  },
                  { forceRefetch: true }
                )
              );
            }
          }
        });
      },
    }),
    getLatestOrdersDetails: builder.query<
      LatestOrderDetailsResponse,
      LatestOrderDetailsRequest
    >({
      query(args) {
        return {
          url: `commerce/orders/view/latest`,
          method: 'GET',
          params: args,
        };
      },
    }),
    sumbitCartOrder: builder.mutation<
      AddItemToActiveOrderResponse,
      {
        customerId: string;
        sellerWorkspaceId: string;
      }
    >({
      query: (args) => ({
        url: `hub/commerce-v2/orders/checkout/${args.sellerWorkspaceId}?sellerWorkspaceId=${args.sellerWorkspaceId}`,
        method: 'POST',
        body: {
          customerId: args.customerId,
          sellerWorkspaceId: args.sellerWorkspaceId,
        },
      }),
      invalidatesTags: ['ActiveOrder'],
      async onQueryStarted(request, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then(() => {
            dispatch(
              ordersApi.endpoints.listCart.initiate({
                body: {
                  customerId: request.customerId,
                  includeActiveOrders: true,
                  includeSummary: true,
                },
                workspaceId: request?.sellerWorkspaceId,
              })
            );
            //dispatch(closeCartModal());
          })
          .catch((x) => {
            console.error(x);
          });
      },
    }),
    checkoutOrders: builder.mutation<
      { status: string; statusCode: number; orders: CheckoutOrder[] },
      OrderCheckoutRequest
    >({
      query(arg) {
        const { sellerWorkspaceId } = arg;
        return {
          url: `hub/commerce-v2/orders/checkout/${sellerWorkspaceId}?sellerWorkspaceId=${sellerWorkspaceId}`,
          method: 'POST',
          body: arg,
        };
      },
      invalidatesTags: ['ActiveOrder'],
      async onQueryStarted(request, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then(() => {
            dispatch(
              ordersApi.endpoints.listCart.initiate({
                body: {
                  customerId: request.customerId,
                  includeActiveOrders: true,
                  includeSummary: true,
                },
                workspaceId: request?.sellerWorkspaceId,
              })
            );
            // dispatch(toggleCartModal());
            // dispatch(closeExtendedCartModal());
            // dispatch(closeCartModal());
          })
          .catch((x) => {
            console.error(x);
          });
      },
    }),
    uploadOrderFile: builder.mutation<
      UploadOrderFileResponse[],
      {
        principalWorkspaceId: string;
        params: UploadOrderFileRequest;
        body: FormData;
      }
    >({
      query: (args) => ({
        url: `hub/commerce-v2/poFile/upload/${args.principalWorkspaceId}?sellerWorkspaceId=${args.principalWorkspaceId}`,
        method: 'POST',
        params: args.params,
        body: args.body,
      }),
      invalidatesTags: ['ActiveOrder'],
    }),
    manualProductMapping: builder.mutation<
      ManualProductMappingResponse,
      {
        inviteId: string;
        body: ManualProductMappingRequest;
      }
    >({
      query: (args) => ({
        url: `/hub/commerce-v2/poFile/map/${args.inviteId}?sellerWorkspaceId=${args.body.workspaceId}`,
        method: 'POST',
        body: args.body,
      }),
    }),
    createTicketForUnmapped: builder.mutation<
      CreateTicketResponse,
      {
        sellerWorkspaceId: string;
        body: CreateTicketRequest;
      }
    >({
      query: (args) => ({
        url: `/hub/commerce-v2/poFile/createMappingTicket/${args.sellerWorkspaceId}?sellerWorkspaceId=${args.sellerWorkspaceId}`,
        method: 'POST',
        body: args.body,
      }),
    }),
    getTrackPODetails: builder.mutation<
      PoOrderDetailsResponse,
      TrackPODetailsRequest
    >({
      query: (args) => ({
        url: `hub/commerce-v2/pofiles/details/${args.workspaceId}?includePromotionData=true&sellerWorkspaceId=${args.workspaceId}`,
        method: 'POST',
        body: {
          ...args.body,
          includePromotionData: true,
        },
      }),
      async onQueryStarted(request, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then((response) => {
            // adding filters option
            if (request?.dispatchFilters) {
              const divisionInputItems = mapDataToInputItems(
                response?.data?.divisions,
                'divisionId',
                'divisionName'
              );
              dispatch(
                addListInputItems({
                  inputItems: divisionInputItems,
                  key: ListInputItemOptions.divisions,
                })
              );

              const statusInputItems = Object.entries(
                response?.data?.allStatus ?? {}
              ).map(([value, id]) => ({
                id,
                value,
                selected: false,
              }));

              dispatch(
                addListInputItems({
                  inputItems: statusInputItems,
                  key: ListInputItemOptions.orderStatus,
                })
              );
              const inputItems = response?.data?.lines
                ?.filter((x) => x?.refOrderNumberL)
                ?.map((y) => y?.refOrderNumberL)
                ?.filter((item, index, array) => array.indexOf(item) === index);

              const orderNumberInputItems = inputItems?.map((value) => ({
                id: value || '',
                value: value || '',
                selected: false,
              }));
              if (!_.isEmpty(inputItems)) {
                dispatch(
                  addListInputItems({
                    inputItems: orderNumberInputItems,
                    key: ListInputItemOptions.orderNumber,
                  })
                );
              } else {
                dispatch(
                  addListInputItems({
                    inputItems: [],
                    key: ListInputItemOptions.orderNumber,
                  })
                );
              }
            }

            if (request.body.includeInCartItemsOnly) {
              dispatch(updateCurrentPoFile(response?.data));
            }
          })
          .catch((error) => console.log(error));
      },
    }),
    downloadPODetails: builder.query<
      { data: string; fileName: string },
      { pofileId: string; workspaceId: string; downloadContentType: string }
    >({
      query(args) {
        const { workspaceId, ...otherParams } = args;
        return {
          url: `poFile/download/${workspaceId}?sellerWorkspaceId=${args.workspaceId}`,
          method: 'GET',
          params: otherParams,
          responseHandler: async (response) => {
            const res = await response?.text();
            const data = res?.split('filename:');
            return { data: data[0], fileName: data[1] };
          },
        };
      },
    }),
    downloadPOFileDetails: builder.query<
      string,
      { poFileId: string; workspaceId: string }
    >({
      query(args) {
        const { workspaceId, ...otherParams } = args;
        return {
          url: `hub/commerce-v2/po-file/signed-url/${args.workspaceId}?sellerWorkspaceId=${args.workspaceId}`,
          method: 'GET',
          params: otherParams,
          responseHandler: (response) => response.text(),
        };
      },
    }),
    downloadOrderDetails: builder.query<
      string,
      { orderId: string; workspaceId: string; customerId: string; type: string }
    >({
      query(args) {
        return {
          url: `order/download/${args.workspaceId}?orderId=${args.orderId}&downloadContentType=PDF&customerId=${args.customerId}&sellerWorkspaceId=${args.workspaceId}&type=${args.type}`,
          method: 'GET',
          responseHandler: (response) => response.text(),
        };
      },
    }),
    downloadOrderInvoice: builder.mutation<
      string,
      { workspaceId: string; body: DownloadOrderInvoiceRequest }
    >({
      query(args) {
        return {
          url: `invoiceservice/invoices/download/${args.workspaceId}?sellerWorkspaceId=${args.workspaceId}`,
          method: 'POST',
          body: args.body,
          responseHandler: (response) => response.text(),
        };
      },
    }),
    downloadOrderData: builder.query<string, DownloadOrderRequest>({
      query(args) {
        const { workspaceId, ...otherParams } = args;
        return {
          url: `orders/download/${args.workspaceId}`,
          method: 'GET',
          params: otherParams,
          responseHandler: (response) => response.blob(),
        };
      },
    }),

    cartSummary: builder.mutation<
      cartSummary,
      { sellerWorkspaceId: string; customerId: string }
    >({
      query: (args) => ({
        url: `hub/commerce-v2/poFiles/summary/${args.sellerWorkspaceId}`,
        method: 'GET',
        params: {
          sellerWorkspaceId: args.sellerWorkspaceId,
          customerId: args.customerId,
        },
      }),
    }),

    orderSchemeSummary: builder.mutation<
      OrderSchemeSummaryRepsonse,
      OrderSchemeSummaryRequest
    >({
      query: (args) => ({
        url: `/hub/commerce-v2/orders/order-scheme-summary/${args.workspaceId}?customerId=${args.customerId}&sellerWorkspaceId=${args.workspaceId}`,
        method: 'POST',
        body: args.body,
      }),
    }),
    updateDiscountAndPriceOrder: builder.mutation<
      UpdateOrderResponse,
      UpdateOrderRequest
    >({
      query: (args) => ({
        url: `hub/commerce-v2/orders/update/${args.workspaceId}?sellerWorkspaceId=${args.workspaceId}`,
        method: 'POST',
        body: args.body,
      }),
      async onQueryStarted(request, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then(() => {
            if (request?.isDispatchPOFile) {
              dispatch(
                ordersApi.endpoints.getCart.initiate(
                  {
                    params: {
                      sellerWorkspaceId: request?.workspaceId,
                      customerId: request.body.customerId,
                      includeActiveOrders: true,
                      includeSummary: true,
                    },
                    workspaceId: request?.workspaceId,
                  },
                  { forceRefetch: true }
                )
              );
            }
          })
          .catch((x) => {
            console.error(x);
          });
      },
    }),
    updateQtyOrRemapOrder: builder.mutation<
      UpdateOrderResponse,
      UpdateOrderRequest
    >({
      query: (args) => ({
        url: `hub/commerce-v2/orders/edit/${args.workspaceId}?sellerWorkspaceId=${args.workspaceId}`,
        method: 'POST',
        body: args.body,
      }),
      async onQueryStarted(request, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then(() => {
            if (request?.isDispatchPOFile) {
              dispatch(
                ordersApi.endpoints.getCart.initiate(
                  {
                    params: {
                      sellerWorkspaceId: request?.workspaceId,
                      customerId: request.body.customerId,
                      includeActiveOrders: true,
                      includeSummary: true,
                    },
                    workspaceId: request?.workspaceId,
                  },
                  { forceRefetch: true }
                )
              );
            }
          })
          .catch((x) => {
            console.error(x);
          });
      },
    }),
    editOrder: builder.mutation<UpdateOrderResponse, UpdateOrderRequest>({
      query: (args) => ({
        url: `hub/commerce-v2/orders/edit/${args.workspaceId}?sellerWorkspaceId=${args.workspaceId}`,
        method: 'POST',
        body: args.body,
      }),
    }),

    suggestedMappingProducts: builder.mutation<
      SuggestedProducts[],
      {
        workspaceId: string;
        poFileLineId: string;
        customerId: string;
      }
    >({
      query: (args) => ({
        url: `hub/commerce-v2/poFile/line/available-mapping/${args.workspaceId}?poFileLineId=${args.poFileLineId}&customerId=${args.customerId}&sellerWorkspaceId=${args.workspaceId}`,
        method: 'GET',
      }),
    }),

    getOrdersLogs: builder.mutation<
      { [key: string]: Audit[] },
      OrderLogsRequest
    >({
      query: (args) => ({
        url: `hub/commerce-v2/order-logs?sellerWorkspaceId=${args.workspaceId}`,
        method: 'POST',
        body: args,
      }),
      transformResponse: (res: any[]) => {
        const formatTime = (inputDateString: string) => {
          const inputDate = new Date(inputDateString);
          const formattedTime = inputDate.toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: '2-digit',
            hour12: true,
          });
          return formattedTime;
        };

        // const audits = res
        //   ?.map((obj) => {
        //     const data = obj.data?.map((x) => {
        //       if (x?.action === OrderLogsTypes.REMAPPED) {
        //         return {
        //           ...x,
        //           sequence: obj.sequence,
        //           productName: x.updatedFields?.productDetails?.name,
        //           sku: x.updatedFields?.productDetails?.sku,
        //           oldProductName: x.updatedFields?.oldProductDetails?.name,
        //           oldSku: x.updatedFields?.oldProductDetails?.sku,
        //         };
        //       } else {
        //         return {
        //           ...x,
        //           sequence: obj.sequence,
        //           productName:
        //             obj.productDetails?.name ?? obj?.productDetails ?? '-',
        //           sku: obj?.productDetails?.sku ?? '-',
        //         };
        //       }
        //     });
        //     return { ...obj, data: data };
        //   })
        const audits = res
          ?.map((obj) => {
            let data;
            if (obj?.action === OrderLogsTypes.REMAPPED) {
              data = {
                productName: obj?.productVariantName ?? '-',
                oldProductName: obj.changes?.productVariantId?.oldValue,
                quantity: '',
                unitPrice: obj?.changes?.unitPrice?.newValue ?? '-',
                action: obj?.action ?? '-',
                createdDate: obj?.createdDate ?? '-',
                userName: obj?.userName ?? '-',
                timestamp: obj?.createdDate ?? '-',
              };
            } else {
              data = {
                productName: obj?.productVariantName ?? '-',
                quantity: '',
                unitPrice: obj?.changes?.unitPrice?.newValue ?? '-',
                action: obj?.action ?? '-',
                createdDate: obj?.createdDate ?? '-',
                userName: obj?.userName ?? '-',
                timestamp: obj?.createdDate ?? '-',
              };
            }

            return { ...obj, ...data };
          })
          ?.sort(
            (a, b) =>
              new Date(b.timestamp).getTime() -
                new Date(a.timestamp).getTime() ||
              parseInt(b.timestamp.slice(-3)) - parseInt(a.timestamp.slice(-3))
          )
          .map((c) => ({
            ...c,
            date: moment(new Date(c?.timestamp))
              .format('Do MMM, YYYY')
              ?.toUpperCase(),
            time: formatTime(c?.timestamp),
          }));
        const groupedAudits = audits.reduce((acc: any, current) => {
          const date = current.date;
          if (!acc[date]) {
            acc[date] = [];
          }
          acc[date].push(current);
          return acc;
        }, {});

        return groupedAudits;
      },
    }),
    updatePOStatus: builder.mutation<any, UpdatePOStatusRequest>({
      query(arg) {
        const { sellerWorkspaceId, ...body } = arg;
        return {
          url: `hub/commerce-v2/poFile/order/${sellerWorkspaceId}?sellerWorkspaceId=${arg.sellerWorkspaceId}`,
          method: 'PUT',
          body,
        };
      },
    }),
    notifyToBuyer: builder.mutation<any, NotifyToBuyerRequest>({
      query(arg) {
        const { sellerWorkspaceId, ...body } = arg;
        return {
          url: `hub/commerce-v2/orders/notify-to-buyer/${sellerWorkspaceId}?sellerWorkspaceId=${arg.sellerWorkspaceId}`,
          method: 'POST',
          body,
        };
      },
    }),
    tagList: builder.query<TagListRes[], any>({
      query(params) {
        return {
          url: `/hub/commerce-v2/tags`,
          method: 'GET',
          params,
          headers: {
            'Cache-control': 'no-cache',
          },
        };
      },
    }),
    createTag: builder.mutation<any, any>({
      query(body) {
        return {
          url: `/hub/commerce-v2/tags`,
          method: 'POST',
          body,
        };
      },
    }),
    applyTag: builder.mutation<any, any>({
      query(arg) {
        const { workspaceId, ...body } = arg;
        return {
          url: `/hub/commerce-v2/orders/tags/${workspaceId}?sellerWorkspaceId=${arg.workspaceId}`,
          method: 'POST',
          body: arg.body,
        };
      },
    }),

    cancelOrder: builder.mutation<CancelOrderResponse, CancelOrderRequest>({
      query(arg) {
        const { orderId, sellerWorkspaceId, ...body } = arg;
        return {
          url: `hub/commerce-v2/${sellerWorkspaceId}/order/cancel/${orderId}?sellerWorkspaceId=${sellerWorkspaceId}`,
          method: 'POST',
          body: body,
        };
      },
    }),
    getCart: builder.query<PoListOrderResponse, GetCartRequest>({
      query(arg) {
        return {
          url: `/hub/commerce-v2/${arg?.workspaceId}/cart`,
          method: 'GET',
          params: { ...arg?.params, random: Math.random() },
        };
      },
      async onQueryStarted(request, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then(() => {
            if (Platform.OS !== 'web') {
              dispatch(
                ordersApi.endpoints.cartSummary.initiate({
                  sellerWorkspaceId: request.workspaceId,
                  customerId: request.params.customerId || '',
                })
              );
            }
          })
          .catch((x) => {
            console.error(x);
          });
      },
    }),
  }),
});

export const {
  useAddItemsToOrderMutation,
  useGetFulfillmentLocationsQuery,
  useLazyGetFulfillmentLocationsQuery,
  useListOrdersMutation,
  useOrdersFiltersMutation,
  useLazyGetOrderDetailQuery,
  useCreateOrUpdateOrderMutation,
  useLazyGetCustomerMetadataQuery,
  useGetProductsDetailsMutation,
  useLazyGetLatestOrdersDetailsQuery,
  useGetLatestOrdersDetailsQuery,
  useUpdateOrderMutation,
  useLazyGetActiveOrderQuery,
  useUpdateActiveOrderItemMutation,
  useSumbitCartOrderMutation,
  useUploadOrderFileMutation,
  useListPoOrdersMutation,
  useLazyListCartQuery,
  useCheckoutOrdersMutation,
  useManualProductMappingMutation,
  useGetOrderDetailsMutation,
  useGetTrackPODetailsMutation,
  useDeletePoFileMutation,
  useLazyDownloadPODetailsQuery,
  useLazyDownloadOrderDetailsQuery,
  useDownloadOrderInvoiceMutation,
  useLazyDownloadOrderDataQuery,
  useCreateTicketForUnmappedMutation,
  useDeleteFromActiveOrderMutation,
  useCartSummaryMutation,
  useOrderSchemeSummaryMutation,
  useLazyDownloadPOFileDetailsQuery,
  useUpdateDiscountAndPriceOrderMutation,
  useUpdateQtyOrRemapOrderMutation,
  useSuggestedMappingProductsMutation,
  useGetOrdersLogsMutation,
  useUpdatePoFileMutation,
  useUpdatePOStatusMutation,
  useNotifyToBuyerMutation,
  useEditOrderMutation,
  useLazyTagListQuery,
  useCreateTagMutation,
  useApplyTagMutation,
  useCancelOrderMutation,
  useLazyGetCartQuery,
} = ordersApi;
