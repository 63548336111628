import { Button } from '@mui/material';
import React, { useContext } from 'react';
import { Font, ThemeContext } from '@zonofi/shared';
import Icon from 'react-native-easy-icon';

interface ButtonProps {
  /**
   * Provide the Name of the button;
   */
  text: string;
  /**
   * Callback function to be called on click of the button.
   */
  onClick: () => void;
  /**
   * Button font size. by default 14px is given for both button.
   */
  fontSize?: string;
  /**
   * Button text color. By default white color is given,
   */
  fontColor?: string;
  /**
   * Button background color. By default color #32A7E8 for primary and #DCDFE4 for secondary button is given.
   */
  backgroundColor?: string;
  /**
   * Width of the button. by default 150px is given for both butotn
   */
  width?: string;

  height?: string;
  /**
   * disabled button. By default false is given.
   */
  disabled?: boolean;
  /**
   * Border for the button.
   */
  border?: string;
  borderRadius?: string;
  icon?: any;
  styles?: React.CSSProperties;
  font?: 'regular' | 'semibold' | 'bold';
}

interface DualButtonProps {
  primary: ButtonProps;
  secondary?: ButtonProps;
  showArrow?: boolean;
}

export const DualButton = (props: DualButtonProps) => {
  const { primary, secondary = null } = props;
  const { theme } = useContext(ThemeContext);
  const font =
    primary.font === 'regular'
      ? theme.fontConfig.regular
      : primary.font === 'semibold'
      ? theme.fontConfig.semiBold
      : theme.fontConfig.bold;
  return (
    <div style={{ display: 'flex', gap: '12px' }}>
      <Button
        onClick={() => {
          primary.onClick();
        }}
        style={{
          ...(primary.font ? font : theme.fontConfig.regular),
          fontSize: primary.fontSize || '14px',
          color: primary.fontColor || 'white',
          padding: '8px 12px',
          ...(primary.height ? { height: primary.height } : {}),
          width: primary.width || 'auto',
          borderRadius: primary.borderRadius || '8px',
          backgroundColor: primary.backgroundColor || '#32A7E8',
          boxShadow: 'none',
          textTransform: 'capitalize',
          border: primary.border,
          ...(primary?.styles ? primary?.styles : {}),
        }}
        disabled={primary.disabled}
        endIcon={
          props?.showArrow && (
            <Icon
              type={'font-awesome'}
              name={'arrow-circle-right'}
              color={'white'}
              size={16}
              style={{ marginLeft: 4, marginTop: 3 }}
            />
          )
        }
      >
        {primary.icon && primary.icon}
        {primary.text}
      </Button>
      {secondary && (
        <Button
          onClick={() => secondary?.onClick()}
          style={{
            ...theme.fontConfig.regular,
            fontSize: secondary.fontSize || '14px',
            color: secondary.fontColor || 'white',
            padding: '8px 12px',
            width: secondary.width || 'auto',
            borderRadius: primary.borderRadius || '8px',
            backgroundColor: secondary.backgroundColor || '#DCDFE4',
            boxShadow: 'none',
            textTransform: 'capitalize',
            border: secondary.border,
            ...(secondary?.styles ? primary?.styles : {}),
          }}
          disabled={secondary.disabled}
        >
          {secondary.text}
        </Button>
      )}
    </div>
  );
};
