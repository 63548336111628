import React from 'react';
import './categories.scss';
import { CategoryFilterItem } from '@zonofi/zono-money-store';

interface CategoriesProps {
  categories: CategoryFilterItem[];
  selectedCategories: CategoryFilterItem[];
  setCategories: (val: CategoryFilterItem[]) => any;
  setSelectedCategories: (val: CategoryFilterItem[]) => any;
  onClickCategory: (val: CategoryFilterItem) => any;
  showCategory: boolean;
  showSearch: boolean;
}

export const Categories: React.FC<CategoriesProps> = ({
  categories,
  selectedCategories,
  setCategories,
  setSelectedCategories,
  onClickCategory,
  showCategory,
}) => {
  return (
    <div
      className={`categories${showCategory ? '-visible' : ''}`}
      // style={categoryStyle}
    >
      <div
        className={`categories${showCategory ? '-visible-' : '-'}item ${
          selectedCategories?.length === 0
            ? `categories${showCategory ? '-visible-' : '-'}item-selected`
            : ''
        }`}
        onClick={() => {
          setSelectedCategories([]);
        }}
      >
        <span className="font-primary">All</span>
      </div>
      {categories?.map((item, index) => {
        const isSelected = selectedCategories?.find(
          (i) => item?.categoryId === i?.categoryId
        );
        return (
          <div
            className={`categories${showCategory ? '-visible-' : '-'}item ${
              isSelected
                ? `categories${showCategory ? '-visible-' : '-'}item-selected`
                : ''
            }`}
            key={index}
            onClick={() => onClickCategory(item)}
          >
            <span className="font-primary">{item?.categoryName}</span>
          </div>
        );
      })}
    </div>
  );
};
