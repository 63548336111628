import {
  extractDataFromOrder,
  OrderData,
  OrderStatusEnum,
  useAppSelector,
  useCart,
} from '@zonofi/zono-money-helpers';
import {
  getCustomerInvite,
  ProductPromotion,
  ProductVariantCategory,
  useAddItemToActiveOrderMutation,
  useEditOrderMutation,
  useGetOrderDetailsMutation,
  useGetProductDetailsMutation,
} from '@zonofi/zono-money-store';
import { useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

interface ProductDetails {
  title: string;
  price: number;
  tags: ProductVariantCategory[];
  description: string;
  details: {
    ptr: string;
    margin: string;
    moq: number;
  };
  images: string[];
  itemInCart?: {
    quantity: number;
    orderId: number;
  };
  productVariantId: number;
  promotions: ProductPromotion[] | undefined;
}

export const useProductDetails = () => {
  const [searchParams] = useSearchParams();
  const fromSource = searchParams.get('source');
  const sourceOrderId = searchParams.get('orderId');
  const hideImages = searchParams.get('hImg') === 'true';
  const inviteData = useAppSelector(getCustomerInvite);
  const workspaceId = inviteData?.seller?.workspaceId;
  const customerId = inviteData?.inviteId;

  const params = useParams();
  const { productVariantId } = params;

  const [error, setError] = useState({
    show: false,
    msg: '',
  });
  const [productDetails, setProductDetails] = useState<ProductDetails>({
    title: '',
    price: 0,
    tags: [],
    description: '',
    details: {
      ptr: '',
      margin: '',
      moq: 0,
    },
    promotions: [],
    images: [],
    itemInCart: undefined,
    productVariantId: 0,
  });
  const [addedToCart, setAddedToCart] = useState(false);
  const [showImageGallery, setShowImageGallery] = useState(false);
  const {
    callCartAPI,
    cartAPIResponse,
    productsInCart,
    setProductsInCart,
    cartSummaryAPIResponse,
    onIncrement,
    onDecrement,
    onDelete,
    onChangeQuantity,
    editOrderResponse,
  } = useCart();
  const [isMoqError, setIsMoqError] = useState(false);
  const [getProductDetails, getProductDetailsResponse] =
    useGetProductDetailsMutation();
  const [fetchOrderDetailsAPI, fetchOrderDetailAPIResponse] =
    useGetOrderDetailsMutation();
  const [addItemToSubmittedOrderAPI, addItemToSubmittedOrderAPIResponse] =
    useEditOrderMutation();
  const [addToCartAPI, addToCartAPIResponse] =
    useAddItemToActiveOrderMutation();
  const hidePrice = searchParams?.get('hp') === 'true';
  const onAddToCart = (item: any) => {
    if (fromSource === 'order' && sourceOrderId) {
      addItemToSubmittedOrderAPI({
        workspaceId,
        body: {
          customerId,
          lines: [
            {
              quantity: '1',
              productVariantId: item?.productVariantId,
              operator: 'add',
            },
          ],
          orderId: sourceOrderId,
        },
      });
    } else {
      addToCartAPI({
        sellerWorkspaceId: workspaceId,
        customerId,
        source: 'whatsapp',
        ...(sourceOrderId ? { orderId: sourceOrderId } : {}),
        lines: [
          {
            productVariantId: item?.productVariantId,
            quantity: item?.minOrderQty,
            operator: 'add',
          },
        ],
      });
    }
  };

  const isLoading = useMemo(() => {
    return addToCartAPIResponse?.isLoading || editOrderResponse?.isLoading;
  }, [addToCartAPIResponse, editOrderResponse]);
  useEffect(() => {
    if (editOrderResponse.isError) {
      setIsMoqError(true);
      setTimeout(() => {
        setIsMoqError(false);
      }, 2000);
    }
  }, [editOrderResponse]);
  useEffect(() => {
    if (productVariantId) {
      getProductDetails({
        sellerWorkspaceId: workspaceId,
        customerId,
        body: {
          includeFacets: true,
          includeDivisions: true,
          includeCfas: true,
          productVariantId: Number(productVariantId),
        },
      });
    }
  }, [productVariantId]);

  useEffect(() => {
    if (getProductDetailsResponse?.isSuccess) {
      const product =
        getProductDetailsResponse?.data?.products[0]?.productVariants[0];
      setProductDetails({
        title: product?.name,
        price: product?.price / 100,
        tags: product?.categories || [],
        description: product?.shortDescription,
        promotions: product?.promotions,
        details: {
          ptr: `${(product?.PTR / 100).toFixed(2)}`,
          margin: `${(product?.PTR / 100 - product?.price / 100).toFixed(2)}`,
          moq: product?.minOrderQty,
        },
        // images: product?.assets?.map((i) => i?.redirectUrl),
        images: [product?.assets?.[0]?.redirectUrl],
        productVariantId: product?.productVariantId,
      });

      if (fromSource === 'order') {
        fetchOrderDetailsAPI({
          workspaceId: workspaceId,
          orderId: Number(sourceOrderId),
          body: {
            includeInvoice: false,
            includeTax: false,
            includeCustomer: false,
            includePromotions: false,
            customerId,
            includePromotionData: false,
          },
        });
      } else {
        callCartAPI({
          customerId,
          workspaceId,
          sellerWorkspaceId: workspaceId,
        });
      }
    } else if (getProductDetailsResponse?.isError) {
      setError({
        show: true,
        msg: 'Something went wrong!',
      });
    }
  }, [getProductDetailsResponse]);

  useEffect(() => {
    if (cartAPIResponse?.isSuccess) {
      const productInCart = productsInCart?.find((i) => {
        return i?.productVariantId === Number(productVariantId);
      });

      setProductDetails((prevState) => {
        return {
          ...prevState,
          itemInCart: {
            orderId: productInCart?.orderId,
            quantity: productInCart?.quantity,
          },
        };
      });
    }
  }, [productsInCart, cartAPIResponse]);

  useEffect(() => {
    if (addToCartAPIResponse?.isSuccess) {
      if (fromSource === 'order') {
        fetchOrderDetailsAPI({
          workspaceId: workspaceId,
          orderId: Number(sourceOrderId),
          body: {
            includeInvoice: false,
            includeTax: false,
            includeCustomer: false,
            includePromotions: false,
            customerId,
            includePromotionData: false,
          },
        });
      } else {
        callCartAPI({
          customerId,
          workspaceId,
          sellerWorkspaceId: workspaceId,
        });
      }
    }
  }, [addToCartAPIResponse]);

  useEffect(() => {
    if (editOrderResponse?.isSuccess) {
      if (fromSource === 'order') {
        fetchOrderDetailsAPI({
          workspaceId: workspaceId,
          orderId: Number(sourceOrderId),
          body: {
            includeInvoice: false,
            includeTax: false,
            includeCustomer: false,
            includePromotions: false,
            customerId,
            includePromotionData: false,
          },
        });
      } else {
        callCartAPI({
          customerId,
          workspaceId,
          sellerWorkspaceId: workspaceId,
        });
      }
    }
  }, [editOrderResponse]);

  useEffect(() => {
    if (fetchOrderDetailAPIResponse?.isSuccess) {
      const productInOrder = fetchOrderDetailAPIResponse?.data?.lines?.find(
        (i) => {
          return i?.productVariant?.id === Number(productVariantId);
        }
      );

      setProductDetails((prevState) => {
        return {
          ...prevState,
          itemInCart: {
            orderId: productInOrder?.orderId,
            quantity: productInOrder?.quantity,
          },
        };
      });
    }
  }, [fetchOrderDetailAPIResponse]);

  useEffect(() => {
    if (addToCartAPIResponse?.isSuccess) {
      setAddedToCart(true);
      setTimeout(() => {
        setAddedToCart(false);
      }, 2000);
    }
  }, [addToCartAPIResponse]);
  return {
    productVariantId,
    productDetails,
    getProductDetailsResponse,
    onAddToCart,
    onIncrement,
    onDecrement,
    onDelete,
    onChangeQuantity,
    error,
    isLoading,
    totalCartItems: cartSummaryAPIResponse?.data?.totalSKUCount,
    hidePrice,
    addedToCart,
    isMoqError,
    showImageGallery,
    setShowImageGallery,
    hideImages,
  };
};
