import { ColumnConfig, CustomerPaymentsColumnKey } from '../../model';

export const customerPaymentsConfig: Record<
  CustomerPaymentsColumnKey,
  ColumnConfig
> = {
  paymentId: {
    name: 'Payment Id',
    field: 'textWithImage',
    isHyperlink: true,
  },
  paymentDate: {
    name: 'Payment Date',
  },
  typeOfPayment: {
    name: 'Type Of Payment',
  },
  amount: {
    name: 'Amount',
    sortField: 'amount',
  },
  status: {
    name: 'Status',
    isComponent: true,
  },
};
