import React, { useContext, useMemo } from 'react';
import { CustomSvg, ThemeContext } from '@zonofi/shared';
import { LoadingSpinner, SVGS } from '@zonofi/zono-money-design-system';
import { useAppSelector } from '@zonofi/zono-money-helpers';
import { getCustomerInvite } from '@zonofi/zono-money-store';
import {
  ProductCatalogueWithoutPrice,
  ProductCatalogue,
} from '@zonofi/zono-money-whatsapp-stack';
import { useCatalogueScreen } from './useCatalogueScreen';
import './catalogue-screen.scss';
import { isUndefined } from 'lodash';
import { useLocation } from 'react-router';

export const CatalogueScreen: React.FC = () => {
  const { theme } = useContext(ThemeContext);
  const location = useLocation();

  const isCatalogue = useMemo(
    () => location?.pathname?.includes('catalogue'),
    []
  );
  const isPriceList = useMemo(
    () => location?.pathname?.includes('pricelist'),
    []
  );
  const isCampaign = useMemo(
    () => location?.pathname?.includes('campaign'),
    []
  );

  const inviteData = useAppSelector(getCustomerInvite);

  const {
    orderSummary,
    btnDisabled,
    categories,
    error,
    lastItemRef,
    listData,
    selectedCategories,
    pageNo,
    campaignId,
    onSuccess,
    onScroll,
    getName,
    onAddToCart,
    onClickCategory,
    onDecrement,
    onIncrement,
    onDelete,
    onChangeQuantity,
    addToCartAPIResponse,
    editOrderResponse,
    getCampaignDetailsResponse,
    getCartAPIResponse,
    listProductsAPIResponse,
    isCatalogueLoading,
    pricelistDetailsAPIResponse,
    buttonText,
    showPrice,
    showImage,
    showToastError,
    setShowToastError,
    searchKey,
    setSearchKey,
    showSearchBar,
    setShowSearchBar,
  } = useCatalogueScreen();

  return (
    <div
      className="catalogue_screen"
      style={{ background: '#F1F2F4', ...theme.fontConfig.regular }}
    >
      {/* header section start  */}
      {showSearchBar && (isCatalogue || isCampaign) ? (
        <div className="catalogue_search_container">
          <div className="catalogue_search_bar">
            <img
              height={20}
              width={20}
              src={SVGS.LeftArrowBlack}
              onClick={() => {
                setSearchKey('');
                setShowSearchBar(false);
              }}
            />
            {/* <img height={20} width={20} src={SVGS.SearchIconOrder} /> */}
            <input
              className="catalogue_search_input"
              value={searchKey}
              placeholder="Search with Product name or SKU"
              autoFocus
              onChange={(e) => {
                setSearchKey(e?.target?.value);
              }}
            />
            {searchKey?.length !== 0 && (
              <img
                src={SVGS.XCircle}
                onClick={() => {
                  setSearchKey('');
                }}
              />
            )}
          </div>
        </div>
      ) : (
        <div className="catalogue_header_section">
          <div className="left_sec">
            <div>
              <div
                className="leftname"
                style={{ ...theme.fontConfig.condensedSemiBold }}
              >
                {getName(inviteData?.buyer?.name)}
              </div>
            </div>
            <div className="head_name">
              <h3 style={{ ...theme.fontConfig.condensedSemiBold }}>
                {inviteData?.buyer?.name}
              </h3>
              <span className="catalogue">
                {isCatalogue
                  ? 'Catalogue'
                  : isPriceList
                  ? 'Price List'
                  : getCampaignDetailsResponse?.data?.data?.type === 'ORDER'
                  ? 'Book order for'
                  : 'Get Quote for'}
                <span style={{ ...theme.fontConfig.condensedSemiBold }}>
                  {` ${
                    getCampaignDetailsResponse?.data?.data?.campaignName || ''
                  } `}
                </span>
                {campaignId && 'products'}
              </span>
            </div>
          </div>
          <div className="right_sec">
            {isCatalogue || isCampaign ? (
              <img
                src={SVGS.SearchBlack}
                className="search_img"
                onClick={() => setShowSearchBar(true)}
              />
            ) : (
              <img src={SVGS.ZotokLogoGreen} />
            )}
          </div>
        </div>
      )}
      {/* header section end  */}
      {/* main section start  */}
      {categories && !searchKey && (
        <div className="product_categories" style={theme.fontConfig.semiBold}>
          {categories?.map((item, index) => {
            const isSelected =
              selectedCategories?.filter(
                (i) => i?.categoryId === item?.categoryId
              )?.length !== 0;
            return (
              <span
                className={`product_category ${
                  isSelected && `product_category_selected`
                }`}
                onClick={() => onClickCategory(item)}
                key={index}
              >
                {item?.categoryName}
              </span>
            );
          })}
        </div>
      )}
      {error?.show && (
        <div className="catalogue_error_section">
          <h2>{error?.code}</h2>
          <span style={{ ...theme.fontConfig.semiBold }}>{error?.message}</span>
        </div>
      )}
      {listData?.length === 0 && !isCatalogueLoading && (
        <div className="catalogue_error_section">
          <span
            style={{
              ...theme.fontConfig.semiBold,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            No Products Found
          </span>
        </div>
      )}
      <div className="catalogue_main_section" onTouchMove={onScroll}>
        {isCatalogueLoading ? (
          <div className="catalogue_main_section_loading">
            <LoadingSpinner />
          </div>
        ) : showPrice ? (
          <ProductCatalogue
            listData={listData}
            btnDisabled={btnDisabled}
            onDecrement={onDecrement}
            onIncrement={onIncrement}
            onAddToCart={onAddToCart}
            onDelete={onDelete}
            onChangeQuantity={onChangeQuantity}
            buttonText={buttonText}
            showImage={showImage}
          />
        ) : (
          <ProductCatalogueWithoutPrice
            listData={listData}
            btnDisabled={btnDisabled}
            onDecrement={onDecrement}
            onIncrement={onIncrement}
            onAddToCart={onAddToCart}
            onDelete={onDelete}
            onChangeQuantity={onChangeQuantity}
            buttonText={buttonText}
            showImage={showImage}
          />
        )}
        <div ref={lastItemRef} />
      </div>
      {listProductsAPIResponse?.isLoading && pageNo !== 1 && (
        <div className="catalogue_loading_small">
          <LoadingSpinner size={'small'} />
          Loading...
        </div>
      )}
      {/* main section end  */}
      {/* footer section start */}
      {showToastError?.show && (
        <div className="catalogue_toast_error">
          <span>{showToastError?.message}</span>
          <img
            src={SVGS.XCircle}
            onClick={() =>
              setShowToastError({
                show: false,
                message: '',
              })
            }
          />
        </div>
      )}
      {!getCartAPIResponse?.isLoading &&
        getCartAPIResponse?.isSuccess &&
        !isCatalogueLoading &&
        !isUndefined(orderSummary?.skuCount) &&
        orderSummary?.skuCount !== 0 && (
          <div className="catalogue_footer_sec">
            <div className="catalogue_footer_sec_list">
              <div className="left">
                <h5>{`${orderSummary?.skuCount} Items - ${orderSummary?.totalQuantity} Qty`}</h5>
              </div>
              <div className="right" onClick={onSuccess}>
                <span>View Summary</span>
                <img src={SVGS.LeftArrowWhite} className="right_arrow" />
              </div>
            </div>
          </div>
        )}
      {/* footer section end */}
    </div>
  );
};
