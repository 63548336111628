import * as React from 'react';
import { SHARED_SVGS } from '../assets';
import { ThemeContext } from '../contexts/ThemeContext';
import { colors } from '../colors/colors';

export interface IComingSoonProps {
  featureName: string;
}

export function ComingSoon(props: IComingSoonProps) {
  const { theme } = React.useContext(ThemeContext);
  const { featureName } = props;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      {/* ICON DIV */}
      <div>
        {' '}
        <img
          src={SHARED_SVGS.ZotokLogo}
          style={{ height: 'auto', width: '10vw' }}
        />
      </div>
      {/* CONTENT DIV  */}
      <div
        style={{
          fontFamily: theme.fontConfig.semiBold.fontFamily,
          fontSize: 20,
          padding: '12px 0px 12px 20px',
        }}
      >
        {featureName} feature is coming soon
      </div>
      <div
        style={{
          fontFamily: theme.fontConfig.semiBold.fontFamily,
          color: colors.gray.primary,
          fontSize: 14,
          marginTop: 2,
        }}
      >
        We will notify you once this feature is available.
      </div>
    </div>
  );
}
