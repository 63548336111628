import { ColumnConfig, PaymentRequestKey } from '../model';

export const paymentRequestConfig: Record<PaymentRequestKey, ColumnConfig> = {
  invoiceWithDate: {
    name: 'Invoice & Date',
    field: 'checkboxText',
    isCheckboxField: true,
  },
  orderNumber: {
    name: 'Order Number',
  },
  status: {
    name: 'Status',
  },
  customerName: {
    name: 'Customer',
    field: 'avatarNameStatus',
  },
  dueDate: {
    name: 'Due Date',
    sortField: 'dueDate',
  },
  invoiceAmount: {
    name: 'Invoice Amount',
    alignment: 'flex-end',
    sortField: 'invoiceAmount',
  },
  action: {
    name: '',
    alignment: 'center',
  },
};
