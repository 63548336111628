import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../root';
import { mappingReportConfig } from './configs/mapping-report-config';

import {
  cfaTrackPOConfig,
  customerTrackPOConfig,
  principalInvoiceConfig,
  cfaInvoiceConfig,
  customerInvoiceConfig,
  invoiceDetailsConfig,
  principalSchemeConfig,
  cfaSchemeConfig,
  customerSchemeConfig,
  customerConfig,
  cfaOrderDetailsViewConfig,
  cfaOrderDetailsEditConfig,
  customerOrderDetailsViewConfig,
  principalOrderDetailsViewConfig,
  principalOrderDetailsEditConfig,
  cfaCustomerConfig,
  cfaSelectCustomerConfig,
  cartConfig,
  cfaOrderConfig,
  cfaTrackPODetailsEditConfig,
  customerTrackPODetailsConfig,
  customerOrderConfig,
  principalOrderConfig,
  customerProductConfig,
  principalProductConfig,
  cfaProductConfig,
  recommendedProductsConfig,
  batchNumberLookupConfig,
  customerReturnsConfig,
  bankAccountConfig,
  upiIdConfig,
  customerManualReturnConfig,
  customerDraftReturnConfig,
  cfaDraftReturnConfig,
  cfaManualReturnConfig,
  cfaReturnDetailsConfig,
  customerReturnDetailsConfig,
  cfaReturnsConfig,
  principalReturnsConfig,
  principalReturnDetailsConfig,
  cfaTeamInvitesConfig,
  customerTeamInvitesConfig,
  principalTeamInvitesConfig,
  ledgerConfig,
  returnsDraftConfig,
  divisionConfig,
  supportCustomers,
  productMappingConfig,
  productsCfaConfig,
  schemesCfaConfig,
  supportUserConfig,
  assignedCustomersConfig,
  supportUserManageAccessConfig,
  commsCustomerConfig,
  commsTeamMembersConfig,
  cfaAddProductToOrderConfig,
  commsDetailsCustomerConfig,
  commsDetailsTeamMembersConfig,
  bulkCustomerUploadConfig,
  headDivisionConfig,
  listCfaConfig,
  bulkCfaUploadConfig,
  bulkLedgerUploadConfig,
  bulkImportsConfig,
  bulkDivisionUploadConfig,
  bulkHeadDivisionUploadConfig,
  paymentTransactionConfig,
  bulkProductUploadConfig,
  cfaTrackPODetailsViewConfig,
  principalUserConfig,
  chatActionCreateInvoiceConfig,
  ledgerOutstandingConfig,
  workspaceConfig,
  chatActionSendPricesConfig,
  chatActionSendPricesEditConfig,
  chatActionViewOrderConfig,
  chatActionViewOrderEditConfig,
  chatActionSendLedgerConfig,
  chatActionSendInvoicesConfig,
  chatActionSendSchemesConfig,
  chatActionViewInvoiceConfig,
  zoeyPendingInvoiceConfig,
  principalPaymentsConfig,
  customerPaymentsConfig,
  cfaPaymentsConfig,
  zoeyOrderListConfig,
  zoeyPOListConfig,
  paymentRequestConfig,
  categoryConfig,
  PriceListConfig,
  PriceProductListConfig,
  chatActionViewPaymentConfig,
  chatActionViewProductsConfig,
  chatActionViewCampaignsConfig,
  bulkInvoiceUploadConfig,
  principalReportsConfig,
  customerReportsConfig,
  cfaReportsConfig,
  cfaOrderReportConfig,
  cfaCustomerOrderReportConfig,
  customerOrderReportConfig,
  schedulerConfig,
  orderDetailsReportConfig,
  salesLeaderboardReportConfig,
  CustomerPerformanceReportConfig,
  ProductPerformanceReportConfig,
  InvoiceReportConfig,
  salesTeamCheckInReportConfig,
  paymentReportConfig,
  chatActionCreateOrderConfig,
  PriceProductListConfig2,
} from './configs';
import { ColumnConfig, ColumnKey, TableKey } from './model';
import { zoeyDraftListConfig } from './configs/zoey-ai/draft-list-config';
export const TABLE_CONFIG_FEATURE_KEY = 'tableConfig';

interface TableConfig {
  config: Record<TableKey, Record<any, ColumnConfig>> | undefined;
}

interface ToggleMultipleColumns {
  toggleColumn: ColumnKey;
  isVisible: boolean;
}

export const initialTableConfigState: TableConfig = {
  config: {
    priceListConfig: PriceListConfig,
    priceProductListConfig: PriceProductListConfig,
    priceProductListConfig2: PriceProductListConfig2,
    cfaTrackPO: cfaTrackPOConfig,
    customerTrackPO: customerTrackPOConfig,
    principalOrders: principalOrderConfig,
    customerOrders: customerOrderConfig,
    cfaOrders: cfaOrderConfig,
    principalSchemes: principalSchemeConfig,
    cfaSchemes: cfaSchemeConfig,
    customerSchemes: customerSchemeConfig,
    customers: customerConfig,

    cfaOrderDetailsView: cfaOrderDetailsViewConfig,
    cfaOrderDetailsEdit: cfaOrderDetailsEditConfig,

    principalOrderDetailsView: principalOrderDetailsViewConfig,
    principalOrderDetailsEdit: principalOrderDetailsEditConfig,

    customerOrderDetailsView: customerOrderDetailsViewConfig,

    cfaCustomers: cfaCustomerConfig,
    cfaSelectCustomer: cfaSelectCustomerConfig,
    cart: cartConfig,

    cfaTrackPODetailsEdit: cfaTrackPODetailsEditConfig,
    cfaTrackPODetailsView: cfaTrackPODetailsViewConfig,

    customerTrackPODetails: customerTrackPODetailsConfig,
    customerProducts: customerProductConfig,
    cfaProducts: cfaProductConfig,
    principalProducts: principalProductConfig,
    recommendedProducts: recommendedProductsConfig,
    batchNumberLookupConfig: batchNumberLookupConfig,
    bankAccounts: bankAccountConfig,
    upiIds: upiIdConfig,
    customerReturns: customerReturnsConfig,
    cfaReturns: cfaReturnsConfig,
    principalReturns: principalReturnsConfig,
    cfaReturnDetails: cfaReturnDetailsConfig,
    principalReturnDetails: principalReturnDetailsConfig,
    cfaDraftReturn: cfaDraftReturnConfig,
    cfaManualReturn: cfaManualReturnConfig,
    customerReturnDetails: customerReturnDetailsConfig,
    customerDraftReturn: customerDraftReturnConfig,
    customerManualReturn: customerManualReturnConfig,
    customerTeamInvites: customerTeamInvitesConfig,
    cfaTeamInvites: cfaTeamInvitesConfig,
    principalTeamInvites: principalTeamInvitesConfig,
    ledger: ledgerConfig,

    returnsDraft: returnsDraftConfig,
    principalInvoice: principalInvoiceConfig,
    customerInvoice: customerInvoiceConfig,
    cfaInvoice: cfaInvoiceConfig,
    divisions: divisionConfig,
    supportCustomers: supportCustomers,
    productsMapping: productMappingConfig,
    invoiceDetails: invoiceDetailsConfig,
    principalProductsCfa: productsCfaConfig,
    principalSchemesCfa: schemesCfaConfig,
    supportUser: supportUserConfig,
    assignedCustomers: assignedCustomersConfig,
    supportUserManageAccess: supportUserManageAccessConfig,
    commsCustomers: commsCustomerConfig,
    commsTeamMembers: commsTeamMembersConfig,
    cfaAddProductToOrder: cfaAddProductToOrderConfig,
    principalAddProductToOrder: cfaAddProductToOrderConfig,
    commsDetailsCustomers: commsDetailsCustomerConfig,
    commsDetailsTeamMembers: commsDetailsTeamMembersConfig,
    bulkCustomerUpload: bulkCustomerUploadConfig,
    headDivisions: headDivisionConfig,
    listCfaConfig: listCfaConfig,
    bulkCfaUpload: bulkCfaUploadConfig,
    bulkLedgerUpload: bulkLedgerUploadConfig,
    bulkUploadImportsConfig: bulkImportsConfig,
    bulkDivisionUpload: bulkDivisionUploadConfig,
    bulkHeadDivisionUpload: bulkHeadDivisionUploadConfig,
    paymentTransaction: paymentTransactionConfig,
    bulkProductUpload: bulkProductUploadConfig,
    bulkInvoiceUpload: bulkInvoiceUploadConfig,
    principalUser: principalUserConfig,
    mappingReport: mappingReportConfig,
    chatActionCreateInvoice: chatActionCreateInvoiceConfig,
    chatActionSendPrices: chatActionSendPricesConfig,
    chatActionSendPricesEdit: chatActionSendPricesEditConfig,
    chatActionViewOrder: chatActionViewOrderConfig,
    chatActionViewOrderEdit: chatActionViewOrderEditConfig,
    chatActionViewProducts: chatActionViewProductsConfig,
    chatActionViewCampaigns: chatActionViewCampaignsConfig,
    chatActionSendInvoices: chatActionSendInvoicesConfig,
    chatActionSendSchemes: chatActionSendSchemesConfig,
    chatActionCreateOrder: chatActionCreateOrderConfig,
    ledgerOutstanding: ledgerOutstandingConfig,
    workspace: workspaceConfig,
    chatActionSendLedger: chatActionSendLedgerConfig,
    chatActionViewInvoice: chatActionViewInvoiceConfig,
    chatActionViewPayment: chatActionViewPaymentConfig,
    zoeyPendingInvoice: zoeyPendingInvoiceConfig,
    principalPayments: principalPaymentsConfig,
    customerPayments: customerPaymentsConfig,
    cfaPayments: cfaPaymentsConfig,
    zoeyOrderList: zoeyOrderListConfig,
    zoeyPOList: zoeyDraftListConfig,
    paymentRequest: paymentRequestConfig,
    category: categoryConfig,
    principalReports: principalReportsConfig,
    cfaReports: cfaReportsConfig,
    customerReports: customerReportsConfig,
    customerOrderReport: customerOrderReportConfig,
    cfaOrderReport: cfaOrderReportConfig,
    cfaCustomerOrderReport: cfaCustomerOrderReportConfig,
    scheduler: schedulerConfig,
    orderDetailsReport: orderDetailsReportConfig,
    customerPerformanceReport: CustomerPerformanceReportConfig,
    productPerformanceReport: ProductPerformanceReportConfig,
    salesLeaderboardReport: salesLeaderboardReportConfig,
    invoiceReport: InvoiceReportConfig,
    salesTeamCheckInReport: salesTeamCheckInReportConfig,
    paymentReport: paymentReportConfig,
  },
};

export const tableConfigSlice = createSlice({
  name: TABLE_CONFIG_FEATURE_KEY,
  initialState: initialTableConfigState,
  reducers: {
    updateColumnToggleState: (
      state,
      action: PayloadAction<{
        key: TableKey;
        toggleColumn: ColumnKey;
      }>
    ) => {
      const { key, toggleColumn } = action.payload;
      if (state.config) {
        state.config = {
          ...state.config,
          [key]: {
            ...state.config[key],
            [toggleColumn]: {
              ...state.config[key][toggleColumn],
              isVisible: !state.config[key][toggleColumn]?.isVisible,
            },
          },
        };
      }
    },

    updateMultipleColumnsState: (
      state,
      action: PayloadAction<{
        key: TableKey;
        toggleColumns: ToggleMultipleColumns[];
      }>
    ) => {
      const { key, toggleColumns } = action.payload;
      if (state.config) {
        for (let i = 0; i < toggleColumns.length; i++) {
          state.config = {
            ...state.config,
            [key]: {
              ...state.config[key],
              [toggleColumns[i].toggleColumn]: {
                ...state.config[key][toggleColumns[i].toggleColumn],
                isVisible: toggleColumns[i].isVisible,
              },
            },
          };
        }
      }
    },
    resetTableConfig: (state) => {
      Object.assign(state, initialTableConfigState);
    },
  },
});

export const {
  updateColumnToggleState,
  updateMultipleColumnsState,
  resetTableConfig,
} = tableConfigSlice.actions;

export const getTableConfigState = (rootState: RootState): TableConfig =>
  rootState[TABLE_CONFIG_FEATURE_KEY];

export const getConfigByTableKey = (tableKey: TableKey) =>
  createSelector(getTableConfigState, (tableConfigState) =>
    tableConfigState.config ? tableConfigState.config[tableKey] : undefined
  );
