import { ColumnConfig, CustomerTeamInvitesColumnKey } from '../../model';

export const customerTeamInvitesConfig: Record<
  CustomerTeamInvitesColumnKey,
  ColumnConfig
> = {
  user: {
    name: 'User',
    field: 'component',
  },
  role: {
    name: 'Role',
  },
  status: {
    name: 'Invite Status',
  },
  actions: { name: '', alignment: 'flex-end' },
};
