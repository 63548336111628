import { ColumnConfig, PrincipalUserColumnKey } from '../model';

export const principalUserConfig: Record<PrincipalUserColumnKey, ColumnConfig> =
  {
    user: {
      name: 'Principal',
      field: 'avatarName',
    },
    mobile: {
      name: 'Mobile Number',
    },
    email: {
      name: 'Email ID',
    },
    gst: {
      name: 'GST',
    },
    action: {
      name: 'Workspace Auth Token',
      field: 'component',
      alignment: 'flex-end',
    },
  };
