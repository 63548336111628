import { BulkProductColumnKey, ColumnConfig } from '../model';

export const bulkProductUploadConfig: Record<
  BulkProductColumnKey,
  ColumnConfig
> = {
  productImages: { name: 'Product Images', field: 'component' },
  productName: { name: 'Product Name' },
  description: { name: 'Description' },
  skuCode: { name: 'SKU Code' },
  upcCode: { name: 'UPC Code' },
  hsnCode: { name: 'HSN Code' },
  headDivisionCode: { name: 'Head Division Code' },
  divisionCode: { name: 'Division Code' },
  manufacturingDate: { name: 'Manufacturing Date' },
  expiryDate: { name: 'Expiry Date' },
  expiryBeforeAfter: { name: 'Expiry Before/After' },
  expiryPeriod: { name: 'Expiry Period' },
  buyerPrice: { name: 'Buyer Price' },
  sellerPrice: { name: 'Seller Price' },
  mrp: { name: 'MRP' },
  taxCategory: { name: 'Tax Category' },
  minOrderQuantity: { name: 'Min Order Quantity' },
  maxOrderQuantity: { name: 'Max Order Quantity' },
  baseUnitOfMeasurement: { name: 'Base Unit of Measurement' },
  quantity: { name: 'Quantity' },
  packSize: { name: 'Pack Size' },
  quantityMultiplier: { name: 'Quantity Multiplier' },

  currentStock: { name: 'Current Stock' },
  cfaInventory: { name: 'CFA Inventory' },
  sales: { name: 'Sales' },
  inventoryDays: { name: 'Inventory Days' },
  sortOrder: { name: 'Sort Order' },
  shelfLife: { name: 'Shelf Life' },
  conditions: { name: 'Conditions' },
  warrantyInformation: { name: 'Warranty Information' },
  grossWeight: { name: 'Gross Weight' },
  netWeight: { name: 'Net Weight' },
  volume: { name: 'Volume' },

  cfa: { name: 'CFA Codes' },

  action: { name: '', field: 'component' },
};
