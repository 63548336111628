import { Api } from '../api/root-api';
import {
  FetchReturnOrdersResponse,
  GetReturnsRequest,
  FetchReturnOrderDetailsResponse,
  GetReturnOrderRequest,
  BatchSearchRequest,
  BatchSearchResponse,
  CreateReturnRequest,
  ReturnOrder,
  SubmitReturnRequest,
  DeleteReturnRequest,
  UpdateReturnRequest,
  UploadReturnFileRequest,
  UploadReturnFileResponse,
  DownloadReturnOrderBody,
} from './model';
import {
  addListInputItems,
  deleteListInputItem,
  ListInputItemOptions,
} from '../filter';
import {
  AllReturnTypes,
  CategoryEnum,
  ReturnOrderLineStatusEnum,
} from '../helpers';

export const returnsApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    getReturns: builder.mutation<FetchReturnOrdersResponse, GetReturnsRequest>({
      query: (args) => ({
        url: `hub/claims/return-orders/list/${args.workSpaceId}`,
        method: 'POST',
        body: args.body,
        params: args.params,
      }),
      async onQueryStarted(request, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then((response) => {
            if (request?.dispatchFilters) {
              const importSourcesInputItems =
                response?.data?.importSources?.map((x, i) => {
                  return {
                    id: i,
                    value: x.importSource,
                    selected: false,
                  };
                });
              dispatch(
                addListInputItems({
                  inputItems: importSourcesInputItems,
                  key: ListInputItemOptions.importSources,
                })
              );

              const allStatusInputItems = response?.data?.allStatus?.map(
                (x, i) => {
                  const status =
                    x.status === 'APPROVED' ? 'ACKNOWLEDGED BY CFA' : x.status;
                  return {
                    id: i,
                    value: status,
                    selected: false,
                  };
                }
              );

              if (request.hideReturnStatus) {
                dispatch(
                  deleteListInputItem({
                    key: ListInputItemOptions.returnStatus,
                  })
                );
              } else {
                dispatch(
                  addListInputItems({
                    inputItems: allStatusInputItems,
                    key: ListInputItemOptions.returnStatus,
                  })
                );
              }

              const cfaIdsInputItems = response?.data?.cfaIds
                ?.filter((cfa) => cfa.cfaName)
                ?.map((x, i) => {
                  return {
                    id: x.cfaId,
                    value: x.cfaName,
                    selected: false,
                  };
                });
              dispatch(
                addListInputItems({
                  inputItems: cfaIdsInputItems,
                  key: ListInputItemOptions.cfa,
                })
              );
            }
          })
          .catch((error) => console.log(error));
      },
    }),
    getReturnDetails: builder.mutation<
      FetchReturnOrderDetailsResponse,
      GetReturnOrderRequest
    >({
      query: (args) => ({
        url: `hub/claims/return-orders/details/${args.workSpaceId}`,
        method: 'POST',
        body: args.body,
        params: args.params,
      }),
      async onQueryStarted(request, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then(() => {
            if (request?.dispatchFilters) {
              const categoryList = Object.entries(CategoryEnum).map(
                ([k, v]) => {
                  return {
                    id: v,
                    value: k.replace(/([a-z])([A-Z])/g, '$1 $2'),
                    selected: false,
                  };
                }
              );
              const typeList = Object.entries(AllReturnTypes).map(([k, v]) => {
                return {
                  id: v,
                  value: k.replace(/([a-z])([A-Z])/g, '$1 $2'),
                  selected: false,
                };
              });
              const statusList = Object.entries(ReturnOrderLineStatusEnum).map(
                ([k, v]) => {
                  return {
                    id: v,
                    value: k,
                    selected: false,
                  };
                }
              );
              dispatch(
                addListInputItems({
                  inputItems: categoryList,
                  key: ListInputItemOptions.batchCategory,
                })
              );
              dispatch(
                addListInputItems({
                  inputItems: typeList,
                  key: ListInputItemOptions.returnType,
                })
              );
              dispatch(
                addListInputItems({
                  inputItems: statusList,
                  key: ListInputItemOptions.returnLineStatus,
                })
              );
            }
          })
          .catch((error) => console.log(error));
      },
    }),
    batchSearch: builder.mutation<
      { response: BatchSearchResponse[]; errorMsg: string[] },
      BatchSearchRequest
    >({
      query(arg) {
        const { sellerWorkspace, ...body } = arg;
        return {
          url: `hub/claims/batch/${sellerWorkspace}/search/`,
          body: body,
          method: 'POST',
          params: {
            sellerWorkspaceId: sellerWorkspace,
          },
        };
      },
    }),
    createReturn: builder.mutation<
      { returnOrder: ReturnOrder; errors: any[] },
      CreateReturnRequest
    >({
      query(arg) {
        const { sellerWorkspace, ...body } = arg;
        return {
          url: `hub/claims/return-orders/${sellerWorkspace}`,
          body: body,
          method: 'POST',
          params: {
            sellerWorkspaceId: sellerWorkspace,
          },
        };
      },
    }),
    submitReturnByCustomer: builder.mutation<
      { returnOrder: ReturnOrder; errors: any[] },
      SubmitReturnRequest
    >({
      query(arg) {
        const { sellerWorkspace, ...body } = arg;
        return {
          url: `hub/claims/return-orders/update/bycustomer/${sellerWorkspace}`,
          body: body,
          method: 'POST',
          params: {
            sellerWorkspaceId: sellerWorkspace,
          },
        };
      },
    }),
    deleteReturn: builder.mutation<any, DeleteReturnRequest>({
      query(arg) {
        const { sellerWorkspace, ...body } = arg;
        return {
          url: `hub/claims/return-orders/${sellerWorkspace}`,
          body: body,
          method: 'DELETE',
          params: {
            sellerWorkspaceId: sellerWorkspace,
          },
        };
      },
    }),
    updateReturn: builder.mutation<
      { returnOrder: FetchReturnOrderDetailsResponse; errors: any[] },
      UpdateReturnRequest
    >({
      query(args) {
        const { sellerWorkspaceId, ...body } = args;
        return {
          url: `hub/claims/return-orders/update/${sellerWorkspaceId}`,
          method: 'POST',
          body: body,
          params: {
            sellerWorkspaceId,
          },
        };
      },
    }),
    uploadReturnFile: builder.mutation<
      UploadReturnFileResponse,
      {
        principalWorkspaceId: string;
        params: UploadReturnFileRequest;
        body: FormData;
      }
    >({
      query: (args) => ({
        url: `hub/claims/return-orders/upload/${args.principalWorkspaceId}`,
        method: 'POST',
        params: args.params,
        body: args.body,
      }),
      transformResponse: (response: UploadReturnFileResponse) => {
        const sortedLines = [...(response.lines || [])].sort(
          (a, b) => a.id - b.id
        );
        return {
          ...response,
          lines: sortedLines,
        };
      },
    }),
    downloadReturnOrder: builder.mutation<
      any,
      {
        principalWorkspaceId: string;
        body: DownloadReturnOrderBody;
      }
    >({
      query: (args) => ({
        url: `hub/claims/return-orders/download/${args.principalWorkspaceId}`,
        method: 'POST',
        body: args.body,
        params: {
          sellerWorkspaceId: args.principalWorkspaceId,
        },
      }),
    }),
    downloadReturnOrderFile: builder.query<
      any,
      {
        workspaceId: string;
        returnOrderId: number;
        sellerWorkspaceId: string;
      }
    >({
      query: (args) => ({
        url: `hub/claims/return-orders/download/${args.workspaceId}`,
        method: 'GET',
        params: args,
        responseHandler: (response) => response.text(),
      }),
    }),
  }),
});

export const {
  useBatchSearchMutation,
  useCreateReturnMutation,
  useGetReturnsMutation,
  useGetReturnDetailsMutation,
  useSubmitReturnByCustomerMutation,
  useDeleteReturnMutation,
  useUpdateReturnMutation,
  useUploadReturnFileMutation,
  useDownloadReturnOrderMutation,
  useLazyDownloadReturnOrderFileQuery,
} = returnsApi;
