import { ColumnConfig, CustomersColumnKey } from '../../model';

export const customerConfig: Record<CustomersColumnKey, ColumnConfig> = {
  slNumber: { name: 'S.no' },
  customerSelection: {
    name: 'Customer',
    field: 'checkboxText',
    isCheckboxField: false,
  },
  customerName: {
    name: 'Customer Name',
    field: 'avatarNameStatus',
  },
  // status: {
  //   name: 'Status',
  //   field: 'customerListStatusTag',
  // },
  customerCode: { name: 'Customer Code' },
  routes: {
    name: 'Routes',
    field: 'component',
    isComponent: true,
  },
  segments: {
    name: 'Segments',
    field: 'component',
    isComponent: true,
  },
  // mobileNumber: {
  //   name: 'Mobile Number',
  // },
  numberOfDivisions: { name: 'No. of divisions', alignment: 'flex-end' },
  // dlNumber: {
  //   name: 'DL Number',
  // },
  // gstNumber: {
  //   name: 'GST Number',
  // },
  // groups: {
  //   name: 'Groups',
  //   field: 'customerGroups',
  // },
  teamMember: {
    name: 'Team Members',
    alignment: 'flex-end',
    field: 'addAction',
  },
  action: {
    name: 'Actions',
    field: 'customerListActions',
    alignment: 'flex-end',
    permissions: ['order_write'],
  },
};
