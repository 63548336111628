import { Api } from '../api/root-api';
import {
  UploadWorkspaceDocumentRequest,
  Workspace,
  WorkspacePostRequest,
  DocumentResponse,
  DocumentRequest,
  CreatePrincipalWorkspaceRequest,
  SignedUrlRequest,
  SignedUrlResponse,
  Principal,
  CreatePrincipalWorkspaceResponse,
} from './model';
import { addWorkspace } from './slice';
import { updateUserProfile } from '../auth/slice';

export const workspaceApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    getWorkspace: builder.query<Workspace[], void>({
      query() {
        return {
          url: `workspaces`,
          method: 'GET',
        };
      },
      providesTags: ['workspace'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          const getProfilePic = async (principal: Principal) => {
            try {
              const res = await dispatch(
                workspaceApi.endpoints.getDocuments.initiate({
                  workspaceId: principal?.principalWorkspaceId,
                  sequenceNo: '1',
                  subType: 'passport_photo',
                  entityId: principal?.principalWorkspaceId,
                  documentType: 'customer',
                })
              );
              const updatedPrincipal: Principal = {
                ...principal,
                profilePic: res.data?.[0]?.url || '',
              };
              return updatedPrincipal;
            } catch (error) {
              console.log(error);
              return principal;
            }
          };

          const updateWorkspace = async (workspace: Workspace) => {
            if (workspace?.principal?.length) {
              const updatedPrincipals = await Promise.all(
                workspace.principal.map(getProfilePic)
              );
              return {
                ...workspace,
                isInZonoMoney: true,
                isPaymentInvoicesAvailable: true,
                principal: updatedPrincipals,
              };
            } else return workspace;
          };

          const updatedWorkspaces = await Promise.all(
            data.map(updateWorkspace)
          );
          dispatch(addWorkspace(updatedWorkspaces));
        } catch (err) {
          console.log(err, 'dispatchErr');
        }
      },
    }),
    createWorkspace: builder.mutation<Workspace, WorkspacePostRequest>({
      query(arg) {
        return { url: `workspaces`, method: 'POST', body: arg };
      },
    }),
    updateWorkspaceDetails: builder.mutation<any, any>({
      query(arg) {
        return {
          url: 'workspace/details',
          method: 'PUT',
          body: arg,
        };
      },
      invalidatesTags: ['workspace'],
    }),
    updateWorkspaceCredentials: builder.mutation<any, any>({
      query(arg) {
        return {
          url: 'workspace-details/credentials',
          method: 'POST',
          body: arg,
        };
      },
      invalidatesTags: ['workspace'],
    }),
    uploadWorkspaceDocuments: builder.mutation<
      { presignedUrl: string },
      UploadWorkspaceDocumentRequest
    >({
      query(arg) {
        const { workspaceId, ...body } = arg;
        return {
          url: `workspaces/documents/${workspaceId}`,
          method: 'POST',
          body: body,
        };
      },
    }),
    qrCodeWorkspaceDocuments:builder.mutation<
    { presignedUrl: string },
    UploadWorkspaceDocumentRequest
  >({
    query(arg) {
      const { workspaceId, ...body } = arg;
      return {
        url: `workspaces/documents/v3/${workspaceId}`,
        method: 'POST',
        body: body,
      };
    },
  }),
    getDocuments: builder.query<DocumentResponse[], DocumentRequest>({
      query(arg) {
        const { workspaceId, ...params } = arg;
        return {
          url: `workspaces/documents/${workspaceId}`,
          method: 'GET',
          params: params,
        };
      },
    }),
    getSignedUrlList: builder.query<SignedUrlResponse[], SignedUrlRequest>({
      query(arg) {
        return {
          url: `signUrl/list`,
          method: 'GET',
          params: arg,
        };  
      },
    }),
    deleteDocument: builder.query<DocumentResponse[], DocumentRequest>({
      query(arg) {
        const { workspaceId, ...params } = arg;
        return {
          url: `workspaces/documents/delete/${workspaceId}`,
          method: 'POST',
          params: params,
        };
      },
      // async onQueryStarted(request, { dispatch, queryFulfilled }) {
      //   queryFulfilled
      //     .then(() => {
      //       dispatch(updateUserProfile(''));
      //     })
      //     .catch((x) => {
      //       console.error(x);
      //     });
      // },
    }),
    createPrincipalWorkspace: builder.mutation<
      CreatePrincipalWorkspaceResponse,
      CreatePrincipalWorkspaceRequest
    >({
      query(arg) {
        return {
          url: 'admin/principal/workspace',
          method: 'POST',
          body: arg,
        };
      },
    }),
  }),
});

export const {
  useLazyGetWorkspaceQuery,
  useCreateWorkspaceMutation,
  useUpdateWorkspaceCredentialsMutation,
  useUpdateWorkspaceDetailsMutation,
  useUploadWorkspaceDocumentsMutation,
  useQrCodeWorkspaceDocumentsMutation,
  useLazyGetDocumentsQuery,
  useGetDocumentsQuery,
  useLazyDeleteDocumentQuery,
  useCreatePrincipalWorkspaceMutation,
  useLazyGetSignedUrlListQuery,
} = workspaceApi;
