import { ColumnConfig, ZoeyPOListColumnKey } from '../../model';

export const zoeyDraftListConfig: Record<ZoeyPOListColumnKey, ColumnConfig> = {
  poNo: {
    name: 'PO Number',
  },
  orderDate: {
    name: 'Order Date',
    field: 'orderDate',
    sortField: 'orderDate',
  },
  skuCount: {
    name: 'SKU Count',
    field: 'skuCount',
    sortField: 'skuCount',
    alignment: 'flex-end',
  },
  orderedValue: {
    name: 'Order Value',
    field: 'orderedValue',
    sortField: 'orderedAmount',
    alignment: 'flex-end',
  },
  action: {
    name: '',
  },
};
