import { BulkCfaCoulmnkey, ColumnConfig } from '../model';

export const bulkCfaUploadConfig: Record<BulkCfaCoulmnkey, ColumnConfig> = {
  cfaName: {
    name: 'Cfa Name',
    field: 'avatarNameStatus',
    isHyperlink: false,
  },
  cfaCode: {
    name: 'Cfa Code',
  },
  mobileNumber: {
    name: 'Mobile Number',
  },
  email: {
    name: 'Email ID',
  },
  pinCode: {
    name: 'Pin Code',
  },
  state: {
    name: 'State',
  },
  city: {
    name: 'City',
  },
  actions: {
    name: '',
    field: 'component',
  },
};
