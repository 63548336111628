import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../root';
import { kycApi } from './api';
import { KycStatusResponse, KycSummary, PanImage } from './model';

export const KYC_FEATURE_KEY = 'kycData';

export interface KycState {
  kycData: KycStatusResponse;
  kycSummary: KycSummary;
  panImage: PanImage;
  showEkycModal: boolean;
}

export const initialKycState: KycState = {
  kycData: {
    id: '',
    createdDate: '',
    updatedDate: '',
    workspaceId: '',
    kycStatus: 'INIT',
    approvedAt: null,
    rejectedAt: null,
    remark: '',
    remarkBy: null,
    kycSteps: [],
  },
  kycSummary: { bankStatus: '', kycStatus: '', invoiceExists: false },
  panImage: { path: '', data: '', mime: '' },
  showEkycModal: false,
};

export const kycSlice = createSlice({
  name: KYC_FEATURE_KEY,
  initialState: initialKycState,
  reducers: {
    updateKycData(state, action: PayloadAction<KycStatusResponse>) {
      state.kycData = action.payload;
    },
    updateKycSummary(state, action: PayloadAction<KycSummary>) {
      state.kycSummary = action.payload;
    },
    updatePanImage(state, action: PayloadAction<PanImage>) {
      state.panImage = action.payload;
    },
    updateEkycModalState(state, action: PayloadAction<boolean>) {
      state.showEkycModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      kycApi.endpoints.getKycDetails.matchFulfilled,
      (state, action) => kycSlice.caseReducers.updateKycData(state, action)
    );
  },
});

export const kycReducer = kycSlice.reducer;

export const {
  updateKycData,
  updateKycSummary,
  updatePanImage,
  updateEkycModalState,
} = kycSlice.actions;

export const getKycSliceState = (rootState: RootState): KycState =>
  rootState[KYC_FEATURE_KEY];

export const getKycData = createSelector(
  getKycSliceState,
  (state) => state.kycData
);

export const getKycSummary = createSelector(
  getKycSliceState,
  (state) => state.kycSummary
);

export const getKycStatus = createSelector(
  getKycSliceState,
  (state) => state.kycData.kycStatus
);

export const getPanImage = createSelector(
  getKycSliceState,
  (state) => state.panImage
);

export const getEkycModalState = createSelector(
  getKycSliceState,
  (state) => state.showEkycModal
);
