import { Api } from '../api/root-api';

import {
  CreateSchedulerRequest,
  CreateSchedulerResponse,
  SchedulersRequest,
  Scheduler,
  activeSchedulersRequest,
  activeSchedulersResponse,
} from './model';

export const schedulersApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    createScheduler: builder.mutation<
      CreateSchedulerResponse,
      CreateSchedulerRequest
    >({
      query(args) {
        return {
          url: `hub/reports/api/v1/scheduler/${args?.workspaceId}`,
          method: 'POST',
          body: args,
        };
      },
    }),

    getSchedulers: builder.query<Scheduler[], SchedulersRequest>({
      query(arg) {
        const { reportId, ...otherParams } = arg;
        return {
          url: `hub/reports/api/v1/scheduler/details/${reportId}`,
          params: otherParams,
          method: 'GET',
        };
      },
    }),

    activeSchedulers: builder.query<
      activeSchedulersResponse,
      activeSchedulersRequest
    >({
      query(arg) {
        const { schedulerId, workspaceId, ...otherParams } = arg;
        return {
          url: `hub/reports/api/v1/scheduler/generate/${schedulerId}/${workspaceId}`,
          method: 'GET',
          params: otherParams
        };
      },
    }),
  }),
});

export const {
  useCreateSchedulerMutation,
  useLazyGetSchedulersQuery,
  useLazyActiveSchedulersQuery
} = schedulersApi;
