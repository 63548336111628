import { createEntityAdapter } from '@reduxjs/toolkit';
import { RootState, store } from '../root';

export const sessionAdapter = createEntityAdapter<any>({
  selectId: (session) => session.id,
});

export const sessionSelectors = sessionAdapter.getSelectors(
  (state: RootState) => state.session
);

export const getAllSessionData = () => {
  return store.getState().sessionData;
};
