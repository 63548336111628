import { Api } from '../api/root-api';
import {
  PrincipalLedgerResponse,
  PrincipalLedgerRequestParams,
  ClaimRequestParams,
  Claim,
  OrderResponse,
  OrderRequest,
  CumulativeOrderResponse,
  CumulativeOrderRequest,
  CumulativeReturnResponse,
  CumulativeReturnsRequest,
  CumulativeCampaignsReq,
  CumulativeCampaignsRes,
  AllProductsRes,
  AllProductsReq,
  CumulativeSchemesRes,
  CumulativeSchemesReq,
} from './model';

export const homeApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    getPrincipalLedgerBalance: builder.query<
      PrincipalLedgerResponse,
      { workspaceId: string; params: PrincipalLedgerRequestParams }
    >({
      query(arg) {
        return {
          url: `ledgers/balance/${arg.workspaceId}`,
          method: 'GET',
          params: arg.params,
        };
      },
    }),
    getLatestClaim: builder.query<Claim, ClaimRequestParams>({
      query(params: ClaimRequestParams) {
        return {
          url: `ledger/claims/view/latest`,
          method: 'GET',
          params: params,
        };
      },
      transformResponse: (responseData: Claim[]) => {
        return responseData[0];
      },
    }),
    getLatestOrder: builder.query<OrderResponse, OrderRequest>({
      query(params: OrderRequest) {
        const { workspaceId, ...otherParams } = params;
        return {
          url: `hub/commerce-v2/orders/view/latest/${otherParams.sellerWorkspaceId}?sellerWorkspaceId=${otherParams.sellerWorkspaceId}`,
          method: 'GET',
          params: otherParams,
        };
      },
      transformResponse: (responseData: OrderResponse[]) => {
        return responseData[0];
      },
    }),
    getCumulativeOrders: builder.mutation<
      CumulativeOrderResponse,
      CumulativeOrderRequest
    >({
      query(arg) {
        return {
          url: `hub/commerce-v2/orders/cumulative/${arg.principalWorkspaceId}?sellerWorkspaceId=${arg.sellerWorkspaceId}`,
          method: 'POST',
          params: arg.params,
          body: arg.body,
        };
      },
    }),
    getCumulativeReturns: builder.mutation<
      CumulativeReturnResponse,
      CumulativeReturnsRequest
    >({
      query(arg) {
        return {
          url: `hub/claims/return-orders/cumulative/${arg.principalWorkspaceId}`,
          method: 'POST',
          params: arg.params,
          body: arg.body,
        };
      },
    }),
    getCumulativeCampaigns: builder.query<
      CumulativeCampaignsRes,
      CumulativeCampaignsReq
    >({
      query(arg) {
        return {
          url: `campaigns/cumulative/${arg.workspaceId}`,
          method: 'GET',
          headers: {
            defaultbypass: 'true',
          },
        };
      },
    }),
    getAllProducts: builder.mutation<AllProductsRes, AllProductsReq>({
      query(arg: AllProductsReq) {
        return {
          url: `hub/commerce-v2/allProducts/search/${arg.workspaceId}`,
          method: 'POST',
          params: arg.params,
          body: arg.body,
          // headers: {
          //   defaultbypass: true,
          // },
        };
      },
    }),
    getCumulativeSchemes: builder.mutation<
      CumulativeSchemesRes,
      CumulativeSchemesReq
    >({
      query(arg: CumulativeSchemesReq) {
        return {
          url: `hub/commerce-v2/scheme/cumulative/${arg.workspaceId}`,
          method: 'POST',
          body: arg.body,
          params: { sellerWorkspaceId: arg.workspaceId },
        };
      },
    }),
  }),
});

export const {
  useLazyGetPrincipalLedgerBalanceQuery,
  useLazyGetLatestClaimQuery,
  useLazyGetLatestOrderQuery,
  useGetCumulativeOrdersMutation,
  useGetCumulativeReturnsMutation,
  useLazyGetCumulativeCampaignsQuery,
  useGetAllProductsMutation,
  useGetCumulativeSchemesMutation,
} = homeApi;
