import { CfaCustomersColumnKey, ColumnConfig } from '../../model';

export const cfaCustomerConfig: Record<CfaCustomersColumnKey, ColumnConfig> = {
  customerName: { name: 'Firm Name', field: 'avatarNameStatus' },
  status: {
    name: 'Status',
    field: 'customerListStatusTag',
  },
  customerCode: { name: 'Customer Code' },
  mobileNumber: {
    name: 'Mobile Number',
    field: 'mobileNumber',
  },
  // gstNumber: {
  //   name: 'GST Number',
  //   field: 'gstNumber',
  //   isVisible: true,
  // },
  inCart: {
    name: 'In Cart',
    field: 'textWithImage',
    isVisible: true,
  },
  cfaAction: {
    name: '',
    field: 'cfaCustomersListOptions',
    alignment: 'flex-end',
    permissions: ['order_write'],
  },
};
