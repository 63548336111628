import { ColumnConfig, CFAListColumnKey } from '../model';

export const listCfaConfig: Record<CFAListColumnKey, ColumnConfig> = {
  cfaName: {
    name: 'CFA Name',
    field: 'avatarNameStatus',
  },
  status: {
    name: 'Status',
    field: 'customerListStatusTag',
  },
  mobile: {
    name: 'Mobile Number',
  },
  cfaCode: {
    name: 'CFA Code',
    sortField: 'fulfillmentLocationCode',
  },
  city: {
    name: 'City',
  },
  state: {
    name: 'State',
  },
  pincode: {
    name: 'Pin Code',
    sortField: 'postalCode',
  },
  divisions: {
    name: 'No of Divisions',
  },
  customers: {
    name: 'No. of Customers',
  },
  options: {
    name: '',
    field: 'component',
  },
};
