import { Channel } from '../channel';

export interface CreateIssueThread {
  sellerWorkspaceId: string;
  messageId: string;
  description?: string;
  message?: string;
  customerInviteIds?: string[];
  customerId?: string;
  teamMembers?: IssueTeamMember[];
  channelId?: string;
  entityType?: string;
}

interface IssueTeamMember {
  inviteId: string;
  issueRole: IssueRole;
  role: ThreadMemberRole;
}

export enum IssueRole {
  ASSIGNED = 'assigned',
  WATCHER = 'watcher',
  CREATOR = 'creator',
}

export enum ThreadMemberRole {
  ADMIN = 'admin',
  MEMBER = 'member',
  CUSTOMER_ADMIN = 'customerAdmin',
}

export interface Thread {
  unreadMessageCount: number;
  description: any;
  latestMessage: any;
  channelType: string;
  channelSubType: string;
  channelName: string;
  messageId: string;
  workspaceId: string;
  companyName: string;
  customerId?: string;
  folderId?: string;
  id: string;
  createdAt: string;
  updatedAt: string;
  isDefault: boolean;
  channelId?: string;
  channelMembers: ThreadMember[];
}

export interface ThreadMember {
  addedBy: string;
  channelId: string;
  userId: string;
  teamMemberId?: string;
  id: string;
  createdAt: string;
  updatedAt: string;
  addedDate: string;
  isArchive: boolean;
  isRemoved: boolean;
  hasLeft: boolean;
  isEmailNotificationEnabled: boolean;
  isSmsNotificationEnabled: boolean;
  isAppNotificationEnabled: boolean;
  isWebNotificationEnabled: boolean;
  customerId?: string;
}

export interface SearchThreadsResponse {
  teamMembers: ThreadMemberItem[];
  customerThreads?: SearchCustomerItem[];
  groupsAndIssueThreads: ThreadItem[];
}

interface SearchCustomerItem {
  channel_channelSubType: null;
  inviteId: string;
  phone: string;
  channelId: string | null;
  channelName: string | null;
  createdAt: string;
  firstName: null;
  lastName: null;
  distributorCode: string;
  companyName: string;
  spaceName: string;
}

interface ThreadItem {
  id: string;
  createdAt: string;
  channelName: string;
  channelSubType: string;
  channelMembers: ThreadChannelMember[];
}

interface ThreadMemberItem {
  inviteId: string;
  inviteStatus: string;
  inviteType: string;
  userEmail: string;
  userFirstName: string;
  userId: string;
  userLastName: string;
  userMobile: string;
}

interface ThreadChannelMember {
  id: string;
  userId: string;
}

export interface UpdateThreadStateResponse {
  id: string;
  isActive: boolean;
  issueStatus: IssueStatus;
  customerId: null;
  workspaceId: null;
  folderId: null;
  updatedAt: string;
}

export interface CreateCustomerThreadResonse {
  id: string;
  phone: string;
  senderUserId: string;
  senderWorkspaceId: string;
  customerDetails: CustomerDetails;
  status: string;
  createdDate: string;
  updatedDate: string;
  customerGroups: CustomerGroups;
  customerThread: CustomerThread;
}
interface CustomerThread {
  customerId: string;
  workspaceId: string;
  channelType: string;
  channelName: string;
  enableWhatsappNotification: boolean;
  folderId: string | null;
  id: string;
  createdAt: string;
  updatedAt: string;
  isDefault: boolean;
}
interface CustomerGroups {
  // Todo
}

interface CustomerDetails {
  id: string;
  inviteId: string;
  name: string;
  email: string;
  companyName: string;
  gstin: string;
  businessPAN: string;
  distributorCode: string;
  postalCode: string;
  physicalAddress: PhysicalAddress;
  shippingAddress: ShippingAddress;
  distributorChannel: string;
  CSTNumber: string;
  telephone: string;
  token: string;
  cityId: string;
  districtId: string;
  cityName: string;
  createdDate: string;
  updatedDate: string;
  dlExpiry: string;
  dlNumber: string;
  grossCreditLimit: number;
  creditLimitPeriod: number;
  tags: string;
  fulfillmentLocationCode: string;
  countryId: string;
  stateId: string;
}

interface PhysicalAddress {
  address1: string;
  address2: string;
}

interface ShippingAddress {
  shippingAddress1: string;
  shippingAddress2: string;
}

interface CreateCustomerThreadBody {
  phone: string;
  senderWorkspaceId: string;
  isActive: boolean;
  customerDetails: {
    name: string;
    companyName: string;
    distributorCode: string;
  };
}
export interface CreateCustomerThreadRequest {
  workspaceId: string;
  body: CreateCustomerThreadBody[];
}

export interface CreateThread {
  sellerWorkspaceId: string;
  inviteIds: string[];
}

export interface filterCustomerThreadRequest {
  channelType: string;
  teamMemberIds: string[];
  inviteId: string;
  sellerWorkspaceId: string;
}

export interface filterCustomerThreadResponse {
  id: string;
  channelName: string;
}

export enum IssueStatus {
  ASSIGNED = 'assigned',
  OPEN = 'open',
  CLOSED = 'closed',
}

export interface GetThreadFiltersRequest {
  workspaceId: string;
  body: {
    channelType: string;
  };
  params: {
    sellerWorkspaceId: string;
  };
}

export interface GetThreadFiltersResponse {
  cfas: {
    id: string;
    cfaCode: string;
    name: string;
  }[];
  divisions: {
    id: string;
    code: string;
    name: string;
  }[];
  members: {
    inviteId: string;
    employeeName?: string;
    employeeCode: string;
  }[];
  status: CustomerThreadStatusFilter;
  issueStatus?: string[];
  customers: {
    inviteId: string;
    distributorCode: string;
    name: string;
  }[];
  transactionType?: string[];
  customerSegment: CustomerThreadTreeTypeFilter;
  routes: CustomerThreadTreeTypeFilter;
}

export interface CustomerThreadStatusFilter {
  invoice: string[];
  payment: string[];
  dispatch: string[];
  campaign: string[];
  priceList: string[];
  orderStatus: string[];
}

export interface ApplyThreadsFilterRequest {
  workspaceId: string;
  body: ApplyThreadsFilterBodyRequest;
  params: {
    sellerWorkspaceId: string;
  };
}

export interface ApplyThreadsFilterBodyRequest {
  channelType: string;
  teamMemberIds?: any[];
  divisionIds?: any[];
  cfaIds?: any[];
  status?: ApplyThreadsFilterStatusRequest;
  customers?: string[];
  routes: string[];
  customerSegment?: string[];
}

export interface ApplyThreadsFilterStatusRequest {
  invoice: string[];
  payment: string[];
  campaign: string[];
  dispatch: string[];
  order: string[];
  priceList: string[];
  transactionType?: string[];
}

export interface ApplyThreadsFilterResponse {
  id: string;
  channelName: string;
}

export interface ThreadsFitlers {
  transactionStatus: {
    campaign: string[];
    dispatch: string[];
    invoice: string[];
    order: string[];
    payment: string[];
    priceList: string[];
  };

  teamMember: string[];
  customer: string[];
  cfaList: string[];
  divisionList: string[];
  issueThreadTransaction: string[];
  filteredChannels: string[];
  routes: string[];
  customerSegment: string[];
  others: {
    Type: string[];
    Status: string[];
    Dispatch: string[];
    Whatsapp: string[];
  };
}

export interface CustomerThreadTreeTypeFilter {
  folders: FilterFolder[];
  rootChannels: Channel[];
}

export interface FilterFolder {
  channels: Channel[];
  colorCode: string;
  id: string;
  name: string;
  shadeVariation: string;
  subFolders: FilterFolder[];
}

export const threadsFiltersInitialState = {
  transactionStatus: {
    campaign: [],
    dispatch: [],
    invoice: [],
    order: [],
    payment: [],
    priceList: [],
  },

  teamMember: [],
  cfaList: [],
  divisionList: [],
  customer: [],
  customerSegment: [],
  routes: [],
  issueThreadTransaction: [],
  filteredChannels: [],
  others: {
    Type: [],
    Status: [],
    Dispatch: [],
    Whatsapp: [],
  },
};
