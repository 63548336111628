import { OrderImportSourceEnum, OrderStatusEnum } from '../helpers';
export interface Event {
  id: string;
  date: string;
  profilePic: string;
  userName: string;
  headerSubText: string;
  entity: string;
  eventType: string;
  workspaceId?: string;
  isRead?: boolean;
  importSource?: OrderImportSourceEnum;
  addInfo:
    | PaymentAddInfo
    | OrderAddInfo
    | CustomerAddInfo
    | InvoiceAddInfo
    | LedgerAddInfo
    | SettlementAddInfo
    | ClaimAddInfo;
}
export interface Seller {
  workspaceId: string;
  phone: string;
  name: any;
  shortName: string;
  address: any;
}
export interface PaymentAddInfo {
  allocatedAmount: number;
  narration: string;
  id: string;
  createdDate: string;
  proofOfPayment: any;
  approvedBy: any;
  cardNumber: any;
  buyerWorkspaceId: string;
  paymentRefCode: string;
  upiId: any;
  bankName: any;
  cardNetwork: any;
  instrumentNumber: any;
  channel: any;
  source: string;
  currencyCode: string;
  amountPaid: number;
  paymentType: string;
  createdBy: string;
  status: string;
  bankReference: any;
  updatedDate: string;
  sellerWorkspaceId: string;
  paymentProvider: any;
  teamMemberId: string;
  inviteId: string;
  bankAccountNumber: any;
  cardType: any;
  instrumentDate: any;
  bankCode: any;
  memberInfo: MemberInfo;
  paymentMode: string;
  clientInfo: ClientInfo;
  paymentDate: string;
}
export interface MemberInfo {
  mobile: string;
  userId: any;
  inviteStatus: string;
  id: string;
  userRole: string;
  createdDate: string;
  updatedDate: string;
  customerGroups: any[];
  name: string;
  senderWorkspace: SenderWorkspace;
}

export interface SenderWorkspace {
  companyName: string;
  id: string;
  spaceName: string;
  profilePic: string;
  userName: string;
  location: string;
  address: string;
  userId: string;
  createdDate: string;
  updatedDate: string;
  updatedBy: string;
  timeZone: string;
  defaultCurrencyCode: string;
  isoLanguageCode: string;
  isoCountryCode: string;
  code: string;
  isSeller: number;
  isBuyer: number;
}
export interface OrderAddInfo {
  inviteId: string;
  billingRefNumber: string;
  totalWithTax: string;
  suggestedOrderNumber: string;
  erpOrderNumber: string;
  refOrderNumber: string;
  generationType: string;
  orderDate: string;
  itemCount: number;
  status: string;
  orderId: number;
  principalWorkspaceId: string;
  channelId: string;
  channelName: string;
  eventTimestamp: string;
  orderType: OrderStatusEnum;
  poNumber: string;
}
export interface InvoiceAddInfo {
  total: number;
  success: number;
  failed: number;
  linkCount: number;
  partyType: string;
}
export interface CustomerAddInfo {
  total: number;
  success: number;
  failed: number;
  linkCount: number;
  partyType: string;
}
export interface LedgerAddInfo {
  partyType: string;
  partyWorkspaceId: string;
}

export interface SettlementAddInfo {
  paymentSettledCnt: number;
  settlementAmount: number;
  settlementUtr: number;
  transferId: number;
  paymentId: string;
  inviteId: string;
}

export interface ClaimAddInfo {
  returnOrderNumber: string;
  returnOrderId?: number;
  claimCode: string;
  claimLockTime: string;
  inviteId: string;
  claimedAmount: number;
  claimActivityCount: number;
  status: string;
  claimId: string;
  totalAmount: number;
  taxAmount: number;
  claimYear: string;
  claimMonth: string;
  claimSubmissionDate: any;
  principalWorkspaceId: string;
  channelId: string;
  channelName: string;
  nonClaimedActivityCount: number;
  claimedActivityCount: number;
  invoiceRefNumber: any;
}
export interface ClientInfo {
  status: string;
  id: string;
  seller: Seller;
  buyer: Buyer;
  inviteId: string;
}
export interface Buyer {
  workspaceId: string;
  phone: string;
  customerCode: string;
  name: string;
  address: any;
}
export interface Seller {
  workspaceId: string;
  phone: string;
  name: any;
  shortName: string;
  address: any;
}

export interface Seller2 {
  workspaceId: string;
  name: string;
  shortName: string;
  address: any;
  phone: string;
}

export interface Buyer2 {
  workspaceId: any;
  phone: string;
  customerCode: any;
}

export enum EntityType {
  Customer = 'customer',
  Invoice = 'invoice',
  Order = 'order',
  Payment = 'payment',
  Claim = 'claim',
  Ledger = 'ledger',
  Settlement = 'settlement',
  Return = 'return',
}

export interface EventRequestParams {
  pageNo: number;
  startDate: string;
}

export enum EntityEnum {
  INVOICE = 'invoice',
  ORDER = 'order',
  LEDGER = 'ledger',
  CUSTOMER = 'customer',
  CHEQUE = 'cheque',
  PRODUCT = 'product',
  CLAIM = 'claim',
  EINVOICE = 'eInvoice',
  PAYMENT = 'payment',
  SETTLEMENT = 'settlement',
  USER = 'user',
  INVITE = 'invite',
  INVITE_DETAILS = 'inviteDetails',
  GROUP = 'group',
  CUSTOMER_GROUP = 'customerGroup',
  TEAM_GROUP = 'teamGroup',
  SUPPORT_TICKET = 'supportTicket',
}

export enum EventTypeEnum {
  CREATE = 'create',
  UPDATE = 'update',
  DELETE = 'delete',
  RETRY = 'retry',
  CANCEL = 'cancel',
  COMPLETED = 'completed',
  AWAITING = 'awaiting',
  SUPPORT_TICKET_CREATE = 'createSupportTicket',
}
