import { ChatActionCreateOrderKey, ColumnConfig } from '../../model';

export const chatActionCreateOrderConfig: Record<
  ChatActionCreateOrderKey,
  ColumnConfig
> = {
  skuCode: {
    name: 'SKU Code',
    isCheckboxField: true,
    field: 'checkboxNavigate',
  },
  productTitle: { name: 'Product Title', field: 'multiLine' },
  price: { name: 'price', alignment: 'flex-end', sortField: 'price' },
  addToCart: {
    name: '',
    field: 'addToCart',
    permissions: ['order_write'],
  },
};
