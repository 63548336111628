import { store } from '../../root';
import { ListInputItemOptions } from '../model';

export const mapDataToInputItems = (
  data: any,
  idKey: string,
  valueKey: string,
  selected?: string
) => {
  return data?.map((x: any) => ({
    id: x?.[idKey] || '',
    value: x?.[valueKey] || '',
    selected: selected ? x?.[selected] || false : false,
  }));
};

export const mapDataToInputItemsFromAppliedFilter = (
  data: any,
  idKey: string,
  valueKey: string,
  filterKey: string
) => {
  const appliedFilters = store.getState()?.filtersData?.appliedFilters;
  const key = filterKey as ListInputItemOptions;
  const filterInputs = appliedFilters?.listInputItem?.[key] || [];
  const selectedItems = filterInputs
    .filter((x) => x.selected)
    ?.map((x) => x.id);
  return data?.map((x: any) => ({
    id: x?.[idKey] || '',
    value: x?.[valueKey] || '',
    selected: selectedItems.includes(x?.[idKey] || ''),
  }));
};
