import { Api } from '../api/root-api';
import { getConfigValue } from '@zonofi/common';
import {
  BatchesListRequest,
  BatchesListResponse,
  UploadCSVFileRequest,
  UploadResponse,
} from './model';

export const importsApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    getBatchesList: builder.query<BatchesListResponse, BatchesListRequest>({
      query(params: BatchesListRequest) {
        return {
          url: `${getConfigValue('API_ENDPOINT')}api/batches/list`,
          method: 'GET',
          params: params,
        };
      },
    }),
    getBatchLogs: builder.query<
      any,
      {
        batchId: string;
        pageNo?: number;
        workspaceId: string;
      }
    >({
      query(arg) {
        const { workspaceId, ...params } = arg;
        return {
          url: `${getConfigValue('API_ENDPOINT')}api/batches/logs`,
          method: 'GET',
          params: params,
          headers: {
            module: 'integration',
            workspaceid: workspaceId,
          },
        };
      },
    }),
    getBulkBatchLogs: builder.query<
      any,
      {
        batchId: string;
        pageNo?: number;
        includeRecords?: boolean;
        workspaceId: string;
      }
    >({
      query(arg) {
        const { workspaceId, ...params } = arg;
        return {
          url: `${getConfigValue('API_ENDPOINT')}api/batches/bulk/logs`,
          method: 'GET',
          params: params,
          headers: {
            module: 'integration',
            workspaceid: workspaceId,
          },
        };
      },
    }),
    uploadCSVFile: builder.mutation<UploadResponse, UploadCSVFileRequest>({
      query(payload: UploadCSVFileRequest) {
        const { uploadType, ...params } = payload;
        return {
          url: `${getConfigValue('API_ENDPOINT')}api/upload/${uploadType}`,
          method: 'POST',
          headers: {
            workspaceId: params.workspaceId,
            module: 'integration',
          },
          body: params,
        };
      },
    }),
  }),
  overrideExisting: true,
});

export const {
  useLazyGetBatchesListQuery,
  useLazyGetBatchLogsQuery,
  useLazyGetBulkBatchLogsQuery,
  useUploadCSVFileMutation,
} = importsApi;
