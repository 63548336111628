import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { isEmpty, isUndefined } from 'lodash';
import { RootState } from '../root';
import { Location } from '../cfa';

export interface sellerActionState {
  allSelectedItem: Location[];
  loader: boolean;
  extraData: {
    originalMode: string;
    paymentType: string;
  };
}

export const initialSellerActionState: sellerActionState = {
  allSelectedItem: [],
  loader: false,
  extraData: {
    originalMode: '',
    paymentType: '',
  },
};

const SELLER_ACTION_FEATURE_KEY = 'sellerAction';

export const sellerActionSlice = createSlice({
  name: SELLER_ACTION_FEATURE_KEY,
  initialState: initialSellerActionState,
  reducers: {
    updateAllSelectedItem(state, action: PayloadAction<any>) {
      if (!isEmpty(action.payload)) {
        const item = action.payload;
        const itemExists = state.allSelectedItem.some(
          (existingItem: { id: any }) => existingItem.id === item.id
        );

        if (itemExists) {
          state.allSelectedItem = state.allSelectedItem.filter(
            (existingItem: { id: any }) => existingItem.id !== item.id
          );
        } else {
          state.allSelectedItem.push(item);
        }
      } else {
        state.allSelectedItem = [];
      }
    },
    updateLoaderState(state, action: PayloadAction<boolean>) {
      state.loader = action.payload;
    },
    updateExtraData(
      state,
      action: PayloadAction<{ originalMode: string; paymentType: string }>
    ) {
      if (!isEmpty(action.payload) || !isUndefined(action.payload)) {
        const { originalMode, paymentType } = action.payload;

        state.extraData.originalMode = originalMode;
        state.extraData.paymentType = paymentType;
      } else {
        state.extraData.originalMode = '';
        state.extraData.paymentType = '';
      }
    },
  },
});

export const sellerActionReducer = sellerActionSlice.reducer;

export const { updateAllSelectedItem, updateLoaderState, updateExtraData } =
  sellerActionSlice.actions;

const getsellerActionState = (rootState: RootState): sellerActionState =>
  rootState[SELLER_ACTION_FEATURE_KEY];

export const getAllSelectedItems = createSelector(
  getsellerActionState,
  (state) => state?.allSelectedItem
);

export const getLoaderState = createSelector(
  getsellerActionState,
  (state) => state.loader
);

export const getOriginalMode = createSelector(
  getsellerActionState,
  (state) => state.extraData.originalMode
);

export const getPaymentType = createSelector(
  getsellerActionState,
  (state) => state.extraData.paymentType
);
