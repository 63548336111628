import React from 'react';
import { colors } from '@zonofi/shared';
import { hash } from '@zonofi/zono-money-helpers';
import { ProductVariantCategory } from '@zonofi/zono-money-store';

export const CategoryTag: React.FC<{ category: ProductVariantCategory }> = ({
  category,
}) => {
  const tagColors = [
    { background: '#CEEAF9', color: '#164B68' },
    { background: '#FFEBC2', color: '#734D00' },
    { background: '#F4ECFF', color: '#3F1B73' },
    { background: '#EFFBDA', color: '#7AA049' },
    { background: '#DBD1F5', color: '#24118A' },
    { background: '#F6DDD4', color: '#A38E4E' },
    { background: '#FDF6D6', color: '#5E9A75' },
  ];
  const selectedColor =
    tagColors[hash(category?.categoryName, tagColors.length)];
  return (
    <div
      className="category-tag"
      style={{
        backgroundColor: selectedColor?.background,
      }}
    >
      <span
        className="category-tag-text font-primary-semibold"
        style={{
          color: selectedColor?.color,
        }}
      >
        {category?.categoryName}
      </span>
    </div>
  );
};
