import { ChatActionViewCampaignColumnKey, ColumnConfig } from '../../model';

export const chatActionViewCampaignsConfig: Record<
  ChatActionViewCampaignColumnKey,
  ColumnConfig
> = {
  campaignName: {
    name: 'Campaign Name',
    field: 'component',
  },
  type: {
    name: 'Type',
  },
  view: {
    name: 'View',
  },
  createdDate: {
    name: 'Created Date',
    alignment: 'flex-start',
  },
  status: {
    name: 'status',
    isComponent: true,
  },
  products: { name: 'Products', alignment: 'flex-end' },
  action: {
    name: '',
    alignment: 'flex-end',
    field: 'component',
  },
};
