import './gst-screen.scss';
import React from 'react';

import { useGst } from './useGst';
import { GstRegistration } from './GstRegistration/GstRegistration';
import { GstKyc } from './GstKyc/GstKyc';
import { SuccessMsg } from './SuccessMsg/SuccessMsg';

export const GstScreen = () => {
  const {
    pageNumber,
    GstNumber,
    setGstNumber,
    handleNext,
    error,
    gstFormData,
    handleInputFiled,
    noGst,
    setGst,
    setPageNumber,
    verifyValidGst,
    kycError,
  } = useGst();

  function renderPage() {
    switch (pageNumber) {
      case 0:
        return (
          <GstRegistration
            GstNumber={GstNumber}
            setGstNumber={setGstNumber}
            handleNext={handleNext}
            errorMsg={error}
            handleNoGst={() => {
              setGst(true);
              setPageNumber(1);
            }}
          />
        );

      case 1:
        return (
          <GstKyc
            gstFormData={gstFormData}
            handleInputFiled={handleInputFiled}
            handleNext={handleNext}
            errorMsg={error}
            noGst={noGst}
            verifyValidGst={verifyValidGst}
            kycError={kycError}
          />
        );

      case 2:
        return <SuccessMsg />;

      default:
        return <div>sdfdf</div>;
    }
  }
  return <div>{renderPage()}</div>;
};
