import { Api } from '../api';
import {
  CategoriesResponse,
  CategoriesRequest,
  CreateCategoriesResponse,
  CreateCategoriesRequest,
  MapProductCategoryRequest,
  MapProductCategoryResponse,
  UpdateCategoriesResponse,
  UpdateCategoriesRequest,
  assignCategoryToProductResponse,
  assignCategoryToProductRequest,
  removeCategoryResponse,
  removeCategoryRequest,
  unmapCategoryFromProductResponse,
  unmapCategoryFromProductRequest,
} from './model';

export const categoryApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    getCategories: builder.mutation<CategoriesResponse, CategoriesRequest>({
      query(arg) {
        const { workspaceId, ...remainParams } = arg;
        const { sellerWorkspaceId, ...body } = remainParams;

        return {
          url: `hub/commerce-v2/categories/${workspaceId}`,
          method: 'POST',
          params: { sellerWorkspaceId },
          body: body,
        };
      },
    }),
    createCategories: builder.mutation<
      CreateCategoriesResponse,
      CreateCategoriesRequest
    >({
      query(arg) {
        const { workspaceId, ...remainParams } = arg;
        const { sellerWorkspaceId, ...body } = remainParams;
        return {
          url: `hub/commerce-v2/category/${workspaceId}`,
          body: body,
          params: { sellerWorkspaceId },
          method: 'POST',
        };
      },
    }),
    updateCategories: builder.mutation<
      UpdateCategoriesResponse,
      UpdateCategoriesRequest
    >({
      query(arg) {
        const { workspaceId, ...remainParams } = arg;
        const { sellerWorkspaceId, ...body } = remainParams;
        return {
          url: `hub/commerce-v2/category/update/${workspaceId}`,
          body: body,
          params: { sellerWorkspaceId },
          method: 'POST',
        };
      },
    }),
    mapProductCategory: builder.mutation<
      MapProductCategoryResponse,
      MapProductCategoryRequest
    >({
      query(arg) {
        const { workspaceId, ...remainParams } = arg;
        const { sellerWorkspaceId, ...body } = remainParams;
        return {
          url: `hub/commerce-v2/category/map-products-to-categories/${workspaceId}`,
          body: body,
          params: { sellerWorkspaceId },
          method: 'POST',
        };
      },
    }),
    assignCategoryToProduct: builder.mutation<
      assignCategoryToProductResponse,
      assignCategoryToProductRequest
    >({
      query(arg) {
        const { workspaceId, ...remainParams } = arg;
        const { sellerWorkspaceId, ...body } = remainParams;
        return {
          url: `hub/commerce-v2/category/modify-assignment-to-products/${workspaceId}`,
          body: body,
          params: { sellerWorkspaceId },
          method: 'POST',
        };
      },
    }),
    removeCategory: builder.mutation<
      removeCategoryResponse,
      removeCategoryRequest
    >({
      query(arg) {
        const { workspaceId, ...remainParams } = arg;
        return {
          url: `hub/commerce-v2/category/delete-category/${workspaceId}`,
          params: remainParams,
          method: 'DELETE',
        };
      },
    }),
    unmapCategoryFromProduct: builder.mutation<
      unmapCategoryFromProductResponse,
      unmapCategoryFromProductRequest
    >({
      query(arg) {
        const { workspaceId, ...remainParams } = arg;
        const { sellerWorkspaceId, ...body } = remainParams;
        return {
          url: `hub/commerce-v2/category/unmap-products-to-categories/${workspaceId}`,
          params: { sellerWorkspaceId },
          body: body,
          method: 'POST',
        };
      },
    }),
  }),
});

export const {
  useGetCategoriesMutation,
  useCreateCategoriesMutation,
  useMapProductCategoryMutation,
  useUpdateCategoriesMutation,
  useAssignCategoryToProductMutation,
  useRemoveCategoryMutation,
  useUnmapCategoryFromProductMutation,
} = categoryApi;
