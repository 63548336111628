import { createSlice } from '@reduxjs/toolkit';
import { eventsApi } from '../events';
import _forEach from 'lodash/forEach';

export const SESSION_FEATURE_KEY = 'sessionData';

export const initialSessionState: any = {};

export const sessionSlice = createSlice({
  name: SESSION_FEATURE_KEY,
  initialState: initialSessionState,
  reducers: {
    writeSessionData(state, action) {
      if (action.payload instanceof Array) {
        const tempState = { ...state };
        _forEach(action.payload, (item) => {
          const { param, value } = item;
          //@ts-ignore
          if (tempState[`${param}`] !== value) {
            //@ts-ignore
            tempState[`${param}`] = value;
          }
        });
        return tempState;
      }
      const { param, value } = action.payload;
      const tempState = { ...state };
      //@ts-ignore
      if (tempState[`${param}`] !== value) {
        //@ts-ignore
        tempState[`${param}`] = value;
        return tempState;
      }
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(eventsApi.endpoints.getEvents.matchPending, (state) => {
      const tempState = { ...state };
      const param = 'isEventsFetching';
      const value = true;
      //@ts-ignore
      if (tempState[`${param}`] !== value) {
        //@ts-ignore
        tempState[`${param}`] = value;
        return tempState;
      }
      return;
    });
    builder.addMatcher(
      eventsApi.endpoints.getEvents.matchFulfilled,
      (state) => {
        const tempState = { ...state };
        const param = 'isEventsFetching';
        const value = false;
        //@ts-ignore
        if (tempState[`${param}`] !== value) {
          //@ts-ignore
          tempState[`${param}`] = value;
          return tempState;
        }
        return;
      }
    );
  },
});

export const sessionReducer = sessionSlice.reducer;

export const { writeSessionData } = sessionSlice.actions;
