import {
  CreatePaymentRequest,
  CreatePaymentResponse,
  PaymentLinkResponse,
  PaymentsRequest,
  PaymentsResponse,
  RecordPaymentRequest,
  RecordPaymentResponse,
  GetPaymentResponse,
  OfflinePaymentDetails,
  PaymentDetailsResponse,
  PaymentDetailsRequest,
  PaymentIdRequest,
  PaymentSummaryDetailsRequest,
  PaymentSummaryDetailsResponse,
  CustomerSearchRecordsResponse,
  CustomerSearchRecordsRequest,
  Settlements,
  SettlementRequest,
  ReceivedPaymentsReponse,
  ReceivedPaymentsRequest,
  PaymentTransaction,
  RecivedPayment,
  UpdatePaymentResponse,
  UpdatePaymentRequest,
  PaymentModeGraphResponse,
  GraphRequest,
  CustomerTransactionsGraphResponse,
  PaymentPreferencesResponse,
  AmountCollectedBySalesManRes,
  AmountCollectedBySalesManReq,
  CreatePaymentTransactionResponse,
  CreatePaymentTransactionRequest,
} from './model';
import { Api } from '../api/root-api';
import _ from 'lodash';
import { getQueryParams } from '../helpers';
import { updateReceivedPaymentsData } from './slice';
import { getConfigValue } from '@zonofi/common';

export const paymentApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    createPaymentWithOutToken: builder.mutation<
      CreatePaymentResponse,
      { CreatePaymentRequest: CreatePaymentRequest; env?: string }
    >({
      query: (arg) => {
        const { CreatePaymentRequest, env } = arg;
        return {
          url: 'invoiceservice/payments/paymentrequest',
          body: CreatePaymentRequest,
          params: { env },
          method: 'POST',
        };
      },
    }),
    getPayment: builder.query<GetPaymentResponse, string>({
      query: (id) => ({
        url: `invoiceservice/payments/paymentrequest`,
        method: 'GET',
        params: { paymentRequestId: id },
      }),
    }),
    getOfflinePaymentDetails: builder.query<OfflinePaymentDetails, string>({
      query: (id) => ({
        url: `invoiceservice/payments/find`,
        method: 'GET',
        params: { paymentId: id },
      }),
    }),
    getPaymentLink: builder.query<
      PaymentLinkResponse,
      { inviteId: string; workspaceId: string }
    >({
      query(args) {
        const { inviteId, workspaceId } = args;

        return {
          url: `invoiceservice/party/shortlink/${workspaceId}`,
          method: 'GET',
          params: { inviteId },
        };
      },
    }),
    getCustomerPayments: builder.mutation<PaymentsResponse, PaymentsRequest>({
      query(params: PaymentsRequest) {
        const queryParams = getQueryParams(params);
        return {
          url: `invoiceservice/payments?${queryParams}`,
          method: 'GET',
          ...(params?.isCsv
            ? { responseHandler: (response) => response.text() }
            : {}),
        };
      },
    }),
    getPaymentDetails: builder.query<
      PaymentDetailsResponse,
      PaymentDetailsRequest
    >({
      query(params: PaymentDetailsRequest) {
        const queryParams = getQueryParams(params);
        return {
          url: `invoiceservice/payments/aggregated?${queryParams}`,
          method: 'GET',
        };
      },
    }),

    getIndividualPayments: builder.query<
      OfflinePaymentDetails,
      PaymentIdRequest
    >({
      query(PaymentIdRequest: PaymentIdRequest) {
        return {
          url: `invoiceservice/payments/find`,
          method: 'GET',
          params: PaymentIdRequest,
        };
      },
    }),
    recordPayment: builder.mutation<
      RecordPaymentResponse,
      RecordPaymentRequest
    >({
      query: (RecordPaymentRequest) => {
        return {
          url: 'invoiceservice/payments',
          body: RecordPaymentRequest,
          method: 'POST',
        };
      },
    }),
    paymentSummaryDetails: builder.query<
      PaymentSummaryDetailsResponse,
      PaymentSummaryDetailsRequest
    >({
      query(PaymentSummaryDetailsRequest) {
        return {
          url: `invoiceservice/reports/payments/summary`,
          method: 'GET',
          params: PaymentSummaryDetailsRequest,
        };
      },
    }),
    getCustomerSearchRecords: builder.query<
      CustomerSearchRecordsResponse,
      CustomerSearchRecordsRequest
    >({
      query(params: CustomerSearchRecordsRequest) {
        const { workspaceId, ...updatedParams } = params;
        const { searchKey, ...sepratedParams } = updatedParams;

        return !_.isUndefined(params.searchKey)
          ? {
              url: `customers/${workspaceId}`,
              method: 'GET',
              params: updatedParams,
            }
          : {
              url: `customers/${workspaceId}`,
              method: 'GET',
              params: sepratedParams,
            };
      },
    }),
    getPaymentSettlements: builder.query<Settlements, SettlementRequest>({
      query(arg) {
        return {
          url: `invoiceservice/payments/settlements`,
          params: arg,
          method: 'GET',
        };
      },
    }),
    getReceivedPayments: builder.query<
      ReceivedPaymentsReponse,
      ReceivedPaymentsRequest
    >({
      query(arg) {
        const queryParams = getQueryParams(arg);
        return {
          url: `invoiceservice/payments/received/?${queryParams}`,
          method: 'GET',
        };
      },
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(updateReceivedPaymentsData(data));
        } catch (err) {
          console.log(err, 'dispatchErr');
        }
      },
    }),
    submitReceivedPayments: builder.mutation<any, any>({
      query(arg) {
        return {
          url: 'invoiceservice/payments/received',
          params: arg,
          method: 'POST',
        };
      },
    }),
    getRecievedPaymentDetails: builder.query<
      RecivedPayment,
      { paymentReceivedId: string }
    >({
      query(arg) {
        return {
          url: 'invoiceservice/payments/received/details',
          params: arg,
          method: 'GET',
        };
      },
    }),
    cancelPayment: builder.mutation<
      PaymentTransaction,
      {
        paymentTransactionId: string;
        inviteId: string;
      }
    >({
      query(arg) {
        return {
          url: 'invoiceservice/payments/cancel',
          params: arg,
          method: 'POST',
        };
      },
    }),
    downloadPayments: builder.query<any, PaymentsRequest>({
      query(params) {
        const queryParams = getQueryParams(params);
        return {
          url: `invoiceservice/payments/download?${queryParams}`,
          method: 'GET',
          responseHandler: (response) => {
            return response.arrayBuffer();
          },
        };
      },
    }),
    downloadPaymentDetails: builder.query<any, string>({
      query(arg) {
        return {
          url: `invoiceservice/payments/download-payment-details?paymentId=${arg}`,
          method: 'GET',
          responseHandler(response) {
            return response.arrayBuffer();
          },
        };
      },
    }),
    updatePayment: builder.mutation<
      UpdatePaymentResponse,
      UpdatePaymentRequest
    >({
      query(arg) {
        return {
          url: 'invoiceservice/payments/edit',
          method: 'PUT',
          body: arg,
        };
      },
    }),
    getPaymentModeGraphData: builder.query<
      PaymentModeGraphResponse,
      GraphRequest
    >({
      query(params) {
        return {
          url: `${getConfigValue(
            'API_ENDPOINT'
          )}api/reports/get-daily-transanctions-by-paymentmode`,
          method: 'GET',
          params: params,
        };
      },
    }),
    getCustomerTransactionsGraphData: builder.query<
      CustomerTransactionsGraphResponse,
      GraphRequest
    >({
      query(params) {
        return {
          url: `${getConfigValue(
            'API_ENDPOINT'
          )}api/reports/get-daily-transanctions-by-customer`,
          method: 'GET',
          params: params,
        };
      },
    }),
    getPaymentPreferences: builder.query<PaymentPreferencesResponse, any>({
      query(arg) {
        return {
          url: `invoiceservice/settings/paymentlink/${arg?.workspaceId}`,
          method: 'GET',
        };
      },
    }),
    updatePaymentPreferences: builder.mutation<PaymentPreferencesResponse, any>(
      {
        query(arg) {
          return {
            url: `invoiceservice/settings/paymentlink`,
            method: 'POST',
            body: arg,
          };
        },
      }
    ),
    getAmountCollectedBySalesMan: builder.query<
      AmountCollectedBySalesManRes,
      AmountCollectedBySalesManReq
    >({
      query(arg) {
        return {
          url: 'invoiceservice/payments/amount-collected',
          method: 'GET',
          params: arg,
        };
      },
    }),
    createPaymentTransaction: builder.mutation<
      CreatePaymentTransactionResponse,
      CreatePaymentTransactionRequest
    >({
      query(arg) {
        return {
          url: `invoiceservice/payment-transactions`,
          method: 'POST',
          body: arg,
        };
      },
    }),
  }),
});

export const {
  useCreatePaymentWithOutTokenMutation,
  useLazyGetPaymentLinkQuery,
  useGetPaymentLinkQuery,
  useGetCustomerPaymentsMutation,
  useRecordPaymentMutation,
  useLazyGetPaymentQuery,
  useLazyGetOfflinePaymentDetailsQuery,
  useLazyGetPaymentDetailsQuery,
  useLazyGetIndividualPaymentsQuery,
  useLazyPaymentSummaryDetailsQuery,
  useGetCustomerSearchRecordsQuery,
  useLazyGetCustomerSearchRecordsQuery,
  useLazyGetPaymentSettlementsQuery,
  useLazyGetReceivedPaymentsQuery,
  useLazyGetRecievedPaymentDetailsQuery,
  useCancelPaymentMutation,
  useLazyDownloadPaymentsQuery,
  useLazyDownloadPaymentDetailsQuery,
  useUpdatePaymentMutation,
  useSubmitReceivedPaymentsMutation,
  useLazyGetPaymentModeGraphDataQuery,
  useGetPaymentModeGraphDataQuery,
  useLazyGetCustomerTransactionsGraphDataQuery,
  useGetCustomerTransactionsGraphDataQuery,
  useLazyGetPaymentPreferencesQuery,
  useUpdatePaymentPreferencesMutation,
  useLazyGetAmountCollectedBySalesManQuery,
  useCreatePaymentTransactionMutation,
} = paymentApi;
