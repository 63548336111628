import { useEffect, useState } from 'react';
import {
  LineInOrder,
  POLineData,
  useDeleteFromActiveOrderMutation,
  useEditOrderMutation,
  useLazyGetCartQuery,
  useLazyGetCartSummaryQuery,
} from '@zonofi/zono-money-store';
import { OrderDataLine } from '../types/interfaces';

interface callCartAPIProps {
  workspaceId: string;
  customerId: string;
  sellerWorkspaceId: string;
  includeActiveOrders?: boolean;
  includeSummary?: boolean;
  importSource?: 'manual' | 'upload' | 'whatsapp';
}

interface SelectedLine extends OrderDataLine, LineInOrder {}

export const useCart = () => {
  const [getCartAPI, cartAPIResponse] = useLazyGetCartQuery();
  const [getCartSummaryAPI, cartSummaryAPIResponse] =
    useLazyGetCartSummaryQuery();
  const [editOrder, editOrderResponse] = useEditOrderMutation();
  const [deleteLineAPI, deleteLinesAPIResponse] =
    useDeleteFromActiveOrderMutation();

  const [productsInCart, setProductsInCart] = useState<POLineData[]>([]);

  const callCartAPI = ({
    workspaceId,
    customerId,
    sellerWorkspaceId,
    includeActiveOrders = true,
    includeSummary = false,
    importSource,
  }: callCartAPIProps) => {
    if (workspaceId && customerId && sellerWorkspaceId) {
      getCartAPI({
        workspaceId,
        params: {
          sellerWorkspaceId: sellerWorkspaceId,
          customerId,
          includeActiveOrders: includeActiveOrders,
          includeSummary: includeSummary,
          ...(importSource ? { importSource: importSource } : {}),
        },
      });
      getCartSummaryAPI({
        customerId,
        sellerWorkspaceId,
      });
    }
  };

  const onIncrement = (
    selectedLine: SelectedLine,
    sellerWorkspaceId: string,
    customerId: string
  ) => {
    editOrder({
      workspaceId: sellerWorkspaceId,
      body: {
        customerId: customerId,
        lines: [
          {
            quantity: (selectedLine.quantity + 1)?.toString(),
            productVariantId: selectedLine?.productVariantId,
            operator: 'add',
          },
        ],
        orderId: selectedLine?.orderId?.toString(),
      },
    });
  };

  const onDecrement = (
    selectedLine: SelectedLine,
    sellerWorkspaceId: string,
    customerId: string
  ) => {
    editOrder({
      workspaceId: sellerWorkspaceId,
      body: {
        customerId: customerId,
        lines: [
          {
            quantity: (selectedLine.quantity - 1).toString(),
            productVariantId: selectedLine?.productVariantId,
            operator: 'minus',
          },
        ],
        orderId: selectedLine?.orderId?.toString(),
      },
    });
  };

  const onDelete = (
    selectedLine: SelectedLine,
    sellerWorkspaceId: string,
    customerId: string
  ) => {
    if (selectedLine.productVariantId) {
      onChangeQuantity(selectedLine, 0, sellerWorkspaceId, customerId);
    } else {
      deleteUnMappedLine(selectedLine, sellerWorkspaceId, customerId);
    }

    // if (selectedLine?.orderLineId) {
    //   deleteLineAPI({
    //     params: {
    //       sellerWorkspaceId: sellerWorkspaceId,
    //     },
    //     body: {
    //       customerId,
    //       lines: [
    //         {
    //           orderId: selectedLine?.orderId,
    //           orderLineId: selectedLine?.orderLineId,
    //         },
    //       ],
    //       sellerWorkspaceId,
    //     },
    //   });
    // } else {
    //   onChangeQuantity(selectedLine, 0, sellerWorkspaceId, customerId);
    // }
  };

  const deleteUnMappedLine = (
    selectedLine: SelectedLine,
    sellerWorkspaceId: string,
    customerId: string
  ) => {
    editOrder({
      workspaceId: sellerWorkspaceId,
      body: {
        customerId: customerId,
        lines: [
          {
            poFileLineId: selectedLine?.poFileLineId,
            quantity: '0',
          },
        ],
        poFileId: selectedLine?.poFileLineId,
      },
    });
  };
  const onChangeQuantity = (
    selectedLine: SelectedLine,
    qty: number,
    sellerWorkspaceId: string,
    customerId: string
  ) => {
    if (qty === selectedLine?.quantity) {
      return;
    }
    let operator = 'add';

    if (qty < selectedLine?.quantity) {
      operator = 'minus';
    }
    editOrder({
      workspaceId: sellerWorkspaceId,
      body: {
        customerId: customerId,
        lines: [
          {
            quantity: qty?.toString(),
            productVariantId: selectedLine?.productVariantId,
            ...(qty !== 0 && { operator: operator }),
          },
        ],
        orderId: selectedLine?.orderId?.toString(),
      },
    });
  };

  useEffect(() => {
    if (cartAPIResponse?.isSuccess) {
      setProductsInCart(() => {
        let lines: POLineData[] = [];
        cartAPIResponse?.data?.files?.forEach((file) => {
          if (file?.lines) {
            lines.push(
              ...file?.lines?.map((line) => {
                return {
                  ...line,
                  poFileId: file?.id,
                };
              })
            );
          }
        });
        return lines;
      });
    }
  }, [cartAPIResponse]);

  return {
    callCartAPI,
    productsInCart,
    setProductsInCart,
    cartAPIResponse,
    cartSummaryAPIResponse,
    onIncrement,
    onDecrement,
    onDelete,
    onChangeQuantity,
    editOrderResponse,
    deleteLinesAPIResponse,
  };
};
