import { Input, InputAdornment } from '@mui/material';
import React from 'react';
import { theme } from '@zonofi/common';
import { PNGS, SVGS } from '@zonofi/zono-money-design-system';

interface gstRegistration {
  setGstNumber: (text: string) => void;
  GstNumber: string;
  handleNext: () => void;
  errorMsg: string;
  handleNoGst: () => void;
}

export const GstRegistration = ({
  setGstNumber,
  GstNumber,
  handleNext,
  errorMsg,
  handleNoGst,
}: gstRegistration) => {
  return (
    <div className="top-container" style={{ ...theme.fontConfig.regular }}>
      <div className="kyc-form">
        <div className="kyc-form-list">
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img
              src={PNGS?.Kyc}
              alt="kycScreen"
              className="kyc-form-image"
              width={151}
              height={149}
            />
          </div>

          <p className="kyc-form-text">Let’s Get Started with Your KYC!</p>
        </div>
        <div>
          <p className="kyc-form-gst-text">GSTIN</p>
          <Input
            disableUnderline
            style={{
              display: 'flex',
              height: '44px',
              padding: '8px',
              alignItems: 'center',
              gap: '8px',
              alignSelf: 'stretch',
              flex: 1,
              margin: '10px 0px 10px 0px',
              width: 'auto',
              border: '1px solid #8C8C8C',
              borderRadius: 6,

              outline: 'none',

              fontFamily: 'Segoe-UI',
            }}
            onFocus={(e) => {
              e.target.style.borderColor = '#D9D9D9';
            }}
            onBlur={(e) => {
              const parentElement = e.target.parentNode as HTMLElement;
              if (parentElement) {
                parentElement.style.borderColor = '#D9D9D9';
              }
            }}
            onChange={(e) => {
              const inputValue = e.target.value;
              if (inputValue.length <= 15) {
                setGstNumber(inputValue);
              } else {
                console.log('sdfsdff');
              }
            }}
            value={GstNumber}
            className="comms-font-size-regular"
            placeholder="Search"
            inputProps={{ style: { height: '32px', padding: 0 } }}
            endAdornment={
              <InputAdornment
                position="end"
                style={{ marginRight: 10, cursor: 'pointer' }}
              >
                {`${GstNumber.length}/15`}
              </InputAdornment>
            }
          />
          {errorMsg && (
            <div
              style={{
                color: 'red',
                fontSize: '12px',

                marginBottom: '4px',
                fontFamily: 'Segoe-UI',
              }}
            >
              {errorMsg}
            </div>
          )}
          <div className="Gst-input-text">
            <img src={SVGS?.BlueInfo} alt="blueInfo" />
            <div className="gst-input-text-title">
              We use your GSTIN to fetch your business information and set up
              your business profile.
            </div>
          </div>
  
        </div>
      </div>
      <div className="gst-form-footer">
        <hr />
        <p className="gst-footer-text" onClick={handleNoGst}>
          I don’t have GST
        </p>
        <div className="footer-button-container">
          <button className="footer-button" type="button" onClick={handleNext}>
            Verify
          </button>
        </div>
      </div>
    </div>
  );
};
