import { getConfigValue } from '@zonofi/common';
import { getToken, store } from '@zonofi/seller-rtk-store';
import ReactNativeBlobUtil from 'react-native-blob-util';

export const UploadFileToBotApi = async (
  sellerWorkspaceId: string,
  file: { fileSource: string; contentType: string },
  activityPayload: Record<string, unknown>,
  onSuccess: (value?: any) => void,
  onError?: (value?: any) => void
) => {

  const accessToken = getToken(store.getState());
  const apiUrl = `${getConfigValue(
    'API_ENDPOINT'
  )}hub/bot/api/v1/messages?sellerWorkspaceId=${sellerWorkspaceId}`;
  const { contentType, fileSource } = file;

  const cntType =
    contentType === 'text/comma-separated-values' ? 'text/csv' : contentType;
  const ext = cntType.split('/')[1];

  try {
    let filePath: string = '';
    if (fileSource.startsWith('http')) {
      filePath = `${ReactNativeBlobUtil.fs.dirs.CacheDir}/temp.${ext}`;
      await ReactNativeBlobUtil.config({
        fileCache: true,
        path: filePath,
      }).fetch('GET', fileSource);
    } else {
      filePath = fileSource;
    }


    const response = await ReactNativeBlobUtil.fetch(
      'POST',
      apiUrl,
      {
        Accept: '*/*',
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data',
      },
      [
        { name: 'activity', data: JSON.stringify(activityPayload) },
        {
          name: 'file',
          filename: `temp.${ext}`,
          type: cntType,
          data: ReactNativeBlobUtil.wrap(filePath),
        },
      ]
    );
    const result = await response.json();
    console.log('result', result)
    onSuccess(result);

  } catch (error) {
    onError?.();
  }
};
