import { SVGS } from '@zonofi/zono-money-design-system';
import React from 'react';
import './cart-icon.scss';
interface CartIconProps {
  onCartClick: () => void;
  itemsCount: number;
}
export const CartIcon: React.FC<CartIconProps> = ({
  onCartClick,
  itemsCount,
}) => {
  return (
    <div className="cart-icon-container" onClick={onCartClick}>
      <img className="cart-icon-container-image" src={SVGS.Cart} alt="" />
      <span className="cart-icon-container-badge font-primary">
        {itemsCount}
      </span>
    </div>
  );
};
