import { configureStore } from '@reduxjs/toolkit';
import { localAppStorage } from './local-storage';
import { persistReducer, persistStore } from 'redux-persist';
import { Api } from '../api/root-api';
import { userSlice } from '../auth';
import { workspaceSlice } from '../workspace';
import appReducer from './appReducer';
import { kycSlice } from '../kyc';
import { inviteSlice } from '../invite';
import { getAppVersion, updateAppVersion } from '../user-preference';
import { mediaSlice } from '../media';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { userPreferenceSlice } from '../user-preference';
import { resetTableConfig, tableConfigSlice } from '../table-config';
import { miscellaneousSlice } from '../miscellaneous';
import { customersSlice } from '../customers';
import { divisionSlice } from '../division/slice';
import { configSlice } from '../config';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const createDebugger = require('redux-flipper').default;

const persistConfig = {
  key: 'root',
  storage: localAppStorage,
  whitelist: [
    userSlice.name,
    workspaceSlice.name,
    mediaSlice.name,
    kycSlice.name,
    inviteSlice.name,
    userPreferenceSlice.name,
    tableConfigSlice.name,
    miscellaneousSlice.name,
    customersSlice.name,
    divisionSlice.name,
    configSlice.name,
  ],
  blacklist: ['messagesData', miscellaneousSlice.name, Api.reducerPath],
  timeout: 10000, // for example, 10 seconds
};

const rootReducer = (state: any, action: any) => {
  if (action.type === 'userAuth/logOut') {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

const checkVersionMiddleware = (store: any) => (next: any) => (action: any) => {
  const result = next(action);
  const appVersion = getAppVersion(store.getState());
  const version = process.env.VERSION;
  if (version && appVersion !== version) {
    console.log('versionUpdated', version);
    store.dispatch(updateAppVersion(version));
    store.dispatch(resetTableConfig());
  }
  return result;
};

const pReducer = persistReducer(persistConfig, rootReducer);

const middlewares = [Api.middleware, checkVersionMiddleware];
if (__DEV__) {
  middlewares.push(createDebugger());
}

const reduxStore = configureStore({
  reducer: pReducer,
  middleware: (gDM) =>
    gDM({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(middlewares),
});

export type RootState = ReturnType<typeof store.getState>;
export const persistor = persistStore(reduxStore);
export const store = reduxStore;
export const dispatch = reduxStore?.dispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
