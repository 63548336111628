import { ColumnConfig, UPIIdColumnKey } from '../../model';

export const upiIdConfig: Record<UPIIdColumnKey, ColumnConfig> = {
  UPIId: {
    name: 'UPI ID',
  },
  phoneNumber: { name: 'Phone Number' },
  UPIAccountHolderName: {
    name: 'UPI Account Holder Name',
  },
  settings: {
    name: '',
    alignment: 'flex-end',
    field: 'component',
  },
};
