import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../root';
import { isEmpty, isNull, isUndefined } from 'lodash';

export interface threadState {
  allSelectedMemberItem: string[];
  leaveType: string;
  modalNumber: number;
  filterLoader: boolean;
}

export const initialThreadState: threadState = {
  allSelectedMemberItem: [],
  leaveType: '',
  modalNumber: 0,
  filterLoader: false,
};

const SELLER_ACTION_FEATURE_KEY = 'threadSlice';

export const threadSlice = createSlice({
  name: SELLER_ACTION_FEATURE_KEY,
  initialState: initialThreadState,
  reducers: {
    updateAllSelectedMemberItem(state, action: PayloadAction<string>) {
      if (!isEmpty(action.payload)) {
        const index = state.allSelectedMemberItem.indexOf(action.payload);

        if (index === -1) {
          state.allSelectedMemberItem.push(action.payload);
        } else {
          state.allSelectedMemberItem.splice(index, 1);
        }
      } else {
        state.allSelectedMemberItem = [];
      }
    },
    updateLeaveType(state, action: PayloadAction<string>) {
      if (!isEmpty(action.payload)) {
        state.leaveType = action.payload;
      } else {
        state.leaveType = '';
      }
    },
    updateModalNumber(state, action: PayloadAction<number>) {
      if (!isNull(action.payload) && !isUndefined(action.payload)) {
        state.modalNumber = action.payload;
      } else {
        state.modalNumber = 0;
      }
    },
    updateFilterLoader(state, action: PayloadAction<boolean>) {
      state.filterLoader = action.payload;
    },
  },
});

export const threadReducer = threadSlice.reducer;

export const {
  updateAllSelectedMemberItem,
  updateLeaveType,
  updateModalNumber,
  updateFilterLoader,
} = threadSlice.actions;

const getsellerActionState = (rootState: RootState): threadState =>
  rootState[SELLER_ACTION_FEATURE_KEY];

export const getAllSelectedMemberItems = createSelector(
  getsellerActionState,
  (state) => state?.allSelectedMemberItem
);

export const getModalNumber = createSelector(
  getsellerActionState,
  (state) => state.modalNumber
);

export const getLeaveType = createSelector(
  getsellerActionState,
  (state) => state.leaveType
);

export const getFilterLoader = createSelector(
  getsellerActionState,
  (state) => state.filterLoader
);
