// ProductDetails.jsx
import React from 'react';
import './product-details-screen.scss';
import { SVGS } from '@zonofi/zono-money-design-system';
import {
  MoqErrorSnackBar,
  ProductDetailsContent,
  ProductDetailsGalleryView,
  ProductDetailsHeader,
  ProductDetailsImageGallery,
  ProductFooter,
} from '@zonofi/zono-money-whatsapp-stack';
import { useProductDetails } from './hooks/useProductDetails';

export const ProductDetailsScreen = () => {
  const {
    productDetails,
    onAddToCart,
    onIncrement,
    onDecrement,
    onChangeQuantity,
    isLoading,
    totalCartItems,
    hidePrice,
    addedToCart,
    isMoqError,
    onDelete,
    showImageGallery,
    setShowImageGallery,
    hideImages,
  } = useProductDetails();

  return (
    <>
      <div className="product-details">
        <ProductDetailsHeader
          productName={productDetails.title}
          totalCartItems={totalCartItems}
        />
        {!hideImages && (
          <ProductDetailsImageGallery
            images={productDetails.images}
            setShowImageGallery={setShowImageGallery}
          />
        )}
        <ProductDetailsContent
          productDetails={productDetails}
          hidePrice={hidePrice}
          hideImages={hideImages}
        />
        <div className="product-details-footer-container">
          {addedToCart && (
            <div className="added-to-cart font-primary">
              <img src={SVGS.CheckGreenIcon} alt="added-to-cart" />
              Added To Cart
            </div>
          )}
          <ProductFooter
            productDetails={productDetails}
            onAddToCart={onAddToCart}
            onIncrement={onIncrement}
            onDecrement={onDecrement}
            isLoading={isLoading}
            onChangeQuantity={onChangeQuantity}
            isMoqError={isMoqError}
            onDelete={onDelete}
          />
        </div>
        {isMoqError && (
          <div className="moq-error-snackbar-container">
            <MoqErrorSnackBar number={productDetails.details.moq} />
          </div>
        )}
      </div>
      {showImageGallery && (
        <ProductDetailsGalleryView
          open={showImageGallery}
          onClose={() => setShowImageGallery(false)}
          images={productDetails.images.map((image) => ({
            src: image,
            alt: productDetails.title,
            type: 'image',
          }))}
        />
      )}
    </>
  );
};
