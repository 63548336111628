import React from 'react';
import './error-screen.scss';
import { SVGS } from '@zonofi/zono-money-design-system';
import { useAppSelector } from '@zonofi/zono-money-helpers';
import { getCustomerInvite } from '@zonofi/zono-money-store';
import { getConfigValue } from '@zonofi/common';
import { FallBackScreen } from '@zonofi/zono-money-whatsapp-stack';

export const ErrorScreenV2 = (): React.ReactNode => {
  const inviteDetails = useAppSelector(getCustomerInvite);
  const { seller, waConfig } = inviteDetails;

  const onClickBackToWhatsapp = () => {
    if (waConfig?.whatsappPhoneNo && waConfig?.whatsappPhoneNo?.trim()) {
      window.location.href = `https://wa.me/${waConfig.whatsappPhoneNo}`;
    } else if (seller?.workspaceId === 'c9bedd72-85d7-41c4-8c62-e33ffc926966') {
      window.location.href = getConfigValue('WAPP_REDIRECT_BJ_GROUP');
    } else {
      window.location.href = getConfigValue('WAPP_REDIRECT');
    }
  };
  return (
    <FallBackScreen
      image={SVGS.ErrorScreenImageV2}
      message="Link Expired"
      description="Link is valid for 24 hours only. To get a fresh link, please message us again on WhatsApp."
      buttonName="Back To Whatsapp"
      onButtonClick={onClickBackToWhatsapp}
    />
  );
};
