import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../root';
import { ActionDrawer } from './model';
import { UnreadMessageCount } from '../helpers';
import _ from 'lodash';
import uuid from 'react-native-uuid';
import {
  ChatActionType,
  Media,
  MessageActionType,
  MessageEnvelope,
} from '@zonofi/common';

export const MESSAGE_FEATURE_KEY = 'msgData';

interface SelectedMessageAction {
  action: null | MessageActionType;
  envelope: MessageEnvelope | undefined;
  isLoading?: boolean;
}

export interface MsgState {
  messages: { [channel: string]: MessageEnvelope[] };
  msgPagination: { [channel: string]: boolean };
  unreadMessageCount: Record<string, UnreadMessageCount>;
  hasUnreadMessage: { channel: boolean; thread: boolean };
  chatActionDrawer: ActionDrawer;
  scrollToBottom: boolean;
  selectedMessage?: SelectedMessageAction;
  showLoader: boolean;
}

export const initialMsgState: MsgState = {
  messages: {},
  msgPagination: {},
  unreadMessageCount: {},
  hasUnreadMessage: { channel: false, thread: false },
  chatActionDrawer: {
    open: false,
    type: ChatActionType.NONE,
    suggestions: [],
    entityId: undefined,
    customerId: '',
    isRedirectFromZoAI: false,
  },
  scrollToBottom: false,
  showLoader: false,
};

export const msgSlice = createSlice({
  name: MESSAGE_FEATURE_KEY,
  initialState: initialMsgState,
  reducers: {
    updateMessagesByChannelId(
      state,
      action: PayloadAction<{ channelId: string; messages: MessageEnvelope[] }>
    ) {
      const { channelId, messages } = action.payload;
      state.messages[channelId] = messages;
    },
    updateChannelPagination(
      state,
      action: PayloadAction<{ channelId: string; pagination: boolean }>
    ) {
      const { channelId, pagination } = action.payload;
      state.msgPagination[channelId] = pagination;
    },
    upsertMessage(state, action: PayloadAction<Partial<MessageEnvelope>>) {
      const message = action.payload;
      if (!message || !message.channel || !message.message) {
        return;
      }
      const { channel, message: messagePayload, timetoken } = message;

      if (!(channel.includes('_inbound_v1') && !messagePayload?.isZoey)) {
        const existingChannel = [...(state.messages[channel] || [])];

        if (timetoken) {
          // Update existing message
          const existingMessageIndex = existingChannel.findIndex(
            ({ timetoken: msgTimetoken }) => {
              return timetoken === msgTimetoken;
            }
          );
          if (existingMessageIndex !== -1) {
            const oldMessage = {
              ...existingChannel[existingMessageIndex],
            };
            const oldMedia = Object.fromEntries(
              oldMessage?.message?.media?.map((x, index) => {
                const media = { ...x };
                return [media?._id || index.toString(), media];
              }) || []
            );
            const media: Media[] =
              message.message?.media?.map((item, index) => ({
                ...oldMedia?.[item?._id || index.toString()],
                ...item,
              })) || [];
            if (media.length) {
              message.message.media = media;
            }
            existingChannel[existingMessageIndex] = {
              ...oldMessage,
              ...message,
            };
          } else {
            // Insert new message
            existingChannel.push(message as MessageEnvelope);
          }
        } else {
          // Insert new message without _id
          const newMessage = {
            ...message,
            message: { ...messagePayload, _id: uuid.v4() },
          } as MessageEnvelope;
          existingChannel.push(newMessage);
        }

        // Sort messages based on timetoken
        existingChannel.sort(
          (a, b) => Number(a.timetoken) - Number(b.timetoken)
        );

        state.messages[channel] = existingChannel;
      }
    },
    updateUnreadMessageCount(
      state,
      action: PayloadAction<Record<string, Partial<UnreadMessageCount>>>
    ) {
      const { payload } = action;
      const unreadMessageCount = { ...state.unreadMessageCount };

      Object.entries(payload).forEach(([chId, data]) => {
        if (!chId.includes('inbound_v1')) {
          const oldData = unreadMessageCount?.[chId] || { count: 0, type: '' };
          unreadMessageCount[chId] = oldData
            ? { ...oldData, ...data }
            : ({ ...data } as UnreadMessageCount);
        }
      });

      const channelUnread = Object.values(unreadMessageCount).filter(
        (x) => x.type === 'channel' && x.count > 0
      ).length;
      const threadUnread = Object.values(unreadMessageCount).filter(
        (x) => x.type.includes('thread') && x.count > 0
      ).length;

      state.unreadMessageCount = unreadMessageCount;
      state.hasUnreadMessage = {
        channel: channelUnread !== 0,
        thread: threadUnread !== 0,
      };
    },
    clearAllMessageData(state) {
      state.messages = {};
      state.msgPagination = {};
    },
    clearUnreadData(state) {
      state.unreadMessageCount = {};
      state.hasUnreadMessage = { channel: false, thread: false };
    },
    updateChatActionDrawer(state, action: PayloadAction<ActionDrawer>) {
      state.chatActionDrawer = { ...action?.payload };
    },
    closeChatActionDrawer(state) {
      state.chatActionDrawer.open = false;
      state.chatActionDrawer.entityId = undefined;
    },
    updateScrollToBottom(state, action: PayloadAction<boolean>) {
      state.scrollToBottom = action.payload;
    },
    updateSelectedMessage(state, action: PayloadAction<SelectedMessageAction>) {
      state.selectedMessage = action.payload;
    },
    updateShowLoader(state, action: PayloadAction<boolean>) {
      state.showLoader = action.payload;
    },
  },
});

export const {
  updateMessagesByChannelId,
  updateChannelPagination,
  upsertMessage,
  clearAllMessageData,
  updateUnreadMessageCount,
  clearUnreadData,
  updateChatActionDrawer,
  updateScrollToBottom,
  closeChatActionDrawer,
  updateSelectedMessage,
  updateShowLoader,
} = msgSlice.actions;

export const getMsgSliceState = (rootState: RootState): MsgState =>
  rootState[MESSAGE_FEATURE_KEY];

export const getChannelMessages = (channelId: string) =>
  createSelector(
    getMsgSliceState,
    (state) => state.messages?.[channelId] || []
  );

export const getCurrentChannelPagination = (channelId: string) =>
  createSelector(
    getMsgSliceState,
    (state) => state.msgPagination?.[channelId] || false
  );

export const getHasUnreadMessageData = createSelector(
  getMsgSliceState,
  (state) => state.hasUnreadMessage
);

export const getUnreadMesssageCount = createSelector(
  getMsgSliceState,
  (state) => state.unreadMessageCount
);

export const getChannelUnreadMesssageCount = (channelId: string) =>
  createSelector(
    getMsgSliceState,
    (state) => state.unreadMessageCount[channelId]?.count || 0
  );

export const getChatActionDrawer = createSelector(
  getMsgSliceState,
  (state) => state.chatActionDrawer
);

export const getIsScrollToBottom = createSelector(
  getMsgSliceState,
  (state) => state.scrollToBottom
);

export const getSelectedMessage = createSelector(
  getMsgSliceState,
  (state) => state.selectedMessage
);

export const getShowLoader = createSelector(
  getMsgSliceState,
  (state) => state.showLoader
);

export const getTotalUnreadCountByType = createSelector(
  getMsgSliceState,
  (state) => {
    const unreadCounts = Object.values(state.unreadMessageCount);
    const customerThreadUnreadCount = unreadCounts.filter(
      (x) => x.type === 'customerthread' && x.count > 0
    ).length;
    const issueThreadUnreadCount = unreadCounts.filter(
      (x) => x.type === 'issuethread' && x.count > 0
    ).length;
    const groupThreadUnreadCount = unreadCounts.filter(
      (x) => x.type === 'groupthread' && x.count > 0
    ).length;
    return {
      customerThreadUnreadCount,
      issueThreadUnreadCount,
      groupThreadUnreadCount,
    };
  }
);
