import { ColumnConfig, PaymentTransactionKey } from '../model';

export const paymentTransactionConfig: Record<
  PaymentTransactionKey,
  ColumnConfig
> = {
  invoiceNumber: {
    name: 'Invoice Number',
    // headerExtra: {
    //   type: 'info',
    //   details: '',
    //   title: 'Invoice Number',
    // },
  },
  invoiceDate: {
    name: 'Invoice Date',
    sortField: 'date',
    headerExtra: {
      type: 'info',
      details: 'Created Invoice Date',
      title: 'Invoice Date',
    },
  },
  dueDate: {
    name: 'Due Date',
    sortField: 'dueDate',
    // headerExtra: {
    //   type: 'info',
    //   details: '',
    //   title: 'Due Date',
    // },
  },

  invoiceAmount: {
    name: 'Invoice Amount',
    sortField: 'invoiceAmount',
    // headerExtra: {
    //   type: 'info',
    //   details: '',
    //   title: 'Invoice Amount',
    // },
    alignment: 'flex-end',
  },
  amountCleared: {
    name: 'Amount Cleared',
    sortField: 'amountCleared',
    headerExtra: {
      type: 'info',
      details: '',
      title: 'Amount Cleared',
    },
    alignment: 'flex-end',
  },
  totalSettled: {
    name: 'Total Settled',
    sortField: 'totalSettled',
    headerExtra: {
      type: 'info',
      details: '',
      title: 'Total Settled',
    },
    alignment: 'flex-end',
  },
  totalPending: {
    name: 'Total Pending',
    sortField: 'totalPending',
    headerExtra: {
      type: 'info',
      details: '',
      title: 'Total Pending',
    },
    alignment: 'flex-end',
  },
};
