import {
  getCustomerInvite,
  useLazyValidateKycGstQuery,
} from '@zonofi/zono-money-store';
import { ThemeContext } from '@zonofi/shared';
import React, { useContext, useEffect, useState } from 'react';
import { useAppSelector } from '@zonofi/zono-money-helpers';

export const useGst = () => {
  const [GstNumber, setGstNumber] = useState('');
  const [gstFormData, setGstFormData] = useState({
    name: '',
    address: '',
    date: '',
    GstInNo: '',
    CustomerName: '',
    AadharCard: '',
    AadharNo: '',
    PanCard: '',
    phoneNo: '',
    email: '',
  });
  const [noGst, setGst] = useState(false);
  const [verifyValidGst, setVerifyValidGst] = useState(false);
  const inviteData = useAppSelector(getCustomerInvite);
  const sellerWorkspaceId = inviteData?.seller?.workspaceId;

  console.log(sellerWorkspaceId, 'return the sellerWorkspaceId', inviteData);
  const [validateGstInApi, validateGstInApiReponse] =
    useLazyValidateKycGstQuery();

  const [error, setError] = useState('');
  const [kycError, setKycError] = useState({
    FirmName: '',
    AadharNo: '',
    PanNo: '',
  });
  const { theme } = useContext(ThemeContext);
  const [pageNumber, setPageNumber] = useState(0);

  const handleInputFiled = (text, value) => {
    setGstFormData((prevState) => ({
      ...prevState,
      [text]: value,
    }));
  };

  useEffect(() => {
    if (validateGstInApiReponse?.isSuccess) {
      const data = validateGstInApiReponse?.data;
      setVerifyValidGst(true);
      setGstFormData((prev) => ({
        ...prev,
        name: data.firm_name,
        address: data.address.loc,
        date: data.valid_till,
        GstInNo: data.gstin_number,
      }));
    } else {
      setVerifyValidGst(false);
    }
  }, [validateGstInApiReponse]);

  const googleSheetApiIn = async () => {
    console.log('googlesheetApi1');
    const data = new FormData();
    data.append('fullName', gstFormData.name);
    data.append('email', gstFormData.email);
    data.append('phoneNumber', gstFormData.phoneNo);
    const Sheet_Url =
      'https://script.google.com/macros/s/AKfycbyrVdNK4qs4iOviHGrdVNSz0Yd9MxMo1SFbxEr_bljlFM1bN5uYcPTeDfAckwI-DPw/exec';
    try {
      fetch(Sheet_Url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          fullName: gstFormData.name,
          Name: gstFormData.name,
          Email: gstFormData.email,
          GSTNo: gstFormData.GstInNo,
          Aadhar: gstFormData?.AadharNo,
          PAN: gstFormData?.PanCard,
          Contact: gstFormData.phoneNo,
        }),
      })
        .then((response) => response.json())
        .then((data) => console.log(data));

      console.log('googlesheetApi2');
    } catch (error) {
      console.log(error);
      console.log('googlesheetApi3');
    }
  };
  console.log(gstFormData, 'rererGSt');

  const handleApplication = async () => {
    await googleSheetApiIn();
    setPageNumber(2);
  };

  const handleNext = async () => {
    if (pageNumber === 0) {
      if (GstNumber.length > 14) {
        try {
          const response = await validateGstInApi({
            gstNumber: GstNumber,
            sellerWorkspaceId: sellerWorkspaceId,
            skipWorkspaceCheck: true,
            customerId: inviteData?.inviteId,
          }).unwrap();

          console.log('Validation Success:', response);
          setError('');
        } catch (err) {
          console.error('Validation Error:', err);
          setError('Failed to validate GST');
        }
        setPageNumber(1);
        setError('');
      } else {
        setError('Please Enter Valid GST');
      }
    } else if (pageNumber === 1) {
      if (!gstFormData?.name || gstFormData.name.length === 0) {
        setKycError({
          ...kycError,
          FirmName: 'Please enter a valid Firm Name',
        });
      } else if (
        !gstFormData?.AadharNo ||
        gstFormData.AadharNo.length === 0 ||
        gstFormData.AadharNo.length < 12
      ) {
        setKycError({
          ...kycError,
          FirmName: '',
          AadharNo: 'Please enter a valid Aadhar number',
        });
      } else if (
        !gstFormData?.PanCard ||
        gstFormData.PanCard.length === 0 ||
        gstFormData.PanCard.length < 10
      ) {
        setKycError({
          ...kycError,
          AadharNo: '',
          FirmName: '',
          PanNo: 'Please enter a valid PAN card number',
        });
      } else {
        handleApplication();
      }
    }
  };

  useEffect(() => {}, [pageNumber]);

  return {
    pageNumber,
    GstNumber,
    setGstNumber,
    handleNext,
    error,
    gstFormData,
    handleInputFiled,
    noGst,
    setGst,
    setPageNumber,
    verifyValidGst,
    kycError,
  };
};
