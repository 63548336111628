import { createSelector } from '@reduxjs/toolkit';
import _filter from 'lodash/filter';
import { sentTeamInvitesAdapter } from './slice';
import { store } from '../root';

export const sentTeamInvitesNativeSelectors =
  sentTeamInvitesAdapter.getSelectors();

const getInvitesState = () => store.getState()?.sentTeamInvites?.entities;

const teamInvitesStatus = createSelector(getInvitesState, (invite) =>
  _filter(invite, (i) => i?.inviteStatus === 'P')
);

const acceptedTeamInvitesStatus = createSelector(getInvitesState, (invite) =>
  _filter(invite, (i) => i?.inviteStatus === 'A')
);

const notAcceptedTeamInvitesStatus = createSelector(getInvitesState, (invite) =>
  _filter(invite, (i) => i?.inviteStatus !== 'A')
);

export const getAllSentTeamInvites = (
  invitesState: any = getInvitesState()
) => {
  return sentTeamInvitesNativeSelectors?.selectAll(invitesState);
};

// export const getAllSentTeamInvitesInWorkspace = (
//   invitesState: any = getInvitesState()
// ) => invitesInWorkspace(invitesState);

// export const getAllSentTeamInvitesIds = (sentTeamInvites) => {
//   return sentTeamInvitesNativeSelectors.selectIds(sentTeamInvites);
// };
// export const getSentTeamInvitesById = (id, sentTeamInvites) => {
//   if (!sentTeamInvites) {
//     sentTeamInvites = store.getState().invitesData.sentTeamInvites;
//   }
//   return sentTeamInvitesNativeSelectors.selectById(sentTeamInvites, id);
// };
// export const getSentTeamInvitesCount = (sentTeamInvites) => {
//   if (!sentTeamInvites) {
//     sentTeamInvites = store.getState().invitesData.sentTeamInvites;
//   }
//   return sentTeamInvitesNativeSelectors.selectTotal(sentTeamInvites);
// };

export const getSentPendingTeamInvites = (
  invitesState: any = getInvitesState()
) => teamInvitesStatus(invitesState);

export const getSentAcceptedTeamInvites = (
  invitesState: any = getInvitesState()
) => acceptedTeamInvitesStatus(invitesState);

export const getSentNotAcceptedTeamInvites = (
  invitesState: any = getInvitesState()
) => notAcceptedTeamInvitesStatus(invitesState);
