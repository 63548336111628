import {
  FormHelperTextProps,
  InputLabelProps,
  TextField,
  TextFieldVariants,
} from '@mui/material';
import React, {
  CSSProperties,
  InputHTMLAttributes,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { ThemeContext } from '@zonofi/shared';
import './zo-input-text.scss';
import { SHARED_SVGS } from '@zonofi/shared';

export interface InputTextProps {
  /**
   * Provide the title for the input text. The text should be displayed top of the input feild
   */
  title?: string;
  /**
   * The label text displayed above the input field.
   */
  label?: string;
  /**
   * The current value of the input field.
   * This should be controlled by the parent component.
   */
  value?: string | any;
  /**
   * Indicates whether the input field is required. If true, the field must be filled out before form submission. (By default value is false)
   */
  required?: boolean;
  /**
   * The variant/style of the input field. Possible values could include "outlined", "filled", "standard"
   */
  varient?: TextFieldVariants;
  /**
   * The default value of the input field.
   * This value is used when the component is first rendered.
   */
  defaultValue?: string;
  /**
   * Show Input Prefix Icon
   */
  showIcon?: boolean;
  /**
   * Disable input
   */
  disabled?: boolean;
  /**
   * The name attribute of the input field. This is used to identify the input field.
   */
  name: string;
  /**
   * The placeholder text displayed inside the input field when it is empty.
   */
  placeholder?: string;
  /**
   * The helper text displayed below the input field.
   * This can be used to provide additional information to the user.
   */
  helperText?: string;
  /**
   * An element to display at the start of the input field.
   */
  startAdornment?: React.JSX.Element | any;
  /**
   * An element to display at the end of the input field.
   * Example: An icon or text.
   */
  endAdornment?: React.JSX.Element;
  /**
   * Indicates whether the input field has an error.
   * If true, the input field will be displayed in an error state.
   */
  error?: boolean;
  /**
   * The text direction of the input field content.
   * Possible values could be "ltr" (left-to-right) or "rtl" (right-to-left).
   * By default left-to-right
   */
  textDirection?: 'ltr' | 'rtl';
  /**
   * Function to handle changes to the input field.
   * This function is called with the event whenever the input value changes.
   */
  onBlur: (val: string) => void;
  /**
   * Props to customize the appearance and behavior of the helper text.
   * Example: `{ color: 'primary' }`
   */
  helperTextProps?: FormHelperTextProps;
  /**
   * Props to customize the appearance of the Text field.
   * By default width:100% and height= 44px is given`
   */
  textFeildStyleProps?: CSSProperties;
  /**
   * Additional HTML attributes to be spread onto the input element.
   */
  inputFieldProps?: InputHTMLAttributes<HTMLInputElement>;
  /**
   * Props to customizw the appearance of the label of the text field.
   */
  inputLabelProps?: InputLabelProps;
  /**
   * Any additional elements or components to be rendered within this component.
   * Example: Custom buttons or additional inputs.
   */
  children?: React.JSX.Element;
  /**
   * Key for the input text feild.
   */
  key?: number | string;
  /**
   * Ref for the input feild
   */
  ref?: any;
  /**
   *  Keydown handler useful for keyboard handler
   */
  onKeyDown?: (
    e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
    value: string
  ) => any;
  /**
   * Should Input be focused
   */
  focusInput?: boolean;
  /**
   *
   */
  onFocus?: (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
  ) => any;
  /**
   * Color for input border
   */
  borderColor?: string;
  inputType?: 'string' | 'number';
}

export const ZoInputText: React.FC<InputTextProps> = ({
  label,
  value,
  startAdornment,
  name,
  endAdornment,
  placeholder,
  onBlur,
  required = false,
  varient = 'outlined',
  defaultValue,
  showIcon = false,
  disabled = false,
  helperText = '',
  helperTextProps,
  children,
  textDirection,
  error = false,
  textFeildStyleProps,
  inputFieldProps,
  key,
  ref,
  onKeyDown,
  focusInput = false,
  onFocus,
  borderColor,
  inputLabelProps,
  title,
  inputType = 'string',
}) => {
  if (!ref) {
    ref = useRef<HTMLInputElement>(null);
  }

  const [val, setVal] = useState<string | number>(
    inputType === 'number' && value ? Number(value) : value || ''
  );
  const { theme } = useContext(ThemeContext);
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (focusInput) {
      ref?.current?.focus();
    } else {
      ref?.current?.blur();
    }
  }, [focusInput]);

  useEffect(() => {
    setVal(inputType === 'number' && value ? Number(value) : value || '');
  }, [value, inputType]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = inputType === 'number' ? Number(e.target.value) : e.target.value;
    setVal(inputValue);
  };

  const mergedStyles = {
    height: 'auto',
    padding: '10px 0px',
    fontSize: '16px',
    fontFamily: 'Segoe-UI',
    outline: 'none',
    zIndex: 99,
    borderRadius: 6,
    ...(inputFieldProps?.style || {}),
  };

  return (
    <div className="input_text font-regular">
      {title && (
        <span
          className={`input_text__title ${
            error ? 'input_text__error' : isFocused ? 'input_text__focused' : ''
          }`}
        >
          {title}
          {required && <span className="input_text__asterisk">*</span>}
        </span>
      )}
      <TextField
        label={label}
        value={val}
        key={key}
        inputRef={ref}
        defaultValue={defaultValue}
        name={name}
        error={error}
        placeholder={placeholder}
        variant={varient}
        onChange={handleInputChange}
        onFocus={(e) => {
          setIsFocused(true);
          onFocus && onFocus(e);
        }}
        onBlur={(e) => {
          setIsFocused(false);
          onBlur(e?.target?.value);
        }}
        required={required}
        disabled={disabled}
        className="input_text__textfield"
        FormHelperTextProps={{
          className: `input_text__helper ${
            error ? 'input_text__helper--error' : ''
          }`,
        }}
        helperText={
          helperText && (
            <span className="input_text__helper-text">
              {showIcon && (
                <span className="input_text__helper-icon">
                  <img
                    src={
                      error ? SHARED_SVGS.InputInfoError : SHARED_SVGS.InputInfo
                    }
                    alt={error ? 'Error Icon' : 'Info Icon'}
                  />
                </span>
              )}
              {helperText}
            </span>
          )
        }
        InputLabelProps={{
          shrink: true,
          className: 'input_text__label',
        }}
        InputProps={{
          style: {
            paddingLeft: 12,
            paddingRight: 12,
            fontSize: 16,
            fontFamily: 'Segoe-UI',
          },
          startAdornment: startAdornment && (
            <span className="custom-adornment-class">{startAdornment}</span>
          ),
          endAdornment: endAdornment && endAdornment,
        }}
        inputProps={{
          type: inputType, // Dynamically set the input type
          onKeyDown: (e) => onKeyDown && onKeyDown(e, ref?.current?.value),
          dir: textDirection,
          ...inputFieldProps,
          style: mergedStyles,
        }}
      >
        {children}
      </TextField>
    </div>
  );
};