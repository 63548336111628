import { BulkCustomersColumnKey, ColumnConfig } from '../model';

export const bulkCustomerUploadConfig: Record<BulkCustomersColumnKey, ColumnConfig> = {
  firmName: {
    name: 'Firm Name',
  },
  customerName: {
    name: 'Customer Name',
  },
  customerCode: {
    name: 'Customer Code',
  },
  mobileNumber: {
    name: 'Mobile Number',
  },
  email: {
    name: 'Email ID',
  },
  gstNumber: {
    name: 'GST Number',
    isVisible: true,
  },
  cstNumber: {
    name: 'CST Number',
  },
  licenseNumber: {
    name: 'License Number',
  },
  licenseExpiryDate: {
    name: 'License Expiry Date',
  },
  grossCreditLimit: {
    name: 'Gross Credit Limit',
  },
  creditLimitPeriod: {
    name: 'Credit Days',
  },
  cfa: {
    name: 'CFA',
  },
  division: {
    name: 'Divisions',
  },
  addressLine1: {
    name: 'Physical Address 1',
  },
  addressLine2: {
    name: 'Physical Address 2',
  },
  pinCode: {
    name: 'Pin Code',
  },
  city: {
    name: 'City',
  },
  state: {
    name: 'State',
  },
  shippingAddressIsSameAsPhysicalAddress: {
    name: 'Shipping Address Same as Physical',
  },
  shippingAddressLine1: {
    name: 'Shipping Address 1',
  },
  shippingAddressLine2: {
    name: 'Shipping Address 2',
  },
  shippingPinCode: {
    name: 'Shipping Pin Code',
  },
  shippingCity: {
    name: 'Shipping City',
  },
  shippingState: {
    name: 'Shipping State',
  },
  actions: {
    name: '',
  },
};
