import { getProductsDetailsResponse, ProductVariantAttribute } from '../orders';
import { Product, Promotion } from '../products';
import { WhatsappTemplateData } from '../channel';

export enum PublishStatus {
  PUBLISHED = 'Published',
  NOT_PUBLISHED = 'Not Published',
  EXPIRED = 'Expired'
}
export enum CampaignActionType {
  'TEMPLATE_VIEW' = 'Template View',
  'CREATE_CAMPAIGN' = 'Create Campaign',
  'CAMPAIGN_EDIT' = 'Campaign Edit',
  'CUSTOMER_EDIT' = 'Customer Edit',
  'PRODUCT_EDIT' = 'Product Edit',
  'PREVIEW' = 'Preview',
}

export enum SelectedTemplate {
  'ALL' = 'All',
  'ONE_FRAME' = 'One Frame',
  'CAROUSEL' = 'Carousel'
}

export enum CampaignMessageStatus {
  IMPRESSIONS = 'IMPRESSIONS',
  SUCCESS = 'SUCCESS',
  ORDERED = 'ordered',
  ENGAGED = 'engaged',
  SETUP_ERROR = 'SETUP_ERROR',
  RATE_LIMIT_HIT = 'RATE_LIMIT_HIT',
  SYSTEM_ERROR = 'SYSTEM_ERROR',
  NUMBER_ERROR = 'NUMBER_ERROR',
}

export enum CampaignMessageEnum {
  MESSAGE_SENT = 'MESSAGE_SENT',
  MESSAGE_DELIVERED = 'MESSAGE_DELIVERED',
  MESSAGE_SEEN = 'MESSAGE_SEEN',
  SYSTEM_ERROR = 'SYSTEM_ERROR',
  NUMBER_ERROR = 'NUMBER_ERROR',
  SETUP_ERROR = 'SETUP_ERROR',
  RATE_LIMIT_HIT = 'RATE_LIMIT_HIT',
  SPAM_RATE_LIMIT_HIT = 'SPAM_RATE_LIMIT_HIT',
  TOO_MANY_REQUEST = 'TOO_MANY_REQUEST',
  INVALID_REQUEST = 'INVALID_REQUEST',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
  INVALID_RECIPIENT = 'INVALID_RECIPIENT',
  INCAPABLE_RECIPIENT = 'INCAPABLE_RECIPIENT',
  RECIPIENT_BLOCKED = 'RECIPIENT_BLOCKED',
  UNSUPPORTED_MESSAGE = 'UNSUPPORTED_MESSAGE',
  MEDIA_DOWNLOAD_ERROR = 'MEDIA_DOWNLOAD_ERROR',
  MEDIA_UPLOAD_ERROR = 'MEDIA_UPLOAD_ERROR',
  REENGAGEMENT_ERROR = 'REENGAGEMENT_ERROR',
  INVALID_PARAM = 'INVALID_PARAM',
  MISSING_PARAM = 'MISSING_PARAM',
  INVALID_PARAM_VALUE = 'INVALID_PARAM_VALUE',
  TEMPLATE_ERROR = 'TEMPLATE_ERROR',
  TEMPLATE_PARAM_ERROR = 'TEMPLATE_PARAM_ERROR',
  TEMPLATE_NOT_FOUND = 'TEMPLATE_NOT_FOUND',
  TEMPLATE_TEXT_TOO_LONG = 'TEMPLATE_TEXT_TOO_LONG',
  INVALID_TEMPLATE_NAMESPACE = 'INVALID_TEMPLATE_NAMESPACE',
  MESSAGE_PENDING_TOO_LONG = 'MESSAGE_PENDING_TOO_LONG',
  MESSAGE_EXPIRED = 'MESSAGE_EXPIRED',
  MESSAGE_TOO_LONG = 'MESSAGE_TOO_LONG',
  WEBHOOK_ERROR = 'WEBHOOK_ERROR',
  SECTION_COUNT_ERROR = 'SECTION_COUNT_ERROR',
  ROWS_COUNT_ERROR = 'ROWS_COUNT_ERROR',
  PRODUCT_COUNT_ERROR = 'PRODUCT_COUNT_ERROR',
  CATALOGUE_NOT_FOUND = 'CATALOGUE_NOT_FOUND',
  CATALOGUE_NOT_LINKED = 'CATALOGUE_NOT_LINKED',
  MISSING_PRODUCT = 'MISSING_PRODUCT',
  PRODUCT_NOT_FOUND = 'PRODUCT_NOT_FOUND',
  PRODUCT_COMPLIANCE_ERROR = 'PRODUCT_COMPLIANCE_ERROR',
  INVALID_HEADER = 'INVALID_HEADER',
  MISSING_COMPLIANCE = 'MISSING_COMPLIANCE',
  CHAR_POLICY_VIOLATION = 'CHAR_POLICY_VIOLATION',
  RESTRICTED_BY_META = 'RESTRICTED_BY_META',
  ENGAGED = 'ENGAGED',
  ORDERED = 'ORDERED',
  engaged = 'engaged',
  ordered = 'ordered'
}

export interface CreateCampaignRequest {
  workspaceId: string;
  sellerWorkspaceId: string;
  body: any;
}

interface MessageBody {
  name: string;
  description: string;
  productVariants: string[];
}
interface Message {
  message: string;
  method: string;
  body: MessageBody;
}
export interface CampaignItem {
  id: string;
  createdDate: string;
  updatedDate: string;
  startDate: string;
  endDate: string; 
  code: string;
  name: string;
  customers?: customer[];
  description: string;
  imagePreview: string;
  channelId?: number;
  productVariants?: getProductsDetailsResponse[];
  productVariantAttribute?: ProductVariantAttribute;
  assets: campaginAsset[];
  metric: metric;
  status: PublishStatus;
  customersCount: number;
  productVariantsCount: number;
  publishedDate: string;
  type: string;
}

export enum CampaignMessage {
  CUSTOMER_CONTACT_NAME = 'customer_contact_name',
  CUSTOMER_FIRM_NAME  = 'customer_firm_name',
  SELLER_FIRM_NAME = 'seller_firm_name',
  SELLER_WABA_NUMBER = 'seller_waba_number',
  SELLER_WABA_NUMBER_WA_ME_LINK = 'seller_waba_number_wa_me_link',
  CAMPAIGN_NAME = 'campaign_name',
  CUSTOM_MESSAGE = 'custom_message'
}

interface customer {
  createdAt: string;
  updatedAt: string;
  campaignId: string;
  customerId: string;
  isSent: boolean;
  id: number;
  customer: any;
}

export interface CreateCampaignResponse {
  statusCode: number;
  message: Message | string;
  id: string;
  data: CampaignItem;
  isScheduled?: boolean
}

export interface CampaignListRequest {
  workspaceId: string;
  sellerWorkspaceId: string;
  body: {
    status?: string[];
    imagePreview?: string[];
    type?: string[];
  };
  params?: {
    pageNo?: number;
    pageSize?: number;
    searchKeyword?: string;
    startDate?: string;
    endDate?: string;
    sort?: string;
    order?: string;
    customerId?: any;
  };
}

export interface campaginAsset {
  id: number;
  createdAt: string;
  updatedAt: string;
  name: string;
  type: string;
  mimeType: string;
  width: number;
  height: number;
  fileSize: number;
  source: string;
  preview: string;
  focalPoint: {
    x: number;
    y: number;
  };
}

export interface CampaignListResponse {
  statusCode: number;
  message: string;
  data: CampaignItem[];
  totalCount: number;
  startRecord: number;
  endRecord: number;
}

export interface metric {
  target: number;
  engaged: number;
  id: string;
  impressions: number;
  ordered: number;
  sent: number;
  roi: number;
}

export interface CampaignResponse {
  statusCode: number;
  message: string;
  data: CampaignItem;
}

export interface CampaignRequest {
  campaignId: string;
  workspaceId: string;
  customerId?: string;
  sellerWorkspaceId: string;
}
interface UpdateCampaignBodyRequest {
  name?: string;
  type?: string;
  description?: string;
  productVariants?: number[];
}

export interface UpdateCampaignRequest {
  campaignId: string;
  workspaceId: string;
  sellerWorkspaceId: string;
  body: UpdateCampaignBodyRequest;
}

interface User {
  firstName: string;
  lastName: string;
  mobile: string;
  email: string;
  id: string;
}

interface Product_ {
  name: string;
  price: number;
  qty?: number;
  sku?: string;
  assetUrl?: string;
  description?: string;
  minOrderQty?: number;
}

interface Data {
  id: string;
  name: string;
  description: string;
  type: string;
  sellerName: string;
  assetUrl: string;
  products: Product_[];
}

interface Conversation {
  id: any;
}

export interface SendChannelCampaignRequest {
  sellerWorkspaceId: string;
  message: {
    _id: string;
    type: string;
    sId: string;
    cId: string;
    cAt: number;
    user: User;
    data: Data;
    intent: string;
    workspaceId: string;
    conversation: Conversation;
    chnType: string;
    _v: number;
    source: string;
  };
  channelId: string;
}

export interface SendChannelCampaignResponse {}

export interface CampaignType {
  id: number;
  title: string;
  description: string;
  selected: boolean;
  first?: boolean;
  last?: boolean;
  value: string;
}

interface FunnelCount {
  number: number;
  percentage: number;
}

export interface CampaignDetails {
  campaignName: string;
  status: PublishStatus;
  customerCount: number;
  productCount: number;
  createdDate: string;
  publishedDate: string;
  updatedDate: string;
  description: string;
  type: string;
  productView: string;
  template: string;
  source: string;
  imagePreview: string;
  productVariants: Product[];
  campaignFunnel: CampaignFunnelDetails;
  returnOnInvestments: ReturnOnInvestmentsDetails;
  adId: string;
  postId: string;
  startDate: string;
  endDate: string;
}

export interface CampaignFunnelDetails {
  deliveredCount: FunnelCount;
  sentCount: FunnelCount;
  engagedCount: FunnelCount;
  orderedCount: FunnelCount;
  seenCount: FunnelCount;
  MESSAGE_DELIVERED: FunnelCount;
  MESSAGE_SENT: FunnelCount;
  MESSAGE_SEEN: FunnelCount;
  MESSAGE_ENGAGED: FunnelCount;
}

export interface ReturnOnInvestmentsDetails {
  campaignBudget: number;
  totalReturn: number;
}

interface MetricDataItem {
  dateTime: string;
  description: string;
}

export type MetricData = Record<CampaignMessageEnum, MetricDataItem>;

export interface CampaignDetailsResponse {
  statusCode: number;
  message: string;
  data: CampaignDetails;
}

export interface CampaignDetailsRequest {
  campaignId: string;
  workspaceId: string;
  sellerWorkspaceId: string;
  includeProducts?: boolean;
}

export interface CampaignCustomer {
  firmName: string;
  phoneNumber: string;
  inviteId: string;
  lastName: string;
  firstName: string;
  customerCode: string;
  totalReturn: number;
  metric: MetricData;
}
export interface CampaignCustomersData {
  customers: CampaignCustomer[];
  endRecord: number;
  startRecord: number;
  totalItems: number;
  totalPages: number;
  filter: {
    type: string;
    options: {
      name: CampaignMessageEnum;
      count: number;
      channelId?: string;
      channelName?: string;
    }[];
  }[];
}

export interface CampaignCustomersResponse {
  statusCode: number;
  message: string;
  data: CampaignCustomersData;
}

export interface CampaignCustomersRequest {
  id: string;
  workspaceId: string;
  pageNo: number;
  pageSize: number;
  searchKey?: string;
  stats?: string;
  sellerWorkspaceId: string;
}

export interface CampaignProduct {
  total: number;
  startRecord: number;
  endRecord: number;
  products: Product[];
  categoryCodes: {
    categoryName: string;
    categoryCode: string;
    categoryId: string;
  }[];
  divisionSummary: {
    divisionCode: string;
    divisionId: string;
    name: string;
    headDivisionId: string;
    headDivision: {
      id: string;
      code: string;
      name: string;
    };
    isDefault: boolean;
  }[];
  cfaSummary: {
    cfaCode: string;
    cfaId: string;
    supplierName: string;
    isDefault: boolean;
  }[];
  status: string;
  statusCode: number;
}

export interface CampaignProductsResponse {
  statusCode: number;
  message: string;
  data: CampaignProduct;
}
export interface CampaignProductsRequest {
  campaignId: string;
  sellerWorkspaceId: string;
  workspaceId: string;
  searchKey?: string;
  divisionIds?: string[];
  categoryCodes?: string[];
  pageNo?: number;
  pageSize?: number;
}

export interface RemoveProductFromCampaigntResponse {
  message: string;
}
export interface RemoveProductFromCampaignRequest {
  productVariantIds: number[];
  campaignId: string;
  sellerWorkspaceId: string;
}

export interface RemoveCustomerFromCampaigntResponse {
  message: string;
}
export interface RemoveCustomerProductFromCampaignRequest {
  customerIds: string[];
  campaignId: string;
  sellerWorkspaceId: string;
}

export interface CampaignDetailsV2Response {
  statusCode: number;
  message: string;
  data: CampaignDetailsV2Data;
}

export interface CampaignDetailsV2Data {
  campaignName: string;
  description?: string;
  source?: string;
  type: string;
  createdDate: string;
  id: string;
  productVariants: CampaignDetailsV2ProductVariant[];
  startRecord: number;
  endRecord: number;
  total: number;
}

export interface CampaignDetailsV2ProductVariant {
  parentSku: string;
  productAsset: CampaignDetailsV2ProductAsset;
  source: string;
  preview: string;
  enabled: boolean;
  id: number;
  productVariants: CampaignDetailsV2ProductVariant2[];
  channelId: number;
  workspaceId: string;
}

export interface CampaignDetailsV2ProductAsset {}

export interface CampaignDetailsV2ProductVariant2 {
  facets: any[];
  assets: {
    filename: string;
    redirectUrl: string;
    sequenceNo: number;
    subType: string;
  }[];
  taxCategory: CampaignDetailsV2TaxCategory;
  enabled: boolean;
  price: number;
  MRP: number;
  PTR: number;
  isSpecial: any;
  sku: string;
  sortOrder: number;
  id: number;
  taxCategoryId: number;
  productId: number;
  name: string;
  shortName: string;
  shortDescription: string;
  categories: CampaignDetailsV2Category[];
  productVariantId: number;
  ean: string;
  erpId: string;
  erpPriceUOM: string;
  erpUOM: string;
  ZINS: boolean;
  hsnCode: string;
  upcCode: string;
  baseUom: string;
  quantity: number;
  warrentyInformation: string;
  condition: string;
  packSize: number;
  manufacturingDate: any;
  expiryDate: any;
  bestBeforeOrAfter: any;
  popularity: number;
  bestAfter: any;
  minOrderQty: number;
  maxOrderQty: number;
  minRemShelfLife: number;
  qtyMultiplier: number;
  grossWeight: any;
  netWeight: any;
  volume: any;
  division: CampaignDetailsV2Division[];
  cfas: CampaignDetailsV2Cfa[];
  collections: any[];
  promotions: Promotion[];
  stock: number;
  sales: number;
  margin: string;
  isActiveForOrderCfaIds: string[];
  salesOrg: any;
}

export interface CampaignDetailsV2TaxCategory {
  id: number;
  name: string;
}

export interface CampaignDetailsV2Category {
  categoryId: number;
  categoryName: string;
  categoryCode: string;
}

export interface CampaignDetailsV2Division {
  divisionCode: string;
  divisionId: string;
  name: string;
  headDivisionId: string;
  headDivision: CampaignDetailsV2HeadDivision;
  isDefault: boolean;
}

export interface CampaignDetailsV2HeadDivision {
  id: string;
  code: string;
  name: string;
}

export interface CampaignDetailsV2Cfa {
  cfaCode: string;
  cfaId: string;
  supplierName: string;
  isDefault: boolean;
  minOrderQty: number;
}

export interface CampaignDetailsV2Request {
  workspaceId: string;
  params: {
    campaignId: string;
    customerId?: string;
    sellerWorkspaceId: string;
    includeProducts: boolean;
  };
}

export interface SendCampaignInterface {
  id: string;
  name: string;
  description: string;
  type: string;
  sellerName: string;
  assetUrl: string;
  products: {
    translations: { name: string }[];
    productVariantPrices: { price: number }[];
    productVariantAttribute: { minOrderQty: number };
  };
}

export interface CampaignFiltersResponse {
  status: PublishStatus[];
  imagePreview: string[];
  type: string[];
}
export interface CampaignFiltersRequest {
  workspaceId: string;
  sellerWorkspaceId: string;
  pageNo: number;
  pageSize: number;
}

export interface PublishCampaignResponse {}
export interface PublishCampaignRequest {
  workspaceId: string;
  sellerWorkspaceId: string;
  body: {
    campaignId: string;
    sendAll?: boolean;
    customerIds?: string[];
  };
}


export interface CampaignTemplate {
  campaignName: string;
  campaignDescription: string;
  productView: string;
  imageUri: string;
  productList: {
    imgUrl: string;
    productDescription: string;
    messages: { id: string; value: string }[];
  }[];
  messages: {
    id: string;
    value: string;
    msgId: string;
    isActive: boolean;
  }[];
  template: WhatsappTemplateData;
  templateDescription: string;
  campaignTitle: string;
}