import { ColumnConfig } from '../model';

export const bulkInvoiceUploadConfig: Record<any, ColumnConfig> = {
  invoiceNumber: {
    name: 'Invoice Number',
  },
  invoiceDate: {
    name: 'Invoice Date',
  },
  invoiceCustomerName: {
    name: 'Customer Name',
  },
  invoiceAmt: {
    name: 'Invoice Amt.',
    alignment: 'flex-end',
  },
  invoiceStatus: {
    name: 'Invoice Status',
    isComponent: true,
    alignment: 'center',
  },
};
