import React from 'react';
import './catalog-image-search-screen.scss';
import { SVGS } from '@zonofi/zono-money-design-system';
import { useCatalogImageSearchScreen } from './useCatalogImageSearchScreen';
import {
  CatalogImageSearchCamera,
  CatalogImageSearchFooter,
  CatalogImageSearchResult,
  CatalogImageSearchUpload,
} from '@zonofi/zono-money-whatsapp-stack';

export const CatalogImageSearchScreen: React.FC = () => {
  const {
    onBack,
    uploadedFile,
    uploadedImage,
    onOpenUpload,
    selectedOption,
    parseFileAPIResponse,
    showResult,
    goBackToSearch,
    takePhoto,
    videoRef,
    canvasRef,
    toggleTab,
    error,
    setError,
    onTryAgain,
  } = useCatalogImageSearchScreen();

  return (
    <div className="catalog-image-search-container">
      {showResult &&
      parseFileAPIResponse?.data?.items?.matches?.length !== 0 ? (
        <CatalogImageSearchResult
          imagePreview={uploadedImage}
          parseProductResponse={parseFileAPIResponse?.data?.items?.matches}
          goBackToSearch={goBackToSearch}
        />
      ) : (
        <div className="catalog-image-search font-primary">
          <div className="catalog-image-search-header">
            <div
              className="catalog-image-search-header-btn font-primary-semibold"
              onClick={onBack}
            >
              <img height={15} width={15} src={SVGS.LeftArrowBlack} />
              ZoAi
            </div>
          </div>

          <div className="catalog-image-search-body">
            {selectedOption === 0 ? (
              <CatalogImageSearchCamera
                videoRef={videoRef}
                canvasRef={canvasRef}
                onTakePhoto={takePhoto}
                uploadedFile={uploadedFile}
                uploadedImage={uploadedImage}
                isParsing={parseFileAPIResponse?.isLoading}
                error={error}
                onTryAgain={onTryAgain}
                />
              ) : (
                <CatalogImageSearchUpload
                uploadedFile={uploadedFile}
                uploadedImage={uploadedImage}
                onOpenUpload={onOpenUpload}
                isParsing={parseFileAPIResponse?.isLoading}
                error={error}
                onTryAgain={onTryAgain}
              />
            )}
          </div>
          {!parseFileAPIResponse?.isLoading && (
            <CatalogImageSearchFooter
              selectedOption={selectedOption}
              setSelectedOption={toggleTab}
            />
          )}
        </div>
      )}
    </div>
  );
};
