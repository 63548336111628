import { ColumnConfig, CustomerTrackPOColumnKey } from '../../model';

export const customerTrackPOConfig: Record<
  CustomerTrackPOColumnKey,
  ColumnConfig
> = {
  poNo: {
    name: 'PO Number',
  },
  orderDate: {
    name: 'Order Date',
    field: 'orderDate',
    sortField: 'orderDate',
  },

  status: {
    name: 'Status',
  },
  skuCount: {
    name: 'SKU Count',
    field: 'skuCount',
    sortField: 'skuCount',
    alignment: 'flex-end',
  },
  orderedValue: {
    name: 'Order Value',
    field: 'orderedValue',
    sortField: 'orderedAmount',
    alignment: 'flex-end',
  },
  orderedBy: {
    name: 'Ordered By',
    field: 'orderedBy',
    isVisible: false,
    isAdditional: true,
  },
  discount: {
    name: 'Order Saving',
    field: 'discount',
    sortField: 'orderSavings',
    alignment: 'flex-end',
  },
  invoicedQty: {
    name: 'Invoiced Qty',
    field: 'invoicedQty',
    sortField: 'invoicedQty',
    alignment: 'flex-end',
  },
  invoicedAmount: {
    name: 'Invoiced Amt',
    field: 'invoicedAmount',
    sortField: 'invoicedAmount',
    alignment: 'flex-end',
  },
  invoicedItems: {
    name: 'Invoiced Items',
    isVisible: false,
    isAdditional: true,
    field: 'invoicedItems',
    alignment: 'flex-end',
  },
  invoicedValue: {
    name: 'Invoiced Value',
    isVisible: false,
    isAdditional: true,
    field: 'invoicedValue',
    alignment: 'flex-end',
  },
  download: {
    name: '',
    isVisible: true,
    alignment: 'flex-end',
  },
};
