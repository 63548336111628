import { ColumnConfig, PriceProductListColumnKey } from '../model';

export const PriceProductListConfig: Record<
  PriceProductListColumnKey,
  ColumnConfig
> = {
  sNo: {
    name: 'S. No',
  },
  skuCode: {
    name: 'SKU Code',
    field: 'NavigateLink',
  },
  productTitle: {
    name: 'Product Title',
  },
  status: {
    name: 'Status',
  },
  categories: {
    name: 'Categories',
  },
    // marginPrice: {
    //   name: 'Price with Margin',
    //   isVisible: false,

    // },
    currentPrice: {
      name: 'Current Price',
      isVisible: false,

    },
  // sellingPrice: {
  //   name: 'Selling Price',
  //   field: 'component',
  //   isVisible: false,
  // },
  newBuyingPrice: {
    name: 'New Buying Price',
    isVisible: false,
    sortField: 'newBuyingPrice',
  },
  newSellingPrice: {
    name: 'Selling Price',
    isVisible: false,
    sortField: 'newSellingPrice',
  },

  sellingPrice: {
    name: 'Selling Price',
  },
  sellingPriceInput: {
    name: 'Selling Price',
    field: 'component',
  },
  marginPrice: {
    name: 'Price with Margin',
  },
  marginPriceInput: {
    name: 'Price with Margin',
    field: 'component',
  },
};
