import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState, store } from '../root';
import {
  ChannelListResponse,
  StoredFolder,
  Folder,
  ChannelDetails,
  WhatsappTemplateData,
  ChannelTypeEnum,
  ChannelThread,
} from './model';
import { communicationApi } from './api';
import {
  updateChannelListForSearchQueryHelper,
  updateChannelListForUnreadHelper,
  getChannelsAndFolders,
} from './helpers';
import { isNull, isUndefined } from 'lodash';
import { MultiToastType, UnreadMessageCount } from '../helpers';
import { IssueStatus } from '../thread';
import { getUnreadMesssageCount } from '../message';

export const COMMUNICATION_FEATURE_KEY = 'commsData';

export interface ActionToastDataType {
  visibility: boolean;
  title: string;
  description: string;
  type: MultiToastType;
}

interface UpdateChannelListForUnreadParams {
  switchValue: boolean;
  unReadMessagesCount: Record<string, UnreadMessageCount>;
}

export interface InviteChannelData {
  principalWorkspaceId: string;
  channelId: string;
  modalOpen: boolean;
}

export interface RecentThreadSearcList {
  channelId: string;
  firstDesc: string;
  secondDesc?: string;
  thirdDesc?: string;
}

export interface CommsState {
  channelGroups: string[];
  channels: Record<string, ChannelDetails>;
  appChannels: Record<string, ChannelDetails>;
  waChannels: Record<string, ChannelDetails>;
  folders: Record<string, StoredFolder>;
  currentChannelId: string;
  actionToastData: ActionToastDataType;
  inviteChannelData: InviteChannelData;
  channelListSearchQuery: string;
  tempFolderData: Record<string, StoredFolder>;
  isUnreadSwitchEnabled: boolean;
  channelMemberRoleLoader: boolean;
  customerThreads: Record<string, ChannelDetails>;
  issueThreads: Record<string, ChannelDetails>;
  groupThreads: Record<string, ChannelDetails>;
  threadTabNavigationValue: ChannelTypeEnum;
  whatsappTemplateData: WhatsappTemplateData[];
  logInTracked: boolean;
  isSessionStarted: boolean;
  zoai: Record<string, ChannelDetails>;
  commonThreads: ChannelThread[];
  recentThreadSearchList: RecentThreadSearcList[];
  customersIssueThreadsMap: Record<string, Array<string>>;
}

export const initialCommsState: CommsState = {
  channelGroups: [],
  channels: {},
  appChannels: {},
  waChannels: {},
  folders: {},
  currentChannelId: '',
  channelListSearchQuery: '',
  tempFolderData: {},
  isUnreadSwitchEnabled: false,
  channelMemberRoleLoader: false,
  inviteChannelData: {
    principalWorkspaceId: '',
    channelId: '',
    modalOpen: false,
  },
  actionToastData: {
    visibility: false,
    title: '',
    description: '',
    type: MultiToastType.SUCCESS,
  },
  customerThreads: {},
  issueThreads: {},
  groupThreads: {},
  threadTabNavigationValue: ChannelTypeEnum.CUSTOMERTHREAD,
  whatsappTemplateData: [],
  logInTracked: false,
  isSessionStarted: false,
  zoai: {},
  commonThreads: [],
  recentThreadSearchList: [],
  customersIssueThreadsMap: {},
};

export const commsSlice = createSlice({
  name: COMMUNICATION_FEATURE_KEY,
  initialState: initialCommsState,
  reducers: {
    addRecentThreadSearchList(
      state,
      action: PayloadAction<RecentThreadSearcList[]>
    ) {
      state.recentThreadSearchList = action.payload;
    },
    updateCurrentChannel(state, action: PayloadAction<string>) {
      state.currentChannelId = action.payload;
    },
    updateChannelListForUnread(
      state,
      action: PayloadAction<UpdateChannelListForUnreadParams>
    ) {
      const data: { folders: Record<string, StoredFolder> } = {
        folders: state.tempFolderData,
      };

      const searchResults: Record<string, StoredFolder> =
        updateChannelListForUnreadHelper({
          commsState: state,
          folderData: data,
          unreadMessagesCount: action.payload.unReadMessagesCount,
        });

      state.folders = searchResults;
      state.isUnreadSwitchEnabled = true;
    },
    resetUpdateChannelListForUnread(state) {
      state.folders = state.tempFolderData;
      state.isUnreadSwitchEnabled = false;
    },
    updateChannelListSearchQuery(state, action: PayloadAction<string>) {
      const data: { folders: Record<string, StoredFolder> } = {
        folders: state.tempFolderData,
      };
      const query = action.payload;
      const searchResults: Record<string, StoredFolder> =
        updateChannelListForSearchQueryHelper({
          commsState: state,
          folderData: data,
          searchQuery: query,
        });
      state.folders = searchResults;
      state.channelListSearchQuery = action.payload;
    },
    addChannelsAndFolders(state, action: PayloadAction<ChannelListResponse>) {
      const { folders, channels } = getChannelsAndFolders(action.payload);

      channels.forEach((channel) => {
        const targetState =
          channel.channelSubType === 'WA'
            ? state.waChannels
            : channel.type === 'app'
            ? state.appChannels
            : state.channels;

        if (targetState[channel.id]) {
          targetState[channel.id] = {
            ...targetState[channel.id],
            ...channel,
          };
        } else {
          targetState[channel.id] = channel as unknown as ChannelDetails;
        }
      });

      state.folders = Object.fromEntries(
        folders.map((folder) => [folder.id, folder])
      );
      state.tempFolderData = state.folders;
    },
    updateFolders(state, action: PayloadAction<Folder[]>) {
      const folders = action.payload;
      folders.forEach((folder) => {
        const reqFldr = { ...state.folders?.[folder?.id] };
        const updatedFldr = { ...reqFldr, ...folder };
        state.folders[folder?.id] = updatedFldr as StoredFolder;
      });
    },
    updateActionToastData(state, action: PayloadAction<ActionToastDataType>) {
      state.actionToastData = action.payload;
    },
    updateInviteChannelData(state, action: PayloadAction<InviteChannelData>) {
      state.inviteChannelData = action.payload;
    },
    updateChannelMemberRoleLoader(state, action: PayloadAction<boolean>) {
      state.channelMemberRoleLoader = action.payload;
    },
    updateActionToastDataVisibility(state, action: PayloadAction<boolean>) {
      const tempData = state.actionToastData;
      state.actionToastData = { ...tempData, visibility: action.payload };
    },
    updateThreadTabNavigationValue(
      state,
      action: PayloadAction<ChannelTypeEnum>
    ) {
      state.threadTabNavigationValue = action.payload;
    },
    updateIsSessionStarted(state, action: PayloadAction<boolean>) {
      state.isSessionStarted = action.payload;
    },
    upsertChannel(
      state,
      action: PayloadAction<Record<string, Partial<ChannelDetails>>>
    ) {
      const data = action.payload;

      const stateMap = {
        ...state.channels,
        ...state.appChannels,
        ...state.waChannels,
        ...state.customerThreads,
        ...state.issueThreads,
        ...state.groupThreads,
        ...state.zoai,
      };

      for (const cId in data) {
        const oldData = stateMap[cId];
        const newData = data[cId];
        const updatedData = { ...(oldData || {}), ...newData };
        if (updatedData.channelType === 'C')
          updatedData.type = ChannelTypeEnum.CUSTOMERTHREAD;
        const {
          type,
          channelName,
          channelSubType,
          customerId = '',
        } = updatedData;

        const targetState =
          channelName === 'zo.ai'
            ? state.zoai
            : type === ChannelTypeEnum.APP
            ? state.appChannels
            : channelSubType === 'WA'
            ? state.waChannels
            : type === ChannelTypeEnum.CUSTOMERTHREAD
            ? state.customerThreads
            : type === ChannelTypeEnum.ISSUETHREAD
            ? state.issueThreads
            : type === ChannelTypeEnum.GROUPTHREAD
            ? state.groupThreads
            : state.channels;

        if (JSON.stringify(targetState[cId]) !== JSON.stringify(updatedData))
          targetState[cId] = updatedData as ChannelDetails;

        if (type === ChannelTypeEnum.ISSUETHREAD && customerId) {
          const existingThreads =
            state.customersIssueThreadsMap[customerId] || [];
          const uniqueThreads = new Set(existingThreads);
          uniqueThreads.add(cId);
          state.customersIssueThreadsMap[customerId] =
            Array.from(uniqueThreads);
        }
      }
    },
    removeChannel(state, action: PayloadAction<string>) {
      const channelId = action.payload;
      const allChannels = Object.entries({ ...state.channels });
      const filteredChannels = allChannels.filter(
        ([key, _v]) => key !== channelId
      );
      state.channels = Object.fromEntries(filteredChannels);
      const newChannelId = Object.keys(state.channels)[0];
      state.currentChannelId = newChannelId;
    },
    removeThread(state, action: PayloadAction<string>) {
      const channelId = action.payload;

      // Find the channel type in one pass
      const allThreads = {
        ...state.customerThreads,
        ...state.issueThreads,
        ...state.groupThreads,
      };

      const curr = allThreads[channelId];
      if (!curr) return; // If no channel found, early return

      const { type } = curr;
      let targetState;

      // Determine which state to remove the thread from based on the type
      switch (type) {
        case ChannelTypeEnum.CUSTOMERTHREAD:
          targetState = state.customerThreads;
          break;
        case ChannelTypeEnum.ISSUETHREAD:
          targetState = state.issueThreads;
          break;
        case ChannelTypeEnum.GROUPTHREAD:
          targetState = state.groupThreads;
          break;
        default:
          return; // If no matching type, do nothing
      }

      // Delete the channelId directly from the respective state
      delete targetState[channelId];

      // Update the currentChannelId to the first available channelId after removal
      const newChannelId = Object.keys(targetState)[0];
      state.currentChannelId = newChannelId || ''; // If no new ID, set as empty
    },
    clearAllChannelData(state) {
      state.channels = {};
      state.appChannels = {};
      state.waChannels = {};
      state.folders = {};
      state.currentChannelId = '';
      state.channelListSearchQuery = '';
      state.tempFolderData = {};
      state.inviteChannelData = {
        principalWorkspaceId: '',
        channelId: '',
        modalOpen: false,
      };
      state.actionToastData = {
        visibility: false,
        title: '',
        description: '',
        type: MultiToastType.SUCCESS,
      };
      state.customerThreads = {};
      state.issueThreads = {};
      state.groupThreads = {};
    },
    updateLogInTracked(state, action: PayloadAction<boolean>) {
      state.logInTracked = action.payload;
    },
    allCommonTheads(state, action: PayloadAction<ChannelThread[]>) {
      state.commonThreads = action.payload;
    },
    updateChannelGroups(state, action: PayloadAction<string[]>) {
      state.channelGroups = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      communicationApi.endpoints.getChannels.matchFulfilled,
      (state, action) => {
        commsSlice.caseReducers.addChannelsAndFolders(state, action);
      }
    );
    builder.addMatcher(
      communicationApi.endpoints.getChannelDetails.matchFulfilled,
      (state, action) => {
        const channelData: Partial<ChannelDetails> = Object.fromEntries(
          Object.entries(action.payload).filter(
            ([_key, value]) => !isNull(value) && !isUndefined(value)
          )
        );
        const { id = '', channelName } = channelData;
        const oldChannelName = {
          ...state.channels,
          ...state.waChannels,
          ...state.appChannels,
          ...state.customerThreads,
          ...state.issueThreads,
          ...state.groupThreads,
        }?.[id]?.channelName;
        const payload = {
          ...action,
          payload: {
            [id]: {
              ...channelData,
              channelName: oldChannelName || channelName,
            },
          },
        };
        commsSlice.caseReducers.upsertChannel(state, payload);
      }
    );
    builder.addMatcher(
      communicationApi.endpoints.deleteChannelV2.matchFulfilled,
      (state, action) => {
        const channelId = action.meta.arg.originalArgs.channelId;
        const payload = {
          ...action,
          payload: channelId,
        };
        commsSlice.caseReducers.removeChannel(state, payload);
      }
    );
    builder.addMatcher(
      communicationApi.endpoints.leaveChannel.matchFulfilled,
      (state, action) => {
        const channelId = action.meta.arg.originalArgs.channelId;
        const payload = {
          ...action,
          payload: channelId,
        };
        commsSlice.caseReducers.removeChannel(state, payload);
        commsSlice.caseReducers.removeThread(state, payload);
      }
    );
    builder.addMatcher(
      communicationApi.endpoints.getWhatsappTemplates.matchFulfilled,
      (state, action) => {
        state.whatsappTemplateData = action.payload;
      }
    );
    builder.addMatcher(
      communicationApi.endpoints.getUserChannelGroups.matchFulfilled,
      (state, action) =>
        commsSlice.caseReducers.updateChannelGroups(state, action)
    );
  },
});

export const {
  addRecentThreadSearchList,
  updateCurrentChannel,
  upsertChannel,
  updateFolders,
  updateActionToastData,
  updateActionToastDataVisibility,
  updateInviteChannelData,
  updateChannelListSearchQuery,
  clearAllChannelData,
  updateChannelListForUnread,
  resetUpdateChannelListForUnread,
  updateChannelMemberRoleLoader,
  removeChannel,
  updateThreadTabNavigationValue,
  updateLogInTracked,
  updateIsSessionStarted,
  allCommonTheads,
} = commsSlice.actions;

export const getCommsSliceState = (rootState: RootState): CommsState =>
  rootState[COMMUNICATION_FEATURE_KEY];

export const getCurrentChannelId = createSelector(
  getCommsSliceState,
  (state) => state.currentChannelId
);

export const getAllRecentThreadSearchList = createSelector(
  getCommsSliceState,
  (state) => state.recentThreadSearchList
);

export const getAllChannels = createSelector(
  getCommsSliceState,
  (state) => state.channels
);

export const getAllFolders = createSelector(
  getCommsSliceState,
  (state) => state.folders
);

export const getCurrentChannel = createSelector(getCommsSliceState, (state) =>
  state.currentChannelId !== ''
    ? {
        ...state.channels,
        ...state.waChannels,
        ...state.appChannels,
        ...state.customerThreads,
        ...state.issueThreads,
        ...state.groupThreads,
      }[state.currentChannelId]
    : undefined
);

export const getActionToastData = createSelector(
  getCommsSliceState,
  (state) => state?.actionToastData
);

export const getInviteChannelData = createSelector(
  getCommsSliceState,
  (state) => state?.inviteChannelData
);

export const getChannelListSearchQuery = createSelector(
  getCommsSliceState,
  (state) => state?.channelListSearchQuery
);

export const getIsUnreadSwitchEnabled = createSelector(
  getCommsSliceState,
  (state) => state?.isUnreadSwitchEnabled
);

export const getChannelMemberRoleLoader = createSelector(
  getCommsSliceState,
  (state) => state?.channelMemberRoleLoader
);

export const getAllWaChannels = createSelector(
  getCommsSliceState,
  (state) => state.waChannels
);

export const getAllAppChannels = createSelector(
  getCommsSliceState,
  (state) => state?.appChannels
);

export const getCustomerThreads = createSelector(getCommsSliceState, (state) =>
  Object.values(state.customerThreads)
);

export const getIssueThreads = createSelector(getCommsSliceState, (state) => {
  const threads = Object.values(state.issueThreads);
  const unreadCount = getUnreadMesssageCount(store.getState());
  const issues = threads.filter(
    (thread) =>
      thread.issueStatus !== IssueStatus.CLOSED ||
      (unreadCount?.[thread?.id]?.count ?? 0) > 0
  );
  return issues;
});

export const getClosedIssueThreads = createSelector(
  getCommsSliceState,
  (state) => {
    const threads = Object.values(state.issueThreads);
    const issues = threads.filter(
      (thread) => thread.issueStatus === IssueStatus.CLOSED
    );
    return issues;
  }
);

export const getAllIssueThreads = createSelector(
  getCommsSliceState,
  (state) => state.issueThreads
);

export const getGroupThreads = createSelector(getCommsSliceState, (state) =>
  Object.values(state.groupThreads)
);

export const getAllChannelsAndThreads = createSelector(
  getCommsSliceState,
  (state) => ({
    ...state.channels,
    ...state.waChannels,
    ...state.appChannels,
    ...state.customerThreads,
    ...state.issueThreads,
    ...state.groupThreads,
  })
);

export const getThreadTabNavigationValue = createSelector(
  getCommsSliceState,
  (state) => state.threadTabNavigationValue
);

export const getWhatsappTemplateData = createSelector(
  getCommsSliceState,
  (state) => state.whatsappTemplateData
);

export const getLogInTracked = createSelector(
  getCommsSliceState,
  (state) => state.logInTracked
);

export const getIsSessionStarted = createSelector(
  getCommsSliceState,
  (state) => state.isSessionStarted
);

export const getZoeyChannel = createSelector(
  getCommsSliceState,
  (state) => Object.keys(state.zoai)?.[0]
);

export const getCommonThreads = createSelector(
  getCommsSliceState,
  (state) => state.commonThreads
);

export const getCustomerIssueThreadsMap = createSelector(
  getCommsSliceState,
  (state) => state.customersIssueThreadsMap
);

export const getUserChannelGroups = createSelector(
  getCommsSliceState,
  (state) => state.channelGroups
);
