import React, { useEffect, useState } from 'react';
import { LoadingSpinner, SVGS } from '@zonofi/zono-money-design-system';
import './catalog-image-search-result.scss';
import {
  PIMatch,
  PIMatchedProduct,
  getCustomerInvite,
  useAddItemToActiveOrderMutation,
} from '@zonofi/zono-money-store';
import { getFormattedAmount } from '@zonofi/common';
import { useAppSelector, useCart } from '@zonofi/zono-money-helpers';
import { useCatalogImageSearchResult } from './use-catalog-image-search-result';

interface CatalogImageSearchResultProps {
  imagePreview: any;
  parseProductResponse: PIMatch[];
  goBackToSearch: () => any;
}

export const CatalogImageSearchResult: React.FC<
  CatalogImageSearchResultProps
> = ({ imagePreview, parseProductResponse, goBackToSearch }) => {
  const { seller, inviteId } = useAppSelector(getCustomerInvite);
  const {
    callCartAPI,
    cartAPIResponse,
    productsInCart,
    setProductsInCart,
    cartSummaryAPIResponse,
    onIncrement,
    onDecrement,
    onDelete,
    onChangeQuantity,
    editOrderResponse,
    quantityMap,
    parsedProducts,
    onAddToCart,
    setQuantityMap,
    hidePrice,
  } = useCatalogImageSearchResult(parseProductResponse);

  return (
    <div className="catalog-image-result font-primary">
      <div className="catalog-image-result-header">
        <div className="catalog-image-result-header-search">
          <img
            height={15}
            width={15}
            src={SVGS.LeftArrowBlack}
            onClick={goBackToSearch}
          />
          <div className="catalog-image-result-header-preview-container">
            <img
              src={imagePreview}
              className="catalog-image-result-header-preview"
            />
          </div>
        </div>
      </div>
      <div className="catalog-image-result-list">
        {parsedProducts?.map((i) => {
          if (i?.matchedProducts?.length === 0) {
            return (
              <span className="catalog-image-result-list-item-error font-primary-semibold">
                No results for “{i?.originalInput?.title}”
              </span>
            );
          }
          return (
            <div className="catalog-image-result-list-item">
              <span className=" font-primary-semibold">
                {i?.matchedProducts?.length} result(s) for “
                {i?.originalInput?.title}”
              </span>
              {i?.matchedProducts?.map((matchedProduct) => {
                const isAddedToCart = productsInCart?.find(
                  (i) => i?.productVariantId === matchedProduct?.variantId
                );
                return (
                  <div className="catalog-image-result-list-item-product">
                    <div className="catalog-list-item">
                      <div className="catalog-list-item-info-container">
                        {true && (
                          <img
                            src={
                              matchedProduct?.imageUrl
                                ? matchedProduct?.imageUrl
                                : SVGS.DefaultProduct
                            }
                            className="catalog-list-item-img"
                          />
                        )}
                        <div className="catalog-list-item-info">
                          <span className=" font-primary-semibold">
                            {matchedProduct?.title}
                          </span>

                          {!hidePrice && (
                            <span className=" font-primary-semibold">
                              {false ? (
                                <div className="catalog-list-item-info-price-container">
                                  <span className="catalog-list-item-info-price-container-striked">
                                    {getFormattedAmount(
                                      (matchedProduct?.price ?? 0) / 100
                                    ) ?? 0}
                                  </span>
                                  {getFormattedAmount(
                                    matchedProduct?.price ?? 0
                                  ) ?? 0}
                                </div>
                              ) : (
                                getFormattedAmount(
                                  (matchedProduct?.price ?? 0) / 100
                                ) ?? 0
                              )}
                            </span>
                          )}
                        </div>
                      </div>
                      {isAddedToCart ? (
                        <div className="catalog-list-item-qty-selector-container">
                          <img
                            src={SVGS.DeleteRed}
                            onClick={() =>
                              onDelete(
                                isAddedToCart as any,
                                seller?.workspaceId,
                                inviteId
                              )
                            }
                          />
                          <div className="catalog-list-item-qty-selector font-primary-semibold">
                            <img
                              src={SVGS.MinusPrimaryCyan}
                              onClick={() =>
                                onDecrement(
                                  isAddedToCart as any,
                                  seller?.workspaceId,
                                  inviteId
                                )
                              }
                            />
                            {matchedProduct?.isLoading ? (
                              <div className="catalog-list-item-qty-selector-loader">
                                <LoadingSpinner size={15} />
                              </div>
                            ) : (
                              <input
                                className="catalog-list-item-qty-selector-input"
                                type="text"
                                inputMode="numeric"
                                pattern="[0-9]*[.,]?[0-9]+"
                                key={matchedProduct?.variantId}
                                onKeyDown={(e) => {
                                  e.key === 'Enter' &&
                                    onChangeQuantity(
                                      isAddedToCart as any,
                                      quantityMap[matchedProduct?.variantId],
                                      seller?.workspaceId,
                                      inviteId
                                    );
                                }}
                                onBlur={() => {
                                  onChangeQuantity(
                                    isAddedToCart as any,
                                    quantityMap[matchedProduct?.variantId],
                                    seller?.workspaceId,
                                    inviteId
                                  );
                                }}
                                onChange={(e) => {
                                  if (!isNaN(Number(e.target.value))) {
                                    let initialQuantiyMap = quantityMap;
                                    if (matchedProduct?.variantId) {
                                      initialQuantiyMap[
                                        matchedProduct?.variantId
                                      ] = Number(e.target.value);
                                    }
                                    setQuantityMap({ ...initialQuantiyMap });
                                  }
                                }}
                                value={quantityMap[matchedProduct?.variantId]}
                              />
                            )}
                            <img
                              src={SVGS.PlusPrimaryCyan}
                              onClick={() =>
                                onIncrement(
                                  isAddedToCart as any,
                                  seller?.workspaceId,
                                  inviteId
                                )
                              }
                            />
                          </div>
                        </div>
                      ) : (
                        <div
                          className="catalog-list-item-add-to-cart font-primary-semibold"
                          onClick={() =>
                            matchedProduct?.isLoading
                              ? {}
                              : onAddToCart(matchedProduct)
                          }
                        >
                          {matchedProduct?.isLoading ? (
                            <LoadingSpinner size={15} />
                          ) : (
                            <span>Add</span>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
      <div className="catalog-image-result-footer"></div>
    </div>
  );
};
