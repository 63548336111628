import { ChatActionSendInvoicesColumnKey, ColumnConfig } from '../../model';

export const chatActionSendInvoicesConfig: Record<
  ChatActionSendInvoicesColumnKey,
  ColumnConfig
> = {
  invoiceNumber: {
    name: 'Invoice',
    field: 'checkboxNavigate',
    isCheckboxField: true,
  },
  coNumber: {
    name: 'CO Number',
  },
  status: {
    name: 'Status',
  },
  dueDate: {
    name: 'Due Date',
    sortField: 'dueDate',
  },
  invoiceAmount: {
    name: 'Invoice Amount',
    sortField: 'invoiceAmount',
    alignment: 'flex-end',
  },
  amountPaid: {
    name: 'Amount Paid',
    sortField: 'paidAmount',
    alignment: 'flex-end',
  },
  paymentId: {
    name: 'Payment Id',
  },
  action: {
    name: '',
    field: 'component',
  },
};
