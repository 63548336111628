import * as React from 'react';
import { SHARED_SVGS } from '@zonofi/shared';
import { GetString } from './get-string';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import './kebab-menu.scss';
import { useNavigate } from 'react-router-dom';

export interface KebabMenuProps {
  options: {
    text: string;
    icon?: string;
    onClick: () => void;
  }[];
  CamId?: string;
}

export interface KebabMenuCampaignProps {
  options: {
    text: string;
    icon?: string;
    onClick: ([campaignId]: string, name: string) => void;
  }[];
  optionsForNotPublished: {
    text: string;
    icon?: string;
    onClick: ([campaignId]: string, name: string) => void;
  }[];
  CamId?: string;
}

export const KebabMenu: React.FC<KebabMenuProps | KebabMenuCampaignProps> = ({
  options,
  CamId,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleShare = () => {};

  return (
    <div>
      <img
        alt="kebab"
        src={SHARED_SVGS.KebabMenu}
        onClick={handleClick}
        width={'20px'}
        height={'20px'}
      />
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: {
            style: {
              width: '200px',
              left: '780px',
              height: '110px',
            },
          },
        }}
      >
        {options.map((option) => (
          <MenuItem
            sx={{
              padding: 0,
              width: '100%',
              backgroundColor: 'transparent',
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }}
            key={option.text}
            onClick={
              option?.text === 'Share'
                ? () => {
                    if (CamId) {
                      option?.onClick(CamId);
                    }
                  }
                : () => {
                    if (CamId) {
                      option?.onClick(CamId);
                    }
                  }
            }
          >
            <GetString
              className="kebab-menu-option"
              text={option.text}
              icon={option.icon}
            />
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
