/* eslint-disable @nx/enforce-module-boundaries */
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/query/react';
import {
  store,
  getToken,
  getRefreshToken,
  onRefreshToken,
  logOut,
  getAppType,
  getFCToken,
  getFCRefreshToken,
  fieldCollectionRefreshToken,
  fieldCollectionLogOut,
  isGuestAuthenticated,
  getCustomerInvite,
  OnGuestUserAuthenticated,
  AppType,
} from '@zonofi/zono-money-store';
import _ from 'lodash';
import { getConfigValue } from '@zonofi/common';
const REDUCER_PATH_ZONO_API = 'zonoAPI';

interface RefreshTokenResponse {
  refreshToken: string;
  token: string;
}

const isZonoMoney = () => {
  const appType = getAppType(store.getState());
  return !_.isUndefined(appType) && appType === AppType.ZONOFI;
};

const baseQuery = fetchBaseQuery({
  baseUrl: getConfigValue('API_ENDPOINT'),
  prepareHeaders: (headers) => {
    const accessToken = isZonoMoney()
      ? getToken(store.getState())
      : getFCToken(store.getState());

    if (accessToken) {
      headers.set('authorization', `Bearer ${accessToken}`);
    }

    return headers;
  },
});

export const baseQueryWithReAuth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError,
  any,
  FetchBaseQueryMeta
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  // if (result.error && result.error.status === 401) {
  //   isGuestAuthenticated(store.getState())
  //     ? await handleZonoMoneyGuestRegenerateToken(api, extraOptions)
  //     : await handleAppsRegenerateToken(api, extraOptions, args);

  //   result = await baseQuery(args, api, extraOptions);
  // }
  if (result?.error?.status === 401) {
    //using js redirect to /error-screen
    window.location.href = '/error-screen';
  }
  return result;
};

const handleZonoMoneyGuestRegenerateToken = async (
  api: any,
  extraOptions: any
) => {
  const customerInvite = getCustomerInvite(store.getState());
  const refreshGuestResult = await baseQuery(
    {
      url: `user/guestToken/${customerInvite?.inviteId}`,
      method: 'GET',
      responseHandler: (response) => response.text(),
    },
    api,
    extraOptions
  );
  api.dispatch(OnGuestUserAuthenticated(refreshGuestResult?.data as string));
};

const handleAppsRegenerateToken = async (
  api: any,
  extraOptions: any,
  args: string | FetchArgs
) => {
  const accessToken = isZonoMoney()
    ? getToken(store.getState())
    : getFCToken(store.getState());

  const refreshToken = isZonoMoney()
    ? getRefreshToken(store.getState())
    : getFCRefreshToken(store.getState());

  const refreshResult = await baseQuery(
    {
      url: 'regenerate/token',
      method: 'POST',
      body: {
        refreshToken: refreshToken,
        token: accessToken,
      },
    },
    api,
    extraOptions
  );
  if (refreshResult.data) {
    //@ts-ignore
    const tokens: RefreshTokenResponse = refreshResult.data;
    isZonoMoney()
      ? api.dispatch(onRefreshToken(tokens))
      : api.dispatch(fieldCollectionRefreshToken(tokens));
  } else {
    isZonoMoney()
      ? api.dispatch(logOut())
      : api.dispatch(fieldCollectionLogOut());
  }
};

export const zonoApi = createApi({
  reducerPath: REDUCER_PATH_ZONO_API,
  baseQuery: baseQueryWithReAuth,
  endpoints: () => ({}),
  tagTypes: [
    'User',
    'Invoice',
    'Invoices',
    'PartyAccountBook',
    'WorkSpaceDetails',
    'CustomerInvite',
    'Payment',
    'BankAccount',
    'UPIAccount',
    'Rewards',
    'Profile',
    'Visits',
  ],
});
