import {
  createSlice,
  createEntityAdapter,
  PayloadAction,
  createSelector,
} from '@reduxjs/toolkit';
import { sentTeamsInvitesApi } from './api';
import { TeamMember } from './model';
import { RootState } from '../root';

export const sentTeamInvitesAdapter = createEntityAdapter({
  selectId: (invite: TeamMember) => invite.id,
});

interface State {
  isLoading: boolean;
  teamMembers: TeamMember[];
}

const initialState: State = {
  isLoading: false,
  teamMembers: [],
};

export const sentTeamInvitesSlice = createSlice({
  name: 'sentTeamInvites',
  initialState: sentTeamInvitesAdapter.getInitialState(initialState),
  reducers: {
    addSentTeamInvite: sentTeamInvitesAdapter.upsertMany,
    updateSentTeamInvite: sentTeamInvitesAdapter.upsertOne,
    removeSentTeamInvite: sentTeamInvitesAdapter.removeOne,
    getSentTeamInvitesSuccess(state, action: PayloadAction<TeamMember[]>) {
      sentTeamInvitesAdapter.setAll(state, action.payload);
      console.log('state in get team invite success', action.payload);
      return state;
    },
    getSentTeamInvitesFailed(state, action: PayloadAction<string>) {
      console.log('in get team invite failure ', state, action);
    },
    updateTeamMembers(state, action: PayloadAction<TeamMember[]>) {
      state.teamMembers = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      sentTeamsInvitesApi.endpoints.getChannelMembersSentInvite.matchFulfilled,
      (state, action) => {
        sentTeamInvitesSlice.caseReducers.addSentTeamInvite(state, action);
      }
    );
  },
});

export const {
  getSentTeamInvitesFailed,
  getSentTeamInvitesSuccess,
  addSentTeamInvite,
  updateSentTeamInvite,
  removeSentTeamInvite,
} = sentTeamInvitesSlice.actions;

const SLICE_FEATURE_KEY = 'sentTeamInvites';

export const sentTeamInvitesSliceState = (rootState: RootState): State =>
  rootState[SLICE_FEATURE_KEY];

export const getTeamMembers = createSelector(
  sentTeamInvitesSliceState,
  (state) => state?.teamMembers
);
