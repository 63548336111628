import React from 'react';
import './SuccessMsg.scss';
import { SVGS } from '@zonofi/zono-money-design-system';
import { getCustomerInvite } from '@zonofi/zono-money-store';
import { useAppSelector } from '@zonofi/zono-money-helpers';
import { getConfigValue } from '@zonofi/common';
export const SuccessMsg = () => {
  const inviteDetails = useAppSelector(getCustomerInvite);
  const { seller, waConfig } = inviteDetails;

  const onClickBackToWhatsapp = () => {
    if (waConfig?.whatsappPhoneNo && waConfig?.whatsappPhoneNo?.trim()) {
      window.location.href = `https://wa.me/${waConfig.whatsappPhoneNo}`;
    } else if (seller?.workspaceId === 'c9bedd72-85d7-41c4-8c62-e33ffc926966') {
      window.location.href = getConfigValue('WAPP_REDIRECT_BJ_GROUP');
    } else {
      window.location.href = getConfigValue('WAPP_REDIRECT');
    }
  };
  return (
    <div className="success-top-container">
      <div className="success-msg-container">
        <img src={SVGS?.SuccessfulKycMsgIcon} alt="successMsg" />
        <p className="success-heading">KYC Completed Successfully</p>
        <p>
          आपके KYC विवरण जमा करने के लिए धन्यवाद! हम आपके विवरण की समीक्षा कर
          रहे हैं। इस बीच, ग्रामोफोन के 🌟 Bestsellers और 💥 Exclusive Offers
          देखें!
        </p>
      </div>
      <button
        type="button"
        className="back-to-whatsapp"
        onClick={onClickBackToWhatsapp}
      >
        Back to Whatsapp
      </button>
    </div>
  );
};
