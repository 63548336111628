import _ from 'lodash';

export const SUPPORT_USER_ROUTES: any = {
  CUSTOMERS_LIST: '/support/customers',
};

export const SUPER_ADMIN_ROUTES: any = {
  ROLES_LIST: '/admin/roles',
};

export const USERS_LIST: any = {
  USERS: '/support/users',
  MANAGE_ACCESS: '/support/users/access',
  PRINCIPAL_USERS: '/principal/users',
  MAPPING_REPORT: '/mapping/reports',
};

export type allRoutesType = {
  [key: string]: { path: string; permissions: Array<permissions> };
};

export type permissions =
  | 'collection_report_write'
  | 'role_assignment_write'
  | 'outstanding_report_read'
  | 'outstanding_report_write'
  | 'events_write'
  | 'scheduler_task_read'
  | 'document_read'
  | 'scheduler_task_write'
  | 'promotion_read'
  | 'invoice_write'
  | 'order_read'
  | 'team_member_assignment_read'
  | 'settlements_write'
  | 'promotion_write'
  | 'order_write'
  | 'customer_groups_read'
  | 'einvoice_read'
  | 'division_read'
  | 'customer_groups_write'
  | 'tax_read'
  | 'division_write'
  | 'einvoice_write'
  | 'customer_hierarchy_read'
  | 'product_read'
  | 'cfa_read'
  | 'role_assignment_read'
  | 'customer_hierarchy_write'
  | 'cfa_write'
  | 'channel_read'
  | 'hierarchy_dimensions_read'
  | 'product_write'
  | 'customer_write'
  | 'collection_write'
  | 'settings_read'
  | 'country_read'
  | 'hierarchy_dimensions_write'
  | 'customer_assignment_read'
  | 'party_account_book_read'
  | 'workspace_read'
  | 'settings_write'
  | 'party_account_book_write'
  | 'events_read'
  | 'payment_read'
  | 'team_member_read'
  | 'workspace_write'
  | 'payments_write'
  | 'subscriptions_read'
  | 'recevied_payments_read'
  | 'subscriptions_write'
  | 'reminders_read'
  | 'po_read'
  | 'team_member_assignment_write'
  | 'po_write'
  | '';

interface routesType {
  path: string;
  permissions?: permissions[];
  isSellerRoute?: boolean;
  method?: (principalName: string) => string;
}
export const ALL_ROUTES: any = {
  HOME_PAGE: { path: '/admin/home', permissions: [] },
  SUPPORT_HOME_PAGE: { path: '/support/home', permissions: [] },
  SUPPORT_CUSTOMERS_LIST: { path: '/support/customers', permissions: [] },
  SUPPORT_REPORTS_LIST: { path: '/support/reports', permissions: [] },
  SALES_TEAM_ACTIVITY_MEMBER_DETAILS: {
    path: '/admin/sales-team-activity/member-details',
    permissions: [],
  },
  LOGIN: { path: '/login' }, // Onboarding Route
  OTP: { path: '/otp' }, // Onboarding Route
  // REPORTS_PAGE: {
  //   path: '/admin/reports',
  //   permissions: ['outstanding_report_read'],
  // },
  SELECT_PRINCIPAL_PAGE: {
    path: '/admin/principal/select-principal',
    permissions: ['workspace_read'],
    isSellerRoute: true,
  },
  COMMERCE_BASE_ROUTE: {
    path: '/admin/principal/:principal',
    permissions: [],
    isSellerRoute: true,
  },

  /// Commerce END
  COMMUNICATION_BASE_ROUTE: {
    path: '/admin/communication',
    permissions: ['channel_read'],
    method: () => '/admin/communication',
  },
  CAMPAIGNS_BASE_ROUTE: {
    path: '/admin/campaigns',
    permissions: ['channel_read'],
    method: () => '/admin/communication',
  },
  THREAD_BASE_ROUTE: {
    path: '/admin/thread',
    permissions: [],
    method: () => '/admin/thread',
  },
  CONVERSATION_SCREEN: { path: '/channels', permissions: ['channel_read'] },
  CHANNELS_DETAILS_SCREEN: {
    path: '/channels/channelDetails',
    permissions: ['channel_read'],
  },
  THREAD_DETAILS_SCREEN: {
    path: '/threads/threadDetails',
    permissions: ['channel_read'],
  },
  THREADS_SCREEN: { path: '/threads', permissions: ['channel_read'] },
  CREATE_CHANNEL_SCREEN: {
    path: '/create-channel',
    permissions: ['channel_write'],
  },
  TEAM_INVITES_LIST: {
    path: '/admin/team-members',
    permissions: ['team_member_read'],
  },
  SALES_TEAM_SCREEN: {
    path: '/admin/team-members/details',
  },
  HIERARCHY_CREATE: {
    path: '/admin/hierarchy/create',
    permissions: ['customer_hierarchy_write'],
    isSellerPath: true,
  },
  HIERARCHY_LIST: {
    path: '/admin/hierarchy/list',
    permissions: ['customer_hierarchy_read'],
    isSellerPath: true,
  },
  HIERARCHY_GROUP: {
    path: '/admin/hierarchy/group',
    permissions: ['customer_hierarchy_read'],
    isSellerPath: true,
  },
  HIERARCHY_GROUP_CREATE: {
    path: '/admin/hierarchy/creategroups',
    permissions: ['customer_hierarchy_write'],
    isSellerPath: true,
  },
  HIERARCHY_SEGMENTATION: {
    path: '/admin/hierarchy/segmentation',
    permissions: ['hierarchy_dimensions_write'],
    isSellerPath: true,
  },
  SETTINGS: { path: '/admin/settings', permissions: ['settings_read'] },
  IMPORTS: { path: '/admin/import', permissions: ['settings_write'] },
  BULK_IMPORTS: {
    path: '/admin/import',
    permissions: ['settings_write'],
  },
  BULK_IMPORT_DETAILS: {
    path: '/admin/import/details',
    permissions: ['settings_read'],
    isSellerPath: true,
  },
  ERP_UPLOAD_BATCH_DETAILS: {
    path: '/admin/erp-upload/details',
    permissions: ['settings_read'],
  },
  ERP_UPLOAD_BATCH_COLUMN_MAPPING: {
    path: '/admin/erp-upload/column-map',
    permissions: ['settings_read'],
  },
  FIRM_DETAILS: {
    path: '/admin/myAccount/firmDetails',
    permissions: ['workspace_read'],
  },
  CREDENTIALS: {
    path: '/admin/myAccount/credentials',
    permissions: ['workspace_write'],
  },
  CONFIGURATIONS: {
    path: '/admin/configurations',
    permissions: ['workspace_write'],
    isSellerPath: true,
  },
  PAYMENTS: {
    path: '/admin/myAccount/payments',
    permissions: ['payments_write'],
  },
  NOTIFICATIONS: {
    path: '/admin/myAccount/notifications',
    permissions: ['workspace_read'],
  },

  ZONO_MONEY_BASE_ROUTE: {
    path: '/admin/payments',
    permissions: ['payments_read'],
  },
  CUSTOMER_SETTINGS: {
    path: '/admin/customer-config',
    permissions: ['settings_read'],
  },
  WORKSPACE_SETTINGS: {
    path: '/admin/workspace-config',
    permissions: ['settings_write'],
  },
  DIVISION_CONFIGURATION_SETTINGS: {
    path: '/admin/division-config',
    permissions: ['settings_read'],
  },
  CUSTOMER_GROUP_SETTINGS: {
    path: '/admin/customer-group-config',
    permissions: ['settings_read'],
  },

  CFA_SETTINGS: { path: '/admin/cfa', permissions: ['cfa_read'] },

  DAYBOOK_BASE_ROUTE: {
    path: 'daybook',
    permissions: ['party_account_book_read'],
  },
  DAYBOOK_DETAILS: {
    path: 'details',
    permissions: ['party_account_book_read'],
  },
  COLLECTION: { path: 'collection', permissions: ['collection_read'] },
  DUE: { path: 'due', permissions: ['party_account_book_read'] },
  DEPOSITED: { path: 'deposited', permissions: ['party_account_book_read'] },
  TEAM_COLLECTION: { path: 'teamCollection', permissions: ['collection_read'] },
  TEAM_COLLECTION_SUMMARY: {
    path: 'teamCollectionSummary',
    permissions: ['collection_read'],
  },
  TEAM_MEMBER_OUTSTANDING_SUMMARY: {
    path: 'teamMemberOutstandingSummary',
    permissions: ['outstanding_report_read'],
  },
  DUE_BY_CUSTOMER: {
    path: 'dueByCustomerReport',
    permissions: ['outstanding_report_read'],
  },
  DUE_BY_ROUTE: {
    path: 'dueByRoute',
    permissions: ['outstanding_report_read'],
  },
  CHEQUE_BOOK: {
    path: '/cheque-book',
    permissions: ['party_account_book_read'],
  },
  CUSTOMER_LEDGER_SUMMARY: {
    path: 'customerLedgerSummary',
    permissions: ['party_account_book_read'],
  },
  OUTSTANDING_BY_ROUTE: {
    path: 'outstandingByRoute',
    permissions: ['outstanding_rteport_read'],
  },
  OUTSTANDING_BY_TEAM_MEMBER: {
    path: 'outstandingByTeamMember',
    permissions: ['outstanding_rteport_read'],
  },
  CUSTOMER_BASE_ROUTE: {
    path: 'customers',
    permissions: ['hierarchy_dimensions_write'],
  },
  CUSTOMER_LIST: { path: 'list', permissions: ['customer_groups_read'] },
  CUSTOMER_PROFILE: { path: 'profile', permissions: ['customer_groups_read'] },
  CUSTOMER_LEDGER: { path: 'ledger', permissions: ['party_account_book_read'] },
  CUSTOMER_INVOICE: { path: 'invoice', permissions: ['invoice_read'] },
  CUSTOMER_TRANSACTION: {
    path: 'transaction',
    permissions: ['payments_write'],
  },
  CUSTOMER_ACCOUNT_DETAILS: {
    path: 'accountDetails',
    permissions: ['customer_read'],
  },
  INVOICE_BASE_ROUTE: { path: 'invoices', permissions: ['invoice_read'] },
  ALL_INVOICES: { path: 'all', permissions: ['invoice_read'] },
  OVERDUE_INVOICES: { path: 'overDue', permissions: ['invoice_read'] },
  UNPAID_INVOICES: { path: 'unPaid', permissions: ['invoice_read'] },
  PAID_INVOICES: { path: 'paid', permissions: ['invoice_read'] },
  INVOICE_DETAILS: { path: 'details', permissions: ['invoice_read'] },
  TRANSACTIONS: {
    path: 'transactions',
    permissions: ['party_account_book_read'],
  },
  RECEIVING_PAYMENTS: {
    path: 'receivingPayments',
    permissions: ['received_payments_read'],
  },
  SETTLEMENTS: {
    path: 'settlements',
    permissions: ['party_account_book_read'],
  },
  RECORD_RECEIVING_PAYMENTS: {
    path: 'recordReceivingPayments',
    permissions: ['received_payments_read'],
  },
  RECEIVING_PAYMENT_DETAILS: {
    path: 'receivingPaymentsDetails',
    permissions: ['received_payments_read'],
  },
  RECORD_PAYMENT: { path: 'records', permissions: ['received_payments_read'] },
  PAYMENT_DETAILS: { path: 'details', permissions: ['payment_read'] },
  CUSTOMER_HELP: {
    path: '/admin/invites/helpCustomer',
    permissions: ['customer_read'],
  },
  ADD_CUSTOMERS: {
    path: '/admin/invites/addcustomers',
    permissions: ['customer_hierarchy_write'],
  },
  SELECTION_HIERARCHY: {
    path: '/admin/invites/selectionhiearachy',
    permissions: ['customer_hierarchy_write'],
  },
  INVOICE_HELP: {
    path: '/admin/payments/invoiceHelp',
    permissions: ['payment_read'],
  },
  EKYC_DETAILS: {
    path: '/admin/payments/ekyc/ekycDetails',
    permissions: ['payments_write'],
  },
  ORDER_LIST: {
    path: '/admin/principal/:principal/order',
    method: (principalName: string) =>
      `/admin/principal/${principalName.split(' ').join('-')}/order`,
    permissions: ['order_read'],
  },

  CLAIM_DETAILS_SCREEN: { path: '/claim/details', permissions: ['claim_read'] },
  ORDER_DETAILS_SCREEN: { path: '/order/details', permissions: ['order_read'] },

  CLAIMS_LIST: {
    path: '/admin/principal/:principal/claims',
    method: (principalName: string) =>
      `/admin/principal/${principalName.split(' ').join('-')}/claims`,
    permissions: ['claim_read'],
  },
  INVOICES_LIST: { path: '/admin/sales/invoices', permissions: ['order_read'] },
  INVOICE_LIST: { path: '/admin/sales/invoice', permissions: ['order_read'] },
  INVOICE_LIST_COMMERCE: {
    path: '/admin/principal/:principal/invoice/',
    method: (principalName: string) =>
      `/admin/principal/${principalName.split(' ').join('-')}/invoice`,
    permissions: ['order_read'],
  },
  INVOICES_LIST_COMMERCE: {
    path: '/admin/principal/:principal/invoices/',
    method: (principalName: string) =>
      `/admin/principal/${principalName.split(' ').join('-')}/invoices`,
    permissions: ['order_read'],
  },
  INVOICES_LIST_CFA: '/admin/invoices',
  CHANNEL_INVITE_ACCEPT: {
    path: '/channels/invite',
    // method: (id: string, inviteId: string, principalName: string) =>
    //   `/admin/principal/${principalName
    //     .split(' ')
    //     .join('-')}/invoices/details?id=${id}&inviteId=${inviteId}`,
    // permissions: ['invoice_read'],
  },
  WHATSAPP_ONBOARDING_SCREEN: {
    path: '/admin/whatsapp/onboarding',
    permissions: [],
  },
};

export const CUSTOMER_COMMERCE_APP_ROUTES = {
  LEDGERS_PAYMENT_DETAILS: {
    path: '/admin/principal/:principal/ledgers/paymentDetails/:paymentId',
    method: (id: any, principalName: string) =>
      `/admin/principal/${principalName
        .split(' ')
        .join('-')}/ledgers/paymentDetails/${id}`,
    permissions: ['party_account_book_write'],
  },

  LEDGERS_LIST: {
    path: '/admin/principal/:principal/ledgers',
    method: (principalName: string) =>
      `/admin/principal/${principalName.split(' ').join('-')}/ledgers`,
    permissions: ['party_account_book_read'],
  },
  PRODUCTS_LIST: {
    path: '/admin/principal/:principal/products',
    method: (principalName: string) =>
      `/admin/principal/${principalName.split(' ').join('-')}/products`,
    permissions: ['product_read'],
  },
  SCHEMES_LIST: {
    path: '/admin/principal/:principal/schemes',
    method: (principalName: string) =>
      `/admin/principal/${principalName.split(' ').join('-')}/schemes`,
    permissions: ['promotion_read'],
  },
  ORDERS_LIST: {
    path: '/admin/principal/:principal/orders',
    method: (principalName: string) =>
      `/admin/principal/${principalName.split(' ').join('-')}/orders`,
    permissions: ['order_read'],
  },

  ORDERS_VIEW_DETAILS: {
    path: '/admin/principal/:principal/orders/:id',
    method: (id: number, principalName: string) =>
      `/admin/principal/${principalName.split(' ').join('-')}/orders/${id}`,
    permissions: ['order_read'],
  },
  TRACK_PO_LIST: {
    path: '/admin/principal/:principal/orders/trak-po',
    method: (principalName: string) =>
      `/admin/principal/${principalName.split(' ').join('-')}/track-po`,
    permissions: ['po_read'],
  },
  TRACK_PO_VIEW_DETAILS: {
    path: '/admin/principal/:principal/orders/track-po/:id',
    method: (id: string, principalName: string) =>
      `/admin/principal/${principalName
        .split(' ')
        .join('-')}/orders/track-po/${id}`,
    permissions: ['po_read'],
  },
  MANUAL_ORDER: {
    path: '/admin/principal/:principal/place-order/manual',
    method: (principalName: string) =>
      `/admin/principal/${principalName
        .split(' ')
        .join('-')}/place-order/manual`,
    permissions: ['order_write', 'sales_order_write'],
  },
  UPLOAD_ORDER: {
    path: '/admin/principal/:principal/place-order/upload',
    method: (principalName: string) =>
      `/admin/principal/${principalName
        .split(' ')
        .join('-')}/place-order/upload`,
    permissions: ['order_write'],
  },
  RETURNS_LIST: {
    path: '/admin/principal/:principal/returns',
    method: (principalName: string) =>
      `/admin/principal/${principalName.split(' ').join('-')}/returns`,
    permissions: ['claim_read'],
  },
  MANUAL_RETURN: {
    path: '/admin/principal/:principal/create-return/manual',
    method: (principalName: string) =>
      `/admin/principal/${principalName
        .split(' ')
        .join('-')}/create-return/manual`,
    permissions: ['claim_write'],
  },
  RETURNS_VIEW_DETAILS: {
    path: '/admin/principal/:principal/returns/:id',
    method: (id: number, principalName: string) =>
      `/admin/principal/${principalName.split(' ').join('-')}/returns/${id}`,
    permissions: ['claim_read'],
  },
  UPLOAD_RETURN: {
    path: '/admin/principal/:principal/create-return/upload',
    method: (principalName: string) =>
      `/admin/principal/${principalName
        .split(' ')
        .join('-')}/create-return/upload`,
    permissions: ['claim_write'],
  },
  INVOICES_LIST: {
    path: '/admin/principal/:principal/invoices',
    method: (principalName: string) =>
      `/admin/principal/${principalName.split(' ').join('-')}/invoices`,
    permissions: ['invoice_read'],
  },
  PAYMENTS_LIST: {
    path: '/admin/principal/:principal/payments',
    method: (principalName: string) =>
      `/admin/principal/${principalName.split(' ').join('-')}/payments`,
    permissions: ['payments_read'],
  },
  PAYMENTS_VIEW_DETAILS: {
    path: '/admin/principal/:principal/payments/details',
    method: (id: string, principalName: string) =>
      `/admin/principal/${principalName
        .split(' ')
        .join('-')}/payments/details?id=${id}`,
    permissions: ['payments_read'],
  },
  INVOICES_VIEW_DETAILS: {
    path: '/admin/principal/:principal/invoices/details',
    method: (id: string, inviteId: string, principalName: string) =>
      `/admin/principal/${principalName
        .split(' ')
        .join('-')}/invoices/details?id=${id}&inviteId=${inviteId}`,
    permissions: ['invoice_read'],
  },
  WORKSPACE_SETTINGS: {
    path: '/admin/workspace-config',
    permissions: ['settings_write'],
  },

  // FOR CFA ONLY ROUTES --> PERMISSIONS CASE
};

export const PERMISSIONS_LIST = {
  CUSTOMERS_LIST: {
    permissions: ['customer_read'],
  },
  DIVISIONS_LIST: {
    permissions: ['division_read'],
  },
  CFA_LIST: {
    permissions: ['cfa_read'],
  },
  INVOICES_LIST: {
    permissions: ['invoice_read'],
  },
  REPORTS_LIST: {
    permissions: ['reports_read'],
  },
  CHANNELS: {
    permissions: ['channel_read'],
  },
  THREADS: {
    permissions: [],
  },
  PAYMENTS: {
    permissions: ['payments_read'],
  },
  PRICE_LIST: {
    permission: [],
  },
  CAMPAIGNS: {
    permissions: [],
  },
  // PRINCIPAL SETTINGS CASES
};

export const ALL_ROUTES_VARIABLES = {
  COMMERCE_BASE_ROUTE: (principalName: string) =>
    `/admin/principal/${principalName.split(' ').join('-')}`,
  ORDERS_VIEW_DETAILS: (id: number) => `/orders/${id}`,
  RETURNS_VIEW_DETAILS: (id: number) => `/returns/${id}`,
  TRACK_PO_VIEW_DETAILS: (id: string) => `/orders/poFiles/${id}`,
  LEDGERS_PAYMENT_DETAILS: (paymentId: string) => `/ledgers/${paymentId}`,
  HIERARCHY_GROUP: (id: string) => `/admin/hierarchy/group?id=${id}`,
  BULK_IMPORT_DETAILS: (data: any) =>
    `/admin/import/details?id=${data.id}&entity=${data.entity}&fileName=${data.fileName}&status=${data.status}`,
  HIERARCHY_SEGMENTATION: (name: string) =>
    `/admin/hierarchy/segmentation?name=${name}`,
  HIERARCHY_GROUP_CREATE: (data: {
    itemId: string;
    groupsName: string;
    parentGroupId: string;
  }) =>
    `/admin/hierarchy/creategroups?id=${data.itemId}&name=${data.groupsName}&parentGroupId=${data.parentGroupId}`,
};

export const getZonoMoneyRoute = (r1: string, r2?: string) => {
  let path = ALL_ROUTES['ZONO_MONEY_BASE_ROUTE'].path;
  if (!_.isEmpty(r1)) {
    path = `${path}/${r1}`;
    if (r2) {
      path = `${path}/${r2}`;
    }
    return path;
  }
  return '';
};
