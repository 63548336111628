import { ColumnConfig, InvoiceReportColumnKey } from '../../model';

export const InvoiceReportConfig: Record<
  InvoiceReportColumnKey,
  ColumnConfig
> = {
  sNo: {
    name: 'S.No',
  },
  customerName: {
    name: 'Customer Name',
    sortField: 'customerName',
  },
  invoiceNumber: {
    name: 'Invoice Number',
    sortField: 'invoiceNumber',
    alignment: 'flex-end',
  },
  status: {
    name: 'Status',
  },
  invoiceDate: {
    name: 'Invoice Date',
    sortField: 'invoiceDate',
  },
  invoiceValue: {
    name: 'Invoice Value',
    sortField: 'invoiceValue',
    alignment: 'flex-end',
  },
  dueDate: {
    name: 'Due Date',
    sortField: 'dueDate',
  },
  orderNumber: {
    name: 'Order Number',
  },
  invoiceOrderValue: {
    name: 'Order value',
    sortField: 'orderValue',
    alignment: 'flex-end',
  },
  numberOfSKUs: {
    name: 'Number of SKUs',
    alignment: 'flex-end',
  },
  dispatchDocNumber: {
    name: 'Dispatch Doc Number',
  },
  dispacthDate: {
    name: 'Dispacth Date',
  },
  paymentID: {
    name: 'Payment ID',
  },
  amountPaid: {
    name: 'Amount Paid',
    sortField: 'amountPaid',
    alignment: 'flex-end',
  },
};