import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AppType,
  SendOtpResponse,
  UserAuthResponse,
  UserTokens,
} from './userAuth.interface';
import { userApi } from './userApi';
import _ from 'lodash';
import { RootState } from '../root/ReduxStore.store';
import { CatalogueView, configApi } from '../config';

export const USER_AUTH_FEATURE_KEY = 'userAuth';

/*
 * Update these interfaces according to your requirements.
 */

export interface UserAuthState {
  userData: UserAuthResponse;
  isAuthenticated: boolean;
  error: string;
  token: string;
  refreshToken: string;
  otp?: string;
  lastViewedNewInvoiceDate?: string;
  changeRequest?: boolean;
  isGuestAuthenticated: boolean;
  catalogPreference: undefined | CatalogueView;
}
export const initialUserAuthState: UserAuthState = {
  userData: {
    address: '',
    city: '',
    contentType: '',
    email: '',
    firstName: '',
    id: '',
    integrationToken: '',
    isVerified: false,
    lastName: '',
    mobile: '',
    profilePic: '',
    refreshToken: '',
    registeredOn: '',
    role: '',
    subtitle: '',
    systemFirstName: '',
    systemLastName: '',
    token: '',
    userType: '',
    appType: undefined,
  },
  error: '',
  isAuthenticated: false,
  token: '',
  refreshToken: '',
  isGuestAuthenticated: false,
  catalogPreference: undefined,
};

export const userAuthSlice = createSlice({
  name: USER_AUTH_FEATURE_KEY,
  initialState: initialUserAuthState,
  reducers: {
    onAuthSuccess(state, action: PayloadAction<UserAuthResponse>) {
      //@ts-ignore
      state.isAuthenticated = _.isUndefined(action.payload.alertMessage)
        ? true
        : false;
      state.userData = action.payload;
      state.token = action.payload.token;
      state.refreshToken = action.payload.refreshToken;
      state.changeRequest = false;
      state.userData.appType = AppType.ZONOFI;
      state.isGuestAuthenticated = false;
    },
    onRefreshToken(state, action: PayloadAction<UserTokens>) {
      state.refreshToken = action.payload.refreshToken;
      state.token = action.payload.token;
    },
    logOut(state, action: PayloadAction<void>) {
      state.userData = initialUserAuthState.userData;
      state.token = '';
      state.refreshToken = '';
      state.lastViewedNewInvoiceDate = undefined;
      state.isAuthenticated = false;
      state.isGuestAuthenticated = false;
    },
    saveOtp(state, action: PayloadAction<SendOtpResponse>) {
      state.otp = action?.payload?.otp?.toString();
    },
    updatelastViewedNewInvoiceDate(state, action: PayloadAction<string>) {
      state.lastViewedNewInvoiceDate = action.payload;
    },
    enableChangeRequest(state, action: PayloadAction<void>) {
      state.changeRequest = true;
    },
    OnGuestUserAuthenticated(state, action: PayloadAction<string>) {
      state.isGuestAuthenticated = true;
      state.token = action.payload;
      state.userData.appType = AppType.ZONOFI;
      state.isAuthenticated = false;
    },
    updateCatalogPreference(
      state,
      action: PayloadAction<CatalogueView | undefined>
    ) {
      state.catalogPreference = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      userApi.endpoints.createUser.matchFulfilled,
      (state, action) => {
        userAuthSlice.caseReducers.onAuthSuccess(state, action);
      }
    );
    builder.addMatcher(
      userApi.endpoints.sendOtp.matchFulfilled,
      (state, action) => {
        userAuthSlice.caseReducers.saveOtp(state, action);
      }
    );
    builder.addMatcher(
      userApi.endpoints.getGuestToken.matchFulfilled,
      (state, action) => {
        userAuthSlice.caseReducers.OnGuestUserAuthenticated(state, action);
      }
    );
    builder.addMatcher(
      configApi.endpoints.getConfigDetails.matchFulfilled,
      (state, action) => {
        if (action.payload.config.catalogueView === CatalogueView.ListView) {
          userAuthSlice.caseReducers.updateCatalogPreference(state, {
            payload: undefined,
            type: 'userAuth/updateCatalogPreference',
          });
        }
      }
    );
  },
});

export const userauthReducer = userAuthSlice.reducer;

export const {
  onAuthSuccess,
  onRefreshToken,
  logOut,
  updatelastViewedNewInvoiceDate,
  enableChangeRequest,
  OnGuestUserAuthenticated,
  updateCatalogPreference,
} = userAuthSlice.actions;

export const getUserAuthState = (rootState: RootState): UserAuthState =>
  rootState[USER_AUTH_FEATURE_KEY];

export const getToken = createSelector(
  getUserAuthState,
  (state) => state.token
);

export const getRefreshToken = createSelector(
  getUserAuthState,
  (state) => state.refreshToken
);

export const getUserData = createSelector(
  getUserAuthState,
  (state) => state.userData
);

export const getIsAuthenticated = createSelector(
  getUserAuthState,
  (state) => state.isAuthenticated
);

export const isGuestAuthenticated = createSelector(
  getUserAuthState,
  (state) => state.isGuestAuthenticated
);

export const getLastViewedNewInvoiceDate = createSelector(
  getUserAuthState,
  (state) => state.lastViewedNewInvoiceDate
);
export const getChangeRequest = createSelector(
  getUserAuthState,
  (state) => state.changeRequest
);

export const getAppType = createSelector(
  getUserAuthState,
  (state) => state.userData.appType
);

export const getCatalogPreference = createSelector(
  getUserAuthState,
  (state) => state.catalogPreference
);
export const getOtp = createSelector(getUserAuthState, (state) => state.otp);
