import { Api } from '../api/root-api';
import {
  BulkCustomersUploadRequest,
  BulkCustomersUploadResponse,
  BuyerToSellerApiRequest,
  BuyerToSellerApiResponse,
  GetProductsByBuyerRequest,
  GetProductsByBuyerResponse,
  ValidateGstInRequest,
  ValidateGstInResponse,
} from './model';

export const buyerToSellerApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    validateGstIn: builder.query<ValidateGstInResponse, ValidateGstInRequest>({
      query(payload: ValidateGstInRequest) {
        const { gstNumber, sellerWorkspaceId } = payload;
        return {
          url: `/hub/orgs/api/validate/gst/${gstNumber}?sellerWorkspaceId=${sellerWorkspaceId}`,
          method: 'GET',
        };
      },
    }),
    buyerToSellerApi: builder.mutation<
      BuyerToSellerApiResponse,
      BuyerToSellerApiRequest
    >({
      query(payload: BuyerToSellerApiRequest) {
        const { sellerWorkspaceId } = payload;
        return {
          url: `hub/orgs/api/app-subscriptions/buyer-seller/workspace?sellerWorkspaceId=${sellerWorkspaceId}`,
          method: 'POST',
        };
      },
    }),
    bulkCustomersUpload: builder.mutation<
      BulkCustomersUploadResponse,
      BulkCustomersUploadRequest
    >({
      query(payload: BulkCustomersUploadRequest) {
        const { customers, sellerWorkspaceId } = payload;
        return {
          url: `hub/orgs/api/customers/create/bulk/${sellerWorkspaceId}?sellerWorkspaceId=${sellerWorkspaceId}`,
          method: 'POST',
          body: { customers },
        };
      },
    }),
    getProductsByBuyer: builder.query<
      GetProductsByBuyerResponse,
      GetProductsByBuyerRequest
    >({
      query(payload: GetProductsByBuyerRequest) {
        const { sellerWorkspaceId } = payload;
        return {
          url: `hub/commerce-v2/products/order-history/${sellerWorkspaceId}`,
          method: 'GET',
          params: { random: Math.random(), ...payload },
        };
      },
    }),
  }),
});

export const {
  useLazyValidateGstInQuery,
  useBuyerToSellerApiMutation,
  useBulkCustomersUploadMutation,
  useLazyGetProductsByBuyerQuery,
} = buyerToSellerApi;
