import { createSelector, createSlice } from '@reduxjs/toolkit';
import {
  CFADrawerConfig,
  CustomersDrawerConfig,
  DivisionDrawerConfig,
  HeadDivisionDrawerConfig,
  ProductsDrawerConfig,
  ChatCustomerDrawer,
  ThreadNewCustomerConfig,
} from './configs';
import { RootState } from '../root';

export const DRAWER_CONFIG_FEATURE_KEY = 'drawerConfig';

interface DrawerConfig {
  config: Record<any, Record<any, any>> | undefined;
}

export const initialDrawerConfigState: DrawerConfig = {
  config: {
    ProductDrawer: ProductsDrawerConfig,
    CustomerDrawer: CustomersDrawerConfig,
    CFADrawer: CFADrawerConfig,
    HeadDivisionDrawer: HeadDivisionDrawerConfig,
    DivisionDrawer: DivisionDrawerConfig,
    ChatCustomerDrawer: ChatCustomerDrawer,
    ThreadNewCustomerConfig: ThreadNewCustomerConfig,
  },
};

export const drawerConfigSlice = createSlice({
  name: DRAWER_CONFIG_FEATURE_KEY,
  initialState: initialDrawerConfigState,
  reducers: {},
});

export const getDrawerConfigState = (rootState: RootState): any =>
  rootState[DRAWER_CONFIG_FEATURE_KEY];

export const getConfigByDrawerKey = (drawerKey: any) =>
  createSelector(getDrawerConfigState, (drawerConfigState) =>
    drawerConfigState.config ? drawerConfigState.config[drawerKey] : undefined
  );
