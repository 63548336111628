import { ChatActionSendPricesColumnKey, ColumnConfig } from '../../model';

export const chatActionSendPricesConfig: Record<
  ChatActionSendPricesColumnKey,
  ColumnConfig
> = {
  skuCodeSelection: {
    name: 'SKU Code',
    field: 'checkboxNavigate',
    isCheckboxField: true,
  },
  title: {
    name: 'Product Title',
    field: 'textWidOfferTooltip',
  },
  moq: {
    name: 'MOQ',
    alignment: 'flex-end',
  },
  margin: {
    name: 'Margin',
    alignment: 'flex-end',
  },
  mrp: {
    name: 'MRP',
    alignment: 'flex-end',
  },
  price: {
    name: 'Price',
    // sortField: 'PTR',
    alignment: 'flex-end',
  },
};
