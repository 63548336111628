import { ColumnConfig, CommsDetailsCustomersColumnKey } from '../../model';

export const commsDetailsCustomerConfig: Record<
  CommsDetailsCustomersColumnKey,
  ColumnConfig
> = {
  customerName: { name: 'Customer Name', field: 'avatarNameStatus' },
  customerCode: { name: 'Customer Code' },
  city: { name: 'City' },
  state: { name: 'State' },
  action: { name: '' },
};
