export enum Hierarchy {
  WORKSPACE = 'Workspace',
  DIVISION = 'Division',
  CUSTOMER_GROUPS = 'CustomerGroup',
  CUSTOMER = 'Customer',
}

export enum ConfigModule {
  CATALOGUE = 'Catalogue',
  PROMOTIONS = 'Promotions',
  ORDER = 'Order',
  INVOICE = 'Invoice',
  CUSTOMER = 'Customer',
  TAX = 'Tax',
  MFA = 'Mfa',
  CREDIT_LIMIT = 'Credit Limit',
  WBA = 'WBA',
  PRICING = 'Pricing',
}

export interface ConfigSummaryRequest {
  workspaceId: string;
  hierarchy?: Hierarchy;
  entityIds?: string[];
}
export interface ConfigSummaryResponse {
  workspaceId: string;
  noOfSettings: string;
  noOfModule: string;
  lastUpdatedAt: string;
  divisionId?: string;
  customerGroupId?: string;
  customerId?: string;
  teamMemberId?: string;
}
export type ConfigSummary = ConfigSummaryResponse;
export interface ConfigDetailsRequest {
  workspaceId: string;
  divisionId?: string;
  customerGroupId?: string;
  customerId?: string;
  teamMemberId?: string;
  module: ConfigModule;
  rollback: boolean;
}

export enum CreditLimitStrategy {
  DIVISON_CREDIT_LIMIT = 'Division credit limit',
  OVER_ALL_CREDIT_LIMIT = 'Over all credit limit',
  NONE = 'None',
}

export enum OrderSplitBy {
  cfaDivision = 'cfa-division',
  cfa = 'cfa',
  cfaHeaderDivision = 'cfa-header-division',
}

export interface Config {
  pricingStrategy?: string;
  cfaApprovalRequired?: boolean;
  orderCutOffTime?: number;
  MOQ_delta?: number;
  priceCalculationStrategy?: string;
  distributorMargin?: number;
  MFA_workspace?: boolean;
  displayCreditLimitOnCheckout?: boolean;
  displayCreditLimitStrategy?: CreditLimitStrategy;
  displayCreditLimitOnApproval?: boolean;
  orderSplitBy?: OrderSplitBy;
  catalogueView?: CatalogueView;
}

export interface ConfigDetailsResponse {
  id: string;
  createdDate: string;
  updatedDate: string;
  config: Config;
  workspaceId: string;
  divisionId: string | null;
  customerGroupId: string | null;
  customerId: string | null;
  teamMemberId: string | null;
  module: ConfigModule;
}

export type CreateConfigSettingsResponse = ConfigDetailsResponse;
export interface CreateConfigSettingsRequest {
  config: Config;
  module: ConfigModule;
  hierarchy: Hierarchy;
  workspaceId: string;
  divisionId?: string;
  customerGroupId?: string;
  customerId?: string;
  teamMemberId?: string;
}

export interface ConfigSchemaRequest {
  module?: ConfigModule;
  workspaceId: string;
}

export interface ConfigSetting {
  description: string;
  type: string;
  inputType: string;
  options: number[] | string[];
  hierarchy: string[];
  required: boolean;
  settingName?: string;
  default?: string;
}

export interface ConfigSchema {
  cfaApprovalRequired?: ConfigSetting;
  orderCutOffTime?: ConfigSetting;
  MOQ_delta?: ConfigSetting;
  priceCalculationStrategy?: ConfigSetting;
  distributorMargin?: ConfigSetting;
  module: string;
}

export interface WorkspaceConfigList {
  id: string;
  moduleName: string;
  activeSettings: string;
  lastUpdated: string;
}

export enum CatalogueView {
  ListWithImagesView = 'ListWithImagesView',
  ThumbnailView = 'ThumbnailView',
  ListView = 'ListView',
}
