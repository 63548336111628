import { ColumnConfig, CfaAddProductToOrderConfig } from '../../model';

export const cfaAddProductToOrderConfig: Record<
  CfaAddProductToOrderConfig,
  ColumnConfig
> = {
  skuCodeSelection: {
    name: 'SKU Code',
    field: 'checkboxNavigate',
    isCheckboxField: true,
  },
  status: {
    name: 'Status',
  },
  productTitle: {
    name: 'Product Title',
    field: 'multiLine',
  },
  scheme: {
    name: 'Scheme',
  },
  taxCategory: {
    name: 'Tax Category',
    alignment: 'center',
  },
  pts: {
    name: 'PTS',
    sortField: 'price',
    alignment: 'flex-end',
  },
  ptr: {
    name: 'PTR',
    alignment: 'flex-end',
  },
  margin: {
    name: 'Margin',
    alignment: 'flex-end',
  },
  packSize: {
    name: 'Pack Size',
    alignment: 'flex-end',
  },
  addToOrder: {
    name: '',
    field: 'addToOrder',
    alignment: 'flex-end',
  },
};
