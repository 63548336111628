import { Api } from '../api/root-api';
import {
  NotificationsResponse,
  UpdateNotificationsResponse,
  UpdateNotificationsRequest,
} from './model';

export const notificationsApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    getNotifications: builder.query<
      NotificationsResponse,
      { workspaceId: string }
    >({
      query(arg) {
        return {
          url: `hub/communication-v2/api/channels/app/communication/${arg.workspaceId}`,
          method: 'GET',
          params: {
            sellerWorkspaceId: arg.workspaceId,
          },
          headers: {
            'Cache-control': 'no-cache',
          },
        };
      },
      providesTags: ['Notifications'],
    }),
    updateNotifications: builder.mutation<
      UpdateNotificationsResponse,
      UpdateNotificationsRequest
    >({
      query(Request) {
        return {
          url: `hub/communication-v2/api/channels/app/communication/${Request.workspaceId}`,
          method: 'POST',
          body: Request,
        };
      },
      invalidatesTags: ['Notifications'],
    }),
  }),
});

export const {
  useGetNotificationsQuery,
  useLazyGetNotificationsQuery,
  useUpdateNotificationsMutation,
} = notificationsApi;
