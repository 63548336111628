import React, { useMemo } from 'react';
import './product-details-info-section.scss';
import { isNumber } from 'lodash';
import { ProductVariantCategory } from '@zonofi/zono-money-store';

interface ProductDetailsProps {
  productDetails: {
    title: string;
    price: number;
    tags: ProductVariantCategory[];
    description: string;
    details: {
      ptr: string;
      margin: string;
      moq: number;
    };
    images: string[];
    itemInCart?: {
      quantity: number;
      orderId: number;
    };
  };
  hidePrice?: boolean;
}

export const ProductDetails: React.FC<ProductDetailsProps> = ({
  productDetails,
  hidePrice,
}) => {
  const { title, price, tags, description, details } = productDetails;
  const isPtrGraterThanOne = useMemo(
    () => isNumber(Number(details.ptr)) && Number(details.ptr) > 1,
    [details.ptr]
  );
  return (
    <div className="product-details">
      <div className="tags">
        {tags.map((tag, index) => (
          <span key={index} className="tag font-primary">
            {tag.categoryName}
          </span>
        ))}
      </div>

      <h1 className="title font-primary-bold">{title}</h1>

      <div className="price font-primary-bold">
        <span className="currency font-primary-bold">₹</span>
        {hidePrice ? '-' : price.toFixed(2)}
      </div>

      <div className="section">
        <h2 className=" font-primary-bold">DESCRIPTION</h2>
        <p className="font-primary">{description}</p>
      </div>
      <h2 className="font-primary-bold other-details">OTHER DETAILS</h2>
      <div className="final section">
        <div className="details-grid">
          <div className="detail-item">
            <span className="label font-primary">PTR</span>
            <span className="value font-primary-semibold">
              {hidePrice || !isPtrGraterThanOne ? '-' : details.ptr}
            </span>
          </div>
          <div className="detail-item">
            <span className="label font-primary">Margin</span>
            <span className="value font-primary-semibold">
              {hidePrice || !isPtrGraterThanOne ? '-' : details.margin}
            </span>
          </div>
          <div className="detail-item">
            <span className="label font-primary">MOQ</span>
            <span className="value font-primary-semibold">{details.moq}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
