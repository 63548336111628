import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import { userApi } from './api';
import {
  SendOTPResponse,
  UserTokens,
  User,
  UserPermissionsResponse,
  MappedWorskpaceForSupportResponse,
  ResendOTPResponse,
} from './model';
import { RootState } from '../root';
import { CreateUserResponse } from '../onboarding';

export const USERAUTH_FEATURE_KEY = 'userAuth';

export interface UserAuthState {
  userData: User;
  isAuthenticated: boolean;
  error?: string;
  token?: string;
  refreshToken?: string;
  otp: string;
  emailOtp: string;
  resendOtp: string;
  feedsScreenLastVisitedTime: number;
  lastMessageTimeToken: number;
  fcmToken: string;
  isInvite: boolean;
  userPermissions: string[];
  userRoles: string[];
  otpPayloadValue: any;
  mappingDataForSupportUser: MappedWorskpaceForSupportResponse;
  loginTime: number;
  tempSupportUserData: User;
  pushNotificationToken: string;
}

export const initialUserAuthState: UserAuthState = {
  userData: {
    mobile: '',
    firstName: '',
    email: '',
    lastName: '',
    id: '',
    role: '',
    userType: '',
    token: '',
    refreshToken: '',
    isVerified: false,
    integrationToken: '',
    userProfile: '',
    workspaceRoles: [],
    loggedInOnBehalfData: {
      supportUserToken: '',
      supportUserRefereshToken: '',
      isLoggedInOnBehalf: false,
      workspaceId: '',
    },
  },
  isAuthenticated: false,
  otp: '',
  emailOtp: '',
  resendOtp: '',
  feedsScreenLastVisitedTime: 0,
  lastMessageTimeToken: 0,
  fcmToken: '',
  pushNotificationToken: '',
  isInvite: false,
  userPermissions: [],
  userRoles: [],
  otpPayloadValue: {}, // for accept invite cfa case
  mappingDataForSupportUser: {
    id: '',
    firstName: '',
    lastName: null,
    email: '',
    mobile: '',
    role: '',
    workspaceRoles: [],
    statusCode: 0,
    responseStatus: '',
  },
  loginTime: 0,
  tempSupportUserData: {
    mobile: '',
    firstName: '',
    lastName: '',
    id: '',
    role: '',
    userType: '',
    token: '',
    refreshToken: '',
    isVerified: false,
    integrationToken: '',
    loggedInOnBehalfData: {
      supportUserToken: '',
      supportUserRefereshToken: '',
      isLoggedInOnBehalf: false,
      workspaceId: '',
    },
  },
};

export const userSlice = createSlice({
  name: USERAUTH_FEATURE_KEY,
  initialState: initialUserAuthState,
  reducers: {
    updateToken(state, action: PayloadAction<string>) {
      state.token = action.payload;
    },
    updateRefreshToken(state, action: PayloadAction<string>) {
      state.refreshToken = action.payload;
    },
    onAuthSuccess(state, action: PayloadAction<User>) {
      state.userData = {
        ...action.payload,
        inviteUserType:
          action.payload.inviteUserType ||
          action.payload?.inviteDetails?.userType, // made a workaround for bug in returnsScreen, need to find rootcause
      };
      if (action.payload.temptoken) {
        state.token = action.payload.temptoken;
      } else {
        state.token = action.payload.token;
      }
      state.refreshToken = action.payload.refreshToken;
      state.isInvite = false;
    },
    onRefreshToken(state, action: PayloadAction<UserTokens>) {
      state.refreshToken = action.payload.refreshToken;
      state.token = action.payload.token;
    },
    logOut(state) {
      state.isAuthenticated = initialUserAuthState.isAuthenticated;
      state.otp = initialUserAuthState.otp;
      state.emailOtp = initialUserAuthState.emailOtp;
      state.token = initialUserAuthState.token;
      state.refreshToken = initialUserAuthState.refreshToken;
      state.userData = initialUserAuthState.userData;
      state.isInvite = initialUserAuthState.isInvite;
      state.pushNotificationToken = initialUserAuthState.pushNotificationToken;
      state.feedsScreenLastVisitedTime =
        initialUserAuthState.feedsScreenLastVisitedTime;
      // dispatch(
      //   userApi.endpoints.trackEvent.initiate({
      //     eventType: TrackEventType.LOG_OUT,
      //   })
      // );
    },
    switchUserDataToSupperUserData(state) {
      state.userData = state.tempSupportUserData;
      state.tempSupportUserData = initialUserAuthState.tempSupportUserData;
    },
    storeFCMToken(state, action: PayloadAction<string>) {
      state.fcmToken = action.payload;
    },
    onSendOTPSuccess(state, action: PayloadAction<SendOTPResponse>) {
      if (action?.payload?.mobile || action?.payload?.email) {
        state.otp = action?.payload?.mobile?.otp?.toString();
        state.emailOtp = action?.payload?.email?.otp?.toString();
        if (action.payload.temptoken) {
          state.token = action.payload.temptoken;
        }
      } else {
        state.otp = action?.payload?.otp?.toString();
        if (action.payload.temptoken) {
          state.token = action.payload.temptoken;
        }
      }
    },
    onResendOTPSuccess(state, action: PayloadAction<ResendOTPResponse>) {
      state.otp = action?.payload?.otp?.toString();
      state.resendOtp = action?.payload?.otp?.toString();
    },
    onUserSendOTPSuccess(state, action: PayloadAction<SendOTPResponse>) {
      state.otp = action?.payload?.mobile?.otp?.toString();
      state.emailOtp = action?.payload?.email?.otp?.toString();
    },
    authenticate(state) {
      state.isAuthenticated = true;
      state.feedsScreenLastVisitedTime = moment().valueOf();
    },
    updateFeedsScreenLastVisitedTime(state, action: PayloadAction<number>) {
      state.feedsScreenLastVisitedTime = action?.payload;
    },
    updateLastMessageTimeToken(state, action: PayloadAction<number>) {
      state.lastMessageTimeToken = action?.payload;
    },
    addToken(state, action: PayloadAction<string>) {
      state.token = action?.payload;
    },
    updateUser(state, action: PayloadAction<CreateUserResponse | User>) {
      state.userData = { ...state.userData, ...action.payload };
    },
    updateUserName(state, action: PayloadAction<{ firstName: string }>) {
      state.userData = { ...state.userData, ...action.payload };
    },
    updateIsInvite(state, action: PayloadAction<boolean>) {
      state.isInvite = action.payload;
    },
    updateUserRoles(state, action: PayloadAction<string[]>) {
      state.userRoles = action.payload;
    },
    updateOtp(state, action: PayloadAction<string>) {
      state.otp = action.payload;
    },
    updatePushNotificationToken(state, action: PayloadAction<string>) {
      state.pushNotificationToken = action.payload;
    },
    updateInviteUserType(state, action: any) {
      state.userData = {
        ...state.userData,
        inviteUserType: action.payload,
      };
    },

    updateOtpValue(state, action: any) {
      state.otpPayloadValue = action.payload;
    },

    updateUserPermissions(
      state,
      action: PayloadAction<UserPermissionsResponse[]>
    ) {
      state.userPermissions = action?.payload?.flatMap((item) =>
        item?.permissions?.map((permission) => permission.slug)
      );
    },
    updateMappingDataForSupportUser(state, action: any) {
      state.mappingDataForSupportUser = action?.payload;
    },
    updateLoginTime(state, action: PayloadAction<number>) {
      state.loginTime = action.payload;
    },
    storeSupportUserTokens(state, action: PayloadAction<string>) {
      state.userData = {
        ...state.userData,
        loggedInOnBehalfData: {
          supportUserToken: state.userData.token,
          supportUserRefereshToken: state.userData.refreshToken,
          isLoggedInOnBehalf: true,
          workspaceId: action.payload,
        },
      };
      state.tempSupportUserData = {
        ...state.userData,
        loggedInOnBehalfData: {
          supportUserToken: state.userData.token,
          supportUserRefereshToken: state.userData.refreshToken,
          isLoggedInOnBehalf: false,
          workspaceId: action.payload,
        },
      };
    },
    updateUserProfile(state, action: PayloadAction<string>) {
      state.userData = { ...state.userData, userProfile: action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      userApi.endpoints.sendOTP.matchFulfilled,
      (state, action) => {
        userSlice.caseReducers.onSendOTPSuccess(state, action);
      }
    );
    builder.addMatcher(
      userApi.endpoints.resendOTP.matchFulfilled,
      (state, action) => {
        userSlice.caseReducers.onResendOTPSuccess(state, action);
      }
    );
    builder.addMatcher(
      userApi.endpoints.verifyOTP.matchFulfilled,
      (state, action) => {
        userSlice.caseReducers.onAuthSuccess(state, action);
      }
    );
    builder.addMatcher(
      userApi.endpoints.regenerateToken.matchFulfilled,
      (state, action) => userSlice.caseReducers.onRefreshToken(state, action)
    );
    builder.addMatcher(
      userApi.endpoints.getUserPermissions.matchFulfilled,
      (state, action) => {
        userSlice.caseReducers.updateUserPermissions(state, action);
      }
    );
    builder.addMatcher(
      userApi.endpoints.getUser.matchFulfilled,
      (state, action) => {
        userSlice.caseReducers.updateUser(state, action);
      }
    );
    builder.addMatcher(
      userApi.endpoints.getMappedWorkspacesForSupportUser.matchFulfilled,
      (state, action) => {
        userSlice.caseReducers.updateMappingDataForSupportUser(state, action);
      }
    );
  },
});

export const userAuthReducer = userSlice.reducer;

export const {
  onAuthSuccess,
  onRefreshToken,
  logOut,
  authenticate,
  updateFeedsScreenLastVisitedTime,
  updateLastMessageTimeToken,
  storeFCMToken,
  updateToken,
  updateRefreshToken,
  addToken,
  updateUser,
  updateUserName,
  updateIsInvite,
  updateUserRoles,
  updateOtpValue,
  updateUserPermissions,
  updateLoginTime,
  updateOtp,
  updateInviteUserType,
  storeSupportUserTokens,
  switchUserDataToSupperUserData,
  updatePushNotificationToken,
  updateUserProfile,
} = userSlice.actions;

export const getUserAuthState = (rootState: RootState): UserAuthState =>
  rootState[USERAUTH_FEATURE_KEY];

export const getToken = createSelector(
  getUserAuthState,
  (state) => state?.token
);

export const getRefreshToken = createSelector(
  getUserAuthState,
  (state) => state.refreshToken
);

export const getUserData = createSelector(
  getUserAuthState,
  (state) => state.userData
);

export const getUserId = createSelector(
  getUserAuthState,
  (state) => state.userData?.id
);

export const getLastMessageTimeToken = createSelector(
  getUserAuthState,
  (state) => state.lastMessageTimeToken
);

export const getFCMToken = createSelector(
  getUserAuthState,
  (state) => state.fcmToken
);

export const getOtp = createSelector(getUserAuthState, (state) => state.otp);

export const getEmailOtp = createSelector(
  getUserAuthState,
  (state) => state.emailOtp
);

export const getResendOtp = createSelector(
  getUserAuthState,
  (state) => state.resendOtp
);

export const getIsAuthenticated = createSelector(
  getUserAuthState,
  (state) => state.isAuthenticated
);

export const getIsInvite = createSelector(
  getUserAuthState,
  (state) => state.isInvite
);

export const getUserRoles = createSelector(
  getUserAuthState,
  (state) => state.userRoles
);
export const getOtpPayloadValue = createSelector(
  getUserAuthState,
  (state) => state.otpPayloadValue
);

export const getMappingDataForSupportUser = createSelector(
  getUserAuthState,
  (state) => state.mappingDataForSupportUser
);
export const getLoginTime = createSelector(
  getUserAuthState,
  (state) => state.loginTime
);

export const getIsLoggedInOnBehalf = createSelector(
  getUserAuthState,
  (state) => state.userData?.loggedInOnBehalfData?.isLoggedInOnBehalf || false
);

export const getLoggedInOnBehalfWorkspaceId = createSelector(
  getUserAuthState,
  (state) => state.userData?.loggedInOnBehalfData?.workspaceId
);

export const getPushNotificationToken = createSelector(
  getUserAuthState,
  (state) => state.pushNotificationToken
);

export const getfeedsScreenLastVisitedTime = createSelector(
  getUserAuthState,
  (state) => state.feedsScreenLastVisitedTime
);
