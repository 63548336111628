import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { MediaItem } from './model';

export const mediaAdapter = createEntityAdapter<MediaItem>({
  selectId: (media) => media?.id,
});

export const mediaSlice = createSlice({
  name: 'media',
  initialState: mediaAdapter.getInitialState(),
  reducers: {
    addMediaItem: mediaAdapter.addOne,
    addMediaItems: mediaAdapter.addMany,
    updateMediaItem: mediaAdapter.upsertOne,
    updateMediaItems: mediaAdapter.addMany,
    replaceMediaItems: mediaAdapter.setAll,
    getMediaSuccess(state, action: PayloadAction<any>) {
      mediaAdapter.upsertMany(state, action.payload.media);
      console.log('state in get media success', state);
      return state;
    },
    getMediaFailed(state, action: PayloadAction<string>) {
      console.log('in get media failure ', state, action);
    },
  },
});

export const {
  addMediaItem,
  addMediaItems,
  updateMediaItem,
  updateMediaItems,
  replaceMediaItems,
  getMediaSuccess,
  getMediaFailed,
} = mediaSlice.actions;

export default mediaSlice.reducer;
