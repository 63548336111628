import CustomColumns from './svgs/CustomColumns.svg';
import {
  customersSideBarIcon,
  opsMetricsSideBarIcon,
  rolesAndAccessSideBarIcon,
  supportUsersSideBarIcon,
  reportsSideBarIcon,
  channelsSideBarIcon,
  threadsSideBarIcon,
  whatsAppSideBarIcon,
} from './svgs/side-bar-icons';

import Email from './svgs/email.svg';
import CampaginSuccessSvg from './svgs/campaignSuccessIcon.svg';
import SidebarPrincipalsIcon from './svgs/sidebar-principals-icon.svg';
import SidebarReportsIcon from './svgs/sidebar-reports-icon.svg';
import SidebarSalesIcon from './svgs/sidebar-sales-icon.svg';
import SidebarSettingsIcon from './svgs/sidebar-settings-icon.svg';
import SidebarHomeIcon from './svgs/sidebar-home-icon.svg';
import { ReactComponent as RejectCross } from './svgs/reject.svg';
import { ReactComponent as SidebarHomeIconComponent } from './svgs/sidebar-home-icon.svg';
import { ReactComponent as sidebarGstPortalIcon } from './svgs/sidebar-gst-portal-icon.svg';
import { ReactComponent as sidebarImportIcon } from './svgs/sidebar-import-icon.svg';
import { ReactComponent as sidebarMyAccountIcon } from './svgs/sidebar-my-account-icon.svg';
import { ReactComponent as sidebarNotificationIcon } from './svgs/sidebar-notification-icon.svg';
import { ReactComponent as sidebarPaymentsIcon } from './svgs/sidebar-payments-icon.svg';
import { ReactComponent as sidebarTeamIcon } from './svgs/sidebar-team-icon.svg';
import { ReactComponent as sidebarAppsAndConcentIcon } from './svgs/sidebar-apps-and-content-icon.svg';
import { ReactComponent as sidebarReturnsIcon } from './svgs/sidebar-returns-icon.svg';
import { ReactComponent as sidebarConfigSettingsIcon } from './svgs/sidebar-config-settings.svg';
import { ReactComponent as sidebarHomeActiveIcon } from './svgs/sidebar-home-active-icon.svg';
import Sms from './svgs/sms.svg';
import TickIcon from './svgs/tick-icon.svg';
import CrossIcon from './svgs/cross-icon.svg';
import CloseCrossIcon from './svgs/closeCrossIcon.svg';
import CheckIconBlack from './svgs/checkIcon.svg';
import CheckIconBlue from './svgs/check-blue-icon.svg';
import KebabMenuIcon from './svgs/kabab-menu-icon.svg';
import CheckBoxGreenSVG from './svgs/checkboxGreen.svg';
import CheckBoxBlueSVG from './svgs/checkboxBlue.svg';
import EyeSvg from './svgs/Union.svg';
import blackEyeSvg from './svgs/blackEye.svg';
import ChechBoxGreySVG from './svgs/checkboxGrey.svg';
import { ReactComponent as Notification } from './svgs/Notification.svg';
import { ReactComponent as ConfigIconSVG } from './svgs/configIcon.svg';
import { ReactComponent as DeactivateIcon } from './svgs/deactivate.svg';
import { ReactComponent as ExecuteIcon } from './svgs/execute.svg';
import PhotoIcon from './svgs/photo.svg';
import {
  brandLogo,
  brandLogoWhite,
  brandLogoPoweredBy,
} from './svgs/brand-logos';
import PlusIconBlack from './svgs/plusIconBlack.svg';
import CaretDownIcon from './svgs/carretDownIcon.svg';
import DocIcon from './svgs/doc-icon.svg';
import UploadIcon from './svgs/upload-icon.svg';
import DeleteIcon from './svgs/delete-icon.svg';
import { ReactComponent as DeleteIconComponent } from './svgs/delete-icon.svg';
import SmallDeleteIcon from './svgs/small-delete-icon.svg';
import CreateIcon from './svgs/createIcon.svg';
import WarningIcon from './svgs/warning.svg';
import { ReactComponent as CloseIcon } from './svgs/closeIcon.svg';
import CloseIconOutline from './svgs/cross-icon.svg';
import CopyIcon from './svgs/copy.svg';
import Verified from './svgs/verified.svg';
import { ReactComponent as UploadFile } from './svgs/upload_file.svg';
import ZonoDigitalBgLogo from './svgs/zono_bg_logo.svg';
import DTERP from './svgs/dterp.svg';
import { ReactComponent as SearchProperty } from './svgs/search_property.svg';
import Bot from './svgs/bot.svg';
import ViewDetailsIcon from './svgs/view-details-icon.svg';
import InviteLinkIcon from './svgs/invite-link-icon.svg';
import { ReactComponent as InviteLinkIconComponent } from './svgs/invite-link-icon.svg';
import MoveChannelIcon from './svgs/move-channel-icon.svg';
import CheckBoxDefault from './svgs/DefaultCheck.svg';
import Neutral from './svgs/Neutral.svg';
import WarningSymbol from './svgs/warning.svg';
import HoverActive from './svgs/HoverActive.svg';
import Default from './svgs/Default.svg';
import DetailsGreen from './svgs/DetailsGreen.svg';
import Details from './svgs/Details.svg';
import ExtendedIcon from './svgs/extended';
import CommsSearchIcon from './svgs/commsSearchIcon.svg';
import IssuesTabIcon from './svgs/issuesTabIcon.svg';
import GroupsTabIcon from './svgs/groupsTabIcon.svg';
import CustomersTabIcon from './svgs/customersTabIcon.svg';
import FacebookGreyIcon from './svgs/facebook-grey.svg';
import FormGreyIcon from './svgs/form-icon-grey.svg';
import MobileGreyIcon from './svgs/mobile-icon-grey.svg';
import WhatsAppIcon from './svgs/whatsapp-icon.svg';
import CheckBoxZonoBlue from './svgs/checkboxZonoBlue.svg';
import CheckBoxBlack from './svgs/checkboxBlack.svg';
import PoweredByZonoLogo from './svgs/powered-by-zono.svg';
import PoweredByZonoNavLogo from './svgs/powered-by-zono-nav.svg';
import LedgerListIcon from './svgs/side-drawer-ledger-list-icon.svg';
import SortIcon from './svgs/sort.svg';
import CrossIconGrey from './svgs/cross-icon-grey.svg';
import TextColor from './svgs/textColor.svg';
import AlignCenter from './svgs/alignCenter.svg';
import AlignLeft from './svgs/alignLeft.svg';
import AlignRight from './svgs/alignRight.svg';
import BoldText from './svgs/boldText.svg';
import ItalicText from './svgs/italicText.svg';
import UnderLineText from './svgs/underlineText.svg';
import ListItemsIcon from './svgs/listItems.svg';
import IndentaionIcon from './svgs/indentation.svg';
import StrikeThrough from './svgs/strikeThrough.svg';
import DocumentColour from './svgs/documentColours.svg';
import DefaultColour from './svgs/defaultColors.svg';
import ColorPicker from './svgs/colorPicker.png';
import Popular from './svgs/popular.svg';
import { ReactComponent as PromotionPurple } from './svgs/promotionPurple.svg';
import PromotionPurplePath from './svgs/promotionPurple.svg';
import AddButton from './svgs/addButton.svg';
import Resize from './svgs/resize.svg';
import WhatsApp from './svgs/whatsapp-grey.svg';
import Instagram from './svgs/instagram.svg';
import Youtube from './svgs/youtube.svg';
import Facebook from './svgs/facebook.svg';
import Camera from './svgs/camera.svg';
import BusinessCard from './svgs/businessCard.svg';
import Document from './svgs/document.svg';
import Star from './svgs/star.svg';
import Media from './svgs/media.svg';
import Invoice from './svgs/invoice.svg';
import Order from './svgs/order.svg';
import Payment from './svgs/payment.svg';
import Message from './svgs/message.svg';
import Minus from './svgs/minus.svg';
import AddMember from './svgs/add-member.svg';
import Info from './svgs/info.svg';
import InputInfo from './svgs/InputInfo.svg';
import InputInfoError from './svgs/InputInfo_error.svg';
import LockIconNew from './svgs/Lock-Icon.svg';
import UnLockIconNew from './svgs/Unlock-Icon.svg';
import WhatsAppDisableIcon from './svgs/WhatsApp_disabled.svg';

import ArrowRight from './svgs/arrow-right.svg';
import ArrowLeft from './svgs/arrow-left.svg';
import ArrowDown from './svgs/arrow-up.svg';
import ArrowUp from './svgs/arrow-down.svg';
import SelectArrowDown from './svgs/select_arrow_down.svg';
import SelectArrowUp from './svgs/select_arrow_up.svg';
import BlueArrowDown from './svgs/arrow_down.svg';
import { ReactComponent as WarningIconComponent } from './svgs/warning-icon.svg';
import Warning from './svgs/warning-icon.svg';
import AddCircle from './svgs/add-circle.svg';
import UncheckedBox from './svgs/unchecked_checkbox.svg';
import CheckedBox from './svgs/Checked_Checkbox.svg';
import CalendarIcon from './svgs/calendarIcon.svg';
import TrashIcon from './svgs/trash_can.svg';
import EditIcon from './svgs/edit-icon.svg';
import { ReactComponent as EditIconComponent } from './svgs/edit-icon.svg';
import CalendarFaded from './svgs/calender-faded.svg';
import LockIcon from './svgs/lock.svg';
import DownIcon from './svgs/down-icon.svg';
import { ReactComponent as DownIconComponent } from './svgs/down-icon.svg';
import UpIcon from './svgs/up-icon.svg';
import { ReactComponent as UpIconComponent } from './svgs/up-icon.svg';
import Plus from './svgs/plus.svg';
import TrendingUp from './svgs/trending-up.svg';
import DownLoad from './svgs/download.svg';
import New from './svgs/New.svg';
import offer from './svgs/offer.svg';
import Special from './svgs/Special.svg';
import Folder from './svgs/folder.svg';
import NoProducts from './svgs/NoProducts.svg';
import CheckSvg from './svgs/check.svg';
import ZotokLogo from './svgs/ZoTok_Logo.svg';
import ZotokCircle from './svgs/ZotokCircle.svg';
import PlusIcon from './svgs/plusIcon.svg';
import DiscountIcon from './svgs/discountIcon.svg';
import { ReactComponent as FilterNew } from './svgs/FilterNew.svg';
import SearchNew from './svgs/search-new.svg';
import NoSearchResults from './svgs/NoSearchResults.svg';
import AssignTeamMember from './svgs/assign-team-member.svg';
import { ReactComponent as AssignTeamMember_ } from './svgs/assign-team-member.svg';
import InfoIcon from './svgs/info-icon.svg';
import { ReactComponent as KebabMenuIcon2 } from './svgs/Kebab-menu-icon.svg';
import InvoiceIcon from './svgs/invoices-icon.svg';
import OrderIcon from './svgs/orders-icon.svg';
import orderTagIcon from './svgs/orderTag-icon.svg';
import { ReactComponent as TagIcon } from './svgs/orderTag-icon.svg';
import iconInfo from './svgs/icon-info.svg';
import Rupee from './svgs/rupee.svg';

import { ReactComponent as CloseCross } from './svgs/close.svg';
import handwritingFont from './svgs/thumbnails/thumbnail7083951586191850396.png';
import serifFont from './svgs/thumbnails/thumbnail17476311312496111218.png';
import comicFont from './svgs/thumbnails/thumbnail13133691290834534467.png';
import geometricFont from './svgs/thumbnails/thumbnail15376685863421974106.png';
import cartoonFont from './svgs/thumbnails/thumbnail12219352800739217812.png';
import wideFont from './svgs/thumbnails/thumbnail9015458401650985252.png';
import blackLetterFont from './svgs/thumbnails/thumbnail10488192233732875888.png';
import roundedFont from './svgs/thumbnails/thumbnail1798250677417001318.png';
import modernFont from './svgs/thumbnails/thumbnail5321161798251009649.png';
import boldFont from './svgs/thumbnails/thumbnail13993052541047140681.png';
import elegantFont from './svgs/thumbnails/thumbnail13938203058129850701.webp';
import vintageFont from './svgs/thumbnails/thumbnail389513517831502581.png';
import coolFont from './svgs/thumbnails/thumbnail13624170717657831909.png';
import retroFont from './svgs/thumbnails/thumbnail5232612763160215944.png';
import displayFont from './svgs/thumbnails/thumbnail8463497779251883595.png';
import headingFont from './svgs/thumbnails/thumbnail18053997489821244696.png';
import paragraphFont from './svgs/thumbnails/thumbnail18018640264785023075.png';
import sansSerifFont from './svgs/thumbnails/thumbnail17476311312496111218.png';
import sportsFont from './svgs/thumbnails/thumbnail7949599943738276975.png';
import corporateFont from './svgs/thumbnails/thumbnail6312515763031018624.png';
import monospaced from './svgs/thumbnails/thumbnail766958209385564619.png';
import searchIcon from './svgs/search-icon.svg';
import { ReactComponent as detailsBlue } from './svgs/detailsBlue.svg';
import { ReactComponent as otherInfoBlue } from './svgs/otherInfoBlue.svg';
import DefaultSize from './pngs/default-size.png';
import AcceptTick from './svgs/acceptTick.svg';
import Location from './svgs/location-pin.svg';
import PhoneIcon from './svgs/phone-icon.svg';
import UserIcon from './svgs/user-icon.svg';
import UploadFileIcon from './svgs/upload-file.svg';
import LeftArrow from './svgs/left-arrow.svg';
import MinusIcon from './svgs/minus-icon.svg';
import IconPlus from './svgs/plus-icon.svg';
import ReturnIcon from './svgs/return.svg';
import ZotokIcon from './svgs/brand-logos/zotok.svg';
import DownloadIcon from './svgs/icon-download.svg';
import DocumentIcon from './svgs/document-icon.svg';
import TrendingDown from './svgs/trending-down.svg';
import ScheduleIcon from './svgs/schedule.svg';
import { ReactComponent as SchedulerIcon } from './svgs/scheduler.svg';
import Assets from './svgs/assets.svg';
import Weight from './svgs/weight.svg';
import Truck from './svgs/truck.svg';
import Steering from './svgs/steering.svg';
import DriverIcon from './svgs/driver.svg';
import City from './svgs/city.svg';
import Pincode from './svgs/pincode.svg';
import UploadIconBlue from './svgs/upload-icon-blue.svg';
import DownloadBlue from './svgs/download-blue.svg';
import ClockIn from './svgs/clock-in.svg';
import ClockOut from './svgs/clock-out.svg';
import CheckIn from './svgs/check-in.svg';
import TimelineLocation from './svgs/timeline-location.svg';
import TimelineClockInOut from './svgs/timeline-clock-in-out.svg';
import LocationCheckIn from './svgs/location-check-in.svg';
import OrderPlaced from './svgs/order-placed.svg';
import ZotokPinRed from './svgs/zotok-pin-red.svg';
import ZotokPinGreen from './svgs/zotok-pin-green.svg';
import ZotokPinPurple from './svgs/zotok-pin-purple.svg';
import LocationNotAvilable from './svgs/location-not-found.svg';
import PaymentCollect from './svgs/payment-collected.svg';
import Navigation from './svgs/navigation.svg';
import InvoiceDoc from './svgs/invoice_doc.svg';
import BackArrowLT from './svgs/backArrowlight.svg';
import LocationIcon from './svgs/location-icon.svg';
import KebabMenu from './svgs/kebab-menu.svg';
import ShareIcon from './svgs/share.svg';
import CloneIcon from './svgs/clone.svg';
import Copy from './svgs/copy-icon.svg';
import CheckIcon from './svgs/check.svg';
import EditPencilIcon from './svgs/edit.svg';
import SampleProductIcon from './svgs/sample-product-icon.svg';
import ResendIcon from './svgs/resend.svg';
import ZoCoinIcon from './svgs/zo-coin.svg';
import ListView from './svgs/list-view.svg';
import GalleryView from './svgs/gallery-view.svg';
import DownArrowBlue from './svgs/arrow_down-blue.svg';
import RoiIcon from './svgs/roi-icon.svg';
import { ReactComponent as CampaignIcon } from './svgs/campaign1.svg';
import { ReactComponent as ApprovalIcon } from './svgs/approval-icon.svg';
import { ReactComponent as OutlineWarning } from './svgs/outlined-warning.svg';
import MertricsIcon from './svgs/metrics-icon.svg';
import ClickIcon from './svgs/click.svg';
import KPIIcon from './svgs/kpi.svg';
import PinnedIcon from './svgs/pinned.svg';
import UnPinnedIcon from './svgs/un-pinned.svg';
import TrendingUpIcon from './svgs/ic-trending-up.svg';
import TrendingDownIcon from './svgs/ic-trending-down.svg';
import { ReactComponent as ComboView } from './svgs/combo_view.svg';
import { ReactComponent as ChartIcon } from './svgs/chart-icon.svg';
import ConfigSetting from './svgs/config-setting.svg';
import ErrorIcon from './svgs/error.svg';
import AddCircleIcon from './svgs/add-circle-icon.svg';
import Click from './svgs/click-icon.svg';
import OrderIcon_ from './svgs/order-icon.svg';
import RightBlack from './svgs/right-black.svg';
import RightBlue from './svgs/right-blue.svg';
import Roi from './svgs/roi.svg';
import ViewIcon from './svgs/view.svg';
import { ReactComponent as CustomersIcon } from './svgs/customersTabIcon.svg';
import { ReactComponent as ProductIcon } from './svgs/product-icon.svg';
import { ReactComponent as GraphIcon } from './svgs/graph.svg';
import { ReactComponent as RightIcon } from './svgs/right-blue.svg';
import PlusOutline from './svgs/plus-outline.svg';
import InfoNew from './svgs/info-icon-new.svg';
import DefaultProduct from './svgs/default-product.svg';
import NoResults from './svgs/no-results.svg';
import DownArrow from './svgs/down-arrow.svg';
import ArrowUpNew from './svgs/arrow-up-new.svg';
import RefreshIconCampaign from './svgs/refresh-icon-campaign.svg';
import MiscTagForCustomer from './svgs/miscTagForCustomer.svg';
import NeutralGrey from './svgs/Neutral-grey.svg';
import Image from './svgs/image.svg';
import Loader from './svgs/loader.svg';
import CampaignDefaultImg from './svgs/campaign-default.svg';
import Campaign from './svgs/campaign.svg';
import DefaultPicture from './svgs/default-picture.svg';
import TimeIcon from './svgs/time-icon.svg';
import ArrowLeftIcon from './svgs/arrowLeft.svg';
import View from './svgs/view-icon.svg';
import CloseRed from './svgs/closeRed.svg';
import ArrowDownDark from './svgs/arrowDownBlack.svg';
import CampaignDefaultTemplate from './svgs/campaign-default-template.svg';
import ProductDefaultTemplate from './svgs/product-default-template.svg';
import EditPencilIconWhite from './svgs/edit-pencil-white.svg';
import DocumentColor from './svgs/document-color.svg';
import DefaultCheckboxNew from './svgs/default-checkbox-new.svg';
import CheckedCheckboxNew from './svgs/checked-checkbox-new.svg';
import NeutralCheckboxNew from './svgs/neutral-checkbox-new.svg';
import ThreadsNoView from './pngs/threads-no-view.png';
// import DownloadOutlineIcon from './svgs/Download.svg';
export const SHARED_PNGS = {
  DefaultSize: DefaultSize,
  CampaignIcon: Campaign,
  ThreadsNoView: ThreadsNoView
};

export const SHARED_SVGS = {
  ClickIcon: ClickIcon,
  RoiIcon: RoiIcon,
  DownArrowBlue: DownArrowBlue,
  ListView: ListView,
  GalleryView: GalleryView,
  ZoCoinIcon: ZoCoinIcon,
  ResendIcon: ResendIcon,
  SampleProductIcon: SampleProductIcon,
  ShareIcon: ShareIcon,
  CloneIcon: CloneIcon,
  Copy: Copy,
  CheckIcon: CheckIcon,
  EditPencilIcon: EditPencilIcon,
  EditPencilIconWhite: EditPencilIconWhite,
  DocumentColor,
  // DownloadOutlineIcon,
  KebabMenu: KebabMenu,
  CampaignIcon: CampaignIcon,
  BackArrowLT: BackArrowLT,
  LocationIcon: LocationIcon,
  InvoiceDoc: InvoiceDoc,
  Navigation: Navigation,
  PaymentCollect: PaymentCollect,
  LocationNotAvilable: LocationNotAvilable,
  ZotokPinRed: ZotokPinRed,
  ZotokPinGreen: ZotokPinGreen,
  ZotokPinPurple: ZotokPinPurple,
  OutlineWarning: OutlineWarning,
  ApprovalIcon: ApprovalIcon,
  WarningIconComponent: WarningIconComponent,
  LocationCheckIn: LocationCheckIn,
  OrderPlaced: OrderPlaced,
  TimelineClockInOut: TimelineClockInOut,
  TimelineLocation: TimelineLocation,
  ClockIn: ClockIn,
  ClockOut: ClockOut,
  CheckIn: CheckIn,
  DownloadBlue: DownloadBlue,
  UploadIconBlue: UploadIconBlue,
  Pincode: Pincode,
  CityIcon: City,
  Truck: Truck,
  Steering: Steering,
  DriverIcon: DriverIcon,
  RejectCross: RejectCross,
  Weight: Weight,
  Assets: Assets,
  orderTagIcon: orderTagIcon,
  TagIcon: TagIcon,
  Download: DownLoad,
  TrendingUp: TrendingUp,
  TrendingDown: TrendingDown,
  Plus: Plus,
  UpIcon: UpIcon,
  UpIconComponent: UpIconComponent,
  DownIcon: DownIcon,
  DownIconComponent: DownIconComponent,
  CalendarFaded: CalendarFaded,
  EditIcon: EditIcon,
  EditIconComponent: EditIconComponent,
  TrashIcon: TrashIcon,
  CalendarIcon: CalendarIcon,
  UncheckedBox: UncheckedBox,
  CheckedBox: CheckedBox,
  AddCircle: AddCircle,
  Warning: Warning,
  CustomColumns,
  ConfigIconSVG,
  Email,
  SidebarPrincipalsIcon,
  SidebarReportsIcon,
  SidebarSalesIcon,
  SidebarSettingsIcon,
  SidebarHomeIcon,
  SidebarHomeIconComponent,
  sidebarHomeActiveIcon,
  sidebarGstPortalIcon,
  sidebarImportIcon,
  sidebarMyAccountIcon,
  sidebarNotificationIcon,
  sidebarPaymentsIcon,
  sidebarTeamIcon,
  CampaginSuccessSvg,
  sidebarAppsAndConcentIcon,
  sidebarReturnsIcon,
  sidebarConfigSettingsIcon,
  Sms,
  CustomersSideBarIcon: customersSideBarIcon,
  opsMetricsSideBarIcon: opsMetricsSideBarIcon,
  rolesAndAccessSideBarIcon: rolesAndAccessSideBarIcon,
  supportUsersSideBarIcon: supportUsersSideBarIcon,
  reportsSideBarIcon: reportsSideBarIcon,
  channelsSideBarIcon,
  threadsSideBarIcon: threadsSideBarIcon,
  whatsAppSideBarIcon: whatsAppSideBarIcon,
  PromotionPurple,
  PromotionPurplePath,
  AddButton,
  brandLogo,
  brandLogoWhite,
  brandLogoPoweredBy,
  TickIcon,
  KebabMenuIcon,
  CrossIcon,
  CloseCrossIcon,
  CheckIconBlack,
  CheckIconBlue,
  CheckBoxGreenSVG,
  CheckBoxDefault,
  CheckBoxBlueSVG,
  CheckBoxBlack,
  CheckSvg,
  EyeSvg,
  blackEyeSvg,
  ChechBoxGreySVG,
  CheckBoxZonoBlue,
  Neutral,
  DocIcon: DocIcon,
  UploadIcon: UploadIcon,
  DeleteIcon: DeleteIcon,
  DeleteIconComponent,
  SmallDeleteIcon: SmallDeleteIcon,
  CreateIcon: CreateIcon,
  WarningIcon,
  CloseIcon,
  CopyIcon,
  Verified,
  UploadFile,
  ZonoDigitalBgLogo: ZonoDigitalBgLogo,
  DTERP: DTERP,
  SearchProperty: SearchProperty,
  SearchNew: SearchNew,
  Bot: Bot,
  ViewDetailsIcon: ViewDetailsIcon,
  MoveChannelIcon: MoveChannelIcon,
  InviteLinkIcon: InviteLinkIcon,
  InviteLinkIconComponent,
  WarningSymbol,
  HoverActive,
  Default,
  DetailsGreen: DetailsGreen,
  Details: Details,
  ExtendedIcon,
  CommsSearchIcon,
  IssuesTabIcon,
  GroupsTabIcon,
  CustomersTabIcon,
  PoweredByZonoLogo,
  PoweredByZonoNavLogo,
  LedgerListIcon,
  WhatsAppIcon,
  MobileGreyIcon,
  FormGreyIcon,
  FacebookGreyIcon,
  SortIcon,
  CrossIconGrey,
  TextColor,
  AlignCenter,
  AlignLeft,
  AlignRight,
  BoldText,
  ItalicText,
  UnderLineText,
  ListItemsIcon,
  IndentaionIcon,
  StrikeThrough,
  DocumentColour,
  DefaultColour,
  handwritingFont,
  serifFont,
  comicFont,
  geometricFont,
  cartoonFont,
  wideFont,
  blackLetterFont,
  roundedFont,
  modernFont,
  boldFont,
  elegantFont,
  vintageFont,
  coolFont,
  retroFont,
  displayFont,
  headingFont,
  paragraphFont,
  sansSerifFont,
  corporateFont,
  ColorPicker,
  sportsFont,
  monospaced,
  Popular,
  CloseIconOutline,
  PhotoIcon,
  Resize,
  WhatsApp,
  Instagram,
  Youtube,
  Facebook,
  Camera,
  BusinessCard,
  Document,
  Star,
  Message,
  Media,
  Order,
  Invoice,
  Payment,
  Minus,
  AddMember,
  Info,
  InputInfo,
  InputInfoError,
  LockIconNew,
  UnLockIconNew,
  WhatsAppDisableIcon,
  ArrowRight,
  ArrowLeft,
  LeftArrow,
  ArrowUp,
  BlueArrowDown,
  ArrowDown,
  SelectArrowDown,
  SelectArrowUp,
  ZotokCircle,
  ZotokLogo,
  DiscountIcon,
  PlusIcon,
  FilterNew,
  NoSearchResults,
  AssignTeamMemberIcon: AssignTeamMember,
  AssignTeamMember_: AssignTeamMember_,
  InfoIcon,
  KebabMenuIcon2,
  AcceptTick,
  searchIcon,
  InvoiceIcon,
  OrderIcon,
  detailsBlue,
  otherInfoBlue,
  Location,
  PhoneIcon,
  UserIcon,
  LockIcon,
  UploadFileIcon,
  MinusIcon,
  IconPlus,
  CloseCross,
  ReturnIcon,
  ZotokIcon,
  New: New,
  offer: offer,
  Special: Special,
  Folder: Folder,
  NoProducts,
  DownloadIcon,
  DocumentIcon,
  ScheduleIcon,
  iconInfo,
  SchedulerIcon,
  DeactivateIcon,
  ExecuteIcon,
  Notification,
  MertricsIcon,
  KPIIcon,
  PinnedIcon,
  UnPinnedIcon,
  TrendingUpIcon,
  TrendingDownIcon,
  ComboView,
  ChartIcon,
  ConfigSetting,
  ErrorIcon,
  AddCircleIcon,
  Click,
  OrderIcon_,
  RightBlack,
  RightBlue,
  Roi,
  ViewIcon,
  ProductIcon,
  CustomersIcon,
  GraphIcon,
  PlusOutline,
  InfoNew,
  RightIcon,
  DefaultProduct,
  NoResults,
  DownArrow,
  ArrowUpNew,
  RefreshIconCampaign,
  MiscTagForCustomer,
  NeutralGrey,
  Image,
  Loader,
  CampaignDefaultImg,
  DefaultPicture,
  TimeIcon,
  ArrowLeftIcon,
  View,
  PlusIconBlack,
  CaretDownIcon,
  CloseRed,
  ArrowDownDark,
  CampaignDefaultTemplate,
  ProductDefaultTemplate,
  DefaultCheckboxNew,
  CheckedCheckboxNew,
  NeutralCheckboxNew,
  Rupee
};
