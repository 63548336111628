import { ColumnConfig, PriceProductList2ColumnKey } from '../model';

export const PriceProductListConfig2: Record<
  PriceProductList2ColumnKey,
  ColumnConfig
> = {
  sNo: {
    name: 'S. No',
  },
  skuCode: {
    name: 'SKU Code',
    field: 'NavigateLink',
  },
  productTitle: {
    name: 'Product Title',
  },
  status: {
    name: 'Status',
  },
  categories: {
    name: 'Categories',
  },


  sellingPrice: {
    name: 'Selling Price',
  },
//   sellingPriceInput: {
//     name: 'Selling Price',
//     field: 'component',
//   },
  marginPrice: {
    name: 'Price with Margin',
  },
//   marginPriceInput: {
//     name: 'Price with Margin',
//     field: 'component',
//   },
};
