import { ColumnConfig, SchedulerColumnKey } from '../model';

export const schedulerConfig: Record<
  SchedulerColumnKey,
  ColumnConfig
> = {
  scheduleName: {
    name: 'Schedule Name',
  },
  frequency: {
    name: 'Frequency'
  },
  sentToTeamMembers: {
    name: 'Sent to Team Members'
  },
  status: {
    name: 'Status'
  },
  action: {
    name: ''
  }
};