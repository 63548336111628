import { ColumnConfig, ProductPerformanceReportColumnKey } from '../../model';

export const ProductPerformanceReportConfig: Record<
  ProductPerformanceReportColumnKey,
  ColumnConfig
> = {
  sNo: {
    name: 'S.No',
  },
  sku: {
    name: 'Sku',
  },
  productName: {
    name: 'Product Name',
  },
  category: {
    name: 'Category',
  },
  division: {
    name: 'Division',
  },
  noOfOrders: {
    name: 'Total Orders',
    alignment: 'flex-end',
  },
  totalQty: {
    name: 'Total Qty',
    alignment: 'flex-end',
  },
  totalValue: {
    name: 'Total Value',
    alignment: 'flex-end',
  },
  avgSellingPrice: {
    name: 'Avg Selling Price',
    alignment: 'flex-end',
  },
};
