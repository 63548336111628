import { ColumnConfig, SalesTeamCheckInReportColumnKey } from '../../model';

export const salesTeamCheckInReportConfig: Record<
  SalesTeamCheckInReportColumnKey,
  ColumnConfig
> = {
  sNo: {
    name: 'S.No',
  },
  salesPersonName: {
    name: 'Sales Person',
  },
  customerName: {
    name: 'Customer',
  },
  checkInDate: {
    name: 'Check In Date:',
    sortField: 'visitedDate',
  },
  checkInTime: {
    name: 'Check In Time:',
    // sortField: 'visiteddate',
  },
  checkInStatus: {
    name: 'Check In Status',
    isComponent: true,
  },

  // noOfCheckins: {
  //   name: 'Number of checkins',
  //   alignment: 'flex-end',
  // },
  noOfOrders: {
    name: 'No Of Orders',
    sortField: 'noOfOrders',
    alignment: 'flex-end',
  },
  checkinTotalPaymentsCollected: {
    name: 'Total Payments Collected',
    alignment: 'flex-end',
  },
  checkinPaymentCollectedValue: {
    name: 'Payment Collected Value',
    alignment: 'flex-end',
  },
  checkinPaymentsCollectedByUPI: {
    name: 'Payments Collected By UPI',
    alignment: 'flex-end',
  },
  checkinPaymentsCollectedByCash: {
    name: 'Payments collected by Cash',
    alignment: 'flex-end',
  },
  checkinPaymentsCollectedByCheque: {
    name: 'Payments Collected by Cheque',
    alignment: 'flex-end',
  },
};
