import { ColumnConfig, WorkspaceColumnKey } from '../model';

export const workspaceConfig: Record<WorkspaceColumnKey, ColumnConfig> = {
  moduleName: {
    name: 'Module Name',
  },
  activeSettings: {
    name: 'Active Settings',
  },
  lastUpdated: {
    name: 'Last Updated',
  },
  action: {
    name: '',
    field: 'component',
  },
};
