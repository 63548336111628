import { DrawerConfigValues } from '../model';

export const ChatCustomerDrawer: Record<any, DrawerConfigValues> = {
  details: {
    tabName: 'Customer Info',
    sections: [
      {
        sectionName: 'Customer Details',
        blocks: [
          {
            inputs: [
              {
                inputType: 'textInput',
                name: 'name',
                label: 'Full Name',
                required: true,
                placeholder: 'Enter Name',
              },
            ],
          },
          {
            inputs: [
              {
                inputType: 'textInput',
                name: 'mobile',
                label: 'Mobile Number',
                required: true,
                placeholder: '9999999999',
                prefixType: 'mobile',
                inputValueType: 'mobileNumber',
              },
            ],
          },
          {
            inputs: [
              {
                inputType: 'textInput',
                name: 'email',
                label: 'Email ID',
                required: true,
                placeholder: 'Enter Email Id',
                inputValueType: 'email',
              },
            ],
          },
          {
            inputs: [
              {
                inputType: 'textInput',
                name: 'firmName',
                label: 'Firm Name',
                required: true,
                placeholder: 'Enter firm Name',
              },
            ],
          },
        ],
      },
      {
        sectionName: 'Firm Address',
        blocks: [
          {
            inputs: [
              {
                inputType: 'textInput',
                name: 'address1',
                label: 'Address Line 1',
                required: true,
                placeholder: 'Enter Address',
              },
            ],
          },
          {
            inputs: [
              {
                inputType: 'textInput',
                name: 'address2',
                label: 'Address Line 2',
                required: true,
                placeholder: 'Enter Address',
              },
            ],
          },
          {
            inputs: [
              {
                inputType: 'textInput',
                name: 'pin',
                label: 'Pin Code',
                required: true,
                inputValueType: 'postalCode',
              },
              {
                inputType: 'textInput',
                name: 'city',
                label: 'City',
                required: true,
                placeholder: 'Enter City Name',
              },
            ],
          },
          {
            inputs: [
              {
                inputType: 'dropdownInput',
                name: 'state',
                label: 'State',
                required: false,
                placeholder: 'Select',
              },
              {
                inputType: 'textInput',
                name: 'shippingCountry',
                label: 'Country',
                required: false,
                placeholder: 'Select',
              },
            ],
          },
        ],
      },
    ],
  },

  // link: {
  //   tabName: 'CFA/Division',
  //   sections: [
  //     {
  //       sectionName: '',
  //       sectionId: 'cfaDivisionLink',
  //       isCustomSection: true,
  //     },
  //   ],
  // },
};
