import { ChatActionViewProductsColumnKey, ColumnConfig } from '../../model';

export const chatActionViewProductsConfig: Record<
  ChatActionViewProductsColumnKey,
  ColumnConfig
> = {
  productTitle: {
    name: 'Product Title',
    isCheckboxField: true,
    field: 'checkboxText',
  },
  moq: {
    name: 'MOQ',
    alignment: 'flex-end'
  },
  margin: { name: 'Margin', alignment: 'flex-end' },
  tax: { name: 'tax',alignment: 'flex-end' },
  price: { name: 'price', alignment: 'flex-end', sortField: 'price' },
};
