import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../root';
import { ProductVariants, ModifiedProducts, CategoryChildren } from './model';
import { Division, divisionApi, DivisionResponse } from '../division';

export const PRODUCTS_FEATURE_KEY = 'productsData';

export interface ProductsState {
  product: ProductVariants[];
  productLoadingState: boolean;
  edit: boolean;
  createProduct: boolean;
  modifiedProductsList: ModifiedProducts[];
  divisionsList: Division[];
  categoriesList: CategoryChildren[];
  selectedCategory: CategoryChildren | null;
  subCategoriesList: CategoryChildren[];
  defaultCategory: CategoryChildren | null;
}

export const initialProductsState: ProductsState = {
  product: [],
  productLoadingState: false,
  edit: false,
  createProduct: false,
  modifiedProductsList: [],
  divisionsList: [],
  categoriesList: [],
  subCategoriesList: [],
  selectedCategory: {
    id: '',
    name: 'All',
    categoryCode: '',
    children: [],
  },
  defaultCategory: {
    id: '',
    name: 'All',
    categoryCode: '',
    children: [],
  },
};

export const productsSlice = createSlice({
  name: PRODUCTS_FEATURE_KEY,
  initialState: initialProductsState,
  reducers: {
    addProducts(state, action: PayloadAction<ProductVariants[]>) {
      state.product = action.payload;
      state.productLoadingState = true;
    },
    updateCreateProduct(state, action: PayloadAction<boolean>) {
      state.createProduct = action.payload;
    },
    updateModifiedProductsList(
      state,
      action: PayloadAction<ModifiedProducts[]>
    ) {
      state.modifiedProductsList = action.payload;
    },
    updateCategoriesChipList(state, action: PayloadAction<CategoryChildren[]>) {
      state.categoriesList = action.payload;
    },
    updateSelectedChipCategory(state, action: PayloadAction<CategoryChildren>) {
      state.selectedCategory = action.payload;
    },
    updateSubCategoriesChipList(
      state,
      action: PayloadAction<CategoryChildren[]>
    ) {
      state.subCategoriesList = action.payload;
    },
    addDivisions(state, action: PayloadAction<DivisionResponse>) {
      state.divisionsList = action.payload.divisions;
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      divisionApi.endpoints.getDivisions.matchFulfilled,
      (state, action) => {
        productsSlice.caseReducers.addDivisions(state, action);
      }
    );
  },
});

export const productsReducer = productsSlice.reducer;

export const {
  addProducts,
  updateCreateProduct,
  updateCategoriesChipList,
  updateSelectedChipCategory,
  updateModifiedProductsList,
  updateSubCategoriesChipList,
} = productsSlice.actions;

export const getProductsSliceState = (rootState: RootState): ProductsState =>
  rootState[PRODUCTS_FEATURE_KEY];

export const getProductsData = createSelector(
  getProductsSliceState,
  (state) => state.product
);

export const getCategoriesList = createSelector(
  getProductsSliceState,
  (state) => state.categoriesList
);

export const getSelectedChipCategory = createSelector(
  getProductsSliceState,
  (state) => state.selectedCategory
);

export const getSubCategoryChipList = createSelector(
  getProductsSliceState,
  (state) => state.subCategoriesList
);

export const getDefaultCategory = createSelector(
  getProductsSliceState,
  (state) => state.defaultCategory
);

export const getCreateProduct = createSelector(
  getProductsSliceState,
  (state) => state.createProduct
);

export const getModifiedProductsList = createSelector(
  getProductsSliceState,
  (state) => state.modifiedProductsList
);

export const getDivisionsList = createSelector(
  getProductsSliceState,
  (state) => state.divisionsList
);
