import { createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../root';

export const VISIT_FEATURE_KEY = 'visitData';

export interface VisitState {
  todayVisitedCustomersIds: {
    [key: string]: boolean;
  };
}

export const initialVisitState: VisitState = {
  todayVisitedCustomersIds: {},
};

export const visitSlice = createSlice({
  name: VISIT_FEATURE_KEY,
  initialState: initialVisitState,
  reducers: {
    updateTodayVisitedCustomerIds(state, action) {
      const newList = { ...state.todayVisitedCustomersIds };
      action.payload?.payload?.forEach((id: string) => (newList[id] = true));
      state.todayVisitedCustomersIds = newList;
    },
  },
});

export const visitReducer = visitSlice.reducer;
export const { updateTodayVisitedCustomerIds } = visitSlice.actions;

export const getVisitSliceState = (rootState: RootState): VisitState =>
  rootState[VISIT_FEATURE_KEY];

export const getTodayVisitCustomerIds = createSelector(
  getVisitSliceState,
  (state) => state.todayVisitedCustomersIds
);
