import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FormValue } from '../helpers';
import { RootState } from '../root';
import { onboardingApi } from './api';

const ONBOARDING_FEATURE_KEY = 'onboardingData';

export interface OnboardingState {
  firmName: string;
  firmAddress: string;
  location: string[];
  userCreationValid: boolean;
  userCreationFormValues: FormValue[];
}

export const initialOnboardingState: OnboardingState = {
  firmName: '',
  firmAddress: '',
  location: [],
  userCreationValid: false,
  userCreationFormValues: [],
};

export const onboardingSlice = createSlice({
  name: ONBOARDING_FEATURE_KEY,
  initialState: initialOnboardingState,
  reducers: {
    updateFirmName(state, action: PayloadAction<string>) {
      state.firmName = action.payload;
    },
    updateFirmAddress(state, action: PayloadAction<string>) {
      state.firmAddress = action.payload;
    },
    updateLocation(state, action: PayloadAction<string[]>) {
      state.location = action.payload;
    },
    updateUserCreationValid(state, action: PayloadAction<boolean>) {
      state.userCreationValid = action.payload;
    },
    updateUserCreationFormValues(state, action: PayloadAction<FormValue[]>) {
      state.userCreationFormValues = action.payload;
    },
  },
});

export const onboardingReducer = onboardingSlice.reducer;

export const {
  updateFirmName,
  updateFirmAddress,
  updateLocation,
  updateUserCreationValid,
  updateUserCreationFormValues,
} = onboardingSlice.actions;

export const getOnboardingSliceState = (
  rootState: RootState
): OnboardingState => rootState[ONBOARDING_FEATURE_KEY];

export const getFirmName = createSelector(
  getOnboardingSliceState,
  (state) => state.firmName
);

export const getFirmAddress = createSelector(
  getOnboardingSliceState,
  (state) => state.firmAddress
);

export const getLocation = createSelector(
  getOnboardingSliceState,
  (state) => state.location
);

export const getUserCreationValid = createSelector(
  getOnboardingSliceState,
  (state) => state.userCreationValid
);

export const getUserCreationFormValues = createSelector(
  getOnboardingSliceState,
  (state) => state.userCreationFormValues
);
