import { ColumnConfig, CommsCustomersColumnKey } from '../../model';

export const commsCustomerConfig: Record<
  CommsCustomersColumnKey,
  ColumnConfig
> = {
  customerName: {
    name: 'Customer Name',
    isCheckboxField: true,
    isComponent: true,
  },
  customerCode: { name: 'Customer ID' },
  city: { name: 'City' },

  // state: { name: 'State' },
  added: { name: '' },
};
