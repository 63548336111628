import React, { useContext, useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Icon from 'react-native-easy-icon';
import { Chip } from '@mui/material';
import './zo-multi-select.scss';
import { Avatar, SHARED_SVGS, ThemeContext } from '@zonofi/shared';

interface option {
  name: string;
  id: number | string;
}
interface MultiSelectProps {
  /**
   * Provide options which is shwon in the drop donw list.
   */
  options: option[];
  /**
   * Provide the tite of the drop down list.
   */
  title?: string;

  /**
   * Value for dorp down
   */
  value: any;
  /**
   * Callback function to be called on selcet of item from drop down list.
   */
  onSelectValue: (val: any) => void;
  /**
   * Placeholder text for the drop down
   */
  placeholder: string;
  /**
   * Custom style populated over the multi-select.
   */
  customMultiSelectStyle?: React.CSSProperties;
  /**
   * Custom style populated on the input feild
   */
  customInputStyle?: React.CSSProperties;
  /**
   * Provide this value for the required drop down (default: false)
   */
  required?: boolean;
  /**
   * Avatar to be populated on the left of the option text. (By default Text Avatar to be shown)
   */
  setAvatar?: React.JSX.Element;
  /**
   * Property to hide the avatar. by defalue false value is given.
   */
  hideAvatar?: boolean;
  /**
   * Property to be allowed for single select. By default false value is given (By default Multiselect is applied)
   */
  singleSelect?: boolean;
  /**
   * An element to display at the end of the input field.
   * Example: An icon or text.
   */
  endAdornment?: React.JSX.Element;
  /**
   * Disabled multi-select or select. By defaut false is given
   */
  disabled?: boolean;
  /**
   * Callback function to be called on click of multiselect
   */
  onclick?: () => void;
  /**
   * Provide the Limit tags. by defaut 4 is given
   */
  limitTag?: number;
}

const uncheckedIcon = (
  <img
    alt="uncheckedBox"
    src={SHARED_SVGS.UncheckedBox}
    width="16px"
    height="16px"
    style={{ marginTop: '2px' }}
  />
);
const checkedIcon = (
  <Icon type={'antdesign'} name={'checksquare'} size={16} color="#2C9AD9" style={{ borderRadius: 6, }} />
);
const closeIcon = (
  <Icon
    type={'evilicon'}
    name={'close'}
    size={16}
    color="#000"
    //@ts-ignore
    style={{ cursor: 'pointer' }}
  />
);

const ArrowIcon = ({ isOpen }: { isOpen: boolean }) => (
  <img
    src={isOpen ? SHARED_SVGS.SelectArrowUp : SHARED_SVGS.SelectArrowDown}
    alt="Arrow Icon"
    style={{
      width: '16px',
      height: '16px',
      marginRight: '0px',
      transition: 'transform 0.3s ease',
      filter: isOpen
        ? 'invert(29%) sepia(10%) saturate(178%) hue-rotate(199deg) brightness(87%) contrast(88%)' // Example dark color
        : 'invert(29%) sepia(10%) saturate(178%) hue-rotate(199deg) brightness(87%) contrast(88%)' // Example gray color
    }}
  />
);

export function MultiSelect(props: MultiSelectProps) {
  const {
    title,
    options,
    placeholder,
    required,
    value,
    onSelectValue,
    customInputStyle,
    customMultiSelectStyle,
    setAvatar,
    hideAvatar = false,
    singleSelect = false,
    endAdornment = null,
    disabled = false,
    limitTag = 4,
    onclick,
  } = props;
  const { theme } = useContext(ThemeContext);
  const [borderColor, setBorderColor] = useState('#D9D9D9');
  const [textColor, setTextColor] = useState('#000');
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '6px',
      }}
    >
      {title && (
        <span
          style={{
            ...theme.fontConfig.regular,
            fontSize: '14px',
            color: textColor,
            fontWeight: 400,
          }}
        >
          {title}
        </span>
      )}
      <Autocomplete
        multiple={!singleSelect}
        value={value}
        disableCloseOnSelect={!singleSelect}
        limitTags={limitTag}
        options={options}
        readOnly={disabled}
        className="autocomplete-search-location"
        sx={{

          '& fieldset': {
            border: 'none',
          },
          '& MuiAutocomplete-inputRoot': {
            fontFamily: 'Segoe-UI',
            fontSize: '14px',
            fontWeight: '500',
          },
        }}
        ListboxProps={{
          sx: {
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
            border: '1px solid #D9D9D9',
            borderRadius: '8px',
            backgroundColor: '#FFFFFF',
            '& .MuiAutocomplete-option[aria-selected="true"]': {
              backgroundColor: 'white',
            },
          },
        }}
        style={{ width: '100%', backgroundColor: 'white', ...customMultiSelectStyle }}
        clearIcon={null}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        onChange={(event, newValue) => {
          if (!singleSelect) {
            // Handle multi-select
            if (
              Array.isArray(newValue) &&
              newValue.find((option) => option.all)
            ) {
              return onSelectValue(
                value.length === options.length ? [] : options
              );
            }
            onSelectValue(newValue);
          } else {
            // Handle single select
            onSelectValue(newValue);
          }
        }}
        getOptionLabel={(option: any) => option.name}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        filterOptions={(options: any, params: any) => {
          const filter = createFilterOptions();
          const filtered = filter(options, params);
          return [...filtered];
        }}
        renderOption={(props, option, { selected }) => {
          return (
            <li {...props}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '12px',
                  alignItems: 'center',
                }}
              >
                {!singleSelect && (
                  <Checkbox
                    style={{ padding: '0px', textTransform: 'capitalize' }}
                    icon={uncheckedIcon}
                    checkedIcon={checkedIcon}
                    checked={
                      option.all
                        ? !!(value.length === options.length)
                        : selected
                    }
                  />
                )}
                {!hideAvatar &&
                  (setAvatar ? (
                    setAvatar
                  ) : (
                    <Avatar cfa={true} title={option.name} size={24} />
                  ))}
                <span
                  style={{
                    fontFamily: 'Segoe-UI',
                    fontSize: '14px',
                    textTransform: 'capitalize',
                  }}
                >
                  {option.name}
                </span>
              </div>
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            required={required}
            placeholder={placeholder}
            InputProps={{
              ...params.InputProps,
              // endAdornment: endAdornment,
              endAdornment: (
                <>
                  {endAdornment}
                  <ArrowIcon isOpen={isOpen} />
                </>
              ),
              onClick: () => onclick && onclick(),
              // onFocus: () => setBorderColor('#33A7E8'),
              // onBlur: () => setBorderColor('#D9D9D9'),
              onFocus: () => {
                setBorderColor('#33A7E8');
                setTextColor('#33A7E8');
              },
              onBlur: () => {
                setBorderColor('#D9D9D9');
                setTextColor('#000');
              },
              sx: {
                minHeight: '48px',
                height: 'auto',
                width: '100%',
                alignItems: 'flex-start',
                border: `1px solid ${borderColor}`,
                overflow: 'auto',
                borderRadius: '6px',
                ...customInputStyle,
              },
            }}
            inputProps={{
              ...params.inputProps,
              sx: {
                '&::placeholder': {
                  color: '#BFC0C2',
                  opacity: 1,
                  fontSize: '14px',
                  fontFamily: 'Segoe-UI',
                },
              },
              style: { padding: '0px', fontFamily: 'Segoe-UI' },
            }}
          />
        )}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              size="small"
              avatar={
                setAvatar ? (
                  setAvatar
                ) : (
                  <Avatar cfa={true} title={option.name} size={16} />
                )
              }
              label={
                <span style={{ textTransform: 'capitalize' }}>
                  {option.name}
                </span>
              }
              deleteIcon={closeIcon}
              {...getTagProps({ index })}
              style={{
                fontSize: '12px',
                fontFamily: 'Segoe-UI',
                border: '0.5px solid #D9D9D9',
                backgroundColor: '#FFFFFF',
                borderRadius: '20px',
                height: 'auto',
                padding: '2px 6px',
                margin: '0 2px 2px 0 ',
              }}
            />
          ))
        }
      />
    </div>
  );
}
