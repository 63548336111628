import { Api } from '../api/root-api';
import {
  ChequeBookRequest,
  ChequeBookResponse,
  DownloadChequeBookRequest,
  DownloadChequeBookResponse,
  ChequeQueryParams,
  SearchChequeBookRequest,
  signedUrlChequeRequest,
  signedUrlChequeResponse,
} from './model';

export const chequebookApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    getChequeBookReports: builder.query<
      ChequeBookResponse,
      { workspaceId: string; params: ChequeBookRequest }
    >({
      query(arg) {
        return {
          url: `paymentinstruments/${arg.workspaceId}`,
          method: 'GET',
          params: arg.params,
        };
      },
    }),
    searchChequeBookReports: builder.query<
      ChequeBookResponse,
      { workspaceId: string; params: SearchChequeBookRequest }
    >({
      query(arg) {
        return {
          url: `paymentinstruments/search/${arg.workspaceId}`,
          method: 'GET',
          params: arg.params,
        };
      },
    }),
    downloadChequeBookReports: builder.mutation<
      DownloadChequeBookResponse,
      { workspaceId: string; params: ChequeQueryParams }
    >({
      query: (arg) => {
        return {
          url: `paymentinstruments/download/${arg.workspaceId}`,
          body: arg.params,
          method: 'POST',
        };
      },
    }),
    getChequeDownload: builder.query<
      DownloadChequeBookResponse,
      { workspaceId: string; params: DownloadChequeBookRequest }
    >({
      query(arg) {
        return {
          url: `paymentinstruments/download/${arg.workspaceId}`,
          method: 'GET',
          params: arg.params,
        };
      },
    }),
    getChequeSignUrl: builder.mutation<
      signedUrlChequeResponse,
      { params: signedUrlChequeRequest }
    >({
      query(arg) {
        return {
          url: `sign/url`,
          method: 'POST',
          body: arg.params,
        };
      },
    }),
  }),
});

export const {
  useLazyGetChequeBookReportsQuery,
  useLazySearchChequeBookReportsQuery,
  useDownloadChequeBookReportsMutation,
  useLazyGetChequeDownloadQuery,
  useGetChequeSignUrlMutation,
  useGetChequeDownloadQuery,
} = chequebookApi;
