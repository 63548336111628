import { ChatActionViewOrderEditColumnKey, ColumnConfig } from '../../model';

export const chatActionViewOrderEditConfig: Record<
  ChatActionViewOrderEditColumnKey,
  ColumnConfig
> = {
  skuCode: { name: 'SKU Code' },
  title: {
    name: 'Product Title',
    field: 'component',
  },
  moq: {
    name: 'MOQ',
    alignment: 'flex-end',
  },
  price: {
    name: 'Price',
    alignment: 'flex-end',
  },
  quantity: {
    name: 'Quantity',
    alignment: 'flex-end',
    field: 'component',
  },
  total: {
    name: 'Total',
    alignment: 'flex-end',
  },
  action: { name: '', field: 'component' },
};
