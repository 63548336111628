import { Api } from '../api';
import {
  CustomerByOrderValueReq,
  CustomerByOrderValueRes,
  OrdersPlacedBySalesmanRes,
  OrdersPlacedbySalesmanReq,
  SalesInvoicesReportReq,
  SalesInvoicesReportRes,
  SalesLeaderBoardReq,
  SalesLeaderBoardRes,
  SalesOrdersReportReq,
  SalesOrdersReportRes,
  SalesPaymentsReportReq,
  SalesPaymentsReportRes,
  OrdersPlacedBySalesManForCustomerRes,
  OrdersPlacedBySalesManForCustomerReq,
  SalesClockInNOutRes,
  SalesClockInNOutReq,
  ClockInHistoryRes,
  ClockInHistoryReq,
} from '.';

export const salesApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    getCustomersByOrderValue: builder.mutation<
      CustomerByOrderValueRes,
      CustomerByOrderValueReq
    >({
      query: (arg) => ({
        url: 'hub/reports/api/v2/order-report/customers/by-order-value',
        method: 'POST',
        body: arg,
      }),
    }),
    getSalesLeaderBoard: builder.mutation<
      SalesLeaderBoardRes,
      SalesLeaderBoardReq
    >({
      query: (arg) => ({
        url: 'hub/reports/api/v2/sales-order-report/leader-board',
        method: 'POST',
        body: arg,
      }),
    }),
    getOrdersPlacedBySalesMan: builder.mutation<
      OrdersPlacedBySalesmanRes,
      OrdersPlacedbySalesmanReq
    >({
      query: (arg) => ({
        url: 'hub/reports/api/v2/sales-order-report/orders/placed',
        method: 'POST',
        body: arg,
      }),
    }),
    getSalesOrdersReport: builder.mutation<
      SalesOrdersReportRes,
      SalesOrdersReportReq
    >({
      query: (arg) => {
        const payload = {
          url: 'hub/reports/api/v2/sales-order-report/total-customer-orders',
          method: 'GET',
          params: arg,
        };
        if (!arg?.allowCache) {
          Object.assign(payload, {
            headers: {
              'Cache-control': 'no-cache',
            },
          });
        }
        return payload;
      },
    }),
    getSalesInvoicesReport: builder.mutation<
      SalesInvoicesReportRes,
      SalesInvoicesReportReq
    >({
      query: (arg) => ({
        url: 'hub/reports/api/v2/sales-daily-invoice/customers/invoices',
        method: 'POST',
        body: arg,
      }),
    }),
    getSalesPaymentsReport: builder.mutation<
      SalesPaymentsReportRes,
      SalesPaymentsReportReq
    >({
      query: (arg) => ({
        url: 'hub/reports/api/v2/sales-daily-payments/customers/payments',
        method: 'POST',
        body: arg,
      }),
    }),
    getOrdersPlacedBySalesManForCustomer: builder.mutation<
      OrdersPlacedBySalesManForCustomerRes,
      OrdersPlacedBySalesManForCustomerReq
    >({
      query: (arg) => ({
        url: 'hub/reports/api/v2/sales-order-report/orders/by-salesmen-for-customers',
        method: 'POST',
        body: arg,
      }),
    }),
    salesClockInNOut: builder.mutation<
      SalesClockInNOutRes,
      SalesClockInNOutReq
    >({
      query: (arg) => ({
        url: `hub/orgs/api/team-track/${arg.parms?.sellerWorkspaceId}`,
        method: 'POST',
        body: arg.body,
        params: arg.parms,
      }),
    }),
    clockInHistory: builder.query<ClockInHistoryRes, ClockInHistoryReq>({
      query: (arg) => ({
        url: `hub/orgs/api/team-track/${arg?.sellerWorkspaceId}`,
        method: 'GET',
        params: arg,
        headers: {
          'Cache-control': 'no-cache',
        },
      }),
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetCustomersByOrderValueMutation,
  useGetSalesLeaderBoardMutation,
  useGetOrdersPlacedBySalesManMutation,
  useGetSalesOrdersReportMutation,
  useGetSalesInvoicesReportMutation,
  useGetSalesPaymentsReportMutation,
  useGetOrdersPlacedBySalesManForCustomerMutation,
  useSalesClockInNOutMutation,
  useLazyClockInHistoryQuery,
} = salesApi;
