import { ColumnConfig, HeadDivisionColumnKey } from '../model';

export const headDivisionConfig: Record<HeadDivisionColumnKey, ColumnConfig> = {
  name: {
    name: 'Head Division Name',
  },
  code: {
    name: 'Head Division Code',
  },
  action: {
    name: '',
    field: 'component',
  },
};
