import { Api } from '../api/root-api';
import { updateUser } from '../auth';
import {
  CreateUserRequest,
  CreateUserResponse,
  InviteViaEmailResponse,
  InviteViaEmailRequest,
  ERPResponse,
  ERPRequest,
  ShareInviteResponse,
  ShareInviteRequest,
} from './model';

export const onboardingApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    createUser: builder.mutation<CreateUserResponse, CreateUserRequest>({
      query(params) {
        return { url: `users`, method: 'PUT', body: params };
      },
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(updateUser(data));
        } catch (err) {
          console.log(err, 'dispatchErr');
        }
      },
    }),
    inviteViaEmail: builder.mutation<
      InviteViaEmailResponse,
      InviteViaEmailRequest
    >({
      query(params: InviteViaEmailRequest) {
        const { senderWorkspaceId } = params;
        return {
          url: `send/principalinvites/email/${senderWorkspaceId}`,
          method: 'POST',
          body: params,
        };
      },
    }),
    getGSTDetails: builder.query<any, string>({
      query(arg) {
        return { url: `/gst/${arg}`, method: 'GET' };
      },
    }),
    addErpDetails: builder.mutation<ERPResponse, ERPRequest>({
      query(arg) {
        const { workspaceId, ...body } = arg;
        return {
          url: `erp-details/${workspaceId}`,
          method: 'POST',
          body: body,
        };
      },
    }),
    getShareInvite: builder.query<ShareInviteResponse, ShareInviteRequest>({
      query(arg) {
        return {
          url: `invite/${arg.customerId}`,
          method: 'GET',
          params: arg.params,
        };
      },
    }),
  }),
});

export const {
  useCreateUserMutation,
  useInviteViaEmailMutation,
  useLazyGetGSTDetailsQuery,
  useAddErpDetailsMutation,
  useLazyGetShareInviteQuery,
} = onboardingApi;
