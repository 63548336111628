import React, { useContext } from 'react';
import { Text, TouchableOpacity } from 'react-native';
import { ThemeContext } from '../contexts/ThemeContext';
import Icon from 'react-native-easy-icon';
import { colors } from '../colors/colors';
import { SHARED_SVGS } from '../assets';

interface AddActionProps {
  value?: number | string | null;
  text?: string;
  onPress: () => void;
  hide?: boolean;
  alignment?: 'flex-start' | 'flex-end';
}

export const AddAction: React.FC<AddActionProps> = ({
  value = null,
  text = 'Assign',
  hide = false,
  onPress,
  alignment,
}) => {
  const { theme } = useContext(ThemeContext);
  const [isHovered, setIsHovered] = React.useState(false);

  const handlePressIn = () => {
    setIsHovered(true);
  };

  const handlePressOut = () => {
    setIsHovered(false);
  };

  return value || hide ? (
    <div
      onClick={() => !hide && onPress()}
      onMouseEnter={handlePressIn}
      onMouseLeave={handlePressOut}
      style={{
        color: theme.secondary,
        ...theme.fontConfig.medium,
        fontSize: 14,
        textDecoration: 'underline',
        cursor: !hide ? 'pointer' : undefined,
        backgroundColor:
          !hide && isHovered ? colors.aliceBlue.color : undefined,
        border: !hide && isHovered ? `1px solid ${theme.secondary}` : undefined,
        padding: 8,
        display: 'flex',
        gap: '4px',
        borderRadius: 4,
        justifyContent: alignment ? alignment : 'flex-start',
      }}
    >
      {hide ? '' : value}
    </div>
  ) : (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
      onClick={onPress}
    >
      <div
        onMouseEnter={handlePressIn}
        onMouseLeave={handlePressOut}
        style={{
          backgroundColor: isHovered ? colors.aliceBlue.color : undefined,
          border: isHovered ? `1px solid ${theme.secondary}` : undefined,
          padding: 8,
          display: 'flex',
          gap: '4px',
          cursor: 'pointer',
          borderRadius: 4,
        }}
      >
        <Icon
          type={'antdesign'}
          name={'pluscircle'}
          color={theme.secondary}
          size={16}
        />
        <Text
          style={[
            {
              color: theme.secondary,
              ...theme.fontConfig.medium,
              fontSize: 14,
              marginTop: -1.5,
            },
          ]}
        >
          {text}
        </Text>
      </div>
    </div>
  );
};
