import { ColumnConfig, CFATrackPODetailsEditKey } from '../../model';

export const cfaTrackPODetailsEditConfig: Record<
  CFATrackPODetailsEditKey,
  ColumnConfig
> = {
  sequence: {
    name: 'Sequence',
    sortField: 'sequence',
  },
  orderNo: {
    name: 'Order No.',
    field: 'textWithImage',
    isHyperlink: true,
  },
  status: {
    name: 'Status',
  },
  skuCode: {
    name: 'SKU Code',
    field: 'checkboxNavigate',
    isCheckboxField: false,
  },
  dtProductTitle: {
    name: 'DT Product Title',
  },
  productTitle: {
    name: 'Product Title',
  },
  pts: {
    name: 'PTS',
    field: 'component',
  },
  orderedQty: {
    name: 'Ordered Qty',
    sortField: 'orderedQuantity',
    field: 'component',
  },
  uploadedQty: {
    name: 'Uploaded Qty',
    alignment: 'flex-end',
    sortField: 'uploadedQty',
  },
  tax: {
    name: 'Tax',
    alignment: 'flex-end',
    sortField: 'tax',
  },
  roundedAmount: {
    name: 'Rounded Amt',
    sortField: 'roundedAmount',
    alignment: 'flex-end',
  },
  orderSaving: {
    name: 'Order Saving',
    alignment: 'flex-end',
    sortField: 'orderSavings',
  },
  packSize: {
    name: 'Pack Size',
    alignment: 'flex-end',
    sortField: 'packSize',
  },
  coNo: {
    name: 'Co No.',
  },

  orderedAmt: {
    name: 'Ordered Amt',
    alignment: 'flex-end',
    sortField: 'orderedAmt',
  },
  divisions: {
    name: 'Divisions',
    isAdditional: true,
    isVisible: false,
  },

  cfa: {
    name: 'CFA',
    isAdditional: true,
    isVisible: false,
  },
  invoicedItems: {
    name: 'Invoiced Items',
    isAdditional: true,
    isVisible: false,
    alignment: 'flex-end',
  },
  invoicedValue: {
    name: 'Invoiced Value',
    isAdditional: true,
    isVisible: false,
    alignment: 'flex-end',
  },
  actions: {
    name: '',
    alignment: 'flex-end',
  },
};
