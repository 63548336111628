import { ChatActionViewInvoiceColumnKey, ColumnConfig } from '../../model';

export const chatActionViewInvoiceConfig: Record<
  ChatActionViewInvoiceColumnKey,
  ColumnConfig
> = {
  skuCode: {
    name: 'SKU Code',
  },
  productTitle: {
    name: 'Product Title',
    isComponent: true,
  },
  // batchAndExpiryDate: {
  //   name: 'Batch / Expiry Date',
  //   isComponent: true,
  // },
  // pts: {
  //   name: 'PTS',
  //   alignment: 'flex-end',
  // },
  orderedQuantity: {
    name: 'Ordered Qty',
    alignment: 'flex-end',
  },
  invoicedQuantity: {
    name: 'Invoiced Qty',
    alignment: 'flex-end',
  },
  taxAmount: {
    name: 'Tax Amt.',
    alignment: 'flex-end',
    isVisible: false
  },
  invoicedAmount: {
    name: 'Invoiced Amt.',
    isComponent: true,
    alignment: 'flex-end',
  },
};
