import { useEffect, useMemo, useState } from 'react';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import {
  CatalogueView,
  ConfigModule,
  getCustomerInvite,
  useCheckoutOrdersMutation,
  useGetOrderDetailsMutation,
  useGetTrackPODetailsMutation,
  useGetConfigDetailsMutation,
  useLazyPoFileDetailsByIdQuery,
} from '@zonofi/zono-money-store';
import {
  OrderData,
  OrderStatusEnum,
  WEBROUTES,
  extractDataFromCart,
  extractDataFromOrder,
  extractDataFromPO,
  extractDataFromPOForManual,
  useAppSelector,
  useCart,
} from '@zonofi/zono-money-helpers';
import { isEmpty } from 'lodash';

export const useOrderDetailsScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const [searchParams] = useSearchParams();

  const isCart = location.pathname?.includes('cart');
  const fromScreen = searchParams?.get('from');
  const hidePrice = searchParams?.get('hp');
  const campaignType = searchParams?.get('campaignType');
  const { orderId } = params;

  const inviteDetails = useAppSelector(getCustomerInvite);
  const { seller, inviteId } = inviteDetails;

  const [fetchOrder, fetchOrderResponse] = useGetOrderDetailsMutation();
  const [fetchPoFile, fetchPoFileResponse] = useGetTrackPODetailsMutation();
  const [fetchOrderAfterEdit, fetchOrderAfterEditResponse] =
    useGetOrderDetailsMutation();
  const [fetchPoFileAfterEdit, fetchPoFileAfterEditResponse] =
    useGetTrackPODetailsMutation();

  const [getPoDetailsWithIdAPI, getPoDetailsWithIdAPIResponse] =
    useLazyPoFileDetailsByIdQuery();
  const [
    getPoDetailsWithIdAPIAfterEdit,
    getPoDetailsWithIdAPIAfterEditResponse,
  ] = useLazyPoFileDetailsByIdQuery();
  const [getConfigAPI, getConfigAPIResponse] = useGetConfigDetailsMutation();
  const [checkout, checkoutResponse] = useCheckoutOrdersMutation();

  const [error, setError] = useState({
    show: false,
    msg: '',
  });
  const [moqError, setMoqError] = useState({ isError: false, quantity: 0 });
  const [orderDetails, setOrderDetails] = useState<OrderData>();
  const {
    callCartAPI,
    cartAPIResponse,
    productsInCart,
    setProductsInCart,
    cartSummaryAPIResponse,
    onIncrement,
    onDecrement,
    onDelete,
    onChangeQuantity,
    editOrderResponse,
    deleteLinesAPIResponse,
  } = useCart();

  const isPoFile = useMemo(() => {
    return isNaN(Number(orderId));
  }, [orderId]);

  const showImage = useMemo(() => {
    if (getConfigAPIResponse?.isSuccess && getConfigAPIResponse?.data) {
      if (
        getConfigAPIResponse?.data?.config?.catalogueView &&
        getConfigAPIResponse?.data?.config?.catalogueView !==
          CatalogueView.ListView
      ) {
        return true;
      }
    }
    return false;
  }, [getConfigAPIResponse]);

  const showPrice = useMemo(() => {
    if (hidePrice === 'true') {
      return false;
    }
    if (getConfigAPIResponse?.isSuccess && getConfigAPIResponse?.data) {
      if (
        getConfigAPIResponse?.data?.config?.displayCataloguePrices &&
        getConfigAPIResponse?.data?.config?.displayCataloguePrices !== 'false'
      ) {
        return true;
      }
    }
    return false;
  }, [getConfigAPIResponse, hidePrice]);

  const isLoading = useMemo(() => {
    return (
      fetchOrderResponse?.isLoading ||
      fetchPoFileResponse?.isLoading ||
      getPoDetailsWithIdAPIResponse?.isLoading ||
      getConfigAPIResponse?.isLoading
    );
  }, [
    fetchOrderResponse,
    fetchPoFileResponse,
    getPoDetailsWithIdAPIResponse,
    getConfigAPIResponse,
  ]);

  const isSubmitting = useMemo(() => {
    return checkoutResponse?.isLoading;
  }, [checkoutResponse]);

  const showOrderEdit = useMemo(() => {
    if (orderDetails?.status === OrderStatusEnum.ADDING_ITEMS) {
      return true;
    }
    return (
      (orderDetails?.status === OrderStatusEnum.ORDER_CREATED ||
        orderDetails?.status?.toLowerCase()?.includes('submitted')) &&
      !['C', 'Customer'].includes(orderDetails?.userType)
    );
  }, [orderDetails]);

  const getOrder = () => {
    if (isCart) {
      callCartAPI({
        customerId: inviteId,
        workspaceId: seller?.workspaceId,
        sellerWorkspaceId: seller?.workspaceId,
      });
    } else if (isPoFile) {
      if (fromScreen && fromScreen !== 'campaign') {
        // API used for manual orders
        getPoDetailsWithIdAPI({
          poFileId: orderId,
          workspaceId: seller?.workspaceId,
          params: {
            customerId: inviteId,
            includePromotionData: true,
            sellerWorkspaceId: seller?.workspaceId,
            // This is useless as we are always creating WApp order for Campaign
            // campaignType: campaignType,
          },
        });
      } else {
        fetchPoFile({
          workspaceId: seller?.workspaceId,
          body: {
            includeInvoice: true,
            includeTax: true,
            pofileId: String(orderId),
            sortBy: 'sequence',
            sortDirection: 'ASC',
            customerId: inviteId,
            includePromotionData: true,
          },
        });
      }
    } else {
      fetchOrder({
        workspaceId: seller?.workspaceId,
        orderId: Number(orderId),
        body: {
          includeInvoice: true,
          includeTax: true,
          includeCustomer: true,
          includePromotions: true,
          customerId: inviteId,
          includePromotionData: true,
        },
      });
    }
  };

  const getOrderAfterEdit = () => {
    if (isCart) {
      callCartAPI({
        customerId: inviteId,
        workspaceId: seller?.workspaceId,
        sellerWorkspaceId: seller?.workspaceId,
      });
    } else if (isPoFile) {
      if (fromScreen && fromScreen !== 'campaign') {
        // API used for manual orders
        getPoDetailsWithIdAPIAfterEdit({
          poFileId: orderId,
          workspaceId: seller?.workspaceId,
          params: {
            customerId: inviteId,
            includePromotionData: true,
            sellerWorkspaceId: seller?.workspaceId,
            // This is useless as we are always creating WApp order for Campaign
            // campaignType: campaignType,
          },
        });
      } else {
        fetchPoFileAfterEdit({
          workspaceId: seller?.workspaceId,
          body: {
            includeInvoice: true,
            includeTax: true,
            pofileId: String(orderId),
            sortBy: 'sequence',
            sortDirection: 'ASC',
            customerId: inviteId,
            includePromotionData: true,
          },
        });
      }
    } else {
      fetchOrderAfterEdit({
        workspaceId: seller?.workspaceId,
        orderId: Number(orderId),
        body: {
          includeInvoice: true,
          includeTax: true,
          includeCustomer: true,
          includePromotions: true,
          customerId: inviteId,
          includePromotionData: true,
        },
      });
    }
  };

  const onConfirm = () => {
    checkout({
      sellerWorkspaceId: seller?.workspaceId,
      customerId: inviteId,
      poFileIds: [...(orderDetails?.poFileIds || orderDetails?.poFileId)],
    });
  };

  const onAddMore = () => {
    if (fromScreen === 'campaign') {
      navigate(
        `${WEBROUTES.CATALOGUE}?source=cart&type=campaign${
          showPrice ? '' : '&hp=true'
        }`
      );
    } else if (orderId) {
      navigate(
        `${WEBROUTES.CATALOGUE}?source=order&orderId=${orderId}${
          showPrice ? '' : '&hp=true'
        }`
      );
    } else {
      navigate(
        `${WEBROUTES.CATALOGUE}?source=cart${showPrice ? '' : '&hp=true'}`
      );
    }
  };
  const onBackBtnClick = () => {
    navigate(-1);
  };

  useEffect(() => {
    getOrder();
  }, [orderId]);

  useEffect(() => {
    if (fetchOrderResponse?.isSuccess && fetchOrderResponse?.data) {
      if (isEmpty(fetchOrderResponse?.data)) {
        setOrderDetails(undefined);
      } else {
        const data = extractDataFromOrder(fetchOrderResponse?.data);
        setOrderDetails(data);
      }
    }
    if (fetchOrderResponse?.isError) {
      setError({
        show: true,
        msg: (fetchOrderResponse?.error as any)?.data?.message?.message
          ? (fetchOrderResponse?.error as any)?.data?.message?.message
          : 'Something went wrong!',
      });
    }
  }, [fetchOrderResponse]);

  useEffect(() => {
    if (fetchPoFileResponse?.isSuccess && fetchPoFileResponse?.data) {
      if (isEmpty(fetchPoFileResponse?.data)) {
        setOrderDetails(undefined);
      } else {
        const data = extractDataFromPO(fetchPoFileResponse?.data);
        setOrderDetails(data);
      }
    }
    if (fetchPoFileResponse?.isError) {
      setError({
        show: true,
        msg: (fetchPoFileResponse?.error as any)?.data?.message?.message
          ? (fetchPoFileResponse?.error as any)?.data?.message?.message
          : 'Something went wrong!',
      });
    }
  }, [fetchPoFileResponse]);

  useEffect(() => {
    if (
      fetchOrderAfterEditResponse?.isSuccess &&
      fetchOrderAfterEditResponse?.data
    ) {
      if (isEmpty(fetchOrderAfterEditResponse?.data)) {
        setOrderDetails(undefined);
      } else {
        const data = extractDataFromOrder(fetchOrderAfterEditResponse?.data);
        setOrderDetails(data);
      }
    }
  }, [fetchOrderAfterEditResponse]);

  useEffect(() => {
    if (
      fetchPoFileAfterEditResponse?.isSuccess &&
      fetchPoFileAfterEditResponse?.data
    ) {
      if (isEmpty(fetchPoFileAfterEditResponse?.data)) {
        setOrderDetails(undefined);
      } else {
        const data = extractDataFromPO(fetchPoFileAfterEditResponse?.data);
        setOrderDetails(data);
      }
    }
  }, [fetchPoFileAfterEditResponse]);

  useEffect(() => {
    if (
      getPoDetailsWithIdAPIResponse?.isSuccess &&
      getPoDetailsWithIdAPIResponse?.data
    ) {
      if (isEmpty(getPoDetailsWithIdAPIResponse?.data?.lines)) {
        setOrderDetails(undefined);
      } else {
        const data = extractDataFromPOForManual(
          getPoDetailsWithIdAPIResponse?.data
        );
        setOrderDetails(data);
      }
    }
  }, [getPoDetailsWithIdAPIResponse]);

  useEffect(() => {
    if (
      getPoDetailsWithIdAPIAfterEditResponse?.isSuccess &&
      getPoDetailsWithIdAPIAfterEditResponse?.data
    ) {
      if (isEmpty(getPoDetailsWithIdAPIAfterEditResponse?.data?.lines)) {
        setOrderDetails(undefined);
      } else {
        const data = extractDataFromPOForManual(
          getPoDetailsWithIdAPIAfterEditResponse?.data
        );
        setOrderDetails(data);
      }
    }
  }, [getPoDetailsWithIdAPIAfterEditResponse]);

  useEffect(() => {
    if (editOrderResponse?.isLoading) {
      const pvid =
        editOrderResponse?.originalArgs?.body?.lines?.[0]?.productVariantId;
      const orderId = editOrderResponse?.originalArgs?.body?.orderId;
      if (pvid && orderId) {
        setOrderDetails((prevState) => {
          return {
            ...prevState,
            lines: prevState?.lines?.map((i) => {
              if (
                i?.productVariantId === pvid &&
                i?.orderId === Number(orderId)
              ) {
                return {
                  ...i,
                  isLoading: true,
                };
              }
              return i;
            }),
          };
        });
      }
    } else {
      setOrderDetails((prevState) => {
        return {
          ...prevState,
          lines: prevState?.lines?.map((i) => {
            return {
              ...i,
              isLoading: false,
            };
          }),
        };
      });
    }
  }, [editOrderResponse]);

  useEffect(() => {
    if (editOrderResponse?.isSuccess) {
      console.log('order edit success');
      getOrderAfterEdit();
    } else if (editOrderResponse?.isError) {
      if (
        (editOrderResponse?.error as any)?.data?.message?.message ===
        'Quantity cannot be below the Minimum Order Quantity (MOQ)'
      ) {
        const product = orderDetails.lines.find(
          (line) =>
            line.productVariantId ===
            (editOrderResponse?.error as any)?.data?.message?.body?.lines?.[0]
              ?.productVariantId
        );
        setMoqError({
          isError: true,
          quantity: product?.moq || 0,
        });
        setOrderDetails((prevState) => {
          let lines = prevState?.lines?.map((line) => {
            if (
              line?.productVariantId ===
              (editOrderResponse?.error as any)?.data?.message?.body?.lines?.[0]
                ?.productVariantId
            ) {
              return {
                ...line,
                moqError: true,
              };
            }
            return line;
          });
          return {
            ...prevState,
            lines: lines,
          };
        });
      }
    }
  }, [editOrderResponse]);

  useEffect(() => {
    if (checkoutResponse?.isSuccess) {
      let skuCount = orderDetails?.lines?.length;
      navigate(
        `${WEBROUTES.ORDER_SUCCESSFULL}?skuCount=${skuCount}&successType=order`
      );
    }
  }, [checkoutResponse]);

  // Function to clear errors every 2 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      if (moqError.isError) {
        setMoqError({
          isError: false,
          quantity: 0,
        });
      }
      if (orderDetails?.lines?.some((item) => item?.moqError === true)) {
        setOrderDetails((prevItems) => {
          let lines = prevItems?.lines?.map((line) => {
            return {
              ...line,
              moqError: false,
            };
          });
          return {
            ...prevItems,
            lines: lines,
          };
        });
      }
    }, 2000);

    return () => clearInterval(interval);
  }, [orderDetails, moqError]);

  useEffect(() => {
    getConfigAPI({
      workspaceId: seller?.workspaceId,
      module: ConfigModule.CATALOGUE,
      rollback: false,
    });
  }, []);

  useEffect(() => {
    if (cartAPIResponse?.isSuccess) {
      const data = extractDataFromCart(
        cartAPIResponse?.data?.files,
        cartSummaryAPIResponse?.data
      );
      setOrderDetails(data);
    }
  }, [cartAPIResponse, cartSummaryAPIResponse]);

  return {
    orderDetails,
    setOrderDetails,
    onChangeQuantity,
    // onValueChange,
    onIncrement,
    onDecrement,
    onDelete,
    onConfirm,
    isPoFile,
    showImage,
    showPrice,
    onAddMore,
    isLoading,
    isSubmitting,
    fetchOrderResponse,
    fetchPoFileResponse,
    getPoDetailsWithIdAPIResponse,
    showOrderEdit,
    cartAPIResponse,
    error,
    setError,
    onBackBtnClick,
    moqError,
  };
};
