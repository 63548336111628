import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { hierarchyApi } from './api';
import { HierarchyList, HierarchyPayload } from './model';

export const hierarchyAdapter = createEntityAdapter<HierarchyList>({
  selectId: (hierarchyItem) => hierarchyItem.id,
});

export const hierarchySlice = createSlice({
  name: 'hierarchyItem',
  initialState: hierarchyAdapter.getInitialState(),
  reducers: {
    addHierarchyItem: hierarchyAdapter.addOne,
    addHierarchyItems: hierarchyAdapter.addMany,
    updateHierarchyItem: hierarchyAdapter.upsertOne,
    updateHierarchyItems: hierarchyAdapter.upsertMany,
    getHierarchySuccess(state, action: PayloadAction<any>) {
      console.log(action.payload, 'hierarchy');
      hierarchyAdapter.setAll(state, action.payload);
      return state;
    },
    getHierarchyFailed(state, action: PayloadAction<string>) {
      console.log('in get hierarchy failure ', state, action);
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      hierarchyApi.endpoints.getHierarchyList.matchFulfilled,
      (state, action) => {
        hierarchySlice.caseReducers.getHierarchySuccess(state, action);
      }
    );
  },
});

export const {
  addHierarchyItem,
  getHierarchySuccess,
  getHierarchyFailed,
  updateHierarchyItem,
} = hierarchySlice.actions;

export default hierarchySlice.reducer;
