import { ColumnConfig, CfaReturnsAllColumnKey } from '../../model';

export const principalReturnsConfig: Record<
  CfaReturnsAllColumnKey,
  ColumnConfig
> = {
  returnOrderNo: { name: 'Return Order No.', field: 'multiLine' },
  debitNote: { name: 'Debit Note' },
  returnCoNo: { name: 'Return CO No.' },
  date: {
    name: 'Submitted Date',
    sortField: 'date',
  },
  status: {
    name: 'Status',
    field: 'multiLine',
  },
  returnAmt: {
    name: 'Return Goods Value ',
    sortField: 'grossTotal',
    alignment: 'flex-end',
  },
  batchCount: {
    name: 'Batch Count',
    sortField: 'batchCount',
    alignment: 'flex-end',
  },
  customerName: {
    name: 'Customer Name',
    field: 'avatarNameStatus',
  },
  headDivisionName: {
    name: 'Header Division',
  },
  download: {
    name: '',
    alignment: 'flex-end',
  },
};
