import _ from 'lodash';
import { LoggedInUserType } from './enum';
import { store } from '../root';
import { getIsLoggedInOnBehalf, getUserData } from '../auth';
import { getWorkspace } from '../workspace';

export const useGetLoggedInUserType = (): LoggedInUserType => {
  const userData = getUserData(store.getState());
  const workspaceData = getWorkspace(store.getState());
  const isLoggedInOnBehalf = getIsLoggedInOnBehalf(store.getState());
  // return 'super-admin';
  if (!workspaceData.id && userData.role === 'admin' && !isLoggedInOnBehalf) {
    return 'super-admin';
  }
  if (!workspaceData.id && userData.role === 'support' && !isLoggedInOnBehalf) {
    return 'support';
  }
  if (userData.inviteUserType === 'CFA') {
    return 'cfa';
  }
  if (userData.inviteUserType === 'Sales') {
    return 'sales';
  }
  if (_.isEmpty(workspaceData)) return 'principal';
  // if (workspaceData.isSeller && workspaceData.isBuyer) return 'distributor';
  return workspaceData.isSeller ? 'principal' : 'distributor';
};
