import { useAppSelector } from '@zonofi/seller-rtk-store';
import {
  getCustomerInvite,
  useAddItemToActiveOrderMutation,
  useGetOrderDetailsMutation,
} from '@zonofi/zono-money-store';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export const useReorderScreen = () => {
  const params = useParams();
  const { orderId } = params;
  const [fetchOrder, fetchOrderResponse] = useGetOrderDetailsMutation();
  const [addToCartAPI, addToCartAPIResponse] =
    useAddItemToActiveOrderMutation();
  const inviteDetails = useAppSelector(getCustomerInvite);
  const { seller, inviteId } = inviteDetails;
  const navigate = useNavigate();

  useEffect(() => {
    fetchOrder({
      workspaceId: seller?.workspaceId,
      orderId: Number(orderId),
      body: {
        includeInvoice: false,
        includeTax: false,
        includeCustomer: false,
        includePromotions: false,
        customerId: inviteId,
        includePromotionData: false,
      },
    });
  }, [inviteId, orderId, seller]);

  useEffect(() => {
    if (fetchOrderResponse.data && fetchOrderResponse.isSuccess) {
      const order = fetchOrderResponse?.data;
      const lines = order?.lines?.map((line) => ({
        productVariantId: line?.productVariant?.id,
        quantity: line?.minOrderQty,
        operation: 'add',
      }));
      if (lines.length > 0) {
        addToCartAPI({
          customerId: inviteId,
          sellerWorkspaceId: seller?.workspaceId,
          source: 'whatsapp',
          lines,
        });
      }
    }
  }, [fetchOrderResponse]);

  useEffect(() => {
    if (addToCartAPIResponse?.isSuccess) {
      // navigate to cart
      navigate('/cart');
    }
  }, [addToCartAPIResponse]);
  return;
};
