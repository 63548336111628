import { BulkDivisionColumnkey, ColumnConfig } from '../model';

export const bulkDivisionUploadConfig: Record<
  BulkDivisionColumnkey,
  ColumnConfig
> = {
  divisionName: {
    name: 'Division Name',
  },
  divisionCode: { name: 'Division Code' },
  headDivisionName: { name: 'Head Division Name' },
  actions: { name: '', field: 'component' },
};
