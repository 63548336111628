import { ColumnConfig, CustomerTrackPODetailsColumnKey } from '../../model';

export const customerTrackPODetailsConfig: Record<
  CustomerTrackPODetailsColumnKey,
  ColumnConfig
> = {
  sequence: {
    name: 'Sequence',
    sortField: 'sequence',
  },
  skuCode: {
    name: 'SKU Code',
    field: 'checkboxNavigate',
    isCheckboxField: false,
  },
  coNo: {
    name: 'Co No.',
  },
  productTitle: {
    name: 'Product Title',
    isComponent: true,
  },
  orderNo: {
    name: 'Order No.',
    field: 'textWithImage',
    isHyperlink: true,
  },
  status: {
    name: 'Status',
  },
  pts: { name: 'PTS', alignment: 'flex-end', sortField: 'pts' },
  orderedQty: {
    name: 'Ordered Qty',
    alignment: 'flex-end',
    sortField: 'orderedQuantity',
  },
  orderedAmt: {
    name: 'Ordered Amt',
    alignment: 'flex-end',
    sortField: 'orderedAmt',
  },
  tax: {
    name: 'Tax',
    alignment: 'flex-end',
    sortField: 'tax',
  },

  orderSaving: {
    name: 'Order Saving',
    alignment: 'flex-end',
    sortField: 'orderSavings',
  },

  divisions: {
    name: 'Divisions',
    isAdditional: true,
    isVisible: false,
  },

  cfa: {
    name: 'CFA',
    isAdditional: true,
    isVisible: false,
  },
  invoicedItems: {
    name: 'Invoiced Items',
    isAdditional: true,
    isVisible: false,
    alignment: 'flex-end',
  },
  invoicedValue: {
    name: 'Invoiced Value',
    isAdditional: true,
    isVisible: false,
    alignment: 'flex-end',
  },
};
