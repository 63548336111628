import { RouteRequest, RouteResponse } from './model';
import { Api } from '../api/root-api';
import _ from 'lodash';

export const routesApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    getRoute: builder.query<RouteResponse, RouteRequest>({
      query: (params: RouteRequest) => ({
        url: `teams/groups/${params.workspaceId}?hierarchy=${params.hierarchy}`,
        method: 'GET',
      }),
    }),
    getGroupAssignment: builder.query<RouteResponse, RouteRequest>({
      query: (params: RouteRequest) => ({
        url: `teams/groups/${params.workspaceId}?inviteId=${params.inviteId}`,
        method: 'GET',
      }),
    }),
    getAssignedCustomerInfo: builder.query<RouteResponse, RouteRequest>({
      query: (params: RouteRequest) => ({
        url: `customergroups/assinged/${params.workspaceId}?inviteId=${params.inviteId}`,
        method: 'GET',
      }),
    }),
    removeGroupAssignment: builder.mutation<any, any>({
      query(arg) {
        return {
          url: 'teams/remove/group',
          method: 'POST',
          body: arg,
        };
      },
    }),
    removeCustomersGroupAssignment: builder.mutation<any, any>({
      query(arg) {
        return {
          url: 'customer/remove/group',
          method: 'POST',
          body: arg,
        };
      },
    }),
  }),
});

export const {
  useLazyGetRouteQuery,
  useLazyGetGroupAssignmentQuery,
  useLazyGetAssignedCustomerInfoQuery,
  useRemoveGroupAssignmentMutation,
  useRemoveCustomersGroupAssignmentMutation,
} = routesApi;
