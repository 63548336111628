import { ColumnConfig, RecommendedProductsColumnKey } from '../model';

export const recommendedProductsConfig: Record<
  RecommendedProductsColumnKey,
  ColumnConfig
> = {
  skuCodeAndProductTitle: {
    name: 'SKU Code & Product Title',
    field: 'checkboxNavigate',
    isCheckboxField: true,
  },
  savings: { name: 'S.Savings', sortField: 'savings', alignment: 'flex-end' },
  stock: { name: 'C.Stock', sortField: 'currentStock', alignment: 'flex-end' },
  sales: { name: 'T.Sales', sortField: 'sales', alignment: 'flex-end' },
  ptr: { name: 'PTR', sortField: 'unitSalePrice', alignment: 'flex-end' },
  pts: { name: 'PTS', sortField: 'unitPrice', alignment: 'flex-end' },
  margin: { name: 'Margin', sortField: 'marginValue', alignment: 'flex-end' },
  tax: { name: 'Tax', alignment: 'flex-end' },
  total: {
    name: 'Total',
    sortField: 'linePriceWithTax',
    alignment: 'flex-end',
  },
  packSize: { name: 'Pck Size', alignment: 'flex-end' },
  recommendedQty: { name: 'R.Qty', alignment: 'flex-end' },
};
