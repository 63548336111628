import React from 'react';
import { SVGS } from '@zonofi/zono-money-design-system';
import './catalog-header.scss';
import { useAppSelector } from '@zonofi/zono-money-helpers';
import { getCustomerInvite } from '@zonofi/zono-money-store';
import { CartIcon } from '../cart-icon/cart-icon';

interface CatalogHeaderProps {
  showSearch: boolean;
  setShowSearch: (val: boolean) => any;
  searchKey: string;
  setSearchKey: (val: string) => any;
  getName: (name: string) => any;
  isCatalogue: boolean;
  isPriceList: boolean;
  getCampaignDetailsResponse: any;
  onOpenMediaSearch: () => any;
  onSuccess: () => any;
  fromSource: string;
  backToCart: () => any;
  cartQuantity?: number;
  handleView: () => void;
  listView: boolean;
  isCatalogueViewToggleDisabled: boolean;
  showCategory: boolean;
}

export const CatalogHeader: React.FC<CatalogHeaderProps> = ({
  showSearch,
  setShowSearch,
  searchKey,
  setSearchKey,
  getName,
  isCatalogue,
  isPriceList,
  getCampaignDetailsResponse,
  onOpenMediaSearch,
  onSuccess,
  fromSource,
  backToCart,
  cartQuantity,
  handleView,
  listView,
  isCatalogueViewToggleDisabled,
  showCategory
}) => {
  const inviteData = useAppSelector(getCustomerInvite);
  const campaignName = getCampaignDetailsResponse?.data?.data?.campaignName;
  if (showSearch) {
    return (
      <div className={`catalog-header-search font-primary${!showCategory ? ' catalog-header-category-not-visible' : ''}`}>
        <div className="catalog-header-search-root">
          <img
            height={15}
            width={15}
            src={SVGS.LeftArrowBlack}
            alt="back"
            onClick={() => {
              setSearchKey('');
              setShowSearch(false);
            }}
          />
          <input
            className="catalog-header-search-input font-primary-semibold"
            value={searchKey}
            placeholder="Search with Product name or SKU"
            autoFocus
            onChange={(e) => {
              setSearchKey(e?.target?.value);
            }}
          />
          {searchKey?.length !== 0 && (
            <>
              <img
                src={SVGS.XCircle}
                alt="clear"
                onClick={() => {
                  setSearchKey('');
                }}
              />
              <div className="catalog-header-search-root-divider" />
            </>
          )}
          <img src={SVGS.CameraBlue} alt="camera" onClick={onOpenMediaSearch} />
        </div>
      </div>
    );
  }

  return (
    <div className={`catalog-header font-primary${!showCategory ? ' catalog-header-category-not-visible' : ''}`}>
      <div className="catalog-header-contents">
        {fromSource ? (
          <div className="catalog-header-back" onClick={backToCart}>
            <img src={SVGS.LeftArrowBlack} alt="back" />
            <span className=" font-primary-semibold">
              Back to {fromSource === 'order' ? 'Order' : 'Cart'}
            </span>
          </div>
        ) : (
          <div className="catalog-header-seller">
            <div className="catalog-header-seller-logo font-primary-semibold">
              {getName(inviteData?.buyer?.name)}
            </div>
            <div className="catalog-header-seller-info">
              <span className="catalog-header-seller-info-name font-primary-semibold">
                {inviteData?.buyer?.name}
              </span>
              <span className="catalog-header-seller-info-subtitle font-primary">
                {isCatalogue
                  ? 'Catalogue'
                  : isPriceList
                  ? 'Price List'
                  : campaignName
                  ? campaignName
                  : getCampaignDetailsResponse?.data?.data?.type === 'ORDER'
                  ? 'Campaign: Book Order'
                  : 'Campaign: Get Quote'}
              </span>
            </div>
          </div>
        )}
        <div className="catalog-header-options">
          <img
            src={SVGS.Search}
            alt="search"
            onClick={() => setShowSearch(true)}
          />
          <img
            src={SVGS.Camera}
            alt="camera"
            onClick={onOpenMediaSearch}
            width={20}
            height={19}
          />
          <img
            src={
              isCatalogueViewToggleDisabled
                ? SVGS.ViewToggleDisabled
                : !listView
                ? SVGS.GridView
                : SVGS.ListView
            }
            alt="list-view"
            onClick={() => {
              if (isCatalogueViewToggleDisabled) return;
              handleView();
            }}
          />
          {fromSource !== 'order' && (
            <CartIcon itemsCount={cartQuantity || 0} onCartClick={onSuccess} />
          )}
        </div>
      </div>
    </div>
  );
};
