import { ColumnConfig, CustomerCfaReportColumnKey } from '../../model';

export const cfaCustomerOrderReportConfig: Record<
  CustomerCfaReportColumnKey,
  ColumnConfig
> = {
  sNo: {
    name: 'S.No',
  },
  cfaName: {
    name: 'CFA',
  },
  orderDate: {
    name: 'Date',
    sortField: 'orderdate',
  },
  customerName: {
    name: 'Customer',
  },
  productName: {
    name: 'Product Name',
    sortField: 'productname',
  },

  orderQty: {
    name: 'Ordered Qty',
    alignment: 'flex-end',
  },
  orderValue: {
    name: 'Ordered Value',
    alignment: 'flex-end',
  },
  refOrderNumber: {
    name: 'Order Number',
    sortField: 'refordernumbers',
  },
  totalOrderValue: {
    name: 'Total Order Value'
  },
  totalSku: {
    name: 'Total Sku Count'
  },
  // totalOrders: {
  //   name: 'Total Orders',
  //   alignment: 'flex-end',
  // },
  sku: {
    name: 'SKU',
  },
};
