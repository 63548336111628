//@ts-nocheck
import _ from 'lodash';
import { Api } from '../api/root-api';
import { dispatch } from '../root';
import {
  SendOTPRequest,
  SendOTPResponse,
  VerifyOTPRequest,
  User,
  FCMTokenRequest,
  FCMTokenResponse,
  loginOnBehalfRequest,
  UserTokens,
  UserSubscriptionResponse,
  UserSubscriptionRequest,
  UserPermissionsResponse,
  SubscriptionRequest,
  SubscriptionResponse,
  ResendOTPRequest,
  ResendOTPResponse,
  AddMFAChannelRequest,
  AddMFAChannelResponse,
  WorkspaceAutRequest,
  WorkspaceAutResponse,
  TrackEventRequest,
  TrackEventType,
} from './model';
import {
  updateOtpValue,
  updateRefreshToken,
  updateToken,
  updateUserRoles,
  updateInviteUserType,
  storeSupportUserTokens,
  updateUser,
} from './slice';
import { updateLogInTracked } from '../channel';

export const userApi = Api.injectEndpoints({
  endpoints: (build) => ({
    sendOTP: build.mutation<SendOTPResponse, SendOTPRequest>({
      query: (SendOTPRequest) => {
        return {
          url: 'sendotp',
          body: SendOTPRequest,
          method: 'POST',
        };
      },
    }),
    resendOTP: build.mutation<ResendOTPResponse, ResendOTPRequest>({
      query: (ResendOTPRequest) => {
        return {
          url: 'resend-otp',
          body: ResendOTPRequest,
          method: 'POST',
        };
      },
    }),
    getMappedWorkspacesForSupportUser: build.mutation<
      any,
      {
        supportUserId: string;
      }
    >({
      query(arg) {
        return {
          url: `admin/user/${arg.supportUserId}`,
          method: 'GET',
        };
      },
      // async onQueryStarted(request, { dispatch, queryFulfilled }) {
      //   queryFulfilled
      //     .then((response) => {
      //       console.log('this is verify otp response', response);
      //       if (response?.data?.role === 'support') {
      //         dispatch(
      //           userApi.endpoints.getMappedWorkspacesForSupportUser.initiate({
      //             supportUserId: response?.data?.id,
      //           })
      //         );
      //       }

      //       !_.isEmpty(response?.data?.workspaceRoles) &&
      //         dispatch(updateUserRoles(response?.data?.workspaceRoles));
      //     })
      //     .catch((x) => {
      //       console.error(x);
      //     });
      // },
    }),
    verifyOTP: build.mutation<User, VerifyOTPRequest>({
      query: (VerifyOTPRequest) => {
        dispatch(updateOtpValue(VerifyOTPRequest));
        return {
          url: 'verifyotp',
          body: VerifyOTPRequest,
          method: 'POST',
        };
      },
    }),
    addMfaChannel: build.mutation<AddMFAChannelResponse, AddMFAChannelRequest>({
      query(arg) {
        return {
          url: 'change/sendOtp',
          method: 'POST',
          body: arg,
        };
      },
    }),
    verifyOTPPrincipalInvite: build.mutation<User, VerifyOTPRequest>({
      query: (VerifyOTPRequest) => {
        dispatch(updateOtpValue(VerifyOTPRequest));
        return {
          url: 'verifyotp',
          body: VerifyOTPRequest,
          method: 'POST',
        };
      },
    }),
    getUser: build.query<User, any>({
      query: (args) => {
        return {
          url: 'users/me',
          method: 'GET',
          params: args,
        };
      },
    }),
    getUserV2: build.query<User, any>({
      query: (args) => {
        return {
          url: 'users/me/v2',
          method: 'GET',
          params: args,
        };
      },
      providesTags: ['userData'],
      async onQueryStarted(request, { dispatch, queryFulfilled }) {
        queryFulfilled.then((response) => {
          dispatch(updateUser(response.data));
        });
      },
    }),
    getUserPermissions: build.query<UserPermissionsResponse[], void>({
      query: () => {
        dispatch(
          userApi.endpoints.trackEvent.initiate({
            eventType: TrackEventType.LOG_IN,
          })
        );
        return {
          url: 'workspaces/roles/details',
          method: 'GET',
        };
      },
    }),
    updateFCMToken: build.mutation<FCMTokenResponse, FCMTokenRequest>({
      query: (FCMTokenRequest) => {
        return {
          url: `/hub/communication-v2/api/fcm`,
          body: FCMTokenRequest,
          method: 'POST',
        };
      },
    }),
    loginOnBehalf: build.mutation<User, loginOnBehalfRequest>({
      query: (loginOnBehalfRequest) => {
        return {
          url: 'user/generateTokenonBehalf',
          body: loginOnBehalfRequest,
          method: 'POST',
        };
      },
      async onQueryStarted(request, { dispatch, queryFulfilled }) {
        queryFulfilled.then((response) => {
          dispatch(storeSupportUserTokens(request.workspaceId));
          dispatch(updateToken(response.data.token));
          dispatch(updateRefreshToken(response.data.refreshToken));
          dispatch(updateInviteUserType(response.data.inviteUserType));
          // dispatch(updateLoggedInOnBehalf(true));
        });
      },
    }),
    updateUser: build.mutation<any, any>({
      query(arg) {
        return {
          url: 'users',
          method: 'PUT',
          body: arg,
        };
      },
      invalidatesTags: ['userData'],
    }),
    regenerateToken: build.mutation<
      UserTokens,
      { refreshToken: string; token: string }
    >({
      query(arg) {
        return {
          url: 'regenerate/token',
          body: arg,
          method: 'POST',
        };
      },
      async onQueryStarted(request, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then((response) => {
            response?.data?.workspaceRoles &&
              dispatch(updateUserRoles(response?.data?.workspaceRoles));
          })
          .catch((x) => {
            console.error(x);
          });
      },
    }),
    userSubscription: build.query<
      UserSubscriptionResponse[],
      UserSubscriptionRequest
    >({
      query(arg) {
        return {
          url: `app/list/${arg.workspaceId}`,
          method: 'GET',
        };
      },
    }),
    subscribeApp: build.mutation<SubscriptionResponse, SubscriptionRequest>({
      query(arg) {
        return {
          url: `apps/subscribe/${arg.workspaceId}`,
          method: 'POST',
          body: arg,
        };
      },
    }),

    logout: build.mutation<any, void>({
      query() {
        dispatch(
          userApi.endpoints.trackEvent.initiate({
            eventType: TrackEventType.LOG_OUT,
          })
        );
        return { url: 'logout', method: 'PUT' };
      },
      // async onQueryStarted(request, { dispatch, queryFulfilled }) {
      //   queryFulfilled.then((response) => {
      //     dispatch(
      //       userApi.endpoints.trackEvent.initiate({
      //         eventType: TrackEventType.LOG_OUT,
      //       })
      //     );
      //   });
      // },
    }),

    getWorkspaceAuthDetails: build.query<
      WorkspaceAutResponse,
      WorkspaceAutRequest
    >({
      query(arg) {
        return {
          url: `admin/workspaceAuthentication/details`,
          method: 'GET',
          params: arg,
        };
      },
    }),

    generateWorkspaceAuth: build.query<any, WorkspaceAutRequest>({
      query(arg) {
        return {
          url: `admin/authkey/generate`,
          method: 'GET',
          params: arg,
        };
      },
    }),
    trackEvent: build.mutation<void, TrackEventRequest>({
      query(arg) {
        if (arg.eventType === TrackEventType.LOG_IN) {
          dispatch(updateLogInTracked(true));
        }
        return {
          url: `track`,
          method: 'POST',
          body: arg,
          keepalive: true,
        };
      },
    }),
  }),
  overrideExisting: true,
});

export const {
  useSendOTPMutation,
  useResendOTPMutation,
  useVerifyOTPMutation,
  useAddMfaChannelMutation,
  useVerifyOTPPrincipalInviteMutation,
  useUpdateFCMTokenMutation,
  useLazyGetUserQuery,
  useLazyGetUserV2Query,
  useGetUserQuery,
  useLoginOnBehalfMutation,
  useUpdateUserMutation,
  useRegenerateTokenMutation,
  useLogoutMutation,
  useLazyUserSubscriptionQuery,
  useLazyGetUserPermissionsQuery,
  useSubscribeAppMutation,
  useGetMappedWorkspacesForSupportUserMutation,
  useLazyGetWorkspaceAuthDetailsQuery,
  useLazyGenerateWorkspaceAuthQuery,
  useTrackEventMutation,
} = userApi;
