import { ColumnConfig, CommsDetailsTeamMembersColumnKey } from '../../model';

export const commsDetailsTeamMembersConfig: Record<
  CommsDetailsTeamMembersColumnKey,
  ColumnConfig
> = {
  teamMemberName: {
    name: 'Team Members',
    field: 'avatarNameStatus',
  },
  type: { name: 'Type' },
  city: { name: 'City' },
  state: { name: 'State' },
  role: { name: 'Role In Channel', isComponent: true },
  roleInThread: {
    name: 'Assign',
    isComponent: true,
  },
  // mobile: { name: 'Mobile' },
  // status: { name: 'Status' },
  action: { name: '' },
};
