import React from 'react';
import './catalog-screen.scss';

import {
  CatalogHeader,
  CatalogList,
  CatalogRecentPurchase,
  CatalogRecentSearch,
  Categories,
} from '@zonofi/zono-money-whatsapp-stack';
import { useCatalogScreen } from './hooks/useCatalogScreen';
import { LoadingSpinner, SVGS } from '@zonofi/zono-money-design-system';
import { useLocation } from 'react-router-dom';
import { isUndefined } from 'lodash';
import { FallBackScreen } from '@zonofi/zono-money-whatsapp-stack';
import { useScroll } from './hooks/useCatalogScreenScrollEffect';

export const CatalogScreen: React.FC = () => {
  const location = useLocation();

  const isCatalogue = location?.pathname?.includes('catalogue');
  const isPriceList = location?.pathname?.includes('pricelist');
  const isCampaign = location?.pathname?.includes('campaign');
  const {
    showSearch,
    setShowSearch,
    searchKey,
    setSearchKey,
    getName,
    categories,
    setCategories,
    selectedCategories,
    setSelectedCategories,
    onClickCategory,
    productsList,
    setProductsList,
    onScroll,
    pageNo,
    error,
    lastItemRef,
    campaignId,
    getCampaignDetailsResponse,
    addToCartAPIResponse,
    editOrderResponse,
    listProductsAPIResponse,
    isCatalogueLoading,
    pricelistDetailsAPIResponse,
    showToastError,
    setShowToastError,
    orderSummary,
    onSuccess,
    onIncrement,
    onDecrement,
    onAddToCart,
    onDelete,
    onOpenMediaSearch,
    noProductsFound,
    fromSource,
    backToCart,
    onChangeQuantity,
    showImage,
    showPrice,
    cartAPIResponse,
    listView,
    toggleCatalogView,
    isCatalogueViewToggleDisabled,
    isProductOrCampaignLoading,
    catalogueView,
  } = useCatalogScreen();

  const { showCategory } = useScroll();

  return (
    <div className="catalog-screen-v2">
      <div className="catalog-screen-v2-header-category-container">
        <CatalogHeader
          showSearch={showSearch}
          setShowSearch={setShowSearch}
          searchKey={searchKey}
          setSearchKey={setSearchKey}
          getName={getName}
          isCatalogue={isCatalogue}
          isPriceList={isPriceList}
          getCampaignDetailsResponse={getCampaignDetailsResponse}
          onSuccess={onSuccess}
          onOpenMediaSearch={onOpenMediaSearch}
          fromSource={fromSource}
          backToCart={backToCart}
          cartQuantity={orderSummary.skuCount}
          handleView={toggleCatalogView}
          listView={listView}
          isCatalogueViewToggleDisabled={isCatalogueViewToggleDisabled}
          // headerRef={headerRef}
          showCategory={showCategory}
        />
        {searchKey?.length <= 0 && categories.length > 0 && (
          <div className={showCategory ? `category-container` : `category-container-not-visible`}>
            <Categories
              categories={categories}
              selectedCategories={selectedCategories}
              setCategories={setCategories}
              setSelectedCategories={setSelectedCategories}
              onClickCategory={onClickCategory}
              showCategory={showCategory}
              showSearch={showSearch}
            />
          </div>
        )}
      </div>
      {error?.show && (
        <div className="catalog-screen-v2-error-section font-primary">
          <h2 className=" font-primary">{error?.code}</h2>
          <span className="font-primary-semibold">{error?.message}</span>
        </div>
      )}
      {/* {!isCatalogueLoading && !error?.show && productsList?.length === 0 && (
        // <span>No Result found</span>
        <FallBackScreen
          image={SVGS.EmptyCart}
          message="Oops! No results found."
          description="Try refining your search or explore other categories."
        />
      )} */}
      {isCatalogueLoading ? (
        <div className="catalog-screen-v2-loading">
          <LoadingSpinner />
        </div>
      ) : productsList?.length !== 0 ? (
        <>
          <div
            className={`catalog-list${listView ? '-grid' : ''}`}
            onTouchMove={onScroll}
          >
            <CatalogList
              catalogueView={catalogueView}
              products={productsList}
              onIncrement={onIncrement}
              onDecrement={onDecrement}
              onAddToCart={onAddToCart}
              onDelete={onDelete}
              onChangeQuantity={onChangeQuantity}
              showImage={showImage}
              showPrice={showPrice}
              listView={listView}
            />
            <div ref={lastItemRef} className="lastItemRef" />
          </div>
          {listProductsAPIResponse?.isLoading && pageNo !== 1 && (
            <div className="catalog-screen-v2-list-loading font-primary">
              <LoadingSpinner size={'small'} />
            </div>
          )}
        </>
      ) : error?.show ? null : (
        <FallBackScreen
          image={SVGS.EmptySearch}
          message="Oops! No results found."
          description="Try refining your search or explore other categories."
        />
      )}
      {/* main section end  */}
      {/* footer section start */}
      {!cartAPIResponse?.isLoading &&
        cartAPIResponse?.isSuccess &&
        !isCatalogueLoading &&
        !isUndefined(orderSummary?.skuCount) &&
        orderSummary?.skuCount !== 0 &&
        !fromSource &&
        searchKey?.length === 0 && (
          <div className="catalog-screen-v2-footer-container font-primary-semibold">
            <div className="catalog-screen-v2-footer-popup">
              <div className="catalog-screen-v2-footer-popup-left">
                <span>{`${orderSummary?.skuCount} Items - ${orderSummary?.totalQuantity} Qty`}</span>
              </div>
              <div
                className="catalog-screen-v2-footer-popup-right"
                onClick={onSuccess}
              >
                <span>Go To Cart</span>
                <img
                  src={SVGS.LeftArrowWhite}
                  className="catalog-screen-v2-footer-popup-right-arrow"
                  alt="go-to-cart-arrow"
                />
              </div>
            </div>
          </div>
        )}
    </div>
  );
};
