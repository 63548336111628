import { zonoApi } from '../api';
import {
  getProductDetailsRequest,
  getProductDetailsResponse,
  ParseImageToProductsRequest,
  ParseImageToProductsResponse,
  ProductListResponse,
  ProductsRequestBody,
  ProductsRequestParams,
} from './product-model';

export const ProductApi = zonoApi.injectEndpoints({
  endpoints: (builder) => ({
    getProducts: builder.mutation<
      ProductListResponse,
      {
        params: ProductsRequestParams;
        workspaceId: string;
        body: ProductsRequestBody;
      }
    >({
      query: (args) => ({
        url: `hub/commerce-v2/products/search/customer/${args.workspaceId}?sellerWorkspaceId=${args.workspaceId}`,
        method: 'POST',
        body: args.body,
        params: args.params,
      }),
    }),
    parseFile: builder.mutation<
      string,
      { sellerWorkspaceId: string; body: FormData }
    >({
      query(arg) {
        const { sellerWorkspaceId, body } = arg;
        return {
          url: `hub/bot/api/v1/parse-file`,
          method: 'POST',
          params: { sellerWorkspaceId },
          body: body,
        };
      },
      transformResponse: (response: { parsedText: string }) => {
        return response.parsedText;
      },
    }),
    parseImageToProducts: builder.mutation<
      ParseImageToProductsResponse,
      ParseImageToProductsRequest
    >({
      query(arg) {
        return {
          url: `hub/bot/api/v1/parse-products/${arg?.workspaceId}`,
          method: 'POST',
          params: {
            sellerWorkspaceId: arg?.sellerWorkspaceId,
            random: Math.random(),
          },
          body: arg?.body,
        };
      },
    }),
    getProductDetails: builder.mutation<
      getProductDetailsResponse,
      { sellerWorkspaceId: string; customerId: string; body: getProductDetailsRequest }
    >({
      query(arg) {
        return {
          url: `hub/commerce-v2/products/search/${arg?.sellerWorkspaceId}`,
          method: 'POST',
          params: {
            sellerWorkspaceId: arg?.sellerWorkspaceId,
            customerId: arg?.customerId,
            pageNo: 1,
            pageSize: 10,
          },
          body: arg?.body,
        };
      },
    }),
  }),
});

export const {
  useGetProductsMutation,
  useParseFileMutation,
  useParseImageToProductsMutation,
  useGetProductDetailsMutation,
} = ProductApi;
