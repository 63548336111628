import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CatalogueView, ConfigModule } from './model';
import { configApi } from './api';
import { RootState } from '../root';

const CONFIQ_FEATURE_KEY = 'ConfigSettings';

export interface ConfigState {
  catologueView: CatalogueView;
}

const initialState: ConfigState = {
  catologueView: CatalogueView.ListView,
};

export const configSlice = createSlice({
  name: CONFIQ_FEATURE_KEY,
  initialState,
  reducers: {
    updateCatalogConfig(state, action: PayloadAction<CatalogueView>) {
      state.catologueView = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      configApi.endpoints.getConfigDetails.matchFulfilled,
      (state, action) => {
        if (action.meta.arg.originalArgs?.module === ConfigModule.CATALOGUE) {
          const catalogueView =
            action?.payload?.config?.catalogueView ?? CatalogueView.ListView;
          const updatedAction = {
            ...action,
            payload: catalogueView,
          };
          configSlice.caseReducers.updateCatalogConfig(state, updatedAction);
        }
      }
    );
  },
});

export const { updateCatalogConfig } = configSlice.actions;

export const getConfigState = (rootState: RootState): ConfigState =>
  rootState[CONFIQ_FEATURE_KEY];

export const getCatalogConfig = createSelector(
  getConfigState,
  (state) => state.catologueView
);

export const catalogueReducer = configSlice.reducer;
