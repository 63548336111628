import { ColumnConfig, CfaPaymentsColumnKey } from '../../model';

export const cfaPaymentsConfig: Record<CfaPaymentsColumnKey, ColumnConfig> = {
  paymentId: {
    name: 'Payment Id',
    field: 'textWithImage',
    isHyperlink: true,
  },
  paymentDate: {
    name: 'Payment Date',
  },
  customerName: {
    name: 'Customer Name',
    field: 'avatarNameStatus',
  },
  typeOfPayment: {
    name: 'Type Of Payment',
  },
  amount: {
    name: 'Amount',
    sortField: 'amount',
  },
  status: {
    name: 'Status',
    isComponent: true,
  },
};
