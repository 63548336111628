import { ThreadCustomerKey, DrawerConfigValues } from '../model';

export const ThreadNewCustomerConfig: Record<ThreadCustomerKey,DrawerConfigValues> = {
  details: {
    tabName: '',
    sections: [
      {
        sectionName: '',
        blocks: [
          {
            inputs: [
              {
                inputType: 'textInput',
                name: 'firmName',
                label: 'Firm Name',
                required: true,
                placeholder: 'Enter Firm Name',
              },
            ],
          },
          {
            inputs: [
              {
                inputType: 'textInput',
                name: 'customerName',
                label: 'Customer Name',
                required: true,
                placeholder: 'Enter Customer Name',
              },
            ],
          },
          {
            inputs: [
              {
                inputType: 'textInput',
                name: 'mobile',
                label: 'Mobile Number',
                required: true,
                placeholder: 'Enter number',
                prefixType: 'mobile',
                inputValueType: 'mobileNumber',
              },
            ],
          },
          {
            inputs: [
              {
                inputType: 'textInput',
                name: 'customerCode',
                label: 'Customer Code',
                required: true,
                placeholder: 'Enter Customer Code',
              },
            ],
          },
        ],
      },
    ],
  },
};
