import { Cfa } from '../products';

interface DropdownInputProps {
  value: string;
  label: string;
}

export interface CustomerFormDetails {
  name: string;
  code: string;
  mobile: string;
  mobile2: string;
  email: string;
  pin: string;
  state: {
    label: string;
    value: string;
  };
  city: string;
  gst: string;
  cst: string;
  dlNumber: string;
  dlExpiry: string;
  upDownCountry: string;
  id?: string;
  inviteId?: string;
  country?: string;

  firmName: string;
  creditLimit: string;
  creditLimitPeriod: string;
  address1: string;
  address2: string;
  shippingAddress1: string;
  shippingAddress2: string;
  shippingPin: string;
  shippingCity: string;
  shippingState: {
    label: string;
    value: string;
  };
  shippingCountry: string;
  cfa: CustomerSearchCFA[];
  division: DropdownInputProps[];
  shippingSameAsPhysical: boolean;
}

export interface InviteIds {
  inviteId?: string;
  profilePic?: string;
  companyName?: string;
}
export interface RemindersFrequency {
  weekDay?: string;
  monthDay?: number;
  scheduleType?: string;
}

export interface ReminderPayload {
  isSMS?: boolean;
  isEmail?: boolean;
  isWhatsapp?: boolean;
  scheduledTime?: string;
  triggerAmount?: number;
  inviteIds?: InviteIds[];
  workspaceId?: string;
  reminderFrequency?: RemindersFrequency[];
}
export type CreateCustomerReminderRequest = ReminderPayload;

export interface CreateCustomerReminderResponse {
  reminderRecords?: ReminderRecord[];
  responseStatus?: string;
  statusCode?: number;
}

export interface CustomerListResponse {
  customers?: CustomerData[];
  totalCount: number | null;
  startRecord: number | null;
  endRecord: number | null;
}

interface BankAccount {
  id: string;
  createdDate: string;
  updatedDate: string;
  workspaceId: string;
  accountNumber: string;
  accountHolderName: string;
  IFSCCode: string;
  bankName: string;
  bankCity: string;
  bankStateCode: string;
  bankCountryCode: string;
  utr: string;
  branch: string;
  micr: string;
  accessType: string;
  upiId: string;
  isUsedForSettlement: boolean;
  isUsedForCashBack: boolean;
  status: string;
}

export interface Profile {
  id: string;
}

interface LastPayment {
  id: string;
  createdDate: Date;
  updatedDate: Date;
  sellerWorkspaceId: string;
  buyerWorkspaceId: string;
  inviteId: string;
  paymentDate: Date;
  amountPaid: number;
  allocatedAmount: number;
  currencyCode: string;
  status: string;
  paymentType: string;
  paymentMode: string;
  paymentProvider?: any;
  proofOfPayment?: any;
  approvedBy?: any;
  createdBy: string;
  paymentRefCode: string;
  instrumentNumber?: any;
  instrumentDate?: any;
  bankName?: any;
  bankAccountNumber?: any;
  bankReference?: any;
  channel?: any;
  cardNumber?: any;
  cardNetwork?: any;
  cardType?: any;
  bankCode?: any;
  upiId?: any;
  narration: string;
  source: string;
  teamMemberId: string;
}
interface DueInvoice {
  id: string;
  createdDate: Date;
  updatedDate: Date;
  referenceInvoiceNumber: string;
  supTyp: string;
  invoiceDate: Date;
  dueDate: Date;
  invoiceAmount: number;
  paidAmount: number;
  irn: string;
  customerName: string;
  docType: string;
  gstin?: any;
  docNumber: string;
  status: string;
  invoiceStatus: string;
  workspaceId: string;
  inviteId: string;
  linkedWorkspacesId: string;
}

export interface CustomerPartyAccountBook {
  overdueAmount: number;
  lastPayment: LastPayment;
  dueInvoice: DueInvoice;
  totalCredit?: number;
  totalDebit?: number;
  openingBalance?: number;
  outStandingBalance?: number;
}

interface Ledger {
  id: string;
  transactionDate: string;
  createdDate: string;
  updatedDate: string;
  sellerWorkspaceId: string;
  buyerWorkspaceId: string;
  inviteId: string;
  docNumber: string;
  narration: string;
  creditAmount: number;
  debitAmount: number;
  balance: number;
  transactionType: string;
}

export interface LedgerListResponse {
  partyAccountBook: Ledger[];
  totalPartyAccountBook: number;
  startRecord: number;
  endRecord: number;
  statusCode: number;
  responseStatus: string;
}

export interface Visits {
  id: string;
  createdDate: string;
  updatedDate: string;
  userId: string;
  customerId: string;
  lat: string;
  lng: string;
  visitedDateTime?: string;
}

export interface CustomerInvoicesEntity {
  id: string;
  createdDate: string;
  updatedDate: string;
  referenceInvoiceNumber: string;
  supTyp: string;
  invoiceDate: string;
  invoiceAmount: number;
  irn: string;
  customerName: string;
  customerPhoneNumber?: string;
  docType: string;
  docNumber: string;
  invoiceStatus: string;
  workspaceId: string;
  inviteId: string;
  linkedWorkspacesId: string;
  eInvoice?: null[] | null;
  payments: any[];
  isSelected?: boolean;
  paidAmount?: string;
  dueDate: string;
  pendingAmount?: string;
  amountCleared: number;
  status: string;
  outStandingAmount: 0;
}
export interface CustomerPaymentInvoices {
  invoices?: CustomerInvoicesEntity[] | null;
  totalInvoices: number;
  startRecord: number;
  endRecord: number;
  pageNo: number;
}

interface AccountDetails {
  virtualVpaId: string;
  vAccountId: string;
  inviteId: string;
  upi: string;
  accountNumber: string;
  ifsc: string;
  qrCode: string;
  statusCode: number;
  responseStatus: string;
}

export interface MessageHistory {
  id: string;
  createdDate: string;
  updatedDate: string;
  eventType?: string;
  inviteId: string;
  workspaceId: string;
  sentMessageCount: number;
  deleveredMessageCount: number;
  failedMessageCount: number;
  lastFailedMessageTime?: string;
  lastSuccessMessageTime: string;
  lastMessageBody: string;
}

export interface CustomerPaymentErrorDetails {
  orderAmount?: string;
  orderId?: string;
  paymentMode?: string;
  referenceId?: string;
  signature?: string;
  txMsg?: string;
  txStatus?: string;
  txTime?: string;
}

export interface CustomerPaymentsTransactionsEntity {
  id: string;
  transactionId: number;
  customerName: string;
  transactionDate: string;
  amountPaid: number;
  paymentType: string;
  paymentMode: string;
  paymentRefCode: string;
  instrumentNumber: string;
  instrumentDate: string;
  bankName: string;
  narration?: string;
  inviteId: string;
  workspaceId: string;
  status: string;
  createdDate: string;
  updatedDate: string;
  invoices?: string[];
  paymentInvoiceMap?: {
    invoice: CustomerInvoicesEntity;
    amountCleared: number;
  }[];
  teamMemberId: string;
  channel?: string;
  cardNumber?: string;
  cardNetwork?: string;
  cardType?: string;
  upiId?: string;
  bankAccountNumber?: string;
  bankCode?: string;
  paymentId?: string;
  cardCountry: string;
  collectedBy?: string;
  paymentDate?: string;
  errorDetails?: {
    responseCode: string;
    paymentResponseMsg: string;
    paymentResponse: CustomerPaymentErrorDetails;
  };
  bankReference?: string;
}

export interface PaymentTransactions {
  payments?: CustomerPaymentsTransactionsEntity[] | null;
  total: number;
  startRecord: number;
  endRecord: number;
  pageNo?: number;
}

export interface CustomerGroups {
  createdDate: string;
  groupRefCode: null | string;
  groupsHierarchyDimensionsId: string;
  heritage: null | string;
  hierarchyType: string;
  id: string;
  lineage: null | string;
  name: string;
  parentGroupId: string;
  updatedBy: null | string;
  updatedDate: string;
  workspaceId: string;
}

export enum CustomerActions {
  AddCustomer = 'Add Customer',
  BulkUpload = 'Bulk Upload',
  BulkInvite = 'Bulk Invite',
  DownloadCustomers = 'Download Customers',
  AssignSegments = 'Assign Segments',
  RemoveSegments = 'Remove Segments',
}

export interface CustomerData {
  invitedWorkspaceId?: string;
  teamMemberId?: string;
  id: string;
  phone?: string;
  senderUserId: string;
  senderWorkspaceId: string;
  status: string;
  createdDate: string;
  updatedDate: string;
  gstin?: string;
  businessPAN?: string;
  distributorCode: string;
  postalCode?: string;
  physicalAddress?: string;
  companyName: string;
  stateId?: string;
  countryId?: string;
  mobile?: string;
  cityName?: string;
  name?: string;
  email?: string;
  spaceName?: string;
  address: string;
  profilePic: string;
  wgstin?: string;
  workspaceMembersCount: number;
  bankAccount: BankAccount;
  profile: Profile;
  sellerWorkspaceId: string;
  inviteId: string;
  city: string;
  totalDebit: number;
  totalCredit: number;
  outStandingBalance: number;
  openingBalance: number;
  overdueAmount: number;
  partyAccountBook: CustomerPartyAccountBook;
  lastPayment: LastPayment;
  dueInvoice: DueInvoice;
  Ledger: LedgerListResponse;
  dlNumber: string | null;
  visits: Visits[];
  accountDetails: AccountDetails;
  Invoices: CustomerPaymentInvoices;
  Payments: PaymentTransactions;
  messageHistory?: MessageHistory;
  customerCode?: string;
  lastPaymentDate?: Date;
  dueDate?: Date;
  isSelected?: boolean;
  customerGroups?: CustomerGroups[];
  divisionCount?: number;
  isActive?: number;
  cartCount?: number;
  closingBalance?: number;
  orderCount: OrderCount;
  stateName?: string;
  isLoading?: boolean;
  teamCustomers?: AssignedTeamMember[];
  shippingAddress: string;
  customer_segment?: CustomerSegment[];
  routes?: Route[];
}

export interface Route {
  channelId: string;
  channelName: string;
  folderType: string;
}
export interface CustomerSegment {
  channelId: string;
  channelName: string;
  folderType: string;
}
export interface AssignedTeamMember {
  id: string;
  name: string;
  customerId: string;
  mobile: string;
  userType: string;
  roles: string[];
}

export interface AssignRouterCustomer {
  id: string; 
  invitedWorkspaceId?: string;
  teamMemberId?: string;
  phone?: string;
  senderUserId?: string;
  senderWorkspaceId?: string;
  status?: string;
  createdDate?: string;
  updatedDate?: string;
  gstin?: string;
  businessPAN?: string;
  distributorCode?: string;
  postalCode?: string;
  physicalAddress?: string;
  companyName?: string;
  stateId?: string;
  countryId?: string;
  mobile?: string;
  cityName?: string;
  name?: string;
  email?: string;
  spaceName?: string;
  address?: string;
  profilePic?: string;
  wgstin?: string;
  workspaceMembersCount?: number;
  bankAccount?: BankAccount;
  profile?: Profile;
  sellerWorkspaceId?: string;
  inviteId?: string;
  city?: string;
  totalDebit?: number;
  totalCredit?: number;
  outStandingBalance?: number;
  openingBalance?: number;
  overdueAmount?: number;
  partyAccountBook?: CustomerPartyAccountBook;
  lastPayment?: LastPayment;
  dueInvoice?: DueInvoice;
  Ledger?: LedgerListResponse;
  dlNumber?: string | null;
  visits?: Visits[];
  accountDetails?: AccountDetails;
  Invoices?: CustomerPaymentInvoices;
  Payments?: PaymentTransactions;
  messageHistory?: MessageHistory;
  customerCode?: string;
  lastPaymentDate?: Date;
  dueDate?: Date;
  isSelected?: boolean;
  customerGroups?: CustomerGroups[];
  divisionCount?: number;
  isActive?: number;
  cartCount?: number;
  closingBalance?: number;
  orderCount?: OrderCount;
  stateName?: string;
  isLoading?: boolean;
  teamCustomers?: AssignedTeamMember[];
  shippingAddress?: string;
  customer_segment?: CustomerSegment[];
  routes?: Route[];
}

interface OrderCount {
  Approved: string;
  SubmittedByCustomer: string;
  Confirmed: string;
  AddingItems: string;
  WaitingForCNF: string;
  Created: number;
  [key: string]: string | number; // To allow any other string keys with string or number values
}

export type OutStandingCustomers = Array<CustomerData>;

export type Customers = { [id: string]: CustomerData };

export interface CustomerPartyAccount {
  customers: CustomerData[];
  totalRecords: number;
  startRecord: number;
  endRecord: number;
  statusCode: number;
  responseStatus: string;
}

export interface CustomerListRequest {
  includeWorkspaceMembers?: number;
  includeCustomerGroupAssignments?: number;
  includeTeamCustomersMapping?: boolean;
  pageNo?: number;
  pageSize?: number;
  workspaceId: string;
  searchKey?: string;
  status?: boolean;
  isActive?: boolean;
  dispatchFilter?: boolean;
  eitherOr?: boolean;
}
export interface CustomerSearchListRequest {
  workspaceId: string;
  searchKey?: string;
  pageNo?: number;
  pageSize?: number;
  customerGroupIds?: string[];
  customerCode?: string;
  routes?: string[];
  customer_segment?: string[];
  inviteId?: any[];
  pinCodes?: string[];
  includeCustomerGroupAssignments?: number;
  includeWorkspaceMembers?: number;
  divisionIds?: string[];
  cfaIds?: string[];
  onboardingStatus?: string;
}

export interface LedgerDate {
  startDate: string;
  endDate: string;
}

export interface CustomerPartyAccountRequest {
  sellerWorkspaceId: string;
  inviteId: string;
  startDate: string;
  endDate: string;
  includeLastPayment?: boolean;
  pageSize?: number;
  pageNo?: number;
  includeDueInvoice?: boolean;
  includeOpeningBalance?: boolean;
}

export type CustomerPartyAccountResponse = CustomerPartyAccount;

export interface SendSMSToCustomersRequest {
  workspaceId: string;
  inviteIds: string[];
  communicationType: string;
}

export interface SendSMSToCustomersResponse {
  responseStatus?: string;
  statusCode?: number;
  message?: string;
}

export interface RemoveCustomerReminderRequest {
  workspaceId: string;
  inviteId: string[];
}

export interface RemoveCustomerReminderResponse {
  statusCode?: number;
  responseStatus?: string;
}

interface ReminderJobHistory {
  id: string;
  createdDate: string;
  updatedDate: string;
  reminderId: string;
  reminderFrequencyId: string;
  isSentEmail: boolean;
  isSentWhatsapp: boolean;
  isSentSMS: boolean;
  lastInitiatedDate: string;
  lastSentEmailDate: string;
  lastSentSMSDate: string;
  lastSentWhatsappDate: string;
  status: string;
}
interface Frequency {
  id: string;
  createdDate: string;
  updatedDate: string;
  weekDay: string;
  monthDay: number;
  scheduledDate: string;
  scheduleType: string;
  reminderId: string;
  reminderJobHistory: ReminderJobHistory;
}
export interface ReminderRecord {
  profilePic?: string;
  id: string;
  createdDate: string;
  updatedDate: string;
  isSMS: boolean;
  isEmail: boolean;
  isIVR?: boolean;
  isWhatsapp: boolean;
  inviteId: string;
  workspaceId: string;
  scheduledTime: string;
  triggerAmount: number;
  reminderFrequency: Frequency[];
  phone: string;
  email: string;
  customerName: string;
  triggerAmountOperator?: string;
  triggerAfter?: number;
  isCloudNotification?: boolean;
  isSelected?: boolean;
}
export interface RemindersRequest {
  workspaceId: string;
}

export type RemindersResponse = CreateCustomerReminderResponse;

export interface CustomerSMSTemplateRequest {
  workspaceId: string;
  inviteId: string[];
  communicationType: string;
}

export interface CustomerSMSTemplateResponse {
  statusCode?: number;
  responseStatus?: string;
  body?: string;
}

export interface LedgerSearchFilterInputs {
  startDate?: string;
  endDate?: string;
  type?: string;
  searchKey?: string;
  filter?: string;
}

export interface CustomerAccountDetails {
  virtualVpaId: string;
  vAccountId: string;
  inviteId: string;
  upi: string;
  accountNumber: string;
  ifsc: string;
  qrCode: string;
  statusCode: number;
  responseStatus: string;
}

export type AccountDetailsRes = CustomerAccountDetails;
export interface AccountDetailsReq {
  inviteId: string;
}

export type InvoicesTableListResponse = CustomerPaymentInvoices;

export interface InvoicesTableListRequest {
  endDate: string;
  startDate: string;
  pageNo: number;
  pageSize: number;
  sortBy: string;
  sortDirection: string;
  workspaceId: string;
  inviteId: string;
  docNumber: string;
}

export interface UpdateMobileNumberRequest {
  inviteId: string;
  senderWorkspaceId: string;
  phone: string;
  customerDetails?: any;
}

export interface UpdateMobileNumberResponse {
  statusCode?: string;
  responseStatus?: string;
}

export interface InvitedCustomerRecord {
  id: string;
  phone: string;
  senderUserId: string;
  senderWorkspaceId: string;
  status: string;
  createdDate: Date;
  updatedDate: Date;
  gstin: string;
  businessPAN: string;
  distributorCode: string;
  postalCode: string;
  physicalAddress: string;
  companyName: string;
  stateId: string;
  countryId: string;
  mobile: null | string;
  cityName: string;
  name: string;
  email: string;
  spaceName: null;
  address: null;
  profilePic: null;
  wgstin: null;
}
export interface InvitedCustomer {
  customers: InvitedCustomerRecord[];
  totalCount: number;
  startRecord: number;
  endRecord: number;
}

export type CustomerByIdResponse = InvitedCustomer;

export interface CustomerByIdRequest {
  inviteId: string;
  workspaceId: string;
}

export type MessageHistoryRequest = CustomerByIdRequest;

export type MessageHistoryResponse = MessageHistory;

export interface DownloadLedgerRequest {
  sellerWorkspaceId: string;
  inviteId: string;
  startDate: string;
  endDate: string;
  searchKey: string;
  filter: string;
  pageNo: number;
  pageSize: number;
  downloadContentType?: string;
}

export interface CustomerSearchResponse {
  [x: string]: any;
  id: string;
  inviteId: string;
  name: string;
  email: string;
  companyName: string;
  gstin: string;
  businessPAN: string;
  distributorCode: string;
  postalCode: string;
  physicalAddress: { address1: string; address2: string };
  shippingAddress: {
    shippingAddress1: string;
    shippingAddress2: string;
    pincode: string;
    city: string;
    state: string;
    countryCode: string;
  };
  token: string;
  cityId: string;
  districtId: string;
  cityName: string;
  createdDate: Date;
  updatedDate: Date;
  fulfillmentLocationCode: string;
  countryId: string;
  stateId: string;
  invite: Invite;
  telephone: string;
  CSTNumber: string | null;
  dlNumber: string;
  dlExpiry: string;
  customerGroups: CustomerGroups[];
  cfa: CustomerSearchCFA[];
  stateDetails?: {
    id: string;
    createdDate: string | null;
    updatedDate: string;
    name: string;
    stateCode: string;
    isoCode: string | null;
    gstStateCode: string;
  };
  grossCreditLimit: number;
  creditLimitPeriod: number;
}

export interface CustomerSearchCFA {
  id: string;
  salesOrg: string;
  cfaCode: string;
  name: string;
  divisions: CustomerSearchDivision[];
}

export interface CustomerSearchDivision {
  id: string;
  name: string;
  code: string;
  isActive: boolean;
}

export interface Invite {
  id: string;
  phone: string;
  phone2: string;
  inviteType: string;
  userType: string;
  senderUserId: string;
  invitedUserId: string;
  senderWorkspaceId: string;
  invitedWorkspaceId: string;
  status: string;
  createdDate: Date;
  updatedDate: Date;
  isActive?: boolean;
}

export interface CustomerSummaryResponse {
  workspaceId: string;
  outstandingAmt: number;
  channelId: number;
  totalOrders: number;
  pendingDeliveryOrders: number;
  creationDate: string;
  code: string;
  profilePic?: string;
  distributerCode?: string;
}

export interface CustomerSummaryRequest {
  customerWorkspaceId: string;
  principalWorkspaceId: string;
}

export interface InActiveCustomerResponse {
  message: string;
  success: boolean;
  statusCode: number;
}
export interface InActiveCustomerRequest {
  workspaceId: string;
  isActive: boolean;
}

export interface CustomerDetailsData {
  customerCode: string;
  phoneNumber: string;
  phoneNumber2: string;
  emailId: string;
  gstNumber: string;
  cstNumber: string;
  dlNumber: string;
  dlExpiryDate: string;
  group: string;
  address: string;
  pincode: string;
  cityName: string;
  name: string;
  state: string;
  customerName: string;
  isActive: boolean;
  status: string;
  telephone: string;
  creditLimitPeriod: number;
}

export interface CreateCustomerRequest {
  senderWorkspaceId?: string;
  phone: string;
  phone2?: string;
  companyCode?: string;

  isActive?: string;
  customerDetails: CreateCustomerDetails;
  cfa?: CreateCustomerCfa[];
  location?: number[];
}

export interface CreateCusomerBlukRequest {
  sellerWorkspaceId?: string;
  customers: [CreateCustomerRequest];
}

export interface CreateCustomerDetails {
  telephone: string;
  email: string;
  inviteId?: string;
  CSTNumber: string;
  gstin: string;
  dlNumber?: string;
  dlExpiry?: string;
  distributorChannel?: string;
  grossCreditLimit?: number;
  creditLimitPeriod?: number;
  shippingAddress?: CreateCustomerShippingAddress;
  name: string;
  companyName: string;
  physicalAddress: CreateCustomerPhysicalAddress;
  postalCode: string;
  stateCode: string;
  countryCode: string;
  cityName: string;
  customerCode?: string;
  distributorCode?: string;
  id?: string;
}

export interface CreateCustomerShippingAddress {
  shippingAddress1: string;
  shippingAddress2: string;
  city: string;
  state: string;
  pincode: string;
  countryCode: string;
}

export interface CreateCustomerPhysicalAddress {
  address1: string;
  address2?: string;
}

export interface CreateCustomerCfa {
  cfaCode?: string;
  salesOrg?: string;
  divisionCodes?: CreateCustomerDivisionCode[];
}

export interface CreateCustomerDivisionCode {
  code?: string;
  isActive?: boolean;
}

export interface AssignedMemberListRequest {
  workspaceId: string;
  customerId?: string;
  teamMemberId?: string;
}

export interface AssignTeamMemberOrCustomerRequest {
  workspaceId: string;
  customerIds: string[];
  teamMemberIds: string[];
}

export interface DeleteTeamMemberOrCustomerRequest {
  customerIds: string[];
  teamMemberIds: string[];
  workspaceId: string;
}

export interface AllCustomersAssignedResponse {
  data?: CustomerAssignedDetail[] | null;
  status: string;
  statusCode: number;
  filters: {
    cfas: Cfa[];
  };
}

export interface CustomerAssignedDetail {
  id: string;
  name: string;
  DueAmount: number;
  DueDate: string | null;
  profile: string | null;
  physicalAddress: PhysicalAddress;
  cityName: string;
  isActive: boolean;
  latestOrderDate: string | null;
  status: string;
  phone: string;
  companyName: string;
}

export interface CheckinCustomer {
  id: string;
  name: string;
  physicalAddress: PhysicalAddress;
}

export interface AllCustomersAssignedParams {
  workspaceId: string;
  teamMemberId: string;
  searchKey?: string;
  dispatchFilter?: boolean;
  fulfillmentLocationId?: string[];
}
export interface DownloadCustomerListRequest {
  workspaceId: string;
  body: {
    searchKey?: string;
    includeFacets?: boolean;
    includeDivisions?: boolean;
    includeCfas?: boolean;
    includeCategoryCodesList?: boolean;
    skuCode?: string;
    sortDirection?: string;
    sortBy?: string;
    inventoryFilter?: string;
    stockFilter?: string;
    divisionIds?: any[];
    cfaIds?: any[];
    categoryCodes?: any[];
    statusFilter?: string;
    collectionIds?: any[];
    onboardingStatus?: string;
  };
}

export interface DownloadCustomerListResponse {
  message: string;
}

export interface DownloadedCustomersHistoryRequest {
  workspaceId: string;
}

export interface DownloadedCustomersHistoryResponse {
  Key: string;
  LastModified: string;
  ETag: string;
  Size: number;
  StorageClass: string;
}

export interface DownloadGeneratedCustomerListRequest {
  workspaceId: string;
  key: string;
}

export interface DownloadGeneratedCustomerListResponse {
  userId: string;
  data: {
    signedUrl: {
      signedURL: string;
    };
  };
}

export type FieldErrorStateKeys =
  | 'companyName'
  | 'customerName'
  | 'customerCode'
  | 'companyPhone1'
  | 'gstin'
  | 'address'
  | 'city'
  | 'state'
  | 'dlExpiry';

export type FieldErrorStates = Partial<
  Record<
    FieldErrorStateKeys,
    {
      isShow: boolean;
      msg: string;
    }
  >
>;

export interface DivisionItem {
  code?: string;
  id?: number;
  name?: string;
  isSelected?: any;
  assignedToCustomer?: boolean;
  originalDivision?: boolean;
}

export interface CfaData {
  showDivision?: boolean;
  fulfillmentLocationCode?: string;
  id: number;
  supplierName?: string;
  divisionData?: DivisionItem[];
}

interface PhysicalAddress {
  address1: string;
  address2: string;
}
