import { RootState, store } from '../root';
import { mediaAdapter } from './slice';

export const mediaNativeSelectors = mediaAdapter.getSelectors(
  (state: RootState) => state.media
);

export const getAllMediaItems = () => {
  return mediaNativeSelectors.selectAll(store?.getState());
};
export const getAllMediaItemIds = () => {
  return mediaNativeSelectors.selectIds(store?.getState());
};
export const getMediaItemsById = (ids: string[]) => {
  const mediaIds = ids.map((id) => {
    return mediaNativeSelectors.selectById(store?.getState(), id);
  });
  return mediaIds;
};
export const getMediaItemById = (id: string) => {
  return mediaNativeSelectors.selectById(store?.getState(), id);
};
export const getMediaItemsCount = () => {
  return mediaNativeSelectors.selectTotal(store?.getState());
};
