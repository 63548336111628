import { CustomerDrawerKey, DrawerConfigValues } from '../model';

export const CustomersDrawerConfig: Record<CustomerDrawerKey, DrawerConfigValues> = {
  details: {
    tabName: 'Basic Details',
    sections: [
      {
        sectionName: 'Customer Details',
        blocks: [
          {
            inputs: [
              {
                inputType: 'textInput',
                name: 'firmName',
                label: 'Firm Name',
                required: true,
                placeholder: 'Enter Firm Name',
              },
            ],
          },
          {
            inputs: [
              {
                inputType: 'textInput',
                name: 'name',
                label: 'Customer Name',
                required: true,
                placeholder: 'Enter Customer Name',
              },
            ],
          },
          {
            inputs: [
              {
                inputType: 'textInput',
                name: 'code',
                label: 'Customer Code',
                required: true,
                placeholder: 'Ex: DR394883F',
              },
              {
                inputType: 'textInput',
                name: 'mobile',
                label: 'Mobile Number',
                required: true,
                placeholder: '9999999999',
                prefixType: 'mobile',
                inputValueType: 'mobileNumber',
              },
            ],
          },
          {
            inputs: [
              {
                inputType: 'textInput',
                name: 'mobile2',
                label: 'Mobile Number 2',
                required: false,
                placeholder: '9999999999',
                prefixType: 'mobile',
                inputValueType: 'mobileNumber',
              },
              {
                inputType: 'textInput',
                name: 'email',
                label: 'Email ID',
                required: false,
                placeholder: 'Enter Email Id',
                inputValueType: 'email',
              },
            ],
          },
        ],
      },
      {
        sectionName: 'Licence Details',
        blocks: [
          {
            inputs: [
              {
                inputType: 'textInput',
                name: 'gst',
                label: 'GST Number',
                required: false,
                placeholder: 'Enter GST Number',
              },
              {
                inputType: 'textInput',
                name: 'cst',
                label: 'CST Number',
                required: false,
                placeholder: 'Enter CST Number',
              },
            ],
          },
          {
            inputs: [
              {
                inputType: 'textInput',
                name: 'dlNumber',
                label: 'DL Number',
                required: false,
                placeholder: 'Enter DL Number',
              },
              {
                inputType: 'datePicker',
                name: 'dlExpiry',
                label: 'DL Expiry Date',
                required: false,
                placeholder: 'DD/MM/YYYY',
              },
            ],
          },
          // {
          //   inputs: [
          //     {
          //       inputType: 'dropdownInput',
          //       name: 'upDownCountry',
          //       label: 'Up/Down Country (Optional)',
          //       required: false,
          //       placeholder: 'Select',
          //     },
          //   ],
          // },
        ],
      },
      {
        sectionName: 'Credit Settings (Optional)',
        blocks: [
          {
            inputs: [
              {
                inputType: 'textInput',
                name: 'creditLimit',
                label: 'Gross Credit Limit',
                required: false,
                placeholder: 'Enter Limit',
              },
              {
                inputType: 'textInput',
                name: 'creditLimitPeriod',
                label: 'Credit Days',
                required: false,
                placeholder: '',
              },
            ],
          },
        ],
      },
    ],
  },
  address: {
    tabName: 'Address',
    sections: [
      {
        sectionName: 'Physical Address',
        blocks: [
          {
            inputs: [
              {
                inputType: 'textInput',
                name: 'address1',
                label: 'Address 1',
                required: false,
                placeholder: 'Enter Address',
              },
            ],
          },
          {
            inputs: [
              {
                inputType: 'textInput',
                name: 'address2',
                label: 'Address 2',
                required: false,
                placeholder: 'Enter Address',
              },
            ],
          },
          {
            inputs: [
              {
                inputType: 'textInput',
                name: 'pin',
                label: 'Pin Code',
                required: false,
                inputValueType: 'postalCode',
              },
              {
                inputType: 'textInput',
                name: 'city',
                label: 'City',
                required: false,
                placeholder: 'Enter City Name',
              },
            ],
          },
          {
            inputs: [
              {
                inputType: 'dropdownInput',
                name: 'state',
                label: 'State',
                required: false,
                placeholder: 'Select',
              },
              {
                inputType: 'textInput',
                name: 'country',
                label: 'Country',
                required: false,
                placeholder: 'Select',
              },
            ],
          },
        ],
      },
      {
        sectionName: 'Shipping Address',
        blocks: [
          {
            customBlock: 'addressCheckBox',
          },
          {
            inputs: [
              {
                inputType: 'textInput',
                name: 'shippingAddress1',
                label: 'Address 1',
                required: false,
                placeholder: 'Enter Address',
              },
            ],
          },
          {
            inputs: [
              {
                inputType: 'textInput',
                name: 'shippingAddress2',
                label: 'Address 2',
                required: false,
                placeholder: 'Enter Address',
              },
            ],
          },
          {
            inputs: [
              {
                inputType: 'textInput',
                name: 'shippingPin',
                label: 'Pin Code',
                required: false,
                inputValueType: 'postalCode',
              },
              {
                inputType: 'textInput',
                name: 'shippingCity',
                label: 'City',
                required: false,
                placeholder: 'Enter City Name',
              },
            ],
          },
          {
            inputs: [
              {
                inputType: 'dropdownInput',
                name: 'shippingState',
                label: 'State',
                required: false,
                placeholder: 'Select',
              },
              {
                inputType: 'textInput',
                name: 'shippingCountry',
                label: 'Country',
                required: false,
                placeholder: 'Select',
              },
            ],
          },
        ],
      },
    ],
  },
  link: {
    tabName: 'CFA / Divisions',
    sections: [
      {
        sectionName: '',
        sectionId: 'cfaDivisionLink',
        isCustomSection: true,
      },
    ],
  },
};
