import { getConfigValue } from '@zonofi/common';
import { ThemeContext } from '@zonofi/shared';
import { LoadingSpinner } from '@zonofi/zono-money-design-system';
import { WEBROUTES, dispatch } from '@zonofi/zono-money-helpers';
import {
  CustomerInviteResponse,
  OnGuestUserAuthenticated,
  onSuccess,
} from '@zonofi/zono-money-store';
import axios, { AxiosError } from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

export const InviteLoadingScreen: React.FC = () => {
  const { theme } = useContext(ThemeContext);
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const { inviteId } = params;
  const [searchParams] = useSearchParams();
  const redirectUrl = searchParams.get('redirectUrl');

  const token = searchParams.get('token');
  const orderId = searchParams.get('poFileId') || searchParams.get('orderId');
  const invoiceId = searchParams.get('invoiceId');
  const campaignId = searchParams.get('campaignId');
  const pricelistId = searchParams.get('pricelistId');
  const sellerWorkspaceId = searchParams.get('sellerWorkspaceId');
  const target = searchParams.get('target');
  const docNumbers = [
    ...searchParams.getAll('docNumbers'),
    ...searchParams.getAll('docNumber'),
  ];

  const [error, setError] = useState({
    show: false,
    code: null,
    details: null,
  });

  useEffect(() => {
    console.log('1st console log');
    if (token) {
      dispatch(OnGuestUserAuthenticated(token));
    }
  }, [token]);

  useEffect(() => {
    console.log('2nd consle log');
    if (redirectUrl) {
      getRedirectUrl();
    } else if (inviteId) {
      console.log('before fetching inviteId', inviteId);
      const baseUrl = getConfigValue('API_ENDPOINT');
      console.log('baseUrl: ', baseUrl);
      const url = `${baseUrl}hub/orgs/api/customer/details`;
      console.log('url: ', url);
      // fetchInviteDetails(inviteId);
      // Define the URL

      // Make a GET request using Axios
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            inviteId: inviteId,
            sellerWorkspaceId: sellerWorkspaceId,
            customerId: inviteId,
            includeInactive: false,
            includeCFA: false,
          },
        })
        .then((response) => {
          // Log the response data
          // Store the response data in a variable or wherever you need
          // For example, you can assign it to a variable
          const responseData = response?.data;
          const storeData: CustomerInviteResponse = {
            inviteId: responseData?.inviteId,
            seller: {
              name: responseData?.workspace?.spaceName,
              phone: '',
              workspaceId: sellerWorkspaceId,
            },
            buyer: {
              name: responseData?.companyName,
              phone: responseData?.telephone,
              workspaceId: '',
            },
          };
          if (responseData?.waConfig) {
            storeData.waConfig = {
              ...(responseData?.waConfig || {}),
              wabaId: responseData?.waConfig?.wabaId,
              whatsappPhoneNo: responseData?.waConfig?.whatsappPhoneNo,
            };
          }
          dispatch(onSuccess(storeData));
          if (invoiceId) navigate(`${WEBROUTES.INVOICE_SCREEN}/${invoiceId}`);
          else if (target === 'reorder')
            navigate(`${WEBROUTES.REORDER}/${orderId}`);
          else if (orderId) navigate(`${WEBROUTES.ORDER}/${orderId}`);
          else if (docNumbers.length > 0)
            navigate(
              `${WEBROUTES.REVIEW_INVOICES}?${docNumbers
                .map((docNumber) => `docNumbers=${docNumber}`)
                .join('&')}`
            );
          else if (target === 'kyc') {
            navigate(`${WEBROUTES.GST_SCREEN}`);
          } else if (campaignId)
            navigate(`${WEBROUTES.CAMPAIGN}/${campaignId}`);
          else if (pricelistId)
            navigate(`${WEBROUTES.PRICELIST}/${pricelistId}`);
          else if (target) {
            if (target === 'catalog') {
              navigate(`${WEBROUTES.CATALOGUE}`);
            } else if (target === 'kyc') {
              navigate(`${WEBROUTES.GST_SCREEN}`);
            } else {
              navigate(WEBROUTES.ERROR_SCREEN);
            }
          } else navigate(WEBROUTES.UNAUTHORIZED_SCREEN);

          // Now you can work with the responseData object
          // For example, you can access its properties like responseData.propertyName
        })
        .catch((error: AxiosError) => {
          // Log any errors that occurred during the Axios request
          console.error('Axios Error:', error);
          if (error?.response?.status === 401) {
            navigate(WEBROUTES.ERROR_SCREEN);
          }
          setError({
            show: true,
            code: error?.response?.status,
            details: error?.message,
          });
        });
      console.log('after fetching inviteId', inviteId);
    }
  }, [inviteId]);

  const getRedirectUrl = () => {
    const allParams = decodeURIComponent(location.search);
    console.log('orderId: ', orderId);
    console.log('redirectUrl: ', redirectUrl);

    console.log('allParams: ', allParams);
    const index = allParams.indexOf(redirectUrl);
    console.log('index: ', index);
    let url = allParams.slice(index);
    console.log('url: ', url);
    if (orderId) {
      if (!url?.includes('orderId')) {
        console.log('no order id in redirect url');
        url = url + '&orderId=' + orderId;
        console.log('url: ', url);
      }
    }
    // let reqParam = {};

    // if (orderId) {
    //   reqParam = isNaN(Number(orderId))
    //     ? { orderId: orderId }
    //     : { poFileId: orderId };
    // } else if (invoiceId) {
    //   reqParam = { invoiceId };
    // } else if (docNumbers.length > 0) {
    //   reqParam = { docNumbers };
    // }

    // if (url.indexOf(Object.keys(reqParam)[0]) === -1) {
    //   const urlParams = getQueryParams(reqParam);
    //   url = `${url}&${urlParams}`;
    // }
    console.log('1', url);
    window.location.assign(url);
    console.log('2');
  };

  // useEffect(() => {
  //   console.log('before get redirecut url', redirectUrl);
  //   if (redirectUrl) {
  //     getRedirectUrl();
  //   } else if (inviteDetailsResponse?.isSuccess) {
  //     if (invoiceId) navigate(`${WEBROUTES.INVOICE_SCREEN}/${invoiceId}`);
  //     else if (orderId) navigate(`${WEBROUTES.ORDER}/${orderId}`);
  //     else if (docNumbers.length > 0)
  //       navigate(
  //         `${WEBROUTES.REVIEW_INVOICES}?${docNumbers
  //           .map((docNumber) => `docNumbers=${docNumber}`)
  //           .join('&')}`
  //       );
  //     else navigate(WEBROUTES.ERROR_SCREEN);
  //   }
  // }, [inviteDetailsResponse, invoiceId, orderId, redirectUrl]);
  // console.log('loader rendering');

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
      {error?.show ? (
        <>
          <h2>{error?.code}</h2>
          <span style={{ ...theme.fontConfig.semiBold }}>{error?.details}</span>
        </>
      ) : (
        <LoadingSpinner />
      )}
    </div>
  );
};
