import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CumulativeCampaignDetails } from './model';
import { RootState } from '../root';

export const HOME_KEY = 'homeData';

export interface HomeState {
  campaigns: CumulativeCampaignDetails[];
}

export const initialHomeState: HomeState = {
  campaigns: [],
};

export const homeSlice = createSlice({
  name: HOME_KEY,
  initialState: initialHomeState,
  reducers: {
    updateCampaigns(state, action: PayloadAction<CumulativeCampaignDetails[]>) {
      state.campaigns = action.payload;
    },
  },
});

export const homeReducer = homeSlice.reducer;

export const { updateCampaigns } = homeSlice.actions;

export const getHomeSliceState = (rootState: RootState): HomeState =>
  rootState[HOME_KEY];

export const getCampaigns = createSelector(
  getHomeSliceState,
  (state) => state.campaigns
);
