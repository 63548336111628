// Generated by ts-to-zod
import { z } from 'zod';

const formatTypeSchema = z.union([z.literal('IMAGE'), z.literal('DOCUMENT')]);

export const parameterTypeSchema = z.enum([
  'image',
  'text',
  'payload',
  'document',
]);

const normalizedParameterTypeSchema = z
  .string()
  .transform((val) => val.toLowerCase())
  .pipe(parameterTypeSchema);

const exampleSchema = z.object({
  header_handle: z.array(z.string()).optional(),
  body_text: z.array(z.array(z.string())).optional(),
});

const buttonSchema = z.object({
  url: z.string().optional(),
  text: z.string(),
  type: z.string(),
  example: z.array(z.string()).optional(),
});

const fileSchema = z.object({
  link: z.string(),
  filename: z.string().optional(),
});

export const componentSchema = z.object({
  type: z.string(),
  example: exampleSchema.optional(),
  format: formatTypeSchema.optional(),
  text: z.string().optional(),
  buttons: z.array(buttonSchema).optional(),
});

export const parameterSchema = z.object({
  text: z.coerce.string().optional(),
  type: normalizedParameterTypeSchema,
  payload: z.string().optional(),
  image: fileSchema.optional(),
  document: fileSchema.optional(),
});

const paramsSchema = z.object({
  type: z.string(),
  parameters: z.array(parameterSchema),
  index: z.string().optional(),
  sub_type: z.string().optional(),
});

export const templateParams = z.array(paramsSchema);

export const templateComponents = z.array(componentSchema);

export const templateSchema = z.object({
  params: templateParams,
  components: templateComponents,
});

export const templateSchemaV2 = z.object({
  components: z.array(
    z.object({
      type: z.string(),
      parameters: z.array(parameterSchema).optional(),
      buttons: z.array(buttonSchema).optional(),
    })
  ),
  name: z.string(),
  language: z.object({
    code: z.string(),
  }),
});

export const templateSchemaV3 = z.object({
  category: z.string().optional(),
  components: templateComponents,
  id: z.string().optional(),
  language: z.string().optional(),
  name: z.string(),
  status: z.string().optional(),
  waba_id: z.string().optional(),
});

export type TemplateParameters = z.infer<typeof parameterSchema>;

export type TemplateParams = z.infer<typeof templateParams>;

export type TemplateComponent = z.infer<typeof componentSchema>;

export const transformSchemaV2toV1 = templateSchemaV2.transform((template) => {
  const { name, components } = template;

  const updatedComponents = components.map(({ type, parameters, buttons }) => {
    const textParameters =
      parameters?.filter(({ type }) => type === 'text') || [];
    const imageParameters =
      parameters?.filter(({ type }) => type === 'image') || [];
    const docParameters =
      parameters?.filter(({ type }) => type === 'document') || [];

    return {
      type: type.toUpperCase(),
      buttons,
      text: textParameters.map((_, index) => `{{${index + 1}}}`).join(' '),
      format:
        imageParameters?.length > 0
          ? 'IMAGE'
          : docParameters?.length > 0
          ? 'DOCUMENT'
          : undefined,
    };
  });

  const params: TemplateParams = components.map(
    ({ type, parameters = [] }) => ({
      type,
      parameters,
    })
  );

  return { [name]: { components: updatedComponents, params } };
});

export const transformSchemaV3toV1 = templateSchemaV3.transform((template) => {
  const { name, components } = template;

  const params: TemplateParams = components.map(
    ({ type, example, format, text }) => {
      const parameters: TemplateParameters[] = [];
      if (format === 'IMAGE') {
        parameters.push({
          type: 'image',
          image: { link: example?.header_handle?.[0] || '' },
        });
      } else if (format === 'DOCUMENT') {
        parameters.push({
          type: 'document',
          image: { link: example?.header_handle?.[0] || '' },
        });
      } else if (text) {
        example?.body_text?.[0]?.forEach((text) =>
          parameters.push({
            type: 'text',
            text,
          })
        );
      }
      return { type, parameters };
    }
  );

  return { [name]: { components, params } };
});

export const noIntentTemplateSchema = z.record(
  z.string(),
  z.object({
    url: z.string(),
    caption: z.string(),
    filename: z.string().optional(),
  })
);

export const messageTemplateSchema = z.record(z.string(), templateSchema);

export const mappedTemplateSchema = z.array(
  z.object({
    type: z.string(),
    format: formatTypeSchema.optional(),
    text: z.string().optional(),
    parameters: z.array(parameterSchema),
  })
);
