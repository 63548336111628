import { ColumnConfig, CustomerPerformanceReportColumnKey } from '../../model';

export const CustomerPerformanceReportConfig: Record<
  CustomerPerformanceReportColumnKey,
  ColumnConfig
> = {
  sNo: {
    name: 'S.NO',
  },
  customerName: {
    name: 'Customer Name',
    sortField: 'companyName'
  },
  noOfOrders: {
    name: 'Total Orders',
    sortField: 'totalOrders',
    alignment: 'flex-end',
  },
  orderValue: {
    name: 'Order value',
    sortField: 'totalValue',
    alignment: 'flex-end',
  },
  noOfInvoices: {
    name: 'Number of Invoices',
    sortField: 'totalInvoices',
    alignment: 'flex-end',
  },
  invoicesValue: {
    name: 'Invoices Value',
    alignment: 'flex-end',
  },
  totalPendingInvoices: {
    name: 'Total Pending Invoices',
    sortField: 'totalPendingInvoices',
    alignment: 'flex-end',
  },
  pendingInvoicesValue: {
    name: 'Pending invoices Value',
    sortField: 'totalPendingInvoicesValue',
    alignment: 'flex-end',
  },
  creditLimit: {
    name: 'Credit Limit',
    sortField: 'creditLimit',
    alignment: 'flex-end',
  },
  totalOutstandingAmount: {
    name: 'Total Outstanding',
    sortField: 'outstandingAmount',
    alignment: 'flex-end',
  },
  state: {
    name: 'State',
  },
  district: {
    name: 'District',
  },
  pincode: {
    name: 'Pincode',
  },
};
