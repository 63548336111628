import React from 'react';
import { Box, Typography, Stack, IconButton, Tooltip } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import BlockIcon from '@mui/icons-material/Block'; // For WhatsApp disabled
import { ZoAvatar, AvatarSizeVariants } from '../avatar/zo-avatar';
import './zo-customer-details.scss';
import { SHARED_SVGS } from '@zonofi/shared';

export interface CustomerDetails {
  id: string;
  companyName: JSX.Element | string;
  customerName: string;
  mobileNumber: string;
  address: string;
  invitationNotSent: boolean;
  isLocked: boolean;
}

interface ZoCustomerDetailsProps {
  customer: CustomerDetails;
  onClick?: () => void;
}

// const customers: CustomerDetails[] = [
//   {
//     id: 1,
//     companyName: 'Hero Motors Ltd',
//     customerName: 'Ajeet Khosla',
//     mobileNumber: '+91 74828 00906',
//     address: '123 Hero Street, New Delhi, India',
//     invitationSent: false,
//     isLocked: true,
//   },
//   {
//     id: 2,
//     companyName: 'Jubilant Life Sciences Ltd',
//     customerName: 'Naresh',
//     mobileNumber: '+91 95234 67208',
//     address: '456 Jubilant Avenue, Mumbai, India',
//     invitationSent: true,
//     isLocked: false,
//   },
//   {
//     id: 3,
//     companyName: 'Nestlé',
//     customerName: 'Sumit Swaroop',
//     mobileNumber: '+91 98124 25109',
//     address: '789 Nestlé Lane, Bengaluru, India',
//     invitationSent: true,
//     isLocked: true,
//   },
// ];

// export const ZoCustomerDetails: React.FC = () => {
//   return (
//     <div className='font-regular'>
//       {customers.map((customer) => (
//         <div className='customer-details_sec'
//           key={customer.id}
//           >
//           <div className='customer_informaion'>
//           <span className='avatar_icon'>
//           <ZoAvatar title={customer.companyName} shape='round' variant={AvatarSizeVariants.LARGE} />
//           </span>
//           <div className="custmerdata">
//               <div className='top_sec'>
//                 <div className='company_name'>
//                   {customer.companyName}
//                 </div>
//                 <div className='icon_section'>
//                 {/* Lock/Unlock Icon */}
//                 <span title={customer.isLocked ? 'Locked' : 'Unlocked'}>
//                   <span className='icon'>
//                     {customer.isLocked ? (
//                       <img src={SHARED_SVGS.LockIconNew} />
//                     ) : (
//                       <img src={SHARED_SVGS.UnLockIconNew} />
//                     )}
//                   </span>
//                 </span>

//                 {/* WhatsApp Icon */}
//                 {customer.invitationSent ? (
//                   <span title="WhatsApp Enabled">
//                     <span className='icon'>
//                       {/* <WhatsAppIcon color="success" /> */}
//                     </span>
//                   </span>
//                 ) : (
//                   <span title="WhatsApp Disabled">
//                     <span className='icon'>
//                       <img src={SHARED_SVGS.WhatsAppDisableIcon} />
//                     </span>
//                   </span>
//                 )}

//                 {/* Message for no invitation */}
//                 {!customer.invitationSent && (
//                   <span className='notsent'>
//                     Not Sent
//                   </span>
//                 )}
//               </div>
//             </div>

//           <div className='bottom_sec'>
//             <div className='customer_name'>
//               {customer.customerName}
//             </div>
//             <div className="pointer">•</div>
//             <div className='mobile'>
//               {customer.mobileNumber}
//             </div>
//             {/* <Typography variant="body2" color="textSecondary">
//               {customer.address}
//             </Typography> */}
//           </div>
//           </div>
//           </div>
//         </div>
//       ))}
//     </div>
//   );
// };

export const ZoCustomerDetails: React.FC<ZoCustomerDetailsProps> = ({
  customer,
  onClick,
}) => {
  const handleAvatarOrNameClick = (event: React.MouseEvent) => {
    event.stopPropagation(); // Prevent other click handlers on the parent from triggering
    if (onClick) onClick();
  };
  console.log(customer, 'return the customer');
  return (
    <div className="customer-details_sec font-regular">
      <div className="customer_informaion">
        <span
          className="avatar_icon"
          onClick={handleAvatarOrNameClick}
          style={{ cursor: 'pointer' }}
        >
          {/* <ZoAvatar title={customer.companyName} shape="round" variant={AvatarSizeVariants.LARGE} /> */}
          <ZoAvatar
            title={
              typeof customer.companyName === 'string'
                ? customer.companyName
                : 'Default Title'
            }
            shape="round"
            variant={AvatarSizeVariants.LARGE}
          />
        </span>
        <div className="custmerdata">
          <div className="top_sec">
            <div
              className="company_name"
              onClick={handleAvatarOrNameClick}
              style={{ cursor: 'pointer' }}
            >
              {customer.companyName}
            </div>
            <div className="icon_section">
              <span title={customer.isLocked ? 'Locked' : 'Unlocked'}>
                <span className="icon">
                  <img
                    src={
                      customer.isLocked
                        ? SHARED_SVGS.LockIconNew
                        : SHARED_SVGS.UnLockIconNew
                    }
                    alt={customer.isLocked ? 'Locked' : 'Unlocked'}
                  />
                </span>
              </span>
              {customer.invitationNotSent && (
                <span title={'WhatsApp Disabled'}>
                  <span className="icon">
                    <img
                      src={SHARED_SVGS.WhatsAppDisableIcon}
                      alt={'WhatsApp Disabled'}
                    />
                  </span>
                </span>
              )}
              {customer.invitationNotSent && (
                <span className="notsent">Not Sent</span>
              )}
            </div>
          </div>
          <div className="bottom_sec">
            <div className="customer_name">{customer.customerName}</div>
            <div className="pointer">•</div>
            <div className="mobile">{customer.mobileNumber}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
