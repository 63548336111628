import { Drawer } from '@mui/material';
import React from 'react';

interface DrawerWrapperProps {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode | React.ReactNode[];
  variant?: 'default' | 'small';
  width?: string;
}

export const DrawerWrapperNew: React.FC<DrawerWrapperProps> = ({
  onClose,
  open,
  children,
  variant = 'default',
  width = '50%',
}) => {
  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor="right"
      PaperProps={{
        sx: {
          width: variant === 'default' ? width : '30%',
          overflowX: 'hidden',
          zIndex: 999999,
        },
      }}
      id="chat-drawer-root"
      disableEscapeKeyDown
    >
      {children}
    </Drawer>
  );
};
