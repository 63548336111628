import { Api } from '../api/root-api';
import {
  ReinviteRequest,
  RevokeRequest,
  TeamMember,
  TeamInvitesRequest,
  UpdateRoleRequest,
  UpdateTeamInvitesRequest,
  UpdateTeamInviteResponse,
  RolesAndPermissionsRequest,
  RoleByIDsRequest,
  RoleByIDsResponse,
  RolesAndPermissionsResponse,
  UpdateTeamEmailRequest,
  UpdateTeamEmailResponse,
  UpdateTeamMobileNumberResponse,
  UpdateTeamMobileNumberRequest,
  GetCustomersResponse,
  GetCustomersRequest,
  TeamMembersRequest,
  teamsSearchResponse,
  teamsSearchRequest,
  GetTeamMemberDetailRequest,
  GetTeamMemberDetailResponse,
} from './model';
import { sentTeamInvitesSlice } from './slice';

export const sentTeamsInvitesApi = Api.injectEndpoints({
  // tagTypes: [''],
  endpoints: (builder) => ({
    getChannelMembersSentInvite: builder.query<
      TeamMember[],
      TeamMembersRequest
    >({
      query: (args) => ({
        url: `hub/orgs/api/team/list?includeTeamCustomersMapping=${true}&&includePendingInvites=${
          args.includePendingInvites !== undefined
            ? args.includePendingInvites
            : true
        }`,
        params: { ...(args.searchKey ? { searchKey: args.searchKey } : {}), sellerWorkspaceId: args.workspaceId },
        method: 'GET',
      }),
      providesTags: ['TeamInvites', 'teamMemberList'],

      async onQueryStarted(request, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then((response) => {
            dispatch(
              sentTeamInvitesSlice.actions.updateTeamMembers(response.data)
            );
          })
          .catch((x) => {
            console.error(x);
          });
      },
    }),
    teamsSearch: builder.query<teamsSearchResponse[], teamsSearchRequest>({
      query(args) {
        return {
          url: 'teams/members/search',
          method: 'GET',
          params: args,
        };
      },
    }),
    sendTeamMemberInvite: builder.mutation<TeamMember[], TeamInvitesRequest>({
      query(params) {
        return {
          url: `send/teaminvites`,
          method: 'POST',
          body: params,
        };
      },
    }),
    updateTeamMemberInvite: builder.mutation<
      UpdateTeamInviteResponse,
      UpdateTeamInvitesRequest
    >({
      query(params) {
        return {
          url: `teamMember/create`,
          method: 'POST',
          body: params,
        };
      },
      invalidatesTags: ['TeamInvites'],
    }),
    addSupportUser: builder.mutation<any, any>({
      query(params) {
        return {
          url: `admin/user`,
          method: 'POST',
          body: params,
        };
      },
    }),
    inactiveSupportUser: builder.mutation<any, any>({
      query(params) {
        return {
          url: `/admin/user/status`,
          method: 'POST',
          body: params,
        };
      },
    }),
    updateCustomer: builder.mutation<any, any>({
      query(params) {
        return {
          url: `admin/user/assign-roles`,
          method: 'POST',
          body: params,
        };
      },
    }),
    updateInviteStatus: builder.mutation<string, string>({
      query(id) {
        return {
          url: `teams/${id}`,
          method: 'PUT',
          body: { status: 'P' },
          responseHandler: (response) => response.text(),
        };
      },
    }),
    reinviteTeamMember: builder.mutation<string, ReinviteRequest>({
      query(args) {
        return {
          url: 'teams/reinvite',
          method: 'POST',
          body: args,
          responseHandler: (response) => response.text(),
        };
      },
    }),
    revokeTeamMember: builder.mutation<string, RevokeRequest>({
      query(args) {
        return {
          url: 'workspaces/members/revoke',
          method: 'POST',
          body: args,
          responseHandler: (response) => response.text(),
        };
      },
    }),
    updateTeamMemberRole: builder.mutation<string, UpdateRoleRequest>({
      query(args) {
        return {
          url: 'workspaces/members/role',
          method: 'POST',
          body: args,
          responseHandler: (response) => response.text(),
        };
      },
    }),
    getRolesAndPermissions: builder.query<
      RolesAndPermissionsResponse,
      RolesAndPermissionsRequest
    >({
      query(args) {
        return {
          url: 'workspaces/roles/permissions',
          method: 'GET',
          params: args,
        };
      },
    }),
    getCustomers: builder.query<GetCustomersResponse, GetCustomersRequest>({
      query(args) {
        return {
          url: 'admin/workspaces',
          method: 'GET',
          params: args,
        };
      },
    }),
    deleteCustomer: builder.mutation<any, { id: string }>({
      query: (args) => {
        return {
          url: `admin/user/delete/${args.id}`,
          method: 'DELETE',
        };
      },
    }),
    getRoleByIDs: builder.mutation<RoleByIDsResponse, RoleByIDsRequest>({
      query(args) {
        return {
          url: 'workspaces/roles/permission/list',
          method: 'POST',
          body: args,
        };
      },
    }),
    updateTeamEmail: builder.mutation<
      UpdateTeamEmailResponse,
      UpdateTeamEmailRequest
    >({
      query(args) {
        return {
          url: 'teams/update/email',
          method: 'POST',
          body: args,
        };
      },
      invalidatesTags: ['TeamInvites'],
    }),
    updateTeamMobile: builder.mutation<
      UpdateTeamMobileNumberResponse,
      UpdateTeamMobileNumberRequest
    >({
      query(args) {
        return {
          url: 'teams/update/mobile',
          method: 'POST',
          body: args,
        };
      },
      invalidatesTags: ['TeamInvites'],
    }),
    getTeamMemberDetail: builder.query<
      GetTeamMemberDetailResponse,
      GetTeamMemberDetailRequest
    >({
      query(arg) {
        return {
          url: `hub/orgs/api/team/details/${arg.sellerWorkspaceId}?teamMemberId=${arg.teamMemberId}&sellerWorkspaceId=${arg.sellerWorkspaceId}`,
          method: 'get',
        };
      },
    }),
  }),
  overrideExisting: true,
});

export const {
  useLazyGetChannelMembersSentInviteQuery,
  useSendTeamMemberInviteMutation,
  useUpdateInviteStatusMutation,
  useReinviteTeamMemberMutation,
  useRevokeTeamMemberMutation,
  useUpdateTeamMemberRoleMutation,
  useUpdateTeamMemberInviteMutation,
  useUpdateCustomerMutation,
  useAddSupportUserMutation,
  useInactiveSupportUserMutation,
  useDeleteCustomerMutation,
  useLazyGetRolesAndPermissionsQuery,
  useGetRoleByIDsMutation,
  useLazyGetCustomersQuery,
  useUpdateTeamEmailMutation,
  useUpdateTeamMobileMutation,
  useLazyTeamsSearchQuery,
  useLazyGetTeamMemberDetailQuery,
} = sentTeamsInvitesApi;
