import React from 'react';
import { Switch } from '@mui/material';
import { styled } from '@mui/system';

interface SwitchComponentProps {
  checked: boolean;
  onClick: () => void;
}

export const ZoSwitch = (props: SwitchComponentProps) => {
  const { onClick, checked } = props;
  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
      '& .MuiSwitch-thumb': {
        width: 15,
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(9px)',
      },
    },
    '& .MuiSwitch-switchBase': {
      padding: 2,
      '&.Mui-checked': {
        transform: 'translateX(12px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: '#32A7E8',
          ...theme.applyStyles('dark', {
            backgroundColor: '#32A7E8',
          }),
        },
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      width: 12,
      height: 12,
      borderRadius: 6,
      // Rework - Requires a permanent fix
      // transition: theme.transitions.create(['width'], {
      //   duration: 200,
      // }),
    },
    '& .MuiSwitch-track': {
      borderRadius: '8px',
      opacity: 1,
      backgroundColor: '#EAEAEA',
      boxSizing: 'border-box',
      ...theme.applyStyles('dark', {
        backgroundColor: '#EAEAEA',
      }),
    },
  }));

  return (
    <AntSwitch
      checked={checked}
      onClick={() => onClick()}
      inputProps={{ 'aria-label': 'ant design' }}
    />
  );
};