export interface ProductsRequestParams {
  pageNo?: number;
  pageSize?: number;
  customerId?: string;
  sellerWorkspaceId?: number;
}

export interface ProductsRequestBody {
  includeFacets?: boolean;
  searchKey?: string;
  includeDivisions?: boolean;
  includeCfas?: boolean;
  includeCollections?: boolean;
  includeCategoryCodesList?: boolean;
  categoryCodes?: string[];
  divisionIdFilter?: string[];
  divisionOperator?: boolean;
  divisionIds?: string[];
  facetValueIds?: string[];
  facetValueOperator?: boolean;
  collectionId?: string;
  skuCode?: string | number;
  productVariantId?: string | number;
  statusFilter?: string;
  sortDirection?: string;
  sortBy?: string;
  customerId?: string;
}

export interface CategoryFilterItem {
  categoryCode: string;
  categoryId: string;
  categoryName: string;
  selected?: boolean;
}

export interface ProductListResponse {
  total: number;
  startRecord: number;
  endRecord: number;
  products: Product[];
  divisionSummary: ProductDivision[];
  categoryCodes: CategoryFilterItem[];
  facets: any[];
  cfaSummary: ProductCfa[];
  status: string;
  statusCode: number;
  input: ProductInput;
}

export interface ProductCfa {
  cfaCode: string;
  cfaId: string;
  supplierName: string;
  minOrderQty: number;
  isDefault: boolean;
}

export interface ProductDivision {
  divisionCode: string;
  divisionId: string;
  name: string;
  headDivisionId: string;
  headDivision: ProductHeadDivision;
  isDefault: boolean;
}

export interface ProductHeadDivision {
  id: string;
  code: string;
  name: string;
}

export interface ProductInput {
  skip: number;
  sort: {
    productId: string;
  };
  take: string;
}

export interface Product {
  parentSku: string;
  productAsset: Record<string, any>;
  source: string;
  preview: string;
  enabled: boolean;
  id: number;
  productVariants: ProductVariant[];
  channelId: number;
  workspaceId: string;
}

export interface ProductVariant {
  facets: any[];
  taxCategory: ProductTaxCategory;
  enabled: boolean;
  price: number;
  MRP: number;
  PTR: number;
  isSpecial: boolean;
  sku: string;
  sortOrder: number;
  id: number;
  taxCategoryId: number;
  productId: number;
  name: string;
  shortName: string;
  shortDescription: string;
  productVariantId: number;
  ean: string;
  erpId: string;
  erpPriceUOM: string;
  erpUOM: string;
  ZINS: boolean;
  hsnCode: string;
  upcCode: string;
  baseUom: string;
  warrentyInformation: string;
  condition: string;
  packSize: number;
  manufacturingDate: null;
  expiryDate: null;
  bestBeforeOrAfter: null;
  bestAfter: null;
  minOrderQty: number;
  maxOrderQty: number;
  minRemShelfLife: null;
  qtyMultiplier: number;
  grossWeight: number;
  netWeight: number;
  volume: number;
  division: ProductDivision[];
  cfas: ProductCfa[];
  collections: string[];
  promotions: ProductPromotion[];
  stock: number;
  sales: number;
  margin: string;
  isActiveForOrderCfaIds: string[];
  salesOrg: null;
  assets: {
    filename: string;
    redirectUrl: string;
    sequenceNo: number;
    subType: string;
  }[];
  categories: ProductVariantCategory[];
  quantity: number;
  popularity: number;
  isDefault?: boolean;
}

export interface ProductPromotion {
  id: number;
  name: string;
  promotionType: string;
  promotionCode: string;
  enabled: boolean;
  startsAt: Date;
  endsAt: Date;
  productVariantId: number;
  discount: string;
  discountType: string;
  minQtyASC: number;
  freeQtyASC: number;
  promotionSubType: null;
  promotion: string;
}

export interface ProductTaxCategory {
  id: number;
  name: string;
}

export interface LineInOrder {
  quantity: number;
  productVariantId: number;
  orderId: number;
  poFileId?: string;
  orderLineId?: number;
}

export interface CatalogListData {
  sku: string;
  name: string;
  productVariantId: number;
  minOrderQty: number;
  isAddedToCart: boolean;
  addedQty: number;
  lineInOrder: LineInOrder;
  pts?: number;
  ptr?: number;
  margin?: number;
  offer?: boolean;
  moqError?: boolean;
  promotions?: ProductPromotion[];
  discountedPrice?: number;
  productImage?: string;
  updatingQty?: boolean;
  isLoading?: boolean;
  categories?: ProductVariantCategory[];
}

export interface ProductVariantCategory {
  categoryId: number;
  categoryName: string;
  categoryCode: string;
}

export interface CatalogueOrderSummary {
  poFileId: string;
  orderId: number;
  skuCount: number;
  totalQuantity: number;
}

export enum SchemeTypes {
  BUYXGETY = 'buy_x_get_y_free',
  PRODUCTDISCOUNT = 'products_percentage_discount',
}

export interface CatalogProducts {
  name: string;
  image: string;
  price: number;
  promotions: CatalogProductsPromotion[];
  tags: any[];
  qtyInCart: number;
}

export interface CatalogProductsPromotion {
  id: number;
  name: string;
  promotionType: string;
  promotionCode: string;
  enabled: boolean;
  startsAt: any;
  endsAt: any;
  productVariantId: number;
  discount: string;
  discountType: any;
  minQtyASC: number;
  freeQtyASC: number;
  promotionSubType: number;
  promotion: string;
}

export interface ParseImageToProductsRequest {
  workspaceId: string;
  sellerWorkspaceId: string;
  body: FormData;
}

export interface ParseImageToProductsResponse {
  items: PIItems;
}

export interface PIItems {
  matches: PIMatch[];
  totalMatches: number;
}

export interface PIMatch {
  originalInput: PIOriginalInput;
  matchedProducts: PIMatchedProduct[];
}

export interface PIOriginalInput {
  title: string;
  quantity: number;
}

export interface PIMatchedProduct {
  productId: number;
  variantId: number;
  title: string;
  moq: number;
  price: number;
  imageUrl: string;
  adjustedQuantity?: number;
  isLoading?: boolean;
}

export interface getProductDetailsRequest {
  includeFacets: boolean;
  includeDivisions: boolean;
  includeCfas: boolean;
  productVariantId: number;
}

export interface getProductDetailsResponse {
  products: Product[];
  
}
