import { CategoryColumnKey, ColumnConfig } from '../model';

export const categoryConfig: Record<CategoryColumnKey, ColumnConfig> = {
  categories: {
    name: 'Category Name',
    isComponent: true,
  },

  code: {
    name: 'Category Code',
  },
  parentCategory: {
    name: 'Parent Category',
  },
  products: { name: 'No of Products' },
  actions: {
    name: '',
    field: 'component',
    alignment: 'flex-end',
  },
};
