import { ColumnConfig, BankAccountColumnKey } from '../../model';

export const bankAccountConfig: Record<BankAccountColumnKey, ColumnConfig> = {
  bankName: {
    name: 'Bank Name',
    field: 'textWithImage',
  },
  accountNumber: { name: 'Account Number' },
  accountHolderName: {
    name: 'Account Holder Name',
  },
  ifscCode: { name: 'IFSC Code' },
  alias: { name: 'Alias' },
  settings: {
    name: '',
    alignment: 'flex-end',
    field: 'component',
  },
};
