import { ColumnConfig, CustomerDraftReturnColumnKey } from '../../model';

export const customerDraftReturnConfig: Record<
  CustomerDraftReturnColumnKey,
  ColumnConfig
> = {
  batchNumber: { name: 'Batch Number' },
  title: { name: 'Product Title & SKU Code', field: 'multiLine' },
  expiryDate: { name: 'Expiry Date', sortField: 'expiryDate' },
  pts: {
    name: 'P. PTS',
    headerExtra: {
      type: 'info',
      details:
        'Potential value of return good, subject to change based on CFA reconciliation and actual selling price.',
      title: 'Potential PTS',
    },
  },
  invoiceNumber: { name: 'Invoice Number' },
  category: { name: 'Category' },
  quantity: { name: 'Return Qty', alignment: 'center', sortField: 'qty' },
};
