import { ColumnConfig, CfaReturnDetailsEditColumnKey } from '../../model';

export const cfaManualReturnConfig: Record<
  CfaReturnDetailsEditColumnKey,
  ColumnConfig
> = {
  batchNumber: {
    name: 'Batch Number',
    field: 'component',
  },
  title: {
    name: 'Product Title & SKU Code',
    field: 'multiLine',
  },
  pts: {
    name: 'P. PTS',
    alignment: 'flex-end',
    headerExtra: {
      type: 'info',
      details:
        'Potential value of return good, subject to change based on CFA reconciliation and actual selling price.',
      title: 'Potential PTS',
    },
  },
  invoiceNumber: {
    name: 'Invoice Number',
    field: 'dropdownOrInput',
  },
  categoryExpiry: { name: 'Category & Expiry Date', field: 'multiLine' },
  quantity: {
    name: 'Return Qty',
    alignment: 'flex-end',
    field: 'textinput',
  },
  type: { name: 'Type', field: 'dropdown', sortField: '' },
  status: { name: 'Status', field: 'dropdown' },
  actions: { name: '', alignment: 'flex-end' },
};
