import React from 'react';
import { SVGS } from '@zonofi/zono-money-design-system';
import './catalog-image-search-footer.scss';

interface CatalogImageSearchFooterProps {
  selectedOption: number;
  setSelectedOption: (val: number) => any;
}

export const CatalogImageSearchFooter: React.FC<
  CatalogImageSearchFooterProps
> = ({ selectedOption, setSelectedOption }) => {
  return (
    <div className="catalog-image-search-footer font-primary-semibold">
      <div
        className={`catalog-image-search-footer-option ${
          selectedOption === 0 && 'catalog-image-search-footer-option-selected'
        }`}
        onClick={() => setSelectedOption(0)}
      >
        <img
          src={
            selectedOption === 0 ? SVGS.ImageScanActive : SVGS.ImageScanInActive
          }
        />
        <span>Scan Image</span>
      </div>
      <div
        className={`catalog-image-search-footer-option ${
          selectedOption === 1 && 'catalog-image-search-footer-option-selected'
        }`}
        onClick={() => setSelectedOption(1)}
      >
        <img
          src={
            selectedOption === 1 ? SVGS.ImageAddActive : SVGS.ImageAddInactive
          }
        />
        <span>Upload Image</span>
      </div>
    </div>
  );
};
