import { linkedWorkspacesAdapter } from './LinkedWorkspacesSlice';
import { EntityId } from '@reduxjs/toolkit';
import { LinkedWorkspacePayload } from './model';
import { RootState, store } from '../root';

export const linkedWorkspacesSelectors = linkedWorkspacesAdapter.getSelectors(
  (state: RootState) => state.linkedWorkspaceData
);

export const linkedWorkspacesNativeSelectors =
  linkedWorkspacesAdapter.getSelectors();

const getLinkecWorkspaceState = () => store.getState().linkedWorkspaceData;

export const getAllLinkedWorkspaces = (
  linkedWorkspaces = getLinkecWorkspaceState()
) => {
  return linkedWorkspacesNativeSelectors.selectAll(linkedWorkspaces);
};
export const getAllLinkedWorkspaceIds = (
  linkedWorkspaces = getLinkecWorkspaceState()
) => {
  return linkedWorkspacesNativeSelectors.selectIds(linkedWorkspaces);
};
export const getLinkedWorkspaceById = (
  id: EntityId,
  linkedWorkspaces = getLinkecWorkspaceState()
) => {
  return linkedWorkspacesNativeSelectors.selectById(linkedWorkspaces, id);
};
export const getLinkedWorkspacesCount = (
  linkedWorkspaces = getLinkecWorkspaceState()
) => {
  return linkedWorkspacesNativeSelectors.selectTotal(linkedWorkspaces);
};

export const getLinkedWorkspacesById = (
  ids: any[],
  linkedWorkspaces = getLinkecWorkspaceState()
) => {
  const idLinkedWorkspaces = ids.map((id: EntityId) => {
    return linkedWorkspacesNativeSelectors.selectById(linkedWorkspaces, id);
  });
  return idLinkedWorkspaces;
};

export const getLinkedWorkspaceByPrincipalId = (
  id: string | undefined,
  linkedWorkspaces = getLinkecWorkspaceState()
) => {
  let val;
  const objValues = Object.values(linkedWorkspaces?.entities);
  objValues.map((el?: LinkedWorkspacePayload) => {
    if (el?.principalWorkspaceId === id || el?.clientWorkspaceId === id) {
      val = el;
    }
  });
  return val;
};
