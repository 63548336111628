import { ColumnConfig, CfaReportsColumnKey } from '../../model';

export const cfaReportsConfig: Record<CfaReportsColumnKey, ColumnConfig> = {
  sNo: {
    name: 'S.No',
  },
  orderDate: {
    name: 'Date',
    sortField: 'orderDate',
  },
  tags: {
    name: 'Tags',
  },
  customerName: {
    name: 'Customer Name',
  },

  skuCode: {
    name: 'SKU Code',
  },
  productTitle: {
    name: 'Product Title',
    field: 'component',
  },
  orders: {
    name: 'orders',
    sortField: 'orders',
    alignment: 'flex-end',
  },
  orderQty: {
    name: ' Ord. Qty',
    sortField: 'ordQty',
    alignment: 'flex-end',
  },
  orderAmt: {
    name: 'Ord Amt',
    sortField: 'ordAmt',
    alignment: 'flex-end',
  },
};
