import { ColumnConfig, ProductsCfaColumnKey } from '../../model';

export const productsCfaConfig: Record<ProductsCfaColumnKey, ColumnConfig> = {
  cfa: {
    name: 'CFA Name & ID',
    field: 'multiLine',
  },
  divisions: {
    name: 'Divisions',
    field: 'component',
  },
  moq: {
    name: 'MOQ',
  },
  currentStock: {
    name: 'Current Stock',
    sortField: 'currentStock',
  },
};
