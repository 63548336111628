import { ColumnConfig, ProductsMappingColumnKey } from '../model';

export const productMappingConfig: Record<
  ProductsMappingColumnKey,
  ColumnConfig
> = {
  sequence: {
    name: 'Sequence',
    field: 'checkboxText',
    isCheckboxField: true,
    sortField: 'sequence',
  },
  skuCode: {
    name: 'SKU',
    isHyperlink: true,
    field: 'NavigateLink',
  },

  dtProductTitle: {
    name: 'DT Product Title',
  },
  productTitle: {
    name: 'Product Title',
  },
  pts: { name: 'PTS', alignment: 'flex-end', sortField: 'pts' },
  // currentStock: {
  //   name: 'Current Stock',
  //   alignment: 'flex-end',
  //   sortField: 'currentStock'
  // },
  uploadedQty: {
    name: 'Uploaded Qty',
    alignment: 'flex-end',
  },
  orderedQty: {
    name: 'Ordered Qty',
    alignment: 'flex-end',
    sortField: 'orderedQty',
    isComponent: true,
  },
  packSize: {
    name: 'MOQ',
    alignment: 'flex-end',
  },
  status: {
    name: 'Status',
    field: 'component',
    alignment: 'flex-end',
  },
};
