import { Api } from '../api/root-api';
import { Event, EventRequestParams } from './model';

export const eventsApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    getEvents: builder.query<
      Event[],
      { workspaceId: string; params: EventRequestParams }
    >({
      query(inputParams) {
        return {
          url: `events/${inputParams?.workspaceId}`,
          method: 'GET',
          params: inputParams?.params,
        };
      },
    }),
  }),
});

export const { useLazyGetEventsQuery } = eventsApi;
