import { Permission } from '../sent-team-invites';

export enum AppName {
  SELLER = 'SELLER',
  FIELD_COLLECT = 'FIELD_COLLECT',
  ZONO_MONEY = 'ZONO_MONEY',
  ZONO_DIGITAL = 'zono-digital',
}

export enum DevicePlatform {
  ANDROID = 'ANDROID',
  IOS = 'IOS',
  WEB = 'WEB',
  APP = 'APP',
}

export interface User {
  newUser?: boolean;
  teamInviteId?: string;
  mobile: string;
  firstName: string;
  lastName: string;
  email?: string;
  id: string;
  role: string;
  profilePic?: string;
  contentType?: string;
  systemFirstName?: string;
  systemLastName?: string;
  city?: string;
  subtitle?: string;
  registeredOn?: string;
  address?: string;
  userType: string;
  token: string;
  refreshToken: string;
  isVerified: boolean;
  integrationToken: string;
  alertMessage?: string;
  attemptsLeft?: number;
  resendAttemptsLeft?: number;
  loginAttemptsLeftmessage?: string;
  lastLoginFailedAttempt?: string;
  uom?: string;
  message?: string;
  timeLeft?: number;
  isEmailVerified?: boolean;
  isPrincipal?: boolean;
  workspaceId?: string;
  workspaceRoles?: string[];
  inviteUserType?: string;
  customerCFADivisions?: ICfaDivision[];
  inviteDetails?: InviteDetails;
  inviteId?: string;
  temptoken?: string;
  isOtpValid?: boolean;
  authChannel?: string;
  mfaAuthChannel?: string;
  userProfile?: string;
  loggedInOnBehalfData: {
    isLoggedInOnBehalf: boolean;
    supportUserToken: string;
    supportUserRefereshToken: string;
    workspaceId: string;
  };
  cfaIDs?: string[];
}

interface InviteDetails {
  id: string;
  phone: string;
  phone2: string;
  inviteType: string;
  userType?: string;
  isActive: boolean;
  isArchive: boolean;
  senderUserId: string;
  invitedUserId: string;
  senderWorkspaceId: string;
  invitedWorkspaceId: string;
  status: string;
  createdDate: string;
  updatedDate: string;
  inviteDetails: InviteDetaills;
}

interface InviteDetaills {
  id: string;
  distributorCode: string;
  stateId: string;
  state: { stateCode: string };
  email?: string;
}
export interface ICfaDivision {
  id: string;
  cfaDivisionCode: string;
  state: string;
  name: string;
  divisions: IDivision[];
}

export interface IDivision {
  id: string;
  code: string;
  name: string;
  headDivisionId: string;
}
export interface SendOTPRequest {
  mobile?: string;
  authChannel?: 'email' | 'mobile';
  email?: string;
  inviteId?: string;
}
export interface ResendOTPRequest {
  mobile?: string;
  authChannel?: 'email' | 'mobile';
  email?: string;
  inviteId?: string;
}

export interface VerifyOTPRequest {
  mobile?: string;
  authChannel?: 'email' | 'mobile';
  email?: string;
  otp: string;
  mfaStatus: boolean;
  fcmEnabled?: boolean;
}

export interface AddMFAChannelRequest {
  email: string;
  mobile: string;
}

export interface AddMFAChannelResponse {
  tempToken: string;
  resendOtpCount: number;
  lastResendAttempt: string;
  message: string;
  attemptsLeft: number;
  timeLeft: number;
  resendAttemptsLeft: number;
  uom: string;
  email: string;
  userId: string;
  phone: string;
  sendOtp: boolean;
  otp: number;
  status: string;
  communicationUp: boolean;
}

export interface UserPermissionsResponse {
  id: string;
  createdDate: string;
  updatedDate: string;
  title: string;
  slug: string;
  isActive: boolean;
  description: string;
  parentRoleId: string;
  permissions: userPermission[];
}
export interface UserPermissionsRequest {
  roleSlugs: string[];
}

export interface userPermission {
  id: string;
  createdDate: string;
  updatedDate: string;
  title: string;
  slug: string;
  isActive: boolean;
  description?: string;
  code: string;
}

export interface ResendOTPResponse {
  resendOtpCount: number;
  lastResendAttempt: string;
  uom: string;
  sendOtp: boolean;
  communicationUp: boolean;
  message?: string;
  timeLeft?: number;
  status: string;
  attemptsLeft: number;
  resendAttemptsLeft: number;
  loginAttemptsLeftMessage: string;
  otp: number;
}

export interface SendOTPResponse {
  mfaStatus: boolean;
  channel: OTPResponseChannel;
  mobile: OTPResponseMobile;
  email: OTPResponseEmail;

  resendOtpCount?: number;
  lastResendAttempt?: string;
  uom?: string;
  sendOtp?: boolean;
  communicationUp?: boolean;
  message?: string;
  timeLeft?: number;
  status?: string;
  attemptsLeft?: number;
  resendAttemptsLeft?: number;
  loginAttemptsLeftMessage?: string;
  otp: number;
  temptoken: string;
}

export interface OTPResponseChannel {
  isEmailAvailable: boolean;
  isPhoneAvailable: boolean;
}

export interface OTPResponseMobile {
  resendOtpCount: number;
  lastResendAttempt: string;
  message: string;
  attemptsLeft: number;
  timeLeft: number;
  resendAttemptsLeft: number;
  uom: string;
  sendOtp: boolean;
  otp: number;
  status: string;
  communicationUp: boolean;
}

export interface OTPResponseEmail {
  resendOtpCount: number;
  lastResendAttempt: string;
  message: string;
  attemptsLeft: number;
  timeLeft: number;
  resendAttemptsLeft: number;
  uom: string;
  email?: string;
  userId?: string;
  sendOtp: boolean;
  otp: number;
  status: string;
  communicationUp: boolean;
  tempToken?: string;
}

export interface UserTokens {
  token: string;
  refreshToken: string;
  id?: string;
  workspaceId?: string;
  workspaceRoles?: string[];
  cfaIDs?: string[];
  inviteUserType?: string;
}

export interface FCMTokenRequest {
  appName: AppName;
  deviceId?: string;
  token: string;
  userId: string;
  sellerWorkspaceId?: string;
  devicePlatform: DevicePlatform;
}
export interface FCMTokenResponse {
  appName: string;
  deviceId: string;
  token: string;
  inviteId: string;
  workspaceId: string;
  devicePlatform: string;
  lastUpdatedTime: string;
  id: string;
  createdDate: string;
  updatedDate: string;
  statusCode: number;
  responseStatus: string;
}

export interface loginOnBehalfRequest {
  mobile?: string;
  workspaceId: string;
}

export interface UserSendOtpRequest {
  authChannel: string;
  mobile: string;
  userId: string;
  loginType?: string;
}

export interface UserSendOtpResponse {
  message: string;
  status: string;
  otp: string;
}

export enum APP_IDS {
  CLAIM_APP = 'CLMAPP01',
  LEDGERS_APP = 'LERAPP01',
  ORDERS_APP = 'ORDAPP01',
  PRODUCT_APP = 'PRODUCTAPP',
  SCHEME_APP = 'SCHEMEAPP',
}

export interface UserSubscriptionResponse {
  appId: APP_IDS;
  isAvailable: boolean;
  name: string;
}
export interface UserSubscriptionRequest {
  workspaceId: string;
  purpose?: string;
}

export interface SubscriptionRequest {
  workspaceId: string;
  appId: APP_IDS;
}
export interface SubscriptionResponse {
  id: string;
  createdDate: string;
  updatedDate: string;
  workspaceId: string;
  appId: string;
  channelName: any;
  configs: any;
}

export interface Role {
  id: string;
  title: string;
  slug: string;
  description: string;
  permissions: Permission[]; // Assuming permissions is an array of strings
}

export type EntityViewType = 'dataView' | 'dataCreate' | 'dataEdit';

export interface GetWorkspaceRolesResponse {
  roles: Role[];
}

interface Address {
  formatted: string;
  state: string;
  country: string;
  city: string;
  postcode: string;
}

interface Workspace {
  id: string;
  spaceName: string;
  code: string | null;
  location: [number, number] | null;
  address: Address | null;
  userId: string;
  createdDate: string;
  updatedDate: string;
  updatedBy: string | null;
  profilePic: string | null;
  timeZone: string;
  isBuyer: boolean;
  isSeller: boolean;
  defaultCurrencyCode: string;
  isoLanguageCode: string | null;
  isoCountryCode: string;
}

interface WorkspaceRole {
  id: string;
  createdDate: string;
  updatedDate: string;
  title: string;
  slug: string;
  isActive: boolean;
  description: string;
  isSeller: boolean;
  isBuyer: boolean;
  parentRoleId: string | null;
}

export interface WorkspaceRoleEntry {
  startDate: string;
  endDate: string;
  id?: string;
  cfaId: string | null;
  userType: string;
  workspace: Workspace;
  workspaceRole: WorkspaceRole;
}

export interface MappedWorskpaceForSupportResponse {
  id: string;
  firstName: string;
  lastName: string | null;
  email: string;
  mobile: string;
  role: string;
  profilePic?: string;
  workspaceRoles: WorkspaceRoleEntry[];
  statusCode?: number;
  responseStatus?: string;
}

export interface PrincipalUser {
  id: string;
  firstName: string;
  lastName: string;
  profilePic?: string;
  email: string;
  mobile: string;
  gst: string;
}

export interface WorkspaceAutRequest {
  workspaceId: string;
  purpose?: string;
}

export enum TrackEventType {
  ADD_PHOTO = 'add_photo',
  ADD_CAMERA = 'add_camera',
  ADD_FILES = 'add_files',
  CAMPAIGN_DETAILS = 'campaign_details',
  CAMPAIGN_PRODUCT_LIST = 'campaign_product_list',
  CAMPAIGN_PUBLISH = 'campaign_publish',
  CAMPAIGN_REPUBLISH = 'campaign_re-publish',
  CAMPAIGN_SEARCH = 'campaign_search',
  CLEAR_CALENDAR = 'clear_calendar',
  CLICKS_ADD_PRODUCT = 'clicks_add_product',
  CLICKS_ADD_MEMBER = 'clicks_add_member',
  CLICKS_CAMPAIGN = 'clicks_campaign',
  CLICKS_CHECKOUT_PRODUCTS = 'clicks_checkout_products',
  CLICKS_CREATE_ORDER = 'clicks_create_order',
  CLICKS_DETAILS = 'clicks_details',
  CLICKS_DOWNLOAD = 'clicks_download',
  CLICKS_ON_CART = 'clicks_on_cart',
  CLICKS_ON_ORDER_ACCEPT = 'clicks_on_order_accept',
  CLICKS_ON_ORDER_CANCEL = 'clicks_on_order_cancel',
  CLICKS_ON_ORDER_DETAILS = 'clicks_on_order_details',
  CLICKS_ON_ORDER_SUBMIT = 'clicks_on_order_submit',
  CLICKS_ORDER_STATUS = 'clicks_order_status',
  CLICKS_SUB_PRODUCT = 'clicks_sub_product',
  CLICKS_TAGS = 'clicks_tags',
  CLICK_ADD = 'click_add',
  CLICK_CALENDAR = 'click_calendar',
  CLICK_CALENDAR_APPLY = 'click_calendar_apply',
  CLICK_CHECKINS = 'click_checkins',
  CLICK_CLOSE_ISSUE = 'click_close_issue',
  CLICK_HOME = 'click_home',
  CLICK_INVOICE = 'click_invoice',
  CLICK_INVOICES = 'click_invoices',
  CLICK_ISSUES = 'click_issues',
  CLICK_LEDGER = 'click_ledger',
  CLICK_ORDERS = 'click_orders',
  CLICK_ON_DAYBOOK = 'click_on_daybook',
  CLICK_ON_ORDERS = 'click_on_orders',
  CLICK_PAYMENTS = 'click_payments',
  CLICK_PLUS = 'click_plus',
  CLICK_PRODUCTS = 'click_products',
  CLICK_PROMOTIONS = 'click_promotions',
  CLICK_SCHEMES = 'click_schemes',
  CLICK_THREADS = 'click_threads',
  CLICK_VIEW_LEADERBOARD = 'click_view_leaderboard',
  CUSTOMER_PROFILE_OPEN = 'customer_profile_open',
  LOG_IN = 'log_in',
  LOG_OUT = 'log_out',
  OPEN_ISSUE = 'open_issue',
  SEARCH = 'search',
  SELECTS_CAMPAIGN = 'selects_campaign',
  SELECTS_CATALOGUE_AND_ORDER = 'selects_catalogue_&_order',
  SELECTS_CHOOSE_FROM_DEVICE = 'selects_choose_from_device',
  SELECTS_PRODUCTS = 'selects_products',
  SELECTS_TAKE_PHOTO = 'selects_take_photo',
  SELECTS_UPLOAD_CSV_FILE = 'selects_upload_csv_file',
  SELECT_MEMBER_FROM_LIST = 'select_member_from_list',
  SEND_INVOICES = 'send_invoices',
  SEND_LEDGER = 'send_ledger',
  SEND_PAYMENT_REQUEST = 'send_payment_request',
  SEND_PRICE_ENQUIRY = 'price_enquiry',
  SEND_SCHEMES = 'send_schemes',
  SESSION_END = 'session_end',
  SESSION_START = 'session_start',
  SIGN_UP = 'user_register',
  UPLOAD_INVOICE = 'upload_invoice',
  UPLOAD_PAYMENT = 'upload_payment',
  VIEW_CLOSE_ISSUES = 'view_close_issues',
  VIEW_CUSTOMERS = 'view_customers',
  VIEW_CUSTOMERS_ENGAGED = 'view_customers_Engaged',
  VIEW_CUSTOMERS_FAILED = 'view_customers_failed',
  VIEW_CUSTOMERS_ORDERED = 'view_customers_ordered',
  VIEW_NOTIFICATIONS = 'view_notifications',
  WAPP_INVITE_CLICKED = 'wa_invite_clicked',
  WAPP_INVITE_SHARED = 'wa_invite_shared',
}

export interface TrackEventRequest {
  eventType: TrackEventType;
}

interface workspace {
  id: string;
  spaceName: string;
  code: string;
  location: number[];
  address: any;
  userId: string;
  createdDate: string;
  updatedDate: string;
  updatedBy: any;
  profilePic: any;
  timeZone: string;
  isBuyer: boolean;
  isSeller: boolean;
  defaultCurrencyCode: string;
  isoLanguageCode: any;
  isoCountryCode: string;
}

interface workspacesDetails {
  id: string;
  clientId: string;
  clientSecret: string;
  token: string;
  createdDate: string;
  updatedDate: string;
  isDisable: boolean;
  workspaceId: string;
  workspace: workspace;
}

export interface WorkspaceAutResponse {
  workspacesDetails: workspacesDetails;
  statusCode: number;
  responseStatus: string;
}
