import { ColumnConfig, CfaReturnDetailsColumnKey } from '../../model';

export const cfaDraftReturnConfig: Record<
  CfaReturnDetailsColumnKey,
  ColumnConfig
> = {
  batchNumber: { name: 'Batch Number' },
  title: {
    name: 'Product Title & SKU Code',
    field: 'multiLine',
  },
  returnCoNo: {
    name: 'Return Co No.',
    status: ['CREDIT_NOTE_GENERATED', 'ACKNOWLEDGED_BY_COMPANY'],
  },
  pts: {
    name: 'P. PTS',
    sortField: 'pts',
    alignment: 'flex-end',
  },
  invoiceNumber: {
    name: 'Invoice Number',
  },
  categoryExpiry: { name: 'Category & Expiry Date', field: 'multiLine' },
  quantity: {
    name: 'Return Qty',
    alignment: 'flex-end',
    sortField: 'qty',
  },
  creditNoteNumber: {
    name: 'Credit Note Number',
    field: 'creditNoteNumber',
    status: ['CREDIT_NOTE_GENERATED'],
  },
  creditNoteDate: {
    name: 'Credit Note Date',
    field: 'creditNoteDate',
    status: ['CREDIT_NOTE_GENERATED'],
  },
  creditNoteAmount: {
    name: 'Credit Note Amount',
    field: 'creditNoteAmount',
    alignment: 'flex-end',
    status: ['CREDIT_NOTE_GENERATED'],
  },
  type: { name: 'Type', sortField: '' },
  status: { name: 'Status' },
  cfaRemarks: { name: 'CFA Remarks', field: 'toolTip' },
};
