import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Table } from 'react-bootstrap';
import {
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { ThemeContext } from '../contexts/ThemeContext';
import './styles.css';
import _ from 'lodash';

export interface RowData {
  subRows: any[];
  index: number;
  id: string;
  getcanExpand: () => boolean;
  depth: number;
  getParentRow: () => any;
  toggleExpanded: () => void;
  getIsExpanded: () => boolean;
  original: any;
}

const getStylesForExpdableRow = ({
  isExpandable,
  rowDepth,
  currentRowIndex,
  totalSubRowCounts,
}: {
  isExpandable: boolean;
  rowDepth: number;
  currentRowIndex: number;
  totalSubRowCounts: number;
}) => {
  if (!isExpandable && rowDepth !== 1) return {};
  const allStyles = {
    padding: 10,
    borderLeft: '1px solid #DFE0E0',
    borderRight: '1px solid #DFE0E0',
  };

  const topStyles =
    currentRowIndex === 0
      ? {
          borderTopLeftRadius: '4px',
          borderTopRightRadius: '4px',
          borderTop: '1px solid #DFE0E0',
        }
      : {};

  const bottomStyles =
    currentRowIndex === totalSubRowCounts - 1
      ? {
          borderBottomLeftRadius: '4px',
          borderBottomRightRadius: '4px',
          borderBottom: '1px solid #DFE0E0',
        }
      : {};

  return {
    ...allStyles,
    ...topStyles,
    ...bottomStyles,
  };
};

interface AllInclusiveTableProps {
  columns?: any;
  data?: any;
  ExpandableComponent?: any;
  isExpandable?: any;
  colSpan?: number;
  headerPaddingLeft?: any;
  rowPaddingLeft?: any;
  wrapperClassName?: any;
  containerRef?: any;
  currentActiveIndex?: any;
  listItemRefs?: any;
  isFixed?: any;
  customStyles?: any;
  TableStyles?: any;
  minHeight?: any;
  maxHeight?: any;
  tableContainerStyles?: any;
  onScrollList?: any;
  overrideCellWidth?: boolean;
  isLastColumnFixed?: boolean;
  modifiedRowIndices?: any[];
  showFixedColumnShadow?: boolean;
}

export const AllInclusiveTable: React.FC<AllInclusiveTableProps> = ({
  columns,
  data,
  ExpandableComponent = null,
  isExpandable = false,
  wrapperClassName,
  containerRef = null,
  currentActiveIndex = -1,
  listItemRefs,
  isFixed,
  customStyles,
  TableStyles,
  minHeight = '80vh',
  maxHeight = '85vh',
  tableContainerStyles,
  onScrollList,
  overrideCellWidth,
  isLastColumnFixed,
  headerPaddingLeft,
  rowPaddingLeft,
  modifiedRowIndices = [],
  showFixedColumnShadow,
  colSpan,
}) => {
  if (!containerRef) {
    containerRef = useRef<HTMLTableElement>(null);
  }
  const currentTheme = useContext(ThemeContext);
  const { theme } = currentTheme;
  const [zoomLevel, setZoomLevel] = useState(100);
  const [showLastColumnShadow, setShowLastColumnShadow] = useState(false);
  const tableInstance = useReactTable({
    columns,
    data,
    initialState: {
      expanded: true,
    },
    getSubRows: (row: RowData) => row.subRows || [],
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    autoResetExpanded: false,
  });

  const getRowStyle = (evenRow: boolean, row: any, isSelected: boolean) => {
    const styleObj = {
      // backgroundColor: evenRow ? 'transparent' : theme.table.secondRow,
      ...getStylesForExpdableRow({
        isExpandable,
        rowDepth: row.depth,
        currentRowIndex: row.index,
        totalSubRowCounts: row.getParentRow()?.subRows.length || 0,
      }),
      ...(row?.original?.style ? row?.original.style : {}),
      border: isSelected ? '1px solid #3A94F5' : '1px solid transparent',
    };

    return styleObj;
  };

  const getCellStyle = (
    evenRow: boolean,
    isSelected: boolean,
    row: any,
    index?: number,
    cellIndex?: number,
    modifiedIndices?: number[],
    totalCells?: number
  ): React.CSSProperties => {
    if (!totalCells) {
      totalCells = 0;
    }
    if (!index && index !== 0) {
      index = -1;
    }

    const styleObj: React.CSSProperties = {
      textAlign: 'left',
      paddingLeft: 12,
      paddingRight: overrideCellWidth ? 12 : 20,
      borderRadius:
        modifiedIndices?.includes(index) && cellIndex === 0
          ? '4px 0 0 4px'
          : '',
      borderLeft:
        modifiedIndices?.includes(index) && cellIndex === 0
          ? '4px solid #32A7E8'
          : '',
      backgroundColor: row?.original?.isParent
        ? '#cbdceb'
        : isSelected
        ? '#E1F9DC'
        : evenRow
        ? theme.dropDown.background
        : theme.table.secondRow,
      ...(row?.original?.style ? row?.original.style : {}),
      borderTop: isSelected ? '1px solid #3A94F5' : '1px solid transparent',
      borderBottom: isSelected ? '1px solid #3A94F5' : '1px solid transparent',
    };
    const boxShadowStyle = isSelected
      ? { boxShadow: '0px 2px 0px #DDDDDD' }
      : {};
    const borderStyle = isSelected
      ? {
          borderLeft:
            cellIndex === 0 ? '1px solid #3A94F5' : '1px solid transparent',
          borderRight:
            cellIndex === totalCells - 1
              ? '1px solid #3A94F5'
              : '1px solid transparent',
        }
      : {};
    const borderRadiusStyle = isSelected
      ? {
          borderTopLeftRadius: cellIndex === 0 ? '4px' : '0',
          borderBottomLeftRadius: cellIndex === 0 ? '4px' : '0',
          borderTopRightRadius: cellIndex === totalCells - 1 ? '4px' : '0',
          borderBottomRightRadius: cellIndex === totalCells - 1 ? '4px' : '0',
        }
      : {};

    return {
      ...styleObj,
      ...boxShadowStyle,
      ...borderStyle,
      ...borderRadiusStyle,
    };
  };

  const getRowFooter = (
    evenRow: boolean,
    isSelected: boolean,
    index: number,
    row: any
  ) => {
    if (row.original.footer && !_.isEmpty(row.original.footer)) {
      return (
        <tr
          key={`${row.id}_extra`}
          style={getRowStyle(evenRow, row, isSelected)}
          ref={(ref) =>
            listItemRefs ? (listItemRefs.current[index] = ref) : ref
          }
        >
          <td
            style={getCellStyle(evenRow, isSelected, row)}
            colSpan={row.getVisibleCells().length}
          >
            {row.original.footer}
          </td>
        </tr>
      );
    }
    return null;
  };

  const containerClassName = useMemo(() => {
    if (wrapperClassName?.includes('fixActionColumn')) {
      if (showLastColumnShadow) {
        return wrapperClassName + ' cellShadow';
      } else {
        return wrapperClassName?.replace('cellShadow', '');
      }
    }
    return wrapperClassName;
  }, [showLastColumnShadow]);

  const handleScroll = () => {
    const element = containerRef?.current;
    if (element) {
      const isAtRightEnd =
        element.scrollLeft + element.clientWidth >= element.scrollWidth;
      if (isAtRightEnd) {
        setShowLastColumnShadow(false);
      } else if (showFixedColumnShadow) {
        setShowLastColumnShadow(true);
      }
    }
  };

  useEffect(() => {
    const handleResize = () => {
      const newZoomLevel = (window.innerWidth / window.screen.width) * 100;
      setZoomLevel(newZoomLevel);
    };
    handleResize(); // Initial calculation
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const tableElement = containerRef?.current;
    if (tableElement) {
      tableElement.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (tableElement) {
        tableElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    const isScrollPresent =
      containerRef?.current?.scrollWidth > containerRef?.current?.clientWidth;
    if (isScrollPresent && showFixedColumnShadow) {
      setShowLastColumnShadow(true);
    } else {
      setShowLastColumnShadow(false);
    }
  }, [data]);

  return (
    <div
      style={{
        height: data?.length ? '100%' : 'auto',
        width: isExpandable ? '96%' : '100%',
        marginLeft: isExpandable && '2%',
        overflow: 'auto',
        ...tableContainerStyles,
        // ...zoomLevelStyles,
      }}
      className={containerClassName}
      ref={containerRef}
      onScroll={onScrollList}
    >
      <Table
        className="generic-table"
        style={{
          borderSpacing: '0',
          width: '100%',
          ...TableStyles,
        }}
      >
        <thead
          style={{
            position: 'sticky',
            top: 0,
            zIndex: 99,
            backgroundColor: theme.dropDown.background,
          }}
        >
          {tableInstance.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header, index) => (
                <th
                  key={header.id}
                  style={{
                    textAlign: 'left',
                    paddingLeft:
                      index === 0
                        ? headerPaddingLeft
                          ? headerPaddingLeft
                          : 12
                        : 12,
                    paddingRight: overrideCellWidth ? 12 : 20,
                    borderTop:
                      customStyles?.borderTop ??
                      `2px solid ${theme.table.highlight}`,
                    borderBottom:
                      customStyles?.borderBottom ??
                      `2px solid ${theme.table.highlight}`,
                    minWidth: overrideCellWidth ? 'fit-content' : 100,
                    backgroundColor: theme.dropDown.background,
                  }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {tableInstance.getRowModel().rows.map((row, index) => {
            const evenRow = index % 2 === 0;
            const isSelected = currentActiveIndex === index;
            const fixedRowStyles = index === 0 &&
              isFixed && { position: 'sticky', zIndex: 9999, top: 35 };
            const fixedLastRowStyles = index ===
              tableInstance.getRowModel().rows.length - 1 &&
              isLastColumnFixed && {
                position: 'sticky',
                zIndex: 9999,
                top: 35,
              };

            return row.getCanExpand() ? (
              <tr key={row.id}>
                <td colSpan={colSpan && colSpan}>
                  <ExpandableComponent row={row} />
                </td>
              </tr>
            ) : (
              <>
                <tr
                  key={row.id}
                  style={{
                    ...getRowStyle(evenRow, row, isSelected),
                    ...fixedRowStyles,
                    ...fixedLastRowStyles,
                  }}
                  ref={(ref) =>
                    listItemRefs ? (listItemRefs.current[index] = ref) : ref
                  }
                >
                  {row.getVisibleCells().map((cell, cellIndex) => (
                    <td
                      key={cell.id}
                      style={getCellStyle(
                        evenRow,
                        isSelected,
                        row,
                        index,
                        cellIndex,
                        modifiedRowIndices,
                        row.getVisibleCells().length
                      )}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
                {getRowFooter(evenRow, isSelected, index, row)}
              </>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default AllInclusiveTable;
