import { ChatActionCreateInvoiceColumnKey, ColumnConfig } from '../../model';

export const chatActionCreateInvoiceConfig: Record<
  ChatActionCreateInvoiceColumnKey,
  ColumnConfig
> = {
  skuCode: {
    name: 'SKU Code',
    isHyperlink: true,
  },
  title: {
    name: 'Product Title',
    field: 'textWidOfferTooltip',
  },
  pts: {
    name: 'PTS',
    alignment: 'flex-end',
  },
  orderedQty: {
    name: 'Ordered Qty',
    alignment: 'flex-end',
  },
  invoicedQty: {
    name: 'Invoiced Qty',
    field: 'component',
    alignment: 'flex-end',
  },
  invoicedAmt: {
    name: 'Invoiced Amt.',
    alignment: 'flex-end',
  },
};
