import React from 'react';
import { Avatar, styled } from '@mui/material';
import './zo-avatar.scss';

export enum AvatarSizeVariants {
  SMALL = 'small',
  LARGE = 'large',
}

interface ZoAvatarProps {
  title: string;
  variant?: AvatarSizeVariants;
  source?: string;
  shape?: 'round' | 'square'; 
}

const StyledAvatar = styled(Avatar)(({ shape }: { shape: 'round' | 'square' }) => ({
  borderRadius: shape === 'round' ? '50%' : '8px',
}));

export const ZoAvatar: React.FC<ZoAvatarProps> = ({
  title,
  variant = AvatarSizeVariants.LARGE,
  source,
  shape = 'round',
}) => {
  const backgroundColors = [
    '#F28B82', '#FDD663', '#AECBFA', '#81C995',
    '#FFD966', '#F4B400', '#BB86FC', '#03DAC6',
    '#FF8A80', '#B39DDB',
  ];

  // Generate a background color based on the title
  const getBackgroundColor = (str: string): string => {
    const hash = Array.from(str).reduce((acc, char) => acc + char.charCodeAt(0), 0);
    return backgroundColors[hash % backgroundColors.length];
  };

  const bgColor = getBackgroundColor(title);

  const avatarSize = variant === AvatarSizeVariants.SMALL ? 20 : 32; 
  const fontSize = variant === AvatarSizeVariants.SMALL ? 8 : 12; 

  // Logic to determine the initials
  const getInitials = (name: string): string => {
    const words = name.trim().split(/\s+/); // Split by spaces
    return words.length > 1
      ? `${words[0][0]}${words[1][0]}`.toUpperCase() // First letter of the first and second word
      : name.slice(0, 2).toUpperCase(); // First two letters of the single word
  };

  const initials = getInitials(title);

  return source ? (
    <StyledAvatar
      src={source}
      alt={title}
      shape={shape}
      sx={{
        width: avatarSize,
        height: avatarSize,
      }}
    />
  ) : (
    <StyledAvatar className='font-regular'
      shape={shape}
      sx={{
        width: avatarSize,
        height: avatarSize,
        backgroundColor: bgColor,
        fontSize,
        color: '#000000',
        textTransform: 'uppercase',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily:'Segoe-UI',
      }}
    >
      {initials}
    </StyledAvatar>
  );
};