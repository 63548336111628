import { SVGS } from '@zonofi/zono-money-design-system';
import React from 'react';
import './moq-error-snackbar.scss';
interface MoqErrorSnackBarProps {
  number: number;
}
export const MoqErrorSnackBar: React.FC<MoqErrorSnackBarProps> = ({
  number,
}) => {
  return (
    <div className="font-primary moq-error-snackbar">
      <img src={SVGS.ErrorIconRed} alt="error" />
      Quantity Cannot be less than {number} MOQ
    </div>
  );
};
