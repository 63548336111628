import { elementInViewport } from '@zonofi/common';
import {
  WEBROUTES,
  dispatch,
  useAppSelector,
  useCart,
} from '@zonofi/zono-money-helpers';
import {
  CatalogListData,
  CatalogueOrderSummary,
  CatalogueView,
  CategoryFilterItem,
  ConfigModule,
  LineInOrder,
  POLineData,
  getCustomerInvite,
  useAddItemToActiveOrderMutation,
  useGetCampaignProductsMutation,
  useGetProductsMutation,
  useLazyGetCampaignDetailsV2Query,
  useGetConfigDetailsMutation,
  useLazyPriceListDetailsQuery,
  useGetOrderDetailsMutation,
  useEditOrderMutation,
  useGetTrackPODetailsMutation,
  getCatalogPreference,
  updateCatalogPreference,
} from '@zonofi/zono-money-store';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

export const useCatalogScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const lastItemRef = useRef<null | HTMLDivElement>(null);

  const isCatalogue = location?.pathname?.includes('catalogue');
  const isPriceList = location?.pathname?.includes('pricelist');
  const isCampaign = location?.pathname?.includes('campaign');
  const fromSource = searchParams.get('source');
  const sourceOrderId = searchParams.get('orderId');
  const hidePrice = searchParams.get('hp');
  const inviteData = useAppSelector(getCustomerInvite);
  const workspaceId = inviteData?.seller?.workspaceId;
  const customerId = inviteData?.inviteId;

  const { campaignId, pricelistId } = params;

  const [orderSummary, setOrderSummary] = useState<CatalogueOrderSummary>({
    poFileId: undefined,
    orderId: undefined,
    skuCount: undefined,
    totalQuantity: undefined,
  });
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [showSearch, setShowSearch] = useState(false);
  const [searchKey, setSearchKey] = useState('');
  const [productsList, setProductsList] = useState<CatalogListData[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<
    CategoryFilterItem[]
  >([]);
  const [categories, setCategories] = useState<CategoryFilterItem[]>([]);
  const [error, setError] = useState({
    show: false,
    code: null,
    message: null,
  });
  const [showToastError, setShowToastError] = useState({
    show: false,
    message: '',
  });

  const {
    callCartAPI,
    cartAPIResponse,
    productsInCart,
    setProductsInCart,
    cartSummaryAPIResponse,
    onIncrement,
    onDecrement,
    onDelete,
    onChangeQuantity,
    editOrderResponse,
  } = useCart();

  const [getConfigAPI, getConfigAPIResponse] = useGetConfigDetailsMutation();
  const [getCampaignDetails, getCampaignDetailsResponse] =
    useLazyGetCampaignDetailsV2Query();
  const [getCampaignProductsAPI, getCampaignProductsAPIResponse] =
    useGetCampaignProductsMutation();
  const [addToCartAPI, addToCartAPIResponse] =
    useAddItemToActiveOrderMutation();
  const [listProductsAPI, listProductsAPIResponse] = useGetProductsMutation();
  const [pricelistDetailsAPI, pricelistDetailsAPIResponse] =
    useLazyPriceListDetailsQuery();
  const [fetchOrderDetailsAPI, fetchOrderDetailAPIResponse] =
    useGetOrderDetailsMutation();
  const [fetchPoFileDetailsAPI, fetchPoFileDetailsAPIResponse] =
    useGetTrackPODetailsMutation();
  const [addItemToSubmittedOrderAPI, addItemToSubmittedOrderAPIResponse] =
    useEditOrderMutation();
  const userCatalogueView = useAppSelector(getCatalogPreference);

  const isPoFile = useMemo(() => {
    return isNaN(Number(sourceOrderId));
  }, [sourceOrderId]);
  const catalogueView = useMemo(() => {
    if (userCatalogueView) return userCatalogueView;
    if (getConfigAPIResponse.isSuccess && getConfigAPIResponse.data) {
      return getConfigAPIResponse?.data?.config?.catalogueView;
    }
  }, [getConfigAPIResponse, userCatalogueView]);

  const isCatalogueViewToggleDisabled = useMemo(() => {
    if (getConfigAPIResponse?.isSuccess && getConfigAPIResponse?.data) {
      return (
        getConfigAPIResponse?.data?.config?.catalogueView ===
        CatalogueView.ListView
      );
    }
    return false;
  }, [getConfigAPIResponse]);
  const isCatalogueLoading = useMemo(() => {
    return (
      getCampaignDetailsResponse?.isLoading ||
      pricelistDetailsAPIResponse?.isLoading ||
      (listProductsAPIResponse?.isLoading && pageNo === 1) ||
      getConfigAPIResponse?.isLoading ||
      getCampaignProductsAPIResponse?.isLoading
    );
  }, [
    getCampaignProductsAPIResponse,
    getCampaignDetailsResponse,
    pricelistDetailsAPIResponse,
    listProductsAPIResponse,
    getConfigAPIResponse,
    pageNo,
  ]);

  const noProductsFound = useMemo(() => {
    if (isCatalogue) {
      return listProductsAPIResponse?.isSuccess && productsList?.length === 0;
    }
    if (isCampaign) {
      return (
        getCampaignProductsAPIResponse?.isSuccess && productsList?.length === 0
      );
    }
    if (isPriceList) {
      return (
        pricelistDetailsAPIResponse?.isSuccess && productsList?.length === 0
      );
    }
    return false;
  }, [
    productsList,
    listProductsAPIResponse,
    getCampaignProductsAPIResponse,
    pricelistDetailsAPIResponse,
  ]);

  const showPrice = useMemo(() => {
    if (hidePrice === 'true') return false;
    if (isCampaign) {
      if (getCampaignDetailsResponse?.data?.data?.type !== 'ORDER') {
        return false;
      } else {
        return true;
      }
    }
    if (isPriceList) {
      return true;
    }
    if (
      productsList?.length !== 0 &&
      getConfigAPIResponse?.isSuccess &&
      getConfigAPIResponse?.data
    ) {
      if (
        getConfigAPIResponse?.data?.config?.displayCataloguePrices &&
        getConfigAPIResponse?.data?.config?.displayCataloguePrices !== 'false'
      ) {
        return true;
      }
    }
    return false;
  }, [
    isCampaign,
    isPriceList,
    productsList,
    getConfigAPIResponse,
    getCampaignDetailsResponse,
    hidePrice,
  ]);

  const showImage = useMemo(() => {
    if (getConfigAPIResponse?.isSuccess && getConfigAPIResponse?.data) {
      if (
        getConfigAPIResponse?.data?.config?.catalogueView &&
        getConfigAPIResponse?.data?.config?.catalogueView !==
          CatalogueView.ListView
      ) {
        return true;
      }
    }
    return false;
  }, [getConfigAPIResponse]);

  const getCatalogueViewConfig = useMemo(() => {
    if (getConfigAPIResponse?.isSuccess && getConfigAPIResponse?.data) {
      if (getConfigAPIResponse?.data?.config?.catalogueView)
        return getConfigAPIResponse?.data?.config?.catalogueView;
    }
  }, [getConfigAPIResponse]);

  const onOpenMediaSearch = () => {
    navigate(
      `${WEBROUTES.CATALOGUE_IMAGE_SEARCH}${showPrice ? '' : '?hp=true'}`
    );
  };

  const onScroll = () => {
    if (lastItemRef && lastItemRef?.current) {
      const reachedBottom = elementInViewport(lastItemRef?.current);
      if (
        reachedBottom &&
        !listProductsAPIResponse?.isLoading &&
        !getCampaignProductsAPIResponse?.isLoading &&
        !cartAPIResponse?.isLoading &&
        !isPageLoading
      ) {
        console.log('reached');
        setPageNo(pageNo + 1);
        setIsPageLoading(true);
      }
    }
  };

  const onClickCategory = (item: CategoryFilterItem) => {
    setSelectedCategories((prevState) => {
      let temp = [...prevState];

      const index = temp.findIndex(
        (obj) => obj?.categoryId === item?.categoryId
      );
      if (index === -1) {
        temp.push(item);
      } else {
        temp.splice(index, 1);
      }
      return temp;
    });
  };

  const getName = (name: string) => {
    let words = name.split(' ');
    return words.map((word) => word.charAt(0).toUpperCase()).join('');
  };

  const toggleCatalogView = () => {
    if (userCatalogueView === undefined) {
      dispatch(
        updateCatalogPreference(
          getConfigAPIResponse.data.config.catalogueView ===
            CatalogueView.ListWithImagesView
            ? CatalogueView.ThumbnailView
            : CatalogueView.ListWithImagesView
        )
      );
    } else {
      if (userCatalogueView === CatalogueView.ListWithImagesView) {
        dispatch(updateCatalogPreference(CatalogueView.ThumbnailView));
      } else {
        dispatch(updateCatalogPreference(CatalogueView.ListWithImagesView));
      }
    }
  };
  const getProductsList = () => {
    setError({
      show: false,
      code: null,
      message: '',
    });
    listProductsAPI({
      workspaceId,
      params: {
        customerId,
        pageNo: pageNo,
        pageSize: 20,
      },
      body: {
        ...(searchKey?.length !== 0 ? { searchKey: searchKey } : {}),
        sortDirection: 'ASC',
        includeCategoryCodesList: true,
        ...(searchKey?.length === 0
          ? { categoryCodes: selectedCategories?.map((i) => i?.categoryCode) }
          : {}),
      },
    });
  };

  const getCampaignProducts = () => {
    setError({
      show: false,
      code: null,
      message: '',
    });
    if (
      getCampaignDetailsResponse?.isSuccess &&
      getCampaignDetailsResponse?.data?.data?.type
    ) {
      getCampaignProductsAPI({
        campaignId,
        customerId,
        sellerWorkspaceId: workspaceId,
        workspaceId: workspaceId,
        searchKey,
        campaignType: getCampaignDetailsResponse?.data?.data?.type,
        pageNo,
        pageSize: 20,
        // ...(searchKey?.length === 0
        //   ? { categoryCodes: selectedCategories?.map((i) => i?.categoryCode) }
        //   : {}),
      });
    }
  };

  const onSuccess = () => {
    let from = 'catalog';
    if (campaignId) from = 'campaign';
    if (pricelistId) from = 'price-list';
    // let route = `${
    //   WEBROUTES.ORDER
    // }/${orderSummary?.poFileId?.toString()}?from=${from}`;

    // if (campaignId) {
    //   route += `&campaignType=${getCampaignDetailsResponse?.data?.data?.type}`;
    // }
    let route = WEBROUTES.CART;
    if (!showPrice) {
      route += `?hp=true`;
    }
    navigate(route);
  };

  const onAddToCart = (item: CatalogListData) => {
    if (fromSource === 'order' && sourceOrderId) {
      let orderId = sourceOrderId;
      if (isPoFile) {
        orderId = fetchPoFileDetailsAPIResponse?.data?.lines
          ?.find((i) => i?.orderId)
          ?.orderId?.toString();
      }
      addItemToSubmittedOrderAPI({
        workspaceId,
        body: {
          customerId,
          lines: [
            {
              quantity: '1',
              productVariantId: item?.productVariantId,
              operator: 'add',
            },
          ],
          orderId: orderId,
        },
      });
    } else {
      addToCartAPI({
        sellerWorkspaceId: workspaceId,
        customerId,
        source: 'whatsapp',
        // source: isCampaign || cartType === 'campaign' ? 'whatsapp' : 'manual',
        ...(campaignId ? { campaignId: campaignId } : {}),
        ...(sourceOrderId ? { orderId: sourceOrderId } : {}),
        lines: [
          {
            productVariantId: item?.productVariantId,
            quantity: item?.minOrderQty,
            operator: 'add',
          },
        ],
      });
    }
  };

  const backToCart = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (isPriceList && pricelistId) {
      pricelistDetailsAPI({
        workspaceId,
        params: {
          priceListId: pricelistId,
          sellerWorkspaceId: workspaceId,
          customerId,
        },
      });
    }
  }, [pricelistId]);

  useEffect(() => {
    if (campaignId) {
      getCampaignDetails({
        workspaceId,
        params: {
          customerId,
          sellerWorkspaceId: workspaceId,
          campaignId,
        },
      });
    }
  }, [campaignId]);

  useEffect(() => {
    if (isCatalogue) {
      if (
        listProductsAPIResponse?.isSuccess &&
        listProductsAPIResponse?.data?.endRecord >=
          listProductsAPIResponse?.data?.total
      ) {
        return;
      }
      getProductsList();
    }
  }, [isCatalogue, pageNo]);

  useEffect(() => {
    if (listProductsAPIResponse?.isSuccess) {
      setIsProductOrCampaignLoading(false);
      const productsToAdd: CatalogListData[] =
        listProductsAPIResponse?.data?.products?.map((item) => {
          const product = item?.productVariants?.[0];
          const disc = Number(product?.promotions[0]?.discount);

          let discountedPrice;
          if (disc) {
            const orgPrice = product?.price / 100;
            const discAmt = (orgPrice / 100) * disc;
            discountedPrice = orgPrice - discAmt;
          }
          return {
            name: product?.name,
            productVariantId: product?.productVariantId,
            sku: product?.sku,
            minOrderQty: product?.minOrderQty,
            pts: product?.price,
            ptr: product?.PTR,
            margin: product?.PTR - product?.price,
            isAddedToCart: false,
            addedQty: null,
            lineInOrder: undefined,
            promotions: product?.promotions,
            discountedPrice: discountedPrice,
            productImage: product?.assets?.[0]?.redirectUrl || '',
            categories: product?.categories,
          };
        });

      if (fromSource === 'order') {
        if (isPoFile) {
          fetchPoFileDetailsAPI({
            workspaceId,
            body: {
              pofileId: sourceOrderId,
              customerId,
              includePromotionData: true,
            },
          });
        } else {
          fetchOrderDetailsAPI({
            workspaceId: workspaceId,
            orderId: Number(sourceOrderId),
            body: {
              includeInvoice: false,
              includeTax: false,
              includeCustomer: false,
              includePromotions: false,
              customerId,
              includePromotionData: false,
            },
          });
        }
      } else {
        callCartAPI({
          customerId,
          workspaceId,
          sellerWorkspaceId: workspaceId,
        });
      }

      if (searchKey?.length !== 0) {
        setProductsList((prevState) => {
          return [...productsToAdd];
        });
        return;
      }
      if (
        listProductsAPIResponse?.data?.categoryCodes?.length >
        categories?.length
      ) {
        setCategories(listProductsAPIResponse?.data?.categoryCodes);
      }
      if (selectedCategories?.length === 0) {
        setProductsList((prevState) => {
          return [...prevState, ...productsToAdd];
        });
      } else {
        setProductsList((prevState) => {
          return [...productsToAdd];
        });
      }
    } else if (listProductsAPIResponse?.isError) {
      console.log('error: ', listProductsAPIResponse?.error);
      if (listProductsAPIResponse?.error) {
        setProductsList([]);
        setError({
          show: true,
          code: (listProductsAPIResponse?.error as any)?.status ?? 500,
          message:
            (listProductsAPIResponse?.error as any)?.data?.message?.message ??
            'Something went wrong!',
        });
      }
    }
    setIsPageLoading(false);
  }, [listProductsAPIResponse]);
  const [isProductOrCampaignLoading, setIsProductOrCampaignLoading] =
    useState(false);
  useEffect(() => {
    setPageNo(1);
    setProductsList([]);
    setIsProductOrCampaignLoading(true);
    if (isCatalogue) {
      getProductsList();
    } else if (isCampaign) {
      getCampaignProducts();
    }
  }, [selectedCategories]);

  useEffect(() => {
    if (searchKey?.length !== 0) {
      setSelectedCategories([]);
      setPageNo(1);
    } else {
      setProductsList([]);
      if (isCatalogue) {
        getProductsList();
      } else if (isCampaign) {
        getCampaignProducts();
      }
    }
  }, [searchKey]);

  useEffect(() => {
    if (getConfigAPIResponse?.isUninitialized) {
      getConfigAPI({
        workspaceId: workspaceId,
        module: ConfigModule.CATALOGUE,
        rollback: false,
      });
    }
  }, [
    workspaceId,
    isCatalogue,
    isCampaign,
    isPriceList,
    campaignId,
    pricelistId,
    getCampaignProductsAPIResponse,
    listProductsAPIResponse,
    pricelistDetailsAPIResponse,
    getConfigAPIResponse,
  ]);

  useEffect(() => {
    if (editOrderResponse?.isLoading) {
      const pvid =
        editOrderResponse?.originalArgs?.body?.lines?.[0]?.productVariantId;
      if (pvid) {
        setProductsList((prevState) => {
          return prevState?.map((i) => {
            if (i?.productVariantId === pvid) {
              return {
                ...i,
                isLoading: true,
              };
            }
            return i;
          });
        });
      }
    } else {
      setProductsList((prevState) => {
        return prevState?.map((i) => {
          return {
            ...i,
            isLoading: false,
          };
        });
      });
    }
  }, [editOrderResponse]);

  useEffect(() => {
    if (editOrderResponse?.isSuccess) {
      if (fromSource === 'order') {
        if (isPoFile) {
          fetchPoFileDetailsAPI({
            workspaceId,
            body: {
              pofileId: sourceOrderId,
              customerId,
              includePromotionData: true,
            },
          });
        } else {
          fetchOrderDetailsAPI({
            workspaceId: workspaceId,
            orderId: Number(sourceOrderId),
            body: {
              includeInvoice: false,
              includeTax: false,
              includeCustomer: false,
              includePromotions: false,
              customerId,
              includePromotionData: false,
            },
          });
        }
      } else {
        callCartAPI({
          customerId,
          workspaceId,
          sellerWorkspaceId: workspaceId,
        });
      }
    } else if (editOrderResponse?.isError) {
      if (
        (editOrderResponse?.error as any)?.data?.message?.message ===
        'Quantity cannot be below the Minimum Order Quantity (MOQ)'
      ) {
        setProductsList((prevState) => {
          return prevState?.map((product) => {
            if (
              (editOrderResponse?.error as any)?.data?.message?.body?.lines?.[0]
                ?.productVariantId === product?.productVariantId
            ) {
              return {
                ...product,
                moqError: true,
              };
            }
            return product;
          });
        });
      } else {
        setShowToastError({
          show: true,
          message: 'Error: Something went wrong!',
        });
      }
    }
  }, [editOrderResponse]);

  useEffect(() => {
    if (addToCartAPIResponse?.isLoading) {
      const pvid =
        addToCartAPIResponse?.originalArgs?.lines?.[0]?.productVariantId;
      if (pvid) {
        setProductsList((prevState) => {
          return prevState?.map((i) => {
            if (i?.productVariantId === pvid) {
              return {
                ...i,
                isLoading: true,
              };
            }
            return i;
          });
        });
      }
    } else {
      setProductsList((prevState) => {
        return prevState?.map((i) => {
          return {
            ...i,
            isLoading: false,
          };
        });
      });
    }
  }, [addToCartAPIResponse]);

  useEffect(() => {
    if (addToCartAPIResponse?.isSuccess) {
      if (fromSource === 'order') {
        if (isPoFile) {
          fetchPoFileDetailsAPI({
            workspaceId,
            body: {
              pofileId: sourceOrderId,
              customerId,
              includePromotionData: true,
            },
          });
        } else {
          fetchOrderDetailsAPI({
            workspaceId: workspaceId,
            orderId: Number(sourceOrderId),
            body: {
              includeInvoice: false,
              includeTax: false,
              includeCustomer: false,
              includePromotions: false,
              customerId,
              includePromotionData: false,
            },
          });
        }
      } else {
        callCartAPI({
          customerId,
          workspaceId,
          sellerWorkspaceId: workspaceId,
        });
      }
    }
  }, [addToCartAPIResponse]);

  useEffect(() => {
    if (getCampaignDetailsResponse?.isSuccess) {
      getCampaignProducts();
    } else if (getCampaignDetailsResponse?.isError) {
      console.log('error: ', getCampaignDetailsResponse?.error);
      if (getCampaignDetailsResponse?.error) {
        setError({
          show: true,
          code: (getCampaignDetailsResponse?.error as any)?.status ?? 500,
          message:
            (getCampaignDetailsResponse?.error as any)?.data?.message
              ?.message ?? 'Something went wrong!',
        });
      }
    }
  }, [getCampaignDetailsResponse]);

  useEffect(() => {
    if (getCampaignProductsAPIResponse?.isSuccess) {
      setIsProductOrCampaignLoading(false);
      callCartAPI({
        customerId,
        workspaceId,
        sellerWorkspaceId: workspaceId,
      });

      const newData = getCampaignProductsAPIResponse?.data?.data?.products?.map(
        (item) => {
          let pv = item?.productVariants?.[0];
          return {
            name: pv?.name,
            productVariantId: pv?.id,
            sku: pv?.sku,
            minOrderQty: pv?.minOrderQty,
            pts: pv?.price,
            isAddedToCart: false,
            addedQty: null,
            lineInOrder: undefined,
            promotions: pv?.promotions ?? [],
            discountedPrice: 0,
            productImage: pv?.assets?.[0]?.redirectUrl || '',
            categories: pv?.categories,
          };
        }
      );
      // if (
      //   getCampaignProductsAPIResponse?.data?.data?.categoryCodes?.length >
      //   categories?.length
      // ) {
      //   setCategories(
      //     getCampaignProductsAPIResponse?.data?.data?.categoryCodes
      //   );
      // }
      // if (selectedCategories?.length === 0) {
      //   setProductsList((prevState) => {
      //     return [...prevState, ...newData];
      //   });
      // } else {
      //   setProductsList((prevState) => {
      //     return [...newData];
      //   });
      // }
      if (searchKey?.length !== 0) {
        setProductsList((prevState) => {
          return [...newData];
        });
      } else {
        setProductsList((prevState) => {
          return [...prevState, ...newData];
        });
      }
    } else if (getCampaignDetailsResponse?.isError) {
      console.log('error: ', getCampaignDetailsResponse?.error);
      if (getCampaignDetailsResponse?.error) {
        setError({
          show: true,
          code: (getCampaignDetailsResponse?.error as any)?.status ?? 500,
          message:
            (getCampaignDetailsResponse?.error as any)?.data?.message
              ?.message ?? 'Something went wrong!',
        });
      }
    }
  }, [getCampaignProductsAPIResponse]);

  useEffect(() => {
    if (pricelistDetailsAPIResponse?.isSuccess) {
      callCartAPI({
        customerId,
        workspaceId,
        sellerWorkspaceId: workspaceId,
      });
      setProductsList(
        pricelistDetailsAPIResponse?.data?.Products?.map((item) => {
          return {
            name: item?.name,
            productVariantId: item?.productVariantId,
            sku: item?.sku,
            minOrderQty: item?.minOrderQty,
            pts: item?.price,
            ptr: item?.PTR,
            margin: item?.PTR - item?.price,
            isAddedToCart: false,
            addedQty: null,
            lineInOrder: undefined,
            promotions: [],
            discountedPrice: 0,
            productImage: item?.assets?.[0]?.redirectUrl || '',
            categories: item?.categories,
          };
        })
      );
    } else if (pricelistDetailsAPIResponse?.isError) {
      console.log('error: ', pricelistDetailsAPIResponse?.error);
      if (pricelistDetailsAPIResponse?.error) {
        setError({
          show: true,
          code: (pricelistDetailsAPIResponse?.error as any)?.status ?? 500,
          message:
            (pricelistDetailsAPIResponse?.error as any)?.data?.message
              ?.message ?? 'Something went wrong!',
        });
      }
    }
  }, [pricelistDetailsAPIResponse]);

  // Function to clear errors every 2 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      if (productsList?.some((item) => item?.moqError === true)) {
        setProductsList((prevItems) => {
          return prevItems?.map((i) => {
            return {
              ...i,
              moqError: false,
            };
          });
        });
      }
    }, 2000);

    return () => clearInterval(interval);
  }, [productsList]);

  useEffect(() => {
    if (cartAPIResponse?.isSuccess) {
      let linesInOrder: POLineData[] = [];
      cartAPIResponse?.data?.files?.forEach((file) => {
        if (file?.lines) {
          linesInOrder.push(
            ...file?.lines?.map((line) => {
              return {
                ...line,
                poFileId: file?.id,
              };
            })
          );
        }
      });
      setProductsList((prevState) => {
        return prevState?.map((item) => {
          let isAddedToCart = false;
          let addedQty = 0;
          let lineInOrder: LineInOrder = null;
          let selectedOrder = linesInOrder?.filter(
            (line) => item?.productVariantId === line?.productVariantId
          )?.[0];
          if (selectedOrder) {
            isAddedToCart = true;
            addedQty = selectedOrder?.quantity;
            lineInOrder = {
              quantity: selectedOrder?.quantity,
              orderId: selectedOrder?.orderId,
              productVariantId: selectedOrder?.productVariantId,
              orderLineId: selectedOrder?.orderLineId,
              poFileId: selectedOrder?.poFileId,
            };
          }
          return {
            ...item,
            isAddedToCart: isAddedToCart,
            addedQty: addedQty,
            lineInOrder: lineInOrder,
          };
        });
      });
    }
    if (cartSummaryAPIResponse?.isSuccess) {
      setOrderSummary({
        poFileId: '',
        orderId: 0,
        skuCount: cartSummaryAPIResponse?.data?.totalSKUCount,
        totalQuantity: cartSummaryAPIResponse?.data?.totalItemsCount,
      });
    }
  }, [cartAPIResponse, productsInCart, cartSummaryAPIResponse]);

  useEffect(() => {
    if (fetchOrderDetailAPIResponse?.isSuccess) {
      setProductsList((prevState) => {
        return prevState?.map((item) => {
          let isAddedToCart = false;
          let addedQty = 0;
          let lineInOrder: LineInOrder = null;
          let selectedOrder = fetchOrderDetailAPIResponse?.data?.lines?.filter(
            (line) => item?.productVariantId === line?.productVariant?.id
          )?.[0];
          if (selectedOrder) {
            isAddedToCart = true;
            addedQty = selectedOrder?.quantity;
            lineInOrder = {
              quantity: selectedOrder?.quantity,
              orderId: selectedOrder?.orderId,
              productVariantId: selectedOrder?.productVariant?.id,
            };
          }
          return {
            ...item,
            isAddedToCart: isAddedToCart,
            addedQty: addedQty,
            lineInOrder: lineInOrder,
          };
        });
      });
    }
  }, [fetchOrderDetailAPIResponse]);

  useEffect(() => {
    if (fetchPoFileDetailsAPIResponse?.isSuccess) {
      setProductsList((prevState) => {
        return prevState?.map((item) => {
          let isAddedToCart = false;
          let addedQty = 0;
          let lineInOrder: LineInOrder = null;
          let selectedOrder =
            fetchPoFileDetailsAPIResponse?.data?.lines?.filter(
              (line) => item?.productVariantId === line?.productVariantId
            )?.[0];
          if (selectedOrder) {
            isAddedToCart = true;
            addedQty = selectedOrder?.quantity;
            lineInOrder = {
              quantity: selectedOrder?.quantity,
              orderId: selectedOrder?.orderId,
              productVariantId: selectedOrder?.productVariantId,
            };
          }
          return {
            ...item,
            isAddedToCart: isAddedToCart,
            addedQty: addedQty,
            lineInOrder: lineInOrder,
          };
        });
      });
    }
  }, [fetchPoFileDetailsAPIResponse]);

  useEffect(() => {
    if (addItemToSubmittedOrderAPIResponse?.isSuccess) {
      if (isPoFile) {
        fetchPoFileDetailsAPI({
          workspaceId,
          body: {
            pofileId: sourceOrderId,
            customerId,
            includePromotionData: true,
          },
        });
      } else {
        fetchOrderDetailsAPI({
          workspaceId: workspaceId,
          orderId: Number(sourceOrderId),
          body: {
            includeInvoice: false,
            includeTax: false,
            includeCustomer: false,
            includePromotions: false,
            customerId,
            includePromotionData: false,
          },
        });
      }
    }
  }, [addItemToSubmittedOrderAPIResponse]);

  useEffect(() => {
    if (addItemToSubmittedOrderAPIResponse?.isLoading) {
      const pvid =
        addItemToSubmittedOrderAPIResponse?.originalArgs?.body?.lines?.[0]
          ?.productVariantId;
      if (pvid) {
        setProductsList((prevState) => {
          return prevState?.map((i) => {
            if (i?.productVariantId === pvid) {
              return {
                ...i,
                isLoading: true,
              };
            }
            return i;
          });
        });
      }
    } else {
      setProductsList((prevState) => {
        return prevState?.map((i) => {
          return {
            ...i,
            isLoading: false,
          };
        });
      });
    }
  }, [addItemToSubmittedOrderAPIResponse]);

  return {
    showSearch,
    setShowSearch,
    searchKey,
    setSearchKey,
    getName,
    categories,
    setCategories,
    selectedCategories,
    setSelectedCategories,
    onClickCategory,
    productsList,
    setProductsList,
    onScroll,
    pageNo,
    error,
    lastItemRef,
    campaignId,
    getCampaignDetailsResponse,
    getCatalogueViewConfig,
    addToCartAPIResponse,
    editOrderResponse,
    listProductsAPIResponse,
    isCatalogueLoading,
    pricelistDetailsAPIResponse,
    showToastError,
    setShowToastError,
    orderSummary,
    onSuccess,
    onIncrement,
    onDecrement,
    onAddToCart,
    onDelete,
    onOpenMediaSearch,
    noProductsFound,
    fromSource,
    backToCart,
    onChangeQuantity,
    showPrice,
    showImage,
    cartAPIResponse,
    listView: catalogueView === CatalogueView.ThumbnailView,
    isCatalogueViewToggleDisabled,
    toggleCatalogView,
    isProductOrCampaignLoading,
    catalogueView,
  };
};
