import { useEffect, useState } from 'react';
import { CatalogListData, CatalogueView } from '@zonofi/zono-money-store';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { WEBROUTES } from '@zonofi/zono-money-helpers';

export const useCatalogList = (
  products: CatalogListData[],
  catalogueView: CatalogueView,
  showPrice: boolean
) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const fromSource = searchParams.get('source');

  const [quantityMap, setQuantityMap] = useState<Record<number, any>>({});
  const openProductDetails = (productVariantId: number) => {
    if (fromSource === 'order') {
      navigate(
        `${WEBROUTES.PRODUCT_DETAILS}/${productVariantId}?hideAddToCart=true${
          catalogueView === CatalogueView.ListView ? '&hImg=true' : ''
        }${!showPrice ? '&hp=true' : ''}`
      );
    } else {
      const searchParams = new URLSearchParams();
      if (catalogueView === CatalogueView.ListView) {
        searchParams.set('hImg', 'true');
      }
      if (!showPrice) {
        searchParams.set('hp', 'true');
      }
      navigate(
        `${
          WEBROUTES.PRODUCT_DETAILS
        }/${productVariantId}?${searchParams.toString()}`
      );
    }
  };

  useEffect(() => {
    let quantityMapData: Record<number, any> = {};
    products?.forEach((item) => {
      if (item?.productVariantId) {
        quantityMapData[item?.productVariantId] = item?.addedQty;
      }
    });
    setQuantityMap({ ...quantityMapData });
  }, [products]);

  return { quantityMap, setQuantityMap, openProductDetails };
};
