import { BulkHeadDivisionColumnKey, ColumnConfig } from '../model';

export const bulkHeadDivisionUploadConfig: Record<
  BulkHeadDivisionColumnKey,
  ColumnConfig
> = {
  headDivisionName: { name: 'Head Division Name' },
  headDivisionCode: { name: 'Head Division Code' },
  actions: { name: '', field: 'component' },
};
