import { MultiToastType, actionToastContent } from '../helpers';
import { Api } from '../api';
import {
  AddChannelMemberRequest,
  Channel,
  CreateChannelRequest,
  ChannelListResponse,
  ChannelListRequest,
  ChannelDetailsRequest,
  ChannelDetails,
  Folder,
  MoveChannelRequest,
  LeaveChannelRequest,
  DeleteChannelV2Request,
  AcceptChannelInviteRequest,
  GetChannelNameRequest,
  RemoveChannelMemberRequest,
  GetWhatsappTemplateRequest,
  GetWhatsappTemplateResponse,
  LogZoAIActionsRequest,
  PublishMessagePayload,
  WhatsappTemplateData,
  CheckWhatsAppSyncStatusRequest,
  PreviewWhatsAppSyncRequest,
  PreviewWhatsAppSyncResponse,
  CheckWhatsAppSyncStatusResponse,
  CommonThreadsRequest,
  CommonThreadsResponse,
  ParseFileRequest,
  SendCampaignRequest,
  SendCampaignResponse,
  PublishToBotRes,
  GenerateShortUrlRequest,
} from './model';
import {
  commsSlice,
  updateChannelMemberRoleLoader,
  upsertChannel,
} from './slice';
import { ErrorVariants, showToast } from '../toast';
import { isWeb } from '@zonofi/common';

export const communicationApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    addChannelMember: builder.mutation<void, AddChannelMemberRequest>({
      query: (arg) => ({
        url: `hub/communication-v2/api/channel/member`,
        method: 'POST',
        body: { ...arg, random: Math.random() },
      }),
      async onQueryStarted(request, { dispatch, queryFulfilled }) {
        queryFulfilled.then(() => {
          dispatch(
            communicationApi.endpoints.getChannelDetails.initiate({
              ...request,
              random: Math.random(),
              sellerWorkspaceId: request.sellerWorkspaceId,
            })
          );
          if (request?.showToast && !isWeb()) {
            if (request?.isIssueRoleChange) {
              dispatch(
                showToast({
                  message: 'Issue Role update successfuly!',
                  type: ErrorVariants.SUCCESS,
                  canDisplay: true,
                  responseStatus: 'Success',
                })
              );
            } else {
              dispatch(
                showToast({
                  message: `${request?.numOfMember} members added to thread`,
                  type: ErrorVariants.SUCCESS,
                  canDisplay: true,
                  responseStatus: 'Success',
                })
              );
            }
          }
        });
      },
    }),
    changeChannelMemberRole: builder.mutation<void, AddChannelMemberRequest>({
      query: (arg) => ({
        url: `hub/communication-v2/api/channel/member`,
        method: 'POST',
        body: { ...arg, random: Math.random() },
      }),
      async onQueryStarted(request, { dispatch, queryFulfilled }) {
        dispatch(updateChannelMemberRoleLoader(true));
        queryFulfilled.then(() => {
          dispatch(
            communicationApi.endpoints.getChannelDetails.initiate({
              ...request,
              random: Math.random(),
              sellerWorkspaceId: request.sellerWorkspaceId,
            })
          );

          if (
            request?.members &&
            request?.members.length > 0 &&
            request?.showToast
          ) {
            dispatch(
              showToast({
                message:
                  request?.members[0]?.role === 'admin'
                    ? `${request?.employeeName} is admin now.`
                    : `${request?.employeeName} is removed as admin.`,
                type: ErrorVariants.SUCCESS,
                canDisplay: true,
                responseStatus: 'Success',
              })
            );
          }
        });
      },
    }),
    removeChannelMember: builder.mutation<void, RemoveChannelMemberRequest>({
      query: (arg) => ({
        url: `hub/communication-v2/api/channel/member`,
        method: 'PUT',
        body: arg,
      }),
      async onQueryStarted(request, { dispatch, queryFulfilled }) {
        queryFulfilled.then(() => {
          dispatch(
            communicationApi.endpoints.getChannelDetails.initiate({
              ...request,
              random: Math.random(),
              sellerWorkspaceId: request.sellerWorkspaceId,
            })
          );
          if (request?.showToast && !isWeb()) {
            dispatch(
              showToast({
                message: `${
                  request?.employeeName ?? 'N.A'
                } is removed from the thread.`,
                type: ErrorVariants.SUCCESS,
                canDisplay: true,
                responseStatus: 'Success',
              })
            );
          }
        });
      },
    }),
    deleteChannelV2: builder.mutation<void, DeleteChannelV2Request>({
      query: (args) => ({
        url: `hub/communication-v2/api/channels/${args.workspaceId}?channelId=${args.channelId}`,
        method: 'DELETE',
        body: args,
      }),
      async onQueryStarted(request, { dispatch, queryFulfilled }) {
        if (request.type !== 'thread') {
          queryFulfilled.then(() => {
            dispatch(
              communicationApi.endpoints.getChannels.initiate({
                ...request,
                sellerWorkspaceId: request.sellerWorkspaceId,
              })
            );

            const actionToastContentData = actionToastContent({
              actionPerformed: 'deleted',
              entity: 'channel',
            });
            dispatch(
              commsSlice.actions.updateActionToastData({
                visibility: true,
                title: actionToastContentData.title,
                description: actionToastContentData.description,
                type: MultiToastType.SUCCESS,
              })
            );
          });
        }
      },
    }),
    deleteFolderV2: builder.mutation<void, any>({
      query: (args) => ({
        url: `hub/communication-v2/api/folder/${args.workspaceId}?folderId=${args.folderId}`,
        method: 'DELETE',
        body: args,
      }),
      async onQueryStarted(request, { dispatch, queryFulfilled }) {
        queryFulfilled.then(() => {
          dispatch(communicationApi.endpoints.getChannels.initiate(request));
          const actionToastContentData = actionToastContent({
            actionPerformed: 'deleted',
            entity: 'folder',
          });
          dispatch(
            commsSlice.actions.updateActionToastData({
              visibility: true,
              title: actionToastContentData.title,
              description: actionToastContentData.description,
              type: MultiToastType.SUCCESS,
            })
          );
        });
      },
    }),
    moveChannel: builder.mutation<void, MoveChannelRequest>({
      query: (args) => ({
        url: `hub/communication-v2/api/channels/move/${args.workspaceId}`,
        method: 'POST',
        body: args.body,
      }),
      async onQueryStarted(request, { dispatch, queryFulfilled }) {
        queryFulfilled.then(() => {
          dispatch(
            communicationApi.endpoints.getChannels.initiate({
              ...request,
              sellerWorkspaceId: request.sellerWorkspaceId,
              // added so that communication api gets triggered everytime you move a channel.
              random: Math.random(),
            })
          );
        });
        const actionToastContentData = actionToastContent({
          actionPerformed: 'moved',
          entity: 'channel',
        });
        dispatch(
          commsSlice.actions.updateActionToastData({
            visibility: true,
            title: actionToastContentData.title,
            description: actionToastContentData.description,
            type: MultiToastType.SUCCESS,
          })
        );
      },
    }),
    createChannel: builder.mutation<Channel, CreateChannelRequest>({
      query: (arg) => ({
        url: `hub/communication-v2/api/channels`,
        method: 'POST',
        body: arg,
      }),
      async onQueryStarted(request, { dispatch, queryFulfilled }) {
        queryFulfilled.then((res) => {
          if (request?.id) {
            dispatch(
              upsertChannel({
                [request?.id]: { ...res?.data },
              })
            );
          }
          dispatch(
            communicationApi.endpoints.getChannels.initiate({
              ...request,
            })
          );
          const actionToastContentData = actionToastContent({
            actionPerformed: request.id ? 'edited' : 'created',
            entity: 'channel',
          });
          request.id &&
            dispatch(
              commsSlice.actions.updateActionToastData({
                visibility: true,
                title: actionToastContentData.title,
                description: actionToastContentData.description,
                type: MultiToastType.SUCCESS,
              })
            );
        });
      },
    }),
    createFolder: builder.mutation<Folder, any>({
      query: (args) => ({
        url: `hub/communication-v2/api/folder/${args.workspaceId}`,
        method: 'POST',
        body: args.body,
      }),
      async onQueryStarted(request, { dispatch, queryFulfilled }) {
        queryFulfilled.then(() => {
          dispatch(
            communicationApi.endpoints.getChannels.initiate({
              ...request,
              sellerWorkspaceId: request.body.sellerWorkspaceId,
            })
          );
          const actionToastContentData = actionToastContent({
            actionPerformed: request.body.id ? 'edited' : 'created',
            entity: 'folder',
          });
          dispatch(
            commsSlice.actions.updateActionToastData({
              visibility: true,
              title: actionToastContentData.title,
              description: actionToastContentData.description,
              type: MultiToastType.SUCCESS,
            })
          );
        });
      },
    }),
    moveFolder: builder.mutation<Folder, any>({
      query: (args) => ({
        url: `hub/communication-v2/api/folder/move/${args.workspaceId}`,
        method: 'POST',
        body: args.body,
      }),
      async onQueryStarted(request, { dispatch, queryFulfilled }) {
        queryFulfilled.then(() => {
          dispatch(
            communicationApi.endpoints.getChannels.initiate({
              ...request,
              sellerWorkspaceId: request.body.sellerWorkspaceId,
            })
          );
          const actionToastContentData = actionToastContent({
            actionPerformed: 'moved',
            entity: 'folder',
          });
          dispatch(
            commsSlice.actions.updateActionToastData({
              visibility: true,
              title: actionToastContentData.title,
              description: actionToastContentData.description,
              type: MultiToastType.SUCCESS,
            })
          );
        });
      },
    }),
    getChannels: builder.query<ChannelListResponse, ChannelListRequest>({
      query: (payload) => {
        const {
          workspaceId,
          inviteId,
          principalWorkspaceId,
          sellerWorkspaceId,
        } = payload;
        const queryParams = [
          `inviteId=${inviteId}`,
          `sellerWorkspaceId=${sellerWorkspaceId}`,
        ];

        if (principalWorkspaceId) {
          // queryParams.push(`principalWorkspaceId=${principalWorkspaceId}`);
        }

        return {
          url: `hub/communication-v2/api/channels/${sellerWorkspaceId}?${queryParams.join(
            '&'
          )}`,
          method: 'GET',
        };
      },
    }),
    getFolders: builder.query<Folder[], ChannelListRequest>({
      query: (payload) => {
        const { workspaceId, inviteId, principalWorkspaceId } = payload;
        const queryParams = [`inviteId=${inviteId}`];

        if (principalWorkspaceId) {
          queryParams.push(`principalWorkspaceId=${principalWorkspaceId}`);
        }
        return {
          url: `hub/communication-v2/api/folder/${workspaceId}?${queryParams.join(
            '&'
          )}`,
          method: 'GET',
          params: {
            sellerWorkspaceId: principalWorkspaceId,
          },
        };
      },
    }),
    getChannelDetails: builder.query<ChannelDetails, ChannelDetailsRequest>({
      query: (payload) => {
        const {
          workspaceId,
          customerId,
          channelType,
          channelId: id,
          sellerWorkspaceId,
        } = payload;
        return {
          url: `hub/communication-v2/api/channel/member`,
          method: 'GET',
          params: {
            workspaceId,
            id,
            sellerWorkspaceId,
            customerId,
            channelType,
            random: Math.random(),
          },
        };
      },
      async onQueryStarted(request, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then(() => {
            dispatch(updateChannelMemberRoleLoader(false));
          })
          .catch((e) => console.error(e));
      },
    }),
    leaveChannel: builder.mutation<void, LeaveChannelRequest>({
      query: (arg) => {
        const { channelId, ...params } = arg;
        return {
          url: `hub/communication-v2/api/channels/leave/${channelId}`,
          method: 'GET',
          params: params,
          responseHandler: (response) => response.text(),
        };
      },
    }),
    acceptChannelInvite: builder.mutation<any, AcceptChannelInviteRequest>({
      query: (payload) => ({
        url: `hub/communication-v2/api/channels/invite`,
        method: 'POST',
        body: payload,
      }),
      async onQueryStarted(request, { dispatch, queryFulfilled }) {
        queryFulfilled.then(() => {
          dispatch(
            communicationApi.endpoints.getChannels.initiate({
              ...request,
              sellerWorkspaceId: request.sellerWorkspaceId,
            })
          );
        });
      },
    }),
    getChannelName: builder.mutation<Channel, GetChannelNameRequest>({
      query: (arg) => {
        const { channelId, ...params } = arg;
        return {
          url: `hub/communication-v2/api/channels/find/${channelId}`,
          method: 'GET',
          params: params,
        };
      },
    }),
    publishMessage: builder.mutation<void, PublishMessagePayload>({
      query(arg) {
        return {
          url: 'hub/communication-v2/api/communicate/send',
          method: 'POST',
          body: arg,
        };
      },
    }),
    publishToBot: builder.mutation<
      PublishToBotRes,
      { sellerInviteId?: string; body: any }
    >({
      query(arg) {
        return {
          url: `hub/bot/api/v1/messages?sellerWorkspaceId=${
            arg.body.sellerWorkspaceId || arg.body?.activity?.workspaceId
          }`,
          method: 'POST',
          params: { sellerInviteId: arg.sellerInviteId, random: Math.random() },
          body: arg.body.formData || arg.body,
        };
      },
    }),
    getWhatsappTemplates: builder.mutation<
      WhatsappTemplateData[],
      GetWhatsappTemplateRequest
    >({
      query: (arg) => {
        const { ...params } = arg;
        return {
          url: `hub/communication-v2/api/communicate/wa-templates`,
          method: 'GET',
          params: params,
        };
      },
      transformResponse: (
        response: WhatsappTemplateData[] | GetWhatsappTemplateResponse[]
      ) => {
        if ('data' in response[0]) {
          return (response as GetWhatsappTemplateResponse[]).map(
            (res) => res.data
          ) as WhatsappTemplateData[];
        } else return response as WhatsappTemplateData[];
      },
    }),
    forwardToCustomer: builder.mutation<void, PublishMessagePayload>({
      query(arg) {
        return {
          url: 'hub/communication-v2/api/communicate/forward',
          method: 'POST',
          body: arg,
        };
      },
    }),
    logZoAIActions: builder.mutation<any, LogZoAIActionsRequest>({
      query(arg) {
        return {
          url: `hub/bot/api/v1/zoai-actions/${arg.userId}`,
          method: 'POST',
          body: arg.body,
        };
      },
    }),
    previewWhatsAppSync: builder.query<
      PreviewWhatsAppSyncResponse,
      PreviewWhatsAppSyncRequest
    >({
      query(arg) {
        return {
          url: `hub/communication-v2/api/wa/sync/preview/${arg.sellerWorkspaceId}`,
          method: 'GET',
          params: {
            sellerWorkspaceId: arg.sellerWorkspaceId,
          },
        };
      },
    }),
    checkWhatsAppSyncStatus: builder.mutation<
      CheckWhatsAppSyncStatusResponse,
      CheckWhatsAppSyncStatusRequest
    >({
      query(arg) {
        return {
          url: 'hub/communication-v2/api/wa/sync/status',
          method: 'POST',
          body: arg,
        };
      },
    }),
    syncWhatsAppGroups: builder.mutation<any, CheckWhatsAppSyncStatusRequest>({
      query(arg) {
        return {
          url: 'hub/communication-v2/api/wa/sync',
          method: 'POST',
          body: arg,
        };
      },
      async onQueryStarted(request, { dispatch, queryFulfilled }) {
        queryFulfilled.then(() => {
          dispatch(
            communicationApi.endpoints.getChannels.initiate({
              sellerWorkspaceId: request.sellerWorkspaceId,
              workspaceId: request.sellerWorkspaceId,
            })
          );
        });
      },
    }),

    getCommonThreads: builder.query<
      CommonThreadsResponse,
      CommonThreadsRequest
    >({
      query(payload) {
        return {
          url: `hub/communication-v2/api/threads/commonThreads`,
          method: 'GET',
          params: { ...payload, random: Math.random() },
        };
      },
    }),
    parseFile: builder.mutation<string, ParseFileRequest>({
      query(arg) {
        const { sellerWorkspaceId, body } = arg;
        return {
          url: `hub/bot/api/v1/parse-file`,
          method: 'POST',
          params: { sellerWorkspaceId },
          body: body,
        };
      },
      transformResponse: (response: { parsedText: string }) => {
        return response.parsedText;
      },
    }),
    sendCampaigns: builder.mutation<SendCampaignResponse, SendCampaignRequest>({
      query({ body, sellerWorkspaceId }) {
        return {
          url: `hub/bot/api/v1/type-bot-messages`,
          method: 'POST',
          params: { sellerWorkspaceId },
          body: body,
        };
      },
    }),
    generateShortUrl: builder.mutation<any, GenerateShortUrlRequest>({
      query({ body, sellerWorkspaceId }) {
        return {
          url: `hub/communication-v2/api/shorturl/${sellerWorkspaceId}`,
          method: 'POST',
          params: { sellerWorkspaceId },
          body: body,
        };
      },
    }),
    getUserChannelGroups: builder.query<
      string[],
      { sellerWorkspaceId: string }
    >({
      query(arg) {
        return {
          url: `hub/communication-v2/api/channelGroup`,
          method: 'GET',
          params: arg,
        };
      },
    }),
  }),
});

export const {
  useAddChannelMemberMutation,
  useCreateChannelMutation,
  useLazyGetChannelsQuery,
  useLazyGetChannelDetailsQuery,
  useChangeChannelMemberRoleMutation,
  useCreateFolderMutation,
  useLazyGetFoldersQuery,
  useDeleteChannelV2Mutation,
  useMoveChannelMutation,
  useDeleteFolderV2Mutation,
  useRemoveChannelMemberMutation,
  useLeaveChannelMutation,
  useMoveFolderMutation,
  useAcceptChannelInviteMutation,
  useGetChannelNameMutation,
  usePublishMessageMutation,
  usePublishToBotMutation,
  useGetWhatsappTemplatesMutation,
  useForwardToCustomerMutation,
  useLogZoAIActionsMutation,
  useLazyPreviewWhatsAppSyncQuery,
  useCheckWhatsAppSyncStatusMutation,
  useSyncWhatsAppGroupsMutation,
  useLazyGetCommonThreadsQuery,
  useParseFileMutation,
  useSendCampaignsMutation,
  useGenerateShortUrlMutation,
  useLazyGetUserChannelGroupsQuery,
} = communicationApi;
