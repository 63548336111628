import { Api } from '../api/root-api';
import {
  AssignDivisionRequest,
  CreateDivision,
  DivisionResponse,
  DivisionCustomer,
  DivisionTeamMember,
  Division,
  HeadDivision,
  CreateHeadDivision,
} from './model';
import { ListInputItemOptions, addListInputItems } from '../filter';
import { getQueryParams } from '../helpers';
import { updateDivisionData } from './slice';

export const divisionApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    getDivisions: builder.query<
      DivisionResponse,
      {
        workspaceId: string;
        id?: string;
        searchKey?: string;
        pageNo?: number;
        pageSize?: number;
        sortDirection?: string;
        sortBy?: string;
        headDivisionCode?: string;
        isNoCache?: boolean;
        includeHeadDivision?:boolean;
      }
    >({
      query(arg) {
        const { workspaceId, isNoCache, ...params } = arg;
        const queryParams = getQueryParams({
          ...params,
          sellerWorkspaceId: workspaceId,
          includeHeadDivision:true,
        });
        return {
          url: `hub/orgs/api/division?workspaceId=${workspaceId}&${queryParams}`,
          method: 'GET',
          ...(isNoCache
            ? {
                headers: {
                  'Cache-control': 'no-cache',
                },
              }
            : {}),
        };
      },
      async onQueryStarted(request, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(updateDivisionData(data));
        } catch (err) {
          console.log(err, 'dispatchErr');
        }
      },
      providesTags: ['divsionList'],
    }),
    getFilterDivisions: builder.query<
      DivisionResponse,
      {
        workspaceId: string;
        id?: string;
        searchKey?: string;
        pageNo?: number;
        pageSize?: number;
        dispatchFilter?: boolean;
        sortDirection?: string;
        sortBy?: string;
        eitherOr?: boolean;
        includeHeadDivision?:boolean;
      }
    >({
      query(arg) {
        const { workspaceId, ...params } = arg;
        const queryParams = getQueryParams({
          ...params,
          sellerWorkspaceId: workspaceId,
          includeHeadDivision:true
        });
        return {
          url: `hub/orgs/api/division?workspaceId=${workspaceId}&${queryParams}`,
          method: 'GET',
        };
      },
      providesTags: ['divsionList'],
      async onQueryStarted(request, { dispatch, queryFulfilled }) {
        queryFulfilled.then((response) => {
          if (request?.dispatchFilter) {
            const inputItems = response?.data?.divisions?.map((x) => {
              return {
                id: x.id,
                value: x.name,
                selected: false,
                eitherOr: request.eitherOr ? request.eitherOr : false,
              };
            });
            dispatch(
              addListInputItems({
                inputItems: inputItems,
                key: ListInputItemOptions.divisions,
              })
            );
          }
        });
      },
    }),
    getDivisionCustomers: builder.query<
      DivisionCustomer[],
      { workspaceId: string; id: string }
    >({
      query(arg) {
        return {
          url: `division/${arg.id}/customers`,
          params: { workspaceId: arg.workspaceId },
          method: 'GET',
        };
      },
    }),
    getDivisionTeamMembers: builder.query<
      DivisionTeamMember[],
      { divisionId: string }
    >({
      query(arg) {
        return {
          url: `division/team-members`,
          method: 'GET',
          params: arg,
        };
      },
    }),
    createOrUpdateDivision: builder.mutation<
      Division,
      { workspaceId: string; body: CreateDivision }
    >({
      query(arg) {
        return {
          url: `hub/orgs/api/division`,
          body: arg?.body,
          params: { sellerWorkspaceId: arg?.workspaceId },
          method: 'POST',
        };
      },
      invalidatesTags: ['divsionList'],
    }),
    assignCustomerToDivision: builder.mutation<
      DivisionCustomer,
      AssignDivisionRequest
    >({
      query(arg) {
        return {
          url: `division/customer/add`,
          body: arg,
          method: 'POST',
        };
      },
    }),
    assignTeamMemberToDivision: builder.mutation<
      DivisionTeamMember,
      AssignDivisionRequest
    >({
      query(arg) {
        return {
          url: `hub/orgs/api/division/team-member/add?sellerWorkspaceId=${arg.workspaceId}`,
          body: arg,
          method: 'POST',
        };
      },
      invalidatesTags: ['TeamInvites'],
    }),
    getHeadDivisions: builder.query<HeadDivision[], string>({
      query(arg) {
        return {
          url: `hub/orgs/api/headDivision/${arg}`,
          method: 'GET',
          params: { sellerWorkspaceId: arg },
        };
      },
    }),
    createHeadDivision: builder.mutation<
      any,
      {
        workspaceId: string;
        body: CreateHeadDivision;
      }
    >({
      query(args) {
        return {
          url: `hub/orgs/api/headDivision/${args?.workspaceId}`,
          method: 'POST',
          params: { sellerWorkspaceId: args?.workspaceId },
          body: args?.body,
        };
      },
    }),
  }),
});

export const {
  useLazyGetDivisionsQuery,
  useLazyGetDivisionCustomersQuery,
  useLazyGetDivisionTeamMembersQuery,
  useCreateOrUpdateDivisionMutation,
  useAssignCustomerToDivisionMutation,
  useAssignTeamMemberToDivisionMutation,
  useLazyGetFilterDivisionsQuery,
  useLazyGetHeadDivisionsQuery,
  useCreateHeadDivisionMutation,
} = divisionApi;
