export enum PartyType {
  BUYER = 'BUYER',
  SELLER = 'SELLER',
}

export interface NotificationPreferences {
  appId: string;
  partyType: PartyType;
  isEmail: boolean;
  isSms: boolean;
  isWeb: boolean;
  isNative: boolean;
  isWhatsappNotificationEnabled: boolean;
}

export interface NotificationsResponse {
  workspaceId: string;
  userId: string;
  NotificationPreferences: NotificationPreferences[];
}

export interface UpdateNotificationsRequest {
  workspaceId: string;
  sellerWorkspaceId: string;
  NotificationPreferences: NotificationPreferences[];
}

export type UpdateNotificationsResponse = NotificationsResponse;

export enum NotificationType {
  SELECT_ALL_UPDATES = 'Select All',
  ACCOUNT_UPDATES = 'Account Updates',
  ORDER_UPDATES = 'Order Updates',
  RETURNS_UPDATES = 'Returns Updates',
  TICKETS_UPDATES = 'Ticket Updates',
  ZOAI = 'ZoAi Updates',
}

export enum AppId {
  order = 'ORDAPP01',
  claim = 'CLMAPP01',
  ledger = 'LERAPP01',
  product = 'PRODUCTAPP',
  scheme = 'SCHEMEAPP',
  zoai = 'zo-ai',
}
export interface Notification_ {
  notificationType: NotificationType;
  isSelected: boolean;
  emailSelected: boolean;
  webSelected: boolean;
  smsSelected: boolean;
  appSelected: boolean;
  isDisabled: boolean;
  whatsappSelected: boolean;
  appId: NotificationType | AppId;
}
