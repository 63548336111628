import { ColumnConfig, CfaOrdersColumnKey } from '../../model';

export const cfaOrderConfig: Record<CfaOrdersColumnKey, ColumnConfig> = {
  orderNo: {
    name: 'Order No.',
    field: 'textWithImage',
    isHyperlink: true,
  },
  orderDate: {
    name: 'Order Date',
    sortField: 'orderDate',
  },
  status: { name: 'Status' },
  cfa: { name: 'CFA Name' },
  customerName: {
    name: 'Customer Name',
    field: 'avatarNameStatus',
  },
  skuCount: {
    name: 'SKU Count',
    sortField: 'skuCount',
    alignment: 'flex-end',
  },
  orderedValue: {
    name: 'Net Order Value',
    sortField: 'orderedAmount',
    alignment: 'flex-end',
  },
  orderSaving: {
    name: 'Order Saving',
    sortField: 'orderSavings',
    alignment: 'flex-end',
  },
  invoiceNo: {
    name: 'Invoice No.',
  },
  invoicedQty: {
    name: 'Invoiced Qty',
    sortField: 'invoicedQty',
    alignment: 'flex-end',
  },
  invoicedAmt: {
    name: 'Invoiced Amt',
    sortField: 'invoicedAmount',
    alignment: 'flex-end',
  },
  poNo: { name: 'PO Number', isVisible: false, isAdditional: true },
  coNo: { name: 'CO No.', isVisible: false, isAdditional: true },
  orderedBy: {
    name: 'Ordered By',
    isVisible: false,
    isAdditional: true,
  },
  bouncedQty: {
    name: 'Bounced Qty',
    alignment: 'flex-end',
    isVisible: false,
    isAdditional: true,
  },
  bouncedAmt: {
    name: 'Bounced Amt',
    alignment: 'flex-end',
    isVisible: false,
    isAdditional: true,
  },
  totalQty: {
    name: 'Total Qty',
    alignment: 'flex-end',
    isVisible: false,
    isAdditional: true,
  },
  headerDivision: {
    name: 'Header Division',
    isAdditional: true,
    isVisible: false,
  },
  divisions: {
    name: 'Divisions',
    isVisible: false,
    isAdditional: true,
  },
  download: {
    name: '',
    alignment: 'flex-end',
  },
};
