import { ColumnConfig, CfaProductColumnKey } from '../../model';

export const cfaProductConfig: Record<CfaProductColumnKey, ColumnConfig> = {
  skuCode: {
    name: 'SKU Code',
    isHyperlink: true,
  },
  status: { name: 'Status' },
  productTitle: { name: 'Product Title', field: 'multiLine' },
  categories: { name: 'Categories', field: 'component' },
  scheme: { name: 'Scheme' },
  taxCategory: { name: 'Tax Category', alignment: 'center' },
  pts: { name: 'PTS', sortField: 'price', alignment: 'flex-end' },
  ptr: { name: 'PTR', alignment: 'flex-end' },
  margin: { name: 'Margin', alignment: 'flex-end' },
  packSize: { name: 'Pack Size', alignment: 'flex-end' },
};
