import { ColumnConfig, OrderDetailsReportsColumnKey } from '../../model';

export const orderDetailsReportConfig: Record<
  OrderDetailsReportsColumnKey,
  ColumnConfig
> = {
    sNo: {
      name: 'S.No',
    },
    orderNumber: {
        name: 'Order Number',
        // sortField: 'orderdate',
    },
    orderDate: {
        name: 'Ordered Date',
        sortField: 'orderdate',
    },
    cfaName: {
        name: 'CFA Name',
        sortField: 'cfaname',
    },
    customerName: {
        name: 'Customer Name',
        sortField: 'customername',
    },
    salesrepName: {
        name: 'Salesrep Name',
    },
    totalSku: {
        name: 'Total SKU',
        sortField: 'skucount',
        alignment: 'flex-end',
    },
    totalOrderValue: {
       name: 'Total Order Value',
       sortField: 'netordervalue',
       alignment: 'flex-end',
    }
};
