import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { importsApi } from './api';

export const ImportAdapter = createEntityAdapter({
  // Keep the "all IDs" array sorted based on book titles
  //@ts-ignore
  selectId: (imports) => imports?.id,
  sortComparer: (a, b) =>
    //@ts-ignore
    b.creationTime.toString().localeCompare(a.creationTime.toString()),
});

export const importsSlice = createSlice({
  name: 'imports',
  initialState: ImportAdapter.getInitialState(),
  reducers: {
    addImport: ImportAdapter.addOne,
    addImports: ImportAdapter.addMany,
    updateImport: ImportAdapter.upsertOne,
    updateImports: ImportAdapter.upsertMany,
    removeImport: ImportAdapter.removeOne,
    removeImports: ImportAdapter.removeMany,
    getImportsSuccess(state, action) {
      ImportAdapter.setAll(state, action.payload.data);
      return state;
    },
    getImportsFailed(state, action) {
      console.log('in get Import failure', state, action);
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      importsApi.endpoints.getBatchesList.matchFulfilled,
      (state, action) => {
        importsSlice.caseReducers.getImportsSuccess(state, action);
      }
    );
  },
});

export const {
  addImport,
  addImports,
  updateImport,
  updateImports,
  removeImport,
  removeImports,
  getImportsSuccess,
  getImportsFailed,
} = importsSlice.actions;

// export default importsSlice.reducer;
