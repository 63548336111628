import { Api } from '../api';
import { GetWorkspaceRolesResponse } from '../auth';
import { RenameRoleRequest } from './model';

export const superAdminApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    createSupportUser: builder.mutation<any, any>({
      query(body) {
        return {
          url: `admin/user`,
          method: 'POST',
          body: body,
        };
      },
    }),
    renameRole: builder.mutation<any, RenameRoleRequest>({
      query(body) {
        return {
          url: `admin/role/create`,
          method: 'POST',
          body: body,
        };
      },
    }),
    mappingReport:builder.mutation<any, any>({
      query(body) {
        return {
          url: `reports/api/v2/mapping-report`,
          method: 'POST',
          body: body,
        };
      },
    }),
    mappingDownload:builder.mutation<any, any>({
      query(body) {
        return {
          url: `reports/api/v2/mapping-report/download`,
          method: 'POST',
          body: body,
          responseHandler: (response) => response.arrayBuffer(),

        };
      },
    }),
    getWorkspaceRolesList: builder.query<GetWorkspaceRolesResponse, any>({
      query: (args) => {
        return {
          url: 'workspaces/roles/permissions',
          method: 'GET',
          params: args,
        };
      },
    }),
    getUserSupportList: builder.query<any[], void>({
      query(arg) {
        return {
          url: `admin/users`,
          method: 'GET',
        };
      },
    }),
  }),
});
export const {
  useCreateSupportUserMutation,
  useRenameRoleMutation,
  useLazyGetWorkspaceRolesListQuery,
  useLazyGetUserSupportListQuery,
  useMappingReportMutation,
useMappingDownloadMutation
} = superAdminApi;
