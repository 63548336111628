/* eslint-disable @typescript-eslint/no-empty-function */
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../root';
import {
  AppParams,
  Miscellaneous,
  ChannelType,
  SharedIntent,
  SuccessOrderModal,
} from './model';
import { EventType, Notification } from '@notifee/react-native';
import moment from 'moment';
import { OrderImportSourceEnum } from '../helpers';
import { isArray } from 'lodash';

export const SLICE_FEATURE_KEY = 'miscellaneous';

export const initialMiscellaneousState: Miscellaneous = {
  notificationData: { notification: undefined, eventType: undefined },
  channelInfo: { id: '', type: ChannelType.Channel },
  sellerStoreData: {
    tokens: { refreshToken: '', role: '', token: '' },
  },
  appParams: {
    route: '',
    queryParams: '',
    cartCount: 0,
  },
  selectedChannelWorkspaceID: '',
  cartModalState: false,
  extendedCartState: false,
  retryPolicies: {},
  navigateFromChannel: '',
  headerVisible: true,
  selectedCartTab: OrderImportSourceEnum.MANUAL,
  isZonoMoney: false,
  navigateFunctions: {
    navigateToOrders: () => {},
    navigateToManualOrderFromCreateOrderDropdown: () => {},
    navigateToUploadOrderFromCreateOrderDropdown: () => {},
    navigateToCustomersManualOrder: () => {},
    navigateToCustomersUploadOrder: () => {},
    navigateToCreateManualReturn: () => {},
    navigateToCustomerCreateManualReturn: () => {},
    navigateToTrackPO: () => {},
    navigateToCreateUploadReturn: () => {},
    navigateToCustomerCreateUploadReturn: () => {},
    navigateToUploadCustomers: () => {},
    navigateToUploadProducts: () => {},
    navigateToUploadDivisions: () => {},
    navigateToUploadHeadDivisions: () => {},
    navigateToUploadSchemes: () => {},
    navigateToUploadCfa: () => {},
    navigateToUploadLedgers: () => {},
  },
  runTour: false,
  activeKeyboardHandler: null,
  bottomTabBarHeight: 0,
  topHeaderHeight: 0,
  tabsCurrentIndex: {
    CartScreen: 0,
    OrdersScreen: 0,
    ProductsScreen: 0,
  },
  selectedPos: [],
  // Mobile: a flag to show salesman or seller is checked-in as distributor
  isDistributorFlow: false,
  fromSellerAction: false,
  sharedIntent: null,
  isAssignTags: false,
  successOrderModal: {
    isOpen: false,
    type: null,
  },
};

export const miscellaneousSlice = createSlice({
  name: SLICE_FEATURE_KEY,
  initialState: initialMiscellaneousState,
  reducers: {
    storeNotification(
      state,
      action: PayloadAction<{
        notification: Notification;
        eventType: EventType;
      }>
    ) {
      state.notificationData = {
        eventType: action.payload.eventType,
        notification: action.payload.notification,
      };
    },
    selectChannelInfo(
      state,
      action: PayloadAction<{ id: string; type?: ChannelType }>
    ) {
      state.channelInfo = {
        id: action.payload.id,
        type: action.payload.type || ChannelType.Channel,
      };
    },
    updateSellerWebAppTokens(
      state,
      action: PayloadAction<{
        tokens: {
          token: string;
          refreshToken: string;
          role: string;
        };
      }>
    ) {
      state.sellerStoreData.tokens = action.payload.tokens;
    },
    updateAppParams(state, action: PayloadAction<AppParams>) {
      state.appParams = action.payload;
    },
    updateSelectedChannelWorkspaceID(state, action: PayloadAction<string>) {
      state.selectedChannelWorkspaceID = action.payload;
    },
    closeCartModal(state) {
      state.cartModalState = false;
    },
    openCartModal(state) {
      state.cartModalState = true;
    },
    toggleCartModal(state) {
      state.cartModalState = !state.cartModalState;
    },
    closeExtendedCartModal(state) {
      state.extendedCartState = false;
    },
    openExtendedCartModal(state) {
      state.extendedCartState = true;
    },
    toggleExtendedCartModal(state) {
      state.extendedCartState = !state.extendedCartState;
    },
    updateRetryPolicy(
      state,
      action: PayloadAction<{
        id: string;
        params: {
          count: number;
          time: number;
        };
      }>
    ) {
      state.retryPolicies[action.payload.id] = action.payload.params;
    },
    updateChannelNavigation(state, action: PayloadAction<string>) {
      state.navigateFromChannel = action.payload;
    },
    updateHeaderVisible(state, action: PayloadAction<boolean>) {
      state.headerVisible = action.payload;
    },
    updateCartTab(state, action: PayloadAction<OrderImportSourceEnum>) {
      state.selectedCartTab = action.payload;
    },
    updateIsZonoMoneyState(state, action: PayloadAction<boolean>) {
      state.isZonoMoney = action.payload;
    },
    updateNavigateFunctions(state, action: PayloadAction<any>) {
      state.navigateFunctions = action.payload;
    },
    updateRunTour(state, action: PayloadAction<boolean>) {
      state.runTour = action.payload;
    },
    updateActiveKeyboardHandler(state, action: PayloadAction<any>) {
      state.activeKeyboardHandler = action.payload;
    },
    updateBottomTabBarHeight(state, action: PayloadAction<number>) {
      if (state.bottomTabBarHeight !== action.payload) {
        state.bottomTabBarHeight = action.payload;
      }
    },
    updateTopHeaderHeight(state, action: PayloadAction<number>) {
      if (state.topHeaderHeight !== action.payload) {
        state.topHeaderHeight = action.payload;
      }
    },
    updateTabsCurrentIndex(
      state,
      action: PayloadAction<{ screen: string; currIndex: number }>
    ) {
      const tabIndex = action.payload.currIndex;
      if (state.tabsCurrentIndex[action.payload.screen] !== tabIndex) {
        state.tabsCurrentIndex[action.payload.screen] =
          action.payload.currIndex;
      }
    },
    updateSelectedPos(state, action: PayloadAction<string | string[]>) {
      if (isArray(action.payload)) {
        state.selectedPos = action.payload;
      } else {
        state.selectedPos = [action.payload];
      }
    },
    clearSelectedPos(state) {
      state.selectedPos = [];
    },
    updateIsDistributorFlow(state, action: PayloadAction<boolean>) {
      state.isDistributorFlow = action.payload;
    },
    updateSharedIntent(state, action: PayloadAction<SharedIntent>) {
      state.sharedIntent = action.payload;
    },
    updatefromSellerAction(state, action: PayloadAction<boolean>) {
      state.fromSellerAction = action.payload;
    },
    updateIsAssignTags(state, action: PayloadAction<boolean>) {
      state.isAssignTags = action.payload;
    },
    updateSuccesOrderModal(state, action: PayloadAction<SuccessOrderModal>) {
      state.successOrderModal = action.payload;
    },
  },
});

export const miscellaneousReducer = miscellaneousSlice.reducer;

export const {
  storeNotification,
  selectChannelInfo,
  updateSellerWebAppTokens,
  updateAppParams,
  updateSelectedChannelWorkspaceID,
  openCartModal,
  closeCartModal,
  toggleCartModal,
  toggleExtendedCartModal,
  openExtendedCartModal,
  closeExtendedCartModal,
  updateRetryPolicy,
  updateChannelNavigation,
  updateHeaderVisible,
  updateCartTab,
  updateIsZonoMoneyState,
  updateNavigateFunctions,
  updateRunTour,
  updateActiveKeyboardHandler,
  updateBottomTabBarHeight,
  updateTopHeaderHeight,
  updateTabsCurrentIndex,
  updateSelectedPos,
  clearSelectedPos,
  updateIsDistributorFlow,
  updatefromSellerAction,
  updateSharedIntent,
  updateIsAssignTags,
  updateSuccesOrderModal,
} = miscellaneousSlice.actions;

export const getMiscellaneousSliceState = (
  rootState: RootState
): Miscellaneous => rootState[SLICE_FEATURE_KEY];

export const getNotificationData = createSelector(
  getMiscellaneousSliceState,
  (state) => state.notificationData
);

export const getSelectedChannel = createSelector(
  getMiscellaneousSliceState,
  (state) => state.channelInfo
);

export const getSellerWebAppTokens = createSelector(
  getMiscellaneousSliceState,
  (state) => state.sellerStoreData.tokens
);

export const getAppParams = createSelector(
  getMiscellaneousSliceState,
  (state) => state?.appParams
);

export const getSelectedChannelWorkspaceId = createSelector(
  getMiscellaneousSliceState,
  (state) => state.selectedChannelWorkspaceID
);

export const getCartModalState = createSelector(
  getMiscellaneousSliceState,
  (state) => state?.cartModalState
);

export const getExtendedCartState = createSelector(
  getMiscellaneousSliceState,
  (state) => state?.extendedCartState
);

export const selectRetryPolicy = createSelector(
  [getMiscellaneousSliceState, (state, filter) => filter],
  (miscellaneousState, filter: string) => {
    return (
      miscellaneousState.retryPolicies[filter] || {
        count: 0,
        time: moment().valueOf(),
      }
    );
  }
);

export const getChannelNavigation = createSelector(
  getMiscellaneousSliceState,
  (state) => state.navigateFromChannel
);
export const getHeaderVisibleState = createSelector(
  getMiscellaneousSliceState,
  (state) => state?.headerVisible
);

export const getSelectedCartTab = createSelector(
  getMiscellaneousSliceState,
  (state) => state.selectedCartTab
);

export const getIsZonoMoneyState = createSelector(
  getMiscellaneousSliceState,
  (state) => state.isZonoMoney
);

export const getNavigateFunctions = createSelector(
  getMiscellaneousSliceState,
  (state) => state.navigateFunctions
);

export const getTourState = createSelector(
  getMiscellaneousSliceState,
  (state) => state?.runTour
);

export const getActiveKeyboardHandler = createSelector(
  getMiscellaneousSliceState,
  (state) => state.activeKeyboardHandler
);

export const getBottomTabBarHeight = createSelector(
  getMiscellaneousSliceState,
  (state) => state.bottomTabBarHeight
);

export const getTabsCurrentIndex = createSelector(
  getMiscellaneousSliceState,
  (state) => state.tabsCurrentIndex
);

export const getSelectedPos = createSelector(
  getMiscellaneousSliceState,
  (state) => state.selectedPos
);

export const getTopHeaderHeight = createSelector(
  getMiscellaneousSliceState,
  (state) => state.topHeaderHeight
);

export const getIsDistributorFlow = createSelector(
  getMiscellaneousSliceState,
  (state) => state.isDistributorFlow
);

export const getSharedIntent = createSelector(
  getMiscellaneousSliceState,
  (state) => state.sharedIntent
);

export const getIsfromSellerAction = createSelector(
  getMiscellaneousSliceState,
  (state) => state.fromSellerAction
);

export const getIsAssignTags = createSelector(
  getMiscellaneousSliceState,
  (state) => state.isAssignTags
);

export const getSuccessOrderModal = createSelector(
  getMiscellaneousSliceState,
  (state) => state.successOrderModal
);
