import {
  FormHelperTextProps,
  InputLabelProps,
  TextField,
  TextFieldVariants,
} from '@mui/material';
import React, {
  CSSProperties,
  InputHTMLAttributes,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { ThemeContext } from '../contexts/ThemeContext';

export interface InputTextProps {
  /**
   * Provide the title for the input text. The text should be displayed top of the input feild
   */
  title?: string;
  /**
   * The label text displayed above the input field.
   */
  label?: string;
  /**
   * The current value of the input field.
   * This should be controlled by the parent component.
   */
  value?: string | any;

  type?: string;
  /**
   * Indicates whether the input field is required. If true, the field must be filled out before form submission. (By default value is false)
   */
  required?: boolean;
  /**
   * The variant/style of the input field. Possible values could include "outlined", "filled", "standard"
   */
  varient?: TextFieldVariants;
  /**
   * The default value of the input field.
   * This value is used when the component is first rendered.
   */
  defaultValue?: string;
  /**
   * The name attribute of the input field. This is used to identify the input field.
   */
  name: string;
  /**
   * The placeholder text displayed inside the input field when it is empty.
   */
  placeholder?: string;
  /**
   * Indicates whether the input should be rendered as a select dropdown.
   * If true, the component will render as a select element instead of an input.
   */
  isSelect?: boolean;
  /**
   * The helper text displayed below the input field.
   * This can be used to provide additional information to the user.
   */
  helperText?: string;
  /**
   * An element to display at the start of the input field.
   */
  startAdornment?: React.JSX.Element;
  /**
   * An element to display at the end of the input field.
   * Example: An icon or text.
   */
  endAdornment?: React.JSX.Element;
  /**
   * Indicates whether the input field has an error.
   * If true, the input field will be displayed in an error state.
   */
  error?: boolean;
  /**
   * The text direction of the input field content.
   * Possible values could be "ltr" (left-to-right) or "rtl" (right-to-left).
   * By default left-to-right
   */
  textDirection?: 'ltr' | 'rtl';
  /**
   * Function to handle changes to the input field.
   * This function is called with the event whenever the input value changes.
   */
  onBlur: (val: string) => void;
  /**
   * Props to customize the appearance and behavior of the helper text.
   * Example: `{ color: 'primary' }`
   */
  helperTextProps?: FormHelperTextProps;
  /**
   * Props to customize the appearance of the Text field.
   * By default width:100% and height= 44px is given`
   */
  textFeildStyleProps?: CSSProperties;
  /**
   * Additional HTML attributes to be spread onto the input element.
   */
  inputFieldProps?: InputHTMLAttributes<HTMLInputElement>;
  /**
   * Props to customizw the appearance of the label of the text field.
   */
  inputLabelProps?: InputLabelProps;
  /**
   * Any additional elements or components to be rendered within this component.
   * Example: Custom buttons or additional inputs.
   */
  children?: React.JSX.Element;
  /**
   * Key for the input text feild.
   */
  key?: number | string;
  /**
   * Ref for the input feild
   */
  ref?: any;
  /**
   *  Keydown handler useful for keyboard handler
   */
  onKeyDown?: (
    e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
    value: string
  ) => any;
  /**
   * Should Input be focused
   */
  focusInput?: boolean;
  /**
   *
   */
  onFocus?: (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
  ) => any;
  /**
   * Color for input border
   */
  borderColor?: string;
  containerStyles?: CSSProperties;
}

export const InputText: React.FC<InputTextProps> = ({
  label,
  value,
  startAdornment,
  name,
  endAdornment,
  placeholder,
  onBlur,
  type = 'text',
  required = false,
  varient = 'outlined',
  defaultValue,
  helperText = '',
  helperTextProps,
  children,
  isSelect = false,
  textDirection,
  error = false,
  textFeildStyleProps,
  inputFieldProps,
  key,
  ref,
  onKeyDown,
  focusInput = false,
  onFocus,
  borderColor,
  inputLabelProps,
  title,
  containerStyles,
}) => {
  if (!ref) {
    ref = useRef<HTMLInputElement>(null);
  }

  const [val, setVal] = useState('');
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    if (focusInput) {
      ref?.current?.focus();
    } else {
      ref?.current?.blur();
    }
  }, [focusInput]);

  useEffect(() => {
    setVal(value);
  }, [value]);

  const mergedStyles = {
    height: '20px',
    padding: '12px',
    fontSize: '14px',
    fontFamily: 'Segoe-UI',
    ...(inputFieldProps?.style || {}),
  };

  const helperTextStyle = {
    ...theme.fontConfig.regular,
    fontSize: '10px',
    lineHeight: '10px',
    margin: '0px',
    marginTop: '2px',
    ...helperTextProps,
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '6px',
        ...(containerStyles ? containerStyles : {}),
      }}
    >
      {title && (
        <span
          style={{
            ...theme.fontConfig.regular,
            fontSize: '12px',
            color: '#091E42',
          }}
        >
          {title}
        </span>
      )}
      <TextField
        label={label}
        value={val}
        key={key}
        type={type}
        inputRef={ref}
        defaultValue={defaultValue}
        name={name}
        error={error}
        helperText={helperText}
        placeholder={placeholder}
        variant={varient}
        onChange={(e) => setVal(e.target.value)}
        onFocus={(e) => onFocus && onFocus(e)}
        onBlur={(e) => onBlur(e?.target?.value)}
        required={required}
        style={{
          backgroundColor: 'white',
          ...textFeildStyleProps,
        }}
        sx={{

          input: {
            '&::placeholder': {
              color: '#BFC0C2',
              opacity: 1,
              textTransform: 'capitalize',
              fontSize: '14px',
              fontFamily: 'Segoe-UI',
              fontWeight: '400',
            },
          },
          '& .MuiInputLabel-root': {
            color: '#1D1D22',
            fontSize: 14,
            fontFamily: theme.fontConfig.regular.fontFamily,
            transform: 'translate(14px, 12px) scale(1)',
            '&.Mui-focused': {
              fontFamily: theme.fontConfig.semiBold.fontFamily,
              color: '#1D1D22',
              fontSize: 12,
              transform: 'translate(14px, -6px) scale(0.75)',
            },
            '&.MuiInputLabel-shrink': {
              transform: 'translate(14px, -6px) scale(0.75)',
            },
          },
        }}
        className="text-input"
        FormHelperTextProps={{ style: helperTextStyle }}
        InputLabelProps={{
          shrink: true,
          style: {
            fontSize: '14px',
            fontFamily: 'Segoe-UI',
            fontWeight: '600',
            color: '#2C3E5D',
            lineHeight: '17px',
            height: '42px',
            ...inputLabelProps,
          },
        }}
        InputProps={{
          style: { paddingLeft: 0 },
          startAdornment: startAdornment && startAdornment,
          endAdornment: endAdornment && endAdornment,
        }}
        inputProps={{
          onKeyDown: (e) => onKeyDown && onKeyDown(e, ref?.current?.value),
          dir: textDirection,
          ...inputFieldProps,
          style: mergedStyles,
        }}
        select={isSelect}
      >
        {children}
      </TextField>
    </div>
  );
};

export default InputText;
