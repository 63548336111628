import { createEntityAdapter } from '@reduxjs/toolkit';
import { RootState, store } from '../root';
import { User } from './model';

export const userAdapter = createEntityAdapter<User>({
  selectId: (user) => user.id,
});

export const userSelectors = userAdapter.getSelectors(
  //@ts-ignore
  (state: RootState) => state.user
);

export const getUser = () => {
  return store.getState().userAuth.userData;
};

export const findCfaId=()=>{
  return store.getState().userAuth.userData.cfaIDs;
}

export const getAllTokens = (rootState: RootState) => {
  return {
    token: rootState?.userAuth?.token,

    refreshToken: rootState?.userAuth?.refreshToken,

    workspaceId: rootState?.workspace?.workspace?.id,
  };
};
export const getUserPermissions = (rootState: RootState) => {
  return rootState?.userAuth?.userPermissions;
};
