import React from 'react';
import { LoadingSpinner } from '@zonofi/shared';
import { SVGS } from '@zonofi/zono-money-design-system';
import { CatalogListData, LineInOrder, Seller } from '@zonofi/zono-money-store';

interface RenderAddButtonProps {
  item: CatalogListData; // Replace with the actual type for `item`
  seller: Seller; // Replace with the actual type for `seller`
  inviteId: string; // Adjust type if it's not a string
  quantityMap: Record<number, any>; // Adjust based on the structure of `quantityMap`
  setQuantityMap: React.Dispatch<React.SetStateAction<Record<number, any>>>;
  onDelete: (
    item: LineInOrder,
    sellerWorkspaceId: string,
    customerId: string
  ) => any;
  onDecrement: (
    item: LineInOrder,
    sellerWorkspaceId: string,
    customerId: string
  ) => any;
  onChangeQuantity: (
    item: LineInOrder,
    newQty: number,
    sellerWorkspaceId: string,
    customerId: string
  ) => any;
  onIncrement: (
    item: LineInOrder,
    sellerWorkspaceId: string,
    customerId: string
  ) => any;
  onAddToCart: (item: CatalogListData) => any;
  listView: boolean;
}

export const RenderAddButton: React.FC<RenderAddButtonProps> = ({
  item,
  onDelete,
  seller,
  inviteId,
  onDecrement,
  onChangeQuantity,
  quantityMap,
  setQuantityMap,
  onIncrement,
  onAddToCart,
  listView,
}) => {
  return item?.isAddedToCart ? (
    <div
      className={`catalog-list-item${
        listView ? '-grid-' : '-'
      }qty-selector-container`}
    >
      <div className={`catalog-list-item${listView ? '-grid-' : '-'}delete`}>
        <img
          src={SVGS.DeleteRed}
          alt="delete"
          width={20}
          height={20}
          onClick={(e) => {
            e.stopPropagation();
            onDelete(item?.lineInOrder, seller?.workspaceId, inviteId);
          }}
        />
      </div>

      <div
        className={`catalog-list-item${listView ? '-grid-' : '-'}qty-selector`}
        onClick={(e) => e.stopPropagation()}
      >
        <img
          src={SVGS.MinusPrimaryCyan}
          alt="semi-quantity-selector"
          width={16}
          height={16}
          onClick={(e) => {
            e.stopPropagation();
            onDecrement(item?.lineInOrder, seller?.workspaceId, inviteId);
          }}
        />
        {item?.isLoading ? (
          <div className="catalog-list-item-qty-selector-loader">
            <LoadingSpinner size={15} />
          </div>
        ) : (
          <input
            className={`catalog-list-item${
              listView ? '-grid-' : '-'
            }qty-selector-input font-primary`}
            type="text"
            inputMode="numeric"
            pattern="[0-9]*[.,]?[0-9]+"
            key={item?.productVariantId}
            onKeyDown={(e) => {
              e.key === 'Enter' &&
                onChangeQuantity(
                  item?.lineInOrder,
                  quantityMap[item?.productVariantId],
                  seller?.workspaceId,
                  inviteId
                );
            }}
            onBlur={() => {
              onChangeQuantity(
                item?.lineInOrder,
                quantityMap[item?.productVariantId],
                seller?.workspaceId,
                inviteId
              );
            }}
            onChange={(e) => {
              if (!isNaN(Number(e.target.value))) {
                const initialQuantiyMap = quantityMap;
                if (item?.productVariantId) {
                  initialQuantiyMap[item?.productVariantId] = Number(
                    e.target.value
                  );
                }
                setQuantityMap({ ...initialQuantiyMap });
              }
            }}
            value={quantityMap[item?.productVariantId]}
          />
        )}
        <img
          src={SVGS.PlusPrimaryCyan}
          alt="add-quantity"
          width={16}
          height={16}
          onClick={(e) => {
            e.stopPropagation();
            onIncrement(item?.lineInOrder, seller?.workspaceId, inviteId);
          }}
        />
      </div>
    </div>
  ) : (
    <button
      className={`catalog-list-item${
        listView ? '-grid-' : '-'
      }add-to-cart font-primary-semibold`}
      onClick={
        item?.isLoading
          ? () => {}
          : (e) => {
              e.stopPropagation();
              onAddToCart(item);
            }
      }
    >
      {item?.isLoading ? <LoadingSpinner size={15} /> : <span>Add</span>}
    </button>
  );
};
