export interface BulkUploadRequest {
  entity: string;
  records: any;
  companyCode: string;
  fileName: string;
  workspaceId: string;
}

export interface BulkImportDataRequest {
  uploadBatchId: string;
  params: {
    entity: string;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    uploadStatus?: string;
  };
  body?: {
    in?: {
      customerFirmName?: string[];
      mapping_status?: string[];
      upload_status?: string[];
      transactionType?: string[];
    };
    date?: {
      transactionDate?: TransactionDate;
      dueDate?: TransactionDate;
    };
  };
}

interface TransactionDate {
  startDate: string;
  endDate: string;
}

export interface BulkImportDataResponse {
  records: BulkImportDataRecord[];
  mappingStatusAndCounts?: {
    EXISTING: number;
    MAPPING_FOUND: number;
    MAPPED_PARTIALLY: number;
    MAPPING_NOT_FOUND: number;
    null: number;
  };
  id: string;
  createdAt: string;
  updatedAt: string;
  totalRecords: number;
  processedRecords: number;
  notProcessedRecords: number;
  failedRecords: number;
  successfulRecords: number;
  status: BulkImportStatus;
  entity: string;
  companyCode: string;
  workspaceId: string;
  reprocessCount: number;
  fileName: string;
  parentUploadBatches: any;
  erpSyncBatchId: string;
  startRecord: number;
  endRecord: number;

  mapping_status?: BulkRecordMappingStatus[];
  upload_status?: string[];
  customerFirmName?: string[];
  transactionType?: string[];
  dateFilter?: string[];
}

export interface BulkImportDataRecord {
  id: string;
  name: string;
  description: string;
  parentSku: string;
  companyCode: string;
  CompanyName: string;
  productVariants: string;
  customFields: string;
  divisionCFACodes: string;
  collections: string;
  entityObj: string;
  createdAt: string;
  updatedAt: string;
  uploadBatchId: string;
  uploadBatchAttemptsId: string;
  messageId?: string;
  isMsgConsumed?: boolean;
  productVariants_facets: string;
  productVariants_name: string;
  productVariants_sku: string;
  productVariants_listPrice: number;
  productVariants_shortName: string;
  productVariants_shortDescription: string;
  productVariants_sortOrder: number;
  productVariants_taxCategoryCode: string;
  productVariants_erpId: string;
  productVariants_erpUOM: string;
  productVariants_ean: string;
  productVariants_enabled: any;
  productVariants_vairantCode: any;
  productVariants_upcCode: string;
  productVariants_hsnCode: string;
  productVariants_baseUom: string;
  productVariants_packSize: number;
  productVariants_minOrderQty: number;
  productVariants_maxOrderQty: number;
  productVariants_qtyMultiplier: number;
  productVariants_grossWeight: number;
  productVariants_netWeight: number;
  productVariants_weightUOM: any;
  productVariants_volume: number;
  productVariants_volumeUOM: any;
  productVariants_erpPriceUOM: string;
  productVariants_warrentyInformation: string;
  productVariants_condition: string;
  productVariants_MRP: number;
  productVariants_PTR: string;
  productVariants_caseSize: any;
  productVariants_isSpecial: any;
  productVariants_packingInfo: any;
  productVariants_ZINS: number;
  productVariants_mfgName: any;
  productVariants_mfgCode: any;
  productVariants_minRemShelfLife: number;
  productVariants_totShelfLife: any;
  mdmerror?: BulkImportDataMdmerror;
  toBeProcessed: boolean;
  customerDetails_companyName: string;
  distributorCode: string;
  customerDetails_distributorCode: string;
  phone: string;
  phone2: string;
  customerDetails_gstin: string;
  customerDetails_name: string;
  mapping_status: BulkRecordMappingStatus;
  original_mapping_status: BulkRecordMappingStatus;
  // original_mapping_criteria: string;

  isActive: boolean;
  customerDetails: any;
  customerGroupCodes: any;
  status: any;
  cfa: any;
  upload_status: string;
  mapping_criteria: { criteria: string };
  mapping_suggested: ERPMappingSuggested[] | null;
  mapping_selected: string;
  customerDetails_telephone: any;
  customerDetails_email: any;
  customerDetails_CSTNumber: any;
  customerDetails_dlNumber: any;
  customerDetails_dlExpiry: any;
  customerDetails_distributorChannel: any;
  customerDetails_grossCreditLimit: any;
  customerDetails_creditLimitPeriod: any;
  customerDetails_shippingAddress_shippingAddress1: any;
  customerDetails_shippingAddress_shippingAddress2: any;

  customerDetails_physicalAddress_address1: any;
  customerDetails_physicalAddress_address2: any;
  customerDetails_postalCode: any;
  customerDetails_stateCode: any;
  customerDetails_countryCode: string;
  customerDetails_cityName: any;
  customerDetails_creditLimitDetails: any;

  referenceInvoiceNumber: string;
  refOrderNumber: any;
  docNumber: string;
  narration: any;
  invoiceStatus: any;
  cfaCode: string;
  discountRate: any;
  paymentTerms: any;
  invoiceDate: string;
  dueDate: any;
  invoiceAmount: number;
  invoiceAmountBase: any;
  invoiceAmountWithAdjustment: any;
  disc: any;
  cgst: any;
  sgst: any;
  igst: any;
  cess: any;
  irn: any;
  irnStatus: any;
  lrDate: any;
  lrNumber: any;
  destination: any;
  numCases: any;
  TDSRate: any;
  TDSAmount: any;
  TCSRate: any;
  TCSAmount: any;
  transporterName: any;
  calculationMethod: any;
  discountAmount: any;
  ptrDiscAmt: any;
  spclDiscAmtPts: any;
  utgst: any;
  educationCess: any;
  debitNoteNumber: any;
  debitNoteAmt: any;
  creditNoteOneNumber: any;
  creditNoteOneAmtPts: any;
  creditNoteTwoNumber: any;
  creditNoteTwoAmtPts: any;
  creditNoteThreeNumber: any;
  creditNoteThreeAmtPts: any;
  roundOff: any;
  PONumber: any;
  orderDate: any;
  divisionCode: any;
  itemList: any;
  customerName: string;

  customerCode: any;
  transactionType: string;
  transactionAmount: string;
  transactionDate: string;
  transactionReferenceCode: string;

  erpOrderNumber: any;
  customerFirmName: string;
  clearingDocNumber: any;
  docNumberAmountClearedList: string;
  invoiceReferenceNumber: any;
  clearingDate: any;
  chequeReferenceNumber: any;

  referenceTransactionNumber: string;
  instrumentNumber: any;
  bankReference: any;
  bankName: any;
  paymentMode: any;
}

export interface ERPMappingSuggested {
  name?: string;
  gstin?: string;
  phone?: string;
  firmName?: string;
  inviteId?: string;
  distributorCode?: string;

  productVariantId?: number;
  sku?: string;
  buyingPrice?: number;
  sellingPrice?: number;

  packSize?: number;
  productId?: number;

  docNumber?: string;
  customerName?: string;

  transactionAmount?: string;
  transactionType?: string;
  customerFirmName?: string;

  customer_name?: string;
  transactionReferenceCode?: string;
  transaction_Amount?: number;
}

export interface BulkImportDataMdmerror {
  id: number;
  entity: string;
  entityCode: string;
  message: string;
  createdAt: string;
}

export interface BulkImportListRequest {
  companyCode: string;
  pageNo: number;
  pageSize: number;
}

export interface BulkImportListResponse {
  records: BulkImport[];
  endRecord: number;
  startRecord: number;
  totalRecords: number;
}

export interface BulkImport {
  id: string;
  createdAt: string;
  totalRecords: number;
  processedRecords: number;
  notProcessedRecords: string;
  failedRecords: number;
  successfulRecords: number;
  status: BulkImportStatus;
  entity: BulkImportEntityType;
  companyCode: string;
  fileName: string;
}

export enum BulkImportStatus {
  PROCESSING = 'PROCESSING',
  FAILED = 'FAILED',
  SUCCESSFUL = 'SUCCESSFUL',
  EXTRACTED = 'EXTRACTED',
  TRANSFORMED = 'TRANSFORMED',
  ANALYSED = 'ANALYSED',
  SKIPPED = 'SKIPPED',
  CONFIRMED = 'CONFIRMED',
  SENT_TO_QUEUE = 'SENT_TO_QUEUE',
  COMPLETED = 'COMPLETED',
}

export enum BulkImportEntityType {
  DIVISION_MASTER = 'DIVISION_MASTER',
  HEAD_DIVISION_MASTER = 'HEAD_DIVISION_MASTER',
  CFA_MASTER = 'CFA_MASTER',
  PRODUCT_MASTER = 'PRODUCT_MASTER',
  CUSTOMER_MASTER = 'CUSTOMER_MASTER',
  LEDGER_DETAILS = 'LEDGER_DETAILS',
}

export interface ErpFileUploadResponse {
  id: string;
  syncType: string;
  erpSystemId: string;
  companyCode: string;
  workspaceId: string;
  filePath: string;
  fileName: any;
  createdAt: string;
  updatedAt: string;
  mastersWithNoColumnMapping: string[];
}

export interface ErpFileUploadRequest {
  workspaceId: string;
  params: {
    syncType: string;
    erpSystemCode: 'CUSTOM' | 'TALLY';
    companyCode: string;
  };
  body: FormData;
}

export interface ListErpUploadsRequest {
  workspaceId: string;
  params: {
    pageSize: number;
    pageNo: number;
  };
}

export interface ListErpUploadsResponse {
  startRecord: number;
  endRecord: number;
  totalRecords: number;
  records: ListErpUploadsResponseRecord[];
}

export interface ListErpUploadsResponseRecord {
  id: string;
  fileName: string;
  sync_type: string;
  createdAt: string;
  totalRecords: string;
  processedRecords: string;
  notProcessedRecords: string;
  successfulRecords: string;
  failedRecords: string;
  status: ListErpUploadsStatus;
  columnMappingDetails: ColumnMappingDetails;
  erpSystemCode: string;
}

export interface ColumnMappingDetails {
  mastersWithColumnMapping: any[];
  mastersWithNoColumnMapping: string[];
}

export enum ListErpUploadsStatus {
  PREPARING = 'PREPARING',
  CONFIRMED = 'CONFIRMED',
  PROCESSING = 'PROCESSING',
  READY_TO_PROCESS = 'READY_TO_PROCESS',
  COMPLETED = 'COMPLETED',
  COLUMN_MAPPING_FOUND = 'COLUMN_MAPPING_FOUND',
  COLUMN_MAPPING_NOT_FOUND = 'COLUMN_MAPPING_NOT_FOUND',
  FAILED = 'FAILED',
}

export interface ErpUploadBatchDetailsRequest {
  erpBatchId: string;
  params: {
    pageSize: number;
    pageNo: number;
  };
}

export interface ErpUploadBatchDetailsResponse {
  pageSize: number;
  pageNo: number;
  records: ErpUploadBatchDetailsResponseRecord[];
}

export interface ErpUploadBatchDetailsResponseRecord {
  id: string;
  createdAt: string;
  updatedAt: string;
  totalRecords: number;
  processedRecords: number;
  failedRecords: number;
  successfulRecords: number;
  status: string;
  entity: string;
  entityObj: ErpUploadBatchDetailsEntityObj;
  companyCode: string;
  workspaceId: string;
  reprocessCount: number;
  fileName: string;
  erpSyncBatchId: string;
}

export interface ErpUploadBatchDetailsEntityObj {
  entity: string;
  records: ErpUploadBatchDetailsRecord[];
  fileName: string;
  companyCode: string;
  workspaceId: string;
}

export interface ErpUploadBatchDetailsRecord {
  ledgerDetails?: ErpUploadBatchDetailsLedgerDetail[];
  cfa?: ErpUploadBatchDetailsCfa[];
  phone?: string;
  phone2?: string;
  isActive?: boolean;
  companyCode?: string;
  customerDetails?: ErpUploadBatchDetailsCustomerDetails;
  creditLimitDetails?: any[];
}

export interface ErpUploadBatchDetailsLedgerDetail {
  cfaCode: string;
  dueDate: string;
  division: string;
  docNumber: string;
  narration: string;
  clearingDate: string;
  customerCode: string;
  divisionCode: string;
  erpOrderNumber: string;
  transactionDate: string;
  transactionType: string;
  customerFirmName: string;
  clearingDocNumber: string;
  transactionAmount: string;
  chequeReferenceNumber: string;
  invoiceReferenceNumber: string;
  transactionReferenceCode: string;
  docNumberAmountClearedList: any[];
}

export interface ErpUploadBatchDetailsCfa {
  cfaCode: string;
  salesOrg: string;
  divisionCodes: ErpUploadBatchDetailsDivisionCode[];
}

export interface ErpUploadBatchDetailsDivisionCode {
  code: string;
  isActive: boolean;
}

export interface ErpUploadBatchDetailsCustomerDetails {
  name: string;
  email: string;
  gstin: string;
  cityName: string;
  dlExpiry: string;
  dlNumber: string;
  CSTNumber: string;
  stateCode: string;
  telephone: string;
  postalCode: string;
  companyName: string;
  countryCode: string;
  distributorCode: string;
  physicalAddress: ErpUploadBatchDetailsPhysicalAddress;
  shippingAddress: ErpUploadBatchDetailsShippingAddress;
  grossCreditLimit: number;
  creditLimitPeriod: number;
}

export interface ErpUploadBatchDetailsPhysicalAddress {
  address1: string;
  address2: string;
}

export interface ErpUploadBatchDetailsShippingAddress {
  city: string;
  state: string;
  pincode: string;
  countryCode: string;
  shippingAddress1: string;
  shippingAddress2: string;
}

export type BulkRecordMappingStatus =
  | 'MANUALLY_MAPPED'
  | 'EXISTING'
  | 'MAPPING_FOUND'
  | 'MAPPED_PARTIALLY'
  | 'MAPPING_NOT_FOUND'
  | 'NEW';

export const BulkRecordMappingStatusTextEnum: Record<
  BulkRecordMappingStatus,
  string
> = {
  EXISTING: 'Existing',
  MAPPED_PARTIALLY: 'Partial Data Found',
  MAPPING_FOUND: 'Similar Data Found',
  MAPPING_NOT_FOUND: 'Data Not Found',
  MANUALLY_MAPPED: 'Manually Mapped',
  NEW: 'New',
};

export interface ERPUploadBatchUpdateRequest {
  uploadBatchId: string;
  body: {
    id: string;
    phone?: string;
    distributorCode?: string;
    customerDetails_companyName?: string;

    parentSku?: string;

    transactionReferenceCode?: string;

    mapping_status?: any;
    mapping_criteria?: any;
    mapping_selected?: any;
    toBeProcessed: boolean;
  }[];
  params: {
    isConfirmed: boolean;
  };
}

export interface ERPUploadBatchUpdateResponse {
  message: string;
  records: BulkImportDataRecord[];
}

export interface DownloadErpFileRequest {
  id: string;
  params: {
    sellerWorkspaceId: string;
    sendSSE: boolean;
  };
}

export interface DownloadErpFileResponse {
  sellerWorkspaceId: string;
  signedUrl: {
    signedURL: string;
  };
}

export interface CustomerMasterColumnsForMapping {
  inviteId?: string;
  phone?: string;
  phone2?: string;
  companyCode?: string;
  isActive?: string;
  status?: string;
  customerGroupCodes?: string;
  telephone?: string;
  email?: string;
  CSTNumber?: string;
  gstin?: string;
  dlNumber?: string;
  dlExpiry?: string;
  distributorChannel?: string;
  grossCreditLimit?: string;
  creditLimitPeriod?: string;
  shippingAddress1?: string;
  shippingAddress2?: string;
  distributorCode?: string;
  name?: string;
  companyName?: string;
  address1?: string;
  address2?: string;
  postalCode?: string;
  stateCode?: string;
  countryCode?: string;
  cityName?: string;
}

export interface ProductMasterColumnsForMapping {
  name?: string;
  description?: string;
  parentSku?: string;
  sku?: string;
  listPrice?: string;
  price?: string;
  shortName?: string;
  shortDescription?: string;
  sortOrder?: string;
  taxCategoryCode?: string;
  erpId?: string;
  erpUOM?: string;
  ean?: string;
  enabled?: string;
  vairantCode?: string;
  upcCode?: string;
  hsnCode?: string;
  baseUom?: string;
  packSize?: string;
  minOrderQty?: string;
  maxOrderQty?: string;
  salesOrg?: string;
  qtyMultiplier?: string;
  grossWeight?: string;
  netWeight?: string;
  weightUOM?: string;
  volume?: string;
  volumeUOM?: string;
  erpPriceUOM?: string;
  warrentyInformation?: string;
  condition?: string;
  MRP?: string;
  PTR?: string;
  caseSize?: string;
  isSpecial?: string;
  packingInfo?: string;
  ZINS?: string;
  mfgName?: string;
  mfgCode?: string;
  minRemShelfLife?: string;
  totShelfLife?: string;
}

export interface InvoiceMasterColumnsForMapping {
  docNumber?: string;
  referenceInvoiceNumber?: string;
  narration?: string;
  invoiceStatus?: string;
  refOrderNumber?: string;
  distributorCode?: string;
  customerName?: string;
  cfaCode?: string;
  discountRate?: string;
  paymentTerms?: string;
  invoiceDate?: string;
  dueDate?: string;
  invoiceAmount?: string;
  invoiceAmountBase?: string;
  invoiceAmountWithAdjustment?: string;
  disc?: string;
  cgst?: string;
  sgst?: string;
  igst?: string;
  cess?: string;
  irn?: string;
  irnStatus?: string;
  status?: string;
  lrDate?: string;
  destination?: string;
  numCases?: string;
  transporterName?: string;
  calculationMethod?: string;
  discountAmount?: string;
  spclDiscAmtPts?: string;
  utgst?: string;
  educationCess?: string;
  debitNoteNumber?: string;
  debitNoteAmt?: string;
  creditNoteOneNumber?: string;
  creditNoteOneAmtPts?: string;
  creditNoteTwoNumber?: string;
  creditNoteTwoAmtPts?: string;
  creditNoteThreeNumber?: string;
  creditNoteThreeAmtPts?: string;
  roundOff?: string;
  PONumber?: string;
  orderDate?: string;
  divisionCode?: string;
  dispatchDocNumber?: string;
  destinationAddress?: string;
  vehicleNumber?: string;
  driverName?: string;
  lrNumber?: string;
  dispatchDate?: string;
  sku?: string;
  erpOrderNumber?: string;
  Qty?: string;
  Unit?: string;
  UnitPrice?: string;
  lineTotal?: string;
  lineTotalWithTax?: string;
  lineDiscount?: string;
  AssAmt?: string;
  GstRt?: string;
  CgstAmt?: string;
  IgstAmt?: string;
  SgstAmt?: string;
  batchNo?: string;
  expiryDate?: string;
  packsizes?: string;
  headDivisionNumber?: string;
  TDSRate?: string;
  TCSRate?: string;
  TDSAmount?: string;
  TCSAmount?: string;
  UTGSTRate?: string;
  UTGSTAmount?: string;
  discountPercent?: string;
  MRP?: string;
  PTR?: string;
  productTitle?: string;
  igstRate?: string;
  hsnCode?: string;
  freeQuantity?: string;
  ptrDiscPerc?: string;
  ptrDiscAmt?: string;
  specialDiscPercOnPts?: string;
  specialDiscAmtOnPts?: string;
  uniformProductCode?: string;
  manufacturerName?: string;
  isVersionTwo?: string;
}

export interface LedgerMasterColumnsForMapping {
  distributorCode?: string;
  transactionType?: string;
  transactionAmount?: string;
  transactionDate?: string;
  transactionReferenceCode?: string;
  referenceTransactionNumber?: string;
  narration?: string;
  docNumber?: string;
  amountCleared?: string;
  dueDate?: string;
  erpOrderNumber?: string;
  divisionCode?: string;
  status?: string;
  cfaCode?: string;
  clearingDocNum?: string;
  invoiceRefNum?: string;
  clearingDate?: string;
  chequeRefNum?: string;
  firmName?: string;
  instrumentNumber?: string;
  bankReference?: string;
  bankName?: string;
  paymentMode?: string;
}

export interface GetErpColumnMappingRequest {
  params: {
    erpSyncBatchId?: string;
    workspaceId: string;
    master: string;
  };
}

export interface GetErpColumnMappingResponse {
  id: string;
  createdAt: string;
  updatedAt: string;
  companyCode: string;
  workspaceId: string;
  master: string;
  erpSystemId: string;
  masterColumnNames: string[];
  columnMapping:
    | CustomerMasterColumnsForMapping
    | ProductMasterColumnsForMapping
    | InvoiceMasterColumnsForMapping
    | LedgerMasterColumnsForMapping;
  isMapped: boolean;
  mappedFields: MappedFields;
  notMappedFields: NotMappedFields;
  mandatoryColumns: MandatoryColumns;
  fileColumnNames: string[];
  fileData: any[];
}

export interface MappedFields {
  mappedFields: string[];
}

export interface NotMappedFields {
  notMappedFields: string[];
}

export interface MandatoryColumns {
  mandatoryColumns: string[];
}

export interface UpdateColumnMappingRequest {
  body: {
    columnMapping:
      | CustomerMasterColumnsForMapping
      | ProductMasterColumnsForMapping
      | InvoiceMasterColumnsForMapping
      | LedgerMasterColumnsForMapping;
    companyCode: string;
    notMappedFields?: string[];
  };
  params: {
    erpSyncBatchId?: string;
    workspaceId: string;
    master: string;
  };
}

export const codeToMaster = {
  Product: 'PRODUCT_MASTER',
  Customer: 'CUSTOMER_MASTER',
  Invoice: 'INVOICE_INBOUND',
  Ledger: 'LEDGER_DETAILS',
};

export const mastersToCode = {
  PRODUCT_MASTER: 'Product',
  CUSTOMER_MASTER: 'Customer',
  INVOICE_INBOUND: 'Invoice',
  LEDGER_DETAILS: 'Ledger',
};

export interface ListData {
  id: number;
  masterColumn: string;
  masterColumnLabel: string;
  selectedFileColumn: {
    label: string;
    value: string;
  };
  type: string;
  required: boolean;
  error: boolean;
}
export interface UpdateErpSyncSettingsResponse {
  id: string;
  createdAt: string;
  updatedAt: string;
  erpSystemId: string;
  companyCode: string;
  workspaceId: string;
  settingsValue: {
      syncType: string;
      frequency: string;
      host: string;
      port: number;
      filesPath: string;
  };
  priceLevel: string;
}


export interface UpdateErpSyncSettingsRequest {
  params: {
    sellerWorkspaceId: string;
  }
  body: {
    settings?: {
      syncType: string;
      frequency: string;
      host: string;
      port: number;
      filesPath: string;
    };
    priceLevel: string;
  }

}



export interface GetErpSyncSettingResponse  {
  id: string;
  createdAt: string;
  updatedAt: string;
  erpSystemId: string;
  companyCode: string;
  workspaceId: string;
  settingsValue: {
      host: string;
      port: number;
      syncType: string;
      filesPath: string;
      frequency: string;
  };
  priceLevel: string;
  erpSystemCode: string;
}