import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../root';
import { Workspace, Principal, WorkSpaceBusinessDetails } from './model';
import _ from 'lodash';

const WORKSPACE_FEATURE_KEY = 'workspace';

export interface workspaceState {
  workspace: Workspace;
  workspaces: Workspace[];
  userInviteId: string;
  selectedPrincipal: Principal | undefined;
}

export const initialWorkspaceState: workspaceState = {
  workspace: {
    id: '',
    spaceName: '',
    location: [],
    userId: '',
    createdDate: '',
    updatedDate: '',
    updatedBy: '',
    profilePic: '',
    timeZone: '',
    defaultCurrencyCode: '',
    isoLanguageCode: '',
    isoCountryCode: '',
    workspaceMembers: [],
    client: [],
    principal: [],
    role: '',
    isBuyer: false,
    isSeller: false,
    code: '',
  },
  userInviteId: '',
  workspaces: [],
  selectedPrincipal: {
    id: '',
    principalWorkspaceId: '',
    clientWorkspaceId: '',
    spaceName: '',
    profilePic: '',
    address: '',
    createdDate: '',
    updatedDate: '',
    inviteId: '',
    dlNumber: '',
    dlExpiry: '',
  },
};

export const workspaceSlice = createSlice({
  name: WORKSPACE_FEATURE_KEY,
  initialState: initialWorkspaceState,
  reducers: {
    addWorkspace(state, action: PayloadAction<Workspace[]>) {
      if (!_.isEmpty(action.payload)) {
        state.workspace = action.payload[0];
        state.workspaces = action.payload;
        state.selectedPrincipal = action.payload[0]?.principal?.find(
          (x) => x.clientWorkspaceId === action.payload[0].id
        );
      }
    },
    selectWorkspace(state, action: PayloadAction<Workspace>) {
      if (!_.isEmpty(action.payload)) {
        state.workspace = action.payload;
        state.selectedPrincipal = action.payload.principal?.find(
          (x) => x.clientWorkspaceId === action.payload.id
        );
      }
    },
    selectPrincipal(state, action: PayloadAction<Principal>) {
      state.selectedPrincipal = action?.payload;
    },
    updateIsInZonoMoney(state, action: PayloadAction<boolean>) {
      state.workspace.isInZonoMoney = action.payload;
    },
    updateSpaceName(state, action: PayloadAction<string>) {
      state.workspace.spaceName = action.payload;
    },
    updateWorkspaceBusinessDetails(
      state,
      action: PayloadAction<WorkSpaceBusinessDetails>
    ) {
      state.workspace.businessDetails = {
        ...state.workspace.businessDetails,
        ...action.payload,
      };
    },
    updateIsPaymentInvoicesAvailable(state, action: PayloadAction<boolean>) {
      state.workspace.isPaymentInvoicesAvailable = action.payload;
    },
    updateUserInviteId(state, action: PayloadAction<string>) {
      state.userInviteId = action.payload;
    },
    clearWorkspaceData(state) {
      state.workspace = initialWorkspaceState.workspace;
      state.workspaces = initialWorkspaceState.workspaces;
      state.selectedPrincipal = initialWorkspaceState.selectedPrincipal;
    },
  },
});

export const workspaceReducer = workspaceSlice.reducer;

export const {
  addWorkspace,
  selectPrincipal,
  selectWorkspace,
  clearWorkspaceData,
  updateSpaceName,
  updateWorkspaceBusinessDetails,
  updateUserInviteId,
} = workspaceSlice.actions;

const getWorkspaceState = (rootState: RootState): workspaceState =>
  rootState[WORKSPACE_FEATURE_KEY];

export const getWorkspaceId = createSelector(
  getWorkspaceState,
  (state) => state?.workspace?.id
);

export const getSelectedPrincipal = createSelector(
  getWorkspaceState,
  (state) => state.selectedPrincipal
);

export const getLinkedWorkspaces = createSelector(getWorkspaceState, (state) =>
  state.workspace?.principal?.filter(
    (x) => x.clientWorkspaceId === state.workspace.id
  )
);

export const getWorkspace = createSelector(
  getWorkspaceState,
  (state) => state.workspace
);

export const getWorkspaces = createSelector(
  getWorkspaceState,
  (state) => state?.workspaces
);

export const getWorkspaceTimezone = createSelector(
  getWorkspaceState,
  (state) => state.workspace?.timeZone
);

export const getUserInviteId = createSelector(
  getWorkspaceState,
  (state) => state.userInviteId
);
