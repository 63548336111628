import { CartColumnKey, ColumnConfig } from '../model';

export const cartConfig: Record<CartColumnKey, ColumnConfig> = {
  skuCode: {
    name: 'SKU Code',
    field: 'textWithImage',
    isHyperlink: true,
  },
  productTitle: {
    name: 'Product Title',
    field: 'multiLine',
  },
  dtProductTitle: {
    name: 'DT Product Name',
  },
  division: { name: 'Division' },
  pts: { name: 'PTS', alignment: 'flex-end' },
  tax: { name: 'Tax (GST)', alignment: 'flex-end', isComponent: true },
  savings: {
    name: 'Scheme Savings',
    color: '#4F9E52',
    alignment: 'flex-end',
  },
  total: { name: 'Total', alignment: 'flex-end' },
  packSize: { name: 'Pack Size', alignment: 'flex-end' },
  moq: { name: 'MOQ', alignment: 'flex-end' },
  uploadedQty: {
    name: 'Uploaded Qty',
    alignment: 'flex-end',
  },
  action: {
    name: 'Ordered Qty',
    field: 'component',
    alignment: 'flex-end',
  },
};
