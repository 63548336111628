import {
  OrderData,
  OrderStatusEnum,
  WEBROUTES,
} from '@zonofi/zono-money-helpers';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

export const useOrderDetailsList = (
  orderDetails: OrderData,
  isCart?: boolean,
  showImage?: boolean,
  showPrice?: boolean
) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [quantityMap, setQuantityMap] = useState<Record<number | string, any>>(
    {}
  );
  const hidePrice = searchParams?.get('hp') === 'true' || !showPrice;
  const getModifiedImageUrl = (url: string) => {
    try {
      const urlObj = new URL(url);
      urlObj.searchParams.set('width', '1024');
      urlObj.searchParams.set('height', '1024');
      return urlObj.toString();
    } catch {
      return url;
    }
  };

  const openProductDetails = (productVariantId: number) => {
    if (
      ![
        OrderStatusEnum.ADDING_ITEMS,
        OrderStatusEnum.DRAFT,
        OrderStatusEnum.SUBMITTED_BY_CUSTOMER,
      ].includes(orderDetails?.status as any)
    ) {
      const orderId = orderDetails?.lines?.find(
        (i) => i?.productVariantId === productVariantId
      )?.orderId;
      const isSubmitted = orderDetails?.status
        ?.toLowerCase()
        ?.includes('submitted');
      const hideAddToCart =
        !isSubmitted ||
        (isSubmitted && ['C', 'Customer'].includes(orderDetails?.userType));
      navigate(
        `${
          WEBROUTES.PRODUCT_DETAILS
        }/${productVariantId}?source=order&orderId=${orderId}${
          hidePrice ? '&hp=true' : ''
        }${hideAddToCart ? '&hideAddToCart=true' : ''}${
          !showImage ? '&hImg=true' : ''
        }`
      );
    } else {
      const queryParams = new URLSearchParams();
      if (isCart) {
        queryParams.append('isCart', 'true');
      }
      if (hidePrice) {
        queryParams.append('hp', 'true');
      }
      if (!showImage) {
        queryParams.append('hImg', 'true');
      }
      const navigationLink = `${
        WEBROUTES.PRODUCT_DETAILS
      }/${productVariantId}?${queryParams.toString()}`;
      navigate(navigationLink);
    }
  };

  useEffect(() => {
    let quantityMapData: any = {};
    orderDetails?.lines?.forEach((item) => {
      if (item?.poFileLineId) {
        quantityMapData[item?.poFileLineId] = item?.quantity;
      } else {
        //@ts-ignore
        quantityMapData[item?.orderLineId] = item?.quantity;
      }
    });
    setQuantityMap({ ...quantityMapData });
  }, [orderDetails]);

  return {
    quantityMap,
    setQuantityMap,
    getModifiedImageUrl,
    openProductDetails,
    hidePrice,
  };
};
