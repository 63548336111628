import { ColumnConfig, SupportUserColumnKey } from '../model';

export const supportUserConfig: Record<SupportUserColumnKey, ColumnConfig> = {
  user: {
    name: 'User',
    field: 'avatarName',
  },
  mobile: {
    name: 'Mobile Number',
  },
  email: {
    name: 'Email ID',
  },
  access: {
    name: 'Access',
  },
  manage: {
    name: '',
    field: 'NavigateLink',
    alignment: 'flex-end',
  },
  action: {
    name: '',
    alignment: 'flex-end',
    flexDirection: 'row',
    field: 'component',
  },
};
