export interface importsModel {
  id: string;
  creationTime: string;
  startTime: string;
  lastUpdatedTime: string;
  totalRecords: number;
  failedRecords: number;
  successfulRecords: number;
  processedRecords: number;
  skippedRecords?: any;
  status: string;
  source: string;
  taskType: string;
  uploadedFileName: string;
  reprocessCount?: number;
  workspaceId: string;
}
export interface BatchesListRequest {
  workspaceId: string;
  pageNo: number;
  pageSize: number;
}
export interface Errors {}

export interface BatchesListResponse {
  success: boolean;
  errors: Errors;
  data: importsModel[];
  totalRecord: number;
  startRecord: number;
  endRecord: number;
}

export interface UploadCSVFileRequest {
  workspaceId: string;
  records: any[];
  fileName: string;
  uploadType: UploadTypes;
}

export interface UploadResponse {
  success: boolean;
  data: UploadResponseData;
  msg: string;
  errors: string;
}

export interface UploadResponseData {
  id: string;
  creationTime: Date;
  startTime: null;
  lastUpdatedTime: Date;
  totalRecords: number;
  failedRecords: number;
  successfulRecords: number;
  processedRecords: number;
  skippedRecords: null;
  status: string;
  source?: string;
  taskType: string;
  uploadedFileName?: string;
  reprocessCount?: number;
  workspaceId: string;
  batchAttempts?: UploadResponseData[];
  priority?: number;
  batchId?: string;
}

export enum UploadTypes {
  customers = 'customers',
  invoices = 'invoices',
  payments = 'payments',
  products = 'products',
}
