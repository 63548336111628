import { ProductVariant } from '../orders';
import { ProductVariants } from '../products/model';

export type PriceListResponse = PriceListItem;
export interface PriceListSearchResponse {
  priceList?: {
    globalPriceListId: string | null;
    exclusivePriceListId: string;
    priceListId: string;
    code: string;
    name: string;
    createdOn: string;
    type: string;
    criteria: string;
    isActive: boolean;
    isArchived: boolean;
    startDate: string;
    endDate: string;
    noOfProducts: number;
    pinCodes: string[];
    cfas: Cfa1[];
  };
  products: ProductInPriceListSearchResponse[];
}

export interface ProductInPriceListSearchResponse {
  parentSku: string;
  productAsset: Record<string, any>;
  source: string;
  preview: string;
  enabled: boolean;
  id: number;
  productVariants: ProductVariant[];
  channelId: number;
  workspaceId: string;
}

export interface PriceListItem {
  pricing: Pricing[];
  startRecord: number;
  endRecord: number;
  totalRecords: number;
}

export interface Pricing {
  code: string;
  name: string;
  createdOn: string;
  type: string;
  criteria: CriteriaEnum;
  status: string;
  priceListId: string;
  noOfProducts: number;
  filterBy: string[];
  segments: { channelId: string; channelName: string }[];
  isActive: boolean;
  isModfied?: boolean;
  cfas: Cfa1[];
  customers: Customer[];
  startDate: string;
  endDate: string;
}
export interface FilterBy {
  cfa: Cfa1[];
  customers: Customer[];
}

interface Cfa1 {
  id: string;
  cfaId: string;
  cfaName: string;
  cfaCount?: number;
}

interface Customer {
  id: string;
  customerId: string;
  customerName: string;
  customerCount: number;
}

export interface PriceListRequest {
  sellerWorkspaceId: string;
  workspaceId?: string;
  searchKey?: string;
  pageNo?: number;
  pageSize?: number;
  cfaIds?: string[];
  customerIds?: string[];
  criteria?: string[];
  status?: string;
  segments?: any;
}

export interface PriceListSearchRequest {
  sellerWorkspaceId: string;
  workspaceId?: string;

  customerId: string;
  productVariantIds?: string[];
}

export interface FormRequest {
  name: string;
  code: string;
  type: string;
  criteria: string;
  priceListItems: PriceListItem[];
  priceConditions: PriceCondition[];
}

export interface PriceListItem {
  productVariantId: number;
  pts: number;
  ptr: number;
}

export interface PriceCondition {
  cfaId: string;
  customerId: string;
  startDate: string;
  endDate: string;
}

export interface PriceListFilterRequest {
  cfaIds: string[];
  customerIds: string[];
  criteria: string[];
  pageNo: number;
  pageSize: number;
  sortBy: string;
  sortDirection: string;
  sellerWorkspaceId: string;
  workspaceId: string;
}

export interface PriceListFilterResponse {
  criteria: CriteriaEnum[];
  customers: Customer[];
  cfa: CfaItem[];
  status: string[];
  segment: any;
}

interface CfaItem {
  id: string;
  isDeault: boolean;
  cfaDivisionCode: string;
  cfaCode: string;
  state: string;
  name: string;
  divisions: Division[];
}

interface Division {
  id: string;
  isDefault: boolean;
  code: string;
  name: string;
  headDivisionId: string;
  headDivisionCode: string;
  headDivisionName: string;
}

export enum CriteriaEnum {
  CFA_CUSTOMER = 'cfa-customer-price',
  CFA = 'cfa-price',
  CUSTOMER = 'customer-price',
  PINCODE = 'pinCode-price',
  PINCODE_CFA = 'pinCode-cfa-price',
  PINCODE_CUSTOMER = 'pinCode-customer-price',
  PINCODE_CFA_CUSTOMER = 'pinCode-cfa-customer-price',
  SEGMENT = 'customer-segment',
}

export interface CreatePriceListBody {
  name: string;
  code: string;
  type?: string;
  criteria?: string;
  startDate: string;
  endDate: string;
  sellerWorkspaceId?: string;
  priceListItems?: UpdatedVlaue[];
  priceConditions?: PriceConditions;
}

export interface UpdatedVlaue {
  productVariantId: number;
  pts: number;
  ptr: number;
}

export interface PriceConditions {
  cfaIds?: string[];
  customerIds?: string[];
  segementIds?: string[];
}

export interface GetPriceListItemsRequest {
  sellerWorkspaceId: string;
  priceListId: string;
}
export interface GetPriceListItemsRsponse {
  priceList: Pricing[];
  getPriceListProducts: existingProductList[];
}

export interface existingProductList {
  parentSku: string;
  productAsset: ProductAsset;
  source: string;
  preview: string;
  enabled: boolean;
  id: number;
  isModified: boolean;
  productVariants: ProductVariants[];
  channelId: number;
  workspaceId: string;
}

export interface ProductAsset {}

export interface ValidatePriceListReq {
  sellerWorkspaceId: string;
  priceListCode: string;
}

export interface ValidatePriceListRes {
  statusCode: number;
  message: string;
  status: string;
  rollbackTransaction: boolean;
  timestamp: string;
}

export interface UpdatePriceListReq {
  sellerWorkspaceId: string;
  priceListId: string;
  isDeactivate?: boolean;
  isDelete?: boolean;
}

export interface ModifiedList {
  sNo: number;
  skuCode: string;
  productTitle: string;
  status: string;
  categories: string;
  id: number;
  sellingPrice: string;
  isModified: boolean;
  marginPrice: string;
  price?: string;
  ptr?: string;
}

export interface PriceListState {
  id?: string;
  name: string;
  code: string;
  type: string;
  criteria: CriteriaEnum;
  startDate: string;
  endDate: string;
  codeErr?: boolean;

  dateSelected: string;

  cfaValue: { name: string; id: string }[];
  customerValue: { name: string; id: string }[];
  segmentValue: { name: string; id: string }[];
  segmentIds: string[];
  cfaIds: string[];
  customerIds: string[];
  pincode?: string[];
  cfaName: string[];
  customerName: string[];

  modifiedList: ModifiedList[];
}
