import React, { useContext, useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { SHARED_SVGS, ThemeContext } from '@zonofi/shared';
import dayjs from 'dayjs';
import './zo-date-picker.scss';

interface DatePickerProps {
  /**
   * Provide the label of date picker.
   */
  title?: string;
  label: string;
  /**
   * Date picker is reqired of not (by default isRequired: false).
   */
  isRequired?: boolean;
  /**
   * Provide the placeholder text for the date picker.
   */
  placeholder?: string;
  /**
   * Callback function to be called on change of the date.
   */
  onChange: (val: any) => void;
  /**
   * Provide value for the date picker.
   */
  value: string | dayjs.Dayjs | null;
  /**
   * Proivde true to make date picker disable. By default false value is given.
   */
  disabled?: boolean;
  /**
   * Provide this value to disable the past. By default false value is given
   */
  disablePast?: boolean;
  /**
   * Indicates whether the date picker field has an error. If true, the date picker field will be displayed in an error state.
   * By defalut false value is given
   */
  error?: boolean;
  /**
   * Minimal selectable date.
   */
  minDate?: any;
  /**
   * An element to display at the start of the input field.
   */
  startAdornment?: React.JSX.Element;
  /**
   * An element to display at the end of the input field.
   * Example: An icon or text.
   */
  endAdornment?: React.JSX.Element;
  /**
   * Props to customize the appearance of the Date field.
   * By default width:100% and height= 32px is given`
   */
  dateFeildStyleProps?: React.CSSProperties;
  /**
   * Additional HTML attributes to be spread onto the input element.
   */
  inputFieldProps?: React.CSSProperties;
}

export const ZoDatePicker = (props: DatePickerProps) => {
  const {
    label,
    title,
    isRequired = false,
    onChange,
    placeholder,
    value,
    disabled = false,
    disablePast = false,
    error = false,
    minDate,
    startAdornment,
    endAdornment,
    dateFeildStyleProps,
    inputFieldProps,
  } = props;
  const [open, setOpen] = useState(false);

  const currentTheme = useContext(ThemeContext);
  const { theme } = currentTheme;
  const [isFocused, setIsFocused] = useState(false);

  const inputFeildStyleContainer = {
    ...theme.fontConfig.regular,
    fontSize: '14px',
    color: `${value ? '#091E42' : '#8f8f8f'}`,
    caretColor: 'transparent',
    cursor: 'pointer',
    ...inputFieldProps,
  };
  const handleClearDate = () => {
    onChange(null); // Reset the date value
    setOpen(false); // Close the popup
  };

  const CustomActionBar = ({ onAccept, onCancel }: any) => (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        padding: '8px 16px',
        borderTop: '1px solid #e0e0e0',
      }}
    >
      <div
        onClick={handleClearDate}
        style={{
          color: theme.secondary,
          fontSize: 14,
          textAlign: 'center',
          textDecorationLine: 'underline',
          ...theme.fontConfig.semiBold,
          cursor: 'pointer',
          lineHeight: '30px'
        }}
      >
        Clear
      </div>

      <div>
        <button
          onClick={onCancel}
          style={{
            marginRight: 12,
            width: 62,
            height: 32,
            borderRadius: 6,
            fontSize: 14,
            background: 'none',
            border: 'none',
            ...theme.fontConfig.semiBold,
            cursor: 'pointer',
            color: '#82807f',
          }}
        >
          Cancel
        </button>
        <button
          onClick={onAccept}
          style={{
            background: '#32A7E8',
            border: 'none',
            color: '#fff',
            cursor: 'pointer',
            fontSize: '14px',
            width: 62,
            height: 32,
            borderRadius: 3,
            ...theme.fontConfig.semiBold,
          }}
        >
          Done
        </button>
      </div>
    </div>
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className="zoDatePicker font-regular" style={{ width: '100%' }}>
        {/* {title && (
          <span
            className={`datepicker_title ${error
              ? 'datepicker_title__error'
              : isFocused
                ? 'datepicker_title__focused'
                : ''
              }`}
            style={{
              color: isFocused ? '#32a7e8' : '#000000',
            }}
          >
            {title}
          </span>
        )} */}
        {title && (
  <span
    className={`datepicker_title ${error
      ? 'datepicker_title__error'
      : open || value
        ? 'datepicker_title__active'
        : ''
      }`}
    style={{
      color: open || value ? '#32a7e8' : '#000000', // Active/selected color: #32a7e8, default: black
    }}
  >
    {title}
  </span>
)}

        <DatePicker
          label={label}
          format="DD-MM-YYYY"
          disabled={disabled}
          disablePast={disablePast}
          minDate={minDate}
          open={!disabled && open}
          onClose={() => setOpen(false)}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: isFocused ? '#32a7e8' : '#dcdcdc',
                borderWidth: '1px',
                borderRadius: '6px',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#32a7e8',
                borderWidth: '1px',
                borderRadius: '6px',
              },
            },
          }}
          onChange={(value) => onChange(value)}
          value={value ? dayjs(value) : null}

          slotProps={{
            textField: {
              onClick: () => {
                setOpen(true);
              },
              placeholder: placeholder,
              disabled: disabled,
              required: isRequired,
              InputLabelProps: {
                style: {
                  ...theme.fontConfig.regular,
                  color: `${value || open ? '#091E42' : '#BFC0C2'}`,
                  ...(value || open ? {} : { marginTop: -10 })
                },
              },
              inputProps: {
                sx: {
                  '&::placeholder': {
                    color: `${open ? '#091E42' : '#BFC0C2'}`,
                    opacity: 1,
                    fontSize: '14px',
                    fontFamily: 'Segoe-UI',
                    display: 'block !important',
                  },
                },
                style: inputFeildStyleContainer,
              },
              InputProps: {
                startAdornment: startAdornment && startAdornment,
                endAdornment: endAdornment && endAdornment,
                placeholder: placeholder,
                error: error,
                sx: {
                  height: '38px',
                  ...dateFeildStyleProps,
                },
              },
            },
          }}
        />
      </div>
    </LocalizationProvider>
  );
};