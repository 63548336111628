import { Channel, ChannelListResponse, Folder, StoredFolder } from '../model';

export const getChannelsAndFolders = (data: ChannelListResponse) => {
  const addEmojiIdToAppChannel = (channel: Channel) => {
    const { appId } = channel;
    switch (appId) {
      case 'CLMAPP01':
        return { ...channel, emojiId: '1f519' };
      case 'LERAPP01':
        return { ...channel, emojiId: '1f4d6' };
      case 'ORDAPP01':
        return { ...channel, emojiId: '1f4e6' };
      case 'PRODUCTAPP':
        return { ...channel, emojiId: '1f389' };
      case 'SCHEMEAPP':
        return { ...channel, emojiId: '1f389' };
      default:
        return channel;
    }
  };

  const convertFolderToStoredFolder = (folder: Folder): StoredFolder => ({
    ...folder,
    channels: folder.channels?.map((channel) => channel.id) || [],
    subFolders: folder.subFolders?.map(convertFolderToStoredFolder),
  });

  const getFolders = (
    folders: Folder[] | undefined,
    parentId: string | null = null
  ): Folder[] => {
    if (!folders) return [];

    return folders.flatMap((folder) => [
      { ...folder, parentId },
      ...getFolders(folder.subFolders, folder.id),
    ]);
  };

  const { folders, rootChannels } = data;
  const allFolders = getFolders(folders);
  const storedFolders = allFolders.map(convertFolderToStoredFolder);
  const nestedChannels = allFolders.flatMap((folder) => folder.channels || []);
  const allChannels = [...nestedChannels, ...rootChannels].map((chnl) =>
    addEmojiIdToAppChannel(chnl)
  );

  return { folders: storedFolders, channels: allChannels };
};
