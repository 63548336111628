import { ColumnConfig, PrincipalOrdersColumnKey } from '../../model';

export const principalOrderConfig: Record<
  PrincipalOrdersColumnKey,
  ColumnConfig
> = {
  orderNo: {
    name: 'Order No.',
    isCheckboxField: true,
    field: 'checkboxNavigate',
    
  },
  orderDate: {
    name: 'Order Date',
    sortField: 'orderDate',
    isVisible: true,
  },
  status: { name: 'Status' },
  skuCount: {
    name: 'SKU Count',
    sortField: 'skuCount',
    alignment: 'flex-end',
  },
  customerName: {
    name: 'Customer Name',
    field: 'avatarNameStatus',
  },
  orderedValue: {
    name: 'Net Order Value',
    sortField: 'orderedAmount',
    alignment: 'flex-end',
  },
  orderSaving: {
    name: 'Order Saving',

    sortField: 'orderSavings',
    alignment: 'flex-end',
  },
  Ztag:{
    name:"Tags",
    alignment:'flex-start'

  },
  invoiceNo: {
    name: 'Invoice No.',
  },
  invoicedQty: {
    name: 'Invoiced Qty',

    sortField: 'invoicedQty',
    alignment: 'flex-end',
  },
  invoicedAmt: {
    name: 'Invoiced Amt',

    sortField: 'invoicedAmount',
    alignment: 'flex-end',
  },
  subTotal: {
    name: 'Sub Total',
    alignment: 'flex-end',
    isVisible: false,
    isAdditional: true,
  },
  orderedBy: {
    name: 'Ordered By',
    isVisible: false,
    isAdditional: true,
  },
  totalQty: {
    name: 'Total Qty',
    alignment: 'flex-end',
    isVisible: false,
    isAdditional: true,
  },
  totalOrderValue: {
    name: 'Total Order Value',
    alignment: 'flex-end',
    isVisible: false,
    isAdditional: true,
  },
  poNo: { name: 'PO Number', isVisible: false, isAdditional: true },
  coNo: { name: 'CO No.', isVisible: false, isAdditional: true },
  headerDivision: {
    name: 'Header Division',
    isAdditional: true,
    isVisible: false,
  },
  divisions: {
    name: 'Divisions',
    isVisible: false,
    isAdditional: true,
  },
  cfa: {
    name: 'CFA',
    isVisible: false,
    isAdditional: true,
  },
  invoicedItems: {
    name: 'Invoiced Items',
    isVisible: false,
    isAdditional: true,
    alignment: 'flex-end',
  },
  invoicedValue: {
    name: 'Invoiced Value',
    isVisible: false,
    isAdditional: true,
    alignment: 'flex-end',
  },
  orderValue: {
    name: 'Order Value',
    isVisible: false,
    isAdditional: true,
    alignment: 'flex-end',
  },
  download: {
    name: '',
    alignment: 'flex-end',
  },
};
