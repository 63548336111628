import { SortField } from '../model';

export const isInitialSort = (
  initialSort: SortField,
  currentSort: SortField
) => {
  return (
    initialSort.field === currentSort.field &&
    initialSort.direction === currentSort.direction
  );
};
