import { Api } from '../api/root-api';
import { getConfigValue } from '@zonofi/common';
import {
  CollectionByTeamMember,
  CollectionByTeamMemberRequest,
  DaybookDueByRouteRecordsRequest,
  DaybookDueByRouteRecordsResponse,
  DaybookSummaryRequest,
  DaybookSummaryResponse,
  DueTodayByCustomersRequest,
  DueTodayByCustomersResponse,
  OutstandingSummaryOfTeamMember,
  DueByCustomersResponse,
  DueByCustomersRequest,
  CollectionReportRequest,
  CollectionReportResponse,
  CollectionSummaryReportResponse,
  DownloadTeamMemberCollectionRequest,
  CustomerLedgerSummary,
  CustomerLedgerSummaryReq,
  OutstandingRouteSummary,
  OutstandingRouteSummaryRequest,
  downloadOutstandingSummaryRequest,
} from './model';

export const daybookApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    getDueSellersByRoutes: builder.query<
      DaybookDueByRouteRecordsResponse,
      DaybookDueByRouteRecordsRequest
    >({
      query(params: DaybookDueByRouteRecordsRequest) {
        return {
          url: `${getConfigValue('API_ENDPOINT')}reports/daybook/due-by-route`,
          method: 'GET',
          params: params,
        };
      },
    }),
    getDueTodayByCustomers: builder.query<
      DueTodayByCustomersResponse[],
      DueTodayByCustomersRequest
    >({
      query(params: DueTodayByCustomersRequest) {
        return {
          url: `${getConfigValue(
            'API_ENDPOINT'
          )}reports/daybook/due-today-by-customer`,
          method: 'GET',
          params: params,
        };
      },
    }),
    getDaybookSummary: builder.query<
      DaybookSummaryResponse,
      { workspaceId: string; daybookSummaryRequest: DaybookSummaryRequest }
    >({
      query(args) {
        const { workspaceId, daybookSummaryRequest } = args;
        return {
          url: `invoiceservice/invoices/aggregated/${workspaceId}`,
          method: 'GET',
          params: daybookSummaryRequest,
        };
      },
    }),
    getCollectionByTeamMembers: builder.query<
      CollectionByTeamMember,
      CollectionByTeamMemberRequest
    >({
      query(params: CollectionByTeamMemberRequest) {
        return {
          url: `${getConfigValue(
            'API_ENDPOINT'
          )}reports/daybook/today-collection-by-member`,
          method: 'GET',
          params: params,
        };
      },
    }),
    getCollectionReport: builder.query<
      CollectionReportResponse,
      CollectionReportRequest
    >({
      query(params: CollectionReportRequest) {
        return {
          url: `invoiceservice/reports/payments`,
          method: 'GET',
          params: params,
        };
      },
    }),
    getCollectionReportSummary: builder.query<
      CollectionSummaryReportResponse,
      CollectionReportRequest
    >({
      query(params: CollectionReportRequest) {
        return {
          url: `invoiceservice/reports/payments/summary`,
          method: 'GET',
          params: params,
        };
      },
    }),
    getDueByCustomersReport: builder.query<
      DueByCustomersResponse,
      DueByCustomersRequest
    >({
      query(params: DueTodayByCustomersRequest) {
        return {
          url: `${getConfigValue(
            'API_ENDPOINT'
          )}reports/daybook/due-by-customer-report`,
          method: 'GET',
          params: params,
        };
      },
    }),
    getOutstandingSummaryOfTeamMembers: builder.query<
      OutstandingSummaryOfTeamMember,
      CollectionByTeamMemberRequest
    >({
      query(params: CollectionByTeamMemberRequest) {
        return {
          url: `${getConfigValue(
            'API_ENDPOINT'
          )}reports/daybook/dueOfTeamMember`,
          method: 'GET',
          params: params,
        };
      },
    }),
    getCustomerLedgerSummary: builder.query<
      CustomerLedgerSummary,
      CustomerLedgerSummaryReq
    >({
      query(params: CustomerLedgerSummaryReq) {
        return {
          url: `${getConfigValue(
            'API_ENDPOINT'
          )}reports/daybook/customer-ledger-summary`,
          method: 'GET',
          params: params,
        };
      },
    }),
    getOutstandingRouteSummary: builder.query<
      OutstandingRouteSummary,
      OutstandingRouteSummaryRequest
    >({
      query(params: OutstandingRouteSummaryRequest) {
        return {
          url: `invoiceservice/reports/invoices`,
          method: 'GET',
          params: params,
        };
      },
    }),
    downloadTeamMemberOutstandingSummary: builder.query<
      any,
      CollectionByTeamMemberRequest
    >({
      query(params: CollectionByTeamMemberRequest) {
        return {
          url: `${getConfigValue(
            'API_ENDPOINT'
          )}reports/daybook/dueOfTeamMember/download`,
          method: 'GET',
          params: params,
          responseHandler: (response) => {
            return response.arrayBuffer();
          },
        };
      },
    }),
    downloadDueByCustomers: builder.query<any, CollectionByTeamMemberRequest>({
      query(params: CollectionByTeamMemberRequest) {
        return {
          url: `${getConfigValue(
            'API_ENDPOINT'
          )}reports/daybook/due-by-customer-report/download`,
          method: 'GET',
          params: params,
          responseHandler: (response) => {
            return response.arrayBuffer();
          },
        };
      },
    }),
    downloadDueByRoutes: builder.query<any, CollectionByTeamMemberRequest>({
      query(params: CollectionByTeamMemberRequest) {
        return {
          url: `${getConfigValue(
            'API_ENDPOINT'
          )}reports/daybook/due-by-route/download`,
          method: 'GET',
          params: params,
          responseHandler: (response) => {
            return response.arrayBuffer();
          },
        };
      },
    }),
    downloadCollectionByTeamMembers: builder.query<
      any,
      CollectionByTeamMemberRequest
    >({
      query(params: CollectionByTeamMemberRequest) {
        return {
          url: `${getConfigValue(
            'API_ENDPOINT'
          )}reports/daybook/today-collection-by-member/download`,
          method: 'GET',
          params: params,
          responseHandler: (response) => {
            return response.arrayBuffer();
          },
        };
      },
    }),
    downloadTeamMemberCollection: builder.query<
      any,
      DownloadTeamMemberCollectionRequest
    >({
      query(params: DownloadTeamMemberCollectionRequest) {
        return {
          url: `invoiceservice/reports/payments/download`,
          method: 'GET',
          params: params,
          responseHandler: (response) => {
            return response.arrayBuffer();
          },
        };
      },
    }),
    downloadCustomerLedgerSummary: builder.query<
      any,
      CollectionByTeamMemberRequest
    >({
      query(params: CollectionByTeamMemberRequest) {
        return {
          url: `${getConfigValue(
            'API_ENDPOINT'
          )}reports/daybook/customer-ledger-summary/download`,
          method: 'GET',
          params: params,
          responseHandler: (response) => {
            return response.arrayBuffer();
          },
        };
      },
    }),
    downloadOutstandingSummary: builder.query<
      any,
      downloadOutstandingSummaryRequest
    >({
      query(params: downloadOutstandingSummaryRequest) {
        return {
          url: `invoiceservice/reports/invoices/download`,
          method: 'GET',
          params: params,
          responseHandler: (response) => {
            return response.arrayBuffer();
          },
        };
      },
    }),
  }),
  overrideExisting: true,
});
export const {
  useGetDueSellersByRoutesQuery,
  useLazyGetDueSellersByRoutesQuery,
  useGetDueTodayByCustomersQuery,
  useLazyGetDueTodayByCustomersQuery,
  useLazyGetDaybookSummaryQuery,
  useLazyGetCollectionByTeamMembersQuery,
  useLazyGetOutstandingSummaryOfTeamMembersQuery,
  useLazyGetDueByCustomersReportQuery,
  useLazyGetCollectionReportQuery,
  useLazyGetCollectionReportSummaryQuery,
  useLazyGetCustomerLedgerSummaryQuery,
  useLazyGetOutstandingRouteSummaryQuery,
  useLazyDownloadTeamMemberOutstandingSummaryQuery,
  useLazyDownloadDueByCustomersQuery,
  useLazyDownloadDueByRoutesQuery,
  useLazyDownloadCollectionByTeamMembersQuery,
  useLazyDownloadTeamMemberCollectionQuery,
  useLazyDownloadCustomerLedgerSummaryQuery,
  useLazyDownloadOutstandingSummaryQuery,
} = daybookApi;
