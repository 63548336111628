import { Api } from '../api/root-api';
import {
  AadharVerificationRequest,
  BankAccountVerificationRequest,
  BankAccountVerificationResponse,
  KycStatusRequest,
  KycStatusResponse,
  KycSummary,
  PanVerificationRequest,
} from './model';

export const kycApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    updatePanDetails: builder.mutation<
      KycStatusResponse,
      PanVerificationRequest
    >({
      query(params) {
        return { url: `invoiceservice/kyc/pan`, method: 'POST', body: params };
      },
    }),
    updateAadharDetails: builder.mutation<
      KycStatusResponse,
      AadharVerificationRequest
    >({
      query(params) {
        return {
          url: `invoiceservice/kyc/aadhar`,
          method: 'POST',
          body: params,
        };
      },
    }),
    updateBankDetails: builder.mutation<
      BankAccountVerificationResponse,
      BankAccountVerificationRequest
    >({
      query(params) {
        return {
          url: `invoiceservice/bankaccount`,
          method: 'POST',
          body: params,
        };
      },
    }),
    getKycDetails: builder.query<KycStatusResponse, KycStatusRequest>({
      query(params) {
        return { url: `invoiceservice/kyc`, method: 'GET', params: params };
      },

      // async onQueryStarted(id, { dispatch, queryFulfilled }) {
      //   try {
      //     const { data } = await queryFulfilled;
      //     let workspaces = store.getState().workspace.workspaces;
      //     workspaces = workspaces.map((x) =>
      //       x.id === data.workspaceId
      //         ? { ...x, isInZonoMoney: data.kycStatus === KYCStatus.APPROVED }
      //         : x
      //     );
      //     dispatch(addWorkspace(workspaces));
      //   } catch (err) {
      //     console.log(err, 'dispatchErr');
      //   }
      // },
    }),
    getKycSummary: builder.query<KycSummary, { workspaceId: string }>({
      query(params) {
        return {
          url: `invoiceservice/kyc/summary`,
          method: 'GET',
          params: params,
        };
      },
    }),
    submitKyc: builder.mutation<KycSummary, { workspaceId: string }>({
      query(params) {
        return {
          url: `invoiceservice/kyc/submit`,
          method: 'POST',
          body: params,
        };
      },
    }),
  }),
});

export const {
  useUpdatePanDetailsMutation,
  useUpdateAadharDetailsMutation,
  useUpdateBankDetailsMutation,
  useLazyGetKycDetailsQuery,
  useLazyGetKycSummaryQuery,
  useSubmitKycMutation,
} = kycApi;
