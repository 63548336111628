import { CfaSelectCustomerColumnKey, ColumnConfig } from '../../model';

export const cfaSelectCustomerConfig: Record<
  CfaSelectCustomerColumnKey,
  ColumnConfig
> = {
  customerName: { name: 'Customer Name', field: 'avatarNameStatus' },
  status: {
    name: 'Status',
    field: 'customerListStatusTag',
  },
  customerCode: { name: 'Customer Code' },
  mobileNumber: {
    name: 'Mobile Number',
    field: 'mobileNumber',
  },
  gstNumber: {
    name: 'GST Number',
    field: 'gstNumber',
  },
  placeOrder: { name: '', field: 'textWithImage' },
};
