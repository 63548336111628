import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

export const linkedWorkspacesAdapter = createEntityAdapter<any>({
  // Keep the "all IDs" array sorted based on book titles
  selectId: (linkedWorkspace) => linkedWorkspace?.id,
});

export const linkedWorkspacesSlice = createSlice({
  name: 'linkedWorkspaceData',
  initialState: linkedWorkspacesAdapter.getInitialState(),
  reducers: {
    addLinkedWorkspace: linkedWorkspacesAdapter.addOne,
    addLinkedWorkspaces: linkedWorkspacesAdapter.addMany,
    updateLinkedWorkspace: linkedWorkspacesAdapter.upsertOne,
    updateLinkedWorkspaces: linkedWorkspacesAdapter.upsertMany,
    getLinkedWorkspacesSuccess(state, action: PayloadAction<any>) {
      linkedWorkspacesAdapter.addMany(state, action.payload.workspaces);
      console.log('state in get linked workspace success', state);
      return state;
    },
    getLinkedWorkspacesFailed(state, action: PayloadAction<string>) {
      console.log('in get linked workspace failure ', state, action);
    },
  },
});

export const {
  addLinkedWorkspace,
  addLinkedWorkspaces,
  updateLinkedWorkspace,
  updateLinkedWorkspaces,
  getLinkedWorkspacesSuccess,
  getLinkedWorkspacesFailed,
} = linkedWorkspacesSlice.actions;

export default linkedWorkspacesSlice.reducer;
