import { Api } from '../api/root-api';
import { MediaCopyRequest, MediaHeadersRequest, MediaRequest } from './model';

export const mediaApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    getSignUrl: builder.mutation<any, MediaRequest>({
      query(MediaRequest) {
        return {
          url: `sign/url`,
          method: 'POST',
          body: MediaRequest,
        };
      },
    }),
    getSignUrlWithHeaders: builder.mutation<any, MediaHeadersRequest>({
      query(MediaHeadersRequest) {
        return {
          url: `sign/headers`,
          method: 'POST',
          body: MediaHeadersRequest,
        };
      },
    }),
    copyMedia: builder.mutation<any, MediaCopyRequest>({
      query(MediaCopyRequest) {
        return {
          url: `images/copy`,
          method: 'POST',
          body: MediaCopyRequest,
          responseHandler: (response) => response.text(),
        };
      },
    }),
  }),
});

export const {
  useGetSignUrlMutation,
  useGetSignUrlWithHeadersMutation,
  useCopyMediaMutation,
} = mediaApi;
