import { useEffect, useState } from 'react';
import './catalog-image-search-result.scss';
import {
  PIMatch,
  PIMatchedProduct,
  getCustomerInvite,
  useAddItemToActiveOrderMutation,
} from '@zonofi/zono-money-store';
import { useAppSelector, useCart } from '@zonofi/zono-money-helpers';
import { useSearchParams } from 'react-router-dom';

export const useCatalogImageSearchResult = (
  parseProductResponse: PIMatch[]
) => {
  const { seller, inviteId } = useAppSelector(getCustomerInvite);
  const [queryParams] = useSearchParams();
  const hidePrice = queryParams.get('hp') === 'true';
  const [parsedProducts, setParsedProducts] = useState<PIMatch[]>([]);
  const [quantityMap, setQuantityMap] = useState<Record<number, any>>({});

  const {
    callCartAPI,
    cartAPIResponse,
    productsInCart,
    setProductsInCart,
    cartSummaryAPIResponse,
    onIncrement,
    onDecrement,
    onDelete,
    onChangeQuantity,
    editOrderResponse,
  } = useCart();

  const [addToCartAPI, addToCartAPIResponse] =
    useAddItemToActiveOrderMutation();

  const onAddToCart = (item: PIMatchedProduct) => {
    addToCartAPI({
      sellerWorkspaceId: seller?.workspaceId,
      customerId: inviteId,
      source: 'whatsapp',
      lines: [
        {
          productVariantId: item?.variantId,
          quantity: item?.moq,
          operator: 'add',
        },
      ],
    });
  };

  useEffect(() => {
    if (addToCartAPIResponse?.isLoading) {
      const pvid =
        addToCartAPIResponse?.originalArgs?.lines?.[0]?.productVariantId;
      if (pvid) {
        setParsedProducts((prevState) => {
          return prevState?.map((i) => {
            if (i?.matchedProducts?.length !== 0) {
              return {
                ...i,
                matchedProducts: i?.matchedProducts?.map((j) => {
                  if (j?.variantId === pvid) {
                    return {
                      ...j,
                      isLoading: true,
                    };
                  }
                  return j;
                }),
              };
            }
            return {
              ...i,
            };
          });
        });
      }
    } else {
      setParsedProducts((prevState) => {
        return prevState?.map((i) => {
          if (i?.matchedProducts?.length !== 0) {
            return {
              ...i,
              matchedProducts: i?.matchedProducts?.map((j) => {
                return {
                  ...j,
                  isLoading: false,
                };
              }),
            };
          }
          return {
            ...i,
          };
        });
      });
    }
  }, [addToCartAPIResponse]);

  useEffect(() => {
    if (editOrderResponse?.isLoading) {
      console.log('editOrderResponse: ', editOrderResponse);
      const pvid =
        editOrderResponse?.originalArgs?.body?.lines?.[0]?.productVariantId;
      if (pvid) {
        setParsedProducts((prevState) => {
          return prevState?.map((i) => {
            if (i?.matchedProducts?.length !== 0) {
              return {
                ...i,
                matchedProducts: i?.matchedProducts?.map((j) => {
                  if (j?.variantId === pvid) {
                    return {
                      ...j,
                      isLoading: true,
                    };
                  }
                  return j;
                }),
              };
            }
            return {
              ...i,
            };
          });
        });
      }
    } else {
      setParsedProducts((prevState) => {
        return prevState?.map((i) => {
          if (i?.matchedProducts?.length !== 0) {
            return {
              ...i,
              matchedProducts: i?.matchedProducts?.map((j) => {
                return {
                  ...j,
                  isLoading: false,
                };
              }),
            };
          }
          return {
            ...i,
          };
        });
      });
    }
  }, [editOrderResponse]);

  useEffect(() => {
    callCartAPI({
      customerId: inviteId,
      sellerWorkspaceId: seller?.workspaceId,
      workspaceId: seller?.workspaceId,
    });
  }, []);

  useEffect(() => {
    if (addToCartAPIResponse?.isSuccess) {
      callCartAPI({
        customerId: inviteId,
        workspaceId: seller?.workspaceId,
        sellerWorkspaceId: seller?.workspaceId,
      });
    }
  }, [addToCartAPIResponse]);

  useEffect(() => {
    if (editOrderResponse?.isSuccess) {
      callCartAPI({
        customerId: inviteId,
        workspaceId: seller?.workspaceId,
        sellerWorkspaceId: seller?.workspaceId,
      });
    }
  }, [editOrderResponse]);

  useEffect(() => {
    setParsedProducts(parseProductResponse);
  }, [parseProductResponse]);

  useEffect(() => {
    let quantityMapData: Record<number, any> = {};
    productsInCart?.forEach((product) => {
      quantityMapData[product?.productVariantId] = product?.quantity;
    });
    setQuantityMap({ ...quantityMapData });
  }, [productsInCart]);

  return {
    callCartAPI,
    cartAPIResponse,
    productsInCart,
    setProductsInCart,
    cartSummaryAPIResponse,
    onIncrement,
    onDecrement,
    onDelete,
    onChangeQuantity,
    editOrderResponse,
    quantityMap,
    parsedProducts,
    onAddToCart,
    setQuantityMap,
    hidePrice,
  };
};
