import { ChatActionViewPaymentColumnKey, ColumnConfig } from '../../model';

export const chatActionViewPaymentConfig: Record<
  ChatActionViewPaymentColumnKey,
  ColumnConfig
> = {
    invoiceNumber: {
        name: 'Invoice Number',
      },
      invoiceDate: {
        name: 'Invoice Date',
        sortField: 'date',
        headerExtra: {
          type: 'info',
          details: 'Created Invoice Date',
          title: 'Invoice Date',
        },
      },
      dueDate: {
        name: 'Due Date',
        sortField: 'dueDate',
      },
    
      invoiceAmount: {
        name: 'Invoice Amount',
        sortField: 'invoiceAmount',
        alignment: 'flex-end',
      },
      amountCleared: {
        name: 'Amount Cleared',
        sortField: 'amountCleared',
        headerExtra: {
          type: 'info',
          details: '',
          title: 'Amount Cleared',
        },
        alignment: 'flex-end',
      },
      totalSettled: {
        name: 'Total Settled',
        sortField: 'totalSettled',
        isVisible: false,
        // headerExtra: {
        //   type: 'info',
        //   details: '',
        //   title: 'Total Settled',
        // },
        alignment: 'flex-end',
        
      },
      totalPending: {
        name: 'Total Pending',
        sortField: 'totalPending',
        isVisible: false,
        // headerExtra: {
        //   type: 'info',
        //   details: '',
        //   title: 'Total Pending',
        // },
        alignment: 'flex-end',
      },
};
