import { ListInputItemOptions, SiftScreenEnum } from '../filter/model';
import { addListInputItems, updateSiftByScreen } from '../filter/slice';
import { Api } from '../api/root-api';
import { InvoiceStatusNames } from '../helpers';
import {
  OtifAbsoluteOrdersDetailResponse,
  OtifDetailsRequest,
  OtifReceviedOrdersDetailResponse,
  OtifOrderFulfilmentDetailResponse,
  DailyOrdersReportRequest,
  DailyInvoicesReportRequest,
  DailyPaymentsReportRequest,
  DailyOrdersReportResponse,
  DailyPaymentsReportResponse,
  DailyInvoicesReportResponse,
  CustomersByOrderValueResponse,
  CustomersByOrderValueRequest,
  ProductsByQuantitySoldRequest,
  ProductsByQuantitySoldResponse,
  ReceivedOrdersReportRequest,
  RevenueReportRequest,
  RevenueReportResponse,
  ReceivedOrdersReportResponse,
  SalesLeaderboardRequest,
  SalesLeaderBoardResponse,
  RecentOrdersReportRequest,
  RecentOrdersReportResponse,
  RecentInvoicesReportRequest,
  RecentPaymentsReportRequest,
  RecentInvoicesReportResponse,
  RecentPaymentsReportResponse,
  ZoAiReportRequest,
  ZoAiReportResponse,
  CustomerCfaReportRequest,
  CustomerCfaReportResponse,
  ReportDownloadRequest,
  LedgerSummaryReportsRequest,
  LedgerSummaryReportsResponse,
  OrderSummaryReportDownloadReq,
  OrderDetailsReportResponse,
  OrderDetailsReportRequest,
  SalesLeaderboardReportResponse,
  SalesLeaderboardReportRequest,
  SalesLeaderboardReportDownloadRequest,
  InvoiceReportRequest,
  InvoiceReportResponse,
  CustomerReportDownloadRequest,
  ProductReportDownloadRequest,
  ProductPerformanceReportFilterRequest,
  salesTeamCheckinReportResponse,
  salesTeamCheckinReportRequest,
  PaymentsReportResponse,
  PaymentsReportRequest,
  ProductPerformanceReportFilterResponse,
  CustomerPerformanceReportFilterRequest,
  CustomerPerformanceReportFilterResponse,
  InviceReportFilterResponse,
  InviceReportFilterRequest,
  SalesTeamCheckinsReportFilterResponse,
  SalesTeamCheckinsReportFilterRequest,
  PaymentReportFilterResponse,
  PaymentReportFilterRequest,
  SupplyTrackerReportFilterResponse,
  SupplyTrackerReportFilterRequest,
  OrderDetailsReportFilterResponse,
  OrderDetailsReportFilterRequest,
  invoicesReportDownloadRequest,
  PaymentsReportDownloadRequest,
  SalesTeamCheckinReportDownloadRequest,
  SupplyTrackerReportDownloadRequest,
  BusinessSummaryReportResponse,
  BusinessSummaryReportRequest,
  UserKpis,
  KpiListRequest,
  ModifyKpisResponse,
  ModifyKpisRequest,
  KpiDetailsResponse,
  KpiDetailsRequest,
  SegmentAnalysisRequest,
  SegmentAnalysisResponse,
  DashboardFilterResponse,
  DashboardFilterRequest,
  UpdateDashboardFilterRequest,
  AppliedDashboardFiltersResponse,
  AppliedDashboardFiltersRequest,
  cfaCustomerWiseProductReportRequest,
  cfaCustomerWiseProductReportResponse,
  cfaCustomerWiseProductReportFilterResponse,
  cfaCustomerWiseProductReportFilterRequest,
} from './model';
import {
  mapDataToInputItems,
  mapDataToInputItemsFromAppliedFilter,
} from '../filter';

export const reportsApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    generateOtifAbsoluteOrdersReport: builder.mutation<
      OtifAbsoluteOrdersDetailResponse,
      OtifDetailsRequest
    >({
      query(arg) {
        const { workspaceId, ...otherParams } = arg;
        return {
          url: `dna-report/on-time-in-full/absolute-orders/${workspaceId}`,
          method: 'POST',
          body: otherParams,
        };
      },
    }),
    generateOtifRecivedOrdersReport: builder.mutation<
      OtifReceviedOrdersDetailResponse,
      OtifDetailsRequest
    >({
      query(arg) {
        const { workspaceId, ...otherParams } = arg;
        return {
          url: `on-time-in-full/received-orders/${workspaceId}`,
          method: 'POST',
          body: otherParams,
        };
      },
    }),
    generateOtifOrderFulfilmentReport: builder.mutation<
      OtifOrderFulfilmentDetailResponse,
      OtifDetailsRequest
    >({
      query(arg) {
        const { workspaceId, ...otherParams } = arg;
        return {
          url: `on-time-in-full/order-fulfilment/${workspaceId}`,
          method: 'POST',
          body: otherParams,
        };
      },
    }),

    revenueReport: builder.mutation<
      RevenueReportResponse,
      RevenueReportRequest
    >({
      query(arg) {
        return {
          url: '/hub/reports/api/v2/revenue-report',
          method: 'POST',
          body: arg,
        };
      },
    }),
    salesLeaderBoard: builder.mutation<
      SalesLeaderBoardResponse,
      SalesLeaderboardRequest
    >({
      query: (arg) => ({
        url: 'hub/reports/api/v2/sales-order-report/leader-board',
        method: 'POST',
        body: arg,
      }),
    }),
    receivedOrdersReport: builder.mutation<
      { data: ReceivedOrdersReportResponse[]; updatedAt: string },
      ReceivedOrdersReportRequest
    >({
      query(arg) {
        return {
          url: '/hub/reports/api/v2/received-orders',
          method: 'POST',
          body: arg,
        };
      },
      transformResponse: (response: {
        data: ReceivedOrdersReportResponse[];
        updatedAt: string;
      }) => {
        return {
          data: response?.data,
          updatedAt: response?.updatedAt,
        };
      },
    }),
    dailyOrdersReport: builder.mutation<
      DailyOrdersReportResponse,
      DailyOrdersReportRequest
    >({
      query(arg) {
        return {
          url: '/hub/reports/api/v2/orders-report',
          method: 'POST',
          body: arg,
        };
      },
    }),
    dailyPaymentsReport: builder.mutation<
      DailyPaymentsReportResponse,
      DailyPaymentsReportRequest
    >({
      query(arg) {
        return {
          url: '/hub/reports/api/v2/daily-payments-report',
          method: 'POST',
          body: arg,
        };
      },
    }),
    dailyInvoicesReport: builder.mutation<
      DailyInvoicesReportResponse,
      DailyInvoicesReportRequest
    >({
      query(arg) {
        return {
          url: '/hub/reports/api/v2/daily-invoice-report',
          method: 'POST',
          body: arg,
        };
      },
    }),
    topCustomersByOrderValue: builder.mutation<
      CustomersByOrderValueResponse,
      CustomersByOrderValueRequest
    >({
      query(arg) {
        return {
          url: '/hub/reports/api/v2/customer-performance-report',
          method: 'POST',
          body: arg,
        };
      },
    }),
    bottomCustomersByOrderValue: builder.mutation<
      CustomersByOrderValueResponse,
      CustomersByOrderValueRequest
    >({
      query(arg) {
        return {
          url: '/hub/reports/api/v2/customer-performance-report',
          method: 'POST',
          body: arg,
        };
      },
    }),
    topProductsByQuantitySold: builder.mutation<
      ProductsByQuantitySoldResponse,
      ProductsByQuantitySoldRequest
    >({
      query(arg) {
        return {
          url: '/hub/reports/api/v2/product-performance-report',
          method: 'POST',
          body: arg,
        };
      },
    }),
    bottomProductsByQuantitySold: builder.mutation<
      ProductsByQuantitySoldResponse,
      ProductsByQuantitySoldRequest
    >({
      query(arg) {
        return {
          url: '/hub/reports/api/v2/product-performance-report',
          method: 'POST',
          body: arg,
        };
      },
    }),
    zoaiReport: builder.mutation<ZoAiReportResponse, ZoAiReportRequest>({
      query(args) {
        return {
          url: '/hub/reports/api/v2/zoai-reports',
          method: 'POST',
          body: args,
        };
      },
    }),
    recentOrdersReport: builder.query<
      RecentOrdersReportResponse[],
      RecentOrdersReportRequest
    >({
      query(arg) {
        return {
          url: `/hub/reports/api/v2/recent-orders`,
          method: 'GET',
          params: arg,
        };
      },
    }),
    recentInvoiceReport: builder.query<
      RecentInvoicesReportResponse[],
      RecentInvoicesReportRequest
    >({
      query(arg) {
        return {
          url: `/hub/reports/api/v2/recent-invoices`,
          method: 'GET',
          params: arg,
        };
      },
    }),
    recentPaymentReport: builder.query<
      RecentPaymentsReportResponse[],
      RecentPaymentsReportRequest
    >({
      query(arg) {
        return {
          url: `/hub/reports/api/v2/recent-payments`,
          method: 'GET',
          params: arg,
        };
      },
    }),

    getPaymentReportApi: builder.mutation<
      PaymentsReportResponse,
      PaymentsReportRequest
    >({
      query(arg) {
        return {
          url: `/hub/reports/api/v2/recent-payments`,
          method: 'POST',
          body: arg,
        };
      },
    }),

    downloadCfaReports: builder.mutation<string, ReportDownloadRequest>({
      query(arg) {
        return {
          url: `/hub/reports/api/v2/customer-or-cfa-product-wise-order-report/download`,
          method: 'POST',
          body: arg,
          responseHandler: (response) => response.blob(),
        };
      },
    }),
    cfaCustomerWiseProductReport: builder.mutation<
      cfaCustomerWiseProductReportResponse,
      cfaCustomerWiseProductReportRequest
    >({
      query(arg) {
        return {
          url: `/hub/reports/api/v2/customer-or-cfa-product-wise-order-report`,
          method: 'POST',
          body: arg,
        };
      },
    }),

    cfaCustomerWiseProductReportFilter: builder.mutation<
      cfaCustomerWiseProductReportFilterResponse,
      cfaCustomerWiseProductReportFilterRequest
    >({
      query(arg) {
        return {
          url: `/hub/reports/api/v2/customer-or-cfa-product-wise-order-report/filter`,
          method: 'POST',
          body: arg.body,
        };
      },
      onQueryStarted(request, { dispatch, queryFulfilled }) {
        if (request?.filterScreen as SiftScreenEnum) {
          queryFulfilled?.then((response) => {
            const customerInputItems = mapDataToInputItemsFromAppliedFilter(
              response?.data?.customers,
              'inviteId',
              'customerName',
              ''
            )?.filter((x: any) => x.value);

            const fulfillmentsInputItems = mapDataToInputItemsFromAppliedFilter(
              response?.data?.cfas,
              'cfaId',
              'cfaName',
              ''
            )?.filter((x: any) => x.value);

            dispatch(
              updateSiftByScreen({
                screen: request?.filterScreen,
                filters: {
                  customer: customerInputItems,
                  cfa: fulfillmentsInputItems,
                },
              })
            );
          });
        }
      },
    }),

    customerCfaReport: builder.mutation<
      CustomerCfaReportResponse,
      CustomerCfaReportRequest
    >({
      query: (arg) => {
        const { dispatchFilters, ...otherFilters } = arg;
        return {
          url: '/hub/reports/api/v2/cfa-product-wise-order-grouping-report',
          method: 'POST',
          body: otherFilters,
        };
      },
      async onQueryStarted(request, { dispatch, queryFulfilled }) {
        queryFulfilled.then((response) => {
          if (request.dispatchFilters) {
            const tags = [
              {
                id: '12am to 1pm',
                name: 'Morning',
              },
              {
                id: '1pm to 12am',
                name: 'Evening',
              },
            ];
            const tagsItems = mapDataToInputItems(tags, 'id', 'name')?.filter(
              (x: any) => x.value
            );

            const cfas = response?.data?.fulfillments
              ?.map((x) => ({
                id: x?.cfaIds,
                name: x?.cfaName,
              }))
              ?.filter(
                (obj, index, self) =>
                  index === self.findIndex((o) => o.id === obj.id)
              );

            const customers = response?.data?.customers
              ?.map((x) => ({
                id: x?.customerId,
                name: x?.customerFirmName,
              }))
              ?.filter(
                (obj, index, self) =>
                  index === self.findIndex((o) => o.id === obj.id)
              );

            const cfaItems = mapDataToInputItems(cfas, 'id', 'name')?.filter(
              (x: any) => x.value
            );

            const customerItems = mapDataToInputItems(
              customers,
              'id',
              'name'
            )?.filter((x: any) => x.value);

            dispatch(
              addListInputItems({
                inputItems: cfaItems,
                key: ListInputItemOptions.cfaReports,
              })
            );
            dispatch(
              addListInputItems({
                inputItems: customerItems,
                key: ListInputItemOptions.cfaCustomers,
              })
            );

            dispatch(
              addListInputItems({
                inputItems: tagsItems,
                key: ListInputItemOptions.tags,
              })
            );
          }
        });
      },
    }),
    ledgerSummaryReport: builder.mutation<
      LedgerSummaryReportsResponse,
      LedgerSummaryReportsRequest
    >({
      query(arg) {
        return {
          url: `/hub/reports/api/v2/ledger-summary-report`,
          method: 'POST',
          body: arg,
        };
      },
    }),

    orderDetailsReport: builder.mutation<
      OrderDetailsReportResponse,
      OrderDetailsReportRequest
    >({
      query(arg) {
        return {
          url: `/hub/reports/api/v2/order-details-report`,
          method: 'POST',
          body: arg,
        };
      },
    }),
    orderDetailsReportFilter: builder.mutation<
      OrderDetailsReportFilterResponse,
      OrderDetailsReportFilterRequest
    >({
      query(arg) {
        return {
          url: `/hub/reports/api/v2/order-details-report/filter`,
          method: 'POST',
          body: arg.body,
        };
      },

      onQueryStarted(request, { dispatch, queryFulfilled }) {
        if (request?.filterScreen as SiftScreenEnum) {
          queryFulfilled?.then((response) => {
            const customerInputItems = mapDataToInputItemsFromAppliedFilter(
              response?.data?.customers,
              'inviteId',
              'customerName',
              ''
            )?.filter((x: any) => x.value);

            const fulfillmentsInputItems = mapDataToInputItemsFromAppliedFilter(
              response?.data?.fulfillments,
              'cfaId',
              'cfaName',
              ''
            )?.filter((x: any) => x.value);

            const salesManInputItems = mapDataToInputItemsFromAppliedFilter(
              response?.data?.salesmen,
              'salesmenId',
              'salesTeamMemberName',
              ''
            )?.filter((x: any) => x.value);

            const tags = [
              {
                id: '12am to 1pm',
                name: 'Morning',
              },
              {
                id: '1pm to 12am',
                name: 'Evening',
              },
            ];
            const tagsItems = mapDataToInputItems(tags, 'id', 'name')?.filter(
              (x: any) => x.value
            );

            dispatch(
              updateSiftByScreen({
                screen: request?.filterScreen,
                filters: {
                  customer: customerInputItems,
                  cfa: fulfillmentsInputItems,
                  salesmen: salesManInputItems,
                  // tags: tagsItems,
                },
              })
            );
          });
        }
      },
    }),

    orderSummaryReportDownload: builder.mutation<
      any,
      OrderSummaryReportDownloadReq
    >({
      query(arg) {
        return {
          url: '/hub/reports/api/v2/order-details-report/download',
          method: 'POST',
          body: arg.body,
          responseHandler: (response) => response.blob(),
        };
      },
    }),

    salesLeaderboardReport: builder.mutation<
      SalesLeaderboardReportResponse,
      SalesLeaderboardReportRequest
    >({
      query(arg) {
        return {
          url: `/hub/reports/api/v2/sales-order-report/leader-board`,
          method: 'POST',
          body: arg,
        };
      },
    }),

    salesLeaderboardReportDownload: builder.mutation<
      any,
      SalesLeaderboardReportDownloadRequest
    >({
      query(arg) {
        return {
          url: '/hub/reports/api/v2/sales-order-report/leader-board/download',
          method: 'POST',
          body: arg,
          responseHandler: (response) => response.blob(),
        };
      },
    }),

    customerPerformanceReportDownload: builder.mutation<
      any,
      CustomerReportDownloadRequest
    >({
      query(arg) {
        return {
          url: '/hub/reports/api/v2/customer-performance-report/download',
          method: 'POST',
          body: arg,
          responseHandler: (response) => response.blob(),
        };
      },
    }),

    paymentsReportDownload: builder.mutation<
      any,
      PaymentsReportDownloadRequest
    >({
      query(arg) {
        return {
          url: '/hub/reports/api/v2/recent-payments/download',
          method: 'POST',
          body: arg,
          responseHandler: (response) => response.blob(),
        };
      },
    }),
    invoicesReportDownload: builder.mutation<
      any,
      invoicesReportDownloadRequest
    >({
      query(arg) {
        return {
          url: '/hub/reports/api/v2/recent-invoices/download',
          method: 'POST',
          body: arg,
          responseHandler: (response) => response.blob(),
        };
      },
    }),

    productPerformanceReportDownload: builder.mutation<
      any,
      ProductReportDownloadRequest
    >({
      query(arg) {
        return {
          url: '/hub/reports/api/v2/product-performance-report/download',
          method: 'POST',
          body: arg,
          responseHandler: (response) => response.blob(),
        };
      },
    }),

    invoiceReport: builder.mutation<
      InvoiceReportResponse,
      InvoiceReportRequest
    >({
      query(arg) {
        return {
          url: '/hub/reports/api/v2/recent-invoices',
          method: 'POST',
          body: arg,
        };
      },
    }),

    invoiceReportFilter: builder.mutation<
      InviceReportFilterResponse,
      InviceReportFilterRequest
    >({
      query(arg) {
        return {
          url: '/hub/reports/api/v2/recent-invoices/filter',
          method: 'POST',
          body: arg.body,
        };
      },
      onQueryStarted(request, { dispatch, queryFulfilled }) {
        if (request?.filterScreen as SiftScreenEnum) {
          queryFulfilled?.then((response) => {
            const invoiceStatusData = response?.data?.invoiceStatus?.map(
              (x) => ({
                statusId: x,
                statusName: InvoiceStatusNames[x],
              })
            );
            const invoioceStatusInputItems =
              mapDataToInputItemsFromAppliedFilter(
                invoiceStatusData,
                'statusId',
                'statusName',
                ListInputItemOptions.invoiceStatus
              )?.filter((x: any) => x.value);

            const orderNumberData = response?.data?.orderNumber?.map((x) => ({
              orderId: x,
              orderRefNumber: x,
            }));
            const orderNumberDataInputItems =
              mapDataToInputItemsFromAppliedFilter(
                orderNumberData,
                'orderId',
                'orderRefNumber',
                ListInputItemOptions.orderNumber
              )?.filter((x: any) => x.value);

            const dueDateData = response?.data?.dueDate?.map((x) => ({
              dueDateId: x,
              dueDate: x,
            }));
            const dueDateDataInputItems = mapDataToInputItemsFromAppliedFilter(
              dueDateData,
              'dueDateId',
              'dueDate',
              ListInputItemOptions.dueDate
            )?.filter((x: any) => x.value);

            const dispatchDateData = response?.data?.dispatchDate?.map((x) => ({
              dispatchDateId: x,
              dispatchDate: x,
            }));
            const dispatchDateDataInputItems =
              mapDataToInputItemsFromAppliedFilter(
                dispatchDateData,
                'dispatchDateId',
                'dispatchDate',
                ListInputItemOptions.dispatchDate
              )?.filter((x: any) => x.value);

            const customerData = response?.data?.customerName?.map((x) => ({
              customerId: x?.customerId,
              customerName: x?.customerName,
            }));
            const customerInputItems = mapDataToInputItemsFromAppliedFilter(
              customerData,
              'customerId',
              'customerName',
              ListInputItemOptions.customers
            )?.filter((x: any) => x.value);

            dispatch(
              updateSiftByScreen({
                screen: request?.filterScreen,
                filters: {
                  invoiceStatus: invoioceStatusInputItems,
                  refOrderNumber: orderNumberDataInputItems,
                  dueDate: dueDateDataInputItems,
                  dispatchDate: dispatchDateDataInputItems,
                  customer: customerInputItems,
                },
              })
            );
          });
        }
      },
    }),
    salesTeamCheckinReport: builder.mutation<
      salesTeamCheckinReportResponse,
      salesTeamCheckinReportRequest
    >({
      query(arg) {
        return {
          url: '/hub/reports/api/v2/salesmen-checkins-report',
          method: 'POST',
          body: arg,
        };
      },
    }),

    salesTeamCheckinDownload: builder.mutation<
      any,
      SalesTeamCheckinReportDownloadRequest
    >({
      query(arg) {
        return {
          url: '/hub/reports/api/v2/salesmen-checkins-report/download',
          method: 'POST',
          body: arg,
          responseHandler: (response) => response.blob(),
        };
      },
    }),

    salesTeamCheckinReportFilter: builder.mutation<
      SalesTeamCheckinsReportFilterResponse,
      SalesTeamCheckinsReportFilterRequest
    >({
      query(arg) {
        return {
          url: '/hub/reports/api/v2/salesmen-checkins-report/filter',
          method: 'POST',
          body: arg.body,
        };
      },
      onQueryStarted(request, { dispatch, queryFulfilled }) {
        if (request?.filterScreen as SiftScreenEnum) {
          queryFulfilled?.then((response) => {
            const customerInputItems = mapDataToInputItemsFromAppliedFilter(
              response?.data?.customers,
              'customerId',
              'customerName',
              ListInputItemOptions.customers
            )?.filter((x: any) => x.value);

            dispatch(
              updateSiftByScreen({
                screen: request?.filterScreen,
                filters: {
                  customer: customerInputItems ?? [],
                },
              })
            );
          });
        }
      },
    }),

    productPerformanceReportFilter: builder.mutation<
      ProductPerformanceReportFilterResponse,
      ProductPerformanceReportFilterRequest
    >({
      query(arg) {
        return {
          url: '/hub/reports/api/v2/product-performance-report/filter',
          method: 'POST',
          body: arg?.body,
        };
      },
      onQueryStarted(request, { dispatch, queryFulfilled }) {
        if (request?.filterScreen as SiftScreenEnum) {
          queryFulfilled?.then((response) => {
            console.log('request?.filterScreen response: ', response?.data);

            const categoriesInputItems = mapDataToInputItemsFromAppliedFilter(
              response?.data?.category,
              'categoryId',
              'category',
              ListInputItemOptions.categories
            )?.filter((x: any) => x.value);

            const divisionInputItems = mapDataToInputItemsFromAppliedFilter(
              response?.data?.division,
              'divisionId',
              'division',
              ListInputItemOptions.divisions
            )?.filter((x: any) => x.value);

            dispatch(
              updateSiftByScreen({
                screen: request?.filterScreen,
                filters: {
                  category: categoriesInputItems,
                  division: divisionInputItems,
                },
              })
            );
          });
        }
      },
    }),

    customerPerformanceReportFilter: builder.mutation<
      CustomerPerformanceReportFilterResponse,
      CustomerPerformanceReportFilterRequest
    >({
      query(arg) {
        return {
          url: '/hub/reports/api/v2/customer-performance-report/filter',
          method: 'POST',
          body: arg?.body,
        };
      },
      onQueryStarted(request, { dispatch, queryFulfilled }) {
        if (request?.filterScreen as SiftScreenEnum) {
          queryFulfilled?.then((response) => {
            console.log('request?.filterScreen response: ', response?.data);

            const districtInputItems = mapDataToInputItemsFromAppliedFilter(
              response?.data?.district,
              'district',
              'district',
              ''
            )?.filter((x: any) => x.value);

            const stateInputItems = mapDataToInputItemsFromAppliedFilter(
              response?.data?.state,
              'stateId',
              'state',
              ''
            )?.filter((x: any) => x.value);

            const pincodeInputItems = mapDataToInputItemsFromAppliedFilter(
              response?.data?.pinCode,
              'inviteId',
              'inviteId',
              ''
            )?.filter((x: any) => x.value);

            dispatch(
              updateSiftByScreen({
                screen: request?.filterScreen,
                filters: {
                  district: districtInputItems,
                  state: stateInputItems,
                  pincode: pincodeInputItems,
                },
              })
            );
          });
        }
      },
    }),

    recentPaymentsReportFilter: builder.mutation<
      PaymentReportFilterResponse,
      PaymentReportFilterRequest
    >({
      query(arg) {
        return {
          url: '/hub/reports/api/v2/recent-payments/filter',
          method: 'POST',
          body: arg?.body,
        };
      },
      onQueryStarted(request, { dispatch, queryFulfilled }) {
        if (request?.filterScreen as SiftScreenEnum) {
          queryFulfilled?.then((response) => {
            const customerInputItems = response?.data?.customer?.map((item) => {
              return {
                id: item?.customerId,
                value: item?.customerName,
                selected: false,
              };
            });

            const paymentStatusInputItems = response?.data?.paymentStatus?.map(
              (val) => {
                return {
                  id: val,
                  value: val,
                  selected: false,
                };
              }
            );

            // const orderNumberInputItems = response?.data?.orderNumber?.map(
            //   (val) => {
            //     return {
            //       id: val,
            //       value: val,
            //       selected: false,
            //     };
            //   }
            // );

            dispatch(
              updateSiftByScreen({
                screen: request?.filterScreen,
                filters: {
                  customer: customerInputItems,
                  paymentStatus: paymentStatusInputItems,
                  // orderNumber: orderNumberInputItems,
                },
              })
            );
          });
        }
      },
    }),

    supplyTrackerReportFilter: builder.mutation<
      SupplyTrackerReportFilterResponse,
      SupplyTrackerReportFilterRequest
    >({
      query(arg) {
        return {
          url: '/hub/reports/api/v2/cfa-product-wise-order-grouping-report/filter',
          method: 'POST',
          body: arg?.body,
        };
      },
      onQueryStarted(request, { dispatch, queryFulfilled }) {
        if (request?.filterScreen as SiftScreenEnum) {
          queryFulfilled?.then((response) => {
            const fulfillmentsInputItems = mapDataToInputItemsFromAppliedFilter(
              response?.data?.fulfillments,
              'cfaId',
              'cfaName',
              ''
            )?.filter((x: any) => x.value);

            const tags = [
              {
                id: '12am to 1pm',
                name: 'Morning',
              },
              {
                id: '1pm to 12am',
                name: 'Evening',
              },
            ];
            const tagsItems = mapDataToInputItems(tags, 'id', 'name')?.filter(
              (x: any) => x.value
            );

            dispatch(
              addListInputItems({
                inputItems: tagsItems,
                key: ListInputItemOptions.tags,
              })
            );

            dispatch(
              updateSiftByScreen({
                screen: request?.filterScreen,
                filters: {
                  fulfillments: fulfillmentsInputItems,
                  tags: tagsItems,
                },
              })
            );
          });
        }
      },
    }),

    supplyTrackerReportDownload: builder.mutation<
      any,
      SupplyTrackerReportDownloadRequest
    >({
      query(arg) {
        return {
          url: 'hub/reports/api/v2/cfa-product-wise-order-grouping-report/download',
          method: 'POST',
          params: arg?.params,
          body: arg?.body,
          responseHandler: (response) => response.blob(),
        };
      },
    }),

    businessSummaryReport: builder.mutation<
      BusinessSummaryReportResponse,
      BusinessSummaryReportRequest
    >({
      query(arg) {
        return {
          url: '/hub/reports/api/v2/dashboard/business-summary',
          method: 'POST',
          body: arg,
        };
      },
    }),

    getKpiList: builder.query<UserKpis[], KpiListRequest>({
      query(arg) {
        return {
          url: `/hub/reports/api/v2/dashboard/kpi/users`,
          method: 'GET',
          params: arg,
        };
      },
    }),

    modifyKpis: builder.mutation<ModifyKpisResponse, ModifyKpisRequest>({
      query(arg) {
        const { body, ...otherParams } = arg;
        return {
          url: `/hub/reports/api/v2/dashboard/kpi/users`,
          method: 'POST',
          params: otherParams,
          body,
        };
      },
    }),

    getKpiDetails: builder.mutation<KpiDetailsResponse, KpiDetailsRequest>({
      query(arg) {
        const { body, ...otherParams } = arg;
        return {
          url: `/hub/reports/api/v2/dashboard/kpi/details`,
          method: 'POST',
          params: otherParams,
          body,
        };
      },
    }),
    getSegmentAnalysis: builder.mutation<
      SegmentAnalysisResponse[],
      SegmentAnalysisRequest
    >({
      query(arg) {
        return {
          url: `/hub/reports/api/v2/dashboard/segment-analysis/details`,
          method: 'POST',
          params: { sellerWorkspaceId: arg.sellerWorkspaceId },
          body: arg,
        };
      },
    }),

    getDashboardFilters: builder.mutation<
      DashboardFilterResponse,
      DashboardFilterRequest
    >({
      query(arg) {
        return {
          url: `/hub/reports/api/v2/dashboard/filter`,
          method: 'POST',
          body: arg,
        };
      },
    }),

    updateDashboardFilters: builder.mutation<
      string,
      UpdateDashboardFilterRequest
    >({
      query(arg) {
        return {
          url: `/hub/reports/api/v2/dashboard/reports/filters`,
          method: 'POST',
          body: arg,
        };
      },
    }),
    appliedDashboardFilters: builder.query<
      AppliedDashboardFiltersResponse,
      AppliedDashboardFiltersRequest
    >({
      query(arg) {
        return {
          url: `/hub/reports/api/v2/dashboard/filters`,
          method: 'GET',
          params: arg,
        };
      },
    }),
  }),
});

export const {
  useGenerateOtifAbsoluteOrdersReportMutation,
  useGenerateOtifRecivedOrdersReportMutation,
  useGenerateOtifOrderFulfilmentReportMutation,
  useSalesLeaderBoardMutation,
  useRevenueReportMutation,
  useReceivedOrdersReportMutation,
  useDailyOrdersReportMutation,
  useDailyPaymentsReportMutation,
  useDailyInvoicesReportMutation,
  useTopCustomersByOrderValueMutation,
  useBottomCustomersByOrderValueMutation,
  useTopProductsByQuantitySoldMutation,
  useBottomProductsByQuantitySoldMutation,
  useLazyRecentOrdersReportQuery,
  useLazyRecentInvoiceReportQuery,
  useLazyRecentPaymentReportQuery,
  useZoaiReportMutation,
  useCustomerCfaReportMutation,
  useDownloadCfaReportsMutation,
  useLedgerSummaryReportMutation,
  useOrderSummaryReportDownloadMutation,
  useOrderDetailsReportMutation,
  useSalesLeaderboardReportMutation,
  useSalesLeaderboardReportDownloadMutation,
  useCustomerPerformanceReportDownloadMutation,
  useProductPerformanceReportDownloadMutation,
  useInvoiceReportMutation,
  useProductPerformanceReportFilterMutation,
  useSalesTeamCheckinReportMutation,
  useGetPaymentReportApiMutation,
  useCustomerPerformanceReportFilterMutation,
  useSalesTeamCheckinReportFilterMutation,
  useInvoiceReportFilterMutation,
  useRecentPaymentsReportFilterMutation,
  useSupplyTrackerReportFilterMutation,
  useOrderDetailsReportFilterMutation,
  usePaymentsReportDownloadMutation,
  useInvoicesReportDownloadMutation,
  useSalesTeamCheckinDownloadMutation,
  useSupplyTrackerReportDownloadMutation,
  useBusinessSummaryReportMutation,
  useLazyGetKpiListQuery,
  useModifyKpisMutation,
  useGetKpiDetailsMutation,
  useGetSegmentAnalysisMutation,
  useUpdateDashboardFiltersMutation,
  useGetDashboardFiltersMutation,
  useLazyAppliedDashboardFiltersQuery,
  useCfaCustomerWiseProductReportMutation,
  useCfaCustomerWiseProductReportFilterMutation,
} = reportsApi;
