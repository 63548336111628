import React, { useContext, useState, useEffect, useRef } from 'react';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import { ThemeContext } from '@zonofi/shared';
import { styled } from '@mui/system';
import '../zo-input-text/zo-input-text.scss';
import './zo-input-textarea.scss';

interface InputTextAreaProps {

  title?: string;

  placeholder?: string;

  value?: string | any;

  maxRows?: number;

  ref?: any;

  // onBlur: (val: string) => void;
  onBlur?: (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
  ) => any;

  focusInput?: boolean;

  onFocus?: (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
  ) => any;

  inputTextAreaStyle?: React.CSSProperties;

  containerStyles?: React.CSSProperties;

  inputFieldProps?: React.TextareaHTMLAttributes<HTMLTextAreaElement>;

  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;

}

const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
    box-sizing: border-box;
    width: 100%;
    font-size: 16px;
    padding: 8px;
    color: #000000;
    background: #fff;
    border-radius: 6px;
    border: 1px solid #D9D9D9;
    resize: vertical;
        
     &::placeholder {
      color: #BFC0C2;
      opacity: 1;
      fontSize: 14px;
      fontFamily: Segoe-UI;
      fontWeight: 400;
    }
    &:hover {
      border-color: #000000;
    }
    &:focus {
      border-color: #33A7E8;
    }
    &:focus-visible {
      outline: 0;
    }
  `
);

export const ZoInputTextArea = ({
  title,
  placeholder = '',
  value,
  maxRows,
  ref,
  onBlur,
  focusInput = false,
  onFocus,
  inputTextAreaStyle,
  containerStyles,
  inputFieldProps,
  onChange = () => { },
}: InputTextAreaProps) => {
  if (!ref) {
    ref = useRef<HTMLInputElement>(null);
  }
  const [val, setVal] = useState('');
  const { theme } = useContext(ThemeContext);
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (focusInput) {
      ref?.current?.focus();
    } else {
      ref?.current?.blur();
    }
  }, [focusInput]);

  useEffect(() => {
    setVal(value);
  }, [value]);

  // const styleContainer = {
  //   height: '84px',
  //   ...inputTextAreaStyle,
  // };
  return (
    <div className='input_text_area font-regular'>
      {title && (
        <span className={`input_text_area__title ${isFocused ? 'input_text_area__focused' : ''}`}>
          {title}
        </span>
      )}
      <Textarea className='font-regular'
        // style={styleContainer}
        style={{
          ...inputTextAreaStyle,
        }}
        value={val}
        maxRows={maxRows}
        onFocus={(e) => {
          setIsFocused(true);
          onFocus && onFocus(e);
        }}
        onBlur={(e) => {
          setIsFocused(false);
          onBlur && onBlur(e);
        }}
        onChange={(e) => onChange(e)}
        placeholder={placeholder}
        {...inputFieldProps}
      />
    </div>
  );
};