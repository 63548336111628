import React from 'react';
import { useAppSelector, WEBROUTES } from '@zonofi/zono-money-helpers';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
  Outlet,
} from 'react-router-dom';
import {
  InviteLoadingScreen,
  OrderDetailsScreen,
  ViewInvoiceScreen,
  ReviewInvoicesScreen,
  CatalogueScreen,
  FacebookScreen,
  OrderSuccessfull,
  Opps,
  CatalogScreen,
  CatalogImageSearchScreen,
  OrderDetailsScreenV2,
} from '../../screens';
import { isGuestAuthenticated } from '@zonofi/zono-money-store';
import { GstScreen } from '../../screens/v1/whatsapp-flow-stack/gst-kyc-screen/gst-screen';
import { UnauthorizedScreen } from '../../screens/v2/unauthorized-screen/unauthorized-screee';
import { ErrorScreenV2 } from '../../screens/v2/error-screen/error-screen';
import { ProductDetailsScreen } from '../../screens/v2/product-details-screen/product-details-screen';
import { ReorderScreen } from '../../screens/v2/reorder-screen/reorder-screen';

export const AppNavigator = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<UnauthorizedScreen />} />
        <Route
          path={WEBROUTES.CUSTOMER_INVITE_LOADING_SCREEN}
          element={<InviteLoadingScreen />}
        >
          <Route path=":inviteId" element={<InviteLoadingScreen />} />
        </Route>
        <Route path={WEBROUTES.GST_SCREEN} element={<GstScreen />} />
        <Route path={WEBROUTES.OPPS} element={<Opps />} />
        <Route path={WEBROUTES.FACEBOOK} element={<FacebookScreen />}>
          <Route path=":id" element={<FacebookScreen />} />
        </Route>
        <Route
          path={WEBROUTES.PRODUCT_DETAILS}
          element={<ProductDetailsScreen />}
        >
          <Route path=":productVariantId" element={<ProductDetailsScreen />} />
        </Route>
        <Route element={<RequireGuestAuth />}>
          <Route
            path={WEBROUTES.ORDER_SUCCESSFULL}
            element={<OrderSuccessfull />}
          />
          <Route
            path={WEBROUTES.INVOICE_SCREEN}
            element={<ViewInvoiceScreen />}
          >
            <Route path=":invoiceId" element={<ViewInvoiceScreen />} />
          </Route>
          {/* <Route path={WEBROUTES.ORDER} element={<OrderDetailsScreen />}>
            <Route path=":orderId" element={<OrderDetailsScreen />} />
          </Route> */}
          <Route path={WEBROUTES.ORDER} element={<OrderDetailsScreenV2 />}>
            <Route path=":orderId" element={<OrderDetailsScreenV2 />} />
          </Route>
          <Route path={WEBROUTES.CART} element={<OrderDetailsScreenV2 />} />
          <Route
            path={WEBROUTES.REVIEW_INVOICES}
            element={<ReviewInvoicesScreen />}
          />
          <Route path={WEBROUTES.CAMPAIGN} element={<CatalogScreen />}>
            <Route path=":campaignId" element={<CatalogScreen />} />
          </Route>
          <Route path={WEBROUTES.PRICELIST} element={<CatalogScreen />}>
            <Route path=":pricelistId" element={<CatalogScreen />} />
          </Route>
          <Route
            path={WEBROUTES.CATALOGUE + '/old'}
            element={<CatalogueScreen />}
          />
          <Route path={WEBROUTES.CATALOGUE} element={<CatalogScreen />} />
          <Route path={WEBROUTES.REORDER} element={<ReorderScreen />}>
            <Route path=":orderId" element={<ReorderScreen />} />
          </Route>
          <Route
            path={WEBROUTES.CATALOGUE_IMAGE_SEARCH}
            element={<CatalogImageSearchScreen />}
          />
          <Route
            path={WEBROUTES.ORDER_SUCCESSFULL}
            element={<OrderSuccessfull />}
          />
        </Route>
        <Route
          path={WEBROUTES.UNAUTHORIZED_SCREEN}
          element={<UnauthorizedScreen />}
        />
        <Route path={WEBROUTES.ERROR_SCREEN} element={<ErrorScreenV2 />} />
      </Routes>
    </BrowserRouter>
  );
};

const RequireGuestAuth = () => {
  const isAuthenticated = useAppSelector(isGuestAuthenticated);
  const location = useLocation();

  if (!isAuthenticated) {
    return <Navigate to={WEBROUTES.ERROR_SCREEN} state={{ from: location }} />;
  }

  return <Outlet />;
};
