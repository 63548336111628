import { useState, useCallback, useEffect } from 'react';

export const useScroll = () => {
  const [lastScrollY, setLastScrollY] = useState(0);
  const [showCategory, setShowCategory] = useState(true);

  const handleScroll = useCallback(() => {
    const currentScrollY = window.scrollY;
    setShowCategory(currentScrollY < lastScrollY);
    setLastScrollY(currentScrollY);
  }, [lastScrollY]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  return {
    showCategory
  };
};
