import { UnreadMessageCount } from '../../helpers';
import { StoredFolder } from '../model';
import { CommsState } from '../slice';

interface UseUpdateChannelListForUnreadProps {
  commsState: CommsState;
  folderData: { folders: Record<string, StoredFolder> };
  unreadMessagesCount: Record<string, UnreadMessageCount>;

  // Add any additional props needed by the hook
}

export const updateChannelListForUnreadHelper = ({
  commsState,
  folderData,
  unreadMessagesCount,
}: UseUpdateChannelListForUnreadProps): Record<string, StoredFolder> => {
  const filterUnreadMessages = (data: {
    folders: Record<string, StoredFolder>;
  }): Record<string, StoredFolder> => {
    const results: Record<string, StoredFolder> = {};

    function searchItems(
      rootFolders: StoredFolder[],
      parentId?: string | null
    ) {
      for (const rootFolder of rootFolders) {
        const updatedSubFolders: StoredFolder[] = [];

        if (rootFolder.subFolders) {
          for (const subfolder of rootFolder.subFolders) {
            const subChannelMatches = subfolder.channels?.filter(
              (chnl) => unreadMessagesCount[chnl]?.count > 0
            );

            if (subChannelMatches && subChannelMatches.length > 0) {
              updatedSubFolders.push({
                ...subfolder,
                channels: subChannelMatches,
              });
            }
          }
        }

        const updatedSubChannels: string[] = [];

        if (rootFolder.channels) {
          for (const channel of rootFolder.channels) {
            const channelNameMatches = unreadMessagesCount[channel]?.count > 0;

            if (channelNameMatches) {
              updatedSubChannels.push(channel);
            }
          }
        }

        if (updatedSubChannels.length > 0 || updatedSubFolders.length > 0) {
          results[rootFolder.id] = {
            ...rootFolder,
            channels: updatedSubChannels,
            subFolders: updatedSubFolders,
          };
        }

        if (parentId && results[parentId]) {
          results[rootFolder.id] = { ...rootFolder, parentId };
        }
      }
    }

    const foldersArray = Object.values(data.folders);
    searchItems(foldersArray);

    return results;
  };

  return filterUnreadMessages(folderData);
};
