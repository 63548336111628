import { WorkspaceRoleEntry } from '../auth';
import { SenderWorkspace } from '../events';
import { Cfa } from '../products';
export interface SentTeamInvitesModel {
  id: string;
  phone: string;
  status: string;
  createdDate: string;
  updatedDate: string;
  inviteeUser: user;
  invitedUser: user;
  senderWorkspace: SenderWorkspace;
}

interface user {
  mobile: string;
  firstName: string;
  lastName: string;
  city: string;
  email: string;
  id: string;
  userType: string;
  profilePic: string;
  subtitle: string;
  address: address;
}

interface address {
  id: string;
  apartmentName: string;
  buildingTowerName: string;
  city: string;
  county: string;
  flatUnitNumber: string;
  formatted: string;
  neighbourhood: string;
  postcode: string;
  stateDistrict: string;
  suburb: string;
}

interface InputField {
  id: number;
  name: string;
  label: string;
  placeholder: string;
  disabled: boolean;
  required: boolean;
  size: 0.5 | 1;
  inputType:
    | 'textInput'
    | 'numberInput'
    | 'mobile'
    | 'dropdown'
    | 'multiDropdown';
}

interface TeamMemberInputField extends InputField {
  onChange?: (val: string) => void;
}

interface TeamMemberMultiInputField extends InputField {
  onChange?: (val: string[]) => void;
}

export interface CreateTeamMemberDropDown {
  firstName: TeamMemberInputField;
  mobileNumber: TeamMemberInputField;
  email: TeamMemberInputField;
  employeeCode: TeamMemberInputField;
  role: TeamMemberMultiInputField;
  type: TeamMemberInputField;
}

export interface TeamMember {
  endDate?: string;
  startDate?: string;
  workspaceRole?: any;
  workspaceRoles?: WorkspaceRoleEntry[];
  workspace?: any;
  firstName: string;
  lastName: string;
  userType: UserType;
  mobile: string;
  name: string;
  userId: string;
  userRole: string;
  id: string;
  inviteStatus: InviteStatus;
  cityName?: any;
  profilePic?: any;
  address?: any;
  email?: any;
  inviteType: string;
  customerGroups?: CustomerGroup[];
  createdDate: Date;
  updatedDate: Date;
  employeeCode: string;
  isActive: boolean;
  senderWorkspace?: SenderWorkspace;
  teamMemberRole: TeamMemberRole[];
  role: 'MEMBER' | 'ADMIN' | null;
  cfas: Cfas[];
  divisions: Division_[];
  assignedCustomers?: string;
  teamCustomers?: AssignedCustomer[];
}

export enum UserType {
  CFA = 'CFA',
  SALES = 'Sales',
  MEMBER = 'Member',
}

enum InviteStatus {
  ACCEPT = 'A',
  PENDING = 'P',
  NOT_INVITED = 'N',
}

export interface TeamMemberRole {
  id: string;
  createdDate: string;
  updatedDate: string;
  title: string;
  slug: string;
  isActive: boolean;
  description: string;
  parentRoleId: any;
}

export interface Cfas {
  id: string;
  createdDate: string;
  updatedDate: string;
  isDefault: boolean;
  supCode: any;
  isActive: boolean;
  startDate: any;
  endDate: any;
  workspaceId: string;
  teamMemberId: string;
  supplierName: string;
  fulfillmentLocationId: string;
}

export interface Division_ {
  name: string;
  headDivisionId: string;
  id: string;
  createdDate: any;
  updatedDate: any;
  code: string;
  stateId: null | any;
  isDefault: boolean;
  isActive: boolean;
}

export interface AssignedCustomer {
  customerCode: string;
  id: string;
  name: string;
  phone: string;
  state: string;
  teamMemberId: string;
}

export interface CustomerGroup {
  customerGroupId: string;
  customerGroupName: string;
}

export interface TeamInvitesRequest {
  senderWorkspaceId: string;
  invites: TeamInvites[];
}

export interface TeamInvites {
  name: string;
  phone: string;
}

export interface ReinviteRequest {
  phone: string;
  senderWorkspaceId: string;
}

export interface RevokeRequest {
  workspaceId: string;
  userId: string;
  id?: string;
}

export interface UpdateRoleRequest {
  workspaceId: string;
  userId: string;
  userRole: string;
  id?: string;
}

interface Invite {
  id: string;
  phone: string;
  phone2: any;
  inviteType: string;
  userType: string;
  isActive: boolean;
  isArchive: boolean;
  senderUserId: string;
  invitedUserId: any;
  senderWorkspaceId: string;
  invitedWorkspaceId: any;
  status: string;
  createdDate: string;
  updatedDate: string;
}

interface TeamMemberInvite {
  workspaceRoles?: any;
  id: string;
  inviteId: string;
  workspaceId: string;
  employeeCode: string;
  employeeName: string;
  HQCode: string;
  divisionId: null;
  plantId: string;
  MISCode: string;
  MISDescription: string;
  Designation: string;
  reportingManagerId: null;
  createdDate: string;
  updatedDate: string;
  email: string;
  invite?: Invite;
}

interface TeamMemberDetails {
  reportingManagerCode?: string;
  employeeCode?: string;
  employeeName?: string;
  HQCode?: string;
  plantId?: string;
  MISCode?: string;
  MISDescription?: string;
  Designation?: string;
  divisionCodes?: string[];
}

export interface UpdateTeamInvitesRequest {
  email?: string;
  phone?: string;
  workspaceId: string;
  userType?: string;
  roleIds?: string[];
  isArchive?: boolean;
  isActive?: boolean;
  inviteId?: string;
  divisionIds?: string[];
  CFAIds?: string[];
  teamMemberDetails: TeamMemberDetails;
}

export interface UpdateTeamInviteResponse {
  teamMember: TeamMemberInvite;
  status: string;
  statusCode: number;
  message: string;
}

export interface RolesAndPermissionsRequest {
  includePermissions?: boolean;
  isSeller?: boolean;
  isBuyer?: boolean;
}

export interface RoleByIDsRequest {
  roleIds: string[];
  isSeller?: boolean;
  isBuyer?: boolean;
}
export interface Slug {
  id: string;
  title: string;
  slug: string;
}

export interface RoleByIDsResponse {
  permissions: Permission[];
}
export interface Permission {
  code: string;
  slugs: Slug[];
}
export interface Roles {
  id: string;
  title: string;
  slug: string;
  description: string;
  permissions: Permission[];
}
export interface RolesAndPermissionsResponse {
  roles: Roles[];
}

export interface UpdateTeamEmailRequest {
  newEmail: string;
  inviteId: string;
  workspaceId: string;
}

export interface UpdateTeamEmailResponse {
  statusCode: number;
  id: string;
  phone: string;
  phone2: any;
  inviteType: string;
  userType: string;
  isActive: boolean;
  isArchive: boolean;
  senderUserId: string;
  invitedUserId: string;
  senderWorkspaceId: string;
  invitedWorkspaceId: any;
  status: string;
  createdDate: string;
  updatedDate: string;
}

export interface UpdateTeamMobileNumberRequest {
  newMobile: string;
  inviteId: string;
  workspaceId: string;
}

export interface UpdateTeamMobileNumberResponse {
  id: string;
  phone: string;
  phone2: any;
  inviteType: string;
  userType: string;
  isActive: boolean;
  isArchive: boolean;
  senderUserId: string;
  invitedUserId: string;
  senderWorkspaceId: string;
  invitedWorkspaceId: any;
  status: string;
  createdDate: string;
  updatedDate: string;
}

export interface GetCustomersResponse {
  workspaces: CustomerWorkspace[];
  total: number;
  startRecord: number;
  endRecord: number;
  statusCode: number;
  responseStatus: string;
}
export interface GetCustomersRequest {
  pageNo?: number;
  pageSize?: number;
  sortBy?: string;
  sortDirection?: string;
  isSeller?: boolean;
  isBuyer?: boolean;
  searchKey?: string;
}

export interface CustomerWorkspace {
  id: string;
  spaceName: string;
  user: Customer;
  principalCount?: number;
  teamMemberCount: number;
  gstIn: string;
  isSeller: boolean;
  isBuyer: boolean;
  customerCount?: number;
}

export interface MappingReportWorkspace {
  uuid: string;
  orderid: string;

  packing: string;
  skucode: string;
  buyercode: string;
  buyername: string;
  sellercode: string;
  sellername: string;
  ordernumber: string;
  orderstatus: string;
  errormessage: string;
  mappingstatus: string;
  importsource: string;
  mappingendtime: string;
  mappedproductqty: string;
  mappingstarttime: string;
  mappedproductname: string;
  uploadedproductqty: string;
  uploadedproductname: string;
}

interface Customer {
  id: string;
  firstName?: string;
  mobile?: string;
  email?: string;
}

export interface TeamMembersRequest {
  workspaceId: string;
  includePendingInvites?: boolean;
  includeInactive?: boolean;
  includeTeamCustomersMapping?: boolean;
  searchKey?: string;
}

export interface teamsSearchResponse {
  id: string;
  name: string;
  email: string;
  userType: string;
  inviteType: string;
  mobile: string;
  profilePic: string;
}
export interface teamsSearchRequest {
  invite: string;
  key: string;
  channel: string;
}

export interface GetTeamMemberDetailRequest {
  teamMemberId: string;
  sellerWorkspaceId: string;
}

export interface GetTeamMemberDetailResponse {
  id: string;
  mobile: string;
  name: string;
  employeeCode: string;
  userId: string;
  userRole: UserRole;
  inviteStatus: string;
  cityName: string;
  profilePic: string;
  address: string;
  email: string;
  inviteType: string;
  isActive: boolean;
  isArchive: boolean;
  createdDate: string;
  updatedDate: string;
  userType: string;
  cfas: visitCfas[];
  divisions: any;
  teamCustomers: any[];
  senderWorkspace: SenderWorkspace;
}

export interface visitCfas {
  id: string
  isDefault: any
  createdDate: string
  updatedDate: string
  supCode: any
  isActive: boolean
  startDate: any
  endDate: any
  workspaceId: string
  fulfillmentLocationId: string
  fulfillmentLocation: Cfa;
}

export interface UserRole {
  inviteId: string;
  slug: string;
  roleId: string;
  invitedUserId: string;
  title: string;
}

export interface Root {
  data: Daum[];
}

export interface Daum {
  date: string;
  teamMemberId: string;
  teamMemberName: string;
  visits: Visit[];
}

export interface Visit {
  customer: VisiCustomer;
  visitTime: string;
  location: VisitLocation;
  status?: string;
  activities: ActivityData[];
  locationStatus?: string;
}

export interface VisiCustomer {
  name: string;
  companyName?: string;
  phone: string;
  location: string;
}

export interface VisitLocation {
  latitude: number;
  longitude: number;
}

export interface ActivityData {
  type: string;
  time: string;
  orderId?: string;
  skuCount?: number;
  amount: number;
  order_id?: string;
  sku_count?: number;
  payment_mode?: string;
  transactionId?: string;
  transaction_id?: string;
  paymentMode?: string;
}
