import { getDocumentAsync } from '@zonofi/shared';
import {
  getCustomerInvite,
  useParseImageToProductsMutation,
} from '@zonofi/zono-money-store';
import { useNavigate } from 'react-router';
import { WEBROUTES, useAppSelector } from '@zonofi/zono-money-helpers';
import { useEffect, useRef, useState } from 'react';

export const useCatalogImageSearchScreen = () => {
  const navigate = useNavigate();

  const inviteData = useAppSelector(getCustomerInvite);
  const sellerWorkspaceId = inviteData?.seller?.workspaceId;

  const videoRef = useRef<HTMLVideoElement | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [showResult, setShowResult] = useState(false);
  const [error, setError] = useState({
    show: false,
    msg: '',
  });
  const [selectedOption, setSelectedOption] = useState(1);
  const [uploadedFile, setUploadedFile] = useState<File>();
  const [uploadedImage, setUploadedImage] = useState<any>();

  const [parseFileAPI, parseFileAPIResponse] =
    useParseImageToProductsMutation();

  const onBack = () => {
    closeCamera();
    navigate(WEBROUTES.CATALOGUE);
  };

  const goBackToSearch = () => {
    setUploadedFile(undefined);
    setUploadedImage(undefined);
    setShowResult(false);
  };

  const dataURLToBlob = (dataUrl: string): Blob => {
    const byteString = atob(dataUrl.split(',')[1]);
    const mimeString = dataUrl.split(',')[0].split(':')[1].split(';')[0];
    const buffer = new Uint8Array(byteString.length);

    for (let i = 0; i < byteString.length; i++) {
      buffer[i] = byteString.charCodeAt(i);
    }

    return new Blob([buffer], { type: mimeString });
  };

  const onOpenUpload = () => {
    getDocumentAsync({ type: 'image/*' }).then((result) => {
      console.log(result);
      setUploadedFile(result?.output[0]?.file);
      const file = result?.output[0]?.file;
      if (file && file.type.startsWith('image/')) {
        // Check if it's an image file
        const reader = new FileReader();

        reader.onload = () => {
          setUploadedImage(reader?.result); // Set the image source to the FileReader result
        };

        reader.readAsDataURL(file); // Read the file as a data URL
      } else {
        alert('Please select a valid image file.');
      }
    });
  };

  const openCamera = async () => {
    try {
      const constraints = {
        video: {
          facingMode: { exact: 'environment' }, // Use rear camera
        },
      };
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.play();
      }
    } catch (err) {
      console.error('Error accessing the camera', err);
    }
  };

  const takePhoto = () => {
    const canvas = canvasRef.current;
    const video = videoRef.current;
    if (canvas && video) {
      // Ensure both refs are not null
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const context = canvas.getContext('2d');
      if (context) {
        context.drawImage(video, 0, 0, canvas.width, canvas.height);
      }

      // Get image data
      const dataUrl = canvas.toDataURL('image/png');
      const blob = dataURLToBlob(dataUrl);
      const file = new File([blob], 'captured-photo.png', {
        type: 'image/png',
      });
      setUploadedFile(file);
      setUploadedImage(dataUrl);
      closeCamera();
    }
  };

  const closeCamera = () => {
    const stream = videoRef.current?.srcObject as MediaStream | null;
    if (stream) {
      stream.getTracks().forEach((track) => {
        track.stop(); // Stop each track
      });
    }
    if (videoRef.current) {
      videoRef.current.srcObject = null;
    }
  };

  const toggleTab = (newTab: number) => {
    setUploadedFile(undefined);
    setUploadedImage(undefined);
    setError({ show: false, msg: '' });
    if (newTab === 1) {
      closeCamera();
    }
    setSelectedOption(newTab);
  };

  const onTryAgain = () => {
    setUploadedFile(undefined);
    setUploadedImage(undefined);
    setError({
      show: false,
      msg: '',
    });
    if (selectedOption === 0) {
      openCamera();
    }
  };

  useEffect(() => {
    if (uploadedFile && uploadedImage) {
      const formData = new FormData();
      formData.append('file', uploadedFile);
      parseFileAPI({
        sellerWorkspaceId,
        body: formData,
        workspaceId: sellerWorkspaceId,
      });
    }
  }, [uploadedFile, uploadedImage]);

  useEffect(() => {
    console.log('parseFileAPIResponse: ', parseFileAPIResponse);
    if (parseFileAPIResponse?.isSuccess) {
      console.log('parseFileAPIResponse?.data: ', parseFileAPIResponse?.data);
      setShowResult(true);
    }
    if (parseFileAPIResponse?.isError) {
      setError({
        show: true,
        msg:
          (parseFileAPIResponse?.error as any)?.status +
            ' ' +
            (parseFileAPIResponse?.error as any)?.data?.message ||
          'Something went wrong',
      });
    }
  }, [parseFileAPIResponse]);

  useEffect(() => {
    if (selectedOption === 0) {
      openCamera();
    } else {
      console.log('closing camera');
      closeCamera();
    }
  }, [selectedOption]);

  console.log('uploaded file: ', uploadedFile);

  return {
    onBack,
    uploadedFile,
    uploadedImage,
    onOpenUpload,
    selectedOption,
    setSelectedOption,
    parseFileAPIResponse,
    showResult,
    setShowResult,
    goBackToSearch,
    openCamera,
    closeCamera,
    takePhoto,
    videoRef,
    canvasRef,
    toggleTab,
    error,
    setError,
    onTryAgain,
  };
};
