export enum ListInputItemOptions {
  campaignStatus = 'Campaign Status',
  dispatchStatus = 'Dispatch Status',
  tags = 'Tags',
  customers = 'Customers',
  campaignCustomers = ' Customers',
  status = 'status',
  priceListStatus = 'Status  ',
  routes = 'Routes',
  products = 'products',
  teamMembers = 'teamMembers',
  threadTeamMembers = 'Team Members',
  schemeActiveStatus = 'status ',
  schemeType = 'Scheme Type',
  divisions = 'Divisions',
  headDivision = 'Head Divisions',
  cities = 'Cities',
  criteria = 'Criteria',
  distributors = 'Distributors',
  outstandingTransactionType = 'OutstandingTransactionType',
  multiMapping = 'Multi Mapping',
  modifiedQty = 'Modified Quantity',
  teamMemberType = 'Type',
  importSource = 'Order Import Source',
  poImportSource = 'Order Imported From',
  view = 'View',
  alphabets = 'By Alphabets',
  poOrderStatus = 'Order Status  ',
  paymentStatus = 'Payment Status',
  orderNumber = 'Order Number',
  cfaPrincipalCustomers = 'Customers  ',
  //  Bulk Import
  bulkImportTaskStatus = 'Task Status',
  bulkImportEntityType = 'Task Type',
  activeStatus = 'Status ',
  cfaStatus = 'CFA_Status',
  states = 'States',
  groups = 'Groups',
  customerStatus = 'Status',
  onboardingStatus = 'Onboarding Status',
  collections = 'Category',
  cfaInventory = 'CFA Inventory',
  stockLevel = 'Stock Level',
  cfa = "CFA's",
  salesCfa = 'CFAs ',
  orderStatus = 'Order Status',
  invoice = 'Invoice',
  scheme = 'Scheme',
  cfaCustomers = 'Customers ',
  returnStatus = 'Order Status ',
  returnType = 'Order Type',
  importSources = 'Import Sources',
  batchNumbers = 'Batch Numbers',
  batchCategory = 'Return Category',
  returnLineStatus = 'Return Line Status',
  ledgerTransactionType = 'Transaction Type',
  invoiceStatus = 'Invoice Status',
  categories = 'Categories',
  threadActionsTags = 'Tags ',
  issueType = 'Issue Type',
  issueThreadStatus = 'Issue Status',
  principal = 'Principal',
  ledgerAmount = 'Amount',
  adminImportSource = 'Import Source',
  mappingStatus = 'Mapping Status',
  adminOrderStatus = 'Orders Status',
  Ztag = 'Order Tags',
  cfaReports = 'CFAs  ',
  erpCustomerName = 'Customer Firm Name',
  erpMappingStatus = 'Sync Status',
  erpTransactionType = ' Transaction Type',
  erpTaskStatus = 'Task Status',
  threadCfa = "CFA's",
  threadStatus = 'Status   ',
  threadDivision = 'Divisions ',
  dueDate = 'Due Date',
  dispatchDate = 'Dispatch Date',
  messageStatus = ' Status',
  customerSegments = 'Customer Segments',
  segment = 'segment',
  promotionsbyStatus = 'By Status',
  promotionsbyMode = 'By Mode',
  promotionsbyType = 'By Type',
}

export enum TreeInputItemOptions {
  threadRoutes = 'Routes',
  threadSegments = 'Segments',
}

export enum StringInputItemOptions {
  paymentId = 'paymentId',
  skuCode = 'SKU Code',
  customerCode = 'Customer Code',
  mobileNumber = 'Mobile Number',
  composition = 'Composition',
  sellerName = 'Seller Name',
}

export enum BooleanInputItemOptions {
  PendingPayment = 'Include Pending Payment',
  UnreadMessages = 'Unread',
}

export enum ArithmeticOperatorInputOptions {
  outstandingPayment = 'outstandingPayment',
  pendingPayment = 'pendingPayment',
  paidAmount = 'paidAmount',
  receivedAmount = 'receivedAmount',
}

export enum Operator {
  less_than = 'lt',
  equal_to = 'eq',
  greater_than = 'gt',
}
export interface FilterInputs {
  arithmeticOperatorInputItem?: ArithmeticOperatorInputItem;
  booleanInputItem?: BooleanInputItem;
  listInputItem?: ListInputItem;
  searchListInputItem?: SearchListInputItem;
  stringInputItem?: StringInputItem;
  calendarInputItem?: CalendarInputItem;
  sortField: SortField;
  treeInputItem?: TreeInputItem;
}

export type ListInputItem = {
  [key in ListInputItemOptions]: InputItem[];
};

export type TreeInputItem = {
  [key in TreeInputItemOptions]: TreeInput[];
};

export type SearchListInputItem = {
  [key in ListInputItemOptions]: {
    listInputItem: InputItem[];
    searchTerm: string;
  };
};

export type StringInputItem = {
  [key in StringInputItemOptions]: string;
};
export type ArithmeticOperatorInputItem = {
  [key in ArithmeticOperatorInputOptions]: ArithmeticOperator;
};

export type BooleanInputItem = {
  [key in BooleanInputItemOptions]: boolean;
};

export type CalendarInputItem = {
  [key: string]: string;
};

export interface InputItem {
  TreeData?: any;
  id: string | number;
  value: string;
  selected: boolean;
  icon?: string;
  parentId?: string | number;
  eitherOr?: boolean;
}

export interface TreeInput {
  TreeData: TreeInput[];
  id: string;
  selected: boolean;
  value: string;
}

export interface ArithmeticOperator {
  operator: Operator;
  value: number;
}

export enum SortDirection {
  ASCENDING = 'ASC',
  DESCENDING = 'DESC',
}

export interface SortField {
  field: string;
  direction: SortDirection;
}

export interface ImageSearchKey {
  path: string;
  type: string;
  width?: number;
  height?: number;
}

export enum RecentSearchTypes {
  PRODUCTS = 'products',
  SCHEMES = 'schemes',
  ORDERS = 'orders',
  TRACKPOS = 'trackPos',
  CART = 'cart',
  RETURNS = 'returns',
  LEDGER = 'ledger',
  Invoice = 'invoice',
  CUTOMERS = 'customers',
  THREADS = 'threads',
  FORWARD = 'forward',
  TAGS = 'tags',
  PAYMENT = 'payment',
  MEMBERS = 'members',
  CAMPAIGNS = 'campaigns',
}

export enum EpochScreenType {
  REPORT = 'REPORT',
  ORDERLIST = 'ORDER LIST',
  INVOICELIST = 'INVOICE LIST',
  SALES_TEAM_ACTIVITY = 'SALES TEAM ACTIVITY',
  CAMPAIGN_DETAIL = 'CAMPAIGN DETAIL',
  CAMPAIGN_LIST = 'CAMPAIGN_LIST',
  SEND_CAMPAIGN = 'SEND_CAMPAIGN',
  CUSTOMERS_ERP_SYNC_DETAILS = 'CUSTOMERS_ERP_SYNC_DETAILS',
}

export interface Epoch {
  [key: string]: {
    startDate: Date;
    endDate: Date;
  };
}

export enum SiftScreenEnum {
  APPLIED_FILTERS = 'APPLIED_FILTERS',
  PRODUCT_PERFORMANCE_REPORTS = 'PRODUCT_PERFORMANCE_REPORTS',
  CUSTOMER_PERFORMANCE_REPORTS = 'CUSTOMER_PERFORMANCE_REPORTS',
  INVOICE_REPORTS = 'INVOICE_REPORTS',
  ORDER_DETAILS_REPORT = 'ORDER_DETAILS_REPORT',
  SALES_TEAM_CHECKINS_REPORT = 'SALES_TEAM_CHECKINS_REPORT',
  PAYMENTS_REPORT = 'PAYMENTS_REPORT',
  SUPPLY_TRACKER = 'SUPPLY_TRACKER',
  SALES_LEADERBOARD_REPORT = 'SALES_LEADERBOARD_REPORT',
  CAMPAIGN_LIST_SCREEN = 'CAMPAIGN_LIST_SCREEN',
  SEND_CAMPAIGN_SCREEN = 'SEND_CAMPAIGN_SCREEN',
  CUSTOMER_SCREEN = 'CUSTOMER_SCREEN',
  ORDER_LINE_SCREEN = 'ORDER_LINE_SCREEN',
  CUSTOMERS_ERP_SYNC_DETAILS = 'CUSTOMERS_ERP_SYNC_DETAILS',
}

type SiftScreenValueType = {
  [key: string]: any;
};

export type Sift = {
  [K in SiftScreenEnum]: SiftScreenValueType | undefined;
} & {
  [SiftScreenEnum.APPLIED_FILTERS]: SiftScreenValueType;
};
