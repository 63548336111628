export enum NotificationTypeEnum {
  REMINDER = 'REMINDER',
  TRANSACTION = 'TRANSACTION',
  CUSTOMERTHREAD = 'CUSTOMERTHREAD',
  TEXT = 'TEXT',

  WAPPGROUPSYNCED = 'WAPPGROUPSYNCED',
}

export enum PnGcmMessageType {
  TIMELINE = 'TMLN',
  TEXT_MESSAGE = '',
  CHANNEL_MEMBER_CREATED = 'CLMC',
  CHANNEL_MEMBER_ADDED = 'CLMA',
  CHANNEL_MEMBER_REMOVED = 'CLMR',
  CHANNEL_MEMBER_UPDATED = 'CLMU',
  WORK_SPACE_MEMBER_UPDATED = 'WSMU',
  WORK_SPACE_INVITE_SENT = 'WSIS',
  WORK_SPACE_MEMBER_REMOVED = 'WSMR',
  TEXT = 'TEXT',
}

export enum KYCStatus {
  INIT = 'INIT',
  PAN = 'PAN',
  AADHAR = 'AADHAR',
  BANK = 'BANK',
  SUBMIT = 'SUBMIT',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export enum SchemeActionButtons {
  RULEINFO = 'rule info',
  CONDITIONS = 'conditions',
  ACTIONS = 'actions',
  CONTENT = 'content',
}

export enum SchemeTypes {
  BUYXGETY = 'buy_x_get_y_free',
  PRODUCTDISCOUNT = 'products_percentage_discount',
}

export enum OrderStatusEnum {
  CREATED = 'OrderCreated',
  ADDING_ITEMS = 'AddingItems',
  ARRANGING_PAYMENT = 'ArrangingPayment',
  PAYMENT_AUTHORIZED = 'PaymentAuthorized',
  PAYMENT_SETTLED = 'PaymentSettled',
  PARTIALLY_SHIPPED = 'PartiallyShipped',
  SHIPPED = 'Shipped',
  PARTIALLY_DELIVERED = 'PartiallyDelivered',
  MODIFYING = 'Modifying',
  ARRANGING_ADDITIONAL_PAYMENT = 'ArrangingAdditionalPayment',
  CANCELLED = 'Cancelled',
  CONFIRMED = 'Confirmed',
  BILLED = 'Billed',
  WAITING_FOR_CONFIRMATION = 'WaitingForCNF',
  SUBMITTED_BY_SALES = 'SubmittedBySales',
  SUBMITTED_BY_CFA = 'SubmittedByCFA',
  SUBMITTED_BY_CUSTOMER = 'SubmittedByCustomer',
  SUBMITTED = 'SUBMITTED',
  SUBMITTED_2 = 'Submitted',
  ORDER_CREATED = 'ORDER_CREATED',
  ORDER_SUBMITTED = 'ORDER_SUBMITTED',
  MAPPED = 'MAPPED',
  RE_MAPPED = 'RE_MAPPED',
  UNMAPPED = 'UNMAPPED',
  APPROVED = 'Approved',
  APPROVED_BY_CFA = 'APPROVED BY CFA',
  REJECTED_BY_CFA = 'REJECTED_BY_CFA',
  PRODUCT_NOT_FOUND = 'PRODUCT_NOT_FOUND',
  TEMPLATE_NOT_FOUND = 'TEMPLATE_NOT_FOUND',
  TEMPLATE_NOT_AVAILABLE = 'TEMPLATE_NOT_AVAILABLE',
  WAITING_FOR_CONFIRMATION_ = 'WAITING_FOR_CONFIRMATION',
  TICKET_CREATED = 'TICKET CREATED',
  TICKET_RESOLVED = 'TICKET RESOLVED',
  BILLED_ = 'BILLED',
  DISPATCHED = 'DISPATCHED',
  PARSING_FAILED = 'PARSING_FAILED',
  MAPPING_FAILED = 'MAPPING_FAILED',
  DRAFT = 'DRAFT',
  DELIVERED = 'Delivered',
  DELIVERED_ = 'DELIVERED'
}

export enum InvoiceStatusEnum {
  PAYMENT_PENDING = 'P',
  CANCELLED = 'C',
  PAID = 'PD',
  PARTIALLY_PAID = 'PP',
  ALL_PAYMENTS = '',
  OVER_DUE = 'OD',
  UNPAID = 'UD',
}

export const InvoiceStatusNames =  {
  [InvoiceStatusEnum.PAYMENT_PENDING] : 'Payment Pending',
  [InvoiceStatusEnum.CANCELLED]: 'Cancelled',
  [InvoiceStatusEnum.PAID] :'Paid',
  [InvoiceStatusEnum.PARTIALLY_PAID] : 'Partially Paid',
  [InvoiceStatusEnum.OVER_DUE] : 'Over Due',
  [InvoiceStatusEnum.UNPAID] : 'Unpaid'
}

export const BeforeConfirmedOrderStatuses = [
  OrderStatusEnum.CREATED,
  OrderStatusEnum.SUBMITTED,
  OrderStatusEnum.SUBMITTED_BY_CUSTOMER,
  OrderStatusEnum.ADDING_ITEMS,
  OrderStatusEnum.DRAFT,
  OrderStatusEnum.SUBMITTED_BY_CFA,
  OrderStatusEnum.SUBMITTED_2,
];

export enum PaymentCodes {
  All_Payments = '',
  Pending = 'P',
  PartiallyPaid = 'PP',
  Paid = 'PD',
  Cancelled = 'C',
  OverDue = 'OD',
  UnPaid = 'UD',
}

export enum OrderImportSourceEnum {
  UPLOAD = `upload`,
  EMAIL = 'e-mail',
  MANUAL = 'manual',
  DTERP = 'dt-erp',
  WHATSAPP = 'whatsapp',
}

export enum CategoryEnum {
  NotExpired = 'NE',
  Expired = 'E',
}

export enum AllReturnTypes {
  IncorrectProduct = 'IP',
  RejectedByPolicy = 'RBP',
  NotSold = 'NS',
  NotReceived = 'NR',
  Damaged = 'D',
  NonDamaged = 'ND',
  PurchaseReturn = 'PR',
  NearExpiry = 'NE',
}

export enum ReturnOrderLineStatusEnum {
  Accepted = 'ACCEPTED',
  Rejected = 'REJECTED',
}

export enum MultiToastType {
  WARNING = 'WARNING',
  DANGER = 'DANGER',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export type LoggedInUserType =
  | 'principal'
  | 'distributor'
  | 'cfa'
  | 'support'
  | 'super-admin'
  | 'sales';

export enum WhatsAppSyncCustomerStatus {
  NEW_USER = 'NOT_AVAILABLE',
  NOT_IN_GROUP = 'AVAILABLE',
  ALREADY_IN_GROUP = 'CHANNEL_MEMBER',
}

export enum WhatsAppSyncCustomerType {
  TEAM = 'team',
  CUSTOMER = 'customer',
}

export enum ActiveCampaignView {
  EMPTY_VIEW = 'Empty View',
  TEMPLATE_VIEW = 'Template View',
  PREVIEW_VIEW = 'Preview View',
  CREATE_CAMPAIGN_VIEW = 'Create Campaign View',
  EDIT_CAMPAIGN_VIEW = 'Edit Campaign View',
  PRODUCT_SELECT_VIEW = 'Product Select View',
  Campaign_List_View = 'Campaign_List_View',
  Campaign_Edit_View = 'Campaign_Edit_View',
}
