import { ColumnConfig, CommsTeamMembersColumnKey } from '../../model';

export const commsTeamMembersConfig: Record<
  CommsTeamMembersColumnKey,
  ColumnConfig
> = {
  teamMemberName: {
    name: 'Team Members',
    isCheckboxField: true,
    isComponent: true,
  },
  type: { name: 'Type' },
  mobile: { name: 'Mobile' },
  status: { name: 'Status' },
  role: { name: 'Role In Channel', isComponent: true },

  added: { name: '' },
};
