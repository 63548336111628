import { ColumnConfig, DivisionColumnKey } from '../model';

export const divisionConfig: Record<DivisionColumnKey, ColumnConfig> = {
  name: {
    name: 'Division Name',
    field: 'avatarNameStatus',
    sortField: 'Division.name',
  },
  divisionCode: {
    name: 'Division Code',
    alignment: 'flex-start',
    sortField: 'Division.code',
  },
  headerDivisionName: {
    name: 'Header Division Name',
    alignment: 'flex-start',
    sortField: 'HeadDivision.name',
  },
  headerDivisionCode: { name: 'Header Division Code', alignment: 'flex-end' },
  noOfCustomers: { name: 'No of Customers', alignment: 'flex-end' },
  action: {
    name: '',
    field: 'component',
  },
};
