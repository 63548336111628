import { ColumnConfig, SchemesCfaColumnKey } from '../../model';

export const schemesCfaConfig: Record<SchemesCfaColumnKey, ColumnConfig> = {
  cfa: {
    name: 'CFA Name',
    field: 'multiLine',
  },
  code: {
    name: 'Code',
  },
};
