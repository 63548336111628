import { OrderImportSourceEnum, OrderStatusEnum } from '../helpers';
import { Asset, Cfa } from '../products';

export enum OrderLogsTypes {
  UPDATE = 'update',
  DELETE = 'delete',
  INSERT = 'insert',
  REMAPPED = 'remapped',
}

export enum ModifiedQtyInput {
  APPLIED = 'Applied',
  NOT_APPLIED = 'NotApplied',
}

export type DiscountType = 'percent' | 'direct';

interface Divisions {
  divisionId: string;
  divisionName: string;
}

export interface CreateOrderResponse {
  customerId: string;
  id: number;
  documentDate: string;
  status: string;
  cfaName: string;
  orderConfirmationETA: string;
  subTotal: string;
  subTotalWithTax: string;
  total: string;
  totalWithTax: string;
  createdAt: string;
  updatedAt: string;
  orderMetaData: OrderMetaData;
  orderHistory: any[];
  orderLine: OrderLine[];
  skucount?: number;
  createdBy?: string;
  createdByName?: string;
  totalQuantity: number;
  fullFillmentLocationId?: string;
  discount?: number;
  tax?: number;
  subTotalWithTaxAmount: number;
  taxAmount: number;
  lines: LineData[];
  poFile: OrderDetailPoFile;
  facets: Facet3[];
  estimatedDeliveryDate: any;
  invoice: Invoice3;
  divisions?: Divisions[];
  userType?: OrderedUserType;
  refOrderNumber?: string;
  invoiceSummary: OrderInvoiceSummary;
  customerName?: string;
  distributorName?: string;
  poFileLineId?: string;
  notifyToBuyer?: boolean;
  promotionNames?: Array<string>;

  divisionId: any;
  headDivisionId: any;
  importSource: string;
  splitBy: string;
  showPrice: boolean;
  distributorFirmName?: string;
  tags?: { id: number; name: string }[];
}
export interface OrderInvoiceSummary {
  totalQty: number;
  totalAmount: string;
  invoicedItem: number;
  invoices: OrderInvoice[];
}

export interface OrderInvoice {
  id: string;
  totalQty: number;
  totalAmount: string;
  invoiceNumber: string;
  invoiceId: string;
  discount: string;
  taxAmount: string;
}

export enum OrderedUserType {
  CUSTOMER = 'C',
  CFA = 'CFA',
  SALES = 'Sales',
  CUSTOMER_2 = 'Customer',
}

export interface Facet3 {
  facetName: string;
  facetValues: FacetValue2[];
}

export interface FacetValue2 {
  facetValue: string;
  facetValueCode: string;
  product: number;
}
export interface PrincipalWorkspaceDetails {
  sellerId: string;
  customerId: string;
  type?: string;
  inviteId: string;
  id: string;
  isCfa: boolean;
  spaceName: string;
}

export interface CreateOrderRequest {
  orderId?: number;
  status: string;

  distributorCode?: string;
  refOrderNumber?: string;
  orderLine?: {
    sku: string;
    erpOrderNumber: string;
  }[];
}
export interface ActiveOrder {
  orders: Order[];
  summary: ActiveOrderSummary;
  status: string;
  statusCode: number;
}

export interface Order {
  id: number;
  status: string;
  suggestedValue: number;
  suggestedValueWithTax: number;
  taxAmount: string;
  discount: string;
  pofileId: string;
  fullFillmentLocationId: string;
  CFA: string;
  divisionId: string;
  orderLine: OrderLine[];
  createdAt?: string;
  headDivisionName: string;
  headDivisionId?: string;
  importSource: string;
}

export interface OrderLine {
  id: number;
  assets?: Asset[];
  sku: string;
  skuName: string;
  description: string;
  unitPrice: number;
  productVariantId: number;
  quantity: number;
  divisionName: string;
  divisionId: string;
  linePrice: number;
  linePriceWithTax: number;
  discountedLinePrice: number;
  discountedLinePriceWithTax: number;
  proratedLinePrice: number;
  proratedLinePriceWithTax: number;
  suggestedValue: number;
  suggestedQuantity: number;
  changedValue: number;
  lineTax: string;
  uom: string;
  orderLineHistory: any[];
  productVariantAttribute: any;
  facets: Facet2[];
  discountedUnitPriceWithTax: string;
  proratedUnitPriceWithTax: string;
  adjustments: {
    lineDiscount: number;
    promotionCode: string;
    promotionId: number;
  }[];
  erpOrderNumber: string;
}

export interface ActiveOrderSummary {
  totalOrder: number;
  totalOrderLineCount: number;
  totalSubTotal: number;
  totalSubTotalWithTaxAmount: number;
  totalDiscount: number;
  totalTaxAmount: number;
}

export interface ShippingAddress {}

export interface BillingAddress {}

// export interface OrderLine {
//   createdAt: string;
//   updatedAt: string;
//   initialListPrice: any;
//   listPrice: number;
//   listPriceIncludesTax: boolean;
//   quantity: number;
//   cancelledQuantity: number;
//   orderId: number;
//   unitPrice: string;
//   unitPriceWithTax: string;
//   discountedUnitPrice: string;
//   discountedUnitPriceWithTax: string;
//   proratedUnitPrice: string;
//   proratedUnitPriceWithTax: number;
//   linePrice: string;
//   linePriceWithTax: string;
//   discountedLinePrice: string;
//   discountedLinePriceWithTax: string;
//   lineTax: string;
//   proratedLinePrice: string;
//   proratedLinePriceWithTax: string;
//   proratedLineTax: string;
//   isDeleted: boolean;
//   id: number;
//   refundId: any;
//   customFields: CustomFieldDetails;
//   productVariant: ProductVariant;
//   items: any[];
//   orderLineToTaxRates: any[];
//   unitPriceWithTaxChangeSinceAdded: number;
//   totalLi: number;
//   discounts: any[];
// }

export interface CustomFieldDetails {
  perItemPrice: any;
  skuType: any;
}

export interface ProductVariant {
  createdAt: string;
  updatedAt: string;
  deletedAt: any;
  enabled: boolean;
  isDeleted: boolean;
  sku: string;
  sortOrder: number;
  stockOnHand: number;
  stockAllocated: number;
  outOfStockThreshold: number;
  useGlobalOutOfStockThreshold: boolean;
  trackInventory: string;
  id: number;
  taxCategoryId: number;
  productId: number;
  translations: Translation[];
  product: ProductDetails;
  price: number;
  priceWithTax: number;
  packSize: number;
  PTR: number;
  cfas: Cfa[];
  assets: Asset[];
  productVariantId?: number;
}

export interface ProductDetails {
  createdAt: string;
  updatedAt: string;
  deletedAt: any;
  parentSku: string;
  enabled: boolean;
  id: number;
}

export interface CustomerDetails {
  createdAt: string;
  updatedAt: string;
  deletedAt: any;
  title: any;
  firstName: string;
  lastName: string;
  customerWorkspaceId: string;
  phoneNumber: string;
  emailAddress: string;
  id: number;
  customerChannels: CustomerChannel[];
  user?: {
    createdAt: string;
    updatedAt: string;
    deletedAt: null;
    identifier: string;
    verified: boolean;
    lastLogin: null;
    id: number;
  };
}

export interface CustomerChannel {
  id: string;
  createdDate: any;
  updatedDate: any;
  inviteId: string;
}

export interface OrderToTaxRates {
  id: string;
  createdDate: string;
  updatedDate: string;
  updatedByUserId: string;
  orderId: number;
  taxLines: any[];
  total_tax_amount: number;
}

export interface addItemsToOrderResponse {
  createdAt: string;
  updatedAt: string;
  initialListPrice: any;
  listPrice: number;
  listPriceIncludesTax: boolean;
  adjustments: any[];
  taxLines: any[];
  quantity: number;
  cancelledQuantity: number;
  id: number;
  productVariant: ProductVariant;
  unitPrice: number;
  unitPriceWithTax: number;
  unitPriceChangeSinceAdded: number;
  unitPriceWithTaxChangeSinceAdded: number;
  discountedUnitPrice: number;
  discountedUnitPriceWithTax: number;
  proratedUnitPrice: number;
  proratedUnitPriceWithTax: number;
  taxRate: number;
  linePrice: number;
  linePriceWithTax: number;
  discountedLinePrice: number;
  discountedLinePriceWithTax: number;
  discounts: any[];
  lineTax: number;
  proratedLinePrice: number;
  proratedLinePriceWithTax: number;
  proratedLineTax: number;
  orderLine?: OrderLine;
}

export interface FulfillmentLocationResponse {
  startRecord: number;
  endRecord: number;
  totalRecords: number;
  locations: FulfillmentLocation[];
  fulfillments: Fulfillments[];
}

export interface Fulfillments {
  cfaIds: string;
  cfaName: string;
}

export interface FulfillmentLocation {
  id: string;
  createdDate: string;
  updatedDate: string;
  fulfillmentLocationCode: string;
  physicalAddress: PhysicalAddressData;
  supplierName: string;
  fulfillmentAddress: string;
  GSTNumber: string;
  businessPan: string;
  postalCode: string;
  isActive: boolean;
  workspaceId: string;
  stateId: string;
  country: string;
  cityId: any;
  phone: any;
  email: any;
  state: StateData;
}

export interface PhysicalAddressData {}

export interface StateData {
  id: string;
  createdDate?: string;
  updatedDate: string;
  name: string;
  stateCode: string;
  isoCode: any;
  gstStateCode: string;
}

export interface ProductVariant {
  createdAt: string;
  updatedAt: string;
  deletedAt: any;
  enabled: boolean;
  sku: string;
  stockOnHand: number;
  stockAllocated: number;
  outOfStockThreshold: number;
  useGlobalOutOfStockThreshold: boolean;
  trackInventory: string;
  id: number;
  productId: number;
  productVariantPrices: ProductVariantPrice[];
  taxCategory: TaxCategory;
  translations: Translation[];
  price: number;
  priceWithTax: number;
  listPrice: number;
  listPriceIncludesTax: boolean;
  taxRateApplied: TaxRateApplied;
  currencyCode: string;
  languageCode: string;
  name: string;
  assetUrl: string;
}

export interface ProductVariantPrice {
  createdAt: string;
  updatedAt: string;
  price: number;
  id: number;
  channelId: number;
}

export interface TaxCategory {
  createdAt: string;
  updatedAt: string;
  name: string;
  isDefault: boolean;
  id: number;
}

export interface Translation {
  createdAt: string;
  updatedAt: string;
  languageCode: string;
  shortName?: string;
  shortDescription?: string;
  name: string;
  id: number;
}

export interface TaxRateApplied {
  value: number;
  enabled: boolean;
  name: string;
  id: string;
}

export interface addItemsToOrderRequest {
  orderId: string;
  lines: addProductsToOrderData[];
}

export interface addProductsToOrderData {
  productVariantId: number;
  quantity: number;
}

export interface updateFulfilmentRequest {
  documentDate: string;
  customerId?: string;
  status: string;
  fullFillmentLocationId: string;
  orderMetaData: OrderMetaData;
  orderLine: any[];
}

export interface getOrderDetailsRequest {
  orderId: string;
  workspaceId: string;
}

export interface ListOrderResponse {
  order: OrderData[];
  status: { status: string }[];
  facets: Facet[];
  totalRecords: number;
  startRecord: number;
  endRecord: number;
  totalOrders?: string;
  totalOrderValue?: string;
  totalAmountSaved?: string;
  fulfillmentLocations: FulfillmentLocation[];
  divisions: DivisionSummary[];
  fulfillments: Fulfillments[];
  customers: CFACustomers[];
  invoicedTotalAmount?: string;
  invoicedTotalQuantity?: string;
}

export interface DivisionSummary {
  divisionId: string;
  divisionName: string;
}
export interface CFACustomers {
  customerId?: string;
  customerName?: string;
}
export interface OrderData {
  id: number;
  documentDate: string;
  customerId?: string;
  customerFirmName?: string;
  customerName?: string;
  status: string;
  tags?: any;
  orderConfirmationETA: string;
  subTotal: string;
  subTotalWithTax: string;
  total: string;
  totalWithTax: string;
  orderMetaData: OrderMetaData;
  orderLine: OrderLine[];
  totalQuantity: number;
  subTotalWithTaxAmount: string;
  taxAmount: string;
  fullFillmentLocationId: string;
  createdAt: string;
  updatedAt: string;
  lines: LineData[];
  poFile: OrderDetailPoFile;
  orderHistory: any[];
  discount: string;
  estimatedDeliveryDate: any;
  invoice: Invoice3;
  isSpecial?: boolean;
  createdBy: null;
  createdByName?: string;
  skucount: number;
  divisionIds: null[];
  divisionNames: null[];
  customer?: {
    customerId?: string;
    customerName?: string;
  };
  invoiceSummary: InvoiceRootSummary;
  erpOrderNumber?: number;
  userType?: string;
}

export interface LineDiscount {
  discount: string;
  discountType: DiscountType;
}

export interface LineData {
  createdAt: string;
  updatedAt: string;
  initialListPrice: any;
  listPrice: number;
  listPriceIncludesTax: boolean;
  quantity: number;
  uploadedQty: number;
  cancelledQuantity: number;
  taxLines?: TaxRates[];
  sequence: any;
  subOrderNumber?: string;
  minOrderQty?: number;
  erpOrderNumber: any;
  orderId: number;
  unitPrice: string;
  isMultiMappingAvailable: boolean;
  unitPriceWithTax: string;
  uploadedProductName: string;
  discountedUnitPrice: string;
  discountedUnitPriceWithTax: string;
  proratedUnitPrice: string;
  proratedUnitPriceWithTax: string;
  linePrice: string;
  linePriceWithTax: string;
  discountedLinePrice: string;
  discountedLinePriceWithTax: string;
  lineTax: string;
  proratedLinePrice: string;
  proratedLinePriceWithTax: string;
  proratedLineTax: string;
  isDeleted: boolean;
  packSize: number;
  id: number;
  refundId: any;
  customFields: CustomFieldsDataList;
  productVariant: ProductVariant;
  orderLineToTaxRates: OrderLineToTaxRate[];
  unitPriceWithTaxChangeSinceAdded: number;
  totalLi: number;
  discounts: any[];
  invoice: InvoiceData;
  promotionIds?: string[];
  orderLineMetaData?: {
    cfa?: string;
    divisionName: string;
    headDivisonName: string;
  };
  divisionName: string;
  divisionId: string;
  refOrderNumber: string;
  poFileLineId: string;
  invoiceLineSummary: invoiceLineSummary;
  distributorProductName: string;
  multiMapping?: string;
  moqError?: boolean;
  updatedQty?: number;
  promotionNames?: Array<string>;
  selected?: boolean;
  name?: string;
  discount?: number;
  discountType?: 'direct' | 'percent';
  lineDiscount?: LineDiscount;
  cfaId?: string;
  assets?: Asset[];
}

export interface invoiceLineSummary {
  totalQty: number;
  totalAmount: string;
  invoicedItem: number;
  invoiceLines: IOrderInvoiceLines[];
}
export interface IOrderInvoiceSummary {
  totalQty: number;
  totalAmount: string;
  invoicedItem: number;
  invoices: IOrderInvoices[];
}

export interface IOrderInvoiceLines {
  id: string;
  invoiceNumber: string;
  amount: string;
  qty: number;
}

export interface IOrderInvoices {
  id: string;
  totalQty: number;
  totalAmount: string;
  invoiceNumber: string;
  discount: string;
  taxAmount: string;
  invoiceId?: string;
}

export interface InvoiceRootSummary {
  totalQty: number;
  totalAmount: string;
  invoicedItem: number;
  invoices: IOrderInvoices[];
  invoiceLines: any[];
}

export interface TaxRates {
  taxRate: string;
  taxAmount: string;
  taxCategory: string;
  taxCode: string;
}

export interface CustomFieldsDataList {
  perItemPrice: any;
  skuType: any;
}

export interface OrderLineToTaxRate {
  tax_amount: string;
}
export interface InvoiceData {
  totalQty: number;
  totalAmount: number;
  invoices: Invoice2[];
}

export interface Invoice2 {
  id: string;
  invoiceNumber: string;
  invoiceId: string;
  amount: number;
  qty: number;
}

export interface OrderDetailPoFile {
  id: string;
  poNumber: string;
  importSource: string;
}

export interface Invoice3 {
  totalQty: number;
  totalAmount: number;
  invoicedItem: number;
  invoices: Invoice4[];
  invoiceLines: Invoice4[];
}
export interface Invoice4 {
  id: string;
  invoiceNumber: string;
  amount: number;
  qty: number;
}
export interface OrderMetaData {
  id?: string;
  orderId?: string;
  suggestedOrderNumber?: string;
  refOrderNumber?: string;
  erpOrderNumber?: string;
  erpOrderDate?: string;
  generationType?: string;
  isEditEventTriggered?: boolean;
  editedByUserId?: string;
  createdDate?: string;
  updatedDate?: string;
  eventTimestamp?: string;
  divisionName?: string;
  headDivisionName?: string;
  cfa?: string;
}

// export interface OrderHistory {
//   id: string;
//   orderId: number;
//   status: string;
//   createdDate: string;
//   subTotal: string;
//   subTotalWithTax: string;
//   total: string;
//   totalWithTax: string;
// }

export interface OrderLineMetaData {
  id: string;
  orderLinesId: number;
  uom?: string;
  uomConversion: number;
  suggestedUomConversion: number;
  skuType: string;
  billingRefNumber: string;
  billingDate: string;
  allocationType: string;
  offerQtyMultiplier?: number;
  schemeBaseQty?: number;
  schemeMultiplierQty?: number;
  schemeStartDate: any;
  schemeEndDate: any;
  createdDate: string;
  updatedDate: string;
}

export interface Status {
  MODIFYING: string;
  CANCELLED: string;
  CONFIRMED: string;
  BILLED: string;
  PAYMENT_AUTHORIZED: string;
  WAITING_FOR_CONFIRMATION: string;
}

export interface Facet {
  facetName: string;
  facetValues: FacetValue[];
  facetValueCode: string;
}

export interface FacetValue {
  facetValueCode: string;
  facetValue: string;
  facetName: string;
  products: number;
}

export interface ListOrderRequest {
  params: {
    workspaceId: string;
    customerWorkspaceId?: string;
  };
  body: {
    workspaceId: string;
    customerId: string;
    pageNo?: number;
    pageSize?: number;
    sortBy: string;
    sortDirection: string;
    startDate?: string;
    endDate?: string;
    includeSummary?: boolean;
    filterModel?: {
      divisionIds?: string[];
      headDivisionIds?: string[];
      cfaIds?: string[];
      status?: string[];
      customerIds?: string[];
      importSource?: string;
      tagIds?: any[];
    };
    searchKeyword?: string;
    includeProductInfo?: boolean;
    includeCustomer?: boolean;
    includeInvoice?: boolean;
    includeOrderHistory?: boolean;
    includeOrderLineHistory?: boolean;
    includeCFA?: boolean;
    includeDivision?: boolean;
    includeStatus?: boolean;
    skip?: number | boolean;
  };
  dispatchFilters?: boolean;
}

export interface PoListOrderRequest {
  customerId?: string;
  pageNo?: number;
  pageSize?: number;
  sortBy?: string;
  sortDirection?: string;
  includeActiveOrders?: boolean;
  includeInvoice?: boolean;
  includeInvoiceLines?: boolean;
  includeOrderInvoices?: boolean;
  includeSummary?: boolean;
  searchKey?: string;
  importSource?: string;
  orderState?: string[];
  filterParams?:
    | {
        customerIds?: string[];
        divisionIds?: string[];
      }
    | string[];
  startDate?: string;
  endDate?: string;
}
export interface CustomerMetadataRequest {
  customerId: string;
  metricDate: string;
}

export interface CustomerMetadataResponse {
  id: string;
  metricDate: string;
  customerId: string;
  outStandingDate: string;
  outStandingAmount: number;
  customerCode: string;
  suggestedOrderTriggered: boolean;
  orderPDT: null;
  createdDate: string;
  updatedDate: string;
}

export interface getProductsDetailsRequest {
  productVariantIds: number[];
}

export interface getProductsDetailsResponse {
  createdAt: string;
  updatedAt: string;
  deletedAt: null;
  enabled: boolean;
  sku: string;
  sortOrder: number;
  stockOnHand: number;
  stockAllocated: number;
  outOfStockThreshold: number;
  useGlobalOutOfStockThreshold: boolean;
  trackInventory: string;
  id: number;
  taxCategoryId: number;
  productId: number;
  options: any[];
  facetValues: any[];
  taxCategory: TaxCategory;
  assets: any[];
  featuredAsset: null;
  productVariantAttribute: ProductVariantAttribute;
  productVariantPrices: ProductVariantPrice[];
  translations: Translation[];
  price: number;
  priceWithTax: number;
  listPrice: number;
  listPriceIncludesTax: boolean;
  taxRateApplied?: any;
  currencyCode: string;
  languageCode: string;
  name: string;
  shortName: string;
  shortDescription: string;
}

export interface ProductVariantAttribute {
  expiryDate?: string;
  MRP?: number;
  PTR?: number;
  id: string;
  createdDate: string;
  updatedDate: string;
  productVariantId: number;
  ean: string;
  erpId: string;
  vairantCode: string;
  saleUOM: string;
  hsnCode: string;
  baseUom: null;
  warrentyInformation: string;
  condition: string;
  packSize: number;
  minOrderQty: number;
  maxOrderQty: number;
  qtyMultiplier: number;
}

export interface LatestOrderDetailsRequest {
  sellerWorkspaceId: string;
  customerId: string;
  requestedTotalItems: number;
  workspaceId: string;
}

export interface LatestOrderDetailsData {
  id: number;
  documentDate: string;
  customerId: string;
  status: string;
  orderConfirmationETA: string;
  subTotal: string;
  subTotalWithTax: string;
  total: string;
  totalWithTax: string;
  suggestedOrderNumber: string;
  refOrderNumber: string;
  erpOrderNumber: string;
  erpOrderDate: string;
  generationType: string;
  isEditEventTriggered?: null;
  editedByUserId?: null;
  eventTimestamp: string;
  orderHistory?: null[] | null;
  lineCount: LineCount;
  lineCountBySkuType?: LineCountBySkuTypeEntity[] | null;
  getBilledOrderLineCountByNumber?: null[] | null;
}
export interface LineCount {
  edited: number;
  opted: number;
  total: number;
}
export interface LineCountBySkuTypeEntity {
  skuTypeLabel?: null;
  totalLines: number;
  linesOpted: number;
}

export type LatestOrderDetailsResponse = Array<LatestOrderDetailsData>;

export interface OrderLineItem {
  id: number;
  sku: string;
  skuName: string;
  facets: any[];
  quantity: number;
  unitPrice: string;
  linePrice: string;
  linePriceWithTax: number;
  discountedLinePrice: number;
  discountedLinePriceWithTax: number;
  proratedUnitPriceWithTax: number;
  uom: string;
  orderLineMetaData: OrderLineMetaData;
  orderLineHistory: any[];
  productVariantAttribute: any;
  productVariantId: string;
  discount?: number;
  description?: string;
}

export interface Facet {
  facetName: string;
  facetValue: string;
  facetValueCode: string;
}

export interface Facet2 {
  facetName: string;
  facetValues: FacetValue[];
  facetValueCode: string;
}

export interface FacetValue {
  facetValue: string;
  facetValueCode: string;
  product: number;
}
export interface AddItemToActiveOrderRequest {
  customerId: string;
  sellerWorkspaceId: string;
  source?: string;
  fullFillmentLocationId?: string;
  poFileId?: string;
  lines: Line[];
}

export interface Line {
  productVariantId: number;
  quantity: number | string;
  poFileLineId?: string;
  operator?: string;
}

export interface AddItemToActiveOrderResponse {
  orders: Order[];
  status: string;
  statusCode: number;
  errors?: Error[];
  message?: string;
}

export interface Error {
  msg: string;
  productVariantId: string;
}

export interface UploadOrderFileRequest {
  customerId: string;
  importSource: string;
  parserType: string;
  mock?: boolean;
}

export interface UploadOrderFileResponse {
  quantity?: number;
  sequence?: number;
  poFileId?: string;
  distributorErpSku?: string;
  boxQuantity?: number;
  caseQuantity?: number;
  unitQuantity?: number;
  packing?: string;
  freeQty?: number;
  isDivisionCFAMappingNotExist?: null;
  distributorProductName?: string;
  principalProductName?: string;
  isMapped?: boolean;
  manualMappingSync?: boolean;
  mappedBy?: string;
  manualMappingResponse?: string;
  status?: string;
  principalErpSku?: null | string;
  errorMessage?: string;
  distributorProductCode?: null;
  divisionCode?: null | string;
  cfaCode?: null | string;
  boxPacking?: null | string;
  casePacking?: null | string;
  mrp?: null;
  ptr?: number | null;
  pts?: number | null;
  composition?: null | string;
  hsnCode?: null | string;
  category?: null | string;
  locked?: null | string;
  divisionName?: null | string;
  gstClassification?: null | string;
  conversionQty?: null;
  currentStock?: number | null | string;
  currentStockDate?: null | string;
  id?: string;
  createdDate?: string;
  updatedDate?: string;
  isLocked?: boolean;
  isNew?: boolean;
  isSpecial?: boolean;
  isTaxExempted?: boolean;
  productVariantId?: number;
  minQty?: number;
}
export interface PoListOrderResponse {
  files: PoFile[];
  summary: PoSummary;
  status: string;
  statusCode: number;
  poFileCount: number;
  totalRecords: number;
  startRecord: number;
  endRecord: number;
}

export interface PoFile {
  id: string;
  number: string;
  uploadedDate: string;
  skuCount: number;
  totalItemsCount: number;
  importSource: string;
  subTotal: string;
  subTotalWithTax: string;
  customerName?: string;
  taxAmount: string;
  discount: string;
  total: string;
  totalWithTax: number;
  proratedPrice: string;
  proratedPriceWithTax: number;
  status: string;
  invoice?: InvoiceData;
  lines?: POLineData[];
  orderState?: string;
  createdBy?: string;
  customerId: string;
  isMapped?: boolean;
  freshdeskTicketId?: number | string | null;
  mappedLineCount?: number | string;
  // orderLines?: POLineData[];
}

export interface InvoiceData {
  skuCount?: number;
  totalQty: number;
  totalAmount: number;
  totalDiscount?: number;
  totalAfterDiscount?: number;
  taxAmount?: number;
  totalWithTax?: number;
  totalItems: number;
}

export interface POLineData {
  id: string;
  divisionName: string;
  creditLimit?: number;
  proratedLinePrice: string;
  proratedLinePriceWithTax: string;
  status: string;
  orderLineId: number;
  productVariantId: number;
  linePrice: string;
  linePriceWithTax: string;
  discountedLinePrice: string;
  discountedLinePriceWithTax: string;
  unitPrice: number;
  CFA: string;
  quantity: number;
  divisionId: string;
  orderId: number;
  product?: PoProduct;
  promotionId: number;
  promotions?: { id: string; name: string }[];
  headDivisionId?: string;
  headDivisionName?: string;
  refOrderNumber: string;
  CFAId: string;
  modeOfOrder: string;
  schemeType: string;
  discountedUnitPrice: number;
  uploadedQty?: number;
  taxCategory?: string;
  errorMessage?: string;
  poFileId?: string;
  assetUrl?: string;
  assetUrls?: { redirectUrl: string; filename: string }[];
}

export interface PoProduct {
  sku: string | number;
  MOQ?: number;
  description?: string;
  name: string;
  packSize?: number;
  productVariantId?: number;
  dtProductName?: string;
}

export interface PoSummary {
  totalSKUCount: number;
  totalOrderValue: number;
  totalDiscount: number;
  totalTax: number;
  totalFreeItems?: number;
  grossOrderNumber?: number;
  grossOrderValue?: number;
  totalFreeQuantity?: number;
  totalItemsCount?: number;
  divisions?: { id: string; name: string }[];
  cfas?: { cfaName: string; fullFillmentLocationId: string }[];
  orderStates?: string;
}

export interface OrderCheckoutRequest {
  customerId: string;
  sellerWorkspaceId: string;
  poFileIds?: string[];
}

export interface CheckoutOrder {
  id: number;
  status: string;
  suggestedValue: string;
  suggestedValueWithTax: string;
  taxAmount: string;
  discount: string;
  fullFillmentLocationId: string;
  refOrderNumber?: string;
  importSource?: OrderImportSourceEnum;
  inviteId?: string;
  orderLine: OrderLine[];
}
export interface cartSummary {
  totalFreeQuantity: number;
  totalSKUCount: number;
  grossOrderValue: number;
  totalDiscount: number;
  totalTax: number;
  totalItemsCount: number;
  totalOrderValue: number;
  grossOrderNumber: number;
}
export interface POLines {
  poFileLinesId?: string;
  errorMessage?: string;
  poFileLinesStatus?: string;
  createdAt?: string;
  updatedAt?: string;
  initialListPrice?: string;
  listPrice?: string;
  listPriceIncludesTax?: boolean;
  promotionId?: number;
  cancelledQuantity?: number;
  erpOrderNumber?: null | string;
  refOrderNumberL?: string;
  unitPriceWithTax?: string;
  discountedUnitPrice?: string;
  discountedUnitPriceWithTax?: string;
  proratedUnitPrice?: string;
  lineTax?: string;
  cfaId?: string;
  cfa?: string;
  proratedLineTax?: string;
  isDeleted?: boolean;
  refundId?: any;
  promotionNames?: Array<string>;
  customFields?: {
    perItemPrice: null;
    skuType: null;
  };
  productVariant?: {
    sku?: string;
    translations?: {
      name?: string;
    }[];
    MOQ?: number;
    packSize?: number;
    productVariantId?: number;
    assetUrl?: string;
  };
  unitPriceWithTaxChangeSinceAdded?: number;
  totalLi?: number;
  discounts?: any[];
  taxLines?: {
    taxRate?: string;
    taxAmount?: string;
    taxCategory?: string;
    taxCode?: string;
  }[];
  promotionIds?: string[];
  invoiceLineSummary?: {
    totalQty?: number;
    totalAmount?: string;
    invoicedItem?: number;
    invoiceLines?: any[];
  };
  uploadedQty?: number;
  roundedAmount?: string;
  POFileId?: string;
  POLineId?: string;
  distributorErpSku?: string;
  distributorProductName?: string;
  principleProductName?: string;
  sku?: string;
  status?: string;
  skuName?: string;
  description?: string;
  unitPrice?: string;
  quantity?: number;
  discount?: number;
  discountType?: 'direct' | 'percent';
  taxAmount?: string;
  orderLineId?: number;
  totalWithTax?: string;
  orderId?: number;
  proratedLinePriceWithTax?: string;
  proratedUnitPriceWithTax?: string;
  sequence?: number;
  linePrice?: string;
  linePriceWithTax?: string;
  discountedLinePrice?: string;
  discountedLinePriceWithTax?: string;
  proratedLinePrice?: string;
  divisionId?: string;
  headDivisionId?: null;
  orderedBy?: string;
  CFAId?: string;
  CFA?: null;
  divisionName?: string;
  headDivisionName?: string;
  refOrderNumber?: string;
  principalErpSku?: string;
  product?: {
    sku?: string;
    description?: string;
    MOQ?: number;
    divisionId?: string;
    name?: string;
  };
  invoices?: {
    invoicedItem: number;
    totalAmount: number;
  };
  invoice?: {
    totalItems: number;
    totalAmount: number;
  };
  MOQ?: number;
  packSize?: number;
  productVariantId?: number;
  poFileLineId?: string;
  isOrderSubmitted?: boolean;
  uploadedProductName?: string;
  moqError?: boolean;
  updatedQty?: number;
  lineDiscount?: LineDiscount;
  assets?: Asset[];
}

export interface ManualProductMappingRequest {
  workspaceId: string;
  POLines: [POLines];
}

export interface ManualProductMappingResponse {
  records: MappingResponseRecord[];
  statusCode: number;
  message: string;
}

export interface MappingResponseRecord {
  distributorErpSku: string;
  principalErpSku: string;
  distributorProductName: string;
  productVariantId: number;
  unitQuantity: number;
  isMapped: boolean;
  mappedBy: string;
  manualMappingSync: boolean;
  manualMappingResponse: string;
  status: string;
  packing: string;
}

export interface PoOrderDetailsRequest {
  pofileId: string;
  includeInvoice: boolean;
  includePromotion: boolean;
  includeTax: boolean;
  workspaceId: string;
}

export interface PoOrderDetailsResponse {
  divisions: PODivision[];
  lines: POLines[];
  allStatus: PODetailStatus;
  customerId: string;
  pofileId: string;
  status: string;
  skuCount: string;
  uploadedDate: string;
  importSource: string;
  poNumber: string;
  customerName: string;
  customerFirmName?: string;
  total: string;
  totalWithTax: string;
  tax: string;
  discount: string;
  discountedPrice: string;
  discountedPriceWithTax: string;
  userType: string;
  userName: string;
  companyName: string;
  orderedBy: string;
  invoiceSummary: POInvoice;
  orderState?: OrderStatusEnum;
  refOrderNumber?: string;
  notifyToBuyer?: boolean;
  promotionNames?: Array<string>;
  tags?: { id: number; name: string }[];
}

export interface PoDetailsSummaryData {
  totalWithTax: string;
  tax: string;
  skuCount: string;
  importSource: string;
  orderedBy: string;
  userType: string;
  discount: string;
  invoiceSummary: {
    totalAmount: string;
    discount: string;
    taxAmount: string;
    totalQty: string;
  };
  uploadedDate: string;
  orderStatus: string;
}

export interface PODivision {
  divisionId: string;
  divisionName: string;
}
export interface PODetailStatus {
  MODIFYING?: string;
  CANCELLED?: string;
  CONFIRMED?: string;
  BILLED?: string;
  PAYMENT_AUTHORIZED?: string;
  WAITING_FOR_CONFIRMATION?: string;
  APPROVED_BY_CFA?: string;
  SUBMITTED?: string;
  SUBMITTED_BY_CFA?: string;
  DISPATCHED?: string;
  REJECTED_BY_CFA?: string;
  DELIVERED?: string;
}

export interface OrderBy {
  userType: string;
  userName: string | null;
  companyName: string | null;
}

export interface POInvoice {
  totalAmount: string;
  totalQty: number;
  discount: string;
  taxAmount: string;
  totalItems: number;
  invoices: {
    totalAmount: number;
    totalQty: number;
    discount: number;
    taxAmount: number;
    invoiceId: string;
    invoiceNumber: string;
    id: string;
  }[];
}

export interface MappingData {
  id?: string;
  sequence?: any;
  distributorErpSku?: string;
  principalErpSku?: string;
  principalProductName?: string;
  distributorProductName?: string;
  isMapped?: boolean;
  poFileId?: string;
  productVariantId?: number;
  unitQuantity?: number;
  freeQty?: number;
  quantity?: number;
  pts: number;
  currentStock: number | string;
  minQty?: number;
  source?: string;
  mappingErrorMessage?: string;
  packSize?: string;
  skuCode?: string;
  productTitle?: string;
  dtProductTitle?: string;
  orderedQty?: string;
  moq?: string;
  offer_free?: string;
  status?: string;
  packing?: string;
  orderId?: number;
  uploadedQty?: number;
}

export interface DeletePoFileRequest {
  workspaceId: string;
  poFileLineIds?: string[];
  poFileId: string;
  customerId: string;
  poFileImportSource?: string;
}

export interface DeletePoFileResponse {
  success: boolean;
  message: string;
}

export interface CartPOFileDisplayData {
  poFileLineId: string;
  importSource: string;
  productId: number;
  orderId: number;
  orderLineId: number;
  skuCode: {
    title: string | number;
    image: null;
    isHyperLink: boolean;
    navigate: () => void;
  };
  productTitle: JSX.Element[];
  division: JSX.Element | string;
  pts: string;
  tax: string;
  savings: string;
  total: string;
  moq: number;
  quantity: number;
  action: JSX.Element;
  discountedUnitPrice: string;
  poFileId?: string;
}

export interface DownloadOrderInvoiceRequest {
  invoiceIds: string[];
  includePayment?: boolean;
  downloadType: string;
  downloadFormat: string;
  startDate: string;
  endDate: string;
  includeHeaders?: boolean;
}

export interface TrackPODetailsRequest {
  workspaceId: string;
  body: {
    pofileId: string;
    includeInvoice?: boolean;
    includePromotion?: boolean;
    includeTax?: boolean;
    includeInCartItemsOnly?: boolean;
    filter?: {
      divisionIds?: string[];
      status?: string[];
      invoice?: string;
      scheme?: string;
    };
    searchKey?: string;
    sortBy?: string;
    sortDirection?: string;
  };
  dispatchFilters?: boolean;
}

export interface DownloadOrderRequest {
  workspaceId: string;
  orderId: number;
  downloadContentType: string;
}

export interface CreateTicketRequest {
  poFileId: string;
  parserType: string;
  importSource: string;
  poNumber: string;
  description: string;
  customerId?: string;
}

export interface CreateTicketResponse {
  cc_emails: string[];
  fwd_emails: any[];
  reply_cc_emails: string[];
  ticket_cc_emails: string[];
  fr_escalated: boolean;
  spam: boolean;
  email_config_id: number;
  group_id: number;
  priority: number;
  requester_id: number;
  responder_id: any;
  source: number;
  company_id: any;
  status: number;
  subject: string;
  support_email: any;
  to_emails: any;
  product_id: number;
  id: number;
  type: any;
  due_by: string;
  fr_due_by: string;
  is_escalated: boolean;
  description: string;
  description_text: string;
  custom_fields: CustomFields;
  created_at: string;
  updated_at: string;
  tags: string[];
  attachments: any[];
  nr_due_by: any;
  nr_escalated: boolean;
}

export interface CustomFields {
  cf_reference_number: any;
  cf_po_file_id: string;
  cf_parsertype: string;
  cf_channelid: number;
  cf_importsource: string;
  cf_workspaceid: string;
  cf_distributorcode: string;
  cf_ponumber: string;
}

export interface DeleteLinesRequest {
  customerId: string;
  sellerWorkspaceId: string;
  lines: DeletedLine[];
  importSource?: string;
  poFileId?: string;
}

export interface DeletedLine {
  orderId: number;
  orderLineId: number;
}
export interface OrderSchemeSummaryRepsonse {
  workSpaceId: string;
  code: string;
  channelId: number;
  schemeCount: number;
  totalOrders: string;
  pendingDeliveryOrders: string;
}

export interface OrderSchemeSummaryRequest {
  workspaceId: string;
  customerId: string;
  body: { customerWorkspaceId: string; sellerWorkspaceId: string };
}

export interface EditLineData {
  skuCode: string;
  productTitle: ProductTitle;
  pts: number;
  uploadedQty: number;
  multiMapping?: string;
  orderedAmt: number;
  orderNumber?: string;
  productVariantId?: number;
  id: number | string;
  newQty?: number;
  orderedQty?: number;
  poFileLineId?: string;
  isEdited: boolean;
  divisionId: string;
  divisionName: string;
  sequence?: number;
  distributorProductName?: string;
  uploadedProductName?: string;
  orderLineId?: number;
  minOrderQty: number;
  remapped?: boolean;
  unMapped?: boolean;
  deleted?: boolean;
  moqError?: boolean;
  status?: string;
  unitPrice?: number;
  unitPriceWithTax?: number;
  tax?: string;
  orderSaving?: string;
  promotionIds?: string[];
  orderId?: number;
  packSize?: number;
  distributorErpSku?: string;
  errorMsg?: string;
  updatedQty?: number;
  discount?: number;
  discountType?: 'direct' | 'percent';
}

export interface ProductTitle {
  text: string;
  promotionId: number[] | any;
}

export interface UpdateOrderRequest {
  workspaceId: string;
  body: {
    orderId?: string;
    customerId: string;
    lines?: UpdatedLine[];
    poFileId?: string;
    discount?: number;
    discountType?: DiscountType;
  };
  isDispatchPOFile?: boolean;
}

export interface UpdatedLine {
  productVariantId?: number;
  poFileLineId?: string;
  quantity: number;
  orderLineId?: number;
  unitPrice?: number;
  discount?: number;
  discountType?: DiscountType;
  operator?: 'minus' | 'add';
}

export interface UpdateOrderResponse {
  message: string;
  orderLines: OrderLine[];
  errors: any[];
  order: CreateOrderResponse;
  deletedPoLines: string[];
}

export interface OrderLine {
  productVariantId: number;
  quantity: number;
  productVariant: UpdatedProductVariant;
  cfaId: string;
  minOrderQty: number;
  divisionId: string;
  headDivisionId: string;
  poFileLineId: string;
}

export interface UpdatedProductVariant {
  createdAt: string;
  updatedAt: string;
  deletedAt: any;
  enabled: boolean;
  isDeleted: boolean;
  ZINS: boolean;
  sku: string;
  sortOrder: number;
  stockOnHand: number;
  stockAllocated: number;
  outOfStockThreshold: number;
  useGlobalOutOfStockThreshold: boolean;
  trackInventory: string;
  id: number;
  taxCategoryId: number;
  productId: number;
  productVariantPrices: ProductVariantPrice[];
  taxCategory: TaxCategory;
  divisionCFAProductVariants: DivisionCfaproductVariant[];
  translations: UpdatedTranslation[];
  price: number;
  priceWithTax: number;
  listPrice?: number;
  listPriceIncludesTax?: boolean;
  taxRateApplied?: UpdatedTaxRateApplied;
  currencyCode?: string;
}

export interface ProductVariantPrice {
  createdAt: string;
  updatedAt: string;
  price: number;
  id: number;
  channelId: number;
}

export interface TaxCategory {
  createdAt: string;
  updatedAt: string;
  name: string;
  code: string;
  isDefault: boolean;
  id: number;
}

export interface DivisionCfaproductVariant {
  id: string;
  createdDate: string;
  updatedDate: string;
  divisionId: string;
  productVariantId: number;
  cfaId: string;
  isActive: boolean;
  minOrderQty: number;
  packSize: number;
}

export interface UpdatedTranslation {
  name: string;
  shortDescription: string;
}

export interface UpdatedTaxRateApplied {
  createdAt: string;
  updatedAt: string;
  name: string;
  enabled: boolean;
  value: number;
  id: number;
  categoryId: number;
  category: Category;
  zone: Zone;
  customerGroup: any;
}

export interface Category {
  createdAt: string;
  updatedAt: string;
  name: string;
  code: string;
  isDefault: boolean;
  id: number;
}

export interface Zone {
  createdAt: string;
  updatedAt: string;
  name: string;
  id: number;
}
export interface MappingEventResponse {
  userId: string;
  entityType: string;
  eventStatus: string;
  data: MappingEventData[] | MappingEventData;
}

export interface MappingEventData {
  id: string;
  createdDate: string;
  updatedDate: string;
  distributorErpSku: string;
  principalErpSku: string;
  principalProductName: string;
  distributorProductName: string;
  uploadedProductName: string;
  errorMessage: string;
  sku: string;
  moq: number;
  taxCategory: string;
  promotionId: number;
  sequence: number;
  isMapped: boolean;
  isLocked: boolean;
  mappedBy: string;
  mappedAt: string;
  status: string;
  manualMappingSync: boolean;
  manualMappingResponse: string;
  orderLineId: any;
  poFileId: string;
  productVariantId: number;
  boxQuantity: number;
  caseQuantity: any;
  unitQuantity: number;
  packing: string;
  freeQty: number;
  distributorProductCode: any;
  isDivisionCFAMappingNotExist: any;
  divisionCode: any;
  cfaCode: any;
  boxPacking: any;
  casePacking: any;
  isArchived: boolean;
  mrp: any;
  ptr: any;
  pts: string;
  minQty: number;
  packSize: number;
  composition: any;
  hsnCode: any;
  category: any;
  locked: any;
  divisionName: any;
  gstClassification: any;
  conversionQty: any;
  isNew: boolean;
  isSpecial: boolean;
  isTaxExempted: boolean;
  currentStock: any;
  currentStockDate: any;
  quantity: number;
  divisionId: string;
  headDivisionId: string;
  headDivisionName: string;
  refOrderNumber: string;
  schemeType: string;
  isQuantityUpdated: boolean;
  isMultiMappingAvailable: boolean;
  orderLine: OrderLine;

  refreshCart: boolean;
  isCompleted: boolean;
}

export interface OrderLine {
  createdAt: string;
  updatedAt: string;
  initialListPrice: number;
  listPrice: number;
  listPriceIncludesTax: boolean;
  quantity: number;
  cancelledQuantity: number;
  sequence: number;
  divisionId: string;
  headDivisionId: string;
  orderId: number;
  productVariantId: number;
  unitPrice: number;
  unitPriceWithTax: number;
  discountedUnitPrice: number;
  discountedUnitPriceWithTax: string;
  proratedUnitPrice: number;
  proratedUnitPriceWithTax: string;
  linePrice: number;
  linePriceWithTax: number;
  discountedLinePrice: number;
  discountedLinePriceWithTax: number;
  lineTax: string;
  proratedLinePrice: number;
  proratedLinePriceWithTax: number;
  proratedLineTax: any;
  isDeleted: boolean;
  orderPlacedAt: any;
  id: number;
  refundId: any;
  channelId: number;
  customFields: CustomFields;
  unitPriceWithTaxChangeSinceAdded: number;
  totalLi: number;
  discounts: any[];
  CFAId: string;
  CFA: string;
}

export interface CustomFields {
  perItemPrice: any;
  skuType: any;
}

export interface SuggestedProducts {
  companyProductCode: string;
  productName: string;
  mdmProductCode: string;
  productVariantId: number;
  pts: number;
}
export interface OrderLogsRequest {
  workspaceId: string;
  orderId: number;
  action: string;
}
interface productDetails {
  name: string;
  sku: string;
}
interface UpdatedFields {
  quantity: number;
  oldQuantity: number;
  productDetails?: productDetails;
  oldProductDetails?: productDetails;
}

export interface OrderLogsData {
  updatedFields: UpdatedFields;
  timestamp: string;
  action: string;
  updatedBy: string;
}

export interface OrderLogsResponse {
  orderId: number;
  orderLineId: number;
  activeQuantity: number;
  productDetails: productDetails;
  activeProductVariantId: number;
  sequence: number;
  Activequantity: number;
  sku: string;
  productVariantId: number;
  poFileLineId: string;
  data: OrderLogsData[];
}

export interface Audit {
  date: string;
  orderId: string;
  orderLineId: string;
  time: string;
  changes: any;
  timestamp: string;
  action: OrderLogsTypes;
  productName: string;
  oldProductName: string;
  productVariantName: string;
  quantity: string;
  sku: string;
  unitPrice: string;
  userName: string;
  createdDate: string;
}

interface UpdatePoFileLine {
  quantity: number;
  poFileLineId?: string;
  orderId?: number;
  productVariantId: number;
  distributorProductName?: string;
  principleProductName: string;
  distributorErpSku?: string;
  principalErpSku: string;
}
export interface UpdatePoFileRequest {
  poFileId?: string;
  lines: UpdatePoFileLine[];
  customerId: string;
  workpsaceId: string;
}

export interface UpdatePoFileResponse {
  message: string;
  errorsResponse: string[];
}

export interface UpdatePOStatusRequest {
  sellerWorkspaceId: string;
  customerId: string;
  poFileId: string;
  status: string;
}

export interface NotifyToBuyerRequest {
  sellerWorkspaceId: string;
  poFileId?: string;
  orderId?: string;
}

export interface OrderFilterRequest {
  workspaceId: string;
  customerId: string;
  pageNo?: number;
  pageSize?: number;
  sortBy?: string;
  sortDirection?: string;
  includeSummary?: boolean;
  includeInvoice?: boolean;
  includeCustomer?: boolean;
  includeStatus?: boolean;
  includeCFA?: boolean;
  includeTeamCustomersMapping?: boolean;
  includeDivision?: boolean;
  searchKeyword?: string;
  startDate: string;
  endDate: string;
  filterModel?: {
    divisionIds?: string[];
    headDivisionIds?: string[];
    cfaIds?: string[];
    status?: string[];
    customerIds?: string[];
    importSource?: string;
    tagIds?: number[];
  };
  skip?: number;
}

export interface OrderFilterResponse {
  status: {
    status: string;
  }[];
  divisions: {
    divisionId: string;
    divisionName: string;
  }[];
  fulfillments: {
    cfaIds: string;
    cfaName: string;
  }[];
  tags: {
    tagId: string;
    tagName: string;
  }[];
  headDivisions: {
    headDivisionId: string;
    headDivisionName: string;
  };
  customers: {
    customerId: string;
    customerName: string;
  }[];
}

// common order res from order and po
// here com stands for common
export interface ComOrderData {
  skuCount: string;
  discount: number;
  tax: number;
  total: number;
  totalWithTax: number;
  invoiceNumber: string;
  invoiceValue: number;
  createdDate: string;
  lines: ComOrderDataLine[];
  importSource: string;
  poFileId: string;
  status: string;
  orderNumber?: string;
  customerId: string;
  customerName: string;
  orderedBy?: string;
  notifyToBuyer: boolean;
  cfaId?: string;
  tags?: { id: number; name: string }[];
  userType?: OrderedUserType;
}

export interface OrderDataLine {
  sequence: string | number;
  orderId: number;
  orderLineId?: number;
  poFileLineId?: string;
  skuCode: string;
  title: string;
  moq: number;
  quantity: number;
  price: number;
  newPrice: number;
  total: number;
  newQty: number;
  productVariantId: number;
  deleted: boolean;
  isEdited: boolean;
  uploadedProductName?: string;
  uploadedQty?: number;
  uploadedQuantity?: number;
  packSize?: number;
  promotionNames: Array<string>;
  discountedPrice: number;
  discountedTotal: number;
  promotionIds: number[];
  discountType?: DiscountType;
  discount?: number;
  tax?: number;
  taxCategory?: string;
  moqError?: boolean;
  productImage?: string;
  isLoading?: boolean;
}

export interface LineInOrder {
  quantity: number;
  productVariantId: number;
  orderId: number;
  poFileId?: string;
  orderLineId?: number;
}

export interface POLineData {
  id: string;
  divisionName: string;
  creditLimit?: number;
  proratedLinePrice: string;
  proratedLinePriceWithTax: string;
  status: string;
  orderLineId: number;
  productVariantId: number;
  linePrice: string;
  linePriceWithTax: string;
  discountedLinePrice: string;
  discountedLinePriceWithTax: string;
  unitPrice: number;
  CFA: string;
  quantity: number;
  divisionId: string;
  orderId: number;
  product?: PoProduct;
  promotionId: number;
  headDivisionId?: string;
  headDivisionName?: string;
  refOrderNumber: string;
  CFAId: string;
  modeOfOrder: string;
  schemeType: string;
  discountedUnitPrice: number;
  uploadedQty?: number;
  taxCategory?: string;
  errorMessage?: string;
  poFileId?: string;

  lineDiscount: LineDiscount;
  promotions?: { id: string; name: string }[];
  assetUrl?: string;
  freeQuantity: number;
  promotionName: string;
}
export interface ComOrderDataLine {
  sequence: string | number;
  orderId?: number;
  orderLineId?: number;
  poFileLineId?: string;
  skuCode: string;
  title: string;
  moq: number;
  quantity: number;
  price: number;
  newPrice: number;
  total: number;
  newQty: number;
  productVariantId: number;
  deleted: boolean;
  isEdited: boolean;
  uploadedProductName?: string;
  uploadedQty?: number;
  uploadedQuantity?: number;
  packSize?: number;
  promotionNames: Array<string>;
  discountedPrice: number;
  discountedTotal: number;
  promotionIds: string[];
  discountType?: DiscountType;
  discount?: number;
  lineDiscount?: LineDiscount;
  lineTax?: string;
  linePrice?: string;
  selected?: boolean;
  assetUrl?: string;
}

export interface TagListRes {
  createdAt: string;
  updatedAt: string;
  name: string;
  workspaceId: string;
  criteria: any;
  condition: any;
  isStatic: boolean;
  isArchived: boolean;
  id: number;
}
export interface CancelOrderResponse {
  createdAt: string;
  updatedAt: string;
  code: string;
  state: string;
  active: boolean;
  createdBy: string;
  lastModifiedBy: null;
  poFileId: string;
  orderPlacedAt: string;
  couponCodes: string[];
  currencyCode: string;
  orderMetaDataId: string;
  fullFillmentLocationId: string;
  divisionId: null;
  headDivisionId: null;
  subTotal: number;
  subTotalWithTax: number;
  taxAmount: number;
  discount: number;
  shipping: number;
  shippingWithTax: number;
  isArchived: boolean;
  userType: string;
  importSource: string;
  total: number;
  totalWithTax: number;
  totalQuantity: number;
  estimatedDeliveryDate: string;
  totalLineItems: number;
  id: number;
  customerId: number;
  taxZoneId: null;
  channelId: number;
  channel: {
    token: string;
    createdAt: string;
    updatedAt: string;
    code: string;
    defaultLanguageCode: string;
    currencyCode: string;
    pricesIncludeTax: boolean;
    applyTax: boolean;
    id: number;
    customFields: {
      workspaceName: string;
    };
  };
  lines: LineData[];
  orderMetaData: OrderMetaData;
  customer: CustomerDetails;
  discounts: string[];
  taxSummary: string[];
}
export interface CancelOrderRequest {
  customerId: string;
  orderId: number;
  workspaceId: string;
  sellerWorkspaceId: string;
}

export interface GetCartRequest {
  workspaceId: string;
  params: {
    sellerWorkspaceId: string;
    customerId: string;
    includeActiveOrders?: boolean;
    includeInvoice?: boolean;
    includeInvoiceLines?: boolean;
    includeOrderInvoices?: boolean;
    includeSummary?: boolean;
    importSource?: 'manual' | 'upload' | 'whatsapp';
  };
}
