import React, { useContext } from 'react';
import { ThemeContext } from '@zonofi/shared';
import CircularProgress from '@mui/material/CircularProgress';
import './zo-button.scss';

export interface ZoButtonProps {

    label?: string | any;

    variant?: 'primary' | 'secondary' | 'ghost' | 'primaryDanger' | 'secondaryDanger' | 'ghostDanger';

    size?: 'large' | 'medium' | 'small' | 'extraSmall';

    shape?: 'squared' | 'rounded';

    disabled?: boolean;

    isLoading?: boolean;

    onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;

    style?: React.CSSProperties;

    className?: string;

    startIcon?: React.ReactNode;

    endIcon?: React.ReactNode;

    type?: 'button' | 'submit' | 'reset';
}

export const ZoButton: React.FC<ZoButtonProps> = ({
    label = 'Button',
    variant = 'primary',
    size = 'medium',
    shape = 'squared',
    disabled = false,
    isLoading = false,
    onClick,
    style = {},
    className = '',
    startIcon,
    endIcon,
    type = 'button',
}) => {
    const { theme } = useContext(ThemeContext);

    const getButtonClass = () => {
        let baseClass = `zo-button zo-button--${variant}`;
        baseClass += ` zo-button--${size}`;
        baseClass += ` zo-button--${shape}`;
        if (disabled || isLoading) baseClass += ' zo-button--disabled';
        if (className) baseClass += ` ${className}`;
        return baseClass;
    };

    const loaderSize = size === 'large' ? 30 : size === 'small' ? 15 : 20;
    // const loaderColor =
    //     variant === 'secondary' ? 'primary' : variant === 'ghost' ? 'primary' : variant === 'secondaryDanger' ? 'primaryDanger' : variant === 'ghostDanger' ? 'primaryDanger' : 'inherit';
    const loaderColor =
        variant === 'secondary' || variant === 'ghost'
            ? '#32a7e8'
            : variant === 'secondaryDanger' || variant === 'ghostDanger'
                ? '#dd360c'
                : '#ffffff';

    return (
        <button
            type={type}
            className={getButtonClass()}
            style={style}
            onClick={!disabled && !isLoading ? onClick : undefined}
            disabled={disabled}
            aria-disabled={disabled}
        >
            {isLoading ? (
                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <CircularProgress
                        size={loaderSize}
                        // color={loaderColor}
                        style={{ color: loaderColor }}
                        className="zo-button__loader"
                    />
                    {/* <span className="zo-button__label">{label}</span> */}
                </div>
            ) : (
                <>
                    {startIcon && <span className="zo-button__start-icon">{startIcon}</span>}
                    <span className="zo-button__label">{label}</span>
                    {endIcon && <span className="zo-button__end-icon">{endIcon}</span>}
                </>
            )}
        </button>
    );
};

export default ZoButton;