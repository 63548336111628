import { ColumnConfig, InvoiceDetailsColumnKey } from '../model';

export const invoiceDetailsConfig: Record<
  InvoiceDetailsColumnKey,
  ColumnConfig
> = {
  skuCode: {
    name: 'SKU Code',
    isHyperlink: true,
    isComponent: true,
  },
  productTitle: {
    name: 'Product Title',
    isComponent: true,
  },
  batchAndExpiryDate: {
    name: 'Batch / Expiry Date',
    isComponent: true,
  },
  pts: {
    name: 'PTS',
    sortField: 'PTS',
    alignment: 'flex-end',
  },
  orderedQuantity: {
    name: 'Ordered Qty',
    sortField: 'orderedQuantity',
    alignment: 'flex-end',
  },
  invoicedQuantity: {
    name: 'Invoiced Qty',
    sortField: 'invoicedQuantity',
    alignment: 'flex-end',
  },
  schemeSavings: {
    name: 'Scheme Savings',
    sortField: 'lineDiscount',
    alignment: 'flex-end',
  },
  taxAmount: {
    name: 'Tax Amt.',
    sortField: 'lineTotalWithTax',
    alignment: 'flex-end',
  },
  invoicedAmount: {
    name: 'Invoiced Amt.',
    isComponent: true,
    sortField: 'listPriceWithTax',
    alignment: 'flex-end',
  },
  ptr: {
    name: 'PTR',
    isAdditional: true,
    isVisible: false,
  },
  orderId: {
    name: 'Order Id',
    isAdditional: true,
    isVisible: false,
  },
  poNo: {
    name: 'PO No.',
    isAdditional: true,
    isVisible: false,
  },
  coNo: {
    name: 'CO No.',
    isAdditional: true,
    isVisible: false,
  },
  mrp: {
    name: 'MRP',
    sortField: 'MRP',
    alignment: 'flex-end',
    isAdditional: true,
    isVisible: false,
  },

  freeQuantity: {
    name: 'Free Qty',
    sortField: 'freeQuantity',
    alignment: 'flex-end',
    isAdditional: true,
    isVisible: false,
  },
  bouncedQuantity: {
    name: 'Bounced Qty',
    sortField: 'bouncedQuantity',
    alignment: 'flex-end',
    isAdditional: true,
    isVisible: false,
  },
};
