import { Api } from '../api';
import {
  ListInputItemOptions,
  addListInputItems,
  mapDataToInputItems,
} from '../filter';
import { getQueryParams } from '../helpers';
import { Products, ProductsRequestBody } from '../products';
import {
  PriceListResponse,
  PriceListRequest,
  PriceListFilterRequest,
  PriceListFilterResponse,
  CreatePriceListBody,
  GetPriceListItemsRsponse,
  GetPriceListItemsRequest,
  ValidatePriceListRes,
  ValidatePriceListReq,
  UpdatePriceListReq,
  PriceListSearchRequest,
  PriceListSearchResponse,
} from './model';
import { updatePriceListSearchData } from './slice';

export const priceListApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    priceList: builder.mutation<
      PriceListResponse,
      { priceListRequest: PriceListRequest }
    >({
      query: (args) => {
        const { priceListRequest } = args;
        return {
          url: `hub/commerce-v2/pricing/all/${priceListRequest.sellerWorkspaceId}`,
          method: 'POST',
          body: priceListRequest,
        };
      },
    }),
    priceListSearch: builder.mutation<
      PriceListSearchResponse,
      PriceListSearchRequest
    >({
      query: (priceListRequest) => {
        return {
          url: `hub/commerce-v2/pricing/search/${priceListRequest.sellerWorkspaceId}?sellerWorskpaceId=${priceListRequest.sellerWorkspaceId}`,
          method: 'POST',
          body: priceListRequest,
        };
      },
      async onQueryStarted(request, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(updatePriceListSearchData(data));
        } catch (err) {
          console.log(err, 'dispatchErr');
        }
      },
    }),
    productPriceList: builder.mutation<
      Products,
      {
        productPriceList: ProductsRequestBody;
        dispatchFilters?: boolean;
        includeAllProducts?: boolean;
        priceListId?: string;
        abortController?: AbortController;
      }
    >({
      query: (args) => ({
        url: `hub/commerce-v2/pricing/details/${
          args?.productPriceList.sellerWorkspaceId
        }?${
          args.priceListId ? `priceListId=${args.priceListId}` : ''
        }${`&includeAllProducts=${args.includeAllProducts ?? false}`}
        `,
        method: 'POST',
        body: args.productPriceList,
        signal: args.abortController?.signal,
      }),
      async onQueryStarted(request, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then((response) => {
            if (request.dispatchFilters) {
              const cfaInputItems = mapDataToInputItems(
                response.data.cfaSummary,
                'cfaId',
                'supplierName'
              )?.filter((x: any) => x.value);
              dispatch(
                addListInputItems({
                  inputItems: cfaInputItems,
                  key: ListInputItemOptions.cfa,
                })
              );

              const categoriesItem = mapDataToInputItems(
                response.data.categoryCodes,
                'categoryId',
                'categoryCode'
              );
              dispatch(
                addListInputItems({
                  inputItems: categoriesItem,
                  key: ListInputItemOptions.categories,
                })
              );

              const divisionInputItems = mapDataToInputItems(
                response.data.divisionSummary,
                'divisionId',
                'divisionCode'
              )?.filter((x: any) => x.value);
              dispatch(
                addListInputItems({
                  inputItems: divisionInputItems,
                  key: ListInputItemOptions.divisions,
                })
              );
            }
          })
          .catch((err) => console.log(err));
      },
    }),
    priceListFilters: builder.query<
      PriceListFilterResponse,
      { priceListFilterReq: PriceListFilterRequest }
    >({
      query: (args) => {
        const { priceListFilterReq } = args;
        return {
          url: `hub/commerce-v2/pricing/price-filter/${priceListFilterReq.sellerWorkspaceId}`,
          method: 'POST',
          body: priceListFilterReq,
        };
      },
      async onQueryStarted(request, { dispatch, queryFulfilled }) {
        queryFulfilled.then((response) => {
          const cfaInputItems = mapDataToInputItems(
            response?.data?.cfa,
            'id',
            'name'
          )?.filter((x: any) => x.value);

          dispatch(
            addListInputItems({
              inputItems: cfaInputItems,
              key: ListInputItemOptions.cfa,
            })
          );

          const customerInputItems = mapDataToInputItems(
            response?.data?.customers,
            'id',
            'name'
          )?.filter((x: any) => x.value);
          dispatch(
            addListInputItems({
              inputItems: customerInputItems,
              key: ListInputItemOptions.customers,
            })
          );

          const segmentInputItems = mapDataToInputItems(
            response?.data?.segment,
            'channelId',
            'channelName'
          )?.filter((x: any) => x.value);
          dispatch(
            addListInputItems({
              inputItems: segmentInputItems,
              key: ListInputItemOptions.segment,
            })
          );

          const criteria = response.data.criteria.map((item) => ({
            id: item,
            name: item,
          }));

          const criteriaItems = mapDataToInputItems(
            criteria,
            'id',
            'name'
          )?.filter((x: any) => x.value);
          dispatch(
            addListInputItems({
              inputItems: criteriaItems,
              key: ListInputItemOptions.criteria,
            })
          );

          const status = response.data.status.map((item) => ({
            id: item,
            name: item,
          }));

          const statusItems = mapDataToInputItems(status, 'id', 'name')?.filter(
            (x: any) => x.value
          );
          dispatch(
            addListInputItems({
              inputItems: statusItems,
              key: ListInputItemOptions.priceListStatus,
            })
          );
        });
      },
    }),
    createPriceList: builder.mutation<
      { response: string },
      { createPriceListBody: CreatePriceListBody }
    >({
      query: (args) => {
        const { createPriceListBody } = args;
        return {
          url: `hub/commerce-v2/pricing/${createPriceListBody.sellerWorkspaceId}`,
          method: 'POST',
          body: createPriceListBody,
        };
      },
    }),
    getPriceList: builder.query<
      GetPriceListItemsRsponse,
      GetPriceListItemsRequest
    >({
      query: (args) => ({
        url: `hub/commerce-v2/pricing/details/${args.sellerWorkspaceId}`,
        method: 'GET',
        params: args,
      }),
    }),
    validatePriceList: builder.query<
      ValidatePriceListRes,
      ValidatePriceListReq
    >({
      query: (args) => ({
        url: `hub/commerce-v2/pricing/validate/${
          args.sellerWorkspaceId
        }?${getQueryParams(args)}`,
        method: 'GET',
      }),
    }),
    updatePriceList: builder.mutation<any, UpdatePriceListReq>({
      query: (args) => ({
        url: `hub/commerce-v2/pricing/update/${args.sellerWorkspaceId}`,
        method: 'PUT',
        body: args,
        responseHandler: (response) => {
          return response.text();
        },
      }),
    }),
    pincode: builder.query<{ postalCodes: string[] }, { workspaceId: string }>({
      query: (args) => ({
        url: `pincodes?sellerWorkspaceId=${args.workspaceId}`,
        method: 'GET',
      }),
    }),
    segement: builder.query<any, any>({
      query: (args) => ({
        url: `hub/communication-v2/api/folder/channels/${args.workspaceId}?type=customer_segment&sellerWorkspaceId=${args.workspaceId}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useLazyPincodeQuery,
  usePriceListMutation,
  useLazyPriceListFiltersQuery,
  useProductPriceListMutation,
  useCreatePriceListMutation,
  useLazyGetPriceListQuery,
  useLazyValidatePriceListQuery,
  useUpdatePriceListMutation,
  usePriceListSearchMutation,
  useLazySegementQuery,
} = priceListApi;
