import { Tag, Theme, ThemeContext, colors } from '@zonofi/shared';
import { ProductVariant } from '@zonofi/zono-money-store';
import React from 'react';
import Icon from 'react-native-easy-icon';
import AddToOrder from '../add-to-order/add-to-order';
import { OrderDataLine } from '@zonofi/zono-money-helpers';

interface AddProductCardProps {
  product: ProductVariant;
  editableData: OrderDataLine[];
  orderId?: number;
  handleDeleteLine: (product: ProductVariant) => void;
  updateLine: (line: OrderDataLine, isReplace?: boolean) => void;
  poFileLineId: string;
}

export const AddProductCard: React.FC<AddProductCardProps> = ({
  product,
  editableData,
  orderId,
  handleDeleteLine,
  updateLine,
  poFileLineId,
}) => {
  const { theme } = React.useContext(ThemeContext);

  const styles = getStyles(theme);
  const tagStyle = {
    text: theme.status.success,
    borderColor: colors.transparent.color,
    backgroundColor: '#E8FFE0',
    borderRadius: 4,
  };

  const productInOrder: OrderDataLine = (editableData
    ?.filter((x) => !x.deleted)
    .find((x) => x?.productVariantId === product?.id) as OrderDataLine) || {
    productVariantId: product?.id,
    moq: product?.minOrderQty,
    isEdited: true,
    deleted: false,
    quantity: 0,
    newQty: 0,
    sequence: '',
    skuCode: '',
    title: '',
    price: 0,
    newPrice: 0,
    total: 0,
    promotionNames: [],
    discountedPrice: 0,
    discountedTotal: 0,
    promotionIds: [],
    orderId: 0,
  };

  const onMapProduct = () => {
    const index = editableData?.findIndex(
      (x) => x.poFileLineId === poFileLineId
    );
    if (index !== -1) {
      const orderline = editableData[index];
      orderline.productVariantId = product.id;
      orderline.newQty = product.minOrderQty;
      updateLine(orderline, true);
    }
  };

  return (
    <main className="product-card" style={{ background: theme.primary }}>
      <section
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <div
            style={{
              display: 'flex',
              gap: 2,
              ...styles.greyText,
            }}
          >
            <div>{`SKU: ${product?.sku}`}</div>
            {product?.enabled && (
              <Tag label="ACTIVE" style={tagStyle} fontSize={8} />
            )}
          </div>
          <div style={styles.blackText}>{product?.name || ''}</div>
        </div>
        {poFileLineId ? (
          <span
            className="map-button"
            style={theme.fontConfig.semiBold}
            onClick={onMapProduct}
          >
            Map Product
          </span>
        ) : (
          <AddToOrder
            product={product}
            orderline={productInOrder}
            allowUpdate={true}
            orderId={orderId}
            updateLine={updateLine}
          />
        )}
      </section>
      <div style={styles.divider} />
      <section
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-end',
          width: '100%',
        }}
      >
        {productInOrder?.newQty !== 0 && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              fontSize: 14,
              color: '#EA0500',
              ...theme.fontConfig.regular,
            }}
            onClick={() => handleDeleteLine(product)}
          >
            Remove
            <Icon name="trash" type="evilicon" size={24} color="#EA0500" />
          </div>
        )}
      </section>
    </main>
  );
};

type stylekey =
  | 'container'
  | 'greyText'
  | 'blackText'
  | 'blackTextBold'
  | 'totalItemText'
  | 'divider';

const getStyles = (theme: Theme): Record<stylekey, React.CSSProperties> => ({
  container: {
    background: '#FFFFFF',
    boxShadow: '0px 3px 6px #00000014',
    borderRadius: 8,
    paddingInline: 16,
    paddingBlock: 12,
    display: 'flex',
    flexDirection: 'column',
  },
  greyText: {
    ...theme.fontConfig.regular,
    fontSize: 12,
    color: colors.gray.primary,
  },
  blackText: {
    ...theme.fontConfig.regular,
    fontSize: 14,
    color: colors.raisinBlack.primary,
  },
  blackTextBold: {
    ...theme.fontConfig.semiBold,
    fontSize: 12,
    color: colors.raisinBlack.primary,
  },
  totalItemText: {
    ...theme.fontConfig.regular,
    fontSize: 12,
    color: colors.raisinBlack.secondary,
  },
  divider: {
    height: 1,
    backgroundColor: colors.gray.opacity20,
    width: '100%',
    marginBlock: 4,
  },
});
