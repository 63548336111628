import { Api } from '../api/root-api';
import { Platform } from 'react-native';
import {
  CreateProductRequest,
  CreateProductResponse,
  Products,
  TaxCategoryResponse,
  AddDivisionsToProductResponse,
  RecommendedProductsResponse,
  RecommendedProductsBody,
  UpdateRecommendedProductRequest,
  RecommendedProducts,
  ProductLockRequest,
  CollectionData,
  DownloadProductListRequest,
  DownloadProductListResponse,
  DownloadedProductsHistoryRequest,
  DownloadedProductsHistoryResponse,
  DownloadGeneratedProductListRequest,
  DownloadGeneratedProductListResponse,
  ProductsRequest,
} from './model';
import {
  addListInputItems,
  ListInputItemOptions,
  mapDataToInputItems,
} from '../filter';

export const productsApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    getProductsList: builder.mutation<Products, ProductsRequest>({
      query: (args) => {
        const { params, body, workspaceId } = args;
        const { sellerWorkspaceId } = params;
        return {
          url: `hub/commerce-v2/products/search/${workspaceId}`,
          method: 'POST',
          body: body,
          params: {
            ...params,
            sellerWorkspaceId: sellerWorkspaceId || workspaceId,
          },
        };
      },
      async onQueryStarted(request, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then((response) => {
            if (request?.dispatchFilter) {
              const divisionInputItems = mapDataToInputItems(
                response?.data?.divisionSummary,
                'divisionId',
                'name'
              );

              dispatch(
                addListInputItems({
                  inputItems: divisionInputItems,
                  key: ListInputItemOptions.divisions,
                })
              );
              const cfaInputItems = mapDataToInputItems(
                response?.data?.cfaSummary,
                'cfaId',
                'supplierName'
              );
              dispatch(
                addListInputItems({
                  inputItems: cfaInputItems,
                  key: ListInputItemOptions.cfa,
                })
              );
              const categories = response?.data?.categoryCodes;
              Platform.OS !== 'web' &&
                categories?.unshift({
                  categoryCode: '',
                  categoryId: 0,
                  categoryName: 'All',
                  selected: true,
                });
              const categoryInputItems = mapDataToInputItems(
                categories,
                'categoryCode',
                'categoryName'
              );
              dispatch(
                addListInputItems({
                  inputItems: categoryInputItems,
                  key: ListInputItemOptions.categories,
                })
              );
            }
          })
          .catch((error) => console.log(error));
      },
    }),
    getCfaProductsList: builder.mutation<Products, ProductsRequest>({
      query: (args) => {
        const { params, body, workspaceId } = args;
        const { sellerWorkspaceId } = params;
        return {
          url: `hub/commerce-v2/products/search/customer/${workspaceId}`,
          method: 'POST',
          body: body,
          params: {
            ...params,
            sellerWorkspaceId: sellerWorkspaceId || workspaceId,
          },
        };
      },
      async onQueryStarted(request, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then((response) => {
            if (request?.dispatchFilter) {
              const divisionInputItems = mapDataToInputItems(
                response?.data?.divisionSummary,
                'divisionId',
                'name'
              );
              dispatch(
                addListInputItems({
                  inputItems: divisionInputItems,
                  key: ListInputItemOptions.divisions,
                })
              );
              const cfaInputItems = mapDataToInputItems(
                response?.data?.cfaSummary,
                'cfaId',
                'supplierName'
              );
              dispatch(
                addListInputItems({
                  inputItems: cfaInputItems,
                  key: ListInputItemOptions.cfa,
                })
              );
              const categories = response?.data?.categoryCodes;
              Platform.OS !== 'web' &&
                categories?.unshift({
                  categoryCode: '',
                  categoryId: 0,
                  categoryName: 'All',
                  selected: true,
                });
              const categoryInputItems = mapDataToInputItems(
                categories,
                'categoryCode',
                'categoryName',
                'selected'
              );
              dispatch(
                addListInputItems({
                  inputItems: categoryInputItems,
                  key: ListInputItemOptions.categories,
                })
              );
            }
          })
          .catch((error) => console.log(error));
      },
    }),
    postProductDetails: builder.mutation<
      {
        product: number;
        productVariantId: number;
        status: string;
        statusCode: number;
        message: string;
      },
      {
        body: CreateProductRequest;
        workspaceId: string;
      }
    >({
      query: (args) => ({
        url: `hub/commerce-v2/products/${args.workspaceId}?sellerWorkspaceId=${args.workspaceId}`,
        method: 'POST',
        body: args.body,
      }),
    }),
    postProductsUpload: builder.mutation<any, any>({
      query(params: any) {
        return {
          url: `mdm-integration/uploadbatch`,
          method: 'POST',
          body: params,
        };
      },
    }),
    getTaxCategory: builder.query<TaxCategoryResponse, string>({
      query(arg) {
        return {
          url: `hub/commerce-v2/taxation/category/${arg}?sellerWorkspaceId=${arg}`,
          method: 'GET',
        };
      },
    }),
    addDivisionsToProduct: builder.mutation<
      AddDivisionsToProductResponse,
      {
        body: {
          divisionIds: string[];
          productVariantIds: number[];
        };
        workspaceId: string;
      }
    >({
      query: (args) => ({
        url: `hub/commerce-v2/division/productVariants/add/${args.workspaceId}?sellerWorkspaceId=${args.workspaceId}`,
        method: 'POST',
        body: args.body,
      }),
    }),
    recommendedProducts: builder.mutation<
      RecommendedProductsResponse,
      {
        workspaceId: string;
        body: RecommendedProductsBody;
      }
    >({
      query: (args) => {
        return {
          url: `hub/commerce-v2/recommended-products/${args.workspaceId}?sellerWorkspaceId=${args.workspaceId}`,
          method: 'POST',
          body: args.body,
        };
      },
    }),
    updateRecommendedProduct: builder.mutation<
      RecommendedProducts,
      UpdateRecommendedProductRequest
    >({
      query: (args) => ({
        url: `hub/commerce-v2/recommended-product/${args.workspaceId}?sellerWorkspaceId=${args.workspaceId}`,
        method: 'POST',
        body: args.body,
      }),
    }),
    updateProductLockByCfa: builder.mutation<
      string,
      {
        workspaceId: string;
        body: ProductLockRequest;
      }
    >({
      query: (args) => ({
        url: `hub/commerce-v2/productVariant/cfa/edit/${args.workspaceId}?sellerWorkspaceId=${args.workspaceId}`,
        method: 'POST',
        body: args.body,
      }),
    }),
    collectionList: builder.query<
      { collections: CollectionData[] },
      { sellerWorkspaceId: string }
    >({
      query(arg) {
        return {
          url: `hub/commerce-v2/collections/${arg.sellerWorkspaceId}`,
          method: 'GET',
        };
      },
      transformResponse: (res: any) => {
        return {
          collections: res?.items?.map((x: any) =>
            x?.translations?.map((y: any) => {
              return { parentId: x?.id, ...y };
            })
          ),
        };
      },
    }),
    initiateProductListDownload: builder.mutation<
      DownloadProductListResponse,
      DownloadProductListRequest
    >({
      query: (args) => ({
        url: `hub/commerce-v2/products/catalogue/download/${args.workspaceId}?sellerWorkspaceId=${args.workspaceId}`,
        method: 'POST',
        body: args.body,
      }),
    }),
    downloadedProductsHistory: builder.query<
      DownloadedProductsHistoryResponse[],
      DownloadedProductsHistoryRequest
    >({
      query: (arg) => ({
        url: `downloadTasks/list-s3-contents/${arg?.workspaceId}?entity=products&maxKeys=10`,
        method: 'GET',
      }),
    }),
    downloadGeneratedProductList: builder.query<
      DownloadGeneratedProductListResponse,
      DownloadGeneratedProductListRequest
    >({
      query: (arg) => ({
        url: `downloadTasks/download-s3-file/${arg?.workspaceId}?entity=products&key=${arg?.key}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetProductsListMutation,
  usePostProductDetailsMutation,
  usePostProductsUploadMutation,
  useLazyGetTaxCategoryQuery,
  useAddDivisionsToProductMutation,
  useGetCfaProductsListMutation,
  useRecommendedProductsMutation,
  useUpdateRecommendedProductMutation,
  useUpdateProductLockByCfaMutation,
  useLazyCollectionListQuery,
  useInitiateProductListDownloadMutation,
  useLazyDownloadedProductsHistoryQuery,
  useLazyDownloadGeneratedProductListQuery,
} = productsApi;
