import { ColumnConfig, CustomerReturnDetailsColumnKey } from '../../model';

export const customerReturnDetailsConfig: Record<
  CustomerReturnDetailsColumnKey,
  ColumnConfig
> = {
  batchNumber: {
    name: 'Batch Number',
    field: 'multiLine',
  },
  title: {
    name: 'Product Title & SKU Code',
    field: 'multiLine',
  },
  returnCoNo: {
    name: 'Return Co No.',
    status: ['CREDIT_NOTE_GENERATED', 'ACKNOWLEDGED_BY_COMPANY'],
  },
  pts: {
    name: 'P. PTS',
    sortField: 'pts',
    headerExtra: {
      type: 'info',
      details:
        'Potential value of return good, subject to change based on CFA reconciliation and actual selling price.',
      title: 'Potential PTS',
    },
  },
  invoiceNumber: {
    name: 'Invoice Number',
  },
  category: {
    name: 'Category',
  },
  expiryDate: {
    name: 'Expiry Date',
    sortField: 'expiryDate',
  },
  quantity: {
    name: 'Return Qty',
    alignment: 'flex-end',
  },
  creditNoteNumber: {
    name: 'Credit Note Number',
    field: 'creditNoteNumber',
    status: ['CREDIT_NOTE_GENERATED'],
  },
  creditNoteDate: {
    name: 'Credit Note Date',
    field: 'creditNoteDate',
    status: ['CREDIT_NOTE_GENERATED'],
  },
  creditNoteAmount: {
    name: 'Credit Note Amount',
    field: 'creditNoteAmount',
    alignment: 'flex-end',
    status: ['CREDIT_NOTE_GENERATED'],
  },
  type: {
    name: 'Type',
  },
  cfaRemarks: {
    name: 'CFA Remarks',
    field: 'toolTip',
  },
};
