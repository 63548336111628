import { Api } from '../api';
import {
  ConfigSummaryRequest,
  ConfigSummaryResponse,
  ConfigDetailsRequest,
  ConfigDetailsResponse,
  CreateConfigSettingsResponse,
  CreateConfigSettingsRequest,
  ConfigSchemaRequest,
  ConfigSchema,
} from './model';

export const configApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    configSummary: builder.mutation<
      ConfigSummaryResponse[],
      ConfigSummaryRequest
    >({
      query(arg) {
        return {
          url: `configsettings/config/summary`,
          body: arg,
          method: 'POST',
        };
      },
    }),
    getConfigDetails: builder.query<
      ConfigDetailsResponse,
      ConfigDetailsRequest
    >({
      query(arg) {
        return {
          url: `configsettings/config`,
          params: arg,
          method: 'GET',
        };
      },
    }),
    createConfigSettings: builder.mutation<
      CreateConfigSettingsResponse,
      CreateConfigSettingsRequest
    >({
      query(arg) {
        return {
          url: `configsettings/create`,
          body: arg,
          method: 'POST',
        };
      },
    }),
    getConfigSchema: builder.query<ConfigSchema[], ConfigSchemaRequest>({
      query(arg) {
        return {
          url: `configsettings/schema/${arg.workspaceId}`,
          params: arg,
          method: 'GET',
        };
      },
    }),
  }),
});

export const {
  useConfigSummaryMutation,
  useCreateConfigSettingsMutation,
  useLazyGetConfigDetailsQuery,
  useLazyGetConfigSchemaQuery,
} = configApi;
