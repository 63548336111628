import {
  GetCartSummaryResponse,
  OrderDetailsResponse,
  OrderLine,
  POFileDetailsByIdResponse,
  PoFile,
  PoOrderDetailsResponse,
} from '@zonofi/zono-money-store';
import { isUndefined } from 'lodash';
import { OrderData, OrderDataLine } from '../types/interfaces';
import { OrderStatusEnum } from '../types/enum';

export const extractDataFromOrder = (
  data: OrderDetailsResponse,
  updateResponseOrderLines?: OrderLine[]
): OrderData => {
  if (isUndefined(data)) return undefined;
  const {
    skucount,
    discount,
    totalWithTax,
    invoiceSummary,
    createdAt,
    lines,
    id: orderId,
    poFile,
    status,
    refOrderNumber,
    taxAmount,
    customerName = '',
    customerId,
    total,
    notifyToBuyer = false,
    showPrice = false,
    userType,
    totalQuantity,
  } = data || {};
  const orderlines: OrderDataLine[] = lines?.map((line) => {
    const {
      productVariant,
      minOrderQty = 1,
      quantity = 0,
      unitPrice,
      linePriceWithTax,
      id: orderLineId,
      poFileLineId,
      discountedUnitPrice,
      discountedLinePrice,
      discountedLinePriceWithTax,
      promotionIds = [],
      packSize,
      promotionNames = [],
      uploadedProductName,
      uploadedQty,
      sequence = '',
      taxLines,
      lineDiscount,
      lineTax,
      linePrice,
      adjustedPrice,
    } = line || {};
    return {
      sequence: sequence,
      skuCode: productVariant?.sku || '-',
      title: productVariant?.translations?.[0]?.name || '-',
      moq: minOrderQty,
      quantity,
      price: Number(unitPrice),
      newPrice: Number(discountedUnitPrice),
      totalWithoutTax: Number(linePrice),
      total: Number(linePriceWithTax),
      orderId,
      poFileLineId: poFileLineId || updateResponseOrderLines?.[0]?.poFileLineId,
      newQty: quantity,
      productVariantId: productVariant?.id,
      deleted: false,
      isEdited: false,
      discountedPrice: Number(discountedUnitPrice || 0),
      discountedLinePrice: Number(discountedLinePrice),
      discountedTotal: Number(discountedLinePriceWithTax),
      promotionIds,
      packSize,
      promotionNames,
      uploadedProductName,
      uploadedQuantity: uploadedQty,
      taxCategory: taxLines?.[0]?.taxCategory,
      tax: Number(taxLines?.[0]?.taxAmount),
      lineDiscount,
      lineTax,
      linePrice,
      adjustedPrice,
      productImage: productVariant?.assetUrl,
      orderLineId,
    };
  });

  return {
    skuCount: skucount?.toString(),
    discount: Number(discount),
    totalWithTax: Number(totalWithTax),
    invoiceNumber: '-',
    invoiceValue: Number(invoiceSummary?.totalAmount),
    createdDate: createdAt,
    lines: orderlines,
    importSource: poFile?.importSource,
    poFileId: poFile?.id,
    status:
      status === OrderStatusEnum.SUBMITTED_BY_CUSTOMER ? 'Submitted' : status,
    orderNumber: refOrderNumber,
    tax: Number(taxAmount),
    customerId,
    customerName,
    total: Number(total),
    notifyToBuyer,
    showPrice,
    poNumber: '-',
    userType,
    totalQuantity,
  };
};

export const extractDataFromPO = (data: PoOrderDetailsResponse): OrderData => {
  if (isUndefined(data)) return undefined;
  const {
    skuCount,
    discount,
    totalWithTax,
    invoiceSummary,
    uploadedDate,
    lines,
    importSource,
    pofileId,
    orderState,
    refOrderNumber,
    tax,
    customerId,
    orderedBy,
    total,
    notifyToBuyer = false,
    showPrice = false,
    poNumber = '-',
    userType,
    totalQuantity,
  } = data || {};
  const polines: OrderDataLine[] = lines?.map((line) => {
    const {
      productVariant,
      MOQ,
      unitPrice,
      quantity = 0,
      linePriceWithTax,
      orderId,
      orderLineId,
      poFileLineId,
      distributorProductName,
      uploadedQty,
      discountedUnitPrice,
      discountedLinePriceWithTax,
      promotionIds,
      packSize,
      promotionNames,
      taxLines,
      lineDiscount,
      lineTax,
      linePrice,
      adjustedPrice,
      uploadedProductName,
    } = line || {};
    return {
      sequence: line?.sequence,
      skuCode: productVariant?.sku || '-',
      title:
        productVariant?.translations?.[0]?.name || uploadedProductName || '',
      moq: MOQ || productVariant?.MOQ || 1,
      price: Number(unitPrice),
      newPrice: Number(discountedUnitPrice),
      quantity,
      total: Number(linePriceWithTax),
      orderId,
      poFileLineId,
      promotionNames,
      newQty: quantity,
      productVariantId: productVariant?.productVariantId,
      deleted: false,
      isEdited: false,
      uploadedProductName: distributorProductName,
      uploadedQty,
      discountedPrice: Number(discountedUnitPrice),
      discountedTotal: Number(discountedLinePriceWithTax),
      promotionIds: promotionIds,
      packSize,
      uploadedQuantity: uploadedQty,
      taxCategory: taxLines?.[0]?.taxCategory,
      tax: Number(taxLines?.[0]?.taxAmount),
      lineDiscount,
      lineTax,
      linePrice,
      adjustedPrice,
      productImage: productVariant?.assetUrl,
      orderLineId,
    };
  });

  return {
    skuCount: skuCount,
    discount: Number(discount),
    totalWithTax: Number(totalWithTax),
    invoiceNumber: '-',
    invoiceValue: Number(invoiceSummary?.totalAmount),
    createdDate: uploadedDate,
    lines: polines,
    importSource,
    poFileId: pofileId,
    status:
      orderState === OrderStatusEnum.SUBMITTED_BY_CUSTOMER
        ? 'Submitted'
        : orderState,
    orderNumber: refOrderNumber,
    tax: Number(tax),
    customerId,
    customerName: orderedBy,
    total: Number(total),
    notifyToBuyer,
    showPrice,
    poNumber,
    userType,
    totalQuantity,
  };
};

export const extractDataFromPOForManual = (
  data: POFileDetailsByIdResponse
): OrderData => {
  if (isUndefined(data)) return undefined;
  const {
    skuCount,
    totalWithTax,
    uploadedDate,
    lines,
    importSource,
    id,
    status,
    totalItemsCount,
  } = data || {};
  const polines: OrderDataLine[] = lines?.map((line) => {
    const {
      product,
      unitPrice,
      quantity = 0,
      linePriceWithTax,
      orderId,
      orderLineId,
      discountedUnitPrice,
      discountedLinePriceWithTax,
      taxCategory,
      linePrice,
      promotionId,
      assetUrl,
    } = line || {};

    return {
      sequence: '',
      skuCode: product?.sku || '-',
      title: product?.description || product?.name || '',
      moq: product?.MOQ || 1,
      price: Number(unitPrice),
      newPrice: Number(discountedUnitPrice),
      quantity,
      total: Number(linePriceWithTax),
      orderId,
      promotionNames: [],
      newQty: quantity,
      productVariantId: product?.productVariantId,
      deleted: false,
      isEdited: false,
      uploadedProductName: product?.description,
      uploadedQty: 0,
      discountedPrice: Number(discountedUnitPrice),
      discountedTotal: Number(discountedLinePriceWithTax),
      promotionIds: promotionId !== 0 ? [promotionId] : [],
      packSize: product?.packSize,
      uploadedQuantity: 0,
      taxCategory,
      tax: 0,
      lineDiscount: 0,
      lineTax: 0,
      linePrice,
      adjustedPrice: 0,
      productImage: assetUrl,
      orderLineId,
    };
  });

  let showPrice = polines?.every((item) => item?.price !== 0);

  return {
    skuCount: skuCount?.toString(),
    discount: 0,
    totalWithTax: Number(totalWithTax),
    invoiceNumber: '-',
    invoiceValue: 0,
    createdDate: uploadedDate,
    lines: polines,
    importSource,
    poFileId: id,
    status:
      status === OrderStatusEnum.SUBMITTED_BY_CUSTOMER ? 'Submitted' : status,
    orderNumber: '',
    tax: 0,
    customerId: '',
    customerName: '',
    total: 0,
    notifyToBuyer: false,
    showPrice: showPrice,
    poNumber: id,
    userType: '',
    totalQuantity: totalItemsCount,
  };
};

export const extractDataFromCart = (
  POFiles: PoFile[],
  orderSummary: GetCartSummaryResponse
): OrderData => {
  if (isUndefined(POFiles)) return undefined;
  let polines: OrderDataLine[] = [];
  let poFileIds: string[] = [];

  POFiles?.forEach((poFile) => {
    poFileIds?.push(poFile?.id);
    poFile?.lines?.forEach((line) => {
      const {
        orderId,
        orderLineId,
        poFileId,
        quantity,
        unitPrice,
        discountedUnitPrice,
        discountedLinePrice,
        discountedLinePriceWithTax,
        productVariantId,
        product,
        linePriceWithTax,
        taxCategory,
        linePrice,
        assetUrl,
        lineDiscount,
        promotions,
        freeQuantity,
        promotionName,
      } = line || {};

      polines?.push({
        sequence: '',
        skuCode: product?.sku?.toString() || '-',
        title: product?.description || '',
        moq: product?.MOQ || 1,
        productImage: assetUrl,
        price: Number(unitPrice),
        newPrice: Number(discountedUnitPrice),
        quantity,
        total: Number(linePriceWithTax),
        orderId,
        orderLineId,
        poFileLineId: line?.id || '',
        promotionNames: [],
        newQty: quantity,
        productVariantId,
        deleted: false,
        isEdited: false,
        uploadedProductName: product?.name,
        uploadedQty: 0,
        discountedPrice: Number(discountedUnitPrice),
        discountedTotal: Number(discountedLinePriceWithTax),
        promotionIds: promotions?.map((i) => i?.id),
        packSize: product?.packSize,
        uploadedQuantity: 0,
        taxCategory: taxCategory,
        tax: 0,
      });
    });
  });

  return {
    skuCount: orderSummary?.totalSKUCount?.toString(),
    discount: Number(orderSummary?.totalDiscount),
    totalWithTax: Number(orderSummary?.grossOrderValue),
    invoiceNumber: '-',
    invoiceValue: 0,
    createdDate: '',
    lines: polines,
    importSource: '',
    poFileId: '',
    status: OrderStatusEnum.ADDING_ITEMS,
    orderNumber: '',
    tax: Number(orderSummary?.totalTax),
    customerId: '',
    customerName: '',
    total: Number(orderSummary?.grossOrderValue),
    notifyToBuyer: false,
    showPrice: true,
    poNumber: '',
    userType: '',
    totalQuantity: orderSummary?.totalItemsCount,
    poFileIds,
  };
};
