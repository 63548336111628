import { ChatActionSendLedgerColumnKey, ColumnConfig } from '../../model';

export const chatActionSendLedgerConfig: Record<
  ChatActionSendLedgerColumnKey,
  ColumnConfig
> = {
  documentNo: {
    name: 'Document No.',
  },
  division: {
    name: 'Division',
    alignment: 'flex-start',
  },
  documentType: {
    name: 'Document Type',
  },
  credited: {
    name: 'Credited',
    alignment: 'flex-end',
  },
  debit: {
    name: 'Debit',
    alignment: 'flex-end',
  },
  balance: {
    name: 'Balance',
    alignment: 'flex-end',
  },
  referenceNo: {
    name: 'Reference No.',
    alignment: 'flex-start',
  },
};
