import { MessagePayload } from '@zonofi/common';
import { CustomerData } from '../customers';
import { OrderImportSourceEnum } from '../helpers';
import { IssueRole } from '../thread';

export interface AddChannelMemberRequest {
  userId: string;
  workspaceId: string;
  channelId: string;
  customerIds?: string[];
  members?: { id: string; role?: string; issueRole?: IssueRole }[];
  inviteId: string | undefined;
  sellerWorkspaceId: string;
  employeeName?: string;
  numOfMember?: number;
  showToast?: boolean;
  isIssueRoleChange?: boolean;
}

export interface RemoveChannelMemberRequest {
  userId: string;
  workspaceId: string;
  channelId: string;
  customerIds?: string[];
  memberIds?: string[];
  inviteId: string | undefined;
  sellerWorkspaceId: string;
  employeeName?: string;
  showToast?: boolean;
}

export interface CreateChannelRequest {
  channelName: string;
  workspaceId: string;
  inviteId: string;
  emojiId?: string;
  folderId?: string;
  id?: string;
  sellerWorkspaceId: string;
}

export interface Channel {
  id: string;
  createdAt: string;
  updatedAt: string;
  channelName: string;
  linkedWorkspaceId: string;
  accessType: string;
  profilePic?: string;
  updatedBy?: null;
  workspaceId: string;
  description: string;
  ownerId?: null;
  appId: string;
  type: string;
  customerId?: string;
  folderId: string;
  emojiId: string;
  channelMembers?: ChannelMembers;
  lastMessageTimeToken?: number;
  unReadMessagesCount?: number;
  role?: ChannelMemberRole;
  channelSubType?: string;
}

export type ChannelMemberRole = 'ADMIN' | 'MEMBER' | 'CUSTOMERADMIN' | null;

export interface ChannelMembers {
  customers: ChannelCustomer[];
  teamMembers: ChannelTeamMember[];
}

export interface ChannelListRequest {
  workspaceId: string;
  inviteId?: string;
  principalWorkspaceId?: string;
  sellerWorkspaceId: string;
  random?: number;
}

export interface ChannelListResponse {
  folders: Folder[];
  rootChannels: Channel[];
}

export interface Folder {
  id: string;
  createdAt: null;
  updatedAt: null;
  name: string;
  parentId: string | null;
  workspaceId: string;
  colorCode: string;
  shadeVariation: string;
  channels?: Channel[];
  subFolders?: Folder[];
}

export interface StoredFolder {
  id: string;
  createdAt: null;
  updatedAt: null;
  name: string;
  parentId: string | null;
  workspaceId: string;
  colorCode: string;
  shadeVariation: string;
  channels?: string[];
  subFolders?: StoredFolder[];
}

export interface ChannelDetailsRequest {
  workspaceId: string;
  channelId?: string;
  sellerWorkspaceId: string;
  customerId?: string;
  channelType?: string;
  random?: number;
}

export interface AcceptChannelInviteRequest {
  workspaceId: string;
  channelId: string;
  principalWorkspaceId?: string;
  inviteId: string;
  sellerWorkspaceId: string;
}

export interface GetChannelNameRequest {
  workspaceId: string;
  channelId: string;
  // principalWorkspaceId?: string;
  inviteId: string;
  sellerWorkspaceId: string;
}

export interface CompanyTeamMember {
  cityName: string;
  companyName: string;
  employeeCode: string;
  employeeName: string;
  fulfillmentLocationId: string;
  inviteId: string;
  stateName: string;
  role: string;
}

export enum OrderAction {
  orderToBeApproved = 'Orders To Be Approved',
  toBeInvoiced = 'To Be Invoiced',
  sendPaymentRequest = 'Send Payment Request',
  paymentReceived = 'Payment Received',
}

export enum Enquiry {
  priceEnquiry = 'Price Enquiry',
  invoiceEnquiry = 'Invoice Enquiry',
  schemsEnquiry = 'Schemes Enquiry',
  orderEnquiry = 'Order Enquiry',
  ledgerEnquiry = 'Ledger Enquiry',
  paymentEnquiry = 'Payment Enquiry',
}

export enum IssueTransactionType {
  DISPATCH = 'dispatch',
  ORDER = 'order',
  INVOICE = 'invoice',
  PAYMENT = 'payment',
  UNKNOWN = 'unknown',
}

export interface ChannelDetails {
  id: string;
  createdAt: string;
  updatedAt: string;
  channelName: string;
  linkedWorkspaceId: string;
  accessType: string;
  profilePic?: string;
  updatedBy: null;
  emojiId: string;
  description: string;
  ownerId?: string | null;
  isDefault?: boolean;
  appId: string;
  channelType: string;
  channelSubType?: string;
  customerId?: string;
  customerName?: string;
  companyName?: string;
  transactionType?: IssueTransactionType;
  channelRole?: string;
  workspaceId: string;
  folderId?: string;
  role?: ChannelMemberRole;
  customers?: ChannelCustomer[];
  teamMembers?: ChannelTeamMember[];
  companyTeamMembers?: CompanyTeamMembers[];
  latestMessage?: string;
  latestMessageTimeToken?: string;
  latestTransactionMessage?: string;
  type: string;
  isActive: boolean;
  latestWaMessageTimeToken?: string;
  status?: boolean;
  sellerWorkspaceId?: string;
  action?: string;
  unreadCount: number;
  isLockedCustomer?: boolean;
  customerInfo?: CustomerData;
  customerIsLoading?: boolean;
  totalMessageCount?: number;
  issueStatus?: string;
  phoneNumber?: string;
  threadTags?: ThreadTagListItem[];
  marketingMessageLimitExhausted?: boolean;
  optedOut?: boolean;
  customerCode?: string;
}

export interface ThreadTagListItem {
  text: string;
  color: string;
  backgroundColor: string;
  icon?: string;
}

export interface CustomerTeam {
  createdDate: string;
  employeeName: string | null;
  id: string;
  inviteType: string;
  invitedUserId: string;
  invitedWorkspaceId: string;
  isActive: number;
  isArchive: number;
  phone: string;
  phone2: string | null;
  role: string;
  senderUserId: string;
  senderWorkspaceId: string;
  status: string;
  updatedDate: string;
  userType: string | null;
}

export interface ChannelCustomer {
  name?: string;
  inviteId: string;
  distributorCode?: string;
  companyName?: string;
  stateName?: string;
  cityName?: null;
  phone?: string;
  employeeName?: string;
  team?: CustomerTeam[];
}

export interface ChannelTeamMember {
  teamMemberRole?: any;
  inviteId: string;
  employeeCode?: string;
  employeeName?: string;
  cityName?: null | string;
  stateName?: null | string;
  isActive?: boolean;
  mobile?: string;
  role?: 'MEMBER' | 'ADMIN' | 'CUSTOMERADMIN' | any;
  userId?: string;
  phone?: string;
  issueRole?: IssueRole;
  companyName?: string;
}

export interface CompanyTeamMembers {
  cityName: string;
  companyName: string;
  employeeCode: string;
  employeeName: string;
  fulfillmentLocationId: string;
  inviteId: string;
  stateName: string;
  role?: string;
}

export interface MoveChannelRequest {
  workspaceId: string;
  sellerWorkspaceId: string;
  body: {
    channelIds: string[];
    folderId: string;
  };
}

export interface LeaveChannelRequest {
  workspaceId: string;
  channelId: string;
  inviteId: string;
  principalWorkspaceId?: string;
  sellerWorkspaceId: string;
}

export interface DeleteChannelV2Request {
  type?: string;
  workspaceId: string;
  channelId: string;
  inviteId: string;
  sellerWorkspaceId: string;
}

export interface GetWhatsappTemplateRequest {
  sellerWorkspaceId: string;
  name: string;
}
interface Card {
  components: {
    type: string;
    text: string;
    example?: {
      body_text: string[][];
      header_handle: string[];
    };
  }[];
}

export interface TemplateComponent {
  format: string;
  type: string;
  text: string;
  example?: {
    body_text: string[][];
    header_handle?: string[];
  };
  url?: string;
  cards?: { components: TemplateComponent[] }[];
  buttons?: TemplateComponent[];
}

export interface GetWhatsappTemplateResponse {
  code: number;
  error_data: any;
  status: string;
  data: WhatsappTemplateData;
}

export interface WhatsappTemplateData {
  name: string;
  components: TemplateComponent[];
  language: string;
  status: string;
  category: string;
  id: string;
}

export interface LogZoAIActionsRequest {
  body: LogZoAIActionsRequestBody;
  userId: string;
}

interface LogZoAIActionsRequestBody {
  action: 'start' | 'end' | 'accept';
  entityName: 'order' | 'invoice';
  entityIds?: number[] | string[];
}

export interface PublishMessagePayload {
  sellerWorkspaceId: string;
  channelId?: string;
  inviteIds?: string[];
  message: MessagePayload;
}

export interface PreviewWhatsAppSyncRequest {
  sellerWorkspaceId: string;
}
export interface PreviewWhatsAppSyncResponse {
  groups: PreviewWhatsAppSyncGroup[];
}

export interface PreviewWhatsAppSyncGroup {
  name: string;
  isNew: boolean;
  customers: {
    name: string;
    mobileNumber: string;
  }[];
}

export interface PreviewWhatsAppSyncCustomer {
  title: string;
  mobileNumber: string;
  status: string;
  type?: string;
  subTitle?: string;
}

export interface CheckWhatsAppSyncStatusRequest {
  groupName: string;
  sellerWorkspaceId: string;
  members?: PreviewWhatsAppSyncMember[];
  sellerPhoneNumber?: string;
  sellerName?: string;
}
export interface CheckWhatsAppSyncStatusResponse {
  groupName: string;
  sellerWorkspaceId: string;
  members: CheckWhatsAppSyncStatusResponseMember[];
}

export interface CheckWhatsAppSyncStatusResponseMember {
  mobile: string;
  status: string;
  type?: string;
  companyName?: string;
  employeeName?: string;
  name?: string;
}

export interface PreviewWhatsAppSyncMember {
  mobile: string;
  name: string;
}

export enum ChannelTypeEnum {
  APP = 'app',
  PRINCIPAL = 'principal',
  CUSTOMER = 'customer',
  THREAD = 'TH',
  CUSTOMERTHREAD = 'THC',
  ISSUETHREAD = 'THT',
  GROUPTHREAD = 'THG',
  ZOAI = 'zo.ai',
}

export enum RemoveThreadAction {
  MAKEADMIN = 'MakeAdmin',
  ADMIN = 'admin',
  CUSTOMERADMIN = 'customeradmin',
  MAIN = 'main',
  CUSTOMER = 'customer',
  TeamMEMBER = 'teamMember',
  MEMBER = 'member',
}

export interface ChannelThread {
  id: string;
  name: string;
}

export interface CommonThreadsResponse {
  channels: ChannelThread[];
}

export interface CommonThreadsRequest {
  customerInviteId?: string;
  sellerWorkspaceId: string;
  userInviteId: string;
  teamMemberInviteId?: string;
}

export interface ParseFileRequest {
  sellerWorkspaceId: string;
  body: FormData;
}

export interface SendCampaignRequest {
  body: any;
  sellerWorkspaceId: string;
}
export interface GenerateShortUrlRequest {
  body: { type: string; url: string };
  sellerWorkspaceId: string;
}

export interface SendCampaignResponse {}

export type PublishToBotRes = string | OrderIntentResponse | void;

export interface OrderIntentResponse {
  isParsed: boolean;
  poNumber: string;
  parsedFileName: any;
  originalFileName: any;
  status: string;
  dateTimeOfImport: string;
  importSource: OrderImportSourceEnum;
  parsedOutput: string;
  mappedOutput: string;
  channelId: number;
  userId: string;
  userType: string;
  customerId: string;
  waMessage: string;
  poDate: any;
  teamMemberId: any;
  fileType: any;
  erpCode: any;
  parsingStartTime: any;
  parsingEndTime: any;
  mappingStartTime: any;
  mappingEndTime: any;
  isMapped: any;
  freshdeskTicketId: any;
  ticketResolutionTime: any;
  id: string;
  createdDate: string;
  updatedDate: string;
  isArchived: boolean;
  isFileParsed: boolean;
  isMapOrder: boolean;
  totalLineItems: number;
  totalQuantity: any;
  discount: any;
  totalWithTax: any;
  total: any;
  notifyToBuyer: boolean;
}
