import React, { useContext } from 'react';
import { ThemeContext } from '@zonofi/shared';

interface getStatusProps {
  text: string;
  bgColor: string;
  fontColor: string;
  icon?: React.JSX.Element;
  customStyles?: React.CSSProperties;
}

export const GetStatus = (props: getStatusProps) => {
  const { text, bgColor, fontColor, icon } = props;

  const { theme } = useContext(ThemeContext);

  return (
    <div
      style={{
        ...theme.fontConfig.semiBold,
        width: 'fit-content',
        whiteSpace: 'nowrap',
        backgroundColor: `${bgColor}`,
        color: `${fontColor}`,
        fontSize: '12px',
        padding: '2px 4px',
        borderRadius: '4px',
        display: 'flex',
        alignItems: 'center',
        gap: '4px',
        ...(props?.customStyles ? props?.customStyles : {})
      }}
    >
      {icon && icon}
      {text}
    </div>
  );
};
