import { ChatActionSendPricesEditColumnKey, ColumnConfig } from '../../model';

export const chatActionSendPricesEditConfig: Record<
  ChatActionSendPricesEditColumnKey,
  ColumnConfig
> = {
  skuCode: {
    name: 'SKU Code',
    isHyperlink: true,
  },
  title: {
    name: 'Product Title',
    field: 'textWidOfferTooltip',
  },
  moq: {
    name: 'MOQ',
    alignment: 'flex-end',
  },
  margin: {
    name: 'Margin',
    alignment: 'flex-end',
  },
  mrp: {
    name: 'MRP',
    alignment: 'flex-end',
  },
  currentPrice: {
    name: 'Current Price',
    alignment: 'flex-end',
  },
  newPrice: {
    name: 'Sending Price',
    alignment: 'flex-end',
    field: 'component',
  },
  action: {
    name: '',
    field: 'component',
  },
};
