import { ColumnConfig, SupportUserManageAccessColumnKey } from '../model';

export const supportUserManageAccessConfig: Record<
  SupportUserManageAccessColumnKey,
  ColumnConfig
> = {
  access: {
    name: 'Access',
    field: 'avatarName',
  },
  role: {
    name: 'Role',
    field: 'NavigateLink',
  },
  expiryDate: {
    name: 'Expiry Date',
  },
  action: {
    name: '',
    field: 'component',
  },
};
