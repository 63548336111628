import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CustomerPaymentFilter,
  PaymentMode,
  SelectedTeamMemberForReceivePayments,
  EditedDetails,
  ReceivedPaymentsReponse,
} from './model';
import { RootState } from '../root';
import { FormValue } from '../helpers';

export const PAYMENT_FEATURE_KEY = 'paymentData';

export interface PaymentState {
  qrImage: string;
  paymentRequestId: string;
  paymentMode?: PaymentMode;
  amountPaid: number;
  paymentDate?: Date;
  manualPaymentFormValid: boolean;
  manualPaymentForm: FormValue[];
  bottomSheetModalState?: boolean;
  customerPaymentSearch: boolean;
  customerPaymentFilter: CustomerPaymentFilter;
  customerBottomSheetModalState?: boolean;
  customerFilter: CustomerPaymentFilter;
  receivePaymentData: ReceivedPaymentsReponse;
  receivePaymentsDetails: {
    selectedTeamMemberForReceivePayments: SelectedTeamMemberForReceivePayments;
    editedDetails?: EditedDetails;
  };
}

export const initialPaymentState: PaymentState = {
  qrImage: '',
  paymentRequestId: '',
  amountPaid: 0,
  paymentDate: new Date(),
  manualPaymentFormValid: false,
  manualPaymentForm: [],
  bottomSheetModalState: false,
  customerPaymentSearch: false,
  customerPaymentFilter: {
    customerName: [],
    customerCode: '',
    pendingAmount: {
      value: '',
      key: 'lt',
    },
    paymentId: '',
    // status: ['P', 'F', 'N'],
    status: [],
    includePendingPayments: false,
  },
  customerBottomSheetModalState: false,
  customerFilter: {
    customerName: [],
    routeName: [],
    customerCode: '',
    outstandingAmount: {
      value: '',
      key: 'lt',
    },
    InvoiceNumber: '',
  },
  receivePaymentData: {
    startRecord: 0,
    endRecord: 0,
    total: 0,
    records: [],
    summary: {
      totalAmountReceived: 0,
      totalNoOfPayments: 0,
      totalAmountByCash: 0,
      totalAmountByCheque: 0,
      totalAmountByOthers: 0,
    },
    statusCode: 0,
    responseStatus: '',
  },
  receivePaymentsDetails: {
    selectedTeamMemberForReceivePayments: {
      teamMemberId: '',
      teamMemberName: '',
      selectedReceiveDate: '',
      cashierId: '',
      cashierName: '',
    },
    editedDetails: undefined,
  },
};

export const paymentSlice = createSlice({
  name: PAYMENT_FEATURE_KEY,
  initialState: initialPaymentState,
  reducers: {
    addQRImage(state, action: PayloadAction<string>) {
      state.qrImage = action.payload;
    },
    updatePaymentRequestId(state, action: PayloadAction<string>) {
      state.paymentRequestId = action.payload;
    },
    updatePaymentMode(state, action: PayloadAction<PaymentMode>) {
      state.paymentMode = action.payload;
    },
    updatePaymentDate(state, action: PayloadAction<Date>) {
      state.paymentDate = action.payload;
    },
    updateFormValid(state, action: PayloadAction<boolean>) {
      state.manualPaymentFormValid = action.payload;
    },
    updateManualPaymentForm(state, action: PayloadAction<FormValue[]>) {
      state.manualPaymentForm = action.payload;
    },
    toggleBottomSheetModal(state, action: PayloadAction<boolean>) {
      state.bottomSheetModalState = action.payload;
    },
    setCustomerPaymentSearch(state, action: PayloadAction<boolean>) {
      state.customerPaymentSearch = action.payload;
    },
    customerPaymentFilterList(
      state,
      action: PayloadAction<CustomerPaymentFilter>
    ) {
      state.customerPaymentFilter = {
        ...action.payload,
        status: action.payload.includePendingPayments
          ? [...(action.payload.status ?? []), 'C']
          : action.payload.status,
      };
    },
    toggleCustomerBottomSheetModal(state, action: PayloadAction<boolean>) {
      state.customerBottomSheetModalState = action.payload;
    },
    customerFilterList(state, action: PayloadAction<CustomerPaymentFilter>) {
      state.customerFilter = action.payload;
    },
    updateReceivedPaymentsData(
      state,
      action: PayloadAction<ReceivedPaymentsReponse>
    ) {
      state.receivePaymentData = action.payload;
    },
    updateSelectedTeamMemberForReceivePayments(
      state,
      action: PayloadAction<SelectedTeamMemberForReceivePayments>
    ) {
      state.receivePaymentsDetails.selectedTeamMemberForReceivePayments =
        action.payload;
    },
    updatePaymentSelectionById(state, action) {
      const index = state.receivePaymentData.records.findIndex(
        (x) => x.id === action.payload?.id
      );
      state.receivePaymentData.records[index].isSelected =
        action.payload.selected;
    },
    updatePaymentNarrationById(state, action) {
      const index = state.receivePaymentData.records.findIndex(
        (x) => x.id === action.payload?.id
      );
      state.receivePaymentData.records[index].narration = action.payload.value;
    },
    updatePaymentSelectionForAll(state, action) {
      const data = state.receivePaymentData.records.map((payment) => {
        return {
          ...payment,
          isSelected: action.payload?.isAllSelected,
        };
      });
      state.receivePaymentData.records = data;
    },
  },
});

export const paymentReducer = paymentSlice.reducer;

export const {
  addQRImage,
  updatePaymentRequestId,
  updatePaymentMode,
  updatePaymentDate,
  updateFormValid,
  updateManualPaymentForm,
  toggleBottomSheetModal,
  setCustomerPaymentSearch,
  customerPaymentFilterList,
  toggleCustomerBottomSheetModal,
  customerFilterList,
  updateReceivedPaymentsData,
  updateSelectedTeamMemberForReceivePayments,
  updatePaymentNarrationById,
  updatePaymentSelectionById,
  updatePaymentSelectionForAll,
} = paymentSlice.actions;

export const getPaymentSliceState = (rootState: RootState): PaymentState =>
  rootState[PAYMENT_FEATURE_KEY];

export const getQRCodeBase64 = createSelector(
  getPaymentSliceState,
  (state) => state.qrImage
);

export const getPaymentRequestId = createSelector(
  getPaymentSliceState,
  (state) => state.paymentRequestId
);

export const getPaymentMode = createSelector(
  getPaymentSliceState,
  (state) => state.paymentMode
);
export const getPaymentDate = createSelector(
  getPaymentSliceState,
  (state) => state.paymentDate
);

export const getManualFormValid = createSelector(
  getPaymentSliceState,
  (state) => state.manualPaymentFormValid
);

export const getManualPaymentForm = createSelector(
  getPaymentSliceState,
  (state) => state.manualPaymentForm
);

export const getBottomSheetModalState = createSelector(
  getPaymentSliceState,
  (state) => state.bottomSheetModalState
);

export const getCustomerPaymentSearch = createSelector(
  getPaymentSliceState,
  (state) => state.customerPaymentSearch
);

export const getCustomerNameFilterList = createSelector(
  getPaymentSliceState,
  (state) => state.customerPaymentFilter
);

export const getCustomerFilterList = createSelector(
  getPaymentSliceState,
  (state) => state.customerFilter
);

export const getCustomerBottomSheetModalState = createSelector(
  getPaymentSliceState,
  (state) => state.customerBottomSheetModalState
);

export const getReceivedPayments = createSelector(
  getPaymentSliceState,
  (state) => state.receivePaymentData
);

export const getReceivedPaymentsDetails = createSelector(
  getPaymentSliceState,
  (state) => state.receivePaymentsDetails
);
