import _ from 'lodash';
import { Api } from '../api';
import {
  addListInputItems,
  ListInputItemOptions,
  mapDataToInputItems,
  SiftScreenEnum,
  updateSiftByScreen,
} from '../filter';
import {
  CreateCampaignRequest,
  CreateCampaignResponse,
  CampaignListRequest,
  CampaignListResponse,
  CampaignRequest,
  CampaignResponse,
  UpdateCampaignRequest,
  SendChannelCampaignResponse,
  SendChannelCampaignRequest,
  CampaignDetailsResponse,
  CampaignDetailsRequest,
  CampaignCustomersResponse,
  CampaignCustomersRequest,
  CampaignProductsResponse,
  CampaignProductsRequest,
  RemoveProductFromCampaigntResponse,
  RemoveProductFromCampaignRequest,
  RemoveCustomerFromCampaigntResponse,
  RemoveCustomerProductFromCampaignRequest,
  CampaignDetailsV2Response,
  CampaignDetailsV2Request,
  CampaignFiltersResponse,
  CampaignFiltersRequest,
  PublishCampaignResponse,
  PublishCampaignRequest,
} from './model';

export const campaginsApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    createCampaign: builder.mutation<
      CreateCampaignResponse,
      CreateCampaignRequest
    >({
      query({ workspaceId, sellerWorkspaceId, body }) {
        return {
          url: `hub/commerce-v2/campaigns/${workspaceId}`,
          method: 'POST',
          params: { sellerWorkspaceId },
          body: body,
        };
      },
      async onQueryStarted(request, { dispatch, queryFulfilled }) {
        queryFulfilled.then((response) => {
          const statusArray = [
            { id: '0', name: 'Published' },
            { id: '1', name: 'Not Published' },
          ];
          const statusList = mapDataToInputItems(
            statusArray,
            'id',
            'name'
          ).filter((x: any) => x.value);

          dispatch(
            addListInputItems({
              inputItems: statusList,
              key: ListInputItemOptions.campaignStatus,
            })
          );
        });
      },
      transformResponse: (
        createResponse: CreateCampaignResponse,
        meta,
        arg
      ) => {
        const startDate = arg?.body.get('startDate');

        return {
          ...createResponse,
          isScheduled: !_.isNull(startDate) ? true : false,
        };
      },
    }),
    getAllCampaigns: builder.mutation<
      CampaignListResponse,
      CampaignListRequest
    >({
      query({ workspaceId, sellerWorkspaceId, body, params }) {
        return {
          url: `hub/commerce-v2/campaigns/list/${workspaceId}`,
          method: 'POST',
          params: { ...params, sellerWorkspaceId, random: Math.random() },
          body,
        };
      },
    }),
    getCampaignById: builder.query<CampaignResponse, CampaignRequest>({
      query({ workspaceId, campaignId, sellerWorkspaceId, customerId }) {
        return {
          url: `hub/commerce-v2/campaigns/${workspaceId}/${campaignId}`,
          method: 'GET',
          params: { sellerWorkspaceId, customerId, random: Math.random() },
        };
      },
    }),
    updateCampaign: builder.mutation<CampaignResponse, UpdateCampaignRequest>({
      query({ workspaceId, campaignId, sellerWorkspaceId, body }) {
        return {
          url: `hub/commerce-v2/campaigns/${workspaceId}/${campaignId}`,
          method: 'PUT',
          params: { sellerWorkspaceId },
          body: body,
        };
      },
    }),
    sendChannelCampaign: builder.mutation<
      SendChannelCampaignResponse,
      SendChannelCampaignRequest
    >({
      query(props) {
        return {
          url: `hub/communication-v2/api/communicate/send`,
          method: 'POST',
          body: props,
        };
      },
    }),
    getCampaignDetails: builder.query<
      CampaignDetailsResponse,
      CampaignDetailsRequest
    >({
      query(args) {
        return {
          url: `/hub/commerce-v2/campaigns/details/${args?.workspaceId}`,
          method: 'GET',
          params: { ...args, random: Math.random() },
        };
      },
    }),
    getCampaignCustomers: builder.query<
      CampaignCustomersResponse,
      CampaignCustomersRequest
    >({
      query({ workspaceId, ...params }) {
        return {
          url: `/hub/commerce-v2/campaigns/customers/${workspaceId}`,
          method: 'GET',
          params: { ...params, random: Math.random() },
        };
      },
    }),

    getCampaignProducts: builder.mutation<
      CampaignProductsResponse,
      CampaignProductsRequest
    >({
      query({ workspaceId, sellerWorkspaceId, ...body }) {
        return {
          url: `/hub/commerce-v2/campaigns/products/${workspaceId}`,
          method: 'POST',
          params: { sellerWorkspaceId },
          body: body,
        };
      },
    }),
    getCampaignDetailsV2: builder.query<
      CampaignDetailsV2Response,
      CampaignDetailsV2Request
    >({
      query: (args) => {
        return {
          url: `hub/commerce-v2/campaigns/details/customer/${args?.workspaceId}`,
          params: { ...args?.params, random: Math.random() },
          method: 'GET',
        };
      },
    }),
    removeProductFromCampaign: builder.mutation<
      RemoveProductFromCampaigntResponse,
      RemoveProductFromCampaignRequest
    >({
      query({ productVariantIds, ...params }) {
        return {
          url: `/hub/commerce-v2/campaigns/products`,
          method: 'DELETE',
          params: params,
          body: { productVariantIds },
        };
      },
    }),

    removeCustomerFromCampaign: builder.mutation<
      RemoveCustomerFromCampaigntResponse,
      RemoveCustomerProductFromCampaignRequest
    >({
      query({ customerIds, ...params }) {
        return {
          url: `/hub/commerce-v2/campaigns/customers`,
          method: 'DELETE',
          params: { ...params, random: Math.random() },
          body: { customerIds },
        };
      },
    }),

    publishCampaign: builder.mutation<
      PublishCampaignResponse,
      PublishCampaignRequest
    >({
      query({ workspaceId, sellerWorkspaceId, body }) {
        return {
          url: `/hub/commerce-v2/campaigns/publish/${workspaceId}`,
          method: 'POST',
          params: { sellerWorkspaceId },
          body: body,
        };
      },
    }),

    getCampaignFilters: builder.query<
      CampaignFiltersResponse,
      CampaignFiltersRequest
    >({
      query({ workspaceId, ...params }) {
        return {
          url: `/hub/commerce-v2/campaigns/filter/${workspaceId}`,
          method: 'GET',
          params: params,
        };
      },
      async onQueryStarted(request, { dispatch, queryFulfilled }) {
        queryFulfilled.then((response) => {
          const statusArray = response?.data?.status?.map((status) => ({
            id: status,
            name: status,
            eitherOr: true,
            selected: false,
          }));
          const imagePreviewArray = response?.data?.imagePreview?.map(
            (imagePreview) => ({
              id: imagePreview,
              name: imagePreview === 'LIST' ? 'One Frame' : 'Carousel',
              eitherOr: true,
              selected: false,
            })
          );

          const typeArray = response?.data?.type?.map((type) => ({
            id: type,
            name: type,
            eitherOr: true,
            selected: false,
          }));

          const statusList = mapDataToInputItems(
            statusArray,
            'id',
            'name',
            'false'
          ).filter((x: any) => x.value);
          dispatch(
            addListInputItems({
              inputItems: statusList,
              key: ListInputItemOptions.promotionsbyStatus,
            })
          );

          const imagePreviewList = mapDataToInputItems(
            imagePreviewArray,
            'id',
            'name',
            'false'
          ).filter((x: any) => x.value);
          dispatch(
            addListInputItems({
              inputItems: imagePreviewList,
              key: ListInputItemOptions.promotionsbyMode,
            })
          );

          const typeList = mapDataToInputItems(
            typeArray,
            'id',
            'name',
            'false'
          ).filter((x: any) => x.value);
          dispatch(
            addListInputItems({
              inputItems: typeList,
              key: ListInputItemOptions.promotionsbyType,
            })
          );

          dispatch(
            updateSiftByScreen({
              screen: SiftScreenEnum.CAMPAIGN_LIST_SCREEN,
              filters: {
                campaignStatus: statusList,
                imagePreview: imagePreviewList,
                type: typeList,
              },
            })
          );
          dispatch(
            updateSiftByScreen({
              screen: SiftScreenEnum.SEND_CAMPAIGN_SCREEN,
              filters: {
                campaignStatus: statusList,
                imagePreview: imagePreviewList,
                type: typeList,
              },
            })
          );
        });
      },
    }),
  }),
});

export const {
  useCreateCampaignMutation,
  useGetAllCampaignsMutation,
  // useLazyGetAllCampaignsQuery,
  useLazyGetCampaignByIdQuery,
  useUpdateCampaignMutation,
  useSendChannelCampaignMutation,
  useLazyGetCampaignDetailsQuery,
  useLazyGetCampaignCustomersQuery,
  useLazyGetCampaignDetailsV2Query,
  useRemoveCustomerFromCampaignMutation,
  useRemoveProductFromCampaignMutation,
  useGetCampaignProductsMutation,
  useLazyGetCampaignFiltersQuery,
  usePublishCampaignMutation,
} = campaginsApi;
