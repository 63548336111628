import { ThemeContext } from '../contexts/ThemeContext';
import React, { useContext } from 'react';
import { DimensionValue, View } from 'react-native';

/* eslint-disable-next-line */
export interface CustomFooterProps {
  left: JSX.Element;
  right: JSX.Element;
  top?: DimensionValue;
  width?: DimensionValue;
  customStyles?: React.CSSProperties;
  footerWidth?: string;
  marginLeft?: string;
  marginRight?: string;
  position?: string;
}

export const CustomFooter: React.FC<CustomFooterProps> = ({
  left,
  right,
  top,
  width,
  customStyles,
  footerWidth,
  marginLeft,
  marginRight,
  position
}) => {
  const currentTheme = useContext(ThemeContext);
  const { theme } = currentTheme;
  return (
    <View
      //@ts-ignore
      style={{
        zIndex: 999999,
        top,
       ...( position ?  {position} : {position: 'absolute'}),
        bottom: 8,
        width: width ? width : '100%',
        justifyContent: 'center',
        alignItems: 'center',
        ...customStyles,
      }}
    >
      <View
        //@ts-ignore
        style={{
          width: footerWidth ?? '98%',
          height: 55,
          borderRadius: 4,
          backgroundColor: theme.feed.background,
          justifyContent: 'space-between',
          paddingHorizontal: 10,
          flexDirection: 'row',
          alignItems: 'center',
          borderColor: '#D9D9D9',
          borderWidth: 1,
          shadowColor: '#000000',
          shadowOffset: {
            width: 0,
            height: -2,
          },
          shadowOpacity: 0.1,
          shadowRadius: 4,
          elevation: 2,
          marginLeft: marginLeft ?? 'auto',
          marginRight: marginRight ?? 'auto',
        }}
      >
        {left}
        {right}
      </View>
    </View>
  );
};

export default CustomFooter;
