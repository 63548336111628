import { ColumnConfig, LedgerOutstandingKey } from '../model';

export const ledgerOutstandingConfig: Record<
  LedgerOutstandingKey,
  ColumnConfig
> = {
  documentNumber: {
    name: 'Document Number',
    isComponent: true,
    headerExtra: {
      type: 'info',
      details: 'Ledger transaction Number',
      title: 'DOCUMENT TYPE',
    },
  },
  date: {
    name: 'Date',
    sortField: 'date',
  },
  division: {
    name: 'Division',
    headerExtra: {
      type: 'info',
      details: '',
      title: '  Division',
    },
  },
  documentType: {
    name: 'Document Type',
    headerExtra: {
      type: 'info',
      details: 'Ledger transaction Type',
      title: 'DOCUMENT TYPE',
    },
  },
  credited: {
    name: 'Credited',
    alignment: 'flex-end',
    headerExtra: {
      type: 'info',
      details: 'Credited amount: Invoice & Debit Note Transaction',
      title: 'CREDITED',
    },
    sortField: 'credited',
  },
  balance: {
    name: 'Balance',
    alignment: 'flex-end',
    headerExtra: {
      type: 'info',
      details: 'Remaining amount to pay',
      title: 'BALANCE',
    },
    sortField: 'balance',
  },
  referenceNumber: {
    name: 'Reference Number',
  },
  clearingDate: {
    name: 'Clearing Date',
  },
};
