import { Api } from '../api/root-api';
import { updateUser } from '../auth';
import { store } from '../root';
import { workspaceApi } from '../workspace';
import {
  PrincipalInvite,
  PrincipalInviteResponse,
  TeamInvite,
  TeamInviteResponse,
  AcceptInviteResponse,
  CustomerInviteResponse,
  PrincipalInviteObject,
  CustomerInviteRequest,
  InvitePrincipalDetails,
  MergeWorkspacesByGSTRequest,
  AcceptInviteRequest,
} from './model';
import { updatePrincipalInvite } from './slice';

export const inviteApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    getTeamInvite: builder.query<TeamInvite[], void>({
      query() {
        return {
          url: `teamInvite`,
          method: 'GET',
        };
      },
      transformResponse: (response: TeamInviteResponse) => {
        return response.teamInvites;
      },
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          let user = store.getState().userAuth.userData;
          user = { ...user, teamInviteId: data[0].id };
          dispatch(updateUser(user));
        } catch (err) {
          console.log(err, 'dispatchErr');
        }
      },
    }),
    getPrincipalInvite: builder.query<PrincipalInvite[], void>({
      query() {
        return { url: `principalinvite`, method: 'GET' };
      },
      transformResponse: (response: PrincipalInviteResponse) => {
        return response.principalInvites;
      },
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          const getProfilePic = async (principal: PrincipalInvite) => {
            try {
              const res = await dispatch(
                workspaceApi.endpoints.getDocuments.initiate({
                  workspaceId: principal?.senderWorkspaceId,
                  sequenceNo: '1',
                  subType: 'passport_photo',
                  entityId: principal?.senderWorkspaceId,
                  documentType: 'customer',
                })
              );
              const updatedPrincipal: PrincipalInvite = {
                ...principal,
                profilePic: res.data?.[0]?.url || '',
              };
              return updatedPrincipal;
            } catch (error) {
              console.log(error);
              return principal;
            }
          };

          const updatedInvites = await Promise.all(data.map(getProfilePic));
          dispatch(updatePrincipalInvite(updatedInvites));
        } catch (err) {
          console.log(err, 'dispatchErr');
        }
      },
    }),
    acceptTeamInvite: builder.mutation<AcceptInviteResponse, string>({
      query(teamMemberId) {
        return {
          url: `teaminvite/accept/${teamMemberId}`,
          method: 'PUT',
        };
      },
    }),
    acceptPrincipalInvite: builder.mutation<
      AcceptInviteResponse,
      AcceptInviteRequest
    >({
      query(params) {
        return {
          url: `hub/orgs/api/customer/accept-invite/${params.inviteId}`,
          method: 'PUT',
          params: { sellerWorkspaceId: params.workspaceId },
          body: {
            invitedWorkspaceId: params.workspaceId,
            phoneNumber: params.phoneNumber,
          },
        };
      },
    }),
    getCustomerInviteDetails: builder.query<
      CustomerInviteResponse,
      CustomerInviteRequest
    >({
      query: (arg) => {
        const { inviteId, ...params } = arg;
        return {
          url: `invite/${inviteId}`,
          method: 'GET',
          params: params,
        };
      },
    }),
    sendPrincipalInvite: builder.mutation<PrincipalInviteObject, any>({
      query(arg) {
        return {
          url: 'send/principalinvites',
          method: 'POST',
          body: arg,
        };
      },
      transformResponse: (data: any) => {
        return {
          address: null,
          businessPAN: data.customerDetails.businessPAN,
          companyName: data.customerDetails.companyName,
          createdDate: data.customerDetails.createdDate,
          customerGroups: data.customerGroup,
          distributorCode: data.customerDetails.distributorCode,
          gstin: data.customerDetails.gstin,
          physicalAddress: JSON.stringify(data.customerDetails.physicalAddress),
          postalCode: data.customerDetails.postalCode,
          profilePic: null,
          senderUserId: data.senderUserId,
          senderWorkspaceId: data.senderWorkspaceId,
          spaceName: null,
          status: data.status,
          success: data.success,
          updatedDate: data.customerDetails.updatedDate,
          workspaceMembersCount: 0,
          name: data.customerDetails.name,
          phone: data.phone,
          id: data.customerDetails.inviteId,
          stateId: data.customerDetails.stateId,
          countryId: data.customerDetails.countryId,
          email: data.customerDetails.email,
          cityName: data.customerDetails.cityName,
        };
      },
    }),
    reInviteCustomer: builder.mutation<any, any>({
      query(arg) {
        return {
          url: 'customers/reinvite',
          method: 'POST',
          body: arg,
        };
      },
    }),
    inviteUploadedCustomer: builder.mutation<any, string>({
      query(arg) {
        return {
          url: `customers/${arg}`,
          method: 'PUT',
          body: { status: 'P' },
        };
      },
    }),
    getInviteByToken: builder.query<CustomerInviteResponse, string>({
      query(arg) {
        return {
          url: 'principalinvite/token',
          method: 'GET',
          params: { token: arg },
        };
      },
    }),
    getInviteById: builder.query<CustomerInviteResponse, { inviteId: string }>({
      query(arg) {
        return {
          url: `principal/invite/${arg?.inviteId}`,
          method: 'GET',
        };
      },
    }),
    getInviteGSTDetails: builder.query<
      InvitePrincipalDetails[],
      {
        gstNumber: string;
      }
    >({
      query(arg) {
        return {
          url: 'invite/gst/' + arg?.gstNumber,
          method: 'GET',
        };
      },
    }),
    mergeWorkspacesByGST: builder.mutation<any, MergeWorkspacesByGSTRequest>({
      query(args) {
        return {
          url: 'invite/update/gst',
          method: 'POST',
          body: args,
        };
      },
    }),
  }),
});

export const {
  useLazyGetTeamInviteQuery,
  useLazyGetPrincipalInviteQuery,
  useAcceptTeamInviteMutation,
  useAcceptPrincipalInviteMutation,
  useLazyGetCustomerInviteDetailsQuery,
  useSendPrincipalInviteMutation,
  useReInviteCustomerMutation,
  useInviteUploadedCustomerMutation,
  useLazyGetInviteByTokenQuery,
  useLazyGetInviteGSTDetailsQuery,
  useMergeWorkspacesByGSTMutation,
  useLazyGetInviteByIdQuery,
} = inviteApi;
