import { ColumnConfig, PaymentReportsColumnKey } from '../../model';

export const paymentReportConfig: Record<
  PaymentReportsColumnKey,
  ColumnConfig
> = {
  sNo: {
    name: 'S.No',
  },
  trasactionDate: {
    name: 'Trasaction Date',
    sortField: 'paymentDate',
  },
  referenceNumber: {
    name: 'Reference Number',
  },
  bankTransactionID: {
    name: 'Bank Transaction ID',
    sortField: 'bankTransactionId',
  },
  paymentMode: {
    name: 'Payment Mode',
  },
  transactionType: {
    name: 'Transaction Type',
  },
  transactionValue: {
    name: 'Transaction Value',
    alignment: 'flex-end',
  },
  paymentStatus: {
    name: 'Payment Status',
  },
  collectedBy: {
    name: 'Collected By',
  },
  customerName: {
    name: 'Customer Name',
  },
  invoiceNumber: {
    name: 'Invoice Number',
  },
  // orderNumber: {
  //   name: 'Order Number',
  // },
};
