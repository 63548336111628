import { SiftScreenEnum } from '../filter';
import { InvoiceStatusEnum, OrderStatusEnum } from '../helpers';

export enum BusinessMetricsEnum {
  TOTAL_REVENUE = 'totalRevenue',
  NO_OF_ORDERS_CONFIRMED = 'noOfOrdersConfirmed',
  NO_OF_INVOICES_SENT = 'noOfInvoicesSent',
  TOTAL_OUTSTANDING = 'totalOutstanding',
  TOTAL_PAYMENTS_RECEIVED = 'totalPaymentsReceived',
}

export enum SegmentAnalysisEnum {
  CATEGORY = 'category',
  PRODUCT = 'product',
  STATE = 'state',
  DISTRICT = 'district',
  PINCODE = 'pincode',
  CUSTOMER = 'customer',
}

export enum SegmentDimension {
  CATEGORY = 'Category',
  STATE = 'State',
  CUSTOMER = 'Customer',
}

export enum Metric {
  VALUE = 'value',
  VOLUME = 'volume',
}

export enum DashboardFilterEnum {
  CATEGORY = 'category',
  SKU = 'sku',
  STATE = 'state',
  DISTRICT = 'district',
  PINCODE = 'pincode',
}

export enum DashboardFilterTypeEnum {
  BUSINESS_SUMMARY = 'BS',
  ZOAI_COCKPIT = 'ZC',
  SEGMENET_ANALYSIS = 'SA',
}

export enum DashboardAppliedFiltersEnum {
  STATE = 'state',
  DISTRICT = 'district',
  PINCODE = 'pincode',
  CATEGORY = 'category',
  PRODUCT = 'product',
}

export interface DashboardAppliedFilter {
  name: DashboardAppliedFiltersEnum;
  options: { id: string; name: string }[];
}

export interface ReportsLoginResponse {
  status: boolean;
  message: string;
  access_token: string;
  token_type: string;
}

export interface OtifAbsoluteOrdersDetailResponse {
  status: boolean;
  message: string;
  data: OtifAbsoluteOrdersDetailData;
}

export interface OtifAbsoluteOrdersDetailData {
  otif_absolute_orders_details: OtifAbsoluteOrdersDetail[];
}

export interface OtifRecivedOrdersDetail {
  order_date: string;
  order_week: number;
  order_month: number;
  order_year: number;
  order_quantity: number;
  order_value: number;
}

export interface OtifReceivedOrdersDetailData {
  groupby_flag: string;
  otif_received_orders_details: OtifRecivedOrdersDetail[];
}

export interface OtifReceviedOrdersDetailResponse {
  status: boolean;
  message: string;
  data: OtifReceivedOrdersDetailData;
}

export interface OtifAbsoluteOrdersDetail {
  cart_quantity: number;
  cart_value: number;
  order_quantity: number;
  order_value: number;
  invoice_quantity: number;
  invoice_value: number;
  qty_shipped_within_tat: number;
  value_shipped_within_tat: number;
}

export interface OtifDetailsRequest {
  from_order_date?: string;
  to_order_date?: string;
  company_id?: string;
  groupby_flag?: string;
  company_name: string;
  cfa_id?: string[];
  cfa_name?: string[];
  distributor_id?: string[];
  distributor_name?: string[];
  division_id?: string[];
  division_name?: string[];
  product_id?: string[];
  workspaceId: string;
}
interface OtifOrderFulfilmentDetail {
  fulfilment_rate: number;
  bounce_rate: number;
}

interface OtifOrderFulfilmentDetailData {
  otif_order_fulfilment_details: OtifOrderFulfilmentDetail[];
}

export interface OtifOrderFulfilmentDetailResponse {
  status: boolean;
  message: string;
  data: OtifOrderFulfilmentDetailData;
}

export interface DailyOrdersReportRequest {
  sellerWorkspaceId: string;
  startDate: string;
  endDate: string;
  cfaId?: string;
}

export interface DailyOrdersReportResponse {
  orderCount: number;
  data: DailyOrdersReportData[];
  percentageIncrease: number;
}

export interface DailyOrdersReportData {
  order_date_range: string;
  orderTotalValue: number;
}

export interface DailyPaymentsReportRequest {
  sellerWorkspaceId: string;
  startDate: string;
  endDate: string;
  cfaId?: string;
}

export interface DailyPaymentsReportResponse {
  paymentCount: number;
  data: DailyPaymentsReportData[];
  percentageIncrease: any;
}

export interface DailyPaymentsReportData {
  payment_date_range: string;
  paymentCount: number;
}

export interface DailyInvoicesReportRequest {
  sellerWorkspaceId: string;
  startDate: string;
  endDate: string;
  cfaId?: string;
}

export interface DailyInvoicesReportResponse {
  invoiceCount: number;
  data: DailyInvoicesReportData[];
  percentageIncrease: any;
}

export interface DailyInvoicesReportData {
  invoice_date_range: string;
  invoiceCount: number;
}

export interface CustomersByOrderValueRequest {
  sellerWorkspaceId: string;
  startDate: string;
  endDate: string;
  pageNo: number;
  pageSize: number;
  sortDirection?: string;
  cfaId?: string;

  searchKey?: string;
  filters?: {
    state?: [];
    district?: [];
    pinCode?: [];
    salesTeamMemberId?: [];
  };
  orderBy?: {
    column: string;
    order: string;
  }[];
}

export interface CustomerByOrderValuee {
  customerName: string;
  totalValue: string;
  totalOrders?: string;
  companyName: string;
  creditLimit?: string;
  totalInvoices?: string;
  totalInvoicesValue?: string;
  totalPendingInvoices?: string;
  totalPendingInvoicesValue?: string;
  totalOutstandingAmount?: string;
  state?: string;
  district?: any;
  pincode?: string;
}
export interface CustomersByOrderValueResponse {
  data: CustomerByOrderValuee[];
  updatedAt: string;
  totalNumberOfOrders: string;
  totalOrderValue: string;
  totalNumberOfInvoices: string;
  totalInvoiceValue: string;
  totalOutStanding: string;
  totalRecords: number;
  startRecord: number;
  endRecord: number;
  totalPendingInvoices: number;
  totalPendingInvoicesValue: number;
}

export interface ProductsByQuantitySoldRequest {
  sellerWorkspaceId: string;
  startDate: string;
  endDate: string;
  pageNo: number;
  pageSize: number;
  sortDirection: string;
  cfaId?: string;
  searchKey?: string;
  filters?: {
    category: string[];
    division: any[];
  };
  orderBy?: {
    column: string;
    order: string;
  }[];
}

export interface ProductsByQuantitySoldResponse {
  data: ProductsByQuantitySoldResponseData[];
  totalSkus: number;
  totalOrders: number;
  totalQuantitySold: number;
  totalCategories: number;
  totalDivisions: number;
  totalSalesValue: number;
  averageSellingPrice: number;
  totalRecords: number;
  startRecord: number;
  endRecord: number;
  updatedAt: string;
}

export interface ProductsByQuantitySoldResponseData {
  sku: string;
  sellerworkspaceid: string;
  productname: string;
  maximumprice: string;
  minimumprice: string;
  Category: string;
  Division: any;
  quantity: string;
  asp: string;
  totalOrders: string;
  totalValue: string;
}

export interface ReceivedOrdersReportRequest {
  sellerWorkspaceId: string;
  startDate: string;
  endDate: string;
  viewField: string;
  cfaId?: string;
}

export interface ReceivedOrdersReportResponse {
  order_date_range: string;
  cfaOrderCount: number;
  customerOrderCount: number;
  salesmenOrdersCount: number;
}

export interface RevenueReportRequest {
  sellerWorkspaceId: string;
  revenueDate: string;
  cfaId?: string;
}

export interface RevenueReportResponse {
  revenueFY: RevenueFy;
  revenueCurrentMonth: RevenueCurrentMonth;
  aovFY: AovFy;
  aovCurrentMonth: AovCurrentMonth;
  financialYear: string;
}

export interface RevenueFy {
  totalValue: number;
  percentIncrease: number;
}

export interface RevenueCurrentMonth {
  totalValue: number;
  percentIncrease: number;
}

export interface AovFy {
  avgTotalValue: number;
  percentIncrease: number;
}

export interface AovCurrentMonth {
  avgOrderValue: number;
  percentIncrease: number;
}

export interface SalesLeaderboardRequest {
  startDate: string;
  endDate: string;
  sellerWorkspaceId: string;
  cfaId?: string;
}

export interface SalesLeaderBoardResponse {
  data: SalesLeaderboardReport[];
  teamMember: TeamMember;
}

interface SalesLeaderboard {
  salesTeamMemberId: string;
  totalValue: string;
  position: number;
  name: string;
}

interface TeamMember {
  salesTeamMemberId: string;
  totalValue: string;
  position: number;
  name: string;
}

export interface RecentOrdersReportRequest {
  sellerWorkspaceId: string;
  cfaId?: string;
  startDate?: string;
  endDate?: string;
}

export interface RecentInvoicesReportRequest {
  sellerWorkspaceId: string;
  cfaId?: string;
}

export interface RecentPaymentsReportRequest {
  sellerWorkspaceId: string;
  cfaId?: string;
}

export interface RecentOrdersReportResponse {
  numproducts: string;
  customername: string;
  customerid: string;
  refordernumber: string;
  orderdate: string;
  orderid: string;
  totalvalue: string;
  sellerworkspaceid: string;
  status: OrderStatusEnum;
  customerFirmName: string;
}

export interface RecentInvoicesReportResponse {
  qty: string;
  customername: string;
  invoicedate: string;
  inviteid: string;
  docnumber: string;
  invoiceid: string;
  totalvalue: string;
  sellerworkspaceid: string;
  status: string;
}

export interface RecentPaymentsReportResponse {
  customername: string;
  paymentdate: string;
  paymentid: string;
  totalvalue: string;
  paymentcode: string;
  sellerworkspaceid: string;
  status: string;
}

export interface ZoAiReportRequest {
  dueDate: string;
  sellerWorkspaceId: string;
  cfaId?: string;
}

export interface ZoAiReportResponse {
  totalOrders: number;
  ordersPendingApproval: number;
  ordersToBeInvoiced: number;
  dueInvoices: number;
}

export interface CustomerCfaReportRequest {
  endDate: string;
  startDate: string;
  sellerWorkspaceId: string;
  isSeller: boolean;
  pageNumber?: number;
  sortBy?: string;
  sortDirection?: string;
  pageSize?: number;
  cfaIds?: string[];
  slot?: string[];
  filters?: {
    cfaIds?: string[];
    customerIds?: string[];
    slot?: string;
  };
  searchKey?: string;
  dispatchFilters?: boolean;
  orderBy?: {
    column?: string;
    order?: string;
  }[];
}

export interface DateSelectionRange {
  dateSelectionRange: {
    startDate: Date;
    endDate: Date;
  };
  setDateSelectionRange: React.Dispatch<
    React.SetStateAction<{
      startDate: Date;
      endDate: Date;
    }>
  >;
}

interface AirbyteMeta {
  changes: any[];
}

export interface CustomerCfaReport {
  sku: string;
  inviteid: string;
  totalqty: string;
  orderdate: string;
  created_at: string;
  customerid: string;
  slot: string;
  totalvalue: string;
  updated_at: string;
  productname: string;
  totalorders: string;
  customerfirmname: string;
  sellerworkspaceid: string;
  sellerworkspacename: string;
  cfaid: string;
  cfaname: string;
  _airbyte_raw_id: string;
  _airbyte_extracted_at: string;
  _airbyte_meta: AirbyteMeta;
}

interface Fulfillments {
  cfaIds: string;
  cfaName: string;
}

interface Customers {
  customerFirmName: string;
  customerId: string;
}

export interface CustomerCfaReportResponse {
  data: CustomerCfaReport[];
  customers: Customers[];
  fulfillments: Fulfillments[];
  endRecord: number;
  reportId: string;
  percentOrderCountIncrease: number;
  percentCfaIncrease: number;
  percentSkuIncrease: number;
  percentTotalOrderValueIncrease: number;
  percentTotalQuantityIncrease: number;
  totalCustomers: number;
  percentCustomersIncrease: number;
  startRecord: number;
  totalOrderCount: number;
  totalOrderValue: number;
  updatedAt: string;
  totalQuantity: number;
  totalRecords: number;
  totalSku: number;
  totalCfas: number;
}
export interface ReportDownloadRequest {
  endDate: string;
  startDate: string;
  sellerWorkspaceId: string;
  
  isSeller?: boolean;

  filters?: {
    inviteIds?: string[];
    cfaIds?: string[];
  };
}

export interface IOrderBy {
  column: string;
  order: string;
}

export interface cfaCustomerWiseProductReportRequest {
  sellerWorkspaceId?: string;
  startDate: string;
  endDate: string;
  inviteIds?: string[];
  cfaIds?: string[];
  customerId?: string;
  pageNumber?: number;
  pageSize?: number;
  isSeller?: boolean;
  type?: string;
  orderBy?: IOrderBy[];
  heading?: string;
  format?: string;
  skus?: string[];
  dispatchFilters?: boolean;
}

export interface cfaCustomerWiseProductReport {
  sku: string;
  cfaid: string;
  cfaname: string;
  orderids: string;
  totalqty: string;
  orderdate: string;
  created_at: string;
  totalvalue: string;
  updated_at: string;
  productname: string;
  sellergstin: string;
  sellerphone: string;
  totalorders: string;
  sellerworkspaceid: string;
  sellerworkspacename: string;
  refordernumbers: string[];
  _airbyte_raw_id: string;
  _airbyte_extracted_at: string;
  _airbyte_meta: {
    changes: string[];
  };
}

export interface cfaCustomerWiseProductReportResponse {
  data: cfaCustomerWiseProductReport[];
  totalSku: number;
  totalOrderCount: number;
  totalOrderValue: number;
  totalQuantity: number;
  isSeller: boolean;
  sellerDetails: {
    workspacename: string;
    gstin: null;
    phone: string;
  };
  updatedAt: string;
  startRecord: number;
  endRecord: number;
  totalRecords: number;
  totalCustomers: number;
}

export interface LedgerSummaryReportsRequest {
  inviteIds: string[];
  sellerWorkspaceId: string;
  pageNo: number;
  pageSize: number;
}

export interface Records {
  sellerworkspaceid: string;
  inviteId: string;
  outstandingAmt: string;
  creditLimit: string;
  overdueAmt: string;
  availableLimit: string;
  createdAt: string;
  updatedAt: string;
}

export interface LedgerSummaryReportsResponse {
  startRecord: number;
  endRecord: number;
  totalRecords: number;
  data: Records[];
}

export interface OrderSummaryReportDownloadReq {
  body: {
    sellerWorkspaceId: string;
    // "2023-06-26"
    startDate: string;
    endDate: string;

    inviteIds: string[];
    salesmenIds: string[];
    productVariantIds: string[];
    cfaIds: string[];
    tags: string[];
  };
}

export enum ReportModule {
  CUSTOMER_CFA_REPORT = 'Customer Cfa Report',
}

export enum FrequencyEnum {
  SELECT = 'Select',
  DAILY = 'Daily',
  WEEKLY = 'Weekly',
  MONTHLY = 'Monthly',
}

export enum Days {
  SUNDAY = 'Sunday',
  MONDAY = 'Monday',
  TUSEDAY = 'Tuesday',
  WEDNESDAY = 'Wednesday',
  THURSDAY = 'Thursday',
  FRIDAY = 'Friday',
  SATURDAY = 'Saturday',
}

export interface OrderDetailsReportRequest {
  sellerWorkspaceId: string;
  endDate: string;
  startDate: string;
  sortDirection?: string;
  pageSize: number;
  pageNo: number;
  orderBy: {
    column: string;
    order: string;
  }[];
}

export interface OrderDetailsReportData {
  tags: string;
  cfaid: string;
  status: string;
  cfaname: string;
  orderid: string;
  inviteid: any;
  skucount: string;
  orderdate: string;
  created_at: string;
  customerid: string;
  updated_at: string;
  ordersaving: string;
  customername: any;
  invoiceamount: any;
  invoicenumber: any;
  netordervalue: string;
  refordernumber: string;
  invoicedquantity: any;
  salesteammemberid: any;
  sellerworkspaceid: string;
  salesteammembername: any;
  sellerworkspacename: string;
  _airbyte_raw_id: string;
  _airbyte_extracted_at: string;
  _airbyte_meta: {
    changes: any[];
  };
}

export interface OrderDetailsReportResponse {
  data: OrderDetailsReportData[];
  totalSkus: number;
  totalCfas: number;
  totalCustomers: number;
  totalOrderValue: number;
  totalOrders: number;
  updatedAt: string;
  startDate: string;
  endDate: string;
  endRecord: number;
  startRecord: number;
  totalRecords: number;
  totalSalesMember: number;
  reportId: string;
}

export interface reportApiReqObject {
  slot: string;
  cfaIds?: string[];
  inviteIds?: string[];
  isSeller?: boolean;
  orderBy: {
    column: string;
    order: string;
  }[];
  searchKey?: string | undefined;
  startDate: string;
  endDate: string;
  sellerWorkspaceId: string;
  pageSize: number;
  pageNo: number;
  dispatchFilters: boolean;
  sortDirection?: string;
  filters?: {
    state?: [];
    district?: [];
    pinCode?: [];
  };
}

export interface SalesLeaderboardReport {
  updatedAt: string;
  salesTeamMemberId: string;
  salesPersonsName: string;
  sellerworkspacename: string;
  customers: string;
  checkins: null;
  noOfOrders: string;
  totalSku: number;
  totalValue: string;
  totalInvoices: string;
  paymentCollected: string;
  paymentCollectedByUpi: string;
  paymentCollectedByCash: string;
  paymentCollectedByCheque: string;
  position: number;
  name: string;
}

export interface SalesLeaderboardReportRequest {
  endDate: string;
  startDate: string;
  sellerWorkspaceId: string;
  pageNo: number;
  pageSize: number;
  orderBy: {
    column: string;
    order: string;
  }[];
  searchKeyword?: string;
}

export interface SalesLeaderboardReportResponse {
  data: SalesLeaderboardReport[];
  teamMember: any;
  totalSalesPersons: string;
  totalCustomers: string;
  totalOrders: string;
  totalOrderValue: string;
  totalPaymentCollected: string;
  totalPaymentCollectedByUpi: string;
  totalPaymentCollectedByCash: string;
  totalPaymentCollectedByCheque: string;
  totalPayments: number;
  updatedAt: string;
  endRecord: number;
  startRecord: number;
  totalCheckins: number;
  totalSku: number;
  totalRecords: number;
}

export interface SalesLeaderboardReportDownloadRequest {
  endDate: string;
  startDate: string;
  sellerWorkspaceId: string;
  orderBy?: {
    column?: string;
    order?: string;
  }[];
  pageNo?: number;
  pageSize?: number;
  format: string;
  filters: {
    range: number;
  }[];
}

export interface InvoiceReport {
  qty: any;
  customerName: string;
  docNumber: string;
  invoiceId: string;
  invoicestatus: string;
  invoiceDate: string;
  totalValue: string;
  inviteId: string;
  sellerWorkspaceId: string;
  dueDate: string;
  orderNumber: string;
  orderValue: string;
  numberOfSKUs: number;
  dispatchDocNumber: string;
  dispatchDate: string;
  paymentId: string;
  amountPaid: string;
}

export interface InvoiceReportResponse {
  length: number;
  data: InvoiceReport[];
  totalInvoicesIssued: number;
  totalInvoiceValue: number;
  totalOrderValue: number;
  numberOfPaidInvoices: number;
  numberOfUnpaidInvoices: number;
  totalOutstandingAmount: number;
  totalAmountPaid: number;
  totalNumberOfSKUsAcrossAllOrders: number;
  totalOverdueInvoices: number;
  averageInvoiceValue: number;
  totalRecords: number;
  startRecord: number;
  endRecord: number;
  updatedAt: string;
}
export interface InvoiceReportRequest {
  startDate?: string;
  endDate?: string;
  pageNo: number;
  pageSize: number;
  sellerWorkspaceId: string;
  cfaId?: string;
  customerId?: string;
  searchKey?: string;
  filters?: {
    customerName?: string[];
    invoiceStatus?: string[];
    orderNumber?: string[];
    dueDate?: string[];
    dispatchDate?: string[];
    paymentStatus?: string[];
  };
  orderBy?: {
    column: string;
    order: string;
  }[];
}

export interface CustomerReportDownloadRequest {
  endDate: string;
  startDate: string;
  sellerWorkspaceId?: string;
  orderBy?: {
    column?: string;
    order?: string;
  }[];
  filters?: {
    state?: [];
    district?: [];
    pinCode?: [];
    salesTeamMemberId?: [];
  };
}

export interface PaymentsReportDownloadRequest {
  endDate?: string;
  startDate?: string;
  sellerWorkspaceId?: string;
  orderBy?: {
    column?: string;
    order?: string;
  }[];
  searchKey?: string;
  pageNo?: number;
  filters?: any;
  pageSize?: number;
}

export interface invoicesReportDownloadRequest {
  endDate?: string;
  startDate?: string;
  sellerWorkspaceId?: string;
  orderBy?: {
    column?: string;
    order?: string;
  }[];
  searchKey?: string;
  pageNo?: number;
  filters?: any;
  pageSize?: number;
}

export interface ProductReportDownloadRequest {
  endDate?: string;
  startDate?: string;
  sellerWorkspaceId?: string;
  orderBy?: {
    column?: string;
    order?: string;
  }[];
  filters?: any;
  format: string;
  sortDirection: string;
  pageNo?: number;
  pageSize?: number;
}

export interface ProductPerformanceReportFilterRequest {
  body: {
    startDate: string;
    endDate: string;
    sellerWorkspaceId: string;
    cfaId?: string;
  };
  filterScreen: SiftScreenEnum;
}

export interface ProductPerformanceReportFilterResponse {
  division: {
    division: any;
    divisionId: any;
  }[];
  category: {
    category?: string;
    categoryId?: string;
  }[];
}
export interface InviceReportFilterRequest {
  body: {
    startDate: string;
    endDate: string;
    pageNo?: number;
    pageSize?: number;
    sellerWorkspaceId: string;
    searchKey?: string;
  };
  filterScreen: SiftScreenEnum;
}

export interface InviceReportFilterResponse {
  customerName: {
    customerName: string;
    customerId: string;
  }[];
  invoiceStatus: InvoiceStatusEnum[];
  orderNumber: string[];
  dueDate: string[];
  dispatchDate: string[];
}

export interface SalesTeamCheckinsReportFilterRequest {
  body: {
    startDate: string;
    endDate: string;
    sellerWorkspaceId: string;
    filters: any[];
  };
  filterScreen: SiftScreenEnum;
}

export interface SalesTeamCheckinsReportFilterResponse {
  customers: {
    customerId: string;
    customerName: string;
  }[];
}
export interface CustomerPerformanceReportFilterRequest {
  body: {
    startDate: string;
    endDate: string;
    sellerWorkspaceId: string;
    cfaId?: string;
  };
  filterScreen: SiftScreenEnum;
}

export interface CustomerPerformanceReportFilterResponse {
  district: {
    district: any;
  }[];
  state: {
    state?: string;
    stateId?: string;
  }[];
  pinCode: {
    inviteId?: string;
  }[];
}

export interface salesTeamCheckReport {
  updatedAt: string;
  checkInDate: string;
  salesTeamMemberId: string;
  salesPersonsName: string;
  customerName: string;
  checkins: string;
  noOfOrders: string;
  paymentCollected: number;
  paymentCollectedByUpi: number;
  paymentCollectedByCash: number;
  paymentCollectedByCheque: number;
  paymentCollectedValue: string;
}

export interface salesTeamCheckinReportResponse {
  data: salesTeamCheckReport[];
  totalCheckIns: number;
  totalOrders: number;
  totalPayments: number;
  totalPaymentCollected: number;
  totalPaymentCollectedValue: number;
  totalPaymentCollectedByUpi: number;
  totalPaymentCollectedByCash: number;
  totalPaymentCollectedByCheque: number;
  totalCustomers: number;
  totalRecords: number;
  startRecord: number;
  endRecord: number;
  updatedAt: string;
}
export interface salesTeamCheckinReportRequest {
  startDate: string;
  endDate: string;
  sellerWorkspaceId: string;
  pageSize: number;
  pageNo: number;
  searchKey?: string;
  orderBy: {
    column: string;
    order: string;
  }[];
  filters: {
    customerIds?: string[];
    teamMemberIds?: string[];
  };
}

export interface SalesTeamCheckinReportDownloadRequest {
  endDate: string;
  startDate: string;
  sellerWorkspaceId: string;
  orderBy?: {
    column?: string;
    order?: string;
  }[];
  pageNo?: number;
  pageSize?: number;
  format: string;
  filters: {
    customerIds: string[];
    teamMemberIds: string[];
  };
}

export interface PaymentsReportRequest {
  startDate?: string;
  endDate?: string;
  pageNo?: number;
  pageSize?: number;
  sellerWorkspaceId: string;
  searchKey?: string;
  orderBy?: {
    column: string;
    order: string;
  }[];
  filters?: {
    customerId?: string[];
    status?: string[];
    orderNumber?: string[];
  };
  cfaId?: string;
}

export interface PaymentsReport {
  sNo: number;
  transactionDate: string;
  referenceNumber: string;
  bankTransactionId: string;
  paymentMode: string;
  paymentDate: string;
  transactionType: string;
  transactionValue: string;
  status: string;
  collectedBy: null;
  customerName: string;
  invoiceNumber: string;
  orderNumber: string;
}
export interface PaymentsReportResponse {
  length: number;
  data: PaymentsReport[];
  totalPayments: string;
  totalTransactionsValue: string;
  startRecord: number;
  endRecord: number;
  totalRecords: number;
  updatedAt: string;
}

export interface PaymentReportFilterRequest {
  body: {
    startDate: string;
    endDate: string;
    pageNo: number;
    pageSize: number;
    sellerWorkspaceId: string;
    searchKey?: string;
  };
  filterScreen: SiftScreenEnum;
}

export interface PaymentReportFilterResponse {
  customer: { customerId: string; customerName: string }[];
  paymentStatus: string[];
  orderNumber: string[];
}

export interface SupplyTrackerReportFilterRequest {
  body: {
    startDate: string;
    endDate: string;
    sellerWorkspaceId: string;
    isSeller: boolean;
    cfaIds?: any[];
    slot?: string[];
  };
  filterScreen: SiftScreenEnum;
}

export interface SupplyTrackerReportFilterResponse {
  fulfillments: {
    cfaId: string;
    cfaName: string;
  }[];
}

export interface OrderDetailsReportFilterResponse {
  fulfillments: {
    cfaId: string;
    cfaName: string;
  }[];
  customers: {
    inviteId: string;
    customerName: string;
  }[];
  salesmen: {
    salesmenId: string;
    salesTeamMemberName: string;
  }[];
}

export interface OrderDetailsReportFilterRequest {
  body: {
    startDate: string;
    endDate: string;
    sellerWorkspaceId: string;
  };
  filterScreen: SiftScreenEnum;
}

export interface SupplyTrackerReportDownloadRequest {
  params?: any;
  body: {
    startDate?: string;
    endDate?: string;
    sellerWorkspaceId: string;
    isSeller: boolean;
    pageSize?: number;
    pageNumber?: number;
    orderBy?: SupplyTrackerReportDownloadOrderBy[];
    format: string;
    cfaIds: string[];
    slot: string[];
  };
}

export interface SupplyTrackerReportDownloadOrderBy {
  column: string;
  order: string;
}

export interface BusinessSummaryReportResponse {
  data: {
    currDateRange: string;
    currValue: number;
    prevDateRange: string;
    prevValue: number;
  }[];
  currTotal: number;
  prevTotal: number;
  percentageIncrease: number;
  updatedAt: string;
  avgTotal: number;
}

export interface BusinessSummaryReportRequest {
  startDate: string;
  endDate: string;
  sellerWorkspaceId: string;
  compare: boolean;
  report: string;
  filters?: {
    category?: string[];
    product?: string[];
    state?: string[];
    district?: string[];
    pincode?: string[];
  };
}

export interface UserKpis {
  kpi_id: string;
  kpi_name?: string;
  position?: number;
  pinned: boolean;
}
export interface KpiListRequest {
  sellerWorkspaceId: string;
}

export interface ModifyKpisResponse {}
export interface ModifyKpisRequest {
  sellerWorkspaceId: string;
  body: {
    kpis: {
      kpi_id: string;
      position: number;
    }[];
  };
}

export interface KpiDetailsResponse {
  kpi_id: string;
  kpi_name: string;
  kpi_description: string;
  kpi_value: number;
  comparison_percent: number | null;
  color_code: string;
  isAbs: boolean;
}
export interface KpiDetailsRequest {
  sellerWorkspaceId: string;
  body: {
    kpi_id: string;
    startDate: string;
    endDate: string;
    isCompare: boolean;
    filters?: {
      category?: string[];
      product?: string[];
      state?: string[];
      district?: string[];
      pincode?: string[];
    };
  };
}

export interface SegmentAnalysisRequest {
  sellerWorkspaceId: string;
  startDate: string;
  endDate: string;
  type: string;
  drilldownBy?: {
    type: string;
    id: string;
  };
  metric: string;
  compare: boolean;
  filters?: {
    category?: string[];
    product?: string[];
    state?: string[];
    district?: string[];
    pincode?: string[];
  };
}

export interface SegmentAnalysisResponse {
  name: string;
  id: string;
  currPercent: string;
  percentInc: string;
}

export interface AppliedFilterItem {
  id: string;
  name: string;
}

export interface AppliedDashboardFilters {
  state?: AppliedFilterItem[];
  pincode?: AppliedFilterItem[];
  product?: AppliedFilterItem[];
  category?: AppliedFilterItem[];
  district?: AppliedFilterItem[];
}

export interface DashboardFilterResponse {
  data: AppliedFilterItem[];
  totalRecords: number;
  startRecord: null;
  endRecord: null;
}

export interface cfaCustomerWiseProductReportFilterRequest {
  body: {
    startDate: string;
    endDate: string;
    sellerWorkspaceId: string;
    filters?: {
      inviteIds?: string[];
    };
    type: string;
  };
  filterScreen: SiftScreenEnum;
}
export interface cfaCustomerWiseProductReportFilterResponse {
  cfas: {
    cfaId: string;
    cfaName: string;
  }[];
  customers: {
    inviteId: string;
    customerName: string;
  }[];
}

export interface DashboardFilterRequest {
  sellerWorkspaceId: string;
  type: DashboardFilterEnum;
  filters?: {
    district?: string[];
    state?: string[];
  };
}

export interface UpdateDashboardFilterRequest {
  sellerWorkspaceId: string;
  type: DashboardFilterTypeEnum;
  filters: AppliedDashboardFilters;
}

export interface AppliedDashboardFiltersResponse {
  data: {
    id: string;
    createdDate: string;
    updatedDate: string;
    sellerWorkspaceId: string;
    userId: string;
    type: DashboardFilterTypeEnum;
    filters?: AppliedDashboardFilters;
  };
}

export interface AppliedDashboardFiltersRequest {
  sellerWorkspaceId: string;
  type: DashboardFilterTypeEnum;
}
