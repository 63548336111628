import {
  CustomerListResponse,
  CustomerListRequest,
  CustomerSearchListRequest,
  CustomerPartyAccountResponse,
  CustomerPartyAccountRequest,
  CustomerPartyAccountBook,
  CreateCustomerReminderResponse,
  CreateCustomerReminderRequest,
  SendSMSToCustomersResponse,
  SendSMSToCustomersRequest,
  RemoveCustomerReminderResponse,
  RemoveCustomerReminderRequest,
  RemindersResponse,
  RemindersRequest,
  CustomerSMSTemplateResponse,
  CustomerSMSTemplateRequest,
  InvoicesTableListResponse,
  InvoicesTableListRequest,
  UpdateMobileNumberRequest,
  CustomerByIdResponse,
  CustomerByIdRequest,
  DownloadLedgerRequest,
  MessageHistoryRequest,
  MessageHistoryResponse,
  CustomerSearchResponse,
  CustomerSummaryResponse,
  CustomerSummaryRequest,
  InActiveCustomerResponse,
  InActiveCustomerRequest,
  CreateCustomerRequest,
  AssignTeamMemberOrCustomerRequest,
  DeleteTeamMemberOrCustomerRequest,
  AllCustomersAssignedResponse,
  AllCustomersAssignedParams,
  DownloadCustomerListResponse,
  DownloadCustomerListRequest,
  DownloadedCustomersHistoryResponse,
  DownloadedCustomersHistoryRequest,
  DownloadGeneratedCustomerListResponse,
  DownloadGeneratedCustomerListRequest,
  CreateCusomerBlukRequest,
} from './model';
import { Api } from '../api/root-api';
import _ from 'lodash';
import _pickBy from 'lodash/pickBy';
import _identity from 'lodash/identity';
import {
  addCustomerList,
  updatePatyAccountBook,
  updateInviteId,
  addRemindersData,
  updateInvoices,
  updateVisits,
  updateMessageHistory,
  updateAllAssignedCustomers,
} from './slice';
import { dispatch } from '../root';
import { getQueryParams } from '../helpers';
import {
  ListInputItemOptions,
  TreeInput,
  TreeInputItemOptions,
  addListInputItems,
  addTreeInputItems,
  mapDataToInputItems,
} from '../filter';
import { handleConvertToTreeInput } from '../thread/helper';
import { CustomerThreadTreeTypeFilter } from '../thread';

let INVITE_ID: string;
let USER_ID: string;

export const customersApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    getCustomerList: builder.query<CustomerListResponse, CustomerListRequest>({
      query(params: CustomerListRequest) {
        const { workspaceId, ...paramObject } = params;
        const param: any = {
          ...paramObject,
          sellerWorkspaceId: workspaceId,
          random: Math.random(),
        };
        const queryParams = Object.keys(param)
          .map((key) => {
            const qString: string[] = [];
            if (Array.isArray(param[key])) {
              //@ts-ignore
              for (let index = 0; index < params[key].length; index++) {
                const value = param[key][index];
                qString.push(
                  `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
                );
              }
              return qString.join('&');
            }
            return `${encodeURIComponent(key)}=${encodeURIComponent(
              param[key]
            )}`;
          })
          .join('&');
        return {
          url: `hub/orgs/api/customers/${workspaceId}?${queryParams}`,
          method: 'GET',
        };
      },
      async onQueryStarted(request, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(addCustomerList(data));
          if (request?.dispatchFilter) {
            const inputItems =
              data?.customers?.map((x) => {
                return {
                  id: x.id,
                  value: x.companyName,
                  selected: false,
                  eitherOr: request.eitherOr ? request.eitherOr : false,
                };
              }) || [];
            dispatch(
              addListInputItems({
                inputItems: inputItems,
                key: ListInputItemOptions.distributors,
              })
            );
          }
        } catch (err) {
          console.log(err, 'dispatchErr');
        }
      },
    }),
    getCustomerFilter: builder.query<any, any>({
      query(params: any) {
        const { sellerWorkspaceId } = params;
        return {
          url: `/hub/orgs/api/customers/filters?sellerWorkspaceId=${sellerWorkspaceId}`,
          method: 'GET',
        };
      },
      async onQueryStarted(request, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then((response) => {
            const { data } = response;
            const routesData = handleConvertToTreeInput(
              data?.routes as CustomerThreadTreeTypeFilter
            );
            console.log(routesData, 'return the routessssss');
            dispatch(
              addTreeInputItems({
                inputItems: routesData?.TreeData as TreeInput[],
                key: TreeInputItemOptions.threadRoutes,
              })
            );
            const segmentData = handleConvertToTreeInput(
              data?.customerSegment as CustomerThreadTreeTypeFilter
            );
            dispatch(
              addTreeInputItems({
                inputItems: segmentData?.TreeData as TreeInput[],
                key: TreeInputItemOptions.threadSegments,
              })
            );
          })
          .catch((error) => console.log('thread filter error', error));
      },
    }),

    getCustomerListSearch: builder.query<
      CustomerListResponse,
      CustomerSearchListRequest
    >({
      query(params: CustomerSearchListRequest) {
        const { workspaceId, ...paramObject } = params;
        const param: any = _pickBy(
          { ...paramObject, sellerWorkspaceId: workspaceId },
          _identity
        );
        const queryParams = Object.keys(param)
          .map((key) => {
            const qString: string[] = [];
            if (Array.isArray(param[key])) {
              //@ts-ignore
              for (let index = 0; index < params[key].length; index++) {
                const value = param[key][index];
                qString.push(
                  `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
                );
              }
              return qString.join('&');
            }
            return `${encodeURIComponent(key)}=${encodeURIComponent(
              param[key]
            )}`;
          })
          .join('&');
        return {
          url: `hub/orgs/api/customers/${workspaceId}?${queryParams}`,
          method: 'GET',
        };
      },
    }),
    getCustomerPartyAccount: builder.query<
      CustomerPartyAccountResponse,
      CustomerPartyAccountRequest
    >({
      query(params: CustomerPartyAccountRequest) {
        const { inviteId } = params;
        INVITE_ID = inviteId;
        dispatch(updateInviteId(inviteId));
        return {
          url: `invoiceservice/partyAccountBook/balanceBySeller`,
          method: 'GET',
          params: params,
        };
      },
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            const customer = _.first(data?.customers);
            const patyAccount: CustomerPartyAccountBook = {
              openingBalance: customer?.openingBalance,
              outStandingBalance: customer?.outStandingBalance,
              //@ts-ignore
              overdueAmount: customer?.overdueAmount,
              totalCredit: customer?.totalCredit,
              totalDebit: customer?.totalDebit,
              //@ts-ignore
              lastPayment: customer?.lastPayment,
              //@ts-ignore
              dueInvoice: customer?.dueInvoice,
            };
            dispatch(
              updatePatyAccountBook({
                partyAccountBook: patyAccount,
                id: INVITE_ID,
              })
            );
          }
        } catch (err) {
          console.log(err, 'dispatchErr');
        }
      },
    }),
    createCustomerReminder: builder.mutation<
      CreateCustomerReminderResponse,
      CreateCustomerReminderRequest
    >({
      query(params: CreateCustomerReminderRequest) {
        return {
          url: `communication/reminder`,
          method: 'POST',
          body: params,
        };
      },
    }),
    sendSMSToCustomers: builder.mutation<
      SendSMSToCustomersResponse,
      SendSMSToCustomersRequest
    >({
      query(params: SendSMSToCustomersRequest) {
        return {
          url: `invoiceservice/communication/invites`,
          method: 'POST',
          body: params,
        };
      },
    }),
    removeCustomerReminder: builder.query<
      RemoveCustomerReminderResponse,
      RemoveCustomerReminderRequest
    >({
      query(params: RemoveCustomerReminderRequest) {
        const queryParams = getQueryParams(params);
        return {
          url: `communication/reminder/delete?${queryParams}`,
          method: 'GET',
        };
      },
    }),
    getReminders: builder.query<RemindersResponse, RemindersRequest>({
      query(params: RemindersRequest) {
        return {
          url: `communication/reminder`,
          method: 'GET',
          params: params,
        };
      },
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          //@ts-ignore
          dispatch(addRemindersData(data?.reminderRecords));
        } catch (err) {
          dispatch(addRemindersData([]));
          console.log(err, 'dispatchErr');
        }
      },
    }),
    getCustomerSMSTemplate: builder.query<
      CustomerSMSTemplateResponse,
      CustomerSMSTemplateRequest
    >({
      query(params: CustomerSMSTemplateRequest) {
        return {
          url: `invoiceservice/communication/invites/template`,
          method: 'GET',
          params: params,
        };
      },
    }),
    getInvoicesTableList: builder.query<
      InvoicesTableListResponse,
      InvoicesTableListRequest
    >({
      query(params: InvoicesTableListRequest) {
        const { workspaceId, ...paramObject } = params;
        INVITE_ID = paramObject.inviteId;
        return {
          url: `invoiceservice/invoices/${workspaceId}`,
          method: 'GET',
          params: paramObject,
        };
      },
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            updateInvoices({
              invoicesListResponse: data,
              id: INVITE_ID,
            })
          );
        } catch (err) {
          console.log(err, 'dispatchErr');
        }
      },
    }),
    updateMobileNumber: builder.mutation<string, UpdateMobileNumberRequest>({
      query(params: UpdateMobileNumberRequest) {
        const { inviteId, ...bodyObject } = params;
        return {
          url: `customers/edit/${inviteId}`,
          method: 'PUT',
          body: bodyObject,
          responseHandler: (response) => response.text(),
        };
      },
    }),
    getCustomerById: builder.query<CustomerByIdResponse, CustomerByIdRequest>({
      query(params: CustomerByIdRequest) {
        console.log(params, 'customerDetails');
        const { workspaceId, ...paramObject } = params;
        return {
          url: `customers/${workspaceId}`,
          method: 'GET',
          params: paramObject,
        };
      },
    }),
    downloadLedger: builder.query<any, DownloadLedgerRequest>({
      query(params: DownloadLedgerRequest) {
        return {
          url: `invoiceservice/partyaccountbook/list/download`,
          method: 'GET',
          params: params,
          responseHandler: (response) => response.arrayBuffer(),
        };
      },
    }),
    getCheckinDetails: builder.query<any, { customerId: string }>({
      query(params) {
        const { customerId } = params;
        USER_ID = customerId;
        return {
          url: `visit`,
          method: 'GET',
          params: params,
        };
      },
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(updateVisits({ visitResponse: data, id: USER_ID }));
        } catch (err) {
          console.log(err, 'dispatchErr');
        }
      },
    }),
    getMessageHistory: builder.query<
      MessageHistoryResponse,
      MessageHistoryRequest
    >({
      query(params: MessageHistoryRequest) {
        const { inviteId } = params;
        INVITE_ID = inviteId;
        return {
          url: `https://integration.zonoapp.com/stage/reports/sms/summary`,
          method: 'GET',
          params: params,
          headers: {
            module: 'integration',
          },
        };
      },
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            updateMessageHistory({ messageHistory: data, id: INVITE_ID })
          );
        } catch (err) {
          console.log(err, 'dispatchErr');
        }
      },
    }),
    searchCustomer: builder.query<
      CustomerSearchResponse,
      {
        workspaceId: string;
        inviteId: string;
        includeInactive: boolean;
        includeCFA: boolean;
        isNoCache?: boolean;
      }
    >({
      query(arg) {
        return {
          url: `/hub/orgs/api/customer/details?sellerWorkspaceId=${arg.workspaceId}`,
          params: {
            inviteId: arg.inviteId,
            includeInactive: arg.includeInactive,
            includeCFA: arg.includeCFA,
          },
          method: 'GET',
          ...(arg.isNoCache
            ? {
                headers: {
                  'Cache-control': 'no-cache',
                },
              }
            : {}),
        };
      },
    }),
    getCustomerSummary: builder.query<
      CustomerSummaryResponse[],
      CustomerSummaryRequest
    >({
      query(arg) {
        return {
          url: `hub/commerce-v2/customer/summary/${arg.principalWorkspaceId}-2?sellerWorkspaceId=${arg.principalWorkspaceId}`,
          method: 'GET',
          params: arg,
        };
      },
    }),
    getuploadedFileData: builder.query<any, any>({
      query(arg) {
        return {
          url: `mdm-integration/uploadbatch/uploadedBatchFile`,
          method: 'GET',
          params: { ...arg, sendSSE: false },
        };
      },
    }),
    assignCustomerGroups: builder.mutation<
      any,
      { workspaceId: string; body: any }
    >({
      query(arg) {
        const { workspaceId, body } = arg;
        return {
          url: `teams/assign-customergroups/${workspaceId}`,
          method: 'POST',
          body: body,
        };
      },
    }),
    addGroupsToCustomers: builder.mutation<any, any>({
      query(arg) {
        const { senderWorkspaceId, body } = arg;
        return {
          url: `customers/groupassignment/${senderWorkspaceId}`,
          method: 'POST',
          body: body,
        };
      },
    }),
    inActiveCustomer: builder.mutation<
      InActiveCustomerResponse,
      InActiveCustomerRequest
    >({
      query(arg) {
        const { workspaceId, ...otherParams } = arg;
        return {
          url: `customer/${workspaceId}`,
          method: 'PATCH',
          params: otherParams,
        };
      },
    }),
    createCustomer: builder.mutation<
      any,
      {
        body: CreateCustomerRequest;
        workspaceId: string;
      }
    >({
      query(args) {
        return {
          url: `customers/create/${args.workspaceId}`,
          method: 'POST',
          body: args.body,
        };
      },
    }),
    updateCustomers: builder.mutation<
      any,
      {
        body: CreateCustomerRequest;
        workspaceId: string;
        inviteId: string;
      }
    >({
      query(args) {
        return {
          url: `customer/update/${args.inviteId}`,
          method: 'POST',
          body: args.body,
        };
      },
    }),
    addCustomers: builder.mutation<any, any>({
      query(payload: CreateCusomerBlukRequest) {
        const { customers, sellerWorkspaceId } = payload;
        return {
          url: `hub/orgs/api/customers/create/bulk/${sellerWorkspaceId}?sellerWorkspaceId=${sellerWorkspaceId}`,
          method: 'POST',
          body: { customers },
        };
      },
    }),
    getAllAssignedCustomers: builder.query<
      AllCustomersAssignedResponse,
      AllCustomersAssignedParams
    >({
      query(arg) {
        const { dispatchFilter, ...restArgs } = arg;
        const queryParams = getQueryParams(restArgs);
        return {
          url: `team/customers?${queryParams}`,
          method: 'GET',
        };
      },
      async onQueryStarted(request, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then((response) => {
            if (request?.dispatchFilter) {
              const cfaInputItems = mapDataToInputItems(
                response?.data?.filters?.cfas,
                'id',
                'supplierName'
              );
              dispatch(
                addListInputItems({
                  inputItems: cfaInputItems,
                  key: ListInputItemOptions.salesCfa,
                })
              );
              // update the assigned custoemrs list
              dispatch(updateAllAssignedCustomers(response?.data?.data || []));
            }
          })
          .catch((error) => console.log(error));
      },
    }),
    assignTeamMemberOrCustomer: builder.mutation<
      any,
      AssignTeamMemberOrCustomerRequest
    >({
      query(arg) {
        const { workspaceId, ...params } = arg;
        return {
          url: `hub/orgs/api/team-member/customer?sellerWorkspaceId=${arg.workspaceId}`,
          method: 'POST',
          body: params,
        };
      },
      invalidatesTags: ['teamMemberList'],
    }),
    deleteTeamMemberOrCustomer: builder.mutation<
      any,
      DeleteTeamMemberOrCustomerRequest
    >({
      query(arg) {
        return {
          url: `team-member/customer/${arg.workspaceId}`,
          method: 'Delete',
          body: arg,
        };
      },
      invalidatesTags: ['teamMemberList'],
    }),
    initiateCustomerListDownload: builder.mutation<
      DownloadCustomerListResponse,
      DownloadCustomerListRequest
    >({
      query: (args) => ({
        url: `customers/catalogue/download/${args.workspaceId}`,
        method: 'GET',
        params: args.body,
      }),
    }),
    downloadedCustomersHistory: builder.query<
      DownloadedCustomersHistoryResponse[],
      DownloadedCustomersHistoryRequest
    >({
      query: (arg) => ({
        url: `downloadTasks/list-s3-contents/${arg?.workspaceId}?entity=customers&maxKeys=10`,
        method: 'GET',
      }),
    }),
    downloadGeneratedCustomerList: builder.query<
      DownloadGeneratedCustomerListResponse,
      DownloadGeneratedCustomerListRequest
    >({
      query: (arg) => ({
        url: `downloadTasks/download-s3-file/${arg?.workspaceId}?entity=customers&key=${arg?.key}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetCustomerListQuery,
  useLazyGetCustomerListQuery,
  useGetCustomerListSearchQuery,
  useLazyGetCustomerListSearchQuery,
  useGetCustomerPartyAccountQuery,
  useLazyGetCustomerPartyAccountQuery,
  useCreateCustomerReminderMutation,
  useSendSMSToCustomersMutation,
  useLazyRemoveCustomerReminderQuery,
  useGetRemindersQuery,
  useLazyGetRemindersQuery,
  useGetCustomerSMSTemplateQuery,
  useLazyGetCustomerSMSTemplateQuery,
  useGetInvoicesTableListQuery,
  useLazyGetInvoicesTableListQuery,
  useUpdateMobileNumberMutation,
  useGetCustomerByIdQuery,
  useLazyGetCustomerByIdQuery,
  useDownloadLedgerQuery,
  useLazyDownloadLedgerQuery,
  useGetCheckinDetailsQuery,
  useLazyGetCheckinDetailsQuery,
  useGetMessageHistoryQuery,
  useLazyGetMessageHistoryQuery,
  useLazySearchCustomerQuery,
  useLazyGetCustomerSummaryQuery,
  useLazyGetuploadedFileDataQuery,
  useAddGroupsToCustomersMutation,
  useInActiveCustomerMutation,
  useAssignCustomerGroupsMutation,
  useCreateCustomerMutation,
  useUpdateCustomersMutation,
  useLazyGetAllAssignedCustomersQuery,
  useAssignTeamMemberOrCustomerMutation,
  useDeleteTeamMemberOrCustomerMutation,
  useInitiateCustomerListDownloadMutation,
  useLazyDownloadedCustomersHistoryQuery,
  useLazyDownloadGeneratedCustomerListQuery,
  useAddCustomersMutation,
  useLazyGetCustomerFilterQuery,
} = customersApi;
