import React, { useState } from 'react';
import './product-details-gallery.scss';
import { SVGS } from '@zonofi/zono-money-design-system';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CartIcon } from '../cart-icon/cart-icon';

interface ProductGalleryProps {
  images: string[];
  totalCartItems: number;
}

export const ProductGallery: React.FC<ProductGalleryProps> = ({
  images,
  totalCartItems,
}) => {
  const [currentImage, setCurrentImage] = useState(0);
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const fromCart = params.get('isCart');
  const getModifiedImageUrl = (url: string) => {
    try {
      const urlObj = new URL(url);
      urlObj.searchParams.set('width', '1024');
      urlObj.searchParams.set('height', '1024');
      return urlObj.toString();
    } catch {
      return url;
    }
  };

  return (
    <div className="product-gallery">
      <div className="product-gallery-header">
        <div className="back-button">
          <div
            onClick={() => {
              navigate(-1);
            }}
            className="back-button"
          >
            <img src={SVGS.LeftArrowBlack} alt="back" />
          </div>
        </div>
        {fromCart !== 'true' && (
          <CartIcon
            itemsCount={totalCartItems}
            onCartClick={() => {
              navigate('/cart');
            }}
          />
        )}
      </div>
      <div className="main-image">
        <img
          src={getModifiedImageUrl(images[currentImage]) || SVGS.DefaultProduct}
          alt=""
          onError={(e) => {
            e.currentTarget.onerror = null;
            e.currentTarget.src = SVGS.DefaultProduct;
          }}
        />
      </div>

      <div className="image-dots">
        {images.map((_, index) => (
          <button
            key={index}
            className={`dot ${currentImage === index ? 'active' : ''}`}
            onClick={() => setCurrentImage(index)}
          />
        ))}
      </div>
    </div>
  );
};
