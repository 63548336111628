import React, { ReactElement } from 'react';
import { Tooltip, Button, TooltipProps } from '@mui/material';
import './zo-tooltip.scss';
import { SHARED_SVGS } from '@zonofi/shared';

export interface ZoTooltipProps extends Omit<TooltipProps, 'title' | 'children'> {

    title: string;

    arrowDirection?: 'top' | 'bottom' | 'left' | 'right';

    theme?: 'dark' | 'light';

    buttonText?: string;

    buttonStyle?: React.CSSProperties;

    tooltipStyle?: React.CSSProperties;

    children?: ReactElement;
}

export const ZoTooltip: React.FC<ZoTooltipProps> = ({
    title,
    arrowDirection = 'top',
    theme = 'dark',
    buttonText,
    buttonStyle,
    tooltipStyle,
    children,
    ...tooltipProps
}) => {
    const iconSrc = theme === 'dark' ? SHARED_SVGS.InfoNew : SHARED_SVGS.Info;
    return (
        <div className="zoTooltip font-regular">
            <Tooltip className={`font-regular zo-tooltip__${theme}`}
                title={
                    <div className={`zo-tooltip__content zo-tooltip__content--${theme}`}>
                        <span className='infoicon'>
                            <img src={iconSrc} alt="Info Icon" />
                        </span>
                        <span>{title}</span>
                    </div>
                }
                arrow
                placement={arrowDirection}
                classes={{
                    tooltip: `zo-tooltip__tooltip zo-tooltip__tooltip--${theme}`,
                    arrow: `zo-tooltip__arrow zo-tooltip__arrow--${theme}`,
                }}
                PopperProps={{
                    style: tooltipStyle,
                }}
                {...tooltipProps}
            >
                {children || (
                    <Button
                        style={buttonStyle}
                        className="zo-tooltip__button"
                        variant="contained"
                    >
                        {buttonText}
                    </Button>
                )}
            </Tooltip>
        </div>
    );
};
