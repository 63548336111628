import { EventType, Notification } from '@notifee/react-native';
import { OrderImportSourceEnum } from '../helpers';

export enum ChannelType {
  Channel = 'channel',
  Thread = 'thread',
}

export interface Miscellaneous {
  notificationData: {
    notification?: Notification;
    eventType?: EventType;
  };
  channelInfo: {
    id: string;
    type?: ChannelType;
  };
  sellerStoreData: {
    tokens: {
      token: string;
      refreshToken: string;
      role: string;
    };
  };
  appParams: AppParams;
  selectedChannelWorkspaceID: string;
  cartModalState?: boolean;
  extendedCartState?: boolean;
  retryPolicies: {
    [id: string]: {
      count: number;
      time: number;
    };
  };
  navigateFromChannel: string;
  headerVisible?: boolean;
  selectedCustomer?: string;
  version?: string;
  selectedCartTab: OrderImportSourceEnum;
  isZonoMoney: boolean;
  navigateFunctions: NavigateFunctions;
  runTour?: boolean;
  activeKeyboardHandler: any;
  bottomTabBarHeight: number;
  topHeaderHeight: number;
  tabsCurrentIndex: {
    [screen: string]: number;
  };
  selectedPos: string[];
  isDistributorFlow: boolean;
  sharedIntent: SharedIntent | null;
  fromSellerAction: boolean;
  isAssignTags: boolean;
  successOrderModal: SuccessOrderModal;
}

export interface AppParams {
  route: string;
  queryParams: string;
  cartCount?: number;
}

export interface NavigateFunctions {
  navigateToOrders: () => void;
  navigateToUploadOrderFromCreateOrderDropdown: () => void;
  navigateToManualOrderFromCreateOrderDropdown: () => void;
  navigateToCustomersManualOrder: () => void;
  navigateToCustomersUploadOrder: () => void;
  navigateToCreateManualReturn: () => void;
  navigateToCustomerCreateManualReturn: () => void;
  navigateToTrackPO: () => void;
  navigateToCreateUploadReturn: () => void;
  navigateToCustomerCreateUploadReturn: () => void;
  navigateToUploadCustomers: () => void;
  navigateToUploadProducts: () => void;
  navigateToUploadDivisions: () => void;
  navigateToUploadHeadDivisions: () => void;
  navigateToUploadSchemes: () => void;
  navigateToUploadCfa: () => void;
  navigateToUploadLedgers: () => void;
}

export interface SharedIntent {
  uri: string;
  type: string;
  size: number;
  name: string;
}

export enum SuccessOrderModalType {
  CANCELLED = 'Cancelled',
  CHECKOUT = 'Checkout',
  SUBMITTED = 'Submitted',
  APPROVED = 'Approved',
}

export interface SuccessOrderModal {
  isOpen: boolean;
  type: SuccessOrderModalType | null;
  isOnlyNavigateToThreads?: boolean;
  data?: {
    skuCount?: number;
    orderNo?: string;
    customerName?: string;
  };
}
