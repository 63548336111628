import React from 'react';
import './GstKyc.scss';
import { theme } from '@zonofi/common';
import { SVGS } from '@zonofi/zono-money-design-system';
import { Input, InputAdornment } from '@mui/material';
import { ZoDatePicker } from '@zonofi/zo-components';
import { SHARED_SVGS } from '@zonofi/shared';

interface KycErrorState {
  FirmName: string;
  AadharNo: string;
  PanNo: string;
}

interface gstKycProps {
  gstFormData: {
    name: string;
    address: string;
    date: string;
    GstInNo: string;
    CustomerName: string;
    AadharCard: string;
    AadharNo: string;
    PanCard: string;
    phoneNo: string;
    email: string;
  };
  handleInputFiled: (text: string, value: string) => void;
  handleNext: () => void;
  errorMsg: string;
  noGst: boolean;
  verifyValidGst: boolean;
  kycError: KycErrorState;
}

export const GstKyc = ({
  gstFormData,
  handleInputFiled,
  handleNext,
  errorMsg,
  verifyValidGst,
  noGst,
  kycError,
}: gstKycProps) => {
  const gstRes = verifyValidGst;

  const getImageIcon = (src: string, marginRight?: string, size?: string) => {
    return (
      <img
        alt="icon"
        src={src}
        width={size || '20px'}
        height={size || '20px'}
        style={{ marginRight: marginRight || '8px', cursor: 'pointer' }}
      />
    );
  };

  return (
    <div style={{ ...theme.fontConfig.regular }}>
      <div className="kyc-container">
        <div className="gst-kyc-title">
          <span>Complete KYC</span>
        </div>
      </div>
      <div className="kyc-body-continer">
        {!noGst && (
          <div
            className="kyc-message-container"
            style={{
              backgroundColor: gstRes ? '#EAF8F2' : '#FCEBE7',
            }}
          >
            <img src={gstRes ? SVGS?.greenTick : SVGS?.BellIcon} alt="gstMsg" />
            <span className="kyc-heading">
              {gstRes ? 'GSTIN Validated' : 'GSTIN Not Validated'}
            </span>
            <span className="kyc-para">
              {gstRes
                ? 'Great! We’ve pulled your company details based on the GSTIN you entered. Please confirm everything is correct.'
                : 'We are unable to validate your GST Number please enter the details below'}
            </span>
          </div>
        )}
        {!noGst && (
          <div className="gstin-details  font-regular">
            <span className="gstin-details-title comms-font-size-regular">
              GSTIN Details
            </span>
            <div className="gst-form">
              <div className="each-form">
                <label>
                  Firm Name
                  <span className="required" style={{ color: '#DD360C' }}>
                    *
                  </span>
                </label>

                <Input
                  disableUnderline
                  onFocus={(e) => {
                    e.target.style.borderColor = '#D9D9D9';
                  }}
                  style={{
                    display: 'flex',
                    height: '36px',
                    padding: '8px',
                    alignItems: 'center',
                    gap: '8px',
                    borderRadius: '6px',
                    border: '1px solid #D9D9D9',
                    background: '#FFF',
                    width: '100%',
                  }}
                  value={gstFormData?.name}
                  onBlur={(e) => {
                    const parentElement = e.target.parentNode as HTMLElement;
                    if (parentElement) {
                      parentElement.style.borderColor = '#D9D9D9';
                    }
                  }}
                  onChange={(e) => {
                    handleInputFiled('name', e.target.value);
                  }}
                  className="comms-font-size-regular input"
                  placeholder="Enter "
                  inputProps={{ style: { height: '32px', padding: 0 } }}
                  endAdornment={
                    <InputAdornment
                      position="end"
                      style={{ marginRight: 10, cursor: 'pointer' }}
                    >
                      <img
                        src={
                          gstFormData?.name
                            ? SVGS?.ActiveIcon
                            : SVGS?.InactiveIcon
                        }
                        alt="input-status"
                      />
                    </InputAdornment>
                  }
                />
                {kycError?.FirmName && (
                  <div
                    style={{
                      color: 'red',
                      fontSize: '12px',

                      marginBottom: '4px',
                      fontFamily: 'Segoe-UI',
                    }}
                  >
                    {kycError?.FirmName}
                  </div>
                )}
              </div>
              <div className="each-form">
                <label>Firm Address</label>
                <textarea
                  onFocus={(e) => {
                    e.target.style.borderColor = '#D9D9D9';
                  }}
                  style={{
                    display: 'flex',
                    minHeight: '56px',
                    padding: '8px',
                    alignItems: 'center',
                    gap: '8px',
                    fontFamily: 'Segoe-UI',
                    fontWeight: 400,
                    lineHeight: '1',
                    borderRadius: '6px',
                    border: '1px solid #D9D9D9',
                    background: '#FFF',
                    width: '100%',
                    boxSizing: 'border-box',
                    resize: 'vertical',
                  }}
                  value={gstFormData?.address}
                  onBlur={(e) => {
                    const parentElement = e.target.parentNode as HTMLElement;
                    if (parentElement) {
                      parentElement.style.borderColor = '#D9D9D9';
                    }
                  }}
                  onChange={(e) => {
                    handleInputFiled('address', e.target.value);
                  }}
                  className="comms-font-size-regular input"
                  placeholder="Enter"
                />
              </div>

              <div className="each-form">
                <label>Valid Till</label>
                <ZoDatePicker
                  label={''}
                  placeholder="DD/MM/YYYY"
                  value={gstFormData.date || ''}
                  onChange={(e) =>
                    handleInputFiled('date', e.format('YYYY-MM-DD'))
                  }
                  disablePast
                  dateFeildStyleProps={{ paddingRight: 28 }}
                  endAdornment={
                    <InputAdornment
                      position="end"
                      style={{ marginRight: 10, cursor: 'pointer' }}
                    >
                      <div>
                        <img
                          src={SHARED_SVGS?.CalendarIcon}
                          alt="calendar-icon"
                        />
                        {/* <img
                          src={
                            gstFormData?.date
                              ? SVGS?.ActiveIcon
                              : SVGS?.InactiveIcon
                          }
                          alt="input-status"
                        /> */}
                      </div>
                    </InputAdornment>
                  }
                  inputFieldProps={{ padding: '6px 8px' }}
                />
              </div>
              <div className="each-form">
                <label>GSTIN Number</label>
                <Input
                  disableUnderline
                  onFocus={(e) => {
                    e.target.style.borderColor = '#D9D9D9';
                  }}
                  value={gstFormData?.GstInNo}
                  onChange={(e) => {
                    const input = e.target.value;
                    if (input.length <= 15) {
                      handleInputFiled('GstInNo', e.target.value);
                    }
                  }}
                  style={{
                    display: 'flex',
                    height: '36px',
                    padding: '8px',
                    alignItems: 'center',
                    gap: '8px',
                    borderRadius: '6px',
                    border: '1px solid #D9D9D9',
                    background: '#FFF',
                    width: '100%',
                  }}
                  onBlur={(e) => {
                    const parentElement = e.target.parentNode as HTMLElement;
                    if (parentElement) {
                      parentElement.style.borderColor = '#D9D9D9';
                    }
                  }}
                  className="comms-font-size-regular input"
                  placeholder="Search"
                  inputProps={{ style: { height: '32px', padding: 0 } }}
                  endAdornment={
                    <InputAdornment
                      position="end"
                      style={{ marginRight: 10, cursor: 'pointer' }}
                    >
                      <img
                        src={
                          gstFormData?.GstInNo.length >= 15
                            ? SVGS?.ActiveIcon
                            : SVGS?.InactiveIcon
                        }
                        alt="input-status"
                      />
                    </InputAdornment>
                  }
                />
              </div>
            </div>
          </div>
        )}
        <div className="gstin-details  font-regular">
          <span className="gstin-details-title comms-font-size-regular">
            Others Details
          </span>
          <div className="gst-form">
            {noGst && (
              <div className="each-form">
                <label>
                  Firm Name
                  <span className="required" style={{ color: '#DD360C' }}>
                    *
                  </span>
                </label>

                <Input
                  disableUnderline
                  onFocus={(e) => {
                    e.target.style.borderColor = '#D9D9D9';
                  }}
                  style={{
                    display: 'flex',
                    height: '36px',
                    padding: '8px',
                    alignItems: 'center',
                    gap: '8px',
                    borderRadius: '6px',
                    border: '1px solid #D9D9D9',
                    background: '#FFF',
                    width: '100%',
                  }}
                  onBlur={(e) => {
                    const parentElement = e.target.parentNode as HTMLElement;
                    if (parentElement) {
                      parentElement.style.borderColor = '#D9D9D9';
                    }
                  }}
                  value={gstFormData?.name}
                  onChange={(e) => {
                    handleInputFiled('name', e.target.value);
                  }}
                  className="comms-font-size-regular input"
                  placeholder="Enter "
                  inputProps={{ style: { height: '32px', padding: 0 } }}
                  endAdornment={
                    <InputAdornment
                      position="end"
                      style={{ marginRight: 10, cursor: 'pointer' }}
                    >
                      <img
                        src={
                          gstFormData?.name
                            ? SVGS?.ActiveIcon
                            : SVGS?.InactiveIcon
                        }
                        alt="input-status"
                      />
                    </InputAdornment>
                  }
                />
                {kycError?.FirmName && (
                  <div
                    style={{
                      color: 'red',
                      fontSize: '12px',

                      marginBottom: '4px',
                      fontFamily: 'Segoe-UI',
                    }}
                  >
                    {kycError?.FirmName}
                  </div>
                )}
              </div>
            )}
            <div className="each-form">
              <label>Customer Name</label>
              <Input
                disableUnderline
                onFocus={(e) => {
                  e.target.style.borderColor = '#D9D9D9';
                }}
                style={{
                  display: 'flex',
                  height: '36px',
                  padding: '8px',
                  alignItems: 'center',
                  gap: '8px',
                  borderRadius: '6px',
                  border: '1px solid #D9D9D9',
                  background: '#FFF',
                  width: '100%',
                }}
                onBlur={(e) => {
                  const parentElement = e.target.parentNode as HTMLElement;
                  if (parentElement) {
                    parentElement.style.borderColor = '#D9D9D9';
                  }
                }}
                value={gstFormData?.CustomerName}
                onChange={(e) => {
                  handleInputFiled('CustomerName', e.target.value);
                }}
                className="comms-font-size-regular input"
                placeholder="Enter "
                inputProps={{ style: { height: '32px', padding: 0 } }}
                endAdornment={
                  <InputAdornment
                    position="end"
                    style={{ marginRight: 10, cursor: 'pointer' }}
                  >
                    <img
                      src={
                        gstFormData?.CustomerName
                          ? SVGS?.ActiveIcon
                          : SVGS?.InactiveIcon
                      }
                      alt="input-status"
                    />
                  </InputAdornment>
                }
              />
            </div>
            {noGst && (
              <div className="each-form">
                <label>Phone Number</label>
                <Input
                  disableUnderline
                  onFocus={(e) => {
                    e.target.style.borderColor = '#D9D9D9';
                  }}
                  style={{
                    display: 'flex',
                    height: '36px',
                    padding: '8px',
                    alignItems: 'center',
                    gap: '8px',
                    borderRadius: '6px',
                    border: '1px solid #D9D9D9',
                    background: '#FFF',
                    width: '100%',
                  }}
                  onBlur={(e) => {
                    const parentElement = e.target.parentNode as HTMLElement;
                    if (parentElement) {
                      parentElement.style.borderColor = '#D9D9D9';
                    }
                  }}
                  value={gstFormData?.phoneNo}
                  onChange={(e) => {
                    const input = e.target.value.replace(/\D/g, '');
                    if (input.length <= 10) {
                      handleInputFiled('phoneNo', input);
                    }
                  }}
                  className="comms-font-size-regular input"
                  placeholder="Enter "
                  inputProps={{ style: { height: '32px', padding: 0 } }}
                  endAdornment={
                    <InputAdornment
                      position="end"
                      style={{ marginRight: 10, cursor: 'pointer' }}
                    >
                      <img
                        src={
                          gstFormData?.phoneNo
                            ? SVGS?.ActiveIcon
                            : SVGS?.InactiveIcon
                        }
                        alt="input-status"
                      />
                    </InputAdornment>
                  }
                />
              </div>
            )}
            {noGst && (
              <div className="each-form">
                <label>Email</label>
                <Input
                  disableUnderline
                  onFocus={(e) => {
                    e.target.style.borderColor = '#D9D9D9';
                  }}
                  style={{
                    display: 'flex',
                    height: '36px',
                    padding: '8px',
                    alignItems: 'center',
                    gap: '8px',
                    borderRadius: '6px',
                    border: '1px solid #D9D9D9',
                    background: '#FFF',
                    width: '100%',
                  }}
                  onBlur={(e) => {
                    const parentElement = e.target.parentNode as HTMLElement;
                    if (parentElement) {
                      parentElement.style.borderColor = '#D9D9D9';
                    }
                  }}
                  value={gstFormData?.email}
                  onChange={(e) => {
                    handleInputFiled('email', e.target.value);
                  }}
                  className="comms-font-size-regular input"
                  placeholder="Enter "
                  inputProps={{ style: { height: '32px', padding: 0 } }}
                  endAdornment={
                    <InputAdornment
                      position="end"
                      style={{ marginRight: 10, cursor: 'pointer' }}
                    >
                      <img
                        src={
                          gstFormData?.email
                            ? SVGS?.ActiveIcon
                            : SVGS?.InactiveIcon
                        }
                        alt="input-status"
                      />
                    </InputAdornment>
                  }
                />
              </div>
            )}
            <div className="each-form">
              <label>
                Aadhaar Number
                <span className="required" style={{ color: '#DD360C' }}>
                  *
                </span>
              </label>
              <Input
                disableUnderline
                onFocus={(e) => {
                  e.target.style.borderColor = '#D9D9D9';
                }}
                style={{
                  display: 'flex',
                  height: '36px',
                  padding: '8px',
                  alignItems: 'center',
                  gap: '8px',
                  lineHeight: 'auto',
                  borderRadius: '6px',
                  border: '1px solid #D9D9D9',
                  background: '#FFF',
                  width: '100%',
                }}
                value={gstFormData?.AadharNo}
                onChange={(e) => {
                  const input = e.target.value.replace(/\D/g, '');
                  if (input.length <= 12) {
                    handleInputFiled('AadharNo', input);
                  }
                }}
                onBlur={(e) => {
                  const parentElement = e.target.parentNode as HTMLElement;
                  if (parentElement) {
                    parentElement.style.borderColor = '#D9D9D9';
                  }
                }}
                className="comms-font-size-regular input"
                placeholder="Enter"
                inputProps={{ style: { height: '32px', padding: 0 } }}
                endAdornment={
                  <InputAdornment
                    position="end"
                    style={{ marginRight: 10, cursor: 'pointer' }}
                  >
                    <img
                      src={
                        gstFormData?.AadharNo.length >= 12
                          ? SVGS?.ActiveIcon
                          : SVGS?.InactiveIcon
                      }
                      alt="input-status"
                    />
                  </InputAdornment>
                }
              />
              {kycError?.AadharNo && (
                <div
                  style={{
                    color: 'red',
                    fontSize: '12px',

                    marginBottom: '4px',
                    fontFamily: 'Segoe-UI',
                  }}
                >
                  {kycError?.AadharNo}
                </div>
              )}
            </div>
            <div className="each-form">
              <label>
                PAN Card Number
                <span className="required" style={{ color: '#DD360C' }}>
                  *
                </span>
              </label>

              <Input
                disableUnderline
                onFocus={(e) => {
                  e.target.style.borderColor = '#D9D9D9';
                }}
                value={gstFormData?.PanCard}
                onChange={(e) => {
                  const input = e.target.value.toUpperCase();
                  if (input.length <= 10) {
                    handleInputFiled('PanCard', input);
                  }
                }}
                style={{
                  display: 'flex',
                  height: '36px',
                  padding: '8px',
                  alignItems: 'center',
                  gap: '8px',
                  borderRadius: '6px',
                  border: '1px solid #D9D9D9',
                  background: '#FFF',
                  width: '100%',
                }}
                onBlur={(e) => {
                  const parentElement = e.target.parentNode as HTMLElement;
                  if (parentElement) {
                    parentElement.style.borderColor = '#D9D9D9';
                  }
                }}
                className="comms-font-size-regular input"
                placeholder="Search"
                inputProps={{ style: { height: '32px', padding: 0 } }}
                endAdornment={
                  <InputAdornment
                    position="end"
                    style={{ marginRight: 10, cursor: 'pointer' }}
                  >
                    <img
                      src={
                        gstFormData?.PanCard.length >= 10
                          ? SVGS?.ActiveIcon
                          : SVGS?.InactiveIcon
                      }
                      alt="input-status"
                    />
                  </InputAdornment>
                }
              />
              {kycError?.PanNo && (
                <div
                  style={{
                    color: 'red',
                    fontSize: '12px',

                    marginBottom: '4px',
                    fontFamily: 'Segoe-UI',
                  }}
                >
                  {kycError?.PanNo}
                </div>
              )}
            </div>
            {noGst && (
              <div className="each-form">
                <label>GST</label>
                <Input
                  disableUnderline
                  onFocus={(e) => {
                    e.target.style.borderColor = '#D9D9D9';
                  }}
                  value={gstFormData?.GstInNo}
                  onChange={(e) => {
                    const GstInNoEvent = e.target.value;
                    if (GstInNoEvent.length <= 15) {
                      handleInputFiled('GstInNo', e.target.value);
                    }
                  }}
                  style={{
                    display: 'flex',
                    height: '36px',
                    padding: '8px',
                    alignItems: 'center',
                    gap: '8px',
                    borderRadius: '6px',
                    border: '1px solid #D9D9D9',
                    background: '#FFF',
                    width: '100%',
                  }}
                  onBlur={(e) => {
                    const parentElement = e.target.parentNode as HTMLElement;
                    if (parentElement) {
                      parentElement.style.borderColor = '#D9D9D9';
                    }
                  }}
                  className="comms-font-size-regular input"
                  placeholder="Search"
                  inputProps={{ style: { height: '32px', padding: 0 } }}
                  endAdornment={
                    <InputAdornment
                      position="end"
                      style={{ marginRight: 10, cursor: 'pointer' }}
                    >
                      <img
                        src={
                          gstFormData?.GstInNo.length >= 15
                            ? SVGS?.ActiveIcon
                            : SVGS?.InactiveIcon
                        }
                        alt="input-status"
                      />
                    </InputAdornment>
                  }
                />
                {errorMsg && (
                  <div
                    style={{
                      color: 'red',
                      fontSize: '12px',

                      marginBottom: '4px',
                      fontFamily: 'Segoe-UI',
                    }}
                  >
                    {errorMsg}
                  </div>
                )}
              </div>
            )}
            {noGst && (
              <div className="each-form">
                <label>Firm Address</label>
                <Input
                  disableUnderline
                  onFocus={(e) => {
                    e.target.style.borderColor = '#D9D9D9';
                  }}
                  style={{
                    display: 'flex',
                    height: '36px',
                    padding: '8px',
                    alignItems: 'center',
                    gap: '8px',
                    borderRadius: '6px',
                    border: '1px solid #D9D9D9',
                    background: '#FFF',
                    width: '100%',
                  }}
                  onBlur={(e) => {
                    const parentElement = e.target.parentNode as HTMLElement;
                    if (parentElement) {
                      parentElement.style.borderColor = '#D9D9D9';
                    }
                  }}
                  value={gstFormData?.address}
                  onChange={(e) => {
                    handleInputFiled('address', e.target.value);
                  }}
                  className="comms-font-size-regular input"
                  placeholder="Enter "
                  inputProps={{ style: { height: '32px', padding: 0 } }}
                  endAdornment={
                    <InputAdornment
                      position="end"
                      style={{ marginRight: 10, cursor: 'pointer' }}
                    >
                      <img
                        src={
                          gstFormData?.address
                            ? SVGS?.ActiveIcon
                            : SVGS?.InactiveIcon
                        }
                        alt="input-status"
                      />
                    </InputAdornment>
                  }
                />
              </div>
            )}
            <div className="footer-button-container-kyc-form">
              <button
                className="submit-kyc-button"
                type="button"
                onClick={handleNext}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
