import { ColumnConfig, PrincipalReportsColumnKey } from '../../model';

export const principalReportsConfig: Record<
  PrincipalReportsColumnKey,
  ColumnConfig
> = {
  sNo: {
    name: 'S.No',
    alignment: 'flex-end',
  },
  orderDate: {
    name: 'Date',
    sortField: 'orderDate',
  },
  tags: {
    name: 'Tags',
  },
  cfa: {
    name: 'CFAs',
  },
  skuCode: {
    name: 'SKU Code',
  },
  productTitle: {
    name: 'Product Title',
    field: 'component',
  },
  orders: {
    name: 'orders',
    sortField: 'orders',
    alignment: 'flex-end',
  },
  orderQty: {
    name: ' Ord. Qty',
    sortField: 'ordQty',
    alignment: 'flex-end',
  },
  orderAmt: {
    name: 'Ord Amt',
    sortField: 'ordAmt',
    alignment: 'flex-end',
  },
};
