import { BulkImportsColumnKey, ColumnConfig } from '../model';

export const bulkImportsConfig: Record<BulkImportsColumnKey, ColumnConfig> = {
  sNO: {
    name: 'S.No',
  },
  taskType: {
    name: 'Task Type',
    field: 'taskType',
  },
  fileName: { name: 'File Name', field: 'textWithImage', isHyperlink: true },
  timeOfUpload: { name: 'Time Of Upload' },
  noOfRecords: {
    name: 'No.of.Records',
  },
  processed: {
    name: 'Processed',
  },
  notProcessedRecords: {
    name: 'Not Processed',
  },
  uploaded: {
    name: 'Successful',
  },
  failed: {
    name: 'Failed',
  },
  taskStatus: {
    name: 'Task Status',
    field: 'component',
  },
};
