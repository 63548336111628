import { SVGS } from '@zonofi/zono-money-design-system';
import React, { useState } from 'react';
import './product-details-image-gallery.scss';
interface ProductDetailsImageGalleryProps {
  images: string[];
  setShowImageGallery: React.Dispatch<React.SetStateAction<boolean>>;
}
export const ProductDetailsImageGallery: React.FC<
  ProductDetailsImageGalleryProps
> = ({ images, setShowImageGallery }) => {
  const [currentImage, setCurrentImage] = useState(0);

  const getModifiedImageUrl = (url: string) => {
    try {
      const urlObj = new URL(url);
      urlObj.searchParams.set('width', '1024');
      urlObj.searchParams.set('height', '1024');
      return urlObj.toString();
    } catch {
      return url;
    }
  };
  return (
    <div className="product-gallery" onClick={() => setShowImageGallery(true)}>
      <div className="product-gallery-images">
        <img
          src={getModifiedImageUrl(images[currentImage]) || SVGS.DefaultProduct}
          alt=""
          onError={(e) => {
            e.currentTarget.onerror = null;
            e.currentTarget.src = SVGS.DefaultProduct;
          }}
        />
      </div>

      <div className="image-dots">
        {images.length > 1 &&
          images.map((_, index) => (
            <button
              key={index}
              className={`dot ${currentImage === index ? 'active' : ''}`}
              onClick={() => setCurrentImage(index)}
            />
          ))}
      </div>
    </div>
  );
};
