import { Button } from '@mui/material';
import { getFormattedAmount } from '@zonofi/common';
import { ThemeContext } from '@zonofi/shared';
import { LoadingSpinner, SVGS } from '@zonofi/zono-money-design-system';
import {
  CatalogListData,
  LineInOrder,
  SchemeTypes,
} from '@zonofi/zono-money-store';
import { isNaN } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';

interface ProductCatalogueProps {
  listData: CatalogListData[];
  btnDisabled: boolean;
  onDecrement: (item: LineInOrder) => any;
  onIncrement: (item: LineInOrder) => any;
  onAddToCart: (item: CatalogListData) => any;
  onDelete: (item: LineInOrder) => any;
  onChangeQuantity: (item: LineInOrder, newQty: number) => any;
  buttonText: string;
  showImage: boolean;
}

export const ProductCatalogue: React.FC<ProductCatalogueProps> = ({
  listData,
  btnDisabled,
  onAddToCart,
  onDecrement,
  onIncrement,
  onDelete,
  onChangeQuantity,
  buttonText,
  showImage,
}) => {
  const { theme } = useContext(ThemeContext);
  const [quantityMap, setQuantityMap] = useState<Record<number, any>>({});
  btnDisabled = false;

  useEffect(() => {
    let quantityMapData: Record<number, any> = {};
    listData?.forEach((item) => {
      if (item?.productVariantId) {
        quantityMapData[item?.productVariantId] = item?.addedQty;
      }
    });
    setQuantityMap({ ...quantityMapData });
  }, [listData]);

  return listData?.map((item, index) => {
    const disc = Number(item?.promotions?.[0]?.discount);
    const discType = item?.promotions?.[0]?.promotionType;

    let showStrikeThroughPrice = false;

    showStrikeThroughPrice =
      !isNaN(disc) &&
      discType !== 'buy_x_get_y_free' &&
      item?.isAddedToCart &&
      item?.addedQty >= item?.minOrderQty;

    return (
      <div
        className="product_item_list"
        key={index}
        style={theme.fontConfig.semiBold}
      >
        {showImage && (
          <img
            src={item?.productImage ? item?.productImage : SVGS.DefaultProduct}
            className="product_catalogue_item_default_img"
            alt=""
            onError={(e) => {
              e.currentTarget.onerror = null;
              e.currentTarget.src = SVGS.DefaultProduct;
            }}
          />
        )}
        <div className="product_item_details">
          <div className="product_item_name_section">
            <span className="product_item_sku">SKU: {item?.sku}</span>
            <span className="product_item_name">{item?.name}</span>
          </div>
          {/* <div className="product_item_info_section">
          <div>
            <span className="product_item_info">PTR: </span>
            <span className="product_item_ptr">
              {getFormattedAmount((item?.ptr ?? 0) / 100) ?? 0}
            </span>
          </div>
          <div>
            <span className="product_item_info">Margin: </span>
            <span className="product_item_margin">
              {getFormattedAmount((item?.margin ?? 0) / 100) ?? 0}
            </span>
          </div>
          <div>
            <span className="product_item_info">MOQ: </span>
            <span className="product_item_moq">{item?.minOrderQty ?? 0}</span>
          </div>
        </div> */}
          {/* <div className="product_item_divider" /> */}
          <div className="product_item_add_section">
            <div className="product_item_pts_section">
              <span className="product_item_info">Price: </span>

              {showStrikeThroughPrice ? (
                <div className="product_item_pts_striked_container">
                  <span className="product_item_pts_striked">
                    {getFormattedAmount((item?.pts ?? 0) / 100) ?? 0}
                  </span>
                  <span className="product_item_pts">
                    {getFormattedAmount(item?.discountedPrice ?? 0) ?? 0}
                  </span>
                </div>
              ) : (
                <span className="product_item_pts">
                  {getFormattedAmount((item?.pts ?? 0) / 100) ?? 0}
                </span>
              )}
            </div>
            <div className="product_add_to_cart">
              {item?.isAddedToCart ? (
                <div className="product_add_to_cart_qty_btn">
                  <img
                    src={SVGS.DeleteIcon}
                    onClick={() => onDelete(item?.lineInOrder)}
                  />
                  <Button
                    disableRipple
                    disableFocusRipple
                    disableElevation
                    sx={{ all: 'unset' }}
                    className={`${btnDisabled && ' qtn_btn_disabled '} qtn_btn`}
                  >
                    <img
                      src={SVGS.MinusPrimaryCyan}
                      onClick={() => onDecrement(item?.lineInOrder)}
                    />
                    {item?.updatingQty ? (
                      <LoadingSpinner size={15} />
                    ) : (
                      <input
                        className="product_add_to_cart_qty_input"
                        type="text"
                        inputMode="numeric"
                        pattern="[0-9]*[.,]?[0-9]+"
                        key={item?.productVariantId}
                        onKeyDown={(e) => {
                          e.key === 'Enter' &&
                            onChangeQuantity(
                              item?.lineInOrder,
                              quantityMap[item?.productVariantId]
                            );
                        }}
                        onBlur={() => {
                          onChangeQuantity(
                            item?.lineInOrder,
                            quantityMap[item?.productVariantId]
                          );
                        }}
                        onChange={(e) => {
                          if (!isNaN(Number(e.target.value))) {
                            let initialQuantiyMap = quantityMap;
                            if (item?.productVariantId) {
                              initialQuantiyMap[item?.productVariantId] =
                                Number(e.target.value);
                            }
                            setQuantityMap({ ...initialQuantiyMap });
                          }
                        }}
                        value={quantityMap[item?.productVariantId]}
                      />
                    )}
                    <img
                      src={SVGS.PlusPrimaryCyan}
                      onClick={() => onIncrement(item?.lineInOrder)}
                    />
                  </Button>
                </div>
              ) : (
                <Button
                  disableRipple
                  disableFocusRipple
                  disableElevation
                  sx={{ all: 'unset' }}
                  className={`${
                    btnDisabled && ' addToCart_disabled '
                  } addToCart`}
                  onClick={() => onAddToCart(item)}
                >
                  {item?.updatingQty ? (
                    <LoadingSpinner size={15} />
                  ) : (
                    buttonText
                  )}
                </Button>
              )}
            </div>
          </div>
          {item?.moqError && (
            <span
              style={theme.fontConfig.regular}
              className="catalog_moq_error"
            >
              Qty cannot be less than MOQ
            </span>
          )}
          {item?.promotions && item?.promotions?.length !== 0 && (
            <div>
              <span className="product_item_offer_text">
                {item?.promotions?.[0]?.promotionType === SchemeTypes.BUYXGETY
                  ? `Buy ${item?.promotions?.[0]?.minQtyASC} Qty, get ${item?.promotions?.[0]?.freeQtyASC} free`
                  : `Buy ${item?.promotions?.[0]?.minQtyASC} Qty, get ${item?.promotions?.[0]?.discount} % off`}
              </span>
            </div>
          )}
        </div>
      </div>
    );
  });
};
