import { AssignedCustomersColumnKey, ColumnConfig } from '../model';

export const assignedCustomersConfig: Record<
  AssignedCustomersColumnKey,
  ColumnConfig
> = {
  customers: {
    name: 'Customers',
    isComponent: true,
  },
  roleAndExpiryDate: {
    name: 'Role / Expiry Date',
    isComponent: true,
  },
};
