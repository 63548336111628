import { LoadingSpinner } from '@zonofi/zono-money-design-system';
import React from 'react';
import { useReorderScreen } from './hooks/userReorderScreen';
import './reorder-screen.scss';
export const ReorderScreen: React.FC = () => {
  useReorderScreen();
  return (
    <div className="reorder-screen-container">
      <LoadingSpinner />
    </div>
  );
};
