import React, { useContext, useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { LoadingSpinner, SVGS } from '@zonofi/zono-money-design-system';
import {
  CatalogListData,
  LineInOrder,
  SchemeTypes,
} from '@zonofi/zono-money-store';
import './catalogue.scss';
import { ThemeContext } from '@zonofi/shared';

interface ProductCatalogueWithoutPriceProps {
  listData: CatalogListData[];
  btnDisabled: boolean;
  onDecrement: (item: LineInOrder) => any;
  onIncrement: (item: LineInOrder) => any;
  onAddToCart: (item: CatalogListData) => any;
  onDelete: (item: LineInOrder) => any;
  onChangeQuantity: (item: LineInOrder, newQty: number) => any;
  buttonText: string;
  showImage: boolean;
}

export const ProductCatalogueWithoutPrice: React.FC<
  ProductCatalogueWithoutPriceProps
> = ({
  listData,
  btnDisabled,
  onAddToCart,
  onDecrement,
  onIncrement,
  onDelete,
  onChangeQuantity,
  buttonText,
  showImage,
}) => {
  const { theme } = useContext(ThemeContext);
  const [quantityMap, setQuantityMap] = useState<Record<number, any>>({});
  btnDisabled = false;

  useEffect(() => {
    let quantityMapData: Record<number, any> = {};
    listData?.forEach((item) => {
      if (item?.productVariantId) {
        quantityMapData[item?.productVariantId] = item?.addedQty;
      }
    });
    setQuantityMap({ ...quantityMapData });
  }, [listData]);

  return listData?.map((item) => {
    return (
      <div className="catalog_item_list" key={item?.sku}>
        {showImage && (
          <img
            src={item?.productImage ? item?.productImage : SVGS.DefaultProduct}
            className="product_catalogue_item_default_img"
            alt=""
            onError={(e) => {
              e.currentTarget.onerror = null;
              e.currentTarget.src = SVGS.DefaultProduct;
            }}
          />
        )}
        <div className="product_catalog_without_price_item_list">
          <div className="left">
            <p className="sku">SKU: {item?.sku}</p>
            <h4 className="title">{item?.name}</h4>
            {item?.promotions && item?.promotions?.length !== 0 && (
              <div>
                <span className="product_item_offer_text">
                  {item?.promotions?.[0]?.promotionType === SchemeTypes.BUYXGETY
                    ? `Buy ${item?.promotions?.[0]?.minQtyASC} Qty, get ${item?.promotions?.[0]?.freeQtyASC} free`
                    : `Buy ${item?.promotions?.[0]?.minQtyASC} Qty, get ${item?.promotions?.[0]?.discount} % off`}
                </span>
              </div>
            )}
          </div>
          <div className="right">
            {item?.isAddedToCart ? (
              <div className="right_btn">
                <img
                  src={SVGS.DeleteIcon}
                  onClick={() => onDelete(item?.lineInOrder)}
                />
                <Button
                  disableRipple
                  disableFocusRipple
                  disableElevation
                  sx={{ all: 'unset' }}
                  className={`${btnDisabled && ' qtn_btn_disabled '} qtn_btn`}
                >
                  <img
                    src={SVGS.MinusPrimaryCyan}
                    onClick={() => onDecrement(item?.lineInOrder)}
                  />
                  {item?.updatingQty ? (
                    <LoadingSpinner size={15} />
                  ) : (
                    <input
                      className="product_add_to_cart_qty_input"
                      type="text"
                      inputMode="numeric"
                      pattern="[0-9]*[.,]?[0-9]+"
                      key={item?.productVariantId}
                      onKeyDown={(e) => {
                        e.key === 'Enter' &&
                          onChangeQuantity(
                            item?.lineInOrder,
                            quantityMap[item?.productVariantId]
                          );
                      }}
                      onBlur={() => {
                        onChangeQuantity(
                          item?.lineInOrder,
                          quantityMap[item?.productVariantId]
                        );
                      }}
                      onChange={(e) => {
                        if (!isNaN(Number(e.target.value))) {
                          let initialQuantiyMap = quantityMap;
                          if (item?.productVariantId) {
                            initialQuantiyMap[item?.productVariantId] = Number(
                              e.target.value
                            );
                          }
                          setQuantityMap({ ...initialQuantiyMap });
                        }
                      }}
                      value={quantityMap[item?.productVariantId]}
                    />
                  )}
                  <img
                    src={SVGS.PlusPrimaryCyan}
                    onClick={() => onIncrement(item?.lineInOrder)}
                  />
                </Button>
              </div>
            ) : (
              <div className="right_btn">
                <Button
                  disableRipple
                  disableFocusRipple
                  disableElevation
                  sx={{ all: 'unset' }}
                  className={`${
                    btnDisabled && ' addToCart_disabled '
                  } addToCart`}
                  onClick={() => onAddToCart(item)}
                >
                  {item?.updatingQty ? (
                    <LoadingSpinner size={15} />
                  ) : (
                    buttonText
                  )}
                </Button>
              </div>
            )}
          </div>
        </div>
        {item?.moqError && (
          <span style={theme.fontConfig.regular} className="catalog_moq_error">
            Qty cannot be less than MOQ
          </span>
        )}
      </div>
    );
  });
};
