import { ColumnConfig, PrincipalProductColumnKey } from '../../model';

export const principalProductConfig: Record<
  PrincipalProductColumnKey,
  ColumnConfig
> = {
  productWithImage: {
    name: 'Product Title',
    field: 'component',
  },
  skuCode: {
    name: 'SKU Code',
    isHyperlink: true,
    headerExtra: {
      type: 'info',
      details:
        'A unique identification number for each product in any alphanumeric format.',
      title: 'SKU Code',
    },
  },
  status: { name: 'Status' },
  productTitle: { name: 'Product Title', field: 'multiLine' },
  categories: { name: 'Categories', field: 'component' },
  scheme: { name: 'Scheme' },
  taxCategory: { name: 'Tax Category' },
  // pts: { name: 'PTS', sortField: 'price', alignment: 'flex-end' },
  ptr: { name: 'PTR', alignment: 'flex-end' },
  // margin: { name: 'Margin', alignment: 'flex-end' },
  packSize: { name: 'Pack Size', alignment: 'flex-end' },
  action: {
    name: '',
    field: 'component',
  },
};
