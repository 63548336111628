import React from 'react';
import './fallback-screen.scss';
import { ZoButton } from '@zonofi/zo-components';
import { FallBackScreenProps } from '@zonofi/zono-money-helpers';

export const FallBackScreen: React.FC<FallBackScreenProps> = ({
  image,
  message,
  description,
  buttonName,
  onButtonClick,
}) => {
  return (
    <div className="fallback-screen-container">
      <div className="fallback-content">
        <img
          className="fallback-content-img"
          src={image}
          alt="fallbackImage"
          width={'172px'}
        />

        <div className="fallback-content-body">
          <div className="fallback-content-body-message">
            <div className="font-primary-semibold fallback-content-body-message-header">
              {message}
            </div>
            <div className="font-primary fallback-content-body-message-description">
              {description}
            </div>
          </div>
          {buttonName && onButtonClick && (
            <ZoButton
              label={buttonName}
              onClick={onButtonClick}
              className="fallback-content-body-button font-primary-semibold"
              style={{backgroundColor: "#141414"}}
            />
          )}
        </div>
      </div>
    </div>
  );
};
