import { useEffect, useState } from 'react';
import { FetchSchemesParams, FetchSchemesSearchParams, Scheme } from '../model';
import { useAppSelector } from '../../root';
import {
  ListInputItemOptions,
  StringInputItem,
  StringInputItemOptions,
  getAppliedFilters,
} from '../../filter';
import moment from 'moment';
import { getSelectedPrincipalAccount } from '../../user-preference';
import { getSelectedFilterChip } from '../slice';
import { getWorkspaceId } from '../../workspace';
import { useGetLoggedInUserType } from '../../helpers';

interface useGetSchemesListProps {
  fetchSchemes: (
    options: FetchSchemesParams | FetchSchemesSearchParams
  ) => void;
  fetchSchemesResponse: any;
  pageNo: number;
  resetPageNo: () => void;
  searchKey?: string;
  isSearchPaginated?: boolean;
}
export const useGetSchemesList = ({
  fetchSchemes,
  fetchSchemesResponse,
  pageNo,
  resetPageNo,
  searchKey = '',
  isSearchPaginated = false,
}: useGetSchemesListProps) => {
  const selectedPrincipal = useAppSelector(getSelectedPrincipalAccount);
  const appliedFilters = useAppSelector(getAppliedFilters);
  const selectedFilterChip = useAppSelector(getSelectedFilterChip);
  const workspaceId = useAppSelector(getWorkspaceId);
  const loggedInUserType = useGetLoggedInUserType();
  const isSalesOrPrincipal = loggedInUserType !== 'distributor';
  const selectedSortField = appliedFilters?.sortField;
  const pageSize = 20;
  const [schemesData, setSchemesData] = useState<Scheme[]>([]);

  const dateSelectionRange = {
    startDate: new Date(moment().subtract(30, 'days').format('YYYY-MM-DD')),
    endDate: new Date(moment().format('YYYY-MM-DD')),
  };

  const getData = (
    filter: {
      promotionType: string;
      status: string;
    },
    divisionFilter: string[],
    cfaFilter: string[],
    skuCode: string,
    startDate?: Date,
    endDate?: Date
  ) => {
    if (selectedSortField.field === 'freeQty') {
      filter.promotionType = 'buy_x_get_y_free';
    } else if (selectedSortField.field === 'discount') {
      filter.promotionType = 'product_discount';
    }
    fetchSchemes({
      principalWorkspaceId: isSalesOrPrincipal
        ? workspaceId
        : selectedPrincipal?.principalWorkspaceId || '',
      params: {
        customerId: selectedPrincipal?.inviteId || '',
        pageNo: pageNo,
        pageSize: pageSize || 0,
        skuCode: skuCode,
        sortDirection: selectedSortField.field
          ? selectedSortField.direction
          : '',
        sortBy: selectedSortField.field,
        startDate: moment(startDate?.toString()).format('YYYY-MM-DD'),
        endDate: moment(endDate?.toString()).format('YYYY-MM-DD'),
        dispatchFilters:
          Object.keys(appliedFilters)?.length === 0 ? true : false,
        body: {
          cfaFilter: cfaFilter,
          divisionFilter: divisionFilter,
        },
        ...filter,
      },
    });
  };

  const getSelectedIds = (key: ListInputItemOptions): string[] => {
    const listInputItem = appliedFilters?.listInputItem;
    const selectedFilters = listInputItem && Object.entries(listInputItem);
    const found = selectedFilters?.find(([k]) => k === key);
    return found
      ? found[1]
          ?.filter((x: { selected: any }) => x.selected)
          ?.map((x: { id: any }) => x.id)
      : [];
  };

  const applySchemeFilters = () => {
    const { listInputItem, calendarInputItem, stringInputItem } =
      appliedFilters || {};
    const selectedDateFilters = calendarInputItem
      ? Object.values(calendarInputItem || {}).reduce(
          (acc: string[], curr) => acc.concat(curr),
          []
        )
      : [];
    const startDate = selectedDateFilters?.[0]
      ? dateSelectionRange?.startDate
      : moment().subtract(30, 'days').toDate();
    const endDate = selectedDateFilters?.[0]
      ? dateSelectionRange?.endDate
      : moment().toDate();

    const selectedDivisions = getSelectedIds(ListInputItemOptions.divisions);
    const selectedCFAs = getSelectedIds(ListInputItemOptions.cfa);
    const selectedStatus = getSelectedIds(
      ListInputItemOptions.schemeActiveStatus
    )[0];
    const selectedSchemeType = getSelectedIds(
      ListInputItemOptions.schemeType
    )[0];

    const filterResult = {
      status: selectedStatus
        ? selectedStatus
        : selectedFilterChip === 'All'
        ? ''
        : selectedFilterChip?.toUpperCase().replace(' ', '_'),
      promotionType: selectedSchemeType,
    };

    const skuCode = appliedFilters?.stringInputItem
      ? (appliedFilters?.stringInputItem as StringInputItem)[
          StringInputItemOptions.skuCode
        ]
      : '';

    if (!listInputItem || (!stringInputItem && !skuCode)) {
      getData(filterResult, [], [], skuCode, startDate, endDate);
    } else {
      getData(
        filterResult,
        selectedDivisions,
        selectedCFAs || [],
        skuCode,
        startDate,
        endDate
      );
    }
  };

  const getSearchedSchemes = (searchKey: string) => {
    fetchSchemes({
      principalWorkspaceId:
        selectedPrincipal?.principalWorkspaceId || workspaceId,
      params: {
        pageNo: pageNo,
        pageSize: 20,
        searchKey: searchKey || '',
      },
    });
  };

  useEffect(() => {
    if (pageNo !== 1) {
      resetPageNo && resetPageNo();
    }
  }, [appliedFilters, selectedFilterChip]);

  useEffect(() => {
    if (searchKey === '') {
      applySchemeFilters();
    } else {
      getSearchedSchemes(searchKey);
    }
  }, [
    pageNo,
    selectedSortField,
    searchKey,
    appliedFilters,
    selectedFilterChip,
  ]);

  useEffect(() => {
    if (searchKey && isSearchPaginated) resetPageNo();
  }, [searchKey]);

  useEffect(() => {
    if (fetchSchemesResponse?.data && fetchSchemesResponse?.data?.promotions) {
      setSchemesData(fetchSchemesResponse?.data?.promotions);
    }
  }, [fetchSchemesResponse]);

  return {
    schemesData,
    totalSchemes: fetchSchemesResponse?.data?.totalRecords,
  };
};
