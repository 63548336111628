export const getQueryParams = (params: any) => {
  const queryParams = Object.keys(params)
    .map((key) => {
      const qString: string[] = [];
      //@ts-ignore
      if (Array.isArray(params[key])) {
        //@ts-ignore
        for (let index = 0; index < params[key].length; index++) {
          //@ts-ignore
          const value = params[key][index];
          qString.push(
            `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
          );
        }
        return qString.join('&');
      }
      return `${encodeURIComponent(key)}=${encodeURIComponent(
        //@ts-ignore
        params[key]
      )}`;
    })
    .join('&');
  return queryParams;
};
