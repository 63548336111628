import { getConfigValue } from '@zonofi/common';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

declare global {
  interface Window {
    FB: any;
    fbAsyncInit: () => void;
  }
}

export const FacebookScreen: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const title = 'Zotok Campaign';
  const description = 'Description about Campaign';
  const location = useLocation();
  const campaignId = location.pathname.split('/')[3];
  const queryParams = new URLSearchParams(location.search);
  const name = queryParams.get('name');
  
  const wapp_platform_number = getConfigValue('WAPP_PLATFORM_NUMBER');
  
  React.useEffect(() => {
    window.location.href = `https://api.whatsapp.com/send/?phone=${encodeURIComponent(
      wapp_platform_number || ''
    )}&text=Hi I would like to know more about the campaign ${name}`;
  }, [campaignId]);
  return (
    <div>
      <Helmet>
        <title>{title || 'Default Title'}</title>
        <meta
          name="description"
          content={description || 'Default description'}
        />
        <meta
          property="og:url"
          content="https://app-qa.zono.digital/admin/thread"
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title || 'Default Title'} />
        <meta
          property="og:description"
          content={description || 'Default description'}
        />

        <meta property="og:image:type" content="image/*" />
        <meta property="og:quote" content="quote description" />
        <meta property="og:hashtag" content="#defaultHashtag" />
      </Helmet>
    </div>
  );
};
