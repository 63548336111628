// @ts-nocheck
import { RootState, store } from '../root';
import { ImportAdapter } from './slice';

export const importSelectors = ImportAdapter.getSelectors(
  (state: RootState) => state.imports
);

export const importsNativeSelectors = ImportAdapter.getSelectors();

const getImportsState = () => store.getState().imports;

export const getAllImports = (imports = getImportsState()) => {
  console.log(
    'getAllImports',
    imports,
    importsNativeSelectors.selectAll(imports)
  );
  return importsNativeSelectors.selectAll(imports);
};

export const getAllImportIds = (imports = getImportsState()) => {
  return importSelectors.selectIds(imports);
};

export const getImportById = (id: any, imports = getImportsState()) => {
  return importSelectors.selectById(imports, id);
};

export const getImportCount = (imports = getImportsState()) => {
  return importSelectors.selectTotal(imports);
};
