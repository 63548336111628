import React, { useMemo, useState } from 'react';
import { CategoryTag } from '../catalog-list/category-tag';
import { isNumber } from 'lodash';
import { ProductPromotion, ProductVariantCategory, SchemeTypes } from '@zonofi/zono-money-store';
import './product-details-content.scss';
import { SVGS } from '@zonofi/zono-money-design-system';
interface ProductDetailsContentProps {
  productDetails: {
    title: string;
    price: number;
    tags: ProductVariantCategory[];
    description: string;
    promotions: ProductPromotion[];
    details: {
      ptr: string;
      margin: string;
      moq: number;
    };
    images: string[];
    itemInCart?: {
      quantity: number;
      orderId: number;
    };
    productVariantId: number;
  };
  hidePrice: boolean;
  hideImages: boolean;
}
export const ProductDetailsContent: React.FC<ProductDetailsContentProps> = ({
  productDetails,
  hidePrice,
  hideImages,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  // Truncate the description to 300 characters
  const truncatedDescription = useMemo(
    () =>
      productDetails.description.length > 300
        ? productDetails.description.slice(0, 300) + '...'
        : productDetails.description,
    [productDetails]
  );
  const isPtrGraterThanOne = useMemo(
    () =>
      isNumber(Number(productDetails.details.ptr)) &&
      Number(productDetails.details.ptr) > 1,
    [productDetails]
  );
  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };
  return (
    <div className={'product-details-content'}>
      <div
        className={`product-details-content-info ${
          hideImages ? 'append-margin' : ''
        }`}
      >
        <div className="product-title-description">
          <div className="product-header">
            <div className="product-details-categories">
              {productDetails.tags.map((category, index) => (
                <CategoryTag key={index} category={category} />
              ))}
            </div>
            <div className="product-title font-primary-semibold">
              {productDetails.title}
            </div>
            {productDetails?.promotions && productDetails?.promotions?.length !== 0 && (
              <div className="catalog-list-item-promotion">
                <img src={SVGS.DiscountIcon} alt="discount" />
                <span className="catalog-list-item-promotion-text font-primary-semibold">
                  {productDetails?.promotions?.[0]?.promotionType === SchemeTypes.BUYXGETY
                    ? `Buy ${productDetails?.promotions?.[0]?.minQtyASC} Qty, get ${productDetails?.promotions?.[0]?.freeQtyASC} free`
                    : `Buy ${productDetails?.promotions?.[0]?.minQtyASC} Qty, get ${productDetails?.promotions?.[0]?.discount} % off`}
                </span>
              </div>
            )}
            <div className="product-price font-primary-bold">
              {hidePrice ? '-' : `₹${productDetails.price.toFixed(2)}`}
            </div>
          </div>

          <div className="description">
            <div className="description-header font-primary-semibold">
              DESCRIPTION
            </div>
            <div className="font-primary product-description">
              {isExpanded ? productDetails.description : truncatedDescription}
              {productDetails.description.length > 300 && (
                <span
                  onClick={toggleReadMore}
                  className="read-more font-primary"
                >
                  {isExpanded ? 'Read Less' : 'Read More'}
                </span>
              )}
            </div>
          </div>
        </div>

        <div className="product-other-details">
          <div className="other-details-header font-primary-semibold">
            OTHER DETAILS
          </div>
          <div className="other-details-wrapper">
            {!hidePrice && isPtrGraterThanOne && (
              <div className="details-row-top">
                <div className="font-primary price-container">
                  <span className="font-primary price-category">PTR</span>
                  <span className="value font-primary-semibold">
                    ₹ {productDetails.details.ptr}
                  </span>
                </div>
                <div className="price-container">
                  <span className="font-primary price-category">Margin</span>
                  <span className="value font-primary-semibold">
                    ₹ {productDetails.details.margin}
                  </span>
                </div>
              </div>
            )}
            <div className="details-row-bottom">
              <div className="price-container">
                <span className="font-primary price-category">MOQ</span>
                <span className="value font-primary-semibold">
                  {productDetails.details.moq}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
