import { BatchNumberLookupColumnKey, ColumnConfig } from '../model';

export const batchNumberLookupConfig: Record<
  BatchNumberLookupColumnKey,
  ColumnConfig
> = {
  batchNumber: {
    name: 'Batch Number',
    field: 'component',
  },
  productTitle: {
    name: 'Product Title & SKU Code',
    field: 'multiLine',
  },
  pts: {
    name: 'PTS',
  },
  expiryDate: {
    name: 'Expiry Date',
  },
  category: {
    name: 'Category',
  },
  returnQty: {
    name: 'Return Qty',
  },
  status: {
    name: '',
    alignment: 'flex-end',
  },
};
