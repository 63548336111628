export const convertToCamelCase = (snakeCase: string) => {
  return snakeCase
    .toLowerCase()
    .replace(/-([a-z])/g, (_, group1) => group1.toUpperCase());
};

export const convertCamelCaseToRegularCase = (name: string) => {
  let value;
  let name__ = name;

  if (name.includes('-')) {
    name__ = convertToCamelCase(name);
  }
  if (name__.includes('_')) {
    value = name__?.replace(/^_*(.)|_+(.)/g, (s, c, d) =>
      c ? c.toUpperCase() : ' ' + d.toUpperCase()
    );
  } else {
    const name_ = name__?.replace(/([A-Z])/g, ' $1');
    value = name_.charAt(0).toUpperCase() + name_.slice(1);
  }
  return value;
};

export const convertKebabToRegular = (str: string) => {
  return str
    .split('_') // Split the kebab-case string into words
    .map(
      (word, index) =>
        index === 0 ? word.charAt(0).toUpperCase() + word.slice(1) : word // Capitalize the first word
    )
    .join(' '); // Join the words with spaces
};
