import { ColumnConfig, MappingReportColumnKey } from '../model';

export const mappingReportConfig: Record<MappingReportColumnKey, ColumnConfig> =
  {
    sellername: {
      name: 'Seller Name',
    },
    buyername: {
      name: 'Buyer Name',
    },
    importsource: {
      name: 'Import Source',
    },
    orderid: {
      name: 'Order Id',
    },
    ordernumber: {
      name: 'Order Number',
    },
    uploadedproductname: {
      name: 'Uploaded Product',
    },
    mappedproductname: {
      name: 'Mapped Product',
    },
    skucode: {
      name: 'Sku Code',
    },
    mappingstatus: {
      name: 'Mapping Status',
    },
    errormessage: {
      name: 'Error Message',
    },
    orderstatus: {
      name: 'Order Status',
    },
    sellercode: {
      name: 'Seller Code',
    },
    
    buyercode: {
      name: 'Buyer Code',
    },
    packing: {
      name: 'Packing',
    },
    uuid: {
      name: 'UUID',
    },
    mappingendtime: {
      name: 'Mapping End Time',
    },
    mappingstarttime: {
      name: 'Mapping Start Time',
    },
    uploadedproductqty: {
      name: 'Uploaded Product Qty',
    },

    mappedproductqty: {
      name: 'Mapped Product Qty',
    },
  };
