import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  Customers,
  ReminderPayload,
  CustomerListResponse,
  InviteIds,
  CustomerPartyAccountBook,
  ReminderRecord,
  OutStandingCustomers,
  LedgerSearchFilterInputs,
  CustomerAccountDetails,
  CustomerPaymentInvoices,
  Visits,
  MessageHistory,
  CustomerSummaryResponse,
  CustomerAssignedDetail,
  CustomerSearchResponse,
  FieldErrorStates,
  FieldErrorStateKeys,
  CfaData,
  CheckinCustomer,
} from './model';
import { CustomerInvoicesResponse } from '../invoice/model';
import { RootState } from '../root';
import moment from 'moment-timezone';
import _ from 'lodash';
import { customersApi } from './api';
import { LoaderStateKeys, LoaderStates } from '../division';

export const CUSTOMER_FEATURE_KEY = 'customerData';

const defaultFilterInfo = {
  startDate: moment().startOf('month').format('YYYY-MM-DD').toString(),
  endDate: moment().endOf('month').format('YYYY-MM-DD').toString(),
  type: 'This Month',
};

export enum CustomerState {
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  IDLE = 'IDLE',
}
export interface CustomersState {
  isAllCustomersTab: boolean;
  customers: Customers;
  customerSummary: CustomerSummaryResponse[];
  remindersData: ReminderRecord[];
  reminderPayload: ReminderPayload | null;
  outStandingCustomers: OutStandingCustomers;
  customerUpdateState: CustomerState;
  customerState: CustomerState;
  ledgerSearchFilterInputs: LedgerSearchFilterInputs;
  invoiceFilterInputs: LedgerSearchFilterInputs;
  paymentFilterInputs: LedgerSearchFilterInputs;
  searchedCustomer: Customers;
  totalCount: number | null;
  customerFilterInfo: CustomerListResponse;
  isSelectAllCustomers: boolean;
  isSelectAllReminders: boolean;
  selectedCustomerIds: InviteIds[];
  inviteId: string | null;
  filterCustomerData: Customers;
  filterSearchedCustomer: Customers;
  divsionSortDirection: string;
  allAssignedCustomers: CustomerAssignedDetail[];
  checkinCustomer: CheckinCustomer | null;
  sellerCustomerDetail: CustomerSearchResponse | null;
  fieldError: FieldErrorStates;
  selectedCfasDivision: CfaData[];
  showLoaderState: LoaderStates;
}

export const initialCustomerState: CustomersState = {
  reminderPayload: null,
  customers: {},
  customerSummary: [],
  filterCustomerData: {},
  filterSearchedCustomer: {},
  remindersData: [],
  outStandingCustomers: [],
  searchedCustomer: {},
  totalCount: null,
  customerUpdateState: CustomerState.IDLE,
  customerState: CustomerState.IDLE,
  isAllCustomersTab: true,
  isSelectAllCustomers: false,
  isSelectAllReminders: false,
  customerFilterInfo: {
    startRecord: null,
    endRecord: null,
    totalCount: null,
  },
  selectedCustomerIds: [],
  inviteId: null,
  ledgerSearchFilterInputs: {
    startDate: moment().startOf('month').format('YYYY-MM-DD').toString(),
    endDate: moment().endOf('month').format('YYYY-MM-DD').toString(),
    type: 'This Month',
    searchKey: '',
    filter: '',
  },
  invoiceFilterInputs: defaultFilterInfo,
  paymentFilterInputs: defaultFilterInfo,
  divsionSortDirection: 'DESC',
  allAssignedCustomers: [],
  checkinCustomer: null,
  sellerCustomerDetail: null,
  fieldError: {},
  selectedCfasDivision: [],
  showLoaderState: {
    firmLoader: false,
    licenseLoader: false,
    saveLoader: false,
    removeLoader: false,
    physicalLoader: false,
    shippingLoader: false,
  },
};

export const customersSlice = createSlice({
  name: CUSTOMER_FEATURE_KEY,
  initialState: initialCustomerState,
  reducers: {
    addCustomerList(state, action: PayloadAction<CustomerListResponse>) {
      const customers = action.payload.customers;
      customers?.forEach((constomer) => {
        state.customers[constomer.id] = constomer;
      });
      state.totalCount = action.payload.totalCount;
    },
    updateCustomerSelectedTab(state, action: PayloadAction<boolean>) {
      state.isAllCustomersTab = action.payload;
    },
    getOutstandingCustomerList(
      state,
      action: PayloadAction<CustomerInvoicesResponse>
    ) {
      const customers = action.payload.customers;
      const ids = state.selectedCustomerIds.map((x) => x.inviteId);
      const updatedCustomers = customers.map((customer) => {
        return {
          ...customer,
          id: customer.inviteId,
          cityName: customer.city,
          distributorCode: customer.customerCode,
          lastPaymentDate: customer?.lastPayment?.paymentDate,
          dueDate: customer?.dueInvoice?.dueDate,
          isSelected: ids.includes(customer.inviteId),
        };
      });
      state.isSelectAllCustomers = updatedCustomers.every(
        (x) => x.isSelected === true
      );

      //@ts-ignore
      state.outStandingCustomers = updatedCustomers;
    },
    addRemindersData(state, action: PayloadAction<ReminderRecord[]>) {
      const data = action.payload;
      const updatedRecords = data.map((reminder) => {
        return {
          ...reminder,
          isSelected: false,
        };
      });
      state.remindersData = updatedRecords;
    },
    updateReminderPayload(state, action: PayloadAction<ReminderPayload>) {
      state.reminderPayload = {
        ...state.reminderPayload,
        ...action.payload,
      };
    },
    getSearchedList(state, action: PayloadAction<CustomerListResponse>) {
      state.searchedCustomer = {};
      const customers = action.payload.customers;
      customers?.forEach((customer) => {
        state.searchedCustomer[customer.id] = customer;
        state.customers[customer.id] = customer;
      });
      state.customerFilterInfo = action.payload;
    },
    updatePatyAccountBook(
      state,
      action: PayloadAction<{
        partyAccountBook: CustomerPartyAccountBook;
        id: string;
      }>
    ) {
      state.customers[action.payload.id] = {
        ...state.customers[action.payload.id],
        partyAccountBook: action.payload.partyAccountBook,
      };
    },
    updateInviteId(state, action: PayloadAction<string>) {
      state.inviteId = action.payload;
    },
    clearSelectedCustomers(state) {
      state.selectedCustomerIds = [];
      state.isSelectAllCustomers = false;
      state.outStandingCustomers = state.outStandingCustomers.map((x) => {
        return {
          ...x,
          isSelected: false,
        };
      });
    },
    ledgerInformation(state, action: PayloadAction<LedgerSearchFilterInputs>) {
      state.ledgerSearchFilterInputs = action.payload;
    },
    invoiceInformation(state, action: PayloadAction<LedgerSearchFilterInputs>) {
      state.invoiceFilterInputs = action.payload;
    },
    paymentInformation(state, action: PayloadAction<LedgerSearchFilterInputs>) {
      state.paymentFilterInputs = action.payload;
    },
    addAccountDetails(
      state,
      action: PayloadAction<{
        accountDetails: CustomerAccountDetails;
        id: string;
      }>
    ) {
      state.customers[action.payload.id].accountDetails =
        action.payload.accountDetails;
    },
    updateSelectAllCustomers(state, action) {
      state.isSelectAllCustomers = action.payload?.isAllSelected;
      const data = state.outStandingCustomers.map((customer) => {
        return {
          ...customer,
          isSelected: action.payload?.isAllSelected,
        };
      });
      state.outStandingCustomers = data;
      const updateAdded = state.outStandingCustomers.map((x) => {
        return {
          inviteId: x.inviteId,
          profilePic: x.profilePic,
          companyName: x.companyName,
        };
      });
      const updateRemoved = state.selectedCustomerIds.filter((x) =>
        state.outStandingCustomers.every((y) => x.inviteId !== y.inviteId)
      );

      state.selectedCustomerIds = action.payload?.isAllSelected
        ? _.unionBy(state.selectedCustomerIds, updateAdded, 'inviteId')
        : updateRemoved;
    },
    updateCustomerSelectionById(state, action) {
      const index = state.outStandingCustomers.findIndex(
        (x) => x.id === action.payload?.id
      );
      state.outStandingCustomers[index] = {
        ...state.outStandingCustomers[index],
        isSelected: action.payload.selected,
      };
      state.isSelectAllCustomers = state.outStandingCustomers?.every(
        (x) => x.isSelected === true
      );
      action.payload?.selected
        ? state.selectedCustomerIds.push({
            inviteId: state.outStandingCustomers[index]?.inviteId,
            profilePic: state.outStandingCustomers[index]?.profilePic,
            companyName: state.outStandingCustomers[index]?.companyName,
          })
        : (state.selectedCustomerIds = state.selectedCustomerIds.filter(
            (x) => x.inviteId !== state.outStandingCustomers[index]?.inviteId
          ));
    },
    updateSelectAllReminders(
      state,
      action: PayloadAction<{ isAllSelected: boolean }>
    ) {
      state.isSelectAllReminders = action.payload?.isAllSelected;
      const data = state.remindersData.map((reminder) => {
        return {
          ...reminder,
          isSelected: action.payload?.isAllSelected,
        };
      });
      state.remindersData = data;
    },
    updateReminderSelectionById(state, action) {
      const index = state.remindersData.findIndex(
        (x) => x.id === action.payload?.id
      );
      state.remindersData[index].isSelected = action.payload.selected;
      state.isSelectAllReminders = state.remindersData.every(
        (x) => x.isSelected === true
      );
    },
    selectSingleReminder(state, action) {
      const data = state.remindersData.map((x) => {
        return {
          ...x,
          isSelected: x.id === action.payload?.id,
        };
      });
      state.remindersData = data;
      state.isSelectAllReminders = data.every((x) => x.isSelected === true);
    },
    updateInvoices(
      state,
      action: PayloadAction<{
        invoicesListResponse: CustomerPaymentInvoices;
        id: string;
      }>
    ) {
      state.customers[action.payload.id].Invoices =
        action.payload.invoicesListResponse;
    },
    updateVisits(
      state,
      action: PayloadAction<{
        visitResponse: Visits[];
        id: string;
      }>
    ) {
      state.customers[action.payload.id].visits = action.payload.visitResponse;
    },
    updateMessageHistory(
      state,
      action: PayloadAction<{ messageHistory: MessageHistory; id: string }>
    ) {
      state.customers[action.payload.id].messageHistory =
        action.payload?.messageHistory;
    },
    clearSession(state) {
      state.customers = initialCustomerState.customers;
      state.searchedCustomer = initialCustomerState.searchedCustomer;
      state.totalCount = initialCustomerState.totalCount;
      state.customerUpdateState = initialCustomerState.customerUpdateState;
      state.ledgerSearchFilterInputs =
        initialCustomerState.ledgerSearchFilterInputs;
      state.invoiceFilterInputs = initialCustomerState.invoiceFilterInputs;
      state.inviteId = initialCustomerState.inviteId;
      state.filterCustomerData = initialCustomerState.filterCustomerData;
      state.filterSearchedCustomer =
        initialCustomerState.filterSearchedCustomer;
    },
    clearReminderPayload(state) {
      state.reminderPayload = initialCustomerState.reminderPayload;
    },
    addFilterCustomerList(state, action: PayloadAction<CustomerListResponse>) {
      const customers = action.payload.customers;
      customers?.forEach((constomer) => {
        state.filterCustomerData[constomer.id] = constomer;
      });
    },
    filterSearchedList(state, action: PayloadAction<CustomerListResponse>) {
      state.filterSearchedCustomer = {};
      const customers = action.payload.customers;
      customers?.forEach((customer) => {
        state.filterSearchedCustomer[customer.id] = customer;
      });
    },
    setDivisionSortDirection(state, action: PayloadAction<string>) {
      state.divsionSortDirection = action.payload;
    },
    updateCustomerSummary(
      state,
      action: PayloadAction<CustomerSummaryResponse[]>
    ) {
      state.customerSummary = action.payload;
    },
    updateAllAssignedCustomers(
      state,
      action: PayloadAction<CustomerAssignedDetail[]>
    ) {
      state.allAssignedCustomers = action.payload;
    },
    updateCheckinCustomer(
      state,
      action: PayloadAction<CheckinCustomer>
    ) {
      state.checkinCustomer = action.payload;
    },
    updateSellerCustomerDetails(
      state,
      action: PayloadAction<CustomerSearchResponse>
    ) {
      state.sellerCustomerDetail = action.payload;
    },
    updateErrorMessage(
      state,
      action: PayloadAction<{
        key: string;
        msg: string;
      }>
    ) {
      const { key, msg } = action.payload;
      state.fieldError[key as FieldErrorStateKeys] = { isShow: true, msg };
    },
    clearErrorMessage(state, action: PayloadAction<string>) {
      const key = action.payload;
      state.fieldError[key as FieldErrorStateKeys] = { isShow: false, msg: '' };
    },
    updateSelectedCfaDivisionDetails(state, action: PayloadAction<CfaData[]>) {
      state.selectedCfasDivision = action.payload;
    },
    updateShowLoaderState(
      state,
      action: PayloadAction<{
        key?: LoaderStateKeys;
        value?: boolean;
        resetAll?: boolean;
      }>
    ) {
      if (action.payload.resetAll) {
        Object.keys(state.showLoaderState).forEach((key) => {
          state.showLoaderState[key as LoaderStateKeys] = false;
        });
      } else if (
        action.payload.key !== undefined &&
        action.payload.value !== undefined
      ) {
        state.showLoaderState[action.payload.key] = action.payload.value;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      customersApi.endpoints.getCustomerSummary.matchFulfilled,
      (state, action) => {
        customersSlice.caseReducers.updateCustomerSummary(state, action);
      }
    );
  },
});

export const customersReducer = customersSlice.reducer;

export const {
  addCustomerList,
  updateCustomerSelectedTab,
  getOutstandingCustomerList,
  addRemindersData,
  updateReminderPayload,
  getSearchedList,
  updatePatyAccountBook,
  updateInviteId,
  clearSelectedCustomers,
  ledgerInformation,
  invoiceInformation,
  paymentInformation,
  addAccountDetails,
  updateSelectAllReminders,
  updateReminderSelectionById,
  selectSingleReminder,
  updateSelectAllCustomers,
  updateCustomerSelectionById,
  updateInvoices,
  updateMessageHistory,
  updateVisits,
  filterSearchedList,
  addFilterCustomerList,
  clearReminderPayload,
  clearSession,
  setDivisionSortDirection,
  updateAllAssignedCustomers,
  updateCheckinCustomer,
  updateSellerCustomerDetails,
  updateErrorMessage,
  clearErrorMessage,
  updateSelectedCfaDivisionDetails,
  updateShowLoaderState,
} = customersSlice.actions;

export const getCustomersSliceState = (rootState: RootState): CustomersState =>
  rootState[CUSTOMER_FEATURE_KEY];

export const getCustomerList = createSelector(
  getCustomersSliceState,
  (state) => state.customers
);

export const getCustomerState = createSelector(
  getCustomersSliceState,
  (state) => state
);

export const getInviteId = createSelector(
  getCustomersSliceState,
  (state) => state.inviteId
);

export const getOutStandingCustomers = createSelector(
  getCustomersSliceState,
  (state) => state.outStandingCustomers
);

export const getDivisionSortDirection = createSelector(
  getCustomersSliceState,
  (state) => state.divsionSortDirection
);

export const getCustomerSummary = createSelector(
  getCustomersSliceState,
  (state) => state.customerSummary
);

export const getAllAssignedCustomers = createSelector(
  getCustomersSliceState,
  (state) => state.allAssignedCustomers
);

export const getCheckinCustomer = createSelector(
  getCustomersSliceState,
  (state) => state.checkinCustomer
);

export const getSellerCustomerDetail = createSelector(
  getCustomersSliceState,
  (state) => state.sellerCustomerDetail
);

export const getFieldError = createSelector(
  getCustomersSliceState,
  (state) => state.fieldError
);

export const getSelectedCfaDivision = createSelector(
  getCustomersSliceState,
  (state) => state.selectedCfasDivision
);

export const getCustomerLoaderState = createSelector(
  getCustomersSliceState,
  (state) => state.showLoaderState
);
