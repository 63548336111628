import React, { useContext, useRef, useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { SHARED_SVGS, ThemeContext } from '@zonofi/shared';
import dayjs, { Dayjs } from 'dayjs';
import './zo-time-picker.scss'

interface TimePickerProps {
  /**
   * Provide the label of time picker.
   */
  label: string;
  /**
   * Time picker is required or not (default: false).
   */
  isRequired?: boolean;
  /**
   * Provide the placeholder text for the time picker.
   */
  placeholder?: string;
  /**
   * Callback function to be called on change of the time.
   */
  onChange: (val: any) => void;
  /**
   * Provide value for the time picker.
   */
  value: string;
  /**
   * Provide true to make time picker disabled. By default false value is given.
   */
  disabled?: boolean;
  /**
   * Indicates whether the time picker field has an error. If true, the time picker field will be displayed in an error state.
   */
  error?: boolean;
  /**
   * An element to display at the start of the input field.
   */
  startAdornment?: React.JSX.Element;
  /**
   * An element to display at the end of the input field.
   * Example: An icon or text.
   */
  endAdornment?: React.JSX.Element;
  /**
   * Props to customize the appearance of the Time field.
   * By default width: 100% and height = 32px is given.
   */
  timeFieldStyleProps?: React.CSSProperties;
  /**
   * Additional HTML attributes to be spread onto the input element.
   */
  inputFieldProps?: React.CSSProperties;
}

export const ZoTimePicker = (props: TimePickerProps) => {
  const {
    label,
    isRequired = false,
    onChange,
    placeholder,
    value,
    disabled = false,
    error = false,
    startAdornment,
    endAdornment,
    timeFieldStyleProps,
    inputFieldProps,
  } = props;

  const [open, setOpen] = useState(false);
  const currentTheme = useContext(ThemeContext);
  const { theme } = currentTheme;

  const inputFieldStyleContainer = {
    ...theme.fontConfig.regular,
    fontSize: '14px',
    color: `${value ? '#091E42' : '#8f8f8f'}`,
    caretColor: 'transparent',
    cursor: 'pointer',
    ...inputFieldProps,
  };

  const handleClearTime = () => {
    onChange(null); // Reset the time value
    setOpen(false); // Close the popup
  };

  // Disable all minutes except 00 and 30
  const filterAvailableTime = (value: Dayjs, view: any) => {
    if (view === 'minutes') {
      const minute = value.minute();
      return minute === 0 || minute === 30;
    }
    return true;
  };

  const CustomActionBar = ({ onAccept, onCancel }: any) => (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        padding: '8px 16px',
        borderTop: '1px solid #e0e0e0',
      }}
    >
      <div
        onClick={handleClearTime}
        style={{
          color: theme.secondary,
          fontSize: 14,
          textAlign: 'center',
          textDecorationLine: 'underline',
          ...theme.fontConfig.semiBold,
          cursor: 'pointer',
          lineHeight: '32px'
        }}
      >
        Clear
      </div>

      <div>
        <button
          onClick={onAccept}
          style={{
            background: '#32A7E8',
            border: 'none',
            color: '#fff',
            cursor: 'pointer',
            fontSize: '14px',
            width: 62,
            height: 32,
            borderRadius: 3,
            ...theme.fontConfig.semiBold,
          }}
        >
          Done
        </button>
      </div>
    </div>
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePicker
        label={label}
        disabled={disabled}
        open={!disabled && open}
        onClose={() => setOpen(false)}
        onChange={(time: Dayjs | null) => onChange(time)}
        value={value ? dayjs(value) : null}
        ampm={true} // Support for AM/PM time format
        sx={{
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#dcdcdc', // Default border color
              borderWidth: '1px',
              borderRadius: '6px',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#dcdcdc', // Border color when focused
              borderWidth: '1px',
              borderRadius: '6px',
            },
          },
        }}
        shouldDisableTime={(val, view) => !filterAvailableTime(val, view)}
        slots={{
          actionBar: CustomActionBar,
        }}
        slotProps={{
          textField: {
            onClick: () => setOpen(true),
            placeholder: placeholder,
            disabled: disabled,
            required: isRequired,
            InputLabelProps: {
              style: {
                ...theme.fontConfig.regular,
                color: `${value || open ? '#091E42' : '#BFC0C2'}`,
                ...((value || open) ? {} : {marginTop: -10})
              },
            },
            inputProps: {
              sx: {
                '&::placeholder': {
                  color: `${open ? '#091E42' : '#BFC0C2'}`,
                  opacity: 1,
                  fontSize: '14px',
                  fontFamily: 'Segoe-UI',
                  display: 'block !important',
                },
              },
              style: inputFieldStyleContainer,
            },
            InputProps: {
              startAdornment: startAdornment && startAdornment,
              endAdornment: endAdornment && endAdornment,
              placeholder: placeholder,
              error: error,
              sx: {
                height: '38px',
                ...timeFieldStyleProps,
              },
            },
          },
        }}
      />
    </LocalizationProvider>
  );
};
