import { ColumnConfig, CustomerManualReturnColumnKey } from '../../model';

export const customerManualReturnConfig: Record<
  CustomerManualReturnColumnKey,
  ColumnConfig
> = {
  batchNumber: { name: 'Batch Number', field: 'component' },
  title: { name: 'Product Title & SKU Code', field: 'multiLine' },
  pts: {
    name: 'P. PTS',
    headerExtra: {
      type: 'info',
      details:
        'Potential value of return good, subject to change based on CFA reconciliation and actual selling price.',
      title: 'Potential PTS',
    },
  },
  invoiceNumber: { name: 'Invoice Number', field: 'dropdownOrInput' },
  category: { name: 'Category' },
  expiryDate: { name: 'Expiry Date' },
  quantity: { name: 'Return Qty', field: 'textinput', alignment: 'flex-end' },
  delete: { name: '' },
};
