import { isUndefined } from 'lodash';
import {
  ComOrderData,
  ComOrderDataLine,
  CreateOrderResponse,
  OrderedUserType,
  POLines,
  PoOrderDetailsResponse,
} from './model';
import { OrderStatusEnum } from '../helpers';

const extractDataFromOrder = (
  data: CreateOrderResponse,
  updateResponseOrderLines?: ComOrderDataLine[]
): ComOrderData => {
  if (isUndefined(data)) return undefined;
  const {
    skucount,
    discount,
    totalWithTax,
    invoiceSummary,
    createdAt,
    lines,
    id: orderId,
    poFile,
    status,
    refOrderNumber,
    taxAmount,
    customerName,
    customerId,
    total,
    notifyToBuyer,
    createdByName,
    distributorName,
    distributorFirmName,
    fullFillmentLocationId,
    tags,
    userType,
  } = data || {};
  const orderlines: ComOrderDataLine[] = lines?.map((line) => {
    const {
      productVariant,
      minOrderQty,
      quantity,
      unitPrice,
      linePriceWithTax,
      id: orderLineId,
      poFileLineId,
      discountedUnitPrice,
      discountedLinePriceWithTax,
      promotionIds,
      packSize,
      promotionNames,
      uploadedProductName,
      uploadedQty,
      lineDiscount,
      lineTax,
      linePrice,
      cfaId,
    } = line || {};
    return {
      sequence: line?.sequence,
      skuCode: productVariant?.sku || '-',
      title: productVariant?.translations?.[0]?.name || '-',
      moq: minOrderQty || 1,
      quantity,
      price: Number(unitPrice),
      newPrice: Number(discountedUnitPrice),
      total: Number(linePriceWithTax),
      orderId,
      orderLineId,
      poFileLineId: poFileLineId || updateResponseOrderLines?.[0]?.poFileLineId,
      newQty: quantity,
      productVariantId: productVariant?.id,
      deleted: false,
      isEdited: false,
      discountedPrice: Number(discountedUnitPrice),
      discountedTotal: Number(discountedLinePriceWithTax),
      promotionIds: promotionIds,
      packSize,
      promotionNames,
      uploadedProductName,
      uploadedQuantity: uploadedQty,
      lineDiscount,
      lineTax,
      linePrice,
      cfaId,
      assetUrl: productVariant?.assetUrl,
    };
  });

  return {
    skuCount: skucount?.toString() || '',
    discount: Number(discount),
    totalWithTax: Number(totalWithTax),
    invoiceNumber: '-',
    invoiceValue: Number(invoiceSummary?.totalAmount),
    createdDate: createdAt,
    lines: orderlines,
    importSource: poFile?.importSource,
    poFileId: poFile?.id,
    status:
      status === OrderStatusEnum.SUBMITTED_BY_CUSTOMER ? 'Submitted' : status,
    orderNumber: refOrderNumber,
    tax: Number(taxAmount),
    customerId,
    orderedBy: createdByName || '',
    customerName: distributorFirmName || distributorName || customerName || '',
    total: Number(total),
    notifyToBuyer: Boolean(notifyToBuyer),
    cfaId: fullFillmentLocationId,
    tags: tags,
    userType,
  };
};

const extractDataFromPO = (data: PoOrderDetailsResponse): ComOrderData => {
  if (isUndefined(data)) return undefined;
  const {
    skuCount,
    discount,
    totalWithTax,
    invoiceSummary,
    uploadedDate,
    lines,
    importSource,
    pofileId,
    orderState,
    refOrderNumber,
    tax,
    customerId,
    orderedBy,
    total,
    notifyToBuyer,
    customerName,
    customerFirmName,
    tags,
    userType,
  } = data || {};
  const polines: ComOrderDataLine[] = lines?.map((line) => {
    const {
      cfaId,
      productVariant,
      MOQ,
      unitPrice,
      quantity,
      linePriceWithTax,
      orderId,
      orderLineId,
      poFileLineId,
      distributorProductName,
      uploadedQty,
      discountedUnitPrice,
      discountedLinePriceWithTax,
      promotionIds,
      packSize,
      promotionNames,
      lineDiscount,
      lineTax,
      linePrice,
    } = line || {};
    return {
      sequence: line?.sequence,
      skuCode: productVariant?.sku || '-',
      title: productVariant?.translations?.[0]?.name || '',
      moq: MOQ || productVariant?.MOQ || 1,
      price: Number(unitPrice),
      newPrice: Number(discountedUnitPrice),
      quantity,
      total: Number(linePriceWithTax),
      orderId,
      orderLineId,
      poFileLineId,
      promotionNames,
      newQty: quantity,
      productVariantId: productVariant?.productVariantId,
      deleted: false,
      isEdited: false,
      uploadedProductName: distributorProductName,
      uploadedQty,
      discountedPrice: Number(discountedUnitPrice),
      discountedTotal: Number(discountedLinePriceWithTax),
      promotionIds: promotionIds,
      packSize,
      uploadedQuantity: uploadedQty,
      lineDiscount,
      lineTax,
      linePrice,
      cfaId,
      assetUrl: productVariant?.assetUrl,
    };
  });

  return {
    skuCount: skuCount,
    discount: Number(discount),
    totalWithTax: Number(totalWithTax),
    invoiceNumber: '-',
    invoiceValue: Number(invoiceSummary?.totalAmount),
    createdDate: uploadedDate,
    lines: polines,
    importSource,
    poFileId: pofileId,
    status:
      orderState === OrderStatusEnum.SUBMITTED_BY_CUSTOMER
        ? 'Submitted'
        : orderState,
    orderNumber: refOrderNumber,
    tax: Number(tax),
    customerId,
    orderedBy: orderedBy,
    customerName: customerFirmName || customerName,
    total: Number(total),
    notifyToBuyer: Boolean(notifyToBuyer),
    cfaId: lines?.find(({ cfaId }) => !isUndefined(cfaId))?.cfaId,
    tags,
    userType: userType as OrderedUserType,
  };
};

const extractOrderLineFromPO = (data: POLines): ComOrderDataLine => {
  const {
    productVariant,
    MOQ,
    unitPrice,
    quantity,
    linePriceWithTax,
    orderId,
    orderLineId,
    poFileLineId,
    distributorProductName,
    uploadedQty,
    discountedUnitPrice,
    discountedLinePriceWithTax,
    promotionIds,
    packSize,
    promotionNames,
    lineDiscount,
    lineTax,
    linePrice,
  } = data;

  return {
    sequence: data?.sequence || '',
    skuCode: productVariant?.sku || '-',
    title: productVariant?.translations?.[0]?.name || '',
    moq: MOQ || productVariant?.MOQ || 1,
    price: Number(unitPrice),
    newPrice: Number(discountedUnitPrice),
    quantity: Number(quantity),
    total: Number(linePriceWithTax),
    orderId,
    orderLineId,
    poFileLineId,
    promotionNames: promotionNames || [],
    newQty: Number(quantity),
    productVariantId: Number(productVariant?.productVariantId),
    deleted: false,
    isEdited: false,
    uploadedProductName: distributorProductName,
    uploadedQty,
    discountedPrice: Number(discountedUnitPrice),
    discountedTotal: Number(discountedLinePriceWithTax),
    promotionIds: promotionIds || [],
    packSize,
    uploadedQuantity: uploadedQty,
    lineDiscount,
    lineTax,
    linePrice,
  };
};

export { extractDataFromOrder, extractDataFromPO, extractOrderLineFromPO };
