import { DrawerConfigValues, ProductDrawerKey } from '../model';

export const ProductsDrawerConfig: Record<
  ProductDrawerKey,
  DrawerConfigValues
> = {
  details: {
    tabName: 'Details',
    sections: [
      {
        sectionName: 'Product Details',
        blocks: [
          {
            inputs: [
              {
                inputType: 'textInput',
                name: 'title',
                label: 'Product Name',
                required: true,
                placeholder: 'Enter product name',
              },
            ],
          },
          {
            inputs: [
              {
                inputType: 'textInput',
                name: 'description',
                label: 'Description',
                required: false,
                placeholder: 'Enter description',
              },
            ],
          },
          {
            inputs: [
              {
                inputType: 'textInput',
                name: 'sku',
                label: 'SKU Code',
                required: true,
                placeholder: 'Ex: 312068116462271666',
              },
            ],
          },
          {
            inputs: [
              {
                inputType: 'textInput',
                name: 'upc',
                label: 'UPC Code',
                required: false,
                placeholder: 'Ex: DR394883F',
              },
              {
                inputType: 'textInput',
                name: 'hsn',
                label: 'HSN Code',
                required: false,
                placeholder: 'Ex: DR394883F',
              },
            ],
          },
          {
            inputs: [
              {
                inputType: 'dropDonwWithCustomOption',
                name: 'headDivision',
                label: 'Head Division (Division)',
                required: true,
                placeholder: 'Select',
                subInputType: 'division'
              },
            ],
          },
          {
            inputs: [
              {
                inputType: 'multiDropdownInput',
                name: 'category',
                label: 'Category',
                required: false,
                placeholder: 'Select',
              },
            ],
          },
        ],
      },
      {
        sectionName: 'Pricing & Tax',
        blocks: [
          {
            inputs: [
              {
                inputType: 'textInput',
                name: 'pts',
                label: 'Buying Price',
                required: true,
                placeholder: '0.00',
                prefixType: 'currency',
                inputValueType: 'integer',
              },
              {
                inputType: 'textInput',
                name: 'ptr',
                label: 'Selling Price',
                required: false,
                placeholder: '0.00',
                prefixType: 'currency',
                inputValueType: 'integer',
              },
            ],
          },
          {
            inputs: [
              {
                inputType: 'textInput',
                name: 'mrp',
                label: 'MRP (Maximum Retail Price)',
                required: false,
                placeholder: '0.00',
                prefixType: 'currency',
                inputValueType: 'integer',
              },
              {
                inputType: 'dropdownInput',
                name: 'tax',
                label: 'Tax Category',
                required: false,
              },
            ],
          },
        ],
      },
      {
        sectionName: 'Base Unit & Pack Size',
        blocks: [
          {
            inputs: [
              {
                inputType: 'textInput',
                name: 'minOrderQty',
                label: 'Min Order Quantity',
                required: false,
                placeholder: 'Enter Min Order Quantity',
                inputValueType: 'integer',
              },
              {
                inputType: 'textInput',
                name: 'maxOrderQty',
                label: 'Max Order Quantity',
                required: false,
                placeholder: 'Enter Max Order Quantity',
                inputValueType: 'integer',
              },
            ],
          },
          {
            inputs: [
              {
                inputType: 'dropdownInput',
                name: 'uom',
                label: 'Base Unit of Measurement',
                required: false,
                placeholder: 'Ex: Pcs',
              },
              {
                inputType: 'textInput',
                name: 'quantity',
                label: 'Quantity',
                required: false,
                placeholder: 'Ex: 10',
                inputValueType: 'integer',
              },
            ],
          },
          {
            inputs: [
              {
                inputType: 'textInput',
                name: 'packSize',
                label: 'Pack Size',
                required: false,
                placeholder: 'Ex: 1',
                inputValueType: 'integer',
              },
              {
                inputType: 'textInput',
                name: 'qtyMultiplier',
                label: 'Quantity Multiplier',
                required: false,
                placeholder: 'Ex: 1',
                inputValueType: 'integer',
              },
            ],
          },
        ],
      },
    ],
  },
  inventory: {
    tabName: 'Other Details',
    sections: [
      {
        sectionName: 'Batch Details',
        blocks: [
          {
            inputs: [
              {
                inputType: 'datePicker',
                name: 'manufacturingDate',
                label: 'Manufacturing Date',
                required: false,
                placeholder: 'MM/YYYY',
              },
              {
                inputType: 'datePicker',
                name: 'expiryDate',
                label: 'Expiry Date',
                required: false,
                placeholder: 'MM/YYYY',
              },
            ],
          },
          {
            inputs: [
              {
                inputType: 'dropdownInput',
                name: 'expiryBeforeAfter',
                label: 'Expiry Before/After',
                required: false,
                placeholder: 'Select',
              },
              {
                inputType: 'dropdownInput',
                name: 'period',
                label: 'Expiry Period',
                required: false,
                placeholder: 'Select',
              },
            ],
          },
        ]
      },
      {
        sectionName: 'Stock and Sales',
        blocks: [
          {
            inputs: [
              {
                inputType: 'textInput',
                name: 'currentStock',
                label: 'Current Stock',
                required: false,
                placeholder: '0',
                inputValueType: 'integer',
              },
              {
                inputType: 'textInput',
                name: 'cfaInventory',
                label: 'CFA Inventory',
                required: false,
                placeholder: '0',
                inputValueType: 'integer',
              },
            ],
          },
          {
            inputs: [
              {
                inputType: 'textInput',
                name: 'sales',
                label: 'Sales',
                required: false,
                placeholder: '0',
                inputValueType: 'integer',
              },
              {
                inputType: 'textInput',
                name: 'inventoryDays',
                label: 'Inventory Days',
                required: false,
                placeholder: '0',
                inputValueType: 'integer',
              },
            ],
          },
        ],
      },
      {
        sectionName: 'Product Attributes',
        blocks: [
          {
            inputs: [
              {
                inputType: 'textInput',
                name: 'sortOrder',
                label: 'Sort Order',
                required: false,
                placeholder: 'Ex: 1',
                inputValueType: 'integer',
              },
              {
                inputType: 'textInput',
                name: 'shelfLife',
                label: 'Shelf Life',
                required: false,
              },
            ],
          },
          {
            inputs: [
              {
                inputType: 'textInput',
                name: 'conditions',
                label: 'Conditions',
                required: false,
                placeholder: 'Enter few conditions',
              },
            ],
          },
          {
            inputs: [
              {
                inputType: 'textInput',
                name: 'warrentyInformation',
                label: 'Warranty Information',
                required: false,
                placeholder: 'Enter few Warranty Information',
              },
            ],
          },
        ],
      },

      {
        sectionName: 'Shipping & Size',
        blocks: [
          {
            inputs: [
              {
                inputType: 'textInput',
                name: 'grossWeight',
                label: 'Gross Weight',
                required: false,
                placeholder: 'Ex: 1.2kg',
              },
              {
                inputType: 'textInput',
                name: 'netWeight',
                label: 'Net Weight',
                required: false,
                placeholder: 'Ex: 1kg',
              },
            ],
          },
          {
            inputs: [
              {
                inputType: 'textInput',
                name: 'volume',
                label: 'Volume',
                required: false,
                placeholder: 'Ex: 1',
              },
            ],
          },
        ],
      },

      {
        sectionName: 'ERP Details',
        sectionId: 'erp',
        blocks: [
          {
            inputs: [
              {
                inputType: 'textInput',
                name: 'erpId',
                label: 'ERP Id',
                required: false,
                placeholder: 'Enter ERP Id',
              },
              {
                inputType: 'textInput',
                name: 'erpUOM',
                label: 'ERP UOM',
                required: false,
                placeholder: 'Enter ERP UOM',
              },
            ],
          },
          {
            inputs: [
              {
                inputType: 'textInput',
                name: 'erpEAN',
                label: 'EAN',
                required: false,
                placeholder: 'Enter EAN',
              },
              {
                inputType: 'textInput',
                name: 'erpPriceUOM',
                label: 'ERP Price UOM',
                required: false,
                placeholder: 'Enter ERP Price UOM',
              },
            ],
          },
        ],
      },
    ],
  },
  cfa: {
    tabName: 'CFA',
    sections: [
      {
        sectionName: 'cfa',
        sectionId: 'cfaLink',
        isCustomSection: true,
      },
    ],
  },
};
