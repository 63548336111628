import { getFormattedAmount } from '@zonofi/common';
import { LoadingSpinner, SVGS } from '@zonofi/zono-money-design-system';
import {
  OrderData,
  OrderDataLine,
  OrderStatusEnum,
  useAppSelector,
} from '@zonofi/zono-money-helpers';
import React, { useMemo } from 'react';
import { useOrderDetailsList } from './useOrderDetailsList';
import { getCustomerInvite } from '@zonofi/zono-money-store';
import { isNumber } from 'lodash';

interface OrderDetailsListProps {
  orderDetails: OrderData;
  onIncrement: (
    val: OrderDataLine,
    sellerWorkspaceId: string,
    customerId: string
  ) => any;
  onDecrement: (val: any, sellerWorkspaceId: string, customerId: string) => any;
  onDelete: (val: any, sellerWorkspaceId: string, customerId: string) => any;
  onChangeQuantity: (
    productVariantId: any,
    qty: number,
    sellerWorkspaceId: string,
    customerId: string
  ) => any;
  showImage: boolean;
  showPrice: boolean;
  showOrderEdit: boolean;
  isCart?: boolean;
}

export const OrderDetailsList: React.FC<OrderDetailsListProps> = ({
  orderDetails,
  onIncrement,
  onDecrement,
  onDelete,
  onChangeQuantity,
  showImage,
  showPrice,
  showOrderEdit,
  isCart,
}) => {
  const inviteDetails = useAppSelector(getCustomerInvite);
  const { seller, inviteId } = inviteDetails;

  const {
    getModifiedImageUrl,
    quantityMap,
    setQuantityMap,
    openProductDetails,
    hidePrice,
  } = useOrderDetailsList(orderDetails, isCart, showImage, showPrice);
  const showPrices = useMemo(
    () =>
      [
        OrderStatusEnum.DRAFT,
        OrderStatusEnum.ADDING_ITEMS,
        OrderStatusEnum.ORDER_CREATED,
      ].includes(orderDetails?.status as any)
        ? showPrice
        : true,
    [orderDetails, showPrice]
  );

  return orderDetails?.lines?.map((item, index) => {
    let showStrikeThroughPrice = item?.promotionIds?.length !== 0;

    return (
      <div className="order-details-line-item-root" key={index}>
        <div className="order-details-line-item">
          <div className="order-details-line-item-container">
            {showImage ? (
              <img
                src={
                  item?.productImage
                    ? getModifiedImageUrl(item?.productImage)
                    : SVGS.DefaultProduct
                }
                className="order-details-line-item-default-img"
                alt=""
                onClick={() => openProductDetails(item?.productVariantId)}
                onError={(e) => {
                  e.currentTarget.onerror = null;
                  e.currentTarget.src = SVGS.DefaultProduct;
                }}
              />
            ) : (
              <></>
            )}
            <div className="order-details-line-item-container-info">
              <span className="font-primary">{item?.title}</span>
              {!isNumber(item.productVariantId) && (
                <span className="font-primary order-details-line-item-container-info-unmapped-product">
                  Unmapped
                </span>
              )}
              {showPrices && (
                <span className="order-details-list-item-info-price-container font-primary-semibold">
                  {showStrikeThroughPrice ? (
                    <>
                      <span className="order-details-list-item-info-price-container-striked">
                        {getFormattedAmount(item?.price ?? 0) ?? 0}
                      </span>
                      {getFormattedAmount(item?.newPrice ?? 0) ?? 0}
                    </>
                  ) : (
                    getFormattedAmount(item?.price ?? 0) ?? 0
                  )}
                </span>
              )}
              {!showOrderEdit ? (
                <div className="order-details-list-item-qty-details">
                  <span className="order-details-list-item-qty-details-label font-primary">
                    Qty -{' '}
                  </span>
                  <span className="font-primary-semibold">
                    {item?.quantity}
                  </span>
                </div>
              ) : (
                <></>
              )}
              {item?.promotionIds && item?.promotionIds?.length !== 0 && (
                <div className="catalog-list-item-promotion">
                  <img src={SVGS.DiscountIcon} />
                  <span className="catalog-list-item-promotion-text font-primary-semibold">
                    Offer Applied
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className="order-details-list-item-qty-root">
            {showOrderEdit ? (
              <div className="order-details-list-item-qty-selector-container">
                <img
                  src={SVGS.DeleteRed}
                  alt="delete"
                  onClick={() => onDelete(item, seller?.workspaceId, inviteId)}
                  className="order-details-list-item-qty-selector-delete"
                />
                {isNumber(item.productVariantId) && (
                  <div className="order-details-list-item-qty-selector font-primary-semibold">
                    <img
                      src={SVGS.MinusPrimaryCyan}
                      alt="Minus"
                      onClick={() =>
                        onDecrement(item, seller?.workspaceId, inviteId)
                      }
                    />
                    {item?.isLoading ? (
                      <div className="order-details-list-item-qty-selector-loader">
                        <LoadingSpinner size={15} />
                      </div>
                    ) : (
                      <input
                        className="order-details-list-item-qty-selector-input"
                        type="text"
                        inputMode="numeric"
                        pattern="[0-9]*[.,]?[0-9]+"
                        key={item?.productVariantId}
                        onKeyDown={(e) => {
                          e.key === 'Enter' &&
                            onChangeQuantity(
                              item,
                              quantityMap[
                                //@ts-ignore
                                item?.poFileLineId || item?.orderLineId
                              ],
                              seller?.workspaceId,
                              inviteId
                            );
                        }}
                        onBlur={() =>
                          onChangeQuantity(
                            item,
                            //@ts-ignore
                            quantityMap[
                              item?.poFileLineId || item?.orderLineId
                            ],
                            seller?.workspaceId,
                            inviteId
                          )
                        }
                        onChange={(e) => {
                          if (!isNaN(Number(e.target.value))) {
                            let initialQuantiyMap = quantityMap;
                            if (item?.poFileLineId) {
                              initialQuantiyMap[item?.poFileLineId] = Number(
                                e.target.value
                              );
                            } else {
                              //@ts-ignore
                              initialQuantiyMap[item?.orderLineId] = Number(
                                e.target.value
                              );
                            }
                            setQuantityMap({ ...initialQuantiyMap });
                          }
                        }}
                        value={
                          //@ts-ignore
                          quantityMap[item?.poFileLineId || item?.orderLineId]
                        }
                      />
                    )}
                    <img
                      src={SVGS.PlusPrimaryCyan}
                      alt="plus"
                      onClick={() =>
                        onIncrement(item, seller?.workspaceId, inviteId)
                      }
                    />
                  </div>
                )}
              </div>
            ) : (
              <></>
            )}
            <span className="order-details-list-item-qty-total font-primary-semibold">
              {!showOrderEdit ? (
                <span className="order-details-list-item-qty-total-label font-primary">
                  {`Total Amount: `}
                </span>
              ) : (
                <></>
              )}
              {showPrices && (
                <span className="order-details-list-item-info-price-container-single-item font-primary-semibold">
                  {getFormattedAmount(item?.discountedTotal)}
                </span>
              )}
            </span>
          </div>
        </div>
      </div>
    );
  });
};
