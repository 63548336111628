import { camelCase, startCase } from 'lodash';
import {
  addListInputItems,
  ListInputItemOptions,
  mapDataToInputItemsFromAppliedFilter,
} from '../filter';
import {
  CustomerThreadStatusFilter,
  GetThreadFiltersResponse,
  CustomerThreadTreeTypeFilter,
  FilterFolder,
} from './model';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { Channel } from '../channel';

export const getCommonThreadFilterItems = (
  data: GetThreadFiltersResponse
): {
  data: any[];
  idKey: string;
  valueKey: string;
  optionKey: string;
}[] => {
  return [
    {
      data: data?.customers || [],
      idKey: 'inviteId',
      valueKey: 'name',
      optionKey: ListInputItemOptions.customers,
    },
    {
      data: data?.cfas || [],
      idKey: 'id',
      valueKey: 'name',
      optionKey: ListInputItemOptions.threadCfa,
    },
    {
      data: data?.members || [],
      idKey: 'inviteId',
      valueKey: 'employeeName',
      optionKey: ListInputItemOptions.threadTeamMembers,
    },
    {
      data: data?.divisions || [],
      idKey: 'id',
      valueKey: 'name',
      optionKey: ListInputItemOptions.threadDivision,
    },
  ];
};

export const handleCustomerStatusFilters = (
  dispatch: ThunkDispatch<any, any, AnyAction>,
  statusData: CustomerThreadStatusFilter
) => {
  const statusArray =
    Object.entries(statusData || {})
      ?.filter(([_, value]) => value.length)
      .flatMap(([key, value]) =>
        value.map((valKey: string) => {
          const depKey = `${startCase(camelCase(key))} ${startCase(
            camelCase(valKey)
          )}`;
          return { id: depKey, value: depKey };
        })
      ) || [];

  const customerStatusInputItems = mapDataToInputItemsFromAppliedFilter(
    statusArray,
    'id',
    'value',
    ListInputItemOptions.threadStatus
  );

  dispatch(
    addListInputItems({
      inputItems: customerStatusInputItems,
      key: ListInputItemOptions.threadStatus,
    })
  );
};

export const handleIssueStatusFilters = (
  dispatch: ThunkDispatch<any, any, AnyAction>,
  data: { issueStatus?: string[]; transactionType?: string[] }
) => {
  const transactionStatusMap =
    data?.transactionType?.map((id) => ({ id, value: id })) || [];

  const transactionStatusInputItems = mapDataToInputItemsFromAppliedFilter(
    transactionStatusMap,
    'id',
    'value',
    ListInputItemOptions.issueType
  );

  dispatch(
    addListInputItems({
      inputItems: transactionStatusInputItems,
      key: ListInputItemOptions.issueType,
    })
  );
};

export function handleConvertToTreeInput(input: CustomerThreadTreeTypeFilter) {
  const processFolder = (folder) => {
    const validChannels = folder?.channels.filter(
      (channel: Channel) => channel?.channelName
    );

    const validSubFolders = folder?.subFolders
      .map((subFolder: FilterFolder) => processFolder(subFolder))
      .filter((sub: FilterFolder) => sub !== null);

    if (validChannels.length === 0 && validSubFolders.length === 0) {
      return null;
    }

    return {
      id: folder?.id,
      value: folder?.name,
      TreeData: [
        ...validSubFolders,
        ...validChannels.map((channel: Channel) => ({
          id: channel?.id,
          value: channel?.channelName,
          selected: false,
        })),
      ],
    };
  };

  const folders = input?.folders
    .flatMap((folder) => {
      const validChannels = folder?.channels.filter(
        (channel: Channel) => channel?.channelName
      );

      const validSubFolders = folder?.subFolders
        .map((subFolder) => processFolder(subFolder))
        .filter((sub) => sub !== null);

      return [
        ...validSubFolders,
        ...validChannels.map((channel: Channel) => ({
          id: channel?.id,
          value: channel?.channelName,
          selected: false,
        })),
      ];
    })
    .filter((item) => item !== null);

  const rootChannels = input?.rootChannels.map((channel) => ({
    id: channel?.id,
    value: channel?.channelName,
    selected: false,
    TreeData: [],
  }));

  return {
    TreeData: [...folders, ...rootChannels],
  };
}
