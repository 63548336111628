import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../root';
import { daybookApi } from './api';
import {
  CollectionByTeamMember,
  DaybookDueByRouteRecordsResponse,
  OutstandingSummaryOfTeamMember,
  OutstandingRouteSummary,
} from './model';

const DAYBOOK_FEATURE_KEY = 'DayBookReports';

export interface DayBookState {
  dueByRoutes: DaybookDueByRouteRecordsResponse;
  outstandingSummaryOfTeamMember: OutstandingSummaryOfTeamMember;
  outstandingRouteSummary: OutstandingRouteSummary;
}

const initialState: DayBookState = {
  dueByRoutes: null,
  outstandingSummaryOfTeamMember: null,
  outstandingRouteSummary: null,
};

export const dayBookSlice = createSlice({
  name: DAYBOOK_FEATURE_KEY,
  initialState,
  reducers: {
    addDueByRoute(
      state,
      action: PayloadAction<DaybookDueByRouteRecordsResponse>
    ) {
      state.dueByRoutes = action.payload;
    },
    addOutstandingSummaryOfTeamMember(
      state,
      action: PayloadAction<OutstandingSummaryOfTeamMember>
    ) {
      state.outstandingSummaryOfTeamMember = action.payload;
    },
    addOutstandingRouteSummary(
      state,
      action: PayloadAction<OutstandingRouteSummary>
    ) {
      state.outstandingRouteSummary = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      daybookApi.endpoints.getDueSellersByRoutes.matchFulfilled,
      (state, action) => {
        dayBookSlice.caseReducers.addDueByRoute(state, action);
      }
    );
    builder.addMatcher(
      daybookApi.endpoints.getOutstandingSummaryOfTeamMembers.matchFulfilled,
      (state, action) => {
        dayBookSlice.caseReducers.addOutstandingSummaryOfTeamMember(
          state,
          action
        );
      }
    );
    builder.addMatcher(
      daybookApi.endpoints.getOutstandingRouteSummary.matchFulfilled,
      (state, action) => {
        dayBookSlice.caseReducers.addOutstandingRouteSummary(state, action);
      }
    );
  },
});

export const {
  addDueByRoute,
  addOutstandingSummaryOfTeamMember,
  addOutstandingRouteSummary,
} = dayBookSlice.actions;

const getDayBookState = (rootState: RootState): DayBookState =>
  rootState[DAYBOOK_FEATURE_KEY];

export const getDueByRoutes = createSelector(
  getDayBookState,
  (state) => state.dueByRoutes
);

export const getOutstandingSummaryOfTeamMember = createSelector(
  getDayBookState,
  (state) => state.outstandingSummaryOfTeamMember
);

export const getOutstandingRouteSummary = createSelector(
  getDayBookState,
  (state) => state.outstandingRouteSummary
);

export const daybookReducer = dayBookSlice.reducer;
