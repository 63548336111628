import { Invoice } from '../invoice';

export enum PaymentMedium {
  CASH = 'CASH',
  CHEQUE = 'CHQ',
  ONLINE = 'ONLINE',
  UPI = 'UPI',
}

export interface Order {
  id: string;
  amount: number;
  currency: string;
  note: string;
}
export interface PaymentLinkResponse {
  id: string;
  inviteId: string;
  sellerWorkspaceId: string;
  linkURL: string;
  statusCode: number;
  responseStatus: string;
}

interface Customer {
  name: string;
  phone: string;
  email: string;
}
export interface CreatePaymentResponse {
  paymentRequestId: string;
  paymentSessionId: string;
  appId: string;
  notifyUrl: string;
  cfToken: string;
  order: Order;
  customer: Customer;
  statusCode: number;
  responseStatus: string;
}

export interface CreatePaymentResponseWeb {
  paymentLink: string;
  orderToken: string;
  paymentRequestId: string;
  paymentSessionId: string;
  currencyCode: string;
  statusCode: number;
  responseStatus: string;
  qrImage?: string;
}

export interface CreateCFOrderRequest {
  order_token: string;
  payment_method: Method;
}

export interface Method {
  upi: Upi;
}

export interface CreateCFOrderResponse {
  payment_method: string;
  channel: string;
  action: string;
  data: Data;
  cf_payment_id: number;
}

export interface Data {
  url: any;
  payload: any;
  content_type: any;
  method: any;
}

export interface CreatePaymentRequest {
  sellerWorkspaceId?: string;
  buyerWorkspaceId?: string;
  inviteId?: string;
  amountPaid: number;
  currencyCode: string;
  paymentProvider?: string;
  generateQr?: boolean;
  env?: string;
  virtualVpaId?: string;
  docNumberToClear?: string[];
  paymentDate?: string;
  teamMemberId?: string;
  paymentMode?: string;
}

export interface SellerDetails {
  id: string;
  name: string;
}
export interface BuyerDetails {
  id: string;
  name: string;
}

export interface RequestPayload {
  orderId: string;
  orderAmount: number;
  orderCurrency: string;
}

export interface ResponsePayload {
  status: string;
  cftoken: string;
  message: string;
}

export interface GetPaymentResponse {
  id: string;
  createdDate: Date;
  updatedDate: string;
  transactionDate?: any;
  amountPaid: number;
  status: string;
  currencyCode: string;
  paymentProvider: string;
  paymentMode: string;
  paymentLink?: any;
  gatewayCode: string;
  gatewayRefCode: string;
  requestPayload: RequestPayload;
  responsePayload: ResponsePayload;
  paymentResponse: PaymentResponse;
  responseCode: string;
  paymentResponseMsg: string;
  paymentTransaction: PaymentTransaction;
  statusCode: number;
  responseStatus: string;
}

export interface PaymentHistory {
  payments: Payment[];
  total: number;
  startRecord: number;
  endRecord: number;
  statusCode: number;
  responseStatus: string;
}

export interface OrderMeta {
  notify_url: string;
  return_url: string;
}

export interface CustomerDetails {
  customer_id: string;
  customer_email: string;
  customer_phone: string;
}

export interface RequestPayload {
  order_id: string;
  order_meta: OrderMeta;
  order_amount: number;
  order_currency: string;
  customer_details: CustomerDetails;
}

export interface Refunds {
  url: string;
}

export interface Payments {
  url: string;
}

export interface OrderMeta2 {
  notify_url: string;
  return_url: string;
  payment_methods?: any;
}

export interface Settlements {
  url: string;
}

export interface CustomerDetails2 {
  customer_id: string;
  customer_name?: any;
  customer_email: string;
  customer_phone: string;
}

export interface ResponsePayload {
  entity: string;
  refunds: Refunds;
  order_id: string;
  payments: Payments;
  order_meta: OrderMeta2;
  order_note?: any;
  order_tags?: any;
  cf_order_id: number;
  order_token: string;
  settlements: Settlements;
  order_amount: number;
  order_splits: any[];
  order_status: string;
  payment_link: string;
  order_currency: string;
  customer_details: CustomerDetails2;
  order_expiry_time: Date;
}

export interface Order {
  order_id: string;
  order_tags?: any;
  order_amount: number;
  order_currency: string;
}

export interface Netbanking {
  channel?: any;
  netbanking_bank_code: string;
  netbanking_bank_name: string;
}

export interface PaymentMethod {
  netbanking: Netbanking;
  card: Card;
  upi: Upi;
  wallet: wallet;
}
export interface wallet {
  wallet_name: string;
}
export interface Upi {
  upi_id: string;
  channel?: any;
}
export interface Card {
  channel?: any;
  card_type: string;
  card_number: string;
  card_country: string;
  card_network: string;
  card_bank_name: string;
}

export interface Payment {
  auth_id?: any;
  payment_time: Date;
  cf_payment_id: number;
  payment_group: string;
  bank_reference: string;
  payment_amount: number;
  payment_method: PaymentMethod;
  payment_status: string;
  payment_message: string;
  payment_currency: string;
}

export interface CustomerDetails3 {
  customer_id: string;
  customer_name?: any;
  customer_email: string;
  customer_phone: string;
}

export interface Data {
  order: Order;
  payment: Payment;
  customer_details: CustomerDetails3;
}

export interface PaymentResponse {
  data: Data;
  type: string;
  event_time: Date;
  remitterVpa?: string;
}

export interface PaymentTransaction {
  id: string;
  createdDate: Date;
  updatedDate: Date;
  sellerWorkspaceId: string;
  buyerWorkspaceId: string;
  inviteId: string;
  paymentDate: Date;
  amountPaid: number;
  allocatedAmount: number;
  currencyCode: string;
  status: string;
  paymentType: string;
  paymentMode: string;
  paymentProvider?: any;
  proofOfPayment?: any;
  approvedBy?: any;
  createdBy: string;
  paymentRefCode: string;
  instrumentNumber?: any;
  instrumentDate?: any;
  bankName: string;
  bankAccountNumber?: any;
  bankReference: string;
  channel?: any;
  cardNumber?: any;
  cardNetwork?: any;
  cardType?: any;
  bankCode: string;
  upiId?: any;
  narration?: any;
  source: string;
  teamMemberId?: any;
}

export interface UPIIdResponse {
  virtualVpaId: string;
  vAccountId: string;
  inviteId: string;
  upi: string;
  statusCode: number;
  responseStatus: string;
}

export interface RecordPaymentRequest {
  sellerWorkspaceId: string;
  inviteId: string;
  amountPaid: number;
  currencyCode: string;
  automatedInvoiceClearance: boolean;
  invoice: Invoice[];
  paymentMode: string;
  paymentRefCode?: string;
  paymentDate: string;
  instrumentNumber?: string;
  instrumentDate?: string;
  bankName?: string;
  bankAccountNumber?: string;
  bankReference?: string;
  narration?: string;
  source?: string;
  teamMemberId: string;
}
export interface RecordPaymentResponse {
  id: string;
  createdDate: string;
  updatedDate: string;
  sellerWorkspaceId: string;
  buyerWorkspaceId: string;
  inviteId: string;
  paymentDate: string;
  amountPaid: number;
  allocatedAmount: number;
  currencyCode: string;
  status: string;
  paymentType: string;
  paymentMode: string;
  paymentProvider: string;
  proofOfPayment: string;
  approvedBy: string;
  createdBy: string;
  paymentRefCode: string;
  instrumentNumber: string;
  instrumentDate: string;
  bankName: string;
  bankAccountNumber: string;
  bankReference: string;
  channel: string;
  cardNumber: string;
  cardNetwork: string;
  cardType: string;
  bankCode: string;
  upiId: string;
  narration: string;
  source: string;
  teamMemberId: string;
  invoices: Invoices[];
  statusCode: number;
  responseStatus: string;
}
interface Invoices {
  docNumber?: string;
  invoiceId?: string;
  amountCleared?: number;
  createdDate?: string;
  updatedDate?: string;
  referenceInvoiceNumber?: string;
  supTyp?: string;
  invoiceDate?: string;
  dueDate?: string;
  invoiceAmount?: number;
  paidAmount?: number;
  irn?: string;
  customerName?: string;
  docType?: string;
  gstin?: string;
  status?: string;
  invoiceStatus?: string;
  workspaceId?: string;
  inviteId?: string;
  linkedWorkspacesId?: string;
}

export interface PaymentSlice {
  qrImage?: string;
}
export interface FieldCollectionPayments {
  payments: PaymentMode[];
  totalAmount: number;
  statusCode: number;
  responseStatus: string;
}

export interface FieldCollectionPaymentRequestParams {
  teamMemberId: string;
  sellerWorkspaceId: string;
  startDate: string;
  endDate: string;
}
interface PaymentMode {
  paymentMode: PaymentMedium;
  amount: number;
}

export interface PaymentCollectionReportsRequestParams {
  sellerWorkspaceId: string;
  pageSize: number;
  pageNo: number;
  endDate: string;
  startDate: string;
  teamMemberId: string;
}

export interface PaymentCollectionReportsResponse {
  payments?: PaymentsEntity[] | null;
  total: number;
  startRecord: number;
  endRecord: number;
  statusCode: number;
  responseStatus: string;
}
export interface PaymentsEntity {
  id: string;
  paymentRequestId?: null;
  customerName: string;
  customerCode: string;
  paymentDate: string;
  paymentRefCode: string;
  amountPaid: number;
  paymentType: string;
  paymentMode: string;
  upiId?: null;
  inviteId: string;
  sellerWorkspaceId: string;
  buyerWorkspaceId: string;
  status: string;
  createdDate: string;
  updatedDate: string;
  currencyCode: string;
  instrumentNumber: string;
  instrumentDate?: null;
  bankName: string;
  bankAccountNumber?: null;
  narration?: null;
  teamMemberId: string;
  teamMemberName: string;
  source: string;
  invoices?: InvoicesEntity[] | null;
}
export interface InvoicesEntity {
  id: string;
  createdDate: string;
  updatedDate: string;
  referenceInvoiceNumber: string;
  supTyp: string;
  invoiceDate: string;
  dueDate: string;
  invoiceAmount: number;
  paidAmount: number;
  irn: string;
  customerName: string;
  docType: string;
  gstin?: null;
  docNumber: string;
  status: string;
  invoiceStatus: string;
  narration?: null;
  workspaceId: string;
  inviteId: string;
  linkedWorkspacesId: string;
  amountCleared: number;
}

export interface OfflinePaymentDetails {
  paymentDate: string;
  paymentId: string;
}

export interface PaymentSettingsResponse {
  linkSetting: PaymentSettings;
  statusCode: number;
  responseStatus: string;
}

export interface PaymentSettings {
  id: string;
  createdDate: string;
  updatedDate: string;
  sellerWorkspaceId: string;
  expiry: number;
  creditCard: number;
  debitCard: number;
  upi: number;
  netBanking: number;
  wallet: number;
}

export interface FormValue {
  value?: any;
  key: string;
  isValid?: boolean;
}

export enum ManualTabs {
  CHQ = 'Cheque',
  CASH = 'Cash',
}

export interface ValidateGstInResponse {
  firm_name: string;
  address: UserAddress;
  valid_till: string;
  gstin_number: string;
}

export interface UserAddress {
  bnm: string;
  st: string;
  loc: string;
  bno: string;
  dst: string;
  lt: string;
  locality: string;
  pncd: string;
  landMark: string;
  stcd: string;
  geocodelvl: string;
  flno: string;
  lg: string;
}

export interface ValidateGstInRequest {
  gstNumber: string;
  sellerWorkspaceId: string;
  skipWorkspaceCheck?: boolean;
  customerId: string;
}
