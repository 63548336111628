import { BulkLedgerCoulmnkey, ColumnConfig } from '../model';

export const bulkLedgerUploadConfig: Record<BulkLedgerCoulmnkey, ColumnConfig> =
  {
    docNumber: {
      name: 'Doc Number',
    },
    customerCode: {
      name: 'Customer Code',
    },
    customerFirmName: {
      name: 'Customer Firm Name',
    },
    transactionType: {
      name: 'Transaction Type',
    },
    transactionDate: {
      name: 'Transaction Date',
    },
    transactionAmount: {
      name: 'Transaction Amount',
    },
    docReferenceNumber: {
      name: 'Doc Reference Number',
    },
    narration: {
      name: 'Narration',
    },
    transactionReferenceCode: {
      name: 'Transaction Reference Code',
    },
    invoiceReferenceNumber: {
      name: 'Invoice Reference Number',
    },
    clearingDocNumber: {
      name: 'Clearing Doc Number',
    },
    clearingDate: {
      name: 'Clearing Date',
    },
    chequeReferenceNumber: {
      name: 'Cheque Reference Number',
    },
    amountCleared: {
      name: 'Amount Cleared',
    },
    dueDate: {
      name: 'Due Date',
    },
    erpOrderNumber: {
      name: 'ERP Order Number',
    },
    actions: {
      name: '',
      field: 'component',
    },
  };
