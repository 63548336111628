import { Api } from '../api/root-api';
import {
  Group,
  HierarchyGroups,
  HierarchyList,
  HierarchyResponse,
  CustomerGroupsResponse,
  CustomerGroupsParams,
} from './model';

export const hierarchyApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    getHierarchyList: builder.query<
      HierarchyResponse,
      { workspaceId: string; groupsHierarchyId?: string }
    >({
      query(arg) {
        return {
          url: `groups-hierarchies/${arg.workspaceId}`,
          method: 'GET',
          params: { groupsHierarchyId: arg.groupsHierarchyId },
        };
      },
    }),
    updateHierarchyName: builder.mutation<
      HierarchyList,
      { workSpaceId: string; name: string; groupsHierarchyId: string }
    >({
      query(arg) {
        const { workSpaceId, ...params } = arg;
        return {
          url: `customer/hierarchy/${workSpaceId}`,
          method: 'POST',
          body: params,
        };
      },
    }),
    updateHierarchyCustomerGroupName: builder.mutation<
      Group,
      { workSpaceId: string; name: string; groupId: string }
    >({
      query(arg) {
        const { workSpaceId, ...params } = arg;
        return {
          url: `edit/customer/group/${workSpaceId}`,
          method: 'PUT',
          body: params,
        };
      },
    }),
    createHierarchy: builder.mutation<
      any,
      { workspaceId: string; hierarchyObj: any }
    >({
      query(arg) {
        const { workspaceId, hierarchyObj } = arg;
        return {
          url: `customer/hierarchy/${workspaceId}`,
          method: 'POST',
          body: hierarchyObj,
        };
      },
    }),
    getHierarchyGroups: builder.query<
      HierarchyGroups,
      {
        workspaceId: string;
        groupHierarchyId: string;
        customerGroupId?: string;
      }
    >({
      query(arg) {
        const { workspaceId, ...params } = arg;
        const payload = { ...params, lazy: true };
        return {
          url: `customergroups/hierarchy/${workspaceId}`,
          method: 'GET',
          params: payload,
        };
      },
    }),
    getCustomersGroups: builder.query<
      CustomerGroupsResponse[],
      CustomerGroupsParams
    >({
      query: (params) => {
        const { workspaceId, ...otherParams } = params;
        return {
          url: `teams/groups/${workspaceId}`,
          params: otherParams,
          method: 'GET',
        };
      },
    }),
  }),
});

export const {
  useLazyGetHierarchyListQuery,
  useUpdateHierarchyCustomerGroupNameMutation,
  useUpdateHierarchyNameMutation,
  useCreateHierarchyMutation,
  useLazyGetHierarchyGroupsQuery,
  useLazyGetCustomersGroupsQuery,
} = hierarchyApi;
