import { ColumnConfig, ZoeyPendingInvoiceColumnKey } from '../../model';

export const zoeyPendingInvoiceConfig: Record<
  ZoeyPendingInvoiceColumnKey,
  ColumnConfig
> = {
  invoice: {
    name: 'Invoice',
  },
  status: {
    name: 'Status',
    isComponent: true,
  },
  customerName: {
    name: 'Customer Name',
    field: 'avatarNameStatus',
  },
  dueDate: {
    name: 'Due Date',
    alignment: 'flex-end',
  },
  invoiceAmt: {
    name: 'Invoice Amount',
    alignment: 'flex-end',
  },
  action: {
    name: '',
  },
};
