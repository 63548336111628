import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../root';
import { ordersApi } from './api';
import {
  CreateOrderRequest,
  CreateOrderResponse,
  FulfillmentLocation,
  FulfillmentLocationResponse,
  PrincipalWorkspaceDetails,
  ActiveOrder,
  OrderLine,
  PoListOrderResponse,
  AddItemToActiveOrderResponse,
  PoFile,
  POLineData,
  cartSummary,
  AddItemToActiveOrderRequest,
  PoOrderDetailsResponse,
  MappingEventData,
  OrderData as OrderData1,
  LineData,
  ComOrderDataLine,
} from './model';
import _ from 'lodash';
import { Platform } from 'react-native';

const SLICE_FEATURE_KEY = 'Orders';
const emptyPOFileArray: PoFile[] = [];
interface OrderData {
  order: CreateOrderResponse | null;
  request: CreateOrderRequest | null;
  principalWorkspaceDetails: PrincipalWorkspaceDetails;
  fulfillmentLocations: FulfillmentLocation[] | null;
  modifiedProductIds: number[];
  activeOrder: ActiveOrder | null;
  cartOrderLine: OrderLine[] | null;
  currentOrderId: string;
  activePOOrder: PoListOrderResponse | null;
  selectedCustomer: {
    id: string;
    name: string;
  };
  defaultSelectOrderItems: boolean;
  cartSummary: cartSummary;
  pofilesDetailMap: Map<string, PoOrderDetailsResponse>;
  currentPOFile?: PoOrderDetailsResponse;
  latestOrder?: OrderData1;
  reOrderProductsList: ComOrderDataLine[];
  reOrderCustomerId: string;
  reOrderProductsSelectedList: LineData[];
  selectedOrders: PoFile[];
}

export const initialOrderState: OrderData = {
  order: null,
  request: null,
  principalWorkspaceDetails: {
    sellerId: '',
    customerId: '',
    inviteId: '',
    id: '',
    isCfa: false,
    spaceName: '',
  },
  fulfillmentLocations: null,
  modifiedProductIds: [],
  activeOrder: null,
  cartOrderLine: [],
  currentOrderId: '',
  activePOOrder: null,
  selectedCustomer: {
    id: '',
    name: '',
  },
  defaultSelectOrderItems: false,
  cartSummary: {
    totalFreeQuantity: 0,
    totalSKUCount: 0,
    grossOrderValue: 0,
    totalDiscount: 0,
    totalTax: 0,
    totalItemsCount: 0,
    totalOrderValue: 0,
    grossOrderNumber: 0,
  },
  pofilesDetailMap: new Map<string, PoOrderDetailsResponse>(),
  reOrderCustomerId: '',
  reOrderProductsList: [],
  reOrderProductsSelectedList: [],
  selectedOrders: [],
};

export const ordersSlice = createSlice({
  name: SLICE_FEATURE_KEY,
  initialState: initialOrderState,
  reducers: {
    addOrder(state, action: PayloadAction<CreateOrderResponse>) {
      state.order = action.payload;
    },
    addFulfillments(state, action: PayloadAction<FulfillmentLocationResponse>) {
      state.fulfillmentLocations = action.payload.locations;
    },
    addOrderRequest(state, action: PayloadAction<CreateOrderRequest>) {
      state.request = action.payload;
    },
    updatePrincipalWorkspaceDetails(
      state,
      action: PayloadAction<PrincipalWorkspaceDetails>
    ) {
      state.principalWorkspaceDetails = action.payload;
    },
    updateModifiedProductIds(state, action: PayloadAction<number[]>) {
      state.modifiedProductIds = action.payload;
    },

    updateActiveOrder(state, action: PayloadAction<ActiveOrder>) {
      state.activeOrder = action.payload;
    },
    updateCurrentOrderId(state, action: PayloadAction<string>) {
      state.currentOrderId = action.payload;
    },
    updateActivePOOrder(state, action: PayloadAction<PoListOrderResponse>) {
      state.activePOOrder = action.payload;
    },
    updateSelectedCustomerDetails(
      state,
      action: PayloadAction<{ id: string; name: string }>
    ) {
      state.selectedCustomer = action.payload;
    },
    updateDefaultSelectOrderItems(state, action: PayloadAction<boolean>) {
      state.defaultSelectOrderItems = action.payload;
    },
    updateCartSummary(state, action: PayloadAction<cartSummary>) {
      state.cartSummary = action.payload;
    },
    updateSelectedOrder(state, action: PayloadAction<PoFile[]>) {
      state.selectedOrders = action.payload;
    },
    updatePOFileLine(
      state,
      action: PayloadAction<{
        response: AddItemToActiveOrderResponse;
        request: AddItemToActiveOrderRequest;
        variantId: number[];
      }>
    ) {
      let activeOrder: PoListOrderResponse = JSON.parse(
        JSON.stringify(state.activePOOrder)
      );
      if (action.payload && action.payload.response.orders) {
        action.payload.response.orders.forEach((order) => {
          const updatedLines: POLineData[] = order.orderLine.map((line) => ({
            id: line.id?.toString(),
            orderLineId: line.id,
            proratedLinePrice: line.proratedLinePrice.toString(),
            proratedLinePriceWithTax: line.proratedLinePriceWithTax.toString(),
            linePrice: line.linePrice.toString(),
            linePriceWithTax: line.linePriceWithTax.toString(),
            discountedLinePrice: line.discountedLinePrice.toString(),
            product: {
              sku: line.sku || line.description,
              description: line.description || line.skuName,
              name: line.skuName,
              productVariantId: line.productVariantId,
              dtProductName: line?.skuName,
            },
            discountedLinePriceWithTax:
              line.discountedLinePriceWithTax.toString(),
            unitPrice: line.unitPrice,
            quantity: line.quantity,
            productVariantId: line.productVariantId,
            divisionId: line.divisionId,
            divisionName: line.divisionName,
            status: 'ORDER_CREATED',
            CFA: order.CFA,
            headDivisionId: order?.headDivisionId ?? '',
            headDivisionName: order.headDivisionName,
            orderId: order.id,
            promotionId: line?.adjustments?.[0]?.promotionId ?? 0,
            refOrderNumber: '',
            CFAId: order.fullFillmentLocationId,
            modeOfOrder:
              order?.importSource ||
              action?.payload.request?.source ||
              'manual',
            schemeType: '',
            discountedUnitPrice: Number(line.proratedLinePrice) / line.quantity,
            lineDiscount: {
              discount: line.adjustments?.[0]?.lineDiscount?.toString() || '0',
              discountType: 'direct',
            },
            freeQuantity: 0,
            promotionName: line.adjustments?.[0]?.promotionCode || '',
          }));
          const matchingFileIndex = activeOrder?.files?.findIndex(
            (file) => file.id === order.pofileId
          );
          if (matchingFileIndex > -1) {
            activeOrder?.files[matchingFileIndex].lines?.forEach((oldLine) => {
              if (oldLine.orderId) {
                if (String(oldLine?.orderId) !== String(order?.id)) {
                  updatedLines.push(oldLine);
                }
                // else {
                //   const matchedLineIndex = updatedLines.findIndex(
                //     (line) =>
                //       Number(line.orderLineId) === Number(oldLine.orderLineId)
                //   );
                //   if (matchedLineIndex > -1) {
                //     const newUpdatedLine = oldLine;
                //     newUpdatedLine.quantity =
                //       updatedLines[matchedLineIndex].quantity;
                //     updatedLines[matchedLineIndex] = newUpdatedLine;
                //   }
                // }
              }
            });

            if (order.importSource !== 'manual') {
              const linesWithoutOrderLineId =
                activeOrder.files[matchingFileIndex].lines?.filter(
                  (line) => !line.orderLineId
                ) ?? [];
              activeOrder.files[matchingFileIndex].lines = [
                ...updatedLines,
                ...linesWithoutOrderLineId,
              ];
            } else {
              // Manual order case:
              activeOrder.files[matchingFileIndex].lines = updatedLines.sort(
                (orderLineA, orderLineB) =>
                  Number(orderLineA?.orderLineId) -
                  Number(orderLineB?.orderLineId)
              );
            }
            activeOrder.files[matchingFileIndex].skuCount =
              activeOrder.files[matchingFileIndex].lines?.length ?? 0;
          } else {
            const newFile: PoFile = {
              id: order.pofileId,
              number: '2',
              uploadedDate: new Date().toISOString(),
              skuCount: updatedLines?.length,
              totalItemsCount: 0,
              importSource: 'manual',
              lines: updatedLines,

              subTotal: '',
              subTotalWithTax: '',
              taxAmount: '',
              discount: '',
              total: '',
              customerId: '',
              totalWithTax: 0,
              proratedPrice: '',
              status: '',
              proratedPriceWithTax: 0,
            };
            let updatedActiveOrder = {
              ...activeOrder,
              files: activeOrder?.files
                ? [...activeOrder?.files, newFile]
                : [newFile],
            };
            activeOrder = updatedActiveOrder;
          }
        });
      }
      state.activePOOrder = activeOrder;
    },
    updatePOFilesDetailMap(
      state,
      action: PayloadAction<PoOrderDetailsResponse>
    ) {
      const poFileDetails = action.payload;
      const newPofilesDetailMap = state.pofilesDetailMap;
      newPofilesDetailMap.set(poFileDetails.pofileId, poFileDetails);
      state.pofilesDetailMap = newPofilesDetailMap;
    },
    updatePOFile(
      state,
      action: PayloadAction<{ response: PoOrderDetailsResponse }>
    ) {
      const activeOrder: PoListOrderResponse = JSON.parse(
        JSON.stringify(state.activePOOrder)
      );

      const matchedFileIndex = activeOrder.files.findIndex(
        (poFile) => poFile.id === action.payload.response?.pofileId
      );
      if (matchedFileIndex > -1) {
        const newPOFile = activeOrder.files[matchedFileIndex];
        // @ts-ignore
        newPOFile.lines =
          action.payload.response.lines?.map((x) => ({
            id: x?.poFileLineId ?? '',
            divisionName: x?.divisionName ?? '',
            proratedLinePrice: x?.proratedLinePrice ?? '',
            proratedLinePriceWithTax: x?.proratedLinePriceWithTax ?? '',
            status: x?.status ?? '',
            orderLineId: x?.orderLineId ?? 1,
            productVariantId: x?.productVariantId ?? 1,
            linePrice: x?.linePrice ?? '',
            linePriceWithTax: x?.linePriceWithTax ?? '',
            discountedLinePrice: x?.discountedLinePrice ?? '',
            discountedLinePriceWithTax: x?.discountedLinePriceWithTax ?? '',
            unitPrice: x?.unitPrice ?? 0,
            CFA: x.cfa,
            quantity: x.quantity,
            uploadedQty: x?.uploadedQty,
            divisionId: x.divisionId,
            orderId: x.orderId ?? 0,
            product: {
              sku: x?.productVariant?.sku ?? '',
              MOQ: x?.MOQ ?? 1,
              description: x?.productVariant?.translations?.[0]?.name ?? '',
              name: x?.productVariant?.translations?.[0]?.name ?? '',
              packSize: x.packSize ?? 1,
              productVariantId: x.productVariantId ?? 1,
              dtProductName: x.distributorProductName,
            },
            promotionId: x.promotionId,
            headDivisionId: x.headDivisionId ?? '',
            headDivisionName: x.headDivisionName ?? '',
            refOrderNumber: x.refOrderNumber ?? '',
            CFAId: x.cfaId ?? '',
            modeOfOrder: newPOFile?.importSource ?? 'upload',
            schemeType: '',
            discountedUnitPrice: x?.discountedUnitPrice ?? '',
            taxCategory: x?.taxLines?.[0]?.taxCategory ?? '',
            errorMessage: x.errorMessage ?? '',
          })) ?? [];

        activeOrder.files[matchedFileIndex] = newPOFile;
      }

      state.activePOOrder = activeOrder;
    },
    updatePOFileLineByEvent(
      state,
      action: PayloadAction<{
        mappingEventData: MappingEventData;
        poFileIndex: number;
        poFileLineIndex: number;
      }>
    ) {
      const eventData = action.payload?.mappingEventData;
      const matchingFileIndex = action.payload?.poFileIndex;
      const matchingLineIndex = action.payload?.poFileLineIndex;

      const activePOOrders: PoListOrderResponse = JSON.parse(
        JSON.stringify(state.activePOOrder)
      );

      if (
        activePOOrders?.files &&
        activePOOrders?.files[matchingFileIndex]?.lines
      ) {
        const oldLines = activePOOrders.files[matchingFileIndex].lines;
        if (oldLines) {
          let updatedLineData: POLineData = oldLines[matchingLineIndex];
          updatedLineData = {
            ...updatedLineData,
            divisionName: eventData?.divisionName,
            status: eventData?.status,
            orderLineId: eventData?.orderLineId,
            productVariantId: eventData?.productVariantId,
            quantity: eventData?.quantity,
            errorMessage: eventData?.errorMessage,
            taxCategory: eventData?.taxCategory,
            promotionId: eventData?.promotionId,
            unitPrice: eventData?.orderLine?.unitPrice,
            discountedUnitPrice: eventData?.orderLine?.discountedUnitPrice,
            linePriceWithTax:
              eventData?.orderLine?.linePriceWithTax?.toString(),
            linePrice: eventData?.orderLine?.linePrice?.toString(),
            proratedLinePriceWithTax:
              eventData?.orderLine?.proratedLinePriceWithTax?.toString(),
            proratedLinePrice:
              eventData?.orderLine?.proratedLinePrice?.toString(),
            discountedLinePrice:
              eventData?.orderLine?.discountedLinePrice?.toString(),
            discountedLinePriceWithTax:
              eventData?.orderLine?.discountedLinePriceWithTax?.toString(),
            CFAId: eventData?.orderLine?.CFAId,
            CFA: eventData?.orderLine?.CFA,
            orderId: eventData?.orderLine?.orderId,
            product: {
              name: '',
              dtProductName: eventData?.distributorProductName,
              description: eventData?.distributorProductName,
              sku: eventData?.principalErpSku,
              MOQ: eventData?.moq,
              packSize: eventData?.packSize,
              productVariantId: eventData?.productVariantId,
            },
          };
          const updatedPOLines: POLineData[] = [...oldLines];
          updatedPOLines[matchingLineIndex] = updatedLineData;
          activePOOrders.files[matchingFileIndex].lines = updatedPOLines;
        }
      }

      state.activePOOrder = activePOOrders;
    },
    updateCurrentPoFile(state, action: PayloadAction<PoOrderDetailsResponse>) {
      state.currentPOFile = action.payload;
    },
    updateLatestOrder(state, action: PayloadAction<OrderData1>) {
      state.latestOrder = action.payload;
    },
    updateReOrderProductList(state, action: PayloadAction<ComOrderDataLine[]>) {
      state.reOrderProductsList = action.payload;
    },
    updateReOrderProductsSelectedList(
      state,
      action: PayloadAction<LineData[]>
    ) {
      state.reOrderProductsSelectedList = action.payload;
    },
    updateReorderCustomerId(state, action: PayloadAction<string>) {
      state.reOrderCustomerId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      ordersApi.endpoints.createOrUpdateOrder.matchFulfilled,
      (state, action) => {
        ordersSlice.caseReducers.addOrder(state, action);
      }
    );
    builder.addMatcher(
      ordersApi.endpoints.getOrderDetail.matchFulfilled,
      (state, action) => {
        ordersSlice.caseReducers.addOrder(state, action);
      }
    );
    builder.addMatcher(
      ordersApi.endpoints.listCart.matchFulfilled,
      (state, action) => {
        ordersSlice.caseReducers.updateActivePOOrder(state, action);
      }
    );
    builder.addMatcher(
      ordersApi.endpoints.getCart.matchFulfilled,
      (state, action) => {
        if (Platform.OS !== 'web')
          ordersSlice.caseReducers.updateActivePOOrder(state, action);
      }
    );
    builder.addMatcher(
      ordersApi.endpoints.getFulfillmentLocations.matchFulfilled,
      (state, action) => {
        ordersSlice.caseReducers.addFulfillments(state, action);
      }
    );

    builder.addMatcher(
      ordersApi.endpoints.getActiveOrder.matchFulfilled,
      (state, action) => {
        ordersSlice.caseReducers.updateActiveOrder(state, action);
      }
    );
    builder.addMatcher(
      ordersApi.endpoints.cartSummary.matchFulfilled,
      (state, action) => {
        ordersSlice.caseReducers.updateCartSummary(state, action);
      }
    );
    // builder.addMatcher(
    //   ordersApi.endpoints.getTrackPODetails.matchFulfilled,
    //   (state, action) => {
    //     // console.log('action => ', action.payload);
    //     const newAction = { ...action, payload: { response: action.payload } };
    //     ordersSlice.caseReducers.updatePOFile(state, newAction);
    //   }
    // );
  },
});

export const ordersReducer = ordersSlice.reducer;

export const {
  addOrder,
  updatePrincipalWorkspaceDetails,
  addOrderRequest,
  updateModifiedProductIds,
  updateActiveOrder,
  updateCurrentOrderId,
  updateSelectedCustomerDetails,
  updateDefaultSelectOrderItems,
  updatePOFileLine,
  updatePOFile,
  updateCartSummary,
  updatePOFileLineByEvent,
  updateCurrentPoFile,
  updateLatestOrder,
  updateReOrderProductList,
  updateSelectedOrder,
  updateReOrderProductsSelectedList,
  updateReorderCustomerId,
} = ordersSlice.actions;

export const getOrdersSliceState = (rootState: RootState): OrderData =>
  rootState[SLICE_FEATURE_KEY];

// export const getActiveOrder = createSelector(
//   getOrdersSliceState,
//   (state) => state?.order
// );
export const getPrincipalWorkspaceDetails = createSelector(
  getOrdersSliceState,
  (state) => state?.principalWorkspaceDetails
);

export const getOrderRequest = createSelector(
  getOrdersSliceState,
  (state) => state?.request
);
export const getFulfillmentLocations = createSelector(
  getOrdersSliceState,
  (state) => state?.fulfillmentLocations
);
export const getModifiedProductIds = createSelector(
  getOrdersSliceState,
  (state) => state?.modifiedProductIds
);

export const getActiveOrder = createSelector(
  getOrdersSliceState,
  (state) => state?.activeOrder
);

export const getSelectedOrders = createSelector(
  getOrdersSliceState,
  (state) => state?.selectedOrders
);

export const getActiveOrderLines = createSelector(
  getOrdersSliceState,
  (state) => _.flatMap(state?.activeOrder?.orders, 'orderLine')
);

export const getActivePOOrderLines = createSelector(
  getOrdersSliceState,
  (state) =>
    _.flatMap(state?.activePOOrder?.files, 'lines').sort(
      (line: POLineData) => line?.orderLineId - line?.orderLineId
    )
);

export const getActiveManualPOOrderLines = createSelector(
  getActivePOOrderLines,
  (lines) => lines?.filter((line) => line?.modeOfOrder === 'manual')
);

export const getCartSummary = createSelector(
  getOrdersSliceState,
  (state) => state?.cartSummary
);

export const getActivePoOrderFiles = createSelector(
  getOrdersSliceState,
  (state) => state?.activePOOrder?.files ?? emptyPOFileArray
);
export const getDefaultSelectOrderItems = createSelector(
  getOrdersSliceState,
  (state) => state?.defaultSelectOrderItems
);
export const getOrder = createSelector(
  [getOrdersSliceState, (filter) => filter],
  (state, id: number) => {
    return {
      order: state?.activeOrder?.orders?.find((x) => x.id === id),
      summary: state?.activeOrder?.summary,
    };
  }
);

export const getCurrentOrderId = createSelector(
  getOrdersSliceState,
  (state) => state?.currentOrderId
);

export const getSelectedCustomerDetails = createSelector(
  getOrdersSliceState,
  (state) => state?.selectedCustomer
);

export const getPofilesDetailMap = createSelector(
  getOrdersSliceState,
  (state) => state.pofilesDetailMap
);

export const getCurrentPOFile = createSelector(
  getOrdersSliceState,
  (state) => state.currentPOFile
);

export const getLatestOrder = createSelector(
  getOrdersSliceState,
  (state) => state?.latestOrder
);

export const getReOrderProductsList = createSelector(
  getOrdersSliceState,
  (state) => state?.reOrderProductsList
);

export const getReOrderProductsSelectedList = createSelector(
  getOrdersSliceState,
  (state) => state?.reOrderProductsSelectedList
);

export const getReOrderCustomerId = createSelector(
  getOrdersSliceState,
  (state) => state?.reOrderCustomerId
);
