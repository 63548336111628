import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { isEmpty, isNull } from 'lodash';
import { RootState } from '../root';
import { ProductVariantStateData } from './model';

export const BUYER_TO_SELLER_FEATURE_KEY = 'buyerToSellerData';

export interface BuyerToSellerDataState {
  buyerSelectedIds: (string | number)[];
  hasFetchedEmptyProducts: boolean;
  createCampaignData: {
    title: string;
    description: string;
    path: string;
    type: string;
  };
  productForCampaign: ProductVariantStateData[];
  needToReload: boolean;
}

export const initialBuyerToSellerState: BuyerToSellerDataState = {
  buyerSelectedIds: [],
  hasFetchedEmptyProducts: false,
  createCampaignData: {
    title: '',
    description: '',
    path: '',
    type: '',
  },
  productForCampaign: [],
  needToReload: false,
};

export const buyerToSellerSlice = createSlice({
  name: BUYER_TO_SELLER_FEATURE_KEY,
  initialState: initialBuyerToSellerState,
  reducers: {
    updatedBuyerSelectedIds(
      state,
      action: PayloadAction<string | number | null>
    ) {
      if (!isNull(action.payload)) {
        const id = action.payload;
        const index = state.buyerSelectedIds.indexOf(id);

        if (index === -1) {
          state.buyerSelectedIds.push(id);
        } else {
          state.buyerSelectedIds.splice(index, 1);
        }
      } else {
        state.buyerSelectedIds = [];
      }
    },
    updateHasFetchedEmptyProducts(state, action: PayloadAction<boolean>) {
      state.hasFetchedEmptyProducts = action.payload;
    },
    updateCreateCampaignData(
      state,
      action: PayloadAction<{
        title: string;
        description: string;
        path: string;
        type: string;
      }>
    ) {
      if (!isNull(action.payload)) {
        state.createCampaignData = action.payload;
      } else {
        state.createCampaignData = {
          title: '',
          description: '',
          path: '',
          type: '',
        };
      }
    },
    updateProductForCampiagn(
      state,
      action: PayloadAction<ProductVariantStateData[]>
    ) {
      if (!isEmpty(action.payload)) {
        state.productForCampaign = action.payload;
      } else {
        state.productForCampaign = [];
      }
    },
    updateNeedToReloadApi(state, action: PayloadAction<boolean>) {
      state.needToReload = action.payload;
    },
  },
});

export const buyerToSellerReducer = buyerToSellerSlice.reducer;

export const {
  updatedBuyerSelectedIds,
  updateHasFetchedEmptyProducts,
  updateCreateCampaignData,
  updateProductForCampiagn,
  updateNeedToReloadApi,
} = buyerToSellerSlice.actions;

export const getBuyerToSellerSliceState = (
  rootState: RootState
): BuyerToSellerDataState => rootState[BUYER_TO_SELLER_FEATURE_KEY];

export const getBuyerSelectedIds = createSelector(
  getBuyerToSellerSliceState,
  (state) => state.buyerSelectedIds
);

export const getHasFetchedEmptyProducts = createSelector(
  getBuyerToSellerSliceState,
  (state) => state.hasFetchedEmptyProducts
);

export const getCreateCampaignData = createSelector(
  getBuyerToSellerSliceState,
  (state) => state.createCampaignData
);

export const getProductsForCampaign = createSelector(
  getBuyerToSellerSliceState,
  (state) => state.productForCampaign
);

export const getNeedToReloadApi = createSelector(
  getBuyerToSellerSliceState,
  (state) => state.needToReload
);
