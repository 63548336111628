import { Api } from '../api/root-api';
import {
  CustomerLedgerRequest,
  CustomerLedgerResponse,
  Ledger,
  LedgerTransactions,
  LedgerRequest,
  LedgerSummary,
  LedgerDownloadResponse,
  LedgerDownloadRequest,
  signedUrlResponse,
  signedUrlRequest,
  PartyAccountLedgerDetailsRequest,
  PartyAccountLedgerDetailsResponse,
  CustomerDivisionCreditLimitRequest,
  CustomerDivisionCreditLimitResponse,
} from './model';

export const ledgerApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    getCustomerLedger: builder.mutation<
      CustomerLedgerResponse,
      CustomerLedgerRequest
    >({
      query(CustomerLedgerRequest: CustomerLedgerRequest) {
        return {
          url: `invoiceservice/partyAccountBook/list`,
          method: 'GET',
          params: CustomerLedgerRequest,
        };
      },
    }),
    getCustomerDivisionCreditLimit: builder.mutation<
      CustomerDivisionCreditLimitResponse,
      CustomerDivisionCreditLimitRequest
    >({
      query(
        CustomerDivisionCreditLimitRequest: CustomerDivisionCreditLimitRequest
      ) {
        const { sellerWorkspaceId, ...otherParams } =
          CustomerDivisionCreditLimitRequest;
        return {
          url: `invoiceservice/creditLimit/${sellerWorkspaceId}`,
          method: 'POST',
          body: otherParams,
        };
      },
    }),
    getPartyLedger: builder.query<Ledger, { workspaceId: string }>({
      query(args) {
        return {
          url: `ledgers/party/${args.workspaceId}`,
          method: 'GET',
        };
      },
    }),
    getLedgerTransactions: builder.query<
      LedgerTransactions,
      { workspaceId: string; params: LedgerRequest }
    >({
      query(arg) {
        return {
          url: `ledgers/transactions/${arg.workspaceId}`,
          method: 'GET',
          params: arg.params,
        };
      },
      // transformResponse: (response: LedgerTransactions) => {
      //   const ledgerItems = response.records;
      //   const descLedgerItems = _.orderBy(
      //     ledgerItems,
      //     ['postingDate'],
      //     ['desc']
      //   );
      //   const groupedLedgerItems = _.groupBy(descLedgerItems, (item) => {
      //     return moment(item.postingDate).format('DD MMMM, YYYY');
      //   });
      //   const ledgerItemArray: LedgerList[] = [];
      //   Object.entries(groupedLedgerItems).map(([key, value]) => {
      //     ledgerItemArray.push({
      //       title: key,
      //       data: value,
      //     });
      //   });
      //   return {
      //     records: ledgerItemArray,
      //     endRecord: response.endRecord,
      //     startRecord: response.startRecord,
      //     totalCount: response.totalCount,
      //     voucherTypes: response.voucherTypes,
      //   };
      // },
    }),
    getLedgerSummary: builder.query<
      LedgerSummary,
      { workspaceId: string; params: LedgerRequest }
    >({
      query(arg) {
        return {
          url: `ledgers/summary/${arg.workspaceId}`,
          method: 'GET',
          params: arg.params,
        };
      },
    }),
    getPartyAccountLedgerDetails: builder.query<
      PartyAccountLedgerDetailsResponse,
      { workspaceId: string; params: PartyAccountLedgerDetailsRequest }
    >({
      query(arg) {
        return {
          url: `ledgers/summary/${arg.workspaceId}`,
          method: 'GET',
          params: arg.params,
        };
      },
    }),
    getLedgerDownload: builder.query<
      LedgerDownloadResponse,
      LedgerDownloadRequest
    >({
      query(arg) {
        const { workspaceId, ...otherParams } = arg;
        return {
          url: `ledgers/download/${arg.workspaceId}`,
          method: 'GET',
          params: otherParams,
        };
      },
    }),
    downloadPresignedUrl: builder.query<Blob, { presignedUrl: string }>({
      query(args) {
        return {
          url: `${args.presignedUrl}`,
          method: 'GET',
          responseHandler: (response) => response.text(),
        };
      },
    }),
    getLedgerSignUrl: builder.mutation<
      signedUrlResponse,
      { params: signedUrlRequest }
    >({
      query(arg) {
        return {
          url: `sign/url`,
          method: 'POST',
          body: arg.params,
        };
      },
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetCustomerLedgerMutation,
  useLazyGetPartyLedgerQuery,
  useLazyGetLedgerTransactionsQuery,
  useLazyGetLedgerSummaryQuery,
  useLazyGetLedgerDownloadQuery,
  useGetLedgerSignUrlMutation,
  useGetLedgerDownloadQuery,
  useGetPartyAccountLedgerDetailsQuery,
  useLazyGetPartyAccountLedgerDetailsQuery,
  useLazyDownloadPresignedUrlQuery,
  useGetCustomerDivisionCreditLimitMutation,
} = ledgerApi;
