import { ColumnConfig, CfaReportsColumnKey } from '../../model';

export const customerOrderReportConfig: Record<CfaReportsColumnKey, ColumnConfig> = {
  sNo: {
    name: 'S.No',
  },
  orderDate: {
    name: 'Date',
    sortField: 'orderDate',
  },
  tags: {
    name: 'Tags',
  },
  customerName: {
    name: 'Customer Name',
    sortField: 'customerfirmname',
  },

  skuCode: {
    name: 'SKU Code',
  },
  productTitle: {
    name: 'Product Title',
    field: 'component',
  },
  orders: {
    name: 'Orders',
    sortField: 'totalorders',
    alignment: 'flex-end',
  },
  orderQty: {
    name: ' Ord. Qty',
    sortField: 'totalqty',
    alignment: 'flex-end',
  },
  orderAmt: {
    name: 'Ord Amt',
    sortField: 'totalvalue',
    alignment: 'flex-end',
  },
};
