import { MultiToastType } from './enum';

export type toastDataType = {
  actionPerformed:
    | 'created'
    | 'moved'
    | 'edited'
    | 'deleted'
    | 'copied'
    | 'submitted'
    | 'updated'
    | 'approved'
    | 'sent'
    | 'uploaded'
    | 'notified'
    | 'parsed'
    | 'customers'
    | 'assigned'
    | 'removed';
  entity:
    | 'folder'
    | 'channel'
    | 'link'
    | 'message'
    | 'order'
    | 'file'
    | `input`
    | 'buyer'
    | 'ledger'
    | 'price list'
    | 'campaign'
    | 'customers'
    | 'products'
    | 'segments';
  status?: MultiToastType;
};

function toTitleCase(str: string) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase();
  });
}

export type ActionToastContent = {
  title: string;
  description: string;
  type: MultiToastType;
};

export const actionToastContent = (
  toastData: toastDataType
): ActionToastContent => {
  const actionPerformed = toastData.actionPerformed;
  const entity = toTitleCase(toastData.entity);

  return {
    title: `${entity} ${actionPerformed}`,
    description: `${entity} has been successfully ${actionPerformed}`,
    type: toastData.status || MultiToastType.SUCCESS,
  };
};
