import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, store } from '../root';
import { invoiceApi } from './api';
import { Customer, SelectedInvoices } from './model';

export const INVOICE_FEATURE_KEY = 'invoiceData';

export interface statusObject {
  id: string;
  value: string;
}
export interface InitialState {
  customerInvoiceItem: Customer;
  selectedInvoiceItems: SelectedInvoices[];
  amountToPay: number;
  filterText: string;
  customerSearch: boolean;
  companyCodeMap: Record<string, string>;
  selectedInvoiceStatus: statusObject;
}

export const initialState: InitialState = {
  customerInvoiceItem: {
    sellerWorkspaceId: '',
    inviteId: '',
    companyName: '',
    address: '',
    city: '',
    phone: '',
    email: '',
    outStandingBalance: 0,
    overdueAmount: 0,
    totalDebit: 0,
    totalCredit: 0,
    lastPayment: undefined,

    dueInvoice: undefined,
    openingBalance: null,
    closingBalance: 0,
    lastPaidInvoice: undefined,
    // invoiceAmount: 0,
    totalInvoices: 0,
    customerCode: '',
  },
  selectedInvoiceItems: [],
  amountToPay: 0,
  filterText: '',
  customerSearch: false,
  companyCodeMap: {},
  selectedInvoiceStatus: { id: '', value: 'All' },
};

export const invoiceSlice = createSlice({
  name: INVOICE_FEATURE_KEY,
  initialState: initialState,
  reducers: {
    updateCustomerInvoiceItemData(state, action: PayloadAction<Customer>) {
      state.customerInvoiceItem = action.payload;
    },
    updateSelectedInvoiceItems(
      state,
      action: PayloadAction<SelectedInvoices[]>
    ) {
      state.selectedInvoiceItems = action.payload;
    },
    updateAmountToPay(state, action: PayloadAction<number>) {
      state.amountToPay = action.payload;
    },
    filterList(state, action: PayloadAction<string>) {
      state.filterText = action.payload;
    },
    setCustomerSearch(state, action: PayloadAction<boolean>) {
      state.customerSearch = action.payload;
    },
    updateSelectedInvoiceStatus(state, action: PayloadAction<statusObject>) {
      state.selectedInvoiceStatus = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      invoiceApi.endpoints.getCustomerInvoices.matchFulfilled,
      (state, actions) => {
        const ledgerDetailsResponse = actions.payload;
        const temp = { ...state.companyCodeMap };
        temp[ledgerDetailsResponse?.customers[0]?.inviteId] =
          ledgerDetailsResponse?.customers[0]?.customerCode;
        state.companyCodeMap = temp;
      }
    );
  },
});

export const invoiceReducer = invoiceSlice.reducer;

export const {
  updateCustomerInvoiceItemData,
  updateAmountToPay,
  updateSelectedInvoiceItems,
  filterList,
  setCustomerSearch,
  updateSelectedInvoiceStatus,
} = invoiceSlice.actions;

export const getInvoiceSliceState = (rootState: RootState): InitialState =>
  rootState[INVOICE_FEATURE_KEY];

export const getCustomerInvoiceItemData = createSelector(
  getInvoiceSliceState,
  (state) => state.customerInvoiceItem
);

export const getSelectedInvoicesItems = createSelector(
  getInvoiceSliceState,
  (state) => state.selectedInvoiceItems
);

export const getAmountToPay = createSelector(
  getInvoiceSliceState,
  (state) => state.amountToPay
);

export const getFilterText = createSelector(
  getInvoiceSliceState,
  (state) => state.filterText
);

export const getCustomerSearch = createSelector(
  getInvoiceSliceState,
  (state) => state.customerSearch
);

export const getCompanyCodeMap = createSelector(
  getInvoiceSliceState,
  (state) => state.companyCodeMap
);

export const getSelectedInvoiceStatus = createSelector(
  getInvoiceSliceState,
  (state) => state.selectedInvoiceStatus
);
