import { combineReducers } from '@reduxjs/toolkit';
import { Api } from '../api/root-api';
import { userSlice } from '../auth';
import { eventsSlice } from '../events';
import { invoiceSlice } from '../invoice';
import { ledgerSlice } from '../ledger';
import { paymentSlice } from '../payment';
import { toastSlice } from '../toast';
import { userPreferenceSlice } from '../user-preference';
import { workspaceSlice } from '../workspace';
import { localAppStorage } from './local-storage';
import { inviteSlice } from '../invite';
import { onboardingSlice } from '../onboarding';
import { linkedWorkspacesSlice } from '../linked-workspaces';
import { sentTeamInvitesSlice } from '../sent-team-invites';
import { miscellaneousSlice } from '../miscellaneous';
import { kycSlice } from '../kyc';
import { ordersSlice } from '../orders';
import { mediaSlice } from '../media';
import { schemesSlice } from '../schemes';
import { productsSlice } from '../products';
import { dayBookSlice } from '../daybook';
import { customersSlice } from '../customers';
import { sessionSlice } from '../session';
import { filtersSlice } from '../filter';
import { hierarchySlice } from '../hierarchy';
import { importsSlice } from '../imports';
import { tableConfigSlice } from '../table-config';
import { returnsSlice } from '../returns';
import { superAdminSlice } from '../super-admin';
import { commsSlice } from '../channel';
import { msgSlice } from '../message';
import { drawerConfigSlice } from '../drawer-config';
import { zoeySlice } from '../zoey';
import { homeSlice } from '../home/slice';
import { visitSlice } from '../visits';
import { sellerActionSlice } from '../seller-action';
import { divisionSlice } from '../division/slice';
import { priceListSlice } from '../price-list/slice';
import { threadSlice } from '../thread';
import { salesSlice } from '../sales';
import { campaignSlice } from '../campaigns';
import { buyerToSellerSlice } from '../buyer-to-seller';
import { configSlice } from '../config';

const messagesPersistConfig = {
  key: 'messages',
  storage: localAppStorage,
  blacklist: ['messages'],
};

const appReducer = combineReducers({
  [Api.reducerPath]: Api.reducer,
  [userSlice.name]: userSlice.reducer,
  [toastSlice.name]: toastSlice.reducer,
  [paymentSlice.name]: paymentSlice.reducer,
  [invoiceSlice.name]: invoiceSlice.reducer,
  [workspaceSlice.name]: workspaceSlice.reducer,
  [ledgerSlice.name]: ledgerSlice.reducer,
  [eventsSlice.name]: eventsSlice.reducer,
  [userPreferenceSlice.name]: userPreferenceSlice.reducer,
  [customersSlice.name]: customersSlice.reducer,
  [inviteSlice.name]: inviteSlice.reducer,
  [onboardingSlice.name]: onboardingSlice.reducer,
  [mediaSlice.name]: mediaSlice.reducer,
  [linkedWorkspacesSlice.name]: linkedWorkspacesSlice.reducer,
  [sentTeamInvitesSlice.name]: sentTeamInvitesSlice.reducer,
  [miscellaneousSlice.name]: miscellaneousSlice.reducer,
  [kycSlice.name]: kycSlice.reducer,
  [ordersSlice.name]: ordersSlice.reducer,
  [schemesSlice.name]: schemesSlice.reducer,
  [productsSlice.name]: productsSlice.reducer,
  [dayBookSlice.name]: dayBookSlice.reducer,
  [sessionSlice.name]: sessionSlice.reducer,
  [filtersSlice.name]: filtersSlice.reducer,
  [hierarchySlice.name]: hierarchySlice.reducer,
  [importsSlice.name]: importsSlice.reducer,
  [tableConfigSlice.name]: tableConfigSlice.reducer,
  [returnsSlice.name]: returnsSlice.reducer,
  [superAdminSlice.name]: superAdminSlice.reducer,
  [commsSlice.name]: commsSlice.reducer,
  [msgSlice.name]: msgSlice.reducer,
  [drawerConfigSlice.name]: drawerConfigSlice.reducer,
  [zoeySlice.name]: zoeySlice.reducer,
  [homeSlice.name]: homeSlice.reducer,
  [visitSlice.name]: visitSlice.reducer,
  [sellerActionSlice.name]: sellerActionSlice.reducer,
  [divisionSlice.name]: divisionSlice.reducer,
  [priceListSlice.name]: priceListSlice.reducer,
  [threadSlice.name]: threadSlice.reducer,
  [salesSlice.name]: salesSlice.reducer,
  [campaignSlice.name]: campaignSlice.reducer,
  [buyerToSellerSlice.name]: buyerToSellerSlice.reducer,
  [configSlice.name]: configSlice.reducer,
});

export default appReducer;
