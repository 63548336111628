import React, { useContext } from 'react';
import { Avatar, ThemeContext } from '@zonofi/shared';
interface getStringProps {
  text: string;
  fontSize?: string;
  fontStyle?: 'semibold' | 'regular' | 'bold';
  fontColor?: string;
  style?: React.CSSProperties;
  showAvatar?: boolean;
  showTextUnderLine?: boolean;
  onClick?: () => void;
  className?: string;
  icon?: string;
}
export const GetString: React.FC<getStringProps> = ({
  text,
  fontSize,
  fontStyle,
  fontColor,
  onClick,
  style,
  showAvatar = false,
  showTextUnderLine = false,
  className,
  icon = null,
}) => {
  const { theme } = useContext(ThemeContext);
  const fontConfig =
    fontStyle === 'bold'
      ? theme.fontConfig.bold
      : fontStyle === 'semibold'
      ? { ...theme.fontConfig.semiBold }
      : { ...theme.fontConfig.regular };
  return (
    <div
      className={className && className}
      style={{ display: 'flex', gap: '8px', alignItems: 'center' }}
      onClick={() => onClick && onClick()}
    >
      {showAvatar && <Avatar size={24} cfa={true} title={text} />}
      {icon && <img alt="img" src={icon} width={'16px'} height={'16px'} />}
      <span
        style={{
          ...fontConfig,
          fontSize: fontSize || '14px',
          color: fontColor || '#1D1D22',
          overflowWrap: 'anywhere',
          textTransform: 'capitalize',
          textDecoration: showTextUnderLine ? 'underline' : '',
          ...style,
        }}
      >
        {text}
      </span>
    </div>
  );
};
