import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../root';
import { OrderStatusEnum } from '../helpers';
import { ZoeyScreens } from './model';

export const ZOEY_FEATURE_KEY = 'zoey';

interface initialZoeyState {
  zoeyDrawerIsOpen: boolean;
  currentActiveScreen: ZoeyScreens;
  zoeyOrderStatus?: OrderStatusEnum;
  activeZoAIMessageData: any;
}

export const initialZoeyState: initialZoeyState = {
  zoeyDrawerIsOpen: false,
  currentActiveScreen: 'welcome',
  activeZoAIMessageData: {},
};

export const zoeySlice = createSlice({
  name: ZOEY_FEATURE_KEY,
  initialState: initialZoeyState,
  reducers: {
    openZoeyDrawer(state) {
      state.zoeyDrawerIsOpen = true;
    },
    closeZoeyDrawer(state) {
      state.zoeyDrawerIsOpen = false;
    },
    updateZoeyScreen(state, action: PayloadAction<ZoeyScreens>) {
      state.currentActiveScreen = action?.payload;
    },
    updateActiveZoAIMessageData(state, action: PayloadAction<any>) {
      state.activeZoAIMessageData = action?.payload;
    },
    updateZoeyOrderStatus(
      state,
      action: PayloadAction<OrderStatusEnum | undefined>
    ) {
      state.zoeyOrderStatus = action.payload;
    },
  },
});

export const {
  openZoeyDrawer,
  closeZoeyDrawer,
  updateZoeyScreen,
  updateZoeyOrderStatus,
  updateActiveZoAIMessageData,
} = zoeySlice.actions;

export const getZoeySliceState = (rootState: RootState): initialZoeyState =>
  rootState[ZOEY_FEATURE_KEY];

export const getZoeyDrawerIsOpen = createSelector(
  getZoeySliceState,
  (state) => state.zoeyDrawerIsOpen
);

export const getZoeyActiveScreen = createSelector(
  getZoeySliceState,
  (state) => state.currentActiveScreen
);

export const getZoeyOrderStatus = createSelector(
  getZoeySliceState,
  (state) => state.zoeyOrderStatus
);

export const getActiveZoAIMessageData = createSelector(
  getZoeySliceState,
  (state) => state.activeZoAIMessageData
);
