import { ColumnConfig, SalesLeaderboardReportColumnKey } from '../../model';

export const salesLeaderboardReportConfig: Record<
  SalesLeaderboardReportColumnKey,
  ColumnConfig
> = {
  sNo: {
    name: 'S.No',
  },
  salespersonName: {
    name: 'Salesperson Name',
  },
  customers: {
    name: 'Customers',
    sortField: 'customers',
    alignment: 'flex-end',
  },
  checkIns: {
    name: 'CheckIns',
    sortField: 'checkins',
    alignment: 'flex-end',
  },
  noOfOrders: {
    name: 'Number of Orders',
    sortField: 'noOfOrders',
    alignment: 'flex-end',
  },
  totalSKU: {
    name: 'Total SKU',
    alignment: 'flex-end',
  },
  invoices: {
    name: 'Invoices',
    alignment: 'flex-end',
  },
  orderValue: {
    name: 'Order Value',
    sortField: 'totalValue',
    alignment: 'flex-end',
  },
  paymentsCollectedByUPI: {
    name: 'Payments Collected by UPI',
    alignment: 'flex-end',
    isComponent: true,
  },
  paymentsCollectedByCash: {
    name: 'Payments collected by Cash',
    alignment: 'flex-end',
    isComponent: true,
  },
  paymentsCollectedByCheque: {
    name: 'Payments Collected by Cheque',
    alignment: 'flex-end',
    isComponent: true,
  },
  totalPaymentsCollected: {
    name: 'Total Payments Collected',
    sortField: 'paymentCollected',
    alignment: 'flex-end',
    isComponent: true,
  },
};
