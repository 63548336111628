import { Api } from '../api/root-api';

import { GetBankAccountResponse } from './model';

export const bankAccountApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    getBankAccounts: builder.query<
      GetBankAccountResponse,
      { id: string; isUsedForCashBack: boolean }
    >({
      query: (arg) => ({
        url: `invoiceservice/bankaccount/${arg.id}?isUsedForCashBack=${arg.isUsedForCashBack}`,
        method: 'GET',
      }),
    }),
  }),
  overrideExisting: true,
});

export const { useLazyGetBankAccountsQuery } = bankAccountApi;
