import { ChatActionSendSchemesColumnKey, ColumnConfig } from '../../model';

export const chatActionSendSchemesConfig: Record<
  ChatActionSendSchemesColumnKey,
  ColumnConfig
> = {
  codeSelection: {
    name: 'Scheme Code',
    field: 'checkboxNavigate',
    isCheckboxField: true,
  },
  code: {
    name: 'Scheme Code',
    isHyperlink: true,
  },
  skuCode: {
    name: 'SKU Code',
  },
  status: {
    name: 'Status',
    field: 'toolTip',
  },
  title: {
    name: 'Title',
  },
  moq: {
    name: 'Min Qty',
    alignment: 'flex-end',
  },
  price: {
    name: 'PTS',
    sortField: 'ProductPrice',
    alignment: 'flex-end',
  },
};
