import { getFormattedAmount } from '@zonofi/common';
import { SVGS } from '@zonofi/zono-money-design-system';
import { OrderData } from '@zonofi/zono-money-helpers';
import React, { useMemo } from 'react';

interface OrderDetailsSummaryProps {
  orderDetails: OrderData;
  showOrderEdit: boolean;
}

export const OrderDetailsSummary: React.FC<OrderDetailsSummaryProps> = ({
  orderDetails,
  showOrderEdit,
}) => {
  let summary = [
    {
      name: 'Total Items',
      value:
        orderDetails?.lines?.length < 10
          ? orderDetails?.lines?.length.toString().padStart(2, '0')
          : orderDetails?.lines?.length,
    },
    {
      name: 'Total Quantity',
      value:
        orderDetails?.totalQuantity < 10
          ? orderDetails?.totalQuantity?.toString().padStart(2, '0')
          : orderDetails?.totalQuantity,
    },
    {
      name: 'Order Amount',
      value: getFormattedAmount(orderDetails?.total + orderDetails?.discount),
    },
    {
      name: 'Scheme Savings',
      value: getFormattedAmount(orderDetails?.discount),
      color: '#2ABB7F',
    },
    {
      name: 'Gross Amount',
      value: getFormattedAmount(orderDetails?.total),
    },
    {
      name: 'GST Tax',
      value: getFormattedAmount(orderDetails?.tax),
      color: '#DD360C',
    },
    {
      name: 'Net Order Amount',
      value: getFormattedAmount(orderDetails?.totalWithTax),
    },
  ];

  const summaryMemo = useMemo(() => {
    if (showOrderEdit) {
      return summary?.splice(0, 2);
    }
    return summary;
  }, [showOrderEdit, orderDetails]);

  return (
    <div className="order-details-summary">
      <div className="order-details-summary-header font-primary-semibold">
        <img src={SVGS.NotesIcon} />
        <span>{showOrderEdit ? 'Requirement' : 'Bill Details'}</span>
      </div>
      <div className="order-details-summary-items font-primary">
        {summaryMemo?.map((item) => {
          return (
            <div className="order-details-summary-item">
              <span>{item?.name}</span>
              <span>{item?.value}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};
